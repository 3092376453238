import { matchPath, useLocation } from 'react-router-dom';
import { ApplicationRole, UserRoles } from '@/types';
import { useClaimStatement } from '@/services/claimStatementServices';
import { useCurrentUser } from '@/services/userInfoServices';
import { useMemo } from 'react';
import { roles } from '@/utils/constants';

export const useParamsFromPath = (path: string) => {
  const { pathname } = useLocation();
  const match = matchPath(path, pathname);
  return match?.params || {};
};

export const useIsClaimsHandlerAssignedClaimsHandlerOnCase = (claimStatementId: string, role: ApplicationRole | undefined | null) => {
  const { data: claimStatement } = useClaimStatement(claimStatementId);
  const user = useCurrentUser();
  const isAssignedClaimsHandler = useMemo(() => claimStatement?.claimsHandlers?.some(x => x.id === user?.id), [claimStatement?.claimsHandlers, user?.id]);

  return role?.name !== roles.ClaimsHandler || isAssignedClaimsHandler;
};

export const isValidRole = (roleName: UserRoles | undefined, validRoles: UserRoles[]) => (
  validRoles.includes(roleName || '')
);

export const getClaimTabTitle = (roleName: UserRoles = '') => {
  if ([roles.Owner, roles.Broker].includes(roleName)) return "OWNER'S CLAIM";
  if (roleName === roles.ClaimsHandler) return "ADJUSTER'S CONSIDERATION";
  if (roleName === roles.Surveyor) return "SURVEYOR'S CONSIDERATION";
  return '';
};
