import {
  SupportingDocumentForm, SupportingDocumentResponse, documentCategories
} from '@/services/supportingDocumentsServices';
import { piiOptions } from '@/utils/invoice/invoicePiiOptions';

export const createInitialValues = (values: Partial<SupportingDocumentForm> = {}): SupportingDocumentForm => ({
  documentName: '',
  documentCategory: '',
  pii: '',
  piiDescription: '',
  ...values
});

export const createInitialEditAttachmentValues = (values: SupportingDocumentResponse): SupportingDocumentForm => ({
  documentName: values.documentName ?? '',
  documentCategory: documentCategories.find(category => category.value.toLocaleLowerCase() === values.documentCategory.toLocaleLowerCase()) ?? '',
  pii: piiOptions.find(p => p.value === values.pii) ?? '',
  piiDescription: values.piiDescription,
});
