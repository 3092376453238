import { StyledTag } from '@/common/components/Tags';
import { tagText } from '@/utils/tags';
import { FilterableTableHeader } from '@instech/components';
import { FC } from 'react';
import styled from 'styled-components';

export type Emphasize = 'Medium' | 'High' | 'Deleted';

/**
 * Styled table header that has less spacing than table headers
 * normally do, in accordance with Split Table styles.
 */
export const SplitTableHeader = styled(FilterableTableHeader)`
  padding: 0px;
`;

/**
 * Styled table header that has less spacing than table headers
 * normally do, in accordance with Split Table styles.
 * Right aligned.
 * Hides the filter icon space.
 */
export const RightAlignedSplitTableHeader = styled(FilterableTableHeader)`
  padding: 0px;
  & span {
    justify-content: end;
  }
  & div:last-child {
    display: none;
  }
`;

interface TableRowItemType {
  emphasize?: Emphasize;
  justify?: string;
  errorText?: boolean;
  hideOverflow?: boolean;
  noWrap?: boolean;
}
/**
 * Display div for elements in  a SplitTableRow, for use to show
 * the values from the form elements inside the table row.
 */
export const TableRowItem = styled.div<TableRowItemType>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify};
  padding: 8px 10px;
  background-color: ${props => (
    (props.emphasize === 'Medium' && props.theme.marineBlue20) ||
    (props.emphasize === 'High' && props.theme.marineBlue30) ||
    (props.emphasize === 'Deleted' && props.theme.adjuster.deleted) ||
    props.theme.marineBlue10
  )};
  color: ${props => props.errorText ? props.theme.warning : props.theme.marineBlue};
  // Required to truncate text in cells
  overflow: ${props => props.hideOverflow && 'hidden'};
  white-space: ${props => props.noWrap && 'nowrap'};
  `;

/**
 * Display div for elements inside a SplitTableRow collapse.
 */
export const TableRowInner = styled.div<{ collapsed?: boolean }>`
  grid-column: 1 / -1;
  background-color: ${props => props.theme.marineBlue10};
  &:not(:last-child) { // spacing down to next row after
    margin-bottom: 2px;
  }
`;

const TableRowTagItem = styled(TableRowItem)`
  justify-content: start;
  padding: 0px;
`;

interface TableRowTagProps {
  tag?: string | null;
  tooltipId?: string;
}
export const TableRowTag: FC<TableRowTagProps> = ({ tag, tooltipId }) => {
  const hideTag = !tag;
  const tagToShow = tagText(tag);
  return (
    <TableRowTagItem>
      {!hideTag && (
        <StyledTag
          data-for={tooltipId}
          data-tip={tagToShow}
          data-testid="table-row-tag"
          $filled
          $small />
      )}
    </TableRowTagItem>
  );
};
