import { ClaimStatement } from '@/types';
import { Pane, TabbedPane } from '@instech/components';
import { FunctionComponent as FC, useMemo } from 'react';
import { InformationView } from './InformationView';
import {
  useClaimStatementInformation,
  useDetailsOccurrences,
  useDetailsVesselInfo
} from './utils';

interface Props {
  data?: ClaimStatement;
  forPrint?: boolean;
}
export const ClaimDetails: FC<Props> = ({ data, forPrint }) => {
  const claimStatementInformation = useClaimStatementInformation(data);
  const vesselInfoDetails = useDetailsVesselInfo(claimStatementInformation?.vesselAndPolicy);
  const occurrenceDetails = useDetailsOccurrences(claimStatementInformation?.occurrences);

  const tabsForPrint = useMemo(
    () => [...occurrenceDetails, vesselInfoDetails].map(claimDetails => (
      <Pane
        title={claimDetails.title}
        color="green"
        key={claimDetails.title}
        padding="20px">
        <InformationView
          key={claimDetails.title}
          details={claimDetails.details}
          maxColumns={3}
          showCircle={claimDetails.title==='Vessel & Policy'} />
      </Pane>
    )),
    [occurrenceDetails, vesselInfoDetails]
  );

  return (
    <>
      { forPrint ?
        tabsForPrint : (
          <TabbedPane
            color="green"
            padding="20px"
            title="Claim Statement Information"
            tabs={[
              ...occurrenceDetails.map(occurrence => ({
                title: occurrence.title,
                key: occurrence.title,
                component: <InformationView details={occurrence.details} maxColumns={3} />
              })),
              {
                title: 'Vessel & Policy',
                key: vesselInfoDetails.title,
                component: <InformationView details={vesselInfoDetails.details} maxColumns={3} showCircle />
              },
            ]}
          />
        )}
    </>
  );
};
