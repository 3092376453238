import { PageContent } from '@/common/layout/Page';
import { useClaimStatement, useIsClosed } from '@/services/claimStatementServices';
import {
  useInvoice, useInvoiceLink, useLatestSurveyorsInstructions
} from '@/services/invoice/invoiceServices';
import { PageLoader } from '@instech/components';
import { useInvoiceLock } from '@/services/invoice/invoiceLockService';
import { useParams } from 'react-router-dom';
import { InvoiceState } from '@/types';
import { invoiceStates } from '@/utils/constants';
import { InvoiceDisplay } from '../core/EditInvoice/InvoiceDisplay/InvoiceDisplay';
import { InvoicePageLayout } from './core/Components';
import { InvoiceDetailsSurveyor } from './core/InvoiceDetailsSurveyor';
import { SurveyorInvoiceHeader } from './InvoiceHeader/SurveyorInvoiceHeader';
import { InvoiceLockProvider } from './utils/InvoiceLockContext';
import { useUserSessionSlide } from '../core/EditInvoice/utils/useUserSessionSlide';

export const editableStates: InvoiceState[] = [invoiceStates.CostAllocation, invoiceStates.CostReallocation];

export const SurveyorInvoicePage = () => {
  const { claimStatementId, invoiceId } = useParams();
  const { data: invoice } = useInvoice(claimStatementId, invoiceId);
  const { data: claimStatement } = useClaimStatement(claimStatementId);
  const { data: imageData } = useInvoiceLink(claimStatementId, invoiceId);
  const { data: surveyorInstructions } = useLatestSurveyorsInstructions(claimStatementId, invoiceId);
  const isClosed = useIsClosed(claimStatement);
  const { ownsLock, unlockInvoice } = useInvoiceLock(editableStates, claimStatementId, invoiceId, isClosed);
  useUserSessionSlide(unlockInvoice);

  if (!invoice || !claimStatement || ownsLock === null || !surveyorInstructions) {
    return <PageLoader />;
  }

  return (
    <div>
      <SurveyorInvoiceHeader
        invoice={invoice}
        claimStatement={claimStatement} />
      <PageContent>
        <InvoiceLockProvider ownsLock={ownsLock}>
          <InvoicePageLayout>
            <InvoiceDetailsSurveyor
              claimStatement={claimStatement}
              invoice={invoice}
              ownsLock={ownsLock}
              surveyorInstructions={surveyorInstructions} />
            <InvoiceDisplay invoice={invoice} ownsLock={ownsLock} imageUrl={imageData?.url} isClaimStatementClosed={isClosed} />
          </InvoicePageLayout>
        </InvoiceLockProvider>
      </PageContent>
    </div>
  );
};
