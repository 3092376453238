import React from 'react';
import { AdjustersConsiderationSplitForm, InvoiceRecord } from '@/types';
import { getAdjustersDeviation } from '@/utils/invoice/invoiceTotalDeviation';
import { arrayOfObjectsToSingle } from '@/utils/array';
import { getDeviationInCurrency } from '@/utils/invoice/invoiceDeviationText';
import { useConsiderationContext } from '@/components/pages/invoice/core/EditInvoice/InvoiceForm/utils/ConsiderationProvider';
import { Deviation } from './Deviation';
import getCurrentValue from './getCurrentValue';

interface ReduceConsiderationReturn {
  commonExpenses: number;
  generalAverage: number;
  particularAverage: number;
  ownersWorkConsideration: number;
}
const reduceConsiderationArray = (consideration: AdjustersConsiderationSplitForm[]) => {
  const valsToReduce: ReduceConsiderationReturn[] = consideration.map(item => ({
    commonExpenses: getCurrentValue(item.commonExpenses, item.initialCommonExpenses, item.form.commonExpensesConsidered ?? false),
    generalAverage: Number(item.generalAverage),
    particularAverage: getCurrentValue(item.particularAverage, item.initialParticularAverage, item.form.particularAvgConsidered ?? false),
    ownersWorkConsideration: getCurrentValue(item.ownersWorkConsideration, item.initialOwnersWork, item.form.ownersWorkConsidered ?? false)
  }));
  return arrayOfObjectsToSingle(valsToReduce);
};

interface Props {
  invoice: InvoiceRecord;
  selectedCurrency: string;
  className?: string;
  showGeneralAverage: boolean;
}
export const DeviationAdjuster = ({ invoice, selectedCurrency, className, showGeneralAverage }: Props) => {
  const { getConsiderationData } = useConsiderationContext();
  const adjusterConsiderationForm = getConsiderationData<AdjustersConsiderationSplitForm[]>();

  let deviationInCurrency = { number: 0, string: '' };
  if (adjusterConsiderationForm) {
    // Map array of surveyors' consideration objects to single set
    const flatVals = reduceConsiderationArray(adjusterConsiderationForm!.filter(x => !x.isDeleted)
      .map(x => ({ ...x, generalAverage: showGeneralAverage ? x.generalAverage : null })));

    const deviation = getAdjustersDeviation({
      commonExpenses: Number(flatVals.commonExpenses),
      generalAverage: Number(flatVals.generalAverage),
      particularAverage: Number(flatVals.particularAverage),
      ownersWorkConsideration: Number(flatVals.ownersWorkConsideration),
      totalInvoiceAmount: Number(invoice.totalAmount)
    });

    const { currency, exchangeRate } = invoice;
    deviationInCurrency = getDeviationInCurrency(deviation, currency, selectedCurrency, exchangeRate);
  }

  if (deviationInCurrency.number === 0) {
    return null;
  }

  return (
    <Deviation
      currency={selectedCurrency}
      deviation={deviationInCurrency.string}
      className={className}
      isNumber
    />
  );
};
