import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Breadcrumb } from './utils';

const Title = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
  color: ${props => props.theme.marineBlue50};
  margin-left: -.12em; // Offset spacing around the symbols
`;

const StyledLink = styled(NavLink)`
  &:hover { text-decoration: underline; }
`;

const Divider = styled.span`
  margin-left: .45em;
  margin-right: .45em;
`;

const CurrentPage = styled.span`
  color: ${props => props.theme.marineBlue};
`;

interface Props {
  steps: Breadcrumb[];
  current: string | JSX.Element;
}
export const Breadcrumbs: FunctionComponent<Props> = ({ steps, current }) => (
  <Title>
    {steps.map(step => (
      <React.Fragment key={step.label}>
        <StyledLink to={step.to}>{step.label}</StyledLink>
        <Divider>/</Divider>
      </React.Fragment>
    ))}
    <CurrentPage>{current}</CurrentPage>
  </Title>
);
