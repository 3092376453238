import * as Yup from 'yup';
import { datepickerSingle } from '@/utils/validationSchemas';

/**
 * Validation schema for AdjustedClaim from
 */
export const adjustedClaimValidationSchema = Yup.object({
  dueToAssured: Yup.number().nullable(),
  underwritersFeeAndExpenses: Yup.number().nullable(),
  totalClaimOnPolicy: Yup.number().nullable(),
  dueDate: datepickerSingle()
});
