import { useModalContext } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import { ButtonGroup, SlimButton } from '@instech/components';
import { WarningFilled } from '@instech/icons';
import { useState } from 'react';
import styled from 'styled-components';
import { ContentHorizontalCenter } from '@/common/layout/Content';
import { WarningLarge } from '@/common/components/Icon/WarningLarge';
import { Description } from './core/Components';

const BoldText = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-top: 16px;
`;

const TextWarningStyled = styled(WarningFilled)`
  margin-right: 8px;
`;

const Warning = styled(Description)`
  font-style: italic;
  display: flex;
`;

interface InvoicesNotCompleteProps {
  completeInvoices: number;
  incompleteInvoices: number;
  finishInvoices: () => Promise<void>;
}
const ModalContent = ({ completeInvoices, incompleteInvoices, finishInvoices }: InvoicesNotCompleteProps) => {
  const { close } = useModalContext();
  const [loading, setLoading] = useState(false);

  const onFinish = async () => {
    setLoading(true);
    await finishInvoices();
    close();
  };

  if (completeInvoices === 0) {
    const requiredInfoTitle = incompleteInvoices > 1
      ? 'These invoices are incomplete.'
      : 'This invoice is incomplete.';
    return (
      <>
        <ContentHorizontalCenter>
          <WarningLarge />
          <BoldText>{requiredInfoTitle}</BoldText>
          <Description>Please enter the required information before submitting.</Description>
        </ContentHorizontalCenter>
        <ButtonGroup alignRight marginTop="24px">
          <SlimButton onClick={close}>OK</SlimButton>
        </ButtonGroup>
      </>
    );
  }

  const incompleteInvoiceTitle = completeInvoices > 1
    ? `${completeInvoices} invoices will be saved as finished.`
    : `${completeInvoices} invoice will be saved as finished.`;

  const incompleteInvoiceText = incompleteInvoices > 1
    ? `${incompleteInvoices} are incomplete and will not be saved as finished.`
    : `${incompleteInvoices} is incomplete and will not be saved as finished.`;

  return (
    <>
      <ContentHorizontalCenter>
        <BoldText>{incompleteInvoiceTitle}</BoldText>
        <Warning>
          <TextWarningStyled />
          {incompleteInvoiceText}
        </Warning>
      </ContentHorizontalCenter>
      <ButtonGroup alignRight marginTop="24px">
        <SlimButton variant="secondary" onClick={close}>CANCEL</SlimButton>
        <SlimButton onClick={onFinish} loading={loading}>SAVE AS FINISHED</SlimButton>
      </ButtonGroup>
    </>
  );
};

export const defaultInvoicesNotCompleteModalProps: InvoicesNotCompleteProps = {
  completeInvoices: 0,
  incompleteInvoices: 0,
  finishInvoices: () => Promise.resolve()
};
export const invoicesNotCompleteModal = (
  { completeInvoices, incompleteInvoices, finishInvoices }: InvoicesNotCompleteProps
): ModalType<InvoicesNotCompleteProps> => ({
  component: ModalContent,
  options: {
    title: 'Save as finished',
    size: 'small',
    padding: '12px 24px 24px'
  },
  args: { completeInvoices, incompleteInvoices, finishInvoices }
});
