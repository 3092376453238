import { ApplyOwnerValuesButton, ApplySurveyorValuesButton } from '@/components/pages/invoice/core/EditInvoice/core/ApplyValuesButton';
import { ButtonGroup, SlimButton } from '@instech/components';
import {
  Close, Plus, Trash
} from '@instech/icons';
import { FunctionComponent as FC } from 'react';

const CloseIcon = () => (
  <Close width="18px" height="18px" />
);

interface Props {
  onAddNote: () => void;
  onDeleteNote: () => void;
  onAddSplit?: () => void;
  onRemoveSplit?: (index: number) => void;
  showNote?: boolean;
  viewAsListOfSplits?: boolean;
  showApplyValuesOwner?: boolean;
  showApplyValuesSurveyor?: boolean;
  onApplyValues?: () => void;
  applyValuesDisabled?: boolean;
}
/**
 * Show button(s) for Consideration Fields. Primarily shows the 'Add Split'
 * button below the list of splits when there are multiple. If there is not
 * a split, this component is not used and the button is shown elsewhere
 * (for layout reasons).
 */
export const SplitItemButtons: FC<Props> = ({
  onAddNote,
  onDeleteNote,
  onAddSplit,
  onRemoveSplit,
  showNote,
  viewAsListOfSplits,
  showApplyValuesOwner,
  showApplyValuesSurveyor,
  onApplyValues,
  applyValuesDisabled
}) => (
  <ButtonGroup alignRight>
    {!showNote && (
      <SlimButton variant="tertiary" startIcon={<Plus />} onClick={onAddNote}>
        ADD INTERNAL NOTE
      </SlimButton>
    )}
    {showNote && (
      <SlimButton variant="tertiary" startIcon={<CloseIcon />} onClick={onDeleteNote}>
        DELETE INTERNAL NOTE
      </SlimButton>
    )}
    {showApplyValuesOwner && <ApplyOwnerValuesButton onApplyValues={onApplyValues} disabled={applyValuesDisabled} />}
    {showApplyValuesSurveyor && <ApplySurveyorValuesButton onApplyValues={onApplyValues} disabled={applyValuesDisabled} />}
    {!viewAsListOfSplits && (
      <SlimButton variant="secondary" startIcon={<Plus />} onClick={onAddSplit}>
        ADD SPLIT
      </SlimButton>
    )}
    {viewAsListOfSplits && (
      <SlimButton variant="tertiary" startIcon={<Trash />} onClick={onRemoveSplit}>
        DELETE SPLIT
      </SlimButton>
    )}
  </ButtonGroup>
);
