import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  height: 40px;
  color: ${props => props.theme.marineBlue};
`;

export const SaveText = styled.span`
  line-height: 16px;
  font-size: 13px;
  font-weight: bold;
  padding: 8px;
`;
