import { ComponentProps } from 'react';
import styled from 'styled-components';
import { DecimalNumberField } from '@/common/components/Form/DecimalNumberField';
import { HighlightEffect } from '@/common/components/HighlightedFields';
import { useInvoiceSuggestion } from './utils';

const DecimalNumberFieldHighlighted = styled(DecimalNumberField)`
  & input[data-highlight="true"] {
    ${HighlightEffect}
  }
`;

type TotalAmountInputProps = {
  suggestion: number | undefined;
} & Partial<ComponentProps<typeof DecimalNumberFieldHighlighted>>;
const TotalAmountInput = ({ suggestion, ...props }: TotalAmountInputProps) => {
  const highlight = useInvoiceSuggestion('totalAmount', suggestion);
  return (
    <DecimalNumberFieldHighlighted
      name="totalAmount"
      maxDecimals={2}
      label="Invoice Total Amount"
      required
      data-highlight={highlight}
      {...props} />
  );
};

export default TotalAmountInput;
