import { FunctionComponent as FC } from 'react';
import { IntrinsicSpanProps } from '@/types';
import { ellipsisOrDash, textOrNone } from '@/utils/text';
import { StyledTextCell } from './Components';

interface Props extends IntrinsicSpanProps {
  value: string | null;
  tooltipId: string;
  underline?: boolean;
}
export const TableCellTextTooltip: FC<Props> = ({ value, tooltipId, underline, ...props }) => (
  <StyledTextCell underline={underline} {...props}>
    <span data-for={tooltipId} data-tip={textOrNone(value)}>
      {ellipsisOrDash(value)}
    </span>
  </StyledTextCell>
);
