import { RadioButtonsControlled } from '@instech/components';
import { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import { HiddenInPrint } from '@/common/layout/Page';
import { GroupingOption, groupingOptionsList } from './types';

/**
 * Separate StickyWrapper export for components, as some (soon deprecated)
 * uses of it still have a sticky page. Remove when no longer needed.
 */
export const GroupingRadioStickyWrapper = styled.div`
  position: sticky;
  left: 0;
  right: 0;
  width: calc(100vw - 98px);
  padding: 0px 40px;
  box-sizing: border-box;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const CustomRadioLabel = styled.label`
  flex-shrink: 0;
  font-size: 16px;
  font-weight: 700;
  color: ${props => props.theme.marineBlue};
  padding: 12px 16px 12px 0px;
`;

const StyledRadioButtonsControlled = styled(RadioButtonsControlled)`
  & label {
    color: ${props => props.theme.marineBlue};
    padding: 10px 16px;
  }
`;

interface Props {
  selected: GroupingOption;
  onChange: (selection: GroupingOption) => void;
}
/**
 * Renders a set of grouping options for a table. Setting and tracking the
 * currently selected grouping choice is done by the implementing table.
 */
export const TableGroupingRadio: FC<Props> = ({ selected, onChange }) => (
  <Container>
    <HiddenInPrint>
      <CustomRadioLabel htmlFor="table-grouping-radio">
        Group invoices by:
      </CustomRadioLabel>
      <StyledRadioButtonsControlled
        name="table-grouping-radio"
        options={groupingOptionsList}
        onChange={onChange}
        selected={selected}
        horizontal
        noLabel
        noErrors />
    </HiddenInPrint>
  </Container>
);
