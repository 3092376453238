import { tableCellVariant } from '@/common/components/Table/types';
import { TableCellMaxContent } from '@/common/components/Table/core/TableCell';
import { ellipsisOrDash, textOrNone } from '@/utils/text';
import { SurveyorsConsideration } from '@/types';
import { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import { getFieldValue, getTooltipValue } from '../../core/utils/getConsideredValues';

const MessageWrapper = styled.div`
  font-style: italic;
`;

interface Props {
  tooltipId: string;
  considerations?: SurveyorsConsideration;
  onClick?: () => void;
  isReadonly?: boolean;
}
export const InvoiceTableSurveyorCells: FC<Props> = ({ tooltipId, considerations, onClick, isReadonly }) => {
  if (isReadonly) {
    return (
      <TableCellMaxContent data-cell-variant={tableCellVariant.secondary} onClick={onClick} span="5" center>
        <MessageWrapper>Not to be considered</MessageWrapper>
      </TableCellMaxContent>
    );
  }

  return (
    <>
      <TableCellMaxContent data-cell-variant={tableCellVariant.secondary} onClick={onClick} right>
        <div data-for={tooltipId} data-tip={getTooltipValue(true, considerations?.generalExpenses, 1)}>
          {getFieldValue(true, considerations?.generalExpenses, 1)}
        </div>
      </TableCellMaxContent>
      <TableCellMaxContent data-cell-variant={tableCellVariant.secondary} onClick={onClick} right>
        <div data-for={tooltipId} data-tip={getTooltipValue(true, considerations?.claimConsideration, 1)}>
          {getFieldValue(true, considerations?.claimConsideration, 1)}
        </div>
      </TableCellMaxContent>
      <TableCellMaxContent data-cell-variant={tableCellVariant.secondary} onClick={onClick} right>
        <div data-for={tooltipId} data-tip={getTooltipValue(true, considerations?.ownersWorkConsideration, 1)}>
          {getFieldValue(true, considerations?.ownersWorkConsideration, 1)}
        </div>
      </TableCellMaxContent>
      <TableCellMaxContent data-cell-variant={tableCellVariant.secondary} onClick={onClick} right>
        <div data-for={tooltipId} data-tip={getTooltipValue(true, considerations?.adjusterToConsider, 1)}>
          {getFieldValue(true, considerations?.adjusterToConsider, 1)}
        </div>
      </TableCellMaxContent>
      <TableCellMaxContent data-cell-variant={tableCellVariant.secondary}>
        <span data-for={tooltipId} data-tip={textOrNone(considerations?.surveyorsComment)}>
          {ellipsisOrDash(considerations?.surveyorsComment)}
        </span>
      </TableCellMaxContent>
    </>
  );
};
