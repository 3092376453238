import { InvoiceRecord } from '@/types';
import { getDeviationInCurrency } from '@/utils/invoice/invoiceDeviationText';
import { getAdjustersDeviation } from '@/utils/invoice/invoiceTotalDeviation';
import { ReduceConsiderationReturn } from './types';

const isDefinedOrZero = (f: number | undefined | null | string) => f !== undefined && f !== null && f !== '';

export const getTotalLineDeviation = (values: ReduceConsiderationReturn, invoice: InvoiceRecord, selectedCurrency: string) => {
  const deviation = getAdjustersDeviation({
    commonExpenses: Number(values.commonExpenses),
    generalAverage: Number(values.generalAverage),
    particularAverage: Number(values.particularAverage),
    ownersWorkConsideration: Number(values.ownersWorkConsideration),
    totalInvoiceAmount: Number(invoice.totalAmount)
  });

  return getDeviationInCurrency(deviation, invoice.currency, selectedCurrency, invoice.exchangeRate).number;
};

export const sumSplitValue = (values: (number | undefined | string | null)[]) => values
  .filter(isDefinedOrZero)
  .reduce((partialSum, nextNumber) => Number(partialSum ?? 0) + Number(nextNumber), undefined) as number | undefined;
