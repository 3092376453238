import { CollapsibleHeaderSegment, CollapsibleSubheaderSegment } from '@/common/components/Table/CollapsibleTable/types';
import { theme } from '@/utils/style/theme';

const headerBorder = theme.marineBlue60;

export const surveyorTableHeaders = (selectableRows: boolean): CollapsibleHeaderSegment[] => [
  {
    specialRole: 'space',
    propName: 'select',
    shouldSkip: selectableRows,
    beforeSubrows: true,
    borderColor: headerBorder
  },
  {
    title: 'Invoice No',
    propName: 'record.invoiceNumber',
    borderColor: headerBorder
  },
  {
    title: 'Supplier',
    propName: 'record.supplier',
    borderColor: headerBorder
  },
  {
    title: 'Category',
    propName: 'record.category',
    borderColor: headerBorder
  },
  {
    title: 'Cost Description',
    propName: 'record.ownersComment',
    borderColor: headerBorder
  },
  {
    title: 'Currency',
    propName: 'record.currency',
    borderColor: headerBorder
  },
  {
    title: 'Total',
    propName: 'record.totalAmount',
    align: 'flex-end',
    borderColor: headerBorder
  },
  {
    title: 'General Expenses',
    propName: 'surveyorsConsideration.generalExpenses',
    align: 'flex-end',
    borderColor: headerBorder
  },
  {
    title: 'Claim',
    propName: 'surveyorsConsideration.claimConsideration',
    align: 'flex-end',
    borderColor: headerBorder
  },
  {
    title: 'Owner',
    propName: 'surveyorsConsideration.ownersWorkConsideration',
    align: 'flex-end',
    borderColor: headerBorder
  },
  {
    title: 'Adjuster to Consider',
    propName: 'surveyorsConsideration.adjusterToConsider',
    align: 'flex-end',
    borderColor: headerBorder
  },
  {
    title: 'Description of Consideration',
    propName: 'surveyorsConsideration.surveyorsComment',
    borderColor: headerBorder
  },
  {
    specialRole: 'space',
    propName: 'contextMenu',
    borderColor: headerBorder
  }
];

export const surveyorTableSubheaders = (selectableRows: boolean): CollapsibleSubheaderSegment[] => [
  {
    key: 'invoice-details-gap',
    span: selectableRows ? 7 : 8
  },
  {
    key: 'surveyorsConsideration',
    title: 'Surveyor\'s Allocation of Costs - Invoice Currency',
    span: 4
  },
  {
    key: 'comment-and-context',
    span: 2
  },
];
