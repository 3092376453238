import { Guid } from '@/types';

/**
 * Get base route to the invoice API
 */
export const apiRoute = (statementId: Guid) => `/claimstatements/${statementId}/invoices`;

/**
 * Get API route to a single invoice
 */
export const apiRouteInvoice = (statementId: Guid, invoiceId: Guid) =>
  `${apiRoute(statementId)}/${invoiceId}`;

export const apiRouteInvoiceSharedForReview = (statementId: Guid, invoiceId: Guid) =>
  `${apiRoute(statementId)}/${invoiceId}/reviews`;

/**
 * Get API route to a previous instruction from CLH to Surveyor
 */
export const apiRouteLatestSurveyorsInstructions = (statementId: Guid, invoiceId: Guid) =>
  `${apiRoute(statementId)}/${invoiceId}/surveyorsinstructions`;

/**
 * Get API route to a previous comments from CLH to Owner
 */
export const apiRouteLatestOwnersInstructions = (statementId: Guid, invoiceId: Guid) =>
  `${apiRoute(statementId)}/${invoiceId}/ownersinstructions`;

/**
 * Get API route to an invoice file link (e.g. for displaying image or PDF)
 */
export const apiRouteInvoiceLink = (statementId: Guid, invoiceId: Guid) =>
  `${apiRouteInvoice(statementId, invoiceId)}/link`;

/**
 * Get API route to a batch of invoices
 */
export const apiRouteBatchInvoices = (statementId: Guid, batchId: Guid) =>
  `${apiRoute(statementId)}/batches/${batchId}`;

/**
 * Get API route to the Surveyor's Consideration part of an invoice
 */
export const apiRouteSurveyorsConsideration = (statementId: Guid, invoiceId: Guid) =>
  `${apiRouteInvoice(statementId, invoiceId)}/surveyors/considerations`;

/**
 * Get API route to the Adjuster's Consideration part of an invoice
 */
export const apiRouteAdjustersConsideration = (statementId: Guid, invoiceId: Guid) =>
  `${apiRouteInvoice(statementId, invoiceId)}/adjusters/considerations`;

export const apiRouteUpdateOwnersRecord = (statementId: Guid, invoiceId: Guid) =>
  `${apiRouteInvoice(statementId, invoiceId)}/owners/records`;

/**
 * Get API route to Owner's review request
 */
export const apiRouteOwnersReview = (claimStatementId: Guid, invoiceId: Guid) =>
  `${apiRoute(claimStatementId)}/${invoiceId}/reviews/comment`;
