import { useMemo } from 'react';
import { TabbedPane, Tabs } from '@instech/components';
import {
  AdjustmentCalculations,
  Cover
} from '@/apps/Adjustment/types/adjustmentCalculations';
import { PolicySummaryItems } from './PolicySummaryItems';
import { PolicyInformationForm } from './PolicyInformationForm';
import { getPolicyTitle } from './utils';

type PolicyTabProps = {
  cover: Cover,
  adjustmentCalculation: AdjustmentCalculations,
  interest: string
};
const PolicyTab = ({
  cover,
  adjustmentCalculation,
  interest
}: PolicyTabProps) => (
  <div>
    <PolicySummaryItems currency={cover.insuredValue.initial?.currency ?? '-'}
                        policyConditions={cover.condition.description}
                        interest={interest} />
    <PolicyInformationForm cover={cover} adjustmentCalculation={adjustmentCalculation} />
  </div>
);

interface Props {
  adjustmentCalculations: AdjustmentCalculations;
  interest: string;
}

export const PolicyInformationPane = ({
  adjustmentCalculations,
  interest
}: Props) => {
  const tabs = useMemo(() => adjustmentCalculations.covers.map<Tabs>((cover, index) => ({
    title: getPolicyTitle(cover, index),
    component: <PolicyTab cover={cover}
                          adjustmentCalculation={adjustmentCalculations}
                          interest={interest} />,
    key: cover.id
  })), [adjustmentCalculations, interest]);

  if (tabs.length === 0) {
    return <p>Nothing to show!</p>;
  }

  return (
    <TabbedPane title="Policy Information" color="green" tabs={tabs} />
  );
};
