import { InfoWithLabel, InfoWithLabelAndTag } from '@/common/components/Text';
import { AdjustersConsiderationSplit, SurveyorsConsiderationSplit } from '@/types';
import { formatWithDigitsOrEmpty, getConvertedCurrencyText } from '@/utils/number';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { calculateSurveyorDeviation } from '@/utils/invoice/calculateSummaries';
import { DividerLine } from '@/apps/ClaimStatement/components/InvoiceSplitTable';
import { useSharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { AtSurveyorsDeskSubSplit } from '../../SurveyorsConsiderationPane/SurveyorsConsiderationPane';
import { useInvoiceConsideredBySurveyor } from '../../../EditInvoice/InvoiceForm/utils/SurveyorSplitsContext';
import { DarkSingleReadonlySurveyorsConsiderationPane } from '../../SurveyorsConsiderationPane/SurveyorsConsiderationReadonly';

const Container = styled.div<{ deleted?: boolean }>`
  padding: 0px 16px 16px;
  color: ${props => props.theme.marineBlue};
  background: ${props => props.deleted ? props.theme.adjuster.deleted : props.theme.marineBlue10};
`;

const GridLayout = styled.div<{ $showGeneralAverage: boolean }>`
  display: grid;
  column-gap: 24px;
  row-gap: 32px;
  grid-template-columns: ${props => props.$showGeneralAverage ? 'repeat(5, 1fr)' : 'repeat(4, 1fr)'};
  margin-bottom: 20px; // +12px from Surveyor collapsible for 32px gap
`;

// Comments and notes should be 3 columns wide
const InfoLabelWide = styled(InfoWithLabel)`
  grid-column: span 3;
`;

interface FieldProps {
  adjusterSplit: AdjustersConsiderationSplit;
  surveyorSplit?: SurveyorsConsiderationSplit | null | undefined;
  exchangeRate?: number | null;
  useInvoiceCurrency: boolean;
  occurrence: string | undefined;
  showInternalNotes: boolean;
  atSurveyorsDesk?: boolean;
  showGeneralAverage: boolean;
}

export const ReadonlyFields: FunctionComponent<FieldProps> = ({
  adjusterSplit,
  surveyorSplit,
  exchangeRate,
  useInvoiceCurrency,
  occurrence,
  showInternalNotes,
  atSurveyorsDesk = false,
  showGeneralAverage
}) => {
  const { tooltipId } = useSharedTooltip();
  const isConsidered = useInvoiceConsideredBySurveyor();

  const deviationFromSurveyor = calculateSurveyorDeviation(adjusterSplit, surveyorSplit, isConsidered, exchangeRate, useInvoiceCurrency);

  return (
    <Container deleted={adjusterSplit.isDeleted}>
      <DividerLine />
      {!adjusterSplit.isDeleted && (
        <GridLayout $showGeneralAverage={showGeneralAverage}>
          <InfoWithLabelAndTag
            label="Common Expenses"
            text={getConvertedCurrencyText(adjusterSplit.commonExpenses, exchangeRate, useInvoiceCurrency)}
            tag={adjusterSplit.commonExpensesTag}
            tooltipId={tooltipId} />
          {showGeneralAverage && (
            <InfoWithLabel
              label="General Average"
              text={getConvertedCurrencyText(adjusterSplit.generalAverage, exchangeRate, useInvoiceCurrency)} />
          )}
          <InfoWithLabelAndTag
            label="Particular Average"
            text={getConvertedCurrencyText(adjusterSplit.particularAverage, exchangeRate, useInvoiceCurrency)}
            tag={adjusterSplit.particularAverageTag}
            tooltipId={tooltipId} />
          <InfoWithLabelAndTag
            label="Remainder"
            text={getConvertedCurrencyText(adjusterSplit.ownersWorkConsideration, exchangeRate, useInvoiceCurrency)}
            tag={adjusterSplit.ownersWorkConsiderationTag}
            tooltipId={tooltipId} />
          <InfoWithLabel
            label="Deviation from Surveyor"
            text={formatWithDigitsOrEmpty(deviationFromSurveyor, true)} />
          <InfoWithLabel
            label="Occurrence"
            text={occurrence} />
          <InfoLabelWide
            label="Adjuster’s Description"
            text={adjusterSplit.adjustersComment}
            multiline />
          <InfoWithLabel
            label="Category"
            text={adjusterSplit.category} />
          <InfoWithLabel
            label="Repair Period"
            text={adjusterSplit.repairPeriod} />
          {showInternalNotes && (
            <InfoLabelWide
              label="Internal Notes - Only visible for CH/Adjuster"
              text={adjusterSplit.internalNotes}
              multiline />
          )}
        </GridLayout>
      )}
      {surveyorSplit && <DarkSingleReadonlySurveyorsConsiderationPane record={surveyorSplit} />}
      {atSurveyorsDesk && <AtSurveyorsDeskSubSplit />}
    </Container>
  );
};
