import { useState } from 'react';
import { useModalContext } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import { ButtonGroup, SlimButton } from '@instech/components';
import { WarningFilled } from '@instech/icons';
import styled from 'styled-components';
import {
  BoldText, FooterText, RegularText, TextWrapper
} from './core/Components';
import { transferOwnerToClaimsHandlerModalTexts } from './core/transferOwnerToClaimsHandlerModalTexts';

const WarningIcon = styled.div`
  margin-bottom: 12px;
`;

interface TransferOwnerToClaimsHandlerProps {
  transfer: () => Promise<void>;
  numberOfInvoices: number;
  numberOfIncompleteInvoices: number;
  onValidationFailed?: () => Promise<void>;
}

const ModalContent = ({ transfer, numberOfIncompleteInvoices, numberOfInvoices, onValidationFailed }: TransferOwnerToClaimsHandlerProps) => {
  const { close } = useModalContext();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const handleSubmit = async () => {
    setSubmitting(true);
    await transfer();
    close();
  };

  const handleIncomplete = async () => {
    if (onValidationFailed) {
      await onValidationFailed();
    }
    close();
  };

  const texts = transferOwnerToClaimsHandlerModalTexts(numberOfIncompleteInvoices, numberOfInvoices);
  if (numberOfIncompleteInvoices === numberOfInvoices) {
    // No invoices will be submitted
    return (
      <div>
        <TextWrapper>
          <WarningIcon>
            <WarningFilled height="52px" width="55px" />
          </WarningIcon>
          <BoldText>{texts.title}</BoldText>
          {texts.body.map(content => <RegularText key={content}>{content}</RegularText>)}
        </TextWrapper>
        <ButtonGroup alignRight>
          <SlimButton padding="0 4px" onClick={handleIncomplete}>OK</SlimButton>
        </ButtonGroup>
      </div>
    );
  }

  // At least some invoices will be submitted
  return (
    <div>
      <TextWrapper>
        <BoldText>{texts.title}</BoldText>
        {texts.body.map(content => <RegularText key={content}>{content}</RegularText>)}
        {texts.footer && (
          <FooterText>
            <WarningFilled />
            {texts.footer}
          </FooterText>
        )}
      </TextWrapper>
      <ButtonGroup alignRight>
        <SlimButton variant="secondary" onClick={close}>CANCEL</SlimButton>
        <SlimButton onClick={handleSubmit} width="186px" disabled={submitting} loading={submitting}>SUBMIT TO CLAIMS HANDLER</SlimButton>
      </ButtonGroup>
    </div>
  );
};

export const transferOwnerToClaimsHandlerModal = (
  { transfer, numberOfIncompleteInvoices, numberOfInvoices, onValidationFailed }: TransferOwnerToClaimsHandlerProps,
  onCancel: () => void = () => null,
): ModalType<TransferOwnerToClaimsHandlerProps> => ({
  component: ModalContent,
  options: {
    title: 'Submit to Claims Handler',
    size: 'small',
    padding: '20px'
  },
  args: { transfer, numberOfIncompleteInvoices, numberOfInvoices, onValidationFailed },
  onCancel
});

export const defaultTransferOwnerToClaimsHandlerProps: TransferOwnerToClaimsHandlerProps = {
  numberOfIncompleteInvoices: 0,
  numberOfInvoices: 0,
  transfer: async () => {}
};
