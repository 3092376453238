import {
  datepickerSingle, numberGreaterThanZero, numberOrUndefined, stringTrimmedSanitized
} from '@/utils/validationSchemas';
import { stringMaxLength } from '@instech/components';
import * as Yup from 'yup';

export const invoiceDataValidationSchema = Yup.object().shape({
  totalAmount: Yup.number().typeError('Not a valid number'),
  exchangeRate: numberGreaterThanZero(),
  invoiceNumber: stringTrimmedSanitized(),
  clientReference: stringTrimmedSanitized(),
  ownersComment: stringTrimmedSanitized(),
  notes: stringTrimmedSanitized(),
  paymentDate: datepickerSingle(),
  personallyIdentifiableInformationDescription: stringMaxLength(110),
  paidAmount: numberOrUndefined()
}).default(undefined);

export const invoiceAdjusterSchema = Yup.object().shape({
  splits: Yup.array().of(Yup.object().shape({
    commonExpenses: numberOrUndefined(),
    ownersWorkConsideration: numberOrUndefined(),
    particularAverage: numberOrUndefined(),
  })),
  personallyIdentifiableInformationDescription: stringMaxLength(110)
}).default(undefined);

export const invoiceDataValidationSchemaOwner = invoiceDataValidationSchema.concat(
  Yup.object().shape({
    totalAmount: Yup.number().typeError('Not a valid number').required('Required'),
    currency: Yup.object().required('Required'),
    supplier: Yup.object().required('Required'),
    invoiceNumber: stringTrimmedSanitized().required('Required')
  })
);
