import {
  createContext, useContext, useMemo, useReducer, useState,
} from 'react';
import { FCWC } from '@/types';
import { PromptIfDirty } from './PromptIfDirty';

export interface ContextData {
  setDirtyForms: (formId: string, value: boolean) => void,
  skipPrompt: () => void;
  shouldPrompt: boolean
}
const initialData: ContextData = {
  setDirtyForms: (_formId, _value) => { },
  skipPrompt: () => null,
  shouldPrompt: false
};
const PromptContext = createContext<ContextData>({ ...initialData });

type FormId = string;
interface Action {
  type: 'push' | 'pop';
  payload: FormId;
}
function dirtyFormIdReducer(state: string[], action: Action): string[] {
  switch (action.type) {
    case 'push':
      if (state.includes(action.payload)) return state;
      return [...state, action.payload];
    case 'pop':
      if (!state.includes(action.payload)) return state;
      return [...state].filter(id => id !== action.payload);
    default:
      throw new Error();
  }
}

export const PromptUnsavedChangesV6: FCWC = ({ children }) => {
  const [dirtyFormIds, dispatchDirtyFormId] = useReducer(dirtyFormIdReducer, []);
  const [shouldSkipPrompt, setSkipPrompt] = useState(false);

  const contextValues = useMemo(() => {
    const shouldPrompt = dirtyFormIds.length > 0;

    const setDirtyForms = (formId: string, isDirty: boolean) => {
      const action = isDirty ? 'push' : 'pop';
      dispatchDirtyFormId({ type: action, payload: formId });
      setSkipPrompt(false);
    };

    const skipPrompt = () => setSkipPrompt(true);

    return { setDirtyForms, skipPrompt, shouldPrompt };
  }, [dirtyFormIds.length]);

  return (
    <PromptContext.Provider value={contextValues}>
      <PromptIfDirty
        message="Are you sure you want to leave? You have unsaved changes."
        when={!!dirtyFormIds.length && !shouldSkipPrompt}
      />
      {children}
    </PromptContext.Provider>
  );
};

export const usePromptContext = () => useContext(PromptContext);
