import {
  deleteAsync, postAsync, putAsync
} from '@/services/client';
import { Occurrence } from '@/types';
import useSWR, { mutate } from 'swr';
import { claimStatementUrl } from '@/services/claimStatementServices';

const baseUrl = (claimStatementId: string) => `claimstatements/${claimStatementId}/occurrences`;
const url = (claimStatementId: string, occurrenceId: string) => `${baseUrl(claimStatementId)}/${occurrenceId}`;

export const isConnectedToIns = (occurrence: Occurrence) => occurrence.insuranceYear && occurrence.claimNumber;

export type TransferAllocatedCostRequest = {
  receivingOccurrenceId: string;
  deallocateCosts: boolean;
};

export const useOccurrences = (claimStatementId: string) => ({
  createOccurrence: async (occurrence: Occurrence) => {
    await postAsync(baseUrl(claimStatementId), occurrence);
  },
  updateOccurrence: async (occurrence: Occurrence) => {
    await putAsync(url(claimStatementId, occurrence.id), occurrence);
  },
  deleteOccurrence: async (occurrenceId: string) => {
    await deleteAsync(url(claimStatementId, occurrenceId));
  },
  transferAllocatedCost: async (occurrenceId: string, request: TransferAllocatedCostRequest) => {
    await postAsync(`${url(claimStatementId, occurrenceId)}/transferAllocatedCost`, request);

    await mutate(
      (key:string) => key && key.startsWith(claimStatementUrl(claimStatementId))
    );
  },
});

export type CostAllocationSummary = {
  [occurrenceId: string]: {
    hasAllocatedCost: boolean;
  };
};
export type CostAllocationSummaryResponse = {
  occurrences: CostAllocationSummary;
}
export const useCostAllocationSummary = (claimStatementId: string) =>
  useSWR<CostAllocationSummaryResponse>(`${baseUrl(claimStatementId)}/costallocationsummary`);
