import { TextFieldControlled, withFormId } from '@instech/components';
import { useField } from 'formik';
import React, { ComponentProps, FunctionComponent as FC } from 'react';
import { NumberInput } from '@/common/components/Form/NumberInput';
import { NumberFormatValues } from 'react-number-format';

interface Props extends Omit<ComponentProps<typeof TextFieldControlled>, 'onChange' | 'type' | 'defaultValue'> {
  name: string;
  maxDecimals: number;
  onBlur?: () => void;
  onChange?: ((value: number | undefined) => void) | ((value: number | undefined) => Promise<void>);
  minValue?: number;
}

export const DecimalNumberField: FC<Props> = ({ id, name, maxDecimals, onBlur, onChange, minValue, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const fieldId = withFormId(name, id);

  // Handler should call with shouldTouch on blur event, so that focusing
  // out of the input element causes Formik to trigger Touched and show errors
  const handleChange = async (evt: NumberFormatValues) => {
    await helpers.setValue(evt.floatValue);

    if (onChange) {
      await onChange(evt.floatValue);
    }
  };

  const handleBlur = async () => {
    await helpers.setTouched(true);
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <NumberInput
      id={fieldId}
      name={name}
      noLabel={!props.label}
      error={meta.error}
      value={field.value}
      touched={meta.touched}
      onBlur={handleBlur}
      thousandSeparator
      decimalScale={maxDecimals}
      customInput={TextFieldControlled}
      onValueChange={handleChange}
      setValue={helpers.setValue}
      {...props}
    />
  );
};
