import { FC } from 'react';
import { CollapsiblePane } from '@instech/components';
import { ApplicationRole, Invoice } from '@/types';
import { ReadonlyCollapsiblePane } from '@/common/components/Pane';
import { INVOICE_PANE_MARGIN } from '@/utils/style/styleConstants';
import { checkIfOwnerOrBroker } from '@/utils/user';
import { OwnersCommentForm } from './OwnersCommentForm';
import { OwnersCommentReadonly } from './OwnersCommentReadonly';

interface Props {
  invoice: Invoice;
  currentRole: ApplicationRole;
  isReadonly: boolean;
}
/**
 * If the user is the Owner and the invoice is for review (may be redundant but just in case),
 * show form for viewing and editing the owner's comment.
 */
export const OwnersCommentPane: FC<Props> = ({ invoice, currentRole, isReadonly }) => {
  const { isSharedForReview } = invoice;

  const isOwnerOrBroker = checkIfOwnerOrBroker(currentRole);
  const hasOwnersComment = !!invoice.ownersReviewComment;

  // Never show if not shared for review
  if (!isSharedForReview) {
    return null;
  }

  // If owner or broker can edit, show form
  if (isOwnerOrBroker && !isReadonly) {
    return (
      <CollapsiblePane title="Comment on Claim Review" color="green" margin={INVOICE_PANE_MARGIN} padding="20px">
        <OwnersCommentForm invoice={invoice} />
      </CollapsiblePane>
    );
  }

  // If user cannot edit but there is content, show pane
  if (hasOwnersComment) {
    return (
      <ReadonlyCollapsiblePane title="Owner's Comment" color="green" margin={INVOICE_PANE_MARGIN} padding="20px">
        <OwnersCommentReadonly invoice={invoice} />
      </ReadonlyCollapsiblePane>
    );
  }

  // Nothing to edit and nothing to show
  return null;
};
