import { useSharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { TableCell } from '@instech/components';
import { Comment } from '@instech/icons';
import { FC } from 'react';
import styled from 'styled-components';
import { TableCellHoverStyles } from '@/common/components/Table/core/TableCell/TableCellHoverStyles';

const StyledCell = styled(TableCell)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  ${TableCellHoverStyles};
`;

const StyledDraftComment = styled(Comment)`
  padding: 8px;
  path {
    fill: white;
    stroke: ${props => props.theme.marineBlue};
    stroke-width: 2;
  }
`;

const StyledComment = styled(Comment)`
  padding: 8px;
`;

const tooltipText = 'Submitted Comment';
const tooltipTextDraft = 'Draft Comment';

interface Props {
  showComment?: boolean;
  showDraftComment?: boolean;
}
export const InvoiceReviewCommentCell: FC<Props> = ({ showComment, showDraftComment, ...props }) => {
  const { tooltipId } = useSharedTooltip();

  return (
    <StyledCell {...props}>
      {(showComment || showDraftComment) && (
        showComment ?
          <StyledComment data-for={tooltipId} data-tip={tooltipText} data-testid="styled-comment" /> :
          <StyledDraftComment data-for={tooltipId} data-tip={tooltipTextDraft} data-testid="styled-draft-comment" />
      )}
    </StyledCell>
  );
};
