import styled from 'styled-components';

const FileTable = styled.table`
  color: ${props => props.theme.marineBlue};
  width: 100%;
  border-collapse: collapse;

  thead {
    th {
      font-weight: bold;
      color: ${props => props.theme.marineBlue};
      border-bottom: 1px solid ${props => props.theme.marineBlue};
      padding: 16px 0;
      text-align: start;
    }

    th:first-child {
      padding-left: 16px;
    }

    th:last-child {
      text-align: center;
    }
  }

  tbody {
    td {
      padding-top: 16px;
    }

    tr td:first-child {
      padding-left: 16px;
    }

    a {
      text-decoration: underline;
    }
  }
`;

export default FileTable;
