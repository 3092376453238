import { FormikValues, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { deepEqual } from '@/utils/array';

/**
 * Auto save that uses a debounced submit form method when the form has unsaved changes
 * and the user has stopped typing for a certain interval.
 */
const useFormikAutosave = <T extends FormikValues>(autosaveInterval: number) => {
  const { submitForm, touched, initialValues, values, resetForm } = useFormikContext<T>();
  const debouncedSubmitForm = useMemo(() => debounce(submitForm, autosaveInterval), [autosaveInterval, submitForm]);

  useEffect(() => {
    if (touched && initialValues !== undefined && !deepEqual(values, initialValues)) {
      void debouncedSubmitForm();
      resetForm({ values });
    }
  }, [debouncedSubmitForm, initialValues, resetForm, touched, values]);
};

export default useFormikAutosave;
