import { useConsiderationContext } from '@/components/pages/invoice/core/EditInvoice/InvoiceForm/utils/ConsiderationProvider';
import { InvoiceRecord, SurveyorsConsiderationSplitForm } from '@/types';
import { arrayOfObjectsToSingle } from '@/utils/array';
import { getDeviationInCurrency } from '@/utils/invoice/invoiceDeviationText';
import { getSurveyorsDeviation } from '@/utils/invoice/invoiceTotalDeviation';
import { FunctionComponent as FC } from 'react';
import { Deviation } from './Deviation';
import getCurrentValue from './getCurrentValue';

interface ReduceConsiderationReturn {
  adjustersConsideration: number;
  claimConsideration: number;
  generalExpenses: number;
  ownersWorkConsideration: number;
}
const reduceConsiderationArray = (consideration: SurveyorsConsiderationSplitForm[]) => {
  const valsToReduce: ReduceConsiderationReturn[] = consideration.map(item => ({
    adjustersConsideration: Number(item.adjustersConsideration ?? 0),
    claimConsideration: getCurrentValue(item.claimConsideration, item.initialClaim, item.form.claimConsidered ?? false),
    generalExpenses: Number(item.generalExpenses ?? 0),
    ownersWorkConsideration: getCurrentValue(item.ownersWorkConsideration, item.initialOwnersWork, item.form.ownersWorkConsidered ?? false)
  }));
  return arrayOfObjectsToSingle(valsToReduce);
};

interface DeviationLineProps {
  selectedCurrency: string;
  invoice: InvoiceRecord;
  className?: string;
}
/**
 * Deviation display for Surveyor role. Implements Deviation components and
 * does calculations from dataset based on fields that surveyor can edit.
 */
export const DeviationSurveyor: FC<DeviationLineProps> = ({ selectedCurrency, invoice, className }) => {
  const { getConsiderationData } = useConsiderationContext();
  const surveyorsConsiderationSplit = getConsiderationData<SurveyorsConsiderationSplitForm[]>();

  let deviationInCurrency = { number: 0, string: '' };
  if (surveyorsConsiderationSplit) {
    // Map array of surveyors' consideration objects to single set
    const flatVals = reduceConsiderationArray(surveyorsConsiderationSplit);

    const deviation = getSurveyorsDeviation({
      adjustersConsideration: flatVals.adjustersConsideration,
      claimConsideration: flatVals.claimConsideration,
      generalExpenses: flatVals.generalExpenses,
      ownersWorkConsideration: flatVals.ownersWorkConsideration,
      totalInvoiceAmount: Number(invoice.totalAmount),
    });

    const { currency, exchangeRate } = invoice;
    deviationInCurrency = getDeviationInCurrency(deviation, currency, selectedCurrency, exchangeRate);
  }

  if (deviationInCurrency.number === 0) {
    return null;
  }

  return (
    <Deviation
      currency={selectedCurrency}
      deviation={deviationInCurrency.string}
      className={className}
      isNumber
    />
  );
};
