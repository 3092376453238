import { OwnersReviewComment, OwnersReviewCommentResponse } from '@/services/invoice/invoiceServices/updateOwnersReviewComment';
import { ButtonGroup, SlimButton } from '@instech/components';
import { Approved } from '@instech/icons';
import { useFormikContext } from 'formik';
import {
  FC, useEffect, useState
} from 'react';

interface CommentActionsProps {
  submitValues: (values: OwnersReviewComment) => Promise<OwnersReviewCommentResponse>;
}

export const CommentActionsButtonGroup: FC<CommentActionsProps> = ({ submitValues }) => {
  const { values, resetForm, initialValues, isSubmitting } = useFormikContext<OwnersReviewComment>();
  const [saveDraftButtonEnabled, setSaveDraftButtonEnabled] = useState<boolean>(false);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState<boolean>(false);

  useEffect(() => {
    const isDraftUpdated = values.ownersReviewDraftComment !== initialValues?.ownersReviewDraftComment;
    setSaveDraftButtonEnabled(isDraftUpdated);

    const isSubmittedCommentOudated = values.ownersReviewDraftComment !== initialValues?.ownersReviewComment;
    setSubmitButtonEnabled(isSubmittedCommentOudated);
  }, [values, initialValues]);

  const saveDraft = async () => {
    const valuesToSave = { ownersReviewComment: values.ownersReviewComment,
      ownersReviewDraftComment: values.ownersReviewDraftComment };

    setSaveDraftButtonEnabled(false);
    const result = await submitValues(valuesToSave);
    if (result) {
      resetForm({ values: { ...result.values } });
    }
  };

  const updateAndSubmit = async () => {
    const valuesToSave = { ownersReviewComment: values.ownersReviewDraftComment,
      ownersReviewDraftComment: values.ownersReviewDraftComment };
    setSubmitButtonEnabled(false);

    const result = await submitValues(valuesToSave);
    if (result) {
      resetForm({ values: { ...result.values } });
    }
  };

  const saveDraftDisabled = !saveDraftButtonEnabled || isSubmitting;
  const submitDisabled = !submitButtonEnabled || isSubmitting;

  const submitDisabledIcon = saveDraftDisabled ? <Approved /> : undefined;
  const saveDraftDisabledIcon = submitDisabled ? <Approved /> : undefined;

  return (
    <ButtonGroup alignRight marginTop="12px">
      <SlimButton endIcon={submitDisabledIcon} variant="secondary" onClick={saveDraft} disabled={saveDraftDisabled}>SAVE DRAFT</SlimButton>
      <SlimButton endIcon={saveDraftDisabledIcon} onClick={updateAndSubmit} disabled={submitDisabled}>UPDATE AND SUBMIT</SlimButton>
    </ButtonGroup>
  );
};
