import {
  useCallback, useEffect, useState
} from 'react';

export type ExpandedRows = {
  [invoiceId: string]: boolean
};

const dictionaryFromIds = (ids: string[], defaultValue: boolean = false): ExpandedRows => (
  ids.reduce((map, id) => ({ ...map, [id]: defaultValue }), {})
);

/**
 * Hook for tables that provides useful state management and functions
 * for expanding and collapsing rows in the table to show subrows.
 */
export const useExpandedTableRows = (tableRowIds: string[]) => {
  const [expanded, setExpanded] = useState<ExpandedRows>(dictionaryFromIds(tableRowIds));
  const [allRowsExpanded, setExpandAllRows] = useState(false);

  const toggleExpanded = useCallback((id: string) => {
    setExpanded({ ...expanded, [id]: !expanded[id] });
  }, [expanded]);

  const toggleExpandAllRows = useCallback(() => {
    setExpandAllRows(!allRowsExpanded);
    setExpanded(dictionaryFromIds(tableRowIds, !allRowsExpanded));
  }, [allRowsExpanded, tableRowIds]);

  useEffect(() => {
    if (allRowsExpanded && Object.values(expanded).some(value => !value)) {
      setExpandAllRows(false);
    }
  }, [allRowsExpanded, expanded]);

  return { expanded, toggleExpanded, allRowsExpanded, toggleExpandAllRows };
};
