import { useModalContext } from '@/common/modules/Modal';
import { replaceStatusToContentMap } from '@/components/pages/invoice/core/modal/ModalContentComponents';
import { FileValidationError } from '@/utils/file';
import { ButtonGroup, SlimButton } from '@instech/components';
import { FunctionComponent as FC } from 'react';

interface InvalidFileProps {
  validationError: FileValidationError;
}
export const InvalidFileError: FC<InvalidFileProps> = ({ validationError }) => {
  const { close } = useModalContext();
  return (
    <div>
      {replaceStatusToContentMap[validationError]}
      <ButtonGroup alignRight>
        <SlimButton onClick={close}>CLOSE</SlimButton>
      </ButtonGroup>
    </div>
  );
};
