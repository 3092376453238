import {
  FilePdf, FileJpg, FilePng
} from '@instech/icons';
import styled from 'styled-components';

const SubTextSecondary = styled.div`
  color: ${props => props.theme.marineBlue60};
  margin-bottom: 24px;
`;

const IconArea = styled.div`
  display: flex;
  color: ${props => props.theme.marineBlue60};
  justify-content: space-around;
  margin: 0px 20px;
  transition: color 0.1s ease-in;
`;

export const SupportedFormat = () => (
  <>
    <SubTextSecondary>Supported formats:</SubTextSecondary>
    <IconArea>
      <FilePdf />
      <FileJpg />
      <FilePng />
    </IconArea>
  </>
);
