import { CollapsibleTableSchemaReturn } from '@/common/components/Table/CollapsibleTable/types';
import { InvoiceOverviewSurveyor } from '@/types';
import { MenuOption } from '@instech/components';
import { ExpandedRows } from '@/common/components/Table/utils';
import { surveyorTableHeaders, surveyorTableSubheaders } from './tableSchemaHeaders';
import { surveyorTableRow } from './tableSchemaRows';
import { tableSchemaSubrow } from './tableSchemaSubrow';

export const tableSchemaSurveyor = (
  data: InvoiceOverviewSurveyor,
  onRowClick: (invoiceId: string) => void,
  selectableRows: boolean,
  toggleExpanded: (id: string) => void,
  expanded: ExpandedRows,
  contextMenuOptions?: (invoiceId: string) => MenuOption[],
  showFullComments?: boolean
): CollapsibleTableSchemaReturn => ({
  layout: '64px auto repeat(11, auto) 64px',
  subrowsHeaderText: 'Splits',
  selectableRows,
  header: surveyorTableHeaders(selectableRows),
  tableRows: data.invoices.map(invoice => ({
    row: surveyorTableRow(invoice, onRowClick, selectableRows, toggleExpanded, expanded, contextMenuOptions),
    subrows: invoice.surveyorsConsideration?.splits.map(
      split => tableSchemaSubrow(split, selectableRows)
    )
  })),
  collapseButtons: [],
  subheader: surveyorTableSubheaders(selectableRows),
  minSubrowsToExpand: 2
});
