import {
  Breadcrumbs, breadcrumbVesselName, reusableSteps
} from '@/common/components/Breadcrumbs';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { PageContent } from '@/common/layout/Page';
import { HeadingWrapper, StickyPageHeading } from '@/common/layout/PageLayout';
import { useTypedModal } from '@/common/modules/Modal';
import { useFeatureFlag } from '@/common/providers/featureFlagProvider';
import { shareDownloadLinkModal } from '@/components/pages/claim/ClaimStatementPage/modal/ShareDownloadLinkModal';
import { ClosedClaimStatementWarning } from '@/components/shared/Warnings/ClosedClaimStatementWarning';
import { useClaimStatement } from '@/services/claimStatementServices';
import { useInvoicesSummary } from '@/services/invoice/invoiceServices';
import { updateInvoiceState } from '@/services/invoice/invoiceStateService';
import { invoiceStates } from '@/utils/constants';
import { useRequireClaimStatementId } from '@/utils/useRequireClaimStatementId';
import { PageLoader } from '@instech/components';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useSWRConfig } from 'swr';
import { ClaimDetails } from '../core/ClaimDetails';
import { ContactsPane } from './ContactsPane/ContactsPane';
import { InvoiceTableClaimsHandler } from './InvoiceTable';
import { ShareButtons } from './ShareButtons';
import { SummaryPane } from './SummaryPane/SummaryPane';
import { exportClaimStatementModal } from './modal/ExportClaimStatementModal';
import { useAggregatedClaimsHandlerInvoicesOverview } from './utils/useAggregatedInvoices';

const PageGrid = styled.div<{ enableClaimsHandlerView?: boolean }>`
  display: grid;
  ${props => props.enableClaimsHandlerView && css`
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
    "details summary contacts";
  `};
  ${props => !props.enableClaimsHandlerView && css`
  grid-template-columns: auto 520px;
    grid-template-areas:
      "details contacts";
  `};
  column-gap: 48px;
  row-gap: 40px;
`;

const GridPlacement = styled.div<{ area?: string }>`
  grid-area: ${props => props.area};
`;

export interface RefreshCommands {
  refreshInvoiceOverview: boolean;
  refreshSummary: boolean;
}

export const ClaimsHandlerClaimStatementPage = () => {
  const claimStatementId = useRequireClaimStatementId();
  const { data } = useClaimStatement(claimStatementId);
  const { cache } = useSWRConfig();
  const { data: summary, mutate: refreshSummary } = useInvoicesSummary(claimStatementId);
  const { data: overview, mutate: refreshInvoiceOverview } = useAggregatedClaimsHandlerInvoicesOverview(claimStatementId);
  const navigate = useNavigate();

  const enableClaimsHandlerView = useFeatureFlag('claimsHandlerView');
  const canCloseClaim = useFeatureFlag('canCloseClaim');

  const navigateToInvoice = (invoiceId: string) => {
    navigate(`invoices/${invoiceId}`);
  };
  const handleStateTransferSuccess = async (refreshCommand: RefreshCommands = { refreshInvoiceOverview: true, refreshSummary: true }) => {
    if (refreshCommand.refreshInvoiceOverview) {
      await refreshInvoiceOverview();
    }
    if (refreshCommand.refreshSummary) {
      await refreshSummary();
    }
  };

  const transferInvoicesToSurveyor = async (invoicesToTransfer: string[]) => {
    await updateInvoiceState(claimStatementId, {
      destinationState: invoiceStates.CostAllocation,
      invoiceIdList: invoicesToTransfer
    }, cache);
  };
  const { open: openExportClaimStatementModal } = useTypedModal(exportClaimStatementModal({ claimStatementId }));

  const { open: openShareDownloadLinkModal } = useTypedModal(shareDownloadLinkModal({ claimStatementId, vesselName: data?.vesselName }));

  if (!data || !summary || !overview) return <PageLoader />;

  const hasInvoices = overview.invoices?.length > 0;
  const showClosedWarning = canCloseClaim && data?.isClosed;

  const breadcrumbSteps = [reusableSteps.myDashboardStep];
  const currentPage = breadcrumbVesselName(data.vesselName, data.isExperimental);

  return (
    <div>
      <SharedTooltip id="invoice-page-tooltip">
        <StickyPageHeading>
          <HeadingWrapper>
            <Breadcrumbs
              steps={breadcrumbSteps}
              current={currentPage}
            />
            <ShareButtons
              openExportModal={openExportClaimStatementModal}
              openShareDownloadLinkModal={openShareDownloadLinkModal}
              downloadLinkDisabled={!hasInvoices}
            />
          </HeadingWrapper>
        </StickyPageHeading>
        <PageContent noTopPadding>
          {showClosedWarning && <ClosedClaimStatementWarning />}
          <PageGrid enableClaimsHandlerView={enableClaimsHandlerView}>
            <GridPlacement area="details">
              <ClaimDetails data={data} />
            </GridPlacement>
            {enableClaimsHandlerView && (
              <GridPlacement area="summary">
                <SummaryPane summary={summary} claimStatementSettings={data.claimStatementSettings} />
              </GridPlacement>
            )}
            <GridPlacement area="contacts">
              <ContactsPane data={data} />
            </GridPlacement>
          </PageGrid>
          {enableClaimsHandlerView && (
            <InvoiceTableClaimsHandler
              data={overview}
              showTable={hasInvoices}
              claimStatementSettings={data.claimStatementSettings}
              onRowClick={navigateToInvoice}
              // onSubmit={transferInvoicesToSurveyor}
              onInvoicesUpdateSuccess={handleStateTransferSuccess}
            />
          )}
        </PageContent>
      </SharedTooltip>
    </div>
  );
};
