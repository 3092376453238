import { convertCurrency } from '@/utils/invoice/convertCurrency';
import { CurrencyFieldControlled } from '@instech/components';
import { useField } from 'formik';
import { FunctionComponent as FC } from 'react';
import {
  adjusterCommonExpensesTags, adjusterOwnersWorkTags, adjusterParticularAverageTags
} from '@/types';
import { useSharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { HighlightedFieldWithTags } from '@/common/components/Form/FieldWithTags';
import { HighlightedNumericalField } from '@/components/pages/invoice/core/EditInvoice/core/HighlightedNumericalField';
import { FormRow } from '../../core/Components';

interface CostFieldProps {
  arrayName: string;
  index: number;
  exchangeRate?: number | null;
  useInvoiceCurrency?: boolean;
  showGeneralAverage: boolean;
}
export const AdjustersCostFields: FC<CostFieldProps> = ({ arrayName, index, exchangeRate, useInvoiceCurrency, showGeneralAverage }) => {
  const [generalAverage] = useField(`splits.${index}.generalAverage`);
  const { tooltipId } = useSharedTooltip();

  if (useInvoiceCurrency) {
    return (
      <FormRow>
        <HighlightedFieldWithTags
          label="Common Expenses"
          name={`${arrayName}.${index}.commonExpenses`}
          initialFieldName={`${arrayName}.${index}.initialCommonExpenses`}
          isConsideredFieldName={`${arrayName}.${index}.form.commonExpensesConsidered`}
          limitValidInput
          tagTooltipId={tooltipId}
          tagFieldName={`${arrayName}.${index}.commonExpensesTag`}
          tagOptions={adjusterCommonExpensesTags} />
        {showGeneralAverage && (
          <HighlightedNumericalField
            label="General Average"
            name={`${arrayName}.${index}.generalAverage`}
            initialFieldName={`${arrayName}.${index}.initialGeneralAverage`}
            isConsideredFieldName={`${arrayName}.${index}.form.generalAverageConsidered`}
          />
        )}
        <HighlightedFieldWithTags
          label="Particular Average"
          name={`${arrayName}.${index}.particularAverage`}
          initialFieldName={`${arrayName}.${index}.initialParticularAverage`}
          isConsideredFieldName={`${arrayName}.${index}.form.particularAvgConsidered`}
          limitValidInput
          tagTooltipId={tooltipId}
          tagFieldName={`${arrayName}.${index}.particularAverageTag`}
          tagOptions={adjusterParticularAverageTags} />
        <HighlightedFieldWithTags
          label="Remainder"
          name={`${arrayName}.${index}.ownersWorkConsideration`}
          initialFieldName={`${arrayName}.${index}.initialOwnersWork`}
          isConsideredFieldName={`${arrayName}.${index}.form.ownersWorkConsidered`}
          limitValidInput
          tagTooltipId={tooltipId}
          tagFieldName={`${arrayName}.${index}.ownersWorkConsiderationTag`}
          tagOptions={adjusterOwnersWorkTags} />
      </FormRow>
    );
  }

  return (
    <FormRow>
      <HighlightedFieldWithTags
        label="Common Expenses"
        name={`${arrayName}.${index}.commonExpenses`}
        initialFieldName={`${arrayName}.${index}.initialCommonExpenses`}
        isConsideredFieldName={`${arrayName}.${index}.form.commonExpensesConsidered`}
        convertRate={exchangeRate}
        emptyWithoutConvertRate
        disabled
        tagTooltipId={tooltipId}
        tagFieldName={`${arrayName}.${index}.commonExpensesTag`}
        tagOptions={[]} />
      {showGeneralAverage && (
        <CurrencyFieldControlled
          label="General Average"
          name={`${arrayName}.${index}.generalExpenses`}
          value={convertCurrency(generalAverage.value, exchangeRate, !!exchangeRate, true)}
          placeholder=""
          disabled
        />
      )}
      <HighlightedFieldWithTags
        label="Particular Average"
        name={`${arrayName}.${index}.particularAverage`}
        initialFieldName={`${arrayName}.${index}.initialParticularAverage`}
        isConsideredFieldName={`${arrayName}.${index}.form.particularAvgConsidered`}
        convertRate={exchangeRate}
        emptyWithoutConvertRate
        disabled
        tagTooltipId={tooltipId}
        tagFieldName={`${arrayName}.${index}.particularAverageTag`}
        tagOptions={[]} />
      <HighlightedFieldWithTags
        label="Remainder"
        name={`${arrayName}.${index}.ownersWorkConsideration`}
        initialFieldName={`${arrayName}.${index}.initialOwnersWork`}
        isConsideredFieldName={`${arrayName}.${index}.form.ownersWorkConsidered`}
        convertRate={exchangeRate}
        emptyWithoutConvertRate
        disabled
        tagTooltipId={tooltipId}
        tagFieldName={`${arrayName}.${index}.ownersWorkConsiderationTag`}
        tagOptions={[]} />
    </FormRow>
  );
};
