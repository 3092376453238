import {
  UpdateAdjustersConsiderationForm, UpdateInvoiceForm, UpdateSurveyorsConsiderationForm
} from '@/types';
import { useFormikContext } from 'formik';
import {
  Dispatch, SetStateAction, useEffect, useState
} from 'react';
import { SaveButtonStatus } from './types';

type UpdateConsiderationForm = UpdateSurveyorsConsiderationForm | UpdateAdjustersConsiderationForm | UpdateInvoiceForm;

/**
 * Save status handler for use within a single component.
 * Use SaveStatusContext if the status needs to be shared between multiple
 * sibling components.
 */
export const useSaveStatus = <T extends UpdateConsiderationForm>(): [SaveButtonStatus, Dispatch<SetStateAction<SaveButtonStatus>>] => {
  const [saveStatus, setSaveStatus] = useState<SaveButtonStatus>('saved');
  const { dirty } = useFormikContext<T>();

  useEffect(() => {
    if (dirty && saveStatus === 'saved') {
      setSaveStatus('unsaved');
    }
  }, [dirty, saveStatus]);

  useEffect(() => {
    if (!dirty && saveStatus === 'unsaved') {
      setSaveStatus('saved');
    }
  }, [dirty, saveStatus]);

  return [saveStatus, setSaveStatus];
};
