import { Column } from '@tanstack/react-table';

/**
 * Return a merge of meta props from colum and column parent, preferring
 * values that are defined in the column directly and deferring otherwise.
 */
export const getMergedMeta = (column: Column<any, unknown>) => {
  const columnMeta = column.columnDef.meta ?? {};
  const parentMeta = column.parent?.columnDef.meta ?? {};
  return { ...parentMeta, ...columnMeta };
};

/**
 * Returns the ID of the column's parent, otherwise nothing. This value
 * can be used in a table cell to bind the cell to the group of headers that
 * the table column belongs to.
 */
export const getHeaderGroup = (column: Column<any, unknown>) => (
  column.parent?.id ?? undefined
);
