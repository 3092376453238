import React, { FC } from 'react';
import { IntrinsicSpanProps } from '@/types';
import { CollapsibleRowSegment } from '../CollapsibleTable/types';
import { TableRowSegment } from '../SortableTable/types';
import {
  TableCellText,
  TableCellComment,
  TableCellTextTooltip,
  TableCellDigitsTooltip,
  TableCellReview,
  TableCellState,
  TableCellMaxContent
} from './TableCell';
import { useRowProps } from './TableRow/RowPropsProvider';
import { rowCells } from '../types';

interface Props extends IntrinsicSpanProps {
  segment: CollapsibleRowSegment | TableRowSegment;
}
/**
 * Maps out a given RowSegment type to a corresponding cell component, based
 * on the provided `cellType`. Any properties to be propagated down to the cell
* can be provided via `restProps`
 */
export const TableRowCell: FC<Props> = ({ segment, ...restProps }) => {
  const { tooltipId, showFullComments } = useRowProps();
  const { value } = segment;

  if (segment.shouldSkip) {
    return null;
  }
  switch (segment.cellType) {
    case rowCells.text:
      return (
        <TableCellText {...restProps} value={value} />
      );
    case rowCells.comment:
      return (
        <TableCellComment {...restProps} comment={value} showFullComment={showFullComments} tooltipId={tooltipId} />
      );
    case rowCells.textTooltip:
      return (
        <TableCellTextTooltip {...restProps} value={value} tooltipId={tooltipId} />
      );
    case rowCells.digitsTooltip:
      return (
        <TableCellDigitsTooltip {...restProps} value={value} tooltipId={tooltipId} />
      );
    case rowCells.review:
      return (
        <TableCellReview {...restProps} sharedForReview={value} />
      );
    case rowCells.state:
      return (
        <TableCellState {...restProps} state={value} />
      );
    case rowCells.gap:
      return (
        <TableCellMaxContent {...restProps} />
      );
    case rowCells.custom:
      return <>{value}</>;
    case rowCells.customWithProps:
      return React.Children.map(value, child => React.cloneElement(child, { ...restProps }));
    default:
      return null;
  }
};
