import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorView, ErrorViewContent } from './core/ErrorView';
import { ErrorCodes } from './core/ErrorCodes';

export const ErrorPage = ({ reset }: { reset: () => void; }) => {
  const location = useLocation();
  const [prevLocation] = useState(location);

  if (location !== prevLocation) {
    reset(); // Resets error boundary if user navigates away from page
  }
  return (
    <ErrorView
        type={ErrorCodes.notAllowed}
        title="Oh no..."
        message="Something went wrong here. It's not you. It's us."
    >
      <ErrorViewContent reset={reset} />
    </ErrorView>
  );
};
