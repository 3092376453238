import { FC, useState } from 'react';
import styled from 'styled-components';
import { ModalType } from '@/common/modules/Modal/types';
import { ButtonGroup, SlimButton } from '@instech/components';
import { useModalContext } from '@/common/modules/Modal';
import { WarningFilled } from '@instech/icons';
import { ContentHorizontalCenter } from '@/common/layout/Content';
import { BoldText, EmphasizedText } from './core/Components';

const StyledWarning = styled(EmphasizedText)`
  margin-top: 16px;
  width: 400px;
  & > svg {
    width: 30px;
  }
`;

interface WarningProps {
  invoiceCount: number;
}

interface WarningText extends WarningProps {
  text: string;
}

const Warning = ({ invoiceCount, text }: WarningText) => {
  if (invoiceCount === 0) {
    return null;
  }
  return (
    <StyledWarning>
      <WarningFilled />
      <div>{text}</div>
    </StyledWarning>
  );
};

const DeviationsWarning: FC<WarningProps> = ({ invoiceCount }) => {
  const multiple = invoiceCount > 1;
  const warningText = multiple
    ? `${invoiceCount} invoices have deviations from 'Total Amount' and will not be marked as finished.`
    : '1 invoice has a deviation from \'Total Amount\' and will not be marked as finished.';

  return <Warning invoiceCount={invoiceCount} text={warningText} />;
};

const MissingInfoWarning: FC<WarningProps> = ({ invoiceCount }) => {
  const multiple = invoiceCount > 1;
  const warningText = multiple
    ? `${invoiceCount} invoices are missing required information and will not be marked as finished.`
    : '1 invoice is missing required information and will not be marked as finished.';

  return <Warning invoiceCount={invoiceCount} text={warningText} />;
};

interface Props {
  totalInvoiceCount: number;
  completeInvoiceCount: number;
  deviatingInvoiceCount: number;
  incompleteInvoiceCount: number;
  finishInvoices: () => Promise<void>;
}

const ModalContent = ({
  totalInvoiceCount,
  completeInvoiceCount,
  deviatingInvoiceCount,
  incompleteInvoiceCount,
  finishInvoices
}: Props) => {
  const { close } = useModalContext();
  const [loading, setLoading] = useState(false);

  const disableFinish = completeInvoiceCount === 0;

  const markAsFinished = async () => {
    setLoading(true);
    await finishInvoices();
    close();
  };

  const title = `${completeInvoiceCount} of ${totalInvoiceCount} ${totalInvoiceCount > 1 ? 'invoices' : 'invoice'} will be marked as finished.`;
  return (
    <>
      <ContentHorizontalCenter>
        <BoldText>{title}</BoldText>
        <DeviationsWarning invoiceCount={deviatingInvoiceCount} />
        <MissingInfoWarning invoiceCount={incompleteInvoiceCount} />
      </ContentHorizontalCenter>
      <ButtonGroup alignRight marginTop="24px">
        <SlimButton variant="secondary" onClick={close}>CANCEL</SlimButton>
        <SlimButton disabled={disableFinish} onClick={markAsFinished} loading={loading}>MARK AS FINISHED</SlimButton>
      </ButtonGroup>
    </>
  );
};

export const defaultMarkAsFinshedProps: Props = {
  totalInvoiceCount: 0,
  completeInvoiceCount: 0,
  deviatingInvoiceCount: 0,
  incompleteInvoiceCount: 0,
  finishInvoices: () => Promise.resolve()
};

export const markAsFinishedModal = (
  {
    totalInvoiceCount,
    completeInvoiceCount,
    deviatingInvoiceCount,
    incompleteInvoiceCount,
    finishInvoices
  }: Props
): ModalType<Props> => ({
  component: ModalContent,
  options: {
    title: 'Mark as Finished',
    size: 'small',
    padding: '12px 20px 20px'
  },
  args: {
    totalInvoiceCount,
    completeInvoiceCount,
    deviatingInvoiceCount,
    incompleteInvoiceCount,
    finishInvoices
  }
});
