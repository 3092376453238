import { useTypedModal } from '@/common/modules/Modal';
import { FitToContent } from '@/common/layout/FitToContent';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { Guid, InvoiceOverview } from '@/types';
import {
  ComplexTable, MenuOption, Pane, SortedTableHeader
} from '@instech/components';
import {
  FunctionComponent, useEffect, useMemo, useState
} from 'react';
import { useSortedInvoiceData } from '@/utils/invoice/sortInvoiceData';
import { COLLAPSED_HEADER_HEIGHT, PAGE_HEADING_HEIGHT } from '@/utils/constants';
import { getTraversalIds, useTraversalOrderContext } from '../core/TraversalContext';
import { defaultTransferOwnerToClaimsHandlerProps, transferOwnerToClaimsHandlerModal } from '../modal/TransferOwnerToClaimsHandlerModal';
import { InvoiceButtons, SubmittedInvoiceButtons } from './core/InvoiceButtons';
import { InvoiceSummary } from './core/InvoiceSummary';
import { InvoiceTableHeaders } from './core/InvoiceTableHeaders';
import { InvoiceTableRows } from './core/InvoiceTableRows';
import { InvoiceTableSubheaders } from './core/InvoiceTableSubheaders';
import { markOwnersWorkCompleteModal } from '../modal/MarkOwnersWorkCompleteModal';
import { TableHeaderButtons, TableHeaderText } from '../core/Components';

// Calculate the stickyTop offsets of the different table elements, based on the height of the elements
const getStickyTopValues = (selectableRows: boolean) => {
  const baseGapHeight = COLLAPSED_HEADER_HEIGHT + PAGE_HEADING_HEIGHT;
  const buttonHeight = selectableRows ? 68 : 0;
  const subheaderHeight = 33;

  const buttonsStickyTop = baseGapHeight;
  const subheaderStickyTop = buttonsStickyTop + buttonHeight;
  const headerStickyTop = subheaderStickyTop + subheaderHeight;

  return {
    buttons: `${buttonsStickyTop}px`,
    subheader: `${subheaderStickyTop}px`,
    header: `${headerStickyTop}px`
  };
};

interface Props {
  title: string;
  data: InvoiceOverview;
  onClick: (invoiceId: string) => void;
  selectableRows?: boolean;
  onSubmit?: (invoicesToTransfer: string[]) => void;
  allowEmpty?: boolean;
  contextMenuOptions?: (invoiceId: Guid) => MenuOption[];
  onMarkAsComplete?: () => void;
  ownersWorkComplete?: boolean;
  isClaimStatementClosed: boolean;
  submittedTable?: boolean;
}
/**
 * Invoice table for the Owner, separate from the same table shown
 * for the Claims Handler.
 */
export const InvoiceTableOwner: FunctionComponent<Props> = ({
  data,
  onClick,
  title,
  onSubmit,
  selectableRows = true,
  allowEmpty = false,
  contextMenuOptions = () => [],
  onMarkAsComplete,
  ownersWorkComplete,
  isClaimStatementClosed,
  submittedTable
}) => {
  const [sortedHeader, setSortedHeader] = useState<SortedTableHeader>();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const { setCurrentTraversalOrder } = useTraversalOrderContext();

  // This could be a feature of the table itself, but right now it
  // has to be implemented manually outside of the table components
  const sortedTable = useSortedInvoiceData(data, sortedHeader);

  const handleSortedHeader = (header: SortedTableHeader) => {
    setSortedHeader(header);
  };

  // Deselect rows if they are somehow removed from the table,
  // e.g transferred or deleted thorugh the context menu
  useEffect(() => {
    const invoices = data.invoices.map(invoice => invoice.id);
    const selectionSubset = selectedRows.filter(invoiceId => invoices.includes(invoiceId));

    if (selectionSubset.length !== selectedRows.length) {
      setSelectedRows(selectionSubset);
    }
  }, [data, selectedRows]);

  // Deselect all rows if any rows are selected, or
  // select all rows if no rows are selected.
  const handleSelectAll = () => {
    if (selectedRows.length > 0) {
      setSelectedRows([]);
    } else {
      const allRowIds = data.invoices.map(invoice => invoice.id);
      setSelectedRows([...allRowIds]);
    }
  };

  // Toggle a row's ID into or out of the array of selected row IDs
  const handleSelectRow = (rowId: string) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter(id => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const transferInvoices = async () => {
    if (onSubmit) {
      const validInvoiceIds = data.invoices
        .filter(i => selectedRows.includes(i.id) && i.record.isComplete)
        .map(i => i.id);
      await onSubmit(validInvoiceIds);
      setSelectedRows([]);
    }
  };

  const handleOnOpen = () => {
    const selectedSortedIds = sortedTable.data.filter(x => selectedRows.some(y => y === x.id)).map(x => x.id);
    setCurrentTraversalOrder(selectedSortedIds);
    onClick(selectedSortedIds[0]);
  };

  const handleOnClick = (invoiceId: string) => {
    const traversalIds = getTraversalIds(sortedTable.data, selectedRows, invoiceId);
    setCurrentTraversalOrder(traversalIds, invoiceId);
    onClick(invoiceId);
  };

  const { open: openModal } = useTypedModal(transferOwnerToClaimsHandlerModal(defaultTransferOwnerToClaimsHandlerProps));

  const handleSubmit = async () => {
    const invoicesToSubmit = data.invoices
      .filter(x => selectedRows.includes(x.id));
    const incompleteInvoices = invoicesToSubmit.filter(x => !x.record.isComplete);

    openModal({ transfer: transferInvoices, numberOfIncompleteInvoices: incompleteInvoices.length, numberOfInvoices: invoicesToSubmit.length });
  };

  const { open: openMarkAsConpleteModal } = useTypedModal(markOwnersWorkCompleteModal({ markOwnersWorkComplete: () => { } }));

  const handleMarkAsComplete = () => {
    if (onMarkAsComplete) {
      openMarkAsConpleteModal({ markOwnersWorkComplete: onMarkAsComplete });
    }
  };

  const tableLayout = '64px repeat(13, auto) 64px';
  const stickyTop = useMemo(() => getStickyTopValues(selectableRows), [selectableRows]);

  const hasInvoices = data.invoices.length > 0;
  const canSelect = selectableRows && hasInvoices;
  const canMarkAsComplete = hasInvoices && !ownersWorkComplete && !isClaimStatementClosed;

  return (
    <FitToContent>
      <Pane title={title} color="green" padding="0px">
        <SharedTooltip id="invoice-table-tooltip">
          <TableHeaderText>
            <InvoiceSummary data={data} showIncomplete={selectableRows} />
            {hasInvoices && submittedTable && (
              <SubmittedInvoiceButtons canMarkAsComplete={canMarkAsComplete} onMarkAsComplete={handleMarkAsComplete} />
            )}
          </TableHeaderText>
          <TableHeaderButtons stickyTop={stickyTop.buttons}>
            {canSelect && (
              <InvoiceButtons
                selected={selectedRows.length}
                onOpen={handleOnOpen}
                onSubmit={handleSubmit}
                isClaimStatementClosed={isClaimStatementClosed} />
            )}
          </TableHeaderButtons>
          {hasInvoices && (
            <ComplexTable layout={tableLayout}>
              <InvoiceTableSubheaders
                currency={data.currency}
                stickyTop={stickyTop.subheader}
              />
              <InvoiceTableHeaders
                sortedHeader={sortedHeader}
                setSortedHeader={handleSortedHeader}
                allItems={data.invoices.length}
                selectedItems={selectedRows.length}
                handleSelectAll={handleSelectAll}
                policyCurrency={data.currency}
                stickyTop={stickyTop.header}
                selectableRows={selectableRows}
              />
              <InvoiceTableRows
                data={sortedTable.data}
                onClick={handleOnClick}
                selectedRows={selectedRows}
                onSelect={handleSelectRow}
                selectableRows={selectableRows}
                allowEmpty={allowEmpty}
                contextMenuOptions={contextMenuOptions}
              />
            </ComplexTable>
          )}
        </SharedTooltip>
      </Pane>
    </FitToContent>
  );
};
