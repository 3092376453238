import { DeviationSurveyor } from '@/apps/ClaimStatement/components/Deviation';
import {
  Invoice, SurveyorsConsiderationForm, SurveyorsConsiderationSplitForm
} from '@/types';
import { calculateSurveyorSummaryValues } from '@/utils/invoice/calculateSummaries';
import { LabelValuePair } from '@instech/components';
import { FieldArray, useFormikContext } from 'formik';
import { FunctionComponent as FC, useMemo } from 'react';
import styled from 'styled-components';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { useConsiderationContext } from '../InvoiceForm/utils/ConsiderationProvider';
import { SurveyorsConsiderationButtons } from './SurveyorsConsiderationButton';
import { newSurveyorsConsiderationItem } from './SurveyorsConsiderationPane';
import { SplitItemSurveyor } from './core/SplitItemSurveyor';
import { SplitTableRowSurveyor } from './core/SplitTableRowSurveyor';
import { SplitTableSurveyor } from './core/SplitTableSurveyor';
import { TotalLineSurveyorsConsideration } from './core/TotalLineSurveyorsConsideration';

const StyledDeviationSurveyor = styled(DeviationSurveyor)`
  margin-top: 2px;
`;

interface Props {
  invoice: Invoice;
  selectedCurrency?: string | null;
  categoryOptions: LabelValuePair[];
  occurrenceOptions: LabelValuePair[];
  updateSplitsOrder: (splitIdOrder: SurveyorsConsiderationSplitForm[]) => void;
}
export const ConsiderationFields: FC<Props> = ({ invoice, selectedCurrency, categoryOptions, occurrenceOptions, updateSplitsOrder }) => {
  const { values } = useFormikContext<SurveyorsConsiderationForm>();
  const { setConsiderationData } = useConsiderationContext();

  const invoiceRecord = invoice.record;
  const useInvoiceCurrency = selectedCurrency === invoiceRecord.currency;
  const isMultiple = values.splits.length > 1;

  const { adjustersConsiderationTotal, claimConsiderationTotal, generalExpensesTotal, ownersWorkConsiderationTotal, totalSum } = useMemo(
    () => calculateSurveyorSummaryValues(values.splits, invoiceRecord.exchangeRate, useInvoiceCurrency),
    [values, invoiceRecord.exchangeRate, useInvoiceCurrency]
  );

  return (
    <FieldArray
      name="splits"
      render={arrayHelpers => {
        const addNewSplit = () => {
          const prevItemIndex = values.splits.length - 1;
          const newItem = newSurveyorsConsiderationItem(occurrenceOptions[0], values.splits[prevItemIndex]);
          arrayHelpers.push(newItem);
        };
        const removeSplit = (index: number) => {
          arrayHelpers.remove(index);
          const splitsForConsideration = [...values.splits];
          splitsForConsideration.splice(index, 1);
          setConsiderationData(splitsForConsideration);
        };
        return (
          <>
            <SplitTableSurveyor isMultiple={isMultiple}>
              <SharedTooltip id="surveyor-cost-fields-tags-tooltip">
                {values.splits.map((split, index: number) => (
                  <SplitTableRowSurveyor
                    key={split.form.arrayId}
                    invoiceRecord={invoiceRecord}
                    consideration={split}
                    useInvoiceCurrency={useInvoiceCurrency}
                    startCollapsed={split.form.startCollapsed || index === 0}
                    isMultiple={isMultiple}>
                    <SplitItemSurveyor
                      arrayName="splits"
                      index={index}
                      invoiceRecord={invoiceRecord}
                      categoryOptions={categoryOptions}
                      occurrenceOptions={occurrenceOptions}
                      onAddSplit={addNewSplit}
                      onRemoveSplit={removeSplit}
                      selectedCurrency={selectedCurrency}
                      useInvoiceCurrency={useInvoiceCurrency}
                      isMultiple={isMultiple} />
                  </SplitTableRowSurveyor>
                ))}
                {isMultiple && (
                <TotalLineSurveyorsConsideration
                  adjustersConsideration={adjustersConsiderationTotal}
                  claim={claimConsiderationTotal}
                  generalExpenses={generalExpensesTotal}
                  ownersWork={ownersWorkConsiderationTotal}
                  sum={totalSum}
                />
                )}
              </SharedTooltip>
            </SplitTableSurveyor>
            {isMultiple && (
              <StyledDeviationSurveyor
                invoice={invoiceRecord}
                selectedCurrency={selectedCurrency || ''} />
            )}
            <SurveyorsConsiderationButtons
              onAddSplit={addNewSplit}
              isMultiple={isMultiple}
              reorderSurveyorSplitsProps={{
                splits: values.splits,
                updateSplitsOrder,
                exchangeRate: invoice.record.exchangeRate,
                usingInvoiceCurrency: useInvoiceCurrency
              }}
            />
          </>
        );
      }}
    />
  );
};
