import {
  ExtendedPageWrapper, HiddenInPrint, ShowInPrint
} from '@/common/layout/Page';
import { StickyPageContent } from '@/common/layout/StickyPage';
import {
  Breadcrumbs, breadcrumbVesselName, reusableSteps
} from '@/common/components/Breadcrumbs';
import { HeadingWrapper, StickyPageHeading } from '@/common/layout/PageLayout';
import {
  useClaimStatement,
  useReviewSummary
} from '@/services/claimStatementServices';
import { ClaimStatementSettings, InvoiceOverview } from '@/types';
import {
  ButtonGroup,
  PageLoader, Pane, SlimButton
} from '@instech/components';
import styled from 'styled-components';
import { FitToContent } from '@/common/layout/FitToContent';
import {
  Download, SendFilled, ExportPdf
} from '@instech/icons';
import { useCurrentRole } from '@/common/authentication/CurrentRoleContext';
import { useFeatureFlag } from '@/common/providers/featureFlagProvider';
import { useRequireClaimStatementId } from '@/utils/useRequireClaimStatementId';
import { useState } from 'react';
import { useTypedModal } from '@/common/modules/Modal';
import { ClaimDetails } from '../core/ClaimDetails';
import { InvoiceTableReview } from './InvoiceTable/InvoiceTableReview/InvoiceTableReview';
import { GridPlacement } from './StyledPageComponents';
import { SummaryPane } from './SummaryPane/SummaryPane';
import { exportAndDownload } from './modal/ExportClaimStatementModal';
import { useAggregatedClaimsHandlerInvoicesForReview } from './utils/useAggregatedInvoices';
import { SavePdfSidebar } from './SavePdfSidebar';
import { shareMagicLinkModal } from './modal/ShareMagicLinkModal';
import { VesselTitle } from '../core/ClaimDetails/VesselTitle';

const TempText = styled.div`
  text-align: center;
  font-style: italic;
  color: ${props => props.theme.marineBlue};
`;

const PageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
  "vessel summary";
  column-gap: 48px;
  row-gap: 40px;
  @media print {
    grid-template-columns: 800px 800px;
    grid-template-areas: "vessel summary";
    page-break-after: auto;
  }
`;

const FitTableArea = styled(FitToContent)`
  padding: 0 40px 40px;
  @media print {
    padding: 0;
  }
  box-sizing: border-box;
`;

interface Props {
  overview?: InvoiceOverview;
  claimStatementSettings?: ClaimStatementSettings;
  sidebarVisible: boolean;
}

const Invoices = ({ overview, claimStatementSettings, sidebarVisible }: Props) => {
  if (!overview?.invoices || overview.invoices.length === 0) {
    return (
      <FitTableArea>
        <Pane title="Invoices Shared for Review" color="green" padding="16px">
          <TempText>
            Submitted Invoices will appear here after being considered by the
            Claims Handler.
          </TempText>
        </Pane>
      </FitTableArea>
    );
  }
  return (
    <InvoiceTableReview
      data={overview}
      title="Invoices Shared for Review"
      claimStatementSettings={claimStatementSettings}
      usePrintSchema={sidebarVisible} />
  );
};

export const ClaimStatementReviewPage = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [showPrintVersion, setPrintVersion] = useState(false);

  const claimStatementId = useRequireClaimStatementId();
  const role = useCurrentRole();
  const { data } = useClaimStatement(claimStatementId);
  const { data: overview } = useAggregatedClaimsHandlerInvoicesForReview(claimStatementId);
  const { data: summary } = useReviewSummary(claimStatementId);
  const showMagicLinksButton = useFeatureFlag('magicLinks');

  const { open: openShareMagicLinkModal } = useTypedModal(shareMagicLinkModal({ claimStatementId }));
  const downloadAllInvoicesFromClaimReview = async () => {
    await exportAndDownload(claimStatementId!, 'allInvoices', true);
  };

  const openPdfSidebar = () => {
    if (!sidebarVisible) {
      setSidebarVisible(true);
      setPrintVersion(true);
    }
  };

  const closePdfSidebar = () => {
    if (sidebarVisible) {
      setSidebarVisible(false);
      setPrintVersion(false);
    }
  };

  if (!data || !overview || !summary || !role) {
    return <PageLoader />;
  }

  const isClaimsHandler = role?.name === 'ClaimsHandler';

  const breadcrumbSteps = [reusableSteps.myDashboardStep];
  const vesselName = breadcrumbVesselName(data.vesselName, data.isExperimental);
  const currentBreadcrumb = `CLAIM REVIEW - ${vesselName}`;

  return (
    <ExtendedPageWrapper>
      <SavePdfSidebar isVisible={sidebarVisible} onClose={closePdfSidebar} />
      <HiddenInPrint>
        <StickyPageHeading>
          <HeadingWrapper>
            <Breadcrumbs
              steps={breadcrumbSteps}
              current={currentBreadcrumb} />
            <ButtonGroup>
              {(isClaimsHandler && showMagicLinksButton) && (
                <SlimButton variant="secondary" startIcon={<SendFilled />} onClick={openShareMagicLinkModal}>
                  SHARE ACCESS
                </SlimButton>
              )}
              <SlimButton
                startIcon={<ExportPdf width="24px" height="24px" />}
                variant="secondary"
                onClick={openPdfSidebar}
                data-testid="open-pdf-sidebar-button">
                SAVE AS PDF
              </SlimButton>
              <SlimButton startIcon={<Download />} onClick={downloadAllInvoicesFromClaimReview}>
                EXPORT TO EXCEL
              </SlimButton>
            </ButtonGroup>
          </HeadingWrapper>
        </StickyPageHeading>
      </HiddenInPrint>
      <StickyPageContent noTopPadding>
        <ShowInPrint>
          <VesselTitle
            prefix="CLAIM REVIEW"
            vesselName={data.vesselName}
            isExperimental={data.isExperimental} />
        </ShowInPrint>
        <PageGrid>
          <GridPlacement area="vessel">
            <ClaimDetails data={data} forPrint={showPrintVersion} />
          </GridPlacement>
          <GridPlacement area="summary">
            <SummaryPane summary={summary} claimStatementSettings={data.claimStatementSettings} isReview />
          </GridPlacement>
        </PageGrid>
      </StickyPageContent>
      <Invoices overview={overview} claimStatementSettings={data.claimStatementSettings} sidebarVisible={sidebarVisible} />
    </ExtendedPageWrapper>
  );
};
