import { useModalContext } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import { ButtonGroup, SlimButton } from '@instech/components';
import {
  BoldText, RegularText, TextWrapper
} from '@/components/pages/claim/ClaimStatementPage/InvoiceTable/modal/core/Components';
import styled from 'styled-components';

const TextWrapperStyled = styled(TextWrapper)`
  padding-bottom: 0px;
  font-size: 17px;
`;

const RegularTextStyled = styled(RegularText)`
  margin-left: 5px;
  justify-content: flex-start;
`;

const FooterBoldText = styled(BoldText)`
  margin-top: 35px;
  margin-bottom: 30px;
  margin-left: 5px;
  justify-content: flex-start;
  text-align: left;
  font-size: 16px;
`;

export const logoutUser = (logoutUrl: any) => {
  window.location = logoutUrl;
};

interface UserSessionExpireModalProps {
  logoutUrl: string;
}

const ModalContent = ({ logoutUrl }: UserSessionExpireModalProps) => {
  const { close } = useModalContext();

  const handleLogout = async () => {
    logoutUser(logoutUrl);
    close();
  };

  const handleOk = async () => {
    close();
    window.location.reload();
  };

  return (
    <div>
      <TextWrapperStyled>
        <RegularTextStyled>For security reasons, your session expired due to inactivity.</RegularTextStyled>
        <RegularTextStyled>Changes you made may not have been saved.</RegularTextStyled>
        <FooterBoldText>
          To continue working, please press OK to refresh the page.
        </FooterBoldText>
      </TextWrapperStyled>
      <ButtonGroup alignRight buttonGap="24px">
        <SlimButton variant="secondary" onClick={handleLogout}>LOG OUT</SlimButton>
        <SlimButton width="50px" onClick={handleOk}>OK</SlimButton>
      </ButtonGroup>
    </div>
  );
};

export const defaultUserSessionExpireModalProps: UserSessionExpireModalProps = {
  logoutUrl: ''
};

export const userSessionExpiredModal = (
  { logoutUrl }: UserSessionExpireModalProps,
  onCancel: () => void = () => null,
): ModalType<UserSessionExpireModalProps> => ({
  component: ModalContent,
  options: {
    title: 'Your session has expired',
    size: 'small',
    padding: '20px',
    hideCloseButton: true,
  },
  args: { logoutUrl },
  onCancel
});
