import { Nullable } from '@/types';
import { calculateWithExchangeRate } from '@/utils/invoice/convertCurrency';
import {
  formatWithDigitsOrEmpty, formatWithTruncateOrEmpty, truncateDecimals
} from '@/utils/number';
import { CellContext } from '@tanstack/react-table';
import { FC } from 'react';
import { CellContent } from '../core/Components';
import { TableCellAlign } from '../types';
import { getHeaderGroup, getMergedMeta } from '../utils';
import { BaseCellProps, BaseTableCell } from './BaseTableCell';
import { useRowClick } from '../providers/RowClickContext';

/**
 * Returns auto-formatted value, with exchange rate, for invoice table cells.
 */
const getFieldValue = (isConsidered?: boolean, value?: string | number | null, multFactor?: number | null) => {
  if (!isConsidered) return null;
  if (!multFactor) return '-';
  const convertedNumber = Number(value);
  return formatWithTruncateOrEmpty(truncateDecimals(calculateWithExchangeRate(convertedNumber, multFactor) as number));
};

/**
 * Returns auto-formatted value, with exchange rate, for invoice table cell tooltips.
 */
const getTooltipValue = (isConsidered?: boolean, value?: string | number | null, multFactor?: number | null) => {
  const convertedNumber = Number(value);
  if (!isConsidered || !multFactor || Number.isNaN(convertedNumber)) {
    return null;
  }

  return formatWithDigitsOrEmpty(truncateDecimals(calculateWithExchangeRate(convertedNumber, multFactor) as number, 2), true);
};

interface BaseTextCellProps extends BaseCellProps {
  value: string | undefined;
  isConsidered?: boolean;
  exchangeRate?: Nullable<number>;
  tooltipId?: string;
  align?: TableCellAlign;
}
export const TableCellConsideredBase: FC<BaseTextCellProps> = ({
  id,
  value,
  isConsidered,
  exchangeRate,
  headerGroup,
  cellVariant,
  columnBorder,
  span,
  align,
  tooltipId,
  onClick
}) => (
  <BaseTableCell
    id={id}
    headerGroup={headerGroup}
    cellVariant={cellVariant}
    columnBorder={columnBorder}
    span={span}
    onClick={onClick}>
    <CellContent align={align}>
      <span data-for={tooltipId} data-tip={getTooltipValue(isConsidered, value, exchangeRate)}>
        {getFieldValue(isConsidered, value, exchangeRate)}
      </span>
    </CellContent>
  </BaseTableCell>
);

/**
 * Simple table cell component for displaying text as-is. Use this if
 * the value is in the table data as it should be, and it just has to
 * be rendered out.
 */
interface TextCellProps {
  data: CellContext<any, any>;
  overrideValue?: string;
  isConsidered?: boolean;
  exchangeRate?: Nullable<number>;
  tooltipId?: string;
}
export const TableCellConsidered: FC<TextCellProps> = ({
  data,
  overrideValue,
  isConsidered,
  exchangeRate,
  tooltipId
}) => {
  const { onRowClick } = useRowClick();
  const mergedMeta = getMergedMeta(data.column);
  const headerGroup = getHeaderGroup(data.column);
  const value = data.getValue();
  return (
    <TableCellConsideredBase
      id={data.cell.id}
      value={overrideValue ?? (value || '-')}
      isConsidered={isConsidered}
      exchangeRate={exchangeRate}
      headerGroup={headerGroup}
      cellVariant={mergedMeta.tableCellVariant}
      columnBorder={mergedMeta.columnBorder}
      align={mergedMeta?.align}
      tooltipId={tooltipId}
      onClick={onRowClick}
    />
  );
};
