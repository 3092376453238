import {
  formatNumber, formatWithDigitsOrEmpty, truncateDecimals
} from '@/utils/number';
import { CellContext } from '@tanstack/react-table';
import { FC } from 'react';
import { CellContent } from '../core/Components';
import { useRowClick } from '../providers/RowClickContext';
import { TableCellAlign } from '../types';
import { getHeaderGroup, getMergedMeta } from '../utils';
import { BaseCellProps, BaseTableCell } from './BaseTableCell';

const formatOrEmpty = (number?: string | number | null, showZero?: boolean) => {
  const asNumber = Number(number);
  if (number === null || Number.isNaN(asNumber)) {
    return '-';
  }
  const numberToFormat = (showZero && asNumber === 0) ? asNumber : truncateDecimals(asNumber, 0);
  return formatNumber(numberToFormat);
};

interface BaseNumberCellProps extends BaseCellProps {
  tooltipId?: string;
  value?: string | number | null;
  overrideTooltip?: string | number | null;
  align?: TableCellAlign;
  onClick?: () => void;
}
export const TableCellNumberBase: FC<BaseNumberCellProps> = ({
  id,
  tooltipId,
  value,
  overrideTooltip,
  headerGroup,
  cellVariant,
  columnBorder,
  span,
  align,
  onClick
}) => {
  const tooltipValue = tooltipId ? formatWithDigitsOrEmpty(value, true) : undefined;
  return (
    <BaseTableCell
      id={id}
      headerGroup={headerGroup}
      cellVariant={cellVariant}
      columnBorder={columnBorder}
      onClick={onClick}
      span={span}>
      <CellContent align={align}>
        <span data-for={tooltipId} data-tip={overrideTooltip ?? tooltipValue}>
          {formatOrEmpty(value)}
        </span>
      </CellContent>
    </BaseTableCell>
  );
};

/**
 * Simple table cell component for displaying a number value.
 * Supports displaying a tooltip if a tooltipId is provided, and allows manual,
 * separate override of both tooltip value and cell value.
 */
interface TextCellProps {
  data: CellContext<any, any>;
  tooltipId?: string;
  overrideTooltip?: string | number | null;
  overrideValue?: string | number | null;
}
export const TableCellNumber: FC<TextCellProps> = ({
  data,
  tooltipId,
  overrideTooltip,
  overrideValue
}) => {
  const { onRowClick } = useRowClick();
  const mergedMeta = getMergedMeta(data.column);
  const headerGroup = getHeaderGroup(data.column);
  const value = overrideValue ?? data.getValue();
  return (
    <TableCellNumberBase
      id={data.cell.id}
      value={value}
      overrideTooltip={overrideTooltip}
      tooltipId={tooltipId}
      headerGroup={headerGroup}
      cellVariant={mergedMeta.tableCellVariant}
      columnBorder={mergedMeta.columnBorder}
      align={mergedMeta?.align}
      onClick={onRowClick}
    />
  );
};
