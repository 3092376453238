import styled from 'styled-components';
import { SlimButton } from '@instech/components';
import { FC } from 'react';
import { useField } from 'formik';
import { DatePickerHighlighted } from '@/common/components/HighlightedFields';

const ResetDateButton = styled(SlimButton)`
  padding: 2px 0;
  min-height: 0;
  outline: none;

  &:focus {
    outline: none;
    text-decoration: underline;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const ResetButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type DueDateProps = {
  dueDateHighlight: boolean;
  handleClick: () => void;
};

const DueDate : FC<DueDateProps> = ({ dueDateHighlight, handleClick }) => {
  const [, helpers] = useField('dueDate');

  return (
    <div>
      <DatePickerHighlighted
        name="dueDate"
        label="Due Date"
        data-highlight={dueDateHighlight}
        noErrors={!helpers.error}
      />
      <ResetButtonWrapper>
        <ResetDateButton onClick={handleClick} variant="tertiary">
          Reset Date
        </ResetDateButton>
      </ResetButtonWrapper>
    </div>
  );
};

export default DueDate;
