import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { InfoWithLabel } from '@/common/components/Text/InfoWithLabel';

const StyledInfo = styled(InfoWithLabel)`
  white-space: pre-line;
  & span {
    font-family: 'CalibriWeb Light';
    color: ${props => props.theme.marineBlue};
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

interface ClaimsHandlerInstructionsProps {
  instructions: string;
}
export const ClaimsHandlerInstructions: FunctionComponent<ClaimsHandlerInstructionsProps> = ({ instructions }) => (
  <Wrapper>
    <StyledInfo text={instructions} />
  </Wrapper>
);
