import { WarningNotification } from '@instech/components';
import styled from 'styled-components';

const ClosedWarning = styled(WarningNotification)`
  margin: 20px 0;
`;

export const ClosedClaimStatementWarning = () => (
  <ClosedWarning
    headingText="This claim statement is closed"
    descriptionText="All invoice details are read-only, but other actions are still available."
    size="medium"
  />
);
