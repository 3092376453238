import {
  AdjustersConsiderationSplitForm, SurveyorsConsiderationSplitForm, UpdateAdjustersConsiderationForm, UpdateSurveyorsConsiderationForm
} from '@/types';
import { stringToLabelValuePair } from '@/utils/labelValuePair';
import { useFormikContext } from 'formik';
import { useState, useEffect } from 'react';
import { LabelValuePair } from '@instech/components';

type UpdateConsiderationForm = (UpdateSurveyorsConsiderationForm | UpdateAdjustersConsiderationForm) & { [key: string]: any };
type SplitForm = (AdjustersConsiderationSplitForm | SurveyorsConsiderationSplitForm) & { [key: string]: any };

const getValue = (splitValue: string[], multipleValue: string) => {
  const splitValueSame = splitValue?.every(v => v === splitValue[0]);
  if (splitValueSame) {
    return splitValue[0];
  }
  return multipleValue;
};

const hasBeenSetExplicitForInvoice = <T extends UpdateConsiderationForm>(values: T, fieldName: string, multipleValue: string): boolean => {
  if (values.splits.length <= 1) return false;
  const fieldValue = getValue(values.splits.map((x: SplitForm) => x[fieldName]?.value!), multipleValue);
  // If all the child values and the overall value is the same, allow for automatic change to multiple
  if (fieldValue === values[fieldName]?.value) return false;
  return true;
};

const isMultiple = (labelValuePairCurrentValue: string | null | undefined, multipleValue: string): boolean => labelValuePairCurrentValue === multipleValue;

export const useSynchronizedLabelValues = <T extends UpdateConsiderationForm>(
  invoiceRepairPeriod: string | undefined | null,
  multipleLabelValuePair: LabelValuePair,
  fieldName: string) => {
  const { values, setFieldValue } = useFormikContext<T>();
  const [allowUpdateToMultiple, setAllowUpdateToMultiple] = useState<boolean>(
    !isMultiple(invoiceRepairPeriod, multipleLabelValuePair.value) &&
    !hasBeenSetExplicitForInvoice(values, fieldName, multipleLabelValuePair.value)
  );

  useEffect(() => {
    if (values.splits.length !== 1) return;
    setAllowUpdateToMultiple(true);

    if (isMultiple(values[fieldName]?.value, multipleLabelValuePair.value) && (values.splits[0] as SplitForm)[fieldName] !== undefined) {
      void setFieldValue(fieldName, (values.splits[0] as SplitForm)[fieldName]);
    } else if ((values.splits[0] as SplitForm)[fieldName] !== values[fieldName] && !isMultiple(values[fieldName]?.value, multipleLabelValuePair.value)) {
      void setFieldValue(`splits[0].${fieldName}`, values[fieldName]);
    }
  }, [setFieldValue, values.splits, values, fieldName, multipleLabelValuePair.value]);

  useEffect(() => {
    const fieldValue = getValue(values.splits.map((x: SplitForm) => x[fieldName]?.value!), multipleLabelValuePair.value);

    if (values.splits.length === 1) return;
    if (values[fieldName]?.value === fieldValue) return;

    if (isMultiple(fieldValue, multipleLabelValuePair.value) && allowUpdateToMultiple) {
      // We only allow for multiple to be automatically set once
      setAllowUpdateToMultiple(false);
      void setFieldValue(fieldName, multipleLabelValuePair);
    } else if (isMultiple(values[fieldName]?.value, multipleLabelValuePair.value) && fieldValue) {
      void setFieldValue(fieldName, stringToLabelValuePair(fieldValue));
    }
  }, [values.splits, allowUpdateToMultiple, setFieldValue, values, multipleLabelValuePair.value, fieldName, multipleLabelValuePair]);
};
