import { CurrencyFieldHighlighted } from '@/common/components/HighlightedFields';
import { useField } from 'formik';
import { InsuranceValue } from '@/apps/Adjustment/types/adjustmentCalculations';
import { isHighlighted } from './utils';

type InsuredValueInputProps = {
  initial: InsuranceValue | null;
}
const InsuredValueInput = ({ initial }: InsuredValueInputProps) => {
  const [, read] = useField('insuredValue');

  const highlight = isHighlighted({ current: read.value, initial: initial?.amount });
  return (
    <CurrencyFieldHighlighted
      name="insuredValue"
      label="Insured Value"
      currency={initial?.currency}
      data-highlight={highlight}
    />
  );
};

export default InsuredValueInput;
