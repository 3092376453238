import styled from 'styled-components';

export const StyledTableHeader = styled.thead`
  position: sticky;
  top: 0px;
  background-color: ${props => props.theme.white};
  z-index: 5;
`;

export const StyledHeaderRow = styled.tr`
  background-color: ${props => props.theme.white};
`;
