import { useTypedModal } from '@/common/modules/Modal';
import { returnSingleInvoiceClaimsHandlerToSurveyorModal }
  from '@/components/pages/claim/ClaimStatementPage/InvoiceTable/modal/ReturnSingleInvoiceClaimsHandlerToSurveyorModal';
import { transferClaimsHandlerToSurveyorModal } from '@/components/pages/claim/ClaimStatementPage/InvoiceTable/modal/TransferClaimsHandlerToSurveyorModal';
import { editableStates } from '@/components/pages/invoice/InvoicePage/ClaimsHandlerInvoicePage';
import { UpdateAdjustersInvoiceResponse } from '@/services/invoice/invoiceServices';
import { updateInvoiceState } from '@/services/invoice/invoiceStateService';
import {
  Invoice, InvoiceState, InvoiceStateMetadata, UpdateAdjustersConsiderationForm
} from '@/types';
import { ButtonGroup } from '@instech/components';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { invoiceStates } from '@/utils/constants';
import { AdjusterTransferInvoiceType } from '../../EditInvoiceAdjuster';
import { resetAdjusterFormWithConsidered } from '../utils/resetWithConsidered';
import {
  GrayCloseButton, GreenCloseButton, ShareSurveyorButton, GreenSaveButton, MarkAsFinishedButton
} from './buttons/HeaderButtons';
import { InvoiceTraversalButtons } from './buttons/InvoiceTraversalButtons';
import { useSaveStatusContext } from '../utils/SaveStatus/SaveStatusContext';

const canSetFinishedStates: InvoiceState[] = [
  invoiceStates.Review,
  invoiceStates.SharedReview,
  invoiceStates.AdjustersConsideration,
  invoiceStates.AdjustersConsiderationNoAllocation
];

const shareableStates: InvoiceState[] = [
  invoiceStates.Review,
  invoiceStates.SharedReview,
  invoiceStates.AdjustersConsiderationNoAllocation,
  invoiceStates.AdjustersConsideration
];

const readonlyStates: InvoiceState[] = [
  invoiceStates.CostAllocation,
  invoiceStates.CostReallocation,
  invoiceStates.Finished,
  invoiceStates.FinishedNoAllocation,
  invoiceStates.FinishedSharedReview,
  invoiceStates.New,
  invoiceStates.ReturnToUploader,
  invoiceStates.ReturnToUploaderAfterConsideration,
  invoiceStates.ReturnToUploaderAfterConsiderationNoAllocation,
  invoiceStates.ReturnToUploaderAfterSharedReview
];

interface InvoiceActionButtonsAdjusterProps {
  transferInvoice: AdjusterTransferInvoiceType;
  invoice: Invoice;
  updateInvoice: (updateInvoiceForm: UpdateAdjustersConsiderationForm) => Promise<UpdateAdjustersInvoiceResponse>;
}
export const InvoiceActionButtonsAdjuster = ({ transferInvoice, invoice, updateInvoice }: InvoiceActionButtonsAdjusterProps) => {
  const { values, resetForm } = useFormikContext<UpdateAdjustersConsiderationForm>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { saveStatus, setSaveStatus } = useSaveStatusContext();

  const handleMarkAsFinished = async () => {
    setSubmitting(true);
    await transferInvoice(values, resetForm);
    setSubmitting(false);
  };

  const { cache } = useSWRConfig();
  const navigate = useNavigate();
  const navigateToClaimStatementPage = () => navigate(`/claimstatements/${invoice.claimStatementId}`);

  const saveInvoice = useCallback(() => (async () => {
    setSaveStatus('saving');
    const result = await updateInvoice(values);
    if (result.status === 'success') {
      resetForm({ values: resetAdjusterFormWithConsidered(values, result.invoice.adjustersConsideration) });
    }
    setSaveStatus('saved');
    return result;
  })(), [resetForm, setSaveStatus, updateInvoice, values]);

  const transferInvoices = async (invoiceToShare: Invoice, destinationState: InvoiceState, instructions?: string) => {
    await updateInvoiceState(invoiceToShare.claimStatementId, {
      destinationState,
      instructions,
      invoiceIdList: [invoiceToShare.id]
    }, cache);

    navigate(`/claimstatements/${invoiceToShare.claimStatementId}`);
    return 'success';
  };

  const { open: openReturnModal } = useTypedModal(returnSingleInvoiceClaimsHandlerToSurveyorModal({
    invoices: [{ state: invoice.state, adjusted: invoice.adjustersConsideration?.isConsidered, id: invoice.id } as InvoiceStateMetadata],
    transfer: async (destinationState, instructions) => transferInvoices(invoice, destinationState, instructions),
    claimStatementId: invoice.claimStatementId
  }));
  const { open: openTransferModal } = useTypedModal(transferClaimsHandlerToSurveyorModal({
    claimStatementId: invoice.claimStatementId,
    firstInvoiceId: invoice.id,
    invoices: [{ state: invoice.state, adjusted: invoice.adjustersConsideration?.isConsidered, id: invoice.id } as InvoiceStateMetadata],
    transfer: async (destinationState, instructions) => transferInvoices(invoice, destinationState, instructions)
  }));

  const shareWithSurveyor = async () => {
    const transferWithPreviousInstructions = invoice.state === invoiceStates.AdjustersConsideration;
    // Break early if a save is currently underway
    if (saveStatus === 'saving') {
      return;
    }
    // If there are unsaved changes, await save to be completed
    if (saveStatus === 'unsaved') {
      const result = await saveInvoice();
      if (result.status !== 'success') {
        return;
      }
    }
    if (transferWithPreviousInstructions) {
      openReturnModal();
      return;
    }
    openTransferModal();
  };

  const editable = editableStates.includes(invoice.state);
  const readonly = readonlyStates.includes(invoice.state);
  const showShareWithSurveyor = shareableStates.includes(invoice.state);
  const canMarkAsFinished = canSetFinishedStates.includes(invoice.state);

  return (
    <ButtonGroup>
      <SharedTooltip
        id="invoice-traversal-tooltip"
        bodyMode={false}
        effect="solid"
        place="bottom">
        {editable && <GrayCloseButton onClick={navigateToClaimStatementPage} />}
        <InvoiceTraversalButtons claimStatementId={invoice.claimStatementId} saveAction={saveInvoice} />
        {readonly && <GreenCloseButton onClick={navigateToClaimStatementPage} />}
        {showShareWithSurveyor && <ShareSurveyorButton onClick={shareWithSurveyor} />}
        {canMarkAsFinished && <MarkAsFinishedButton onClick={handleMarkAsFinished} submitting={submitting} />}
        {editable && <GreenSaveButton status={saveStatus} onClick={saveInvoice} />}
      </SharedTooltip>
    </ButtonGroup>
  );
};
