import React, { FunctionComponent, useMemo } from 'react';
import { DropdownCurrency as Dropdown } from '@instech/components';
import { useCurrencies } from '@/services/currencyServices';

interface Props {
  name: string;
  formId?: string;
  label?: string;
  placeholder?: string;
  noLabel?: boolean;
  noErrors?: boolean;
  selectDisplay?: 'name' | 'code';
  clearable?: boolean;
  required?: boolean;
  optional?: boolean;
}
export const DropdownCurrency: FunctionComponent<Props> = props => {
  const { data: currencies } = useCurrencies();

  const dropdownOptions = useMemo(() => {
    if (!currencies) return undefined;
    return currencies.map(({ name, code }) => ({ label: name, value: code }));
  }, [currencies]);

  return (
    <Dropdown {...props} options={dropdownOptions} />
  );
};
