import { DecimalNumberFieldHighlighted } from '@/common/components/HighlightedFields';
import { ComponentProps } from 'react';

type AdjustedClaimInputProps = Omit<
  ComponentProps<typeof DecimalNumberFieldHighlighted>,
  'name' | 'maxDecimals' | 'label'
>;

export const DueToAssuredInput = (props: AdjustedClaimInputProps) => (
  <DecimalNumberFieldHighlighted
    name="dueToAssured"
    maxDecimals={2}
    label="Due to the Assured"
    {...props} />
);

export const UnderwritersFeeAndExpensesInput = (props: AdjustedClaimInputProps) => (
  <DecimalNumberFieldHighlighted
    name="underwritersFeeAndExpenses"
    maxDecimals={2}
    label="Underwriters Fees and Expenses"
    {...props} />
);

export const TotalClaimOnPolicyInput = (props: AdjustedClaimInputProps) => (
  <DecimalNumberFieldHighlighted
    name="totalClaimOnPolicy"
    maxDecimals={2}
    label="Total Claim on Policy"
    {...props} />
);
