import { useTypedModal } from '@/common/modules/Modal';
import { deviationFromTotalModal } from '@/components/pages/claim/ClaimStatementPage/InvoiceTable/modal/DeviationFromTotalModal';
import {
  defaultInvoicesNotCompleteModalProps,
  invoicesNotCompleteModal
} from '@/components/pages/claim/ClaimStatementPage/InvoiceTable/modal/InvoicesNotCompleteModal';
import { useClaimStatementCategoriesAsOptions } from '@/services/categoriesServices';
import {
  UpdateAdjustersInvoiceResponse,
  useInvoice
} from '@/services/invoice/invoiceServices';
import { updateInvoiceState } from '@/services/invoice/invoiceStateService';
import { useClaimStatementLocationsAsOptions } from '@/services/locationsServices';
import { useClaimStatementRepairPeriodsAsOptions } from '@/services/repairPeriodsServices';
import {
  AdjustersConsiderationForm,
  ClaimStatement,
  Invoice,
  UpdateAdjustersConsiderationForm
} from '@/types';
import { hasAdjustersDeviationForm } from '@/utils/invoice/invoiceTotalDeviation';
import { getInvoiceStateToFinished } from '@/utils/invoicesTransfer';
import { LabelValuePair, PageLoader } from '@instech/components';
import { FormikState } from 'formik';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { InvoiceFormAdjuster } from './InvoiceForm/InvoiceFormAdjuster';
import { resetAdjusterFormWithConsidered } from './InvoiceForm/utils/resetWithConsidered';

export type AdjusterTransferInvoiceType = (
  updateInvoiceForm: UpdateAdjustersConsiderationForm,
  resetForm: (nextState?: Partial<FormikState<AdjustersConsiderationForm>>) => void
) => void;

interface EditInvoiceProps {
  claimStatement: ClaimStatement;
  invoice: Invoice;
  handleInvoiceConflict: () => void;
  ownsLock: boolean;
}
export const EditInvoiceAdjuster: FC<EditInvoiceProps> = ({
  claimStatement,
  invoice,
  handleInvoiceConflict,
  ownsLock
}) => {
  const navigate = useNavigate();
  const { claimStatementId } = useParams();
  const { cache } = useSWRConfig();
  const { updateInvoiceAdjuster, mutate } = useInvoice(claimStatement.id, invoice.id);
  const categoryOptions = useClaimStatementCategoriesAsOptions(claimStatement.id);
  const occurrenceOptions: LabelValuePair[] = claimStatement.occurrences.map(occurrence => ({ label: occurrence.name, value: occurrence.id }));

  const { repairPeriodOptions, mutate: refreshRepairPeriods } = useClaimStatementRepairPeriodsAsOptions(claimStatement.id);
  const { locationOptions, mutate: refreshLocations } = useClaimStatementLocationsAsOptions(claimStatement.id);

  const { open: openDeviationModal } = useTypedModal(deviationFromTotalModal());
  const { open: openInvoicesNotCompleteModal } = useTypedModal(invoicesNotCompleteModal({ ...defaultInvoicesNotCompleteModalProps, incompleteInvoices: 0 }));

  const handleUpdate = async (updateForm: AdjustersConsiderationForm): Promise<UpdateAdjustersInvoiceResponse> => {
    const result = await updateInvoiceAdjuster(updateForm, invoice.etag) as UpdateAdjustersInvoiceResponse;
    if (result.status === 'conflict') handleInvoiceConflict();
    await refreshLocations();
    await refreshRepairPeriods();

    return result;
  };

  const transferInvoice = async (
    updateForm: UpdateAdjustersConsiderationForm,
    resetForm: (nextState?: Partial<FormikState<AdjustersConsiderationForm>>) => void
  ) => {
    const updatedInvoice = (await updateInvoiceAdjuster(updateForm, invoice.etag) as UpdateAdjustersInvoiceResponse).invoice;
    const hasDeviation = hasAdjustersDeviationForm(updatedInvoice.record.totalAmount, updateForm);
    if (hasDeviation) {
      openDeviationModal();
      return;
    }

    const isConsidered = updatedInvoice.adjustersConsideration.splits
      .every(x => x.isDeleted || (x.isOwnersWorkConsiderationConsidered && x.isCommonExpensesConsidered && x.isParticularAverageConsidered));
    const hasAllFields = updatedInvoice.record.exchangeRate && updatedInvoice.record.paymentDate && updatedInvoice.record.personallyIdentifiableInformation
      && updatedInvoice.record.category && updatedInvoice.record.location;

    if (!hasAllFields || !isConsidered) {
      openInvoicesNotCompleteModal();
      return;
    }

    // call to reset the consideration form to flag highlighted fields as considered on submit
    resetForm({ values: resetAdjusterFormWithConsidered(updateForm) });

    await updateInvoiceState(claimStatementId!, {
      destinationState: getInvoiceStateToFinished(updatedInvoice.state),
      invoiceIdList: [invoice.id],
      instructions: undefined
    }, cache);

    void mutate();
    navigate(`/claimstatements/${claimStatementId}`);
  };

  if (!locationOptions || !categoryOptions) {
    return <PageLoader />;
  }

  return (
    <InvoiceFormAdjuster
      invoice={invoice}
      claimStatement={claimStatement}
      updateInvoice={handleUpdate}
      transferInvoice={transferInvoice}
      categoryOptions={categoryOptions}
      repairPeriodOptions={repairPeriodOptions}
      locationOptions={locationOptions}
      occurrenceOptions={occurrenceOptions}
      ownsLock={ownsLock}
    />
  );
};
