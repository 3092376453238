import { Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import styled from 'styled-components';
import {
  AdjustmentCalculations,
  Cover,
} from '@/apps/Adjustment/types/adjustmentCalculations';
import { FormWithAutoSave } from '@/apps/Adjustment/pages/AdjustmentPage/core/Components';
import { updateCover } from '@/apps/Adjustment/services/adjustmentCalculationsService';
import {
  dispatchAutosaveFinishedEvent,
  dispatchAutosaveStartedEvent
} from '@/common/components/Autosave';
import {
  InsuredValueInput,
  PeriodFromInput,
  PeriodToInput
} from './Inputs';
import {
  createInitialValues,
  policyFormValidationSchema
} from './utils';

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 12px;
`;

type FormType = ReturnType<typeof createInitialValues>;

interface Props {
  cover: Cover,
  adjustmentCalculation: AdjustmentCalculations
}

export const PolicyInformationForm: FC<Props> = ({
  cover,
  adjustmentCalculation
}) => {
  const saveCover = async (updateCoverForm: FormType, { resetForm }: FormikHelpers<FormType>) => {
    dispatchAutosaveStartedEvent();
    try {
      await updateCover(adjustmentCalculation.claimStatementId, adjustmentCalculation.id, cover.id, updateCoverForm);
      resetForm({ values: updateCoverForm });
    } finally {
      dispatchAutosaveFinishedEvent();
    }
  };

  return (
    <Formik initialValues={createInitialValues(cover)} validationSchema={policyFormValidationSchema} onSubmit={saveCover}>
      <FormWithAutoSave>
        <Layout>
          <PeriodFromInput initialValue={cover.periodFrom.initial} />
          <PeriodToInput initialValue={cover.periodTo.initial} />
          <InsuredValueInput initial={cover.insuredValue.initial ?? null} />
        </Layout>
      </FormWithAutoSave>
    </Formik>
  );
};
