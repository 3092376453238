import { useModalContext } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import { ButtonGroup, SlimButton } from '@instech/components';
import React from 'react';
import styled from 'styled-components';

const TextWrapper = styled.div`
  text-align: center;
  color: ${props => props.theme.marineBlue};
  padding: 90px 0;
`;

const RegularText = styled.div`
  text-align: center;
  color: ${props => props.theme.marineBlue};
  margin-top: 21px;
`;

const BoldText = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

interface DeleteInvoiceModal {
  onDelete: () => Promise<void>;
}
const ModalContent = ({ onDelete }: DeleteInvoiceModal) => {
  const { close } = useModalContext();

  const handleDelete = async () => {
    await onDelete();
    close();
  };

  return (
    <div>
      <TextWrapper>
        <BoldText>Do you wish to delete the Invoice including all attachments?</BoldText>
        <RegularText>You will not be able to restore it later</RegularText>
      </TextWrapper>
      <ButtonGroup alignRight marginTop="24px">
        <SlimButton variant="secondary" onClick={close}>
          CANCEL
        </SlimButton>
        <SlimButton onClick={handleDelete}>
          DELETE INVOICE
        </SlimButton>
      </ButtonGroup>
    </div>
  );
};

export const deleteInvoiceModal = (
  { onDelete }: DeleteInvoiceModal
): ModalType<DeleteInvoiceModal> => ({
  component: ModalContent,
  options: {
    title: 'Delete Invoice',
    size: 'small',
    padding: '12px 20px 20px'
  },
  args: {
    onDelete
  }
});
