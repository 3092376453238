import styled from 'styled-components';
import {
  Pen, Plus, UserSingle
} from '@instech/icons';
import {
  ButtonGroup, IconButton, Pane, SlimButton
} from '@instech/components';
import { FCWC } from '@/types';

const PaneContentPadding = styled.div`
  position: relative;
  padding: 2px 0px 16px 4px;
`;

// TODO: This should not be necessary? <Pane /> supports this as 'button' prop
const AnchoredIconButton = styled(IconButton)`
  position: absolute;
  top: -65px;
  right: -15px;
  color: ${props => props.theme.marineBlue};
  z-index: 10;
`;

interface ButtonProps {
  onClick?: (param?: any) => any;
}

const AddButton = ({ onClick }: ButtonProps) => (
  <SlimButton variant="secondary" startIcon={<Plus />} onClick={onClick}>ADD</SlimButton>
);

const EditButton = ({ onClick }: ButtonProps) => (
  <AnchoredIconButton title="Edit" icon={<Pen />} smallIcon onClick={onClick} />
);

interface PaneProps {
  title: string;
  openModal: (param?: any) => any;
  margin?: string;
  editMode?: boolean;
}
export const SelectUsersPane: FCWC<PaneProps> = ({ children, title, openModal, margin, editMode }) => (
  <Pane title={title} icon={<UserSingle />} color="green" margin={margin} padding="16px">
    <PaneContentPadding>
      {editMode && <EditButton onClick={openModal} />}
      {children}
    </PaneContentPadding>
    <ButtonGroup alignRight>
      {!editMode && <AddButton onClick={openModal} />}
    </ButtonGroup>
  </Pane>
);
