import { FC, useMemo } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { UserPickerMulti } from '@instech/components';
import { Guid, UserInfo } from '@/types';
import { ModalLoader, useModalContext } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import {
  FetchUsersOptions,
  useAllUsersByOptions,
} from '@/services/userInfoServices';
import styled from 'styled-components';
import { ModalCancelSaveButtons } from './Components';
import { userPickerMulti, userPickerMultiRequired } from './validationSchema';
import { UserNotificationsInfobox } from './UserNotificationsInfobox';

type SelectUsersFormData = {
  users: (UserInfo | '')[];
  primary: Guid;
}

type OnSelectCallback = (users: UserInfo[]) => Promise<void> | void;

const NotificationWrapper = styled.div`
  padding-bottom: 20px;
`;

const createInitialValues = (users?: UserInfo[], primary?: string): SelectUsersFormData => ({
  users: users?.length ? users : [''],
  primary: primary ?? ''
});

const createValidationSchema = (allowEmpty?: boolean) => Yup.object({
  users: allowEmpty ? userPickerMulti() : userPickerMultiRequired('At least one user must be selected')
});

export interface SelectUsersProps {
  fetchUsersOptions: FetchUsersOptions;
  onSelect: OnSelectCallback;
  selectedUsers?: UserInfo[];
  allowEmptySelection?: boolean;
  showUpdateNotificationWarning: boolean;
  isDisabled?: (user: UserInfo) => boolean;
}
const ModalContent: FC<SelectUsersProps> = ({
  onSelect,
  selectedUsers,
  allowEmptySelection,
  fetchUsersOptions,
  showUpdateNotificationWarning,
  isDisabled
}) => {
  const userOptions = useAllUsersByOptions(fetchUsersOptions);
  const { close } = useModalContext();

  // memoized selection of validation schema
  const userPickerValidationSchema = useMemo(() => (
    createValidationSchema(allowEmptySelection)
  ), [allowEmptySelection]);

  const onCompletion = async (values: SelectUsersFormData) => {
    const validUsers = values.users.filter((user): user is UserInfo => user !== '');
    await onSelect(validUsers);
    close();
  };

  if (!userOptions) {
    return <ModalLoader />;
  }

  return (
    <div data-testid="users-input-modal">
      {showUpdateNotificationWarning && (
        <NotificationWrapper>
          <UserNotificationsInfobox />
        </NotificationWrapper>
      )}
      <Formik
        initialValues={createInitialValues(selectedUsers)}
        validationSchema={userPickerValidationSchema}
        onSubmit={onCompletion}>
        {({ handleSubmit, isSubmitting, submitCount, dirty }) => (
          <Form>
            <UserPickerMulti
              name="users"
              options={userOptions}
              isClearable={allowEmptySelection}
              // only show errors after first submit
              noErrorColors={submitCount === 0}
              noErrors={submitCount === 0}
              isDisabled={isDisabled}
            />
            <ModalCancelSaveButtons
              onSave={handleSubmit}
              onCancel={close}
              isPending={isSubmitting}
              isDisabled={!dirty || isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export const selectUsersModal = (props: SelectUsersProps): ModalType<SelectUsersProps> => ({
  component: ModalContent,
  options: {
    title: 'People With Access',
    size: 'large',
    padding: '20px',
  },
  args: props
});
