import { compareValuesForSorting } from '@/common/components/Table/CollapsibleTable/utils/useSortedCollapsibleTableData';
import {
  Invoice, InvoiceOverview, InvoiceOverviewSurveyor
} from '@/types';
import { SortedTableHeader, sortTableByParameter } from '@instech/components';
import { useMemo } from 'react';
import { getStateSortingKey } from './getStateSortingKey';

type SortInvoiceReturn = {
  data: Invoice[];
  header?: SortedTableHeader;
}
/**
 * Function to sort invoice data for display in an invoice table.
 * Most of the values are a straight-forward sort, but certain values (state especially)
 * need to be escaped and overwritten with a different set of values to sort properly.
 *
 * Function returns both the sorted data and the header sorting used. This is to allow
 * hook implementations to catch changes; because it seems (data === sortedData) when
 * the only difference between them is the order of elements?
 */
export const sortInvoiceData = (
  data: InvoiceOverview | InvoiceOverviewSurveyor,
  sortedHeader?: SortedTableHeader
): SortInvoiceReturn => {
  if (!data) return data;
  let sortedData = null;

  if (sortedHeader?.propertyName === 'state') {
    sortedData = data.invoices.sort((a, b) => {
      const sortStateA = getStateSortingKey(a.state, a.record);
      const sortStateB = getStateSortingKey(b.state, b.record);
      return compareValuesForSorting(sortStateA, sortStateB, sortedHeader.sortOrder);
    });
  } else {
    sortedData = sortTableByParameter(data.invoices, sortedHeader?.propertyName, sortedHeader?.sortOrder);
  }
  return { data: sortedData, header: sortedHeader };
};

/**
 * Hook implementation of invoice data sorting
 */
export const useSortedInvoiceData = (
  data: InvoiceOverview | InvoiceOverviewSurveyor,
  sortedHeader?: SortedTableHeader,
) => useMemo(() => sortInvoiceData(data, sortedHeader), [data, sortedHeader]);
