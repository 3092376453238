import { FCWC } from '@/types';
import {
  createContext, useContext, useMemo
} from 'react';

interface InvoiceLockData {
  ownsLock: boolean;
}

const InvoiceLockContext = createContext<InvoiceLockData>(null as unknown as InvoiceLockData);

/**
 * Provide access to Invoice Lock as a context, to read status within nested
 * components without having to resort to prop-drilling to provide them.
 *
 * The useInvoiceLock() hook still needs to be used within the component that
 * implements this provider. Haven't implemented it directly in here because it
 * is often needed on the same level as where this provider would be put.
 */
export const InvoiceLockProvider: FCWC<InvoiceLockData> = ({ children, ownsLock }) => {
  const memoizedValues = useMemo(() => ({ ownsLock }), [ownsLock]);
  return (
    <InvoiceLockContext.Provider value={memoizedValues}>
      {children}
    </InvoiceLockContext.Provider>
  );
};

export const useInvoiceLockContext = () => useContext(InvoiceLockContext);
