import { Invoice } from '@/types';
import { collapseGroups } from '@/common/components/Table/utils';
import { CollapsibleRow } from '@/common/components/Table/CollapsibleTable/types';
import { tableCellVariant } from '@/common/components/Table/types';
import { CSTableProps } from '../useSchemaByTableGrouping';
import { InvoiceReviewCommentCell } from '../../core/InvoiceCells';

const getCategoryText = (category?: string | null) => {
  if (!category || category === '-') return 'No Category';
  return category;
};

export const groupByCategoryRow = (
  rowData: Invoice,
  props: CSTableProps,
  subrowsIdList: string[],
  allSubrowsFinished: boolean,
  subrowsHaveComments: boolean
): CollapsibleRow => {
  const { record, surveyorsConsideration: surveyor, adjustersConsideration: adjuster } = rowData;
  const { claimStatementSettings } = props;
  const expanded = props.expanded[rowData.id];

  const onRowClick = () => props.onRowClick(rowData.id, subrowsIdList);
  const toggleExpanded = () => props.toggleExpanded(rowData.id);

  return ({
    id: rowData.id,
    isFinished: allSubrowsFinished,
    onClick: onRowClick,
    toggleExpanded,
    expanded,
    sortBy: {
      // overriding Category sorting, since its display value is altered
      category: record.category
    },
    segments: [
      {
        key: 'category',
        cellType: 'text',
        value: getCategoryText(record.category),
      },
      {
        key: 'paidAmount',
        cellType: 'digitsTooltip',
        value: record.paidAmount,
        align: 'right'
      },
      {
        key: 'claimInPolicyCurrency',
        cellType: 'digitsTooltip',
        value: record.claimInPolicyCurrency,
        group: collapseGroups.owner,
        border: true,
        align: 'right'
      },
      {
        key: 'ownersWorkInPolicyCurrency',
        cellType: 'digitsTooltip',
        value: record.ownersWorkInPolicyCurrency,
        group: collapseGroups.owner,
        align: 'right'
      },
      {
        key: 'surveyorGeneralExpenses',
        cellType: 'digitsTooltip',
        value: surveyor?.generalExpenses,
        group: collapseGroups.surveyor,
        variant: tableCellVariant.secondary,
        border: true,
        align: 'right'
      },
      {
        key: 'surveyorClaimConsideration',
        cellType: 'digitsTooltip',
        value: surveyor?.claimConsideration,
        group: collapseGroups.surveyor,
        variant: tableCellVariant.secondary,
        align: 'right'
      },
      {
        key: 'surveyorOwnersWorkConsideration',
        cellType: 'digitsTooltip',
        value: surveyor?.ownersWorkConsideration,
        group: collapseGroups.surveyor,
        variant: tableCellVariant.secondary,
        align: 'right'
      },
      {
        key: 'surveyorAdjusterToConsider',
        cellType: 'digitsTooltip',
        value: surveyor?.adjusterToConsider,
        group: collapseGroups.surveyor,
        variant: tableCellVariant.secondary,
        align: 'right'
      },
      {
        key: 'adjusterCommonExpenses',
        cellType: 'digitsTooltip',
        value: adjuster?.commonExpenses,
        group: collapseGroups.adjuster,
        variant: tableCellVariant.tertiary,
        border: true,
        align: 'right'
      },
      {
        key: 'adjusterGeneralAverage',
        cellType: 'digitsTooltip',
        value: adjuster?.generalAverage,
        group: collapseGroups.adjuster,
        variant: tableCellVariant.tertiary,
        align: 'right',
        shouldSkip: !claimStatementSettings?.hasGeneralAverage
      },
      {
        key: 'adjusterParticularAverage',
        cellType: 'digitsTooltip',
        value: adjuster?.particularAverage,
        group: collapseGroups.adjuster,
        variant: tableCellVariant.tertiary,
        align: 'right'
      },
      {
        key: 'adjusterOwnersWorkConsideration',
        cellType: 'digitsTooltip',
        value: adjuster?.ownersWorkConsideration,
        group: collapseGroups.adjuster,
        variant: tableCellVariant.tertiary,
        align: 'right'
      },
      {
        key: 'ownersReviewComment',
        cellType: 'custom',
        shouldSkip: (!props.showReviewComment || props.usePrintSchema),
        value: (
          <InvoiceReviewCommentCell
            data-cell-variant={tableCellVariant.tertiary}
            data-collapse-group={collapseGroups.adjuster}
            // Have to pass this via here since no propagation happens to custom cells
            data-is-finished={allSubrowsFinished}
            showComment={subrowsHaveComments}
          />
        )
      }
    ]
  });
};
