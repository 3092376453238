import styled from 'styled-components';
import {
  AdjustersConsiderationSplit, InvoiceState, Nullable, SurveyorsConsiderationSplit
} from '@/types';
import { FC } from 'react';
import { getFieldValue, getTooltipValue } from '../../core/utils/getConsideredValues';

export interface SplitRowDataType {
  adjuster: AdjustersConsiderationSplit;
  surveyor?: SurveyorsConsiderationSplit;
}

export interface SplitRowSurveyorProps {
  split: SplitRowDataType;
  exchangeRate?: number | null;
  state: InvoiceState;
}

export const SplitRowMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  color: ${props => props.theme.marineBlue70};
  font-style: italic;
`;

const StyledCellContent = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface CellContentProps {
  tooltipId: string;
  value: string | undefined;
  exchangeRate: Nullable<number>;
  isConsidered: boolean | undefined;
}
export const SplitCellContent: FC<CellContentProps> = ({ tooltipId, value, exchangeRate, isConsidered }) => (
  <StyledCellContent>
    <span data-for={tooltipId} data-tip={getTooltipValue(isConsidered, value, exchangeRate)}>
      {getFieldValue(isConsidered, value, exchangeRate)}
    </span>
  </StyledCellContent>
);
