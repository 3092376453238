import { StickyPageContent } from '@/common/layout/StickyPage';
import { useParams } from 'react-router-dom';
import { ClaimStatementSettings, InvoiceOverview } from '@/types';
import { PageLoader, Pane } from '@instech/components';
import {
  useAggregatedMagicLinkInvoicesForReview,
  useMagicLinkClaimStatement,
  useMagicLinkReviewSummary
} from '@/services/magicLinkService';
import styled, { css } from 'styled-components';
import { MagicLinkNoAccessView } from '@/components/pages/error/MagicLinkNoAccessView';
import { GridPlacement } from './StyledPageComponents';
import { ClaimDetailsClaimReview } from '../core/ClaimDetails';
import { InvoiceTableMagicLink } from './InvoiceTable/InvoiceTableMagicLink/InvoiceTableMagicLink';
import { SummaryPane } from './SummaryPane/SummaryPane';
import { VesselTitle } from '../core/ClaimDetails/VesselTitle';

const TitleWrapper = styled.div`
  margin-bottom: 16px;
`;

const TempText = styled.div`
  text-align: center;
  font-style: italic;
  color: ${props => props.theme.marineBlue};
`;

const PageGrid = styled.div`
  display: grid;
  ${props => css`
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "vessel summary";
  `};
  column-gap: 48px;
  row-gap: 40px;
`;

interface Props {
  overview?: InvoiceOverview;
  claimStatementSettings?: ClaimStatementSettings
}

const Invoices = ({ overview, claimStatementSettings }: Props) => {
  if (!overview?.invoices || overview.invoices.length === 0) {
    return (
      <Pane title="Invoices" color="green" padding="16px">
        <TempText>
          Submitted Invoices will appear here after being considered by the
          Claims Handler.
        </TempText>
      </Pane>
    );
  }
  return (
    <InvoiceTableMagicLink data={overview} title="Invoices" claimStatementSettings={claimStatementSettings} />
  );
};

export const MagicLinkReviewPage = () => {
  const { accessKey } = useParams();
  const { data, error } = useMagicLinkClaimStatement(accessKey);
  const { data: overview } = useAggregatedMagicLinkInvoicesForReview(accessKey);
  const { data: summary } = useMagicLinkReviewSummary(accessKey);

  if (error) {
    return <MagicLinkNoAccessView />;
  }

  if (!data || !overview || !summary) {
    return <PageLoader />;
  }

  return (
    <StickyPageContent>
      <TitleWrapper>
        <VesselTitle
          prefix="CLAIM REVIEW"
          vesselName={data.vesselName}
          isExperimental={data.isExperimental}
        />
      </TitleWrapper>
      <PageGrid>
        <GridPlacement area="vessel">
          <ClaimDetailsClaimReview data={data} />
        </GridPlacement>
        <GridPlacement area="summary">
          <SummaryPane summary={summary} claimStatementSettings={data.claimStatementSettings} isReview />
        </GridPlacement>
      </PageGrid>
      <Invoices overview={overview} claimStatementSettings={data.claimStatementSettings} />
    </StickyPageContent>
  );
};
