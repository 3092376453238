import { HelpTextLayout } from '@/apps/Adjustment/components/HelpText';
import { CollapsiblePane } from '@instech/components';

export const HelpText = () => (
  <CollapsiblePane color="green" title="Help Text" padding="20px">
    <HelpTextLayout>
      <p>On [Date], while on a ballast voyage from [A], to [B] a [Description] occurred. [Description] was used.</p>
      <p>The vessel anchored at [Location] on [Date] for initial damage inspection.</p>
      <p>On [Date] the vessel departed [Location] anchorage for [Location]  where repairs were scheduled to be carried out.</p>
      <p>
        On [Date] the vessel proceeded to [Location], for repairs. On [Date] the vessel was all fast alongside [Name] Shipyard.
        Repairs started immediately upon the vessel&apos;s arrival. The repairs were completed on [Date].
      </p>
      <p>
        The vessel departed for [Location] later on the same day for loading. The vessel was back on hire with charterers on [Date].
        After loading was completed, the vessel sailed to [Location] for discharge.
        The vessel remained under the same charter-party during the whole period from the time of loss.
      </p>
    </HelpTextLayout>
  </CollapsiblePane>
);
