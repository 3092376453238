import { ErrorBoundary } from '@instech/components';
import { ThemeProvider } from 'styled-components';
import { SWRConfig } from 'swr';
import { CurrentRoleProvider } from './common/authentication/CurrentRoleContext';
import { ModalHandler } from './common/modules/Modal';
import { TraversalOrderProvider } from './components/pages/claim/ClaimStatementPage/InvoiceTable/core/TraversalContext';
import { ErrorPage } from './components/pages/error/ErrorPage';
import { FeatureFlagProvider } from './common/providers/featureFlagProvider';
import { FontPreloadProvider } from './common/providers/fontPreloadProvider';
import { theme } from './utils/style/theme';
import { getJsonAsync } from './services/client';
import { PromptUnsavedChangesV6 } from './common/components/Form/PromptUnsavedChanges';
import { RootRouting } from './common/authentication/RootRouting';
import { CSSVariableProvider } from './common/providers/cssVariableProvider';

const swrConfig = {
  revalidateOnFocus: false,
  errorRetryCount: 3,
  fetcher: getJsonAsync
};

export const App = () => (
  <ThemeProvider theme={theme}>
    <ErrorBoundary component={ErrorPage}>
      <SWRConfig value={swrConfig}>
        <FeatureFlagProvider>
          <CSSVariableProvider>
            <FontPreloadProvider>
              <CurrentRoleProvider>
                <PromptUnsavedChangesV6>
                  <ModalHandler>
                    <TraversalOrderProvider>
                      <RootRouting />
                    </TraversalOrderProvider>
                  </ModalHandler>
                </PromptUnsavedChangesV6>
              </CurrentRoleProvider>
            </FontPreloadProvider>
          </CSSVariableProvider>
        </FeatureFlagProvider>
      </SWRConfig>
    </ErrorBoundary>
  </ThemeProvider>
);
