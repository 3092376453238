import {
  FC, useEffect, useState
} from 'react';
import styled from 'styled-components';
import { CollapsiblePane, ImagePreview } from '@instech/components';
import { useAdjClaimStatementInformationImage } from '@/apps/Adjustment/services/adjustmentOverviewService';
import { AxiosResponse } from 'axios';
import { FileDropzoneInformationImage } from './FileDropzoneInformationImage';
import { ImageViewBlock } from './ImageViewBlock';

const Layout = styled.div`
  display: grid;
  grid-template-columns: auto min-content;
  gap: 20px;
`;

function isClaimStatementInformationImageResponse(obj: any): obj is AxiosResponse {
  return obj.data !== undefined;
}

interface Props {
  claimStatementId: string;
  adjustmentId: string;
}

export const ClaimStatementInfomrationImage: FC<Props> = ({ claimStatementId, adjustmentId }) => {
  const { data, mutate, uploadInfoImage } = useAdjClaimStatementInformationImage(claimStatementId, adjustmentId);
  const [imageData, setImageData] = useState<ImagePreview | null | undefined>(null);
  const [uploadError, setUploadError] = useState<string>();

  useEffect(() => {
    if (data?.url && data?.name && data?.id) {
      setImageData({ title: data.name, description: data.name, url: data.url, id: data.id });
      setUploadError(undefined);
    } else {
      setImageData(null);
    }
  }, [data]);

  const handleUpload = async (files: File[]) => {
    setImageData(undefined);
    setUploadError(undefined);

    const result = await uploadInfoImage(files[0]);
    if (isClaimStatementInformationImageResponse(result)) {
      await mutate(result.data);
    } else if (result === 'SizeTooLarge') {
      setUploadError('File size is too large');
    } else {
      setUploadError('File type is not supported');
    }
  };

  return (
    <CollapsiblePane color="green" title="Vessel Image" padding="20px" margin="20px 0 0">
      <Layout>
        <FileDropzoneInformationImage id="cs-image-dropzone" handleUpload={handleUpload} />
        <ImageViewBlock imageData={imageData} uploadError={uploadError} />
      </Layout>
    </CollapsiblePane>
  );
};
