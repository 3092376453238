import { OnChangeFn, RowSelectionState } from '@tanstack/react-table';
import {
  useCallback, useMemo, useState
} from 'react';

export type ManagedTableSelectState = {
  rowSelection: RowSelectionState;
  setRowSelection: OnChangeFn<RowSelectionState>;
}

interface ManagedSelectionReturn {
  tableState: ManagedTableSelectState;
  selectionArray: string[];
  updateSelection: (selection: string[]) => void;
}

/**
 * State hook for controlled Tanstack Table selection.
 *
 * The internal state management in Tanstack prefers a Record<string, boolean> to track
 * row selection state, where the key of Record is the row's ID. This hook manages that
 * state while also providing access to it as an array of strings.
 *
 * @return `tableState.rowSelection`: Bind to table's `state.rowSelection`.
 * @return `tableState.setRowSelection`: Bind to table's `onRowSelectionChange`.
 * @return `selectionArray`: Current selection state as `string[]`.
 * @return `updateSelection`: Callback func to pass `string[]` as new selection state.
 */
export const useManagedTableSelect = (initialState: RowSelectionState = {}): ManagedSelectionReturn => {
  const [rowSelection, setRowSelection] = useState(initialState);

  const selectionArray = useMemo(() => (
    Object.keys(rowSelection)
  ), [rowSelection]);

  const updateSelection = useCallback((selection: string[]) => {
    const record = selection.reduce((acc, value) => ({ ...acc, [value]: true }), {});
    setRowSelection(record);
  }, []);

  return ({
    tableState: {
      rowSelection,
      setRowSelection,
    },
    selectionArray,
    updateSelection
  });
};
