import { ClaimStatement } from '@/types';
import { Pane } from '@instech/components';
import { FunctionComponent as FC } from 'react';
import { InformationView } from './InformationView';
import { useDetailsClaimReview } from './utils';

interface Props {
  data?: ClaimStatement;
}
/**
 * Temporary workaround component to make Claim Review page use the old
 * Claim Details component.
 * TODO: Follow up after decision is made on what to do with ClaimDetails in print
 */
export const ClaimDetailsClaimReview: FC<Props> = ({ data }) => {
  const claimReviewDetails = useDetailsClaimReview(data);

  return (
    <Pane
      title="Claim Statement Information"
      color="green"
      padding="20px">
      <InformationView
        details={claimReviewDetails.details}
        maxColumns={4}
        showCircle
      />
    </Pane>
  );
};
