import { documentCategoryValues } from '@/services/supportingDocumentsServices';
import { stringMaxLength } from '@instech/components';
import * as Yup from 'yup';

export const paymentConfirmationValidationSchema = Yup.object({
  documentCategory: Yup.object({
    label: Yup.string(),
    value: Yup.string().equals([documentCategoryValues.paymentConfirmation])
  }).notRequired(),
  pii: Yup.mixed().required('This is a required field.'),
  piiDescription: stringMaxLength(110).notRequired()
});
