import { CurrencySelector } from '@/common/components/Form/CurrencySelector/CurrencySelector';
import {
  Invoice,
  SurveyorsConsiderationSplitForm,
  UpdateSurveyorsConsiderationForm
} from '@/types';
import { CollapsiblePane, LabelValuePair } from '@instech/components';
import { useFormikContext } from 'formik';
import { INVOICE_PANE_MARGIN } from '@/utils/style/styleConstants';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useConsiderationContext } from '../InvoiceForm/utils/ConsiderationProvider';
import { NoExchangeRateInfo } from '../core/NoExchangeRateInfo';
import { ConsiderationFields } from './ConsiderationFields';
import { useSynchronizedLabelValues } from '../utils/useSynchronizedLabelValues';
import { multipleCategoriesLabelValuePair } from '../utils/multipleDropdownValue';

export const newSurveyorsConsiderationFormValues = (
  isConsidered?: boolean,
  hasInternalNotes?: boolean,
  startCollapsed?: boolean
) => ({
  arrayId: uuidv4(),
  startCollapsed: startCollapsed ?? false,
  hasInternalNotes: hasInternalNotes ?? false,
  claimConsidered: isConsidered ?? true,
  ownersWorkConsidered: isConsidered ?? true
});

export const newSurveyorsConsiderationItem = (
  firstOccurrence: LabelValuePair,
  consideration?: SurveyorsConsiderationSplitForm,
): SurveyorsConsiderationSplitForm => ({
  form: newSurveyorsConsiderationFormValues(),
  id: uuidv4(),
  generalExpenses: '',
  claimConsideration: '',
  ownersWorkConsideration: '',
  adjustersConsideration: '',
  surveyorsComment: '',
  internalNotes: '',
  initialClaim: undefined,
  initialOwnersWork: undefined,
  isConsidered: true, // new items needs to initialize this true, data from backend will override it
  category: consideration?.category ?? undefined,
  occurrence: consideration?.occurrence ?? firstOccurrence
});

interface Props {
  invoice: Invoice;
  policyCurrency?: string | null;
  categoryOptions: LabelValuePair[];
  occurrenceOptions: LabelValuePair[];
  updateSplitsOrder: (splitIdOrder: SurveyorsConsiderationSplitForm[]) => void;
}

export const SurveyorsConsiderationPane = ({
  invoice,
  policyCurrency,
  categoryOptions,
  occurrenceOptions,
  updateSplitsOrder
}: Props) => {
  const { setConsiderationData } = useConsiderationContext();
  const [selectedCurrency, setCurrency] = useState(invoice.record.currency);
  const { values } = useFormikContext<UpdateSurveyorsConsiderationForm>();
  useSynchronizedLabelValues<UpdateSurveyorsConsiderationForm>(invoice.record.category, multipleCategoriesLabelValuePair, 'category');

  useEffect(() => {
    setConsiderationData(values.splits); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnBlur = (event: any) => {
    setConsiderationData(values.splits);
  };

  const handleCurrencyChange = (currency: string) => {
    setCurrency(currency);
  };

  // fields should convert values but there is no exchange rate, so show relevant info message to user
  const noExchangeRateVisible = (policyCurrency === selectedCurrency && !invoice.record.exchangeRate);

  return (
    <CollapsiblePane padding="20px" margin={INVOICE_PANE_MARGIN} title="Surveyor's Consideration" color="green">
      <CurrencySelector
        first={invoice.record.currency}
        second={policyCurrency}
        selected={selectedCurrency}
        setSelected={handleCurrencyChange}
        id="surveyors-edit-pane" />
      <NoExchangeRateInfo isVisible={noExchangeRateVisible} />
      <div onBlur={handleOnBlur}>
        <ConsiderationFields
          invoice={invoice}
          selectedCurrency={selectedCurrency}
          categoryOptions={categoryOptions}
          occurrenceOptions={occurrenceOptions}
          updateSplitsOrder={updateSplitsOrder}
        />
      </div>
    </CollapsiblePane>
  );
};
