import React, { FunctionComponent as FC } from 'react';
import {
  TableHeaderRow, TableSubheaderGap, TableSubheaderClear
} from '@instech/components';
import { CollapseButton } from './CollapseColumns';
import { CollapsibleButtonSegment } from './types';

interface Props {
  segments: CollapsibleButtonSegment[];
  selectableRows?: boolean;
  stickyTop: string;
  collapsedGroups: string[];
  onClick: (groupName: string) => any;
}
export const CollapsibleTableCollapseButtons: FC<Props> = ({
  segments,
  selectableRows,
  stickyTop,
  collapsedGroups,
  onClick
}) => (
  <TableHeaderRow stickyTop={stickyTop}>
    {selectableRows && (
      <TableSubheaderGap key="table-collapse-checkbox" />
    )}
    {segments.map(segment => (
      !segment.title ? (
        <TableSubheaderGap
          key={`table-collapse-${segment.key}`}
          span={segment.span} />
      ) : (
        <React.Fragment key={`table-collapse-${segment.key}`}>
          <CollapseButton
            title={segment.title}
            collapsed={collapsedGroups?.includes(segment.title)}
            onClick={onClick} />
          {segment.span >= 2 && (
            <TableSubheaderGap
              span={segment.span - 1}
              data-collapse-group={segment.title} />
          )}
        </React.Fragment>
      )
    ))}
    <TableSubheaderClear />
  </TableHeaderRow>
);
