import { useRef } from 'react';
import { Loader, SlimButton } from '@instech/components';
import { Guid } from '@/types';
import { ButtonGroupModal } from '@/common/components/ButtonGroup/ButtonGroupModal';
import styled from 'styled-components';
import { Copy, Link as LinkIcon } from '@instech/icons';
import { useDownloadAccessKey } from '@/services/magicLinkService';
import { useModalContext } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import { Link } from 'react-router-dom';

const ModalText = styled.p`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 400;
  color: ${props => props.theme.marineBlue};
  margin: 0;
  padding-right: 80px;
`;

const SelectableText = styled(ModalText)`
  border: 1px solid #000;
  color: #000;
  font-size: 14px;
  margin-top: 40px;
  padding: 10px;
`;

const LinkText = styled(Link)`
  font-size: 16px;
  text-decoration: underline;
`;

interface ShareDownloadLinkModal {
  claimStatementId: Guid;
  vesselName?: string;
}

const ModalContent = ({ claimStatementId, vesselName }: ShareDownloadLinkModal) => {
  const { close } = useModalContext();
  const clipboardCopyRef = useRef<HTMLDivElement>(null);

  const { accessKey } = useDownloadAccessKey(claimStatementId);
  if (accessKey === null) return <Loader />;

  const copyDownloadLink = async () => {
    await navigator.clipboard.writeText(accessKey);
  };

  const copyDownloadText = async () => {
    const text = clipboardCopyRef.current!.innerHTML;
    const type = 'text/html';
    const blob = new Blob([text], { type });
    const data = [new ClipboardItem({ [type]: blob })];
    await navigator.clipboard.write(data!);
  };

  return (
    <div>
      <div>
        <ModalText>
          To share access to download all invoices, attachments and the “complete
          invoice table with all columns” excel file for this claim statement,
          copy the link below and paste it into your e-mail.
        </ModalText>
        <SelectableText ref={clipboardCopyRef}>
          <p>
            Please use this link to access our Claim Statement portal where you can
            download all files related to this case, along with the Owner’s Claim
            and the Surveyors Consideration. The link is valid for 10 days.
          </p>
          <LinkText to={accessKey} target="_blank" rel="noopener noreferrer">
            Download all files for
            {' '}
            {vesselName}
          </LinkText>
          <p>
            Or copy this URL:
            <div>{accessKey}</div>
          </p>
        </SelectableText>
      </div>
      <ButtonGroupModal marginTop="24px" buttonGap="16px">
        <SlimButton variant="secondary" type="reset" onClick={close}>CLOSE</SlimButton>
        <SlimButton variant="secondary" startIcon={<LinkIcon />} onClick={copyDownloadLink}>COPY LINK</SlimButton>
        <SlimButton startIcon={<Copy />} onClick={copyDownloadText}>COPY TEXT &amp; LINK TO CLIPBOARD</SlimButton>
      </ButtonGroupModal>
    </div>
  );
};

export const shareDownloadLinkModal = (
  { claimStatementId, vesselName }: ShareDownloadLinkModal
): ModalType<ShareDownloadLinkModal> => ({
  component: ModalContent,
  options: {
    title: 'Share download link',
    size: 'medium',
    padding: '12px 20px 20px',
  },
  args: { claimStatementId, vesselName }
});
