import styled from 'styled-components';
import { DatePicker } from '@instech/components';
import { CurrencyFieldLimited } from '@/components/pages/invoice/core/EditInvoice/core/CurrencyFieldLimited';
import { DecimalNumberField } from '@/common/components/Form/DecimalNumberField';
import { HighlightEffect } from './HighlightEffect';

export { HighlightEffect };

export const DatePickerHighlighted = styled(DatePicker)`
  color: ${props => props.theme.marineBlue};
  &[data-highlight="true"] input {
    ${HighlightEffect}
  }
`;

export const CurrencyFieldHighlighted = styled(CurrencyFieldLimited)`
  & input[data-highlight="true"] {
    ${HighlightEffect}
  }
`;

export const DecimalNumberFieldHighlighted = styled(DecimalNumberField)`
  & input[data-highlight="true"] {
    ${HighlightEffect}
  }
`;
