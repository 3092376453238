import { useEffect } from 'react';
import { FCWC } from '@/types';
import { usePromptContext } from './PromptOnUnsavedChanges';

interface PromptStateHandlerProps {
  dirty: boolean;
  /** Required if there are more than one form on the page */
  formId?: string;
}
export const PromptStateHandler: FCWC<PromptStateHandlerProps> = (
  { children, dirty, formId = 'only-one-form-on-page' }
) => {
  const { setDirtyForms } = usePromptContext();

  useEffect(() => {
    setDirtyForms(formId, dirty);
  }, [dirty, formId, setDirtyForms]);

  useEffect(() => () => {
    setDirtyForms(formId, false);
  }, [setDirtyForms, formId]);

  return <>{children}</>;
};
