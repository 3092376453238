import { CollapsibleTableRowData, CollapsibleTableSchemaReturn } from '@/common/components/Table/CollapsibleTable/types';
import { Invoice } from '@/types';
import {
  checkSubrowComments, checkSubrowsAllFinished, invoiceArrayToSingle
} from '../../../core/utils';
import {
  groupByCategoryCollapse, groupByCategoryHeader, groupByCategorySubheader
} from './groupByCategoryHeaders';
import { groupByCategoryRow } from './groupByCategoryRow';
import { groupByCategorySubrow } from './groupByCategorySubrow';
import { CSTableProps } from '../useSchemaByTableGrouping';

interface InvoicesByCategory {
  [name: string]: Invoice[];
}

type ByCategoryTableData = {
  row: Invoice;
  subrows: Invoice[];
}[];

// Schema by category has invoices as subrows, so it should always be able to expand
const MIN_SUBROWS_TO_EXPAND = 1;

// Take an array of invoices and return an array where 'subrows' is every invoice
// that shares the same category, and 'row' is the sum of those invoices' values
const parseDataToCategoryGrouping = (data: Invoice[]): ByCategoryTableData => {
  const byCategory: InvoicesByCategory = {};
  data.forEach(invoice => {
    // Cast absence of a category to a dash '-' to have a usable key
    const category = invoice.record.category ?? '-';
    if (!byCategory[category]) byCategory[category] = [];
    byCategory[category].push(invoice);
  });

  return Object.values(byCategory).map(invoiceGroup => ({
    row: invoiceArrayToSingle(invoiceGroup),
    subrows: invoiceGroup
  }));
};

// Crunch through and return ready table rows and subrows
const composeTableRowData = (
  data: Invoice[],
  props: CSTableProps
): CollapsibleTableRowData[] => {
  const categoryData = parseDataToCategoryGrouping(data);
  const segments = categoryData.map<CollapsibleTableRowData>(group => {
    const subRowsIdList = group.subrows.map(subrow => subrow.id);
    const allFinished = checkSubrowsAllFinished(group.subrows);
    const someComments = checkSubrowComments(group.subrows);
    return ({
      row: groupByCategoryRow(group.row, props, subRowsIdList, allFinished, someComments),
      subrows: group.subrows.map(subrow => (
        groupByCategorySubrow(subrow, props, subRowsIdList)
      ))
    });
  });
  return segments;
};

/**
 * Takes table data, as well as properties needed for table cell contents, and
 * returns a an object of the different parts of the table schema (header sections,
 * table row content, etc.)
 */
export const createTableSchemaByCategory = (
  data: Invoice[],
  props: CSTableProps
): CollapsibleTableSchemaReturn => {
  const { claimStatementSettings } = props;

  const selectColumn = props.selectableRows ? '64px' : '';
  const commentColumn = (props.showReviewComment && !props.usePrintSchema) ? 'auto' : '';
  const genAvgColumn = claimStatementSettings?.hasGeneralAverage ? 'auto' : '';

  return ({
    layout: `${selectColumn} auto ${genAvgColumn} repeat(11, auto) ${commentColumn}`,
    subrowsHeaderText: 'Invoices',
    minSubrowsToExpand: MIN_SUBROWS_TO_EXPAND,
    selectableRows: props.selectableRows,
    collapseButtons: groupByCategoryCollapse(props),
    header: groupByCategoryHeader(props),
    subheader: groupByCategorySubheader(props),
    tableRows: composeTableRowData(data, props)
  });
};
