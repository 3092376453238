import {
  useState, useMemo, useEffect
} from 'react';
import useSWR, { mutate as globalMutate } from 'swr';
import { LabelValuePair } from '@instech/components';
import {
  ClaimStatementRequestQuery, ClaimStatement, ClaimStatementStatus
} from '@/types';
import client from '../client';
import { useClaimsHandlerOptions } from './useClaimsHandlerOptions';

const getAllClaimStatementsUrl = '/claimstatements/all';

const getAllClaimStatements = async (query: ClaimStatementRequestQuery) => {
  const response = await client.post<ClaimStatement[]>(getAllClaimStatementsUrl, query);
  return response.data;
};

/**
 * If there are selected users, returns fetch URL and prepared query for
 * fetching claim statements based on userIds. Otherwise returns empty
 * URL to signal SWR not to fetch anything.
 */
const useSelectedClaimsHandlersQuery = (
  selectedOptions: LabelValuePair[],
  claimStatementStatus: ClaimStatementStatus
) => useMemo(() => {
  const userIds: string[] = selectedOptions.map(x => x.value);
  const claimStatementsUrl: (string | null) = userIds.length === 0 ? null : getAllClaimStatementsUrl;

  const fetcher = () => getAllClaimStatements(({ claimsHandlerUserInfoIds: userIds, status: claimStatementStatus }));

  return {
    claimStatementsUrl,
    fetcher
  };
}, [claimStatementStatus, selectedOptions]);

/**
 * Hook for fetching Claim Statements based on selected users. The hook also
 * contains all required states for tracking these selected users and whether
 * to fetch ('Open' | 'Closed') Claim Statements.
 */
export const useAllClaimStatements = () => {
  const { options, selectedOptions, setSelectedOptions } = useClaimsHandlerOptions();
  const [selectedStatus, setSelectedStatus] = useState<ClaimStatementStatus>('Open');

  const { claimStatementsUrl, fetcher } = useSelectedClaimsHandlersQuery(selectedOptions, selectedStatus);

  // TODO: Figure out if SWR 2.0 improves Multiple Arguments handling
  // The 'intended' approach for args into Fetcher in SWR 1.2 creates duplicate keys,
  // which is a problem here. So for now, revalidation is handled via useEffect() below.
  const { data, isValidating, error, mutate } = useSWR<ClaimStatement[]>(claimStatementsUrl, fetcher);

  // Manually force revalidation if known params change, see comment on useSWR hook.
  useEffect(() => {
    if (selectedStatus && selectedOptions.length) void mutate();
  }, [selectedOptions.length, selectedStatus, mutate]);

  const deleteClaimStatement = async (claimStatementId: string) => {
    const result = await client.delete(`claimstatements/${claimStatementId}`);
    const remainingClaimStatements = data?.filter(claimStatement => claimStatement.id !== claimStatementId);
    if (result!.status === 200) {
      void globalMutate(getAllClaimStatementsUrl, remainingClaimStatements, false);
    }
  };

  return {
    data,
    isValidating,
    deleteClaimStatement,
    error,
    claimsHandlerOptions: { options, selectedOptions, setSelectedOptions },
    claimStatementStatus: { selectedStatus, setSelectedStatus }
  };
};
