import { UserInfoDto } from '@/types';
import { CollapsedUserDetailsList } from '@/common/components/UserDetails/CollapsedUserDetailsList';

interface ContactListProps {
  contacts: UserInfoDto | UserInfoDto[];
}
export const ContactList = ({ contacts }: ContactListProps) => {
  const contactArray = Array.isArray(contacts) ? contacts : [contacts];
  return (
    <CollapsedUserDetailsList users={contactArray} />
  );
};
