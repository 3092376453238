import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { TextArea, SlimButton } from '@instech/components';
import {
  Guid, InvoiceState, InvoiceStateMetadata
} from '@/types';
import { ModalType } from '@/common/modules/Modal/types';
import { useModalContext } from '@/common/modules/Modal';
import { ButtonGroupModal } from '@/common/components/ButtonGroup/ButtonGroupModal';
import { useLatestSurveyorsInstructions } from '@/services/invoice/invoiceServices';
import { invoiceStates } from '@/utils/constants';

const Instructions = styled(TextArea)`
  & div > textarea {
    min-height: 140px;
  }
`;

const StyledText = styled.div`
  color: ${props => props.theme.marineBlue};
   margin-bottom: 32px;
`;

const CostAllocated = () => (
  <StyledText>
    This invoice will be returned to the Surveyor for further cost allocations.
    <br />
    If needed, edit the previous instructions below.
  </StyledText>
);

interface TransferClaimsHandlerToSurveyorProps {
  claimStatementId: Guid;
  invoices: InvoiceStateMetadata[];
  transfer: (destinationState: InvoiceState, instructions: string) => Promise<string>;
}

const ModalContent = ({ invoices, transfer, claimStatementId }: TransferClaimsHandlerToSurveyorProps) => {
  const { close } = useModalContext();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { data } = useLatestSurveyorsInstructions(claimStatementId, invoices[0].id);

  const handleTransfer = async (values: any) => {
    setSubmitting(true);
    const destinationState = invoiceStates.CostReallocation;
    const trimInstructions = values.instructions.trim();
    await transfer(destinationState, trimInstructions);
    close();
  };

  return (
    <Formik
      initialValues={{ stateOptions: invoiceStates.CostAllocation, instructions: data?.instructions ?? '' }}
      onSubmit={handleTransfer}
      enableReinitialize
    >
      <Form>
        <CostAllocated />
        <Instructions
          name="instructions"
          label="Instructions to Surveyor"
          placeholder="Text"
          noErrors
        />
        <ButtonGroupModal marginTop="24px">
          <SlimButton onClick={close} variant="secondary">
            CANCEL
          </SlimButton>
          <SlimButton type="submit" width="60px" disabled={submitting} loading={submitting}>
            SHARE
          </SlimButton>
        </ButtonGroupModal>
      </Form>
    </Formik>
  );
};

export const returnSingleInvoiceClaimsHandlerToSurveyorModal = (
  { invoices, transfer, claimStatementId }: TransferClaimsHandlerToSurveyorProps
): ModalType<TransferClaimsHandlerToSurveyorProps> => ({
  component: ModalContent,
  options: {
    title: 'Share with Surveyor',
    size: 'small',
    padding: '24px'
  },
  args: { invoices, transfer, claimStatementId }
});
