import React, { FunctionComponent } from 'react';
import { TabbedPane } from '@instech/components';
import { UserSingle } from '@instech/icons';
import {
  ClaimSearchData, ClaimStatement, UserInfoDto
} from '@/types';
import { roles } from '@/utils/constants';
import styled from 'styled-components';
import { useCurrentRole } from '@/common/authentication/CurrentRoleContext';
import { FetchUsersOptions } from '@/services/userInfoServices';
import { ContactList } from './ContactList';
import { SelectUsers } from './SelectUsers';

const StyledTabContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type TabContent = {
  contacts: UserInfoDto | UserInfoDto[];
  includeAddButton?: boolean;
  claimSearchData?: ClaimSearchData;
  fetchUsersOptions: FetchUsersOptions;
  onSelect?: (user: UserInfoDto[]) => void;
}

type NewTab = Omit<TabContent, 'contacts'> & {
  title: string;
  contacts?: UserInfoDto | UserInfoDto[];
}

const tabContent = ({ contacts, includeAddButton, fetchUsersOptions, onSelect }: TabContent) => {
  const contactArray = Array.isArray(contacts) ? contacts : [contacts];
  return (
    <StyledTabContent>
      <ContactList contacts={contactArray} />
      {(includeAddButton && onSelect) &&
        <SelectUsers onSelect={onSelect} selected={contactArray} fetchUsersOptions={fetchUsersOptions} allowEmptySelection={false} />}
    </StyledTabContent>
  );
};

const newTab = ({ title, contacts, includeAddButton, fetchUsersOptions, onSelect }: NewTab) => ({
  title, component: tabContent({ contacts: contacts || [], includeAddButton, fetchUsersOptions, onSelect })
});

interface Props {
  data: ClaimStatement;
  onSelect?: (user: UserInfoDto[]) => void;
}

export const ContactsPane: FunctionComponent<Props> = ({ data, onSelect }) => {
  const role = useCurrentRole();
  const fetchUsersOptions: FetchUsersOptions = { claimStatementId: data.id, limitRoleAndCompany: true };
  const tabs = [
    newTab({ title: 'Owner', contacts: data.owners ?? data.owner, includeAddButton: role?.name === roles.Owner, fetchUsersOptions, onSelect }),
    newTab({ title: 'Broker', contacts: data.brokers, includeAddButton: role?.name === roles.Broker, fetchUsersOptions, onSelect }),
    newTab({ title: 'Surveyor', contacts: data.surveyors, includeAddButton: role?.name === roles.Surveyor, fetchUsersOptions, onSelect }),
    newTab({ title: 'CH / Adjuster', contacts: data.claimsHandlers ?? data.claimsHandler, fetchUsersOptions })
  ];

  return (
    <div>
      <TabbedPane
        title="Contacts"
        icon={<UserSingle />}
        color="green"
        tabs={tabs}
        padding="24px 20px"
      />
    </div>
  );
};
