import { testValidNumberWithGivenDecimals } from './number';

export interface LimitedNumberReturnVal {
  value: string;
  validInput: boolean;
}
export interface LimitedNumberOptions {
  maxLength: number;
  maxDecimals: number;
  minValue?: number;
}
/**
 * Validates a string input to verify that it is a number adhering to:
 * - Dot punctuation mark (auto-convert comma to dot)
 * - Decimal precision (defaults to 2)
 * - Max given number of characters (ex. 21 chars to prevent 1.05e+20) (Optional)
 */
export const validateLimitedNumberInput = (value: string, options?: Partial<LimitedNumberOptions>): LimitedNumberReturnVal => {
  if (!value) return { validInput: true, value };
  const opt: LimitedNumberOptions = { maxLength: 0, maxDecimals: 2, ...options };

  const valReplaceComma = value.replace(/,/g, '.');

  const validDecimalSpaces = testValidNumberWithGivenDecimals(valReplaceComma, opt.maxDecimals);
  const validNumberLength = opt.maxLength === 0 ? true : value.replace(/,/g, '').length <= opt.maxLength;
  const validMinValue = opt.minValue === undefined ? true : Number(valReplaceComma) >= opt.minValue;
  const validInput = validDecimalSpaces && validNumberLength && validMinValue;

  return { validInput, value: valReplaceComma };
};
