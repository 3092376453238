import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import {
  ClaimStatementSettings, ClaimsHandlerInvoiceSummary, UserRoles
} from '@/types';
import { Pane } from '@instech/components';
import { FunctionComponent } from 'react';
import { SummarySection } from './SummarySection';
import { summaryContent, summaryReviewContent } from './utils/summaryContent';

export interface ShowSummary{
  ownerSummary: boolean;
  surveyorSummary: boolean;
  adjusterSummary: boolean;
}

interface Props {
  summary: ClaimsHandlerInvoiceSummary;
  claimStatementSettings?: ClaimStatementSettings
  showSummary?: ShowSummary;
  isReview?: boolean;
  userRole?: UserRoles;
  showSubTitle?: boolean;
}
export const SummaryPane: FunctionComponent<Props> = ({
  summary,
  claimStatementSettings,
  showSummary = { ownerSummary: true, surveyorSummary: true, adjusterSummary: true },
  isReview = false,
  userRole,
  showSubTitle = true
}) => {
  const content = isReview ?
    summaryReviewContent(summary, userRole, claimStatementSettings?.hasGeneralAverage, showSubTitle) :
    summaryContent(summary, userRole, claimStatementSettings?.hasGeneralAverage);

  const title = `Summary - ${summary.currency}`;
  return (
    <Pane color="green" title={title} subTitle={content.subTitle} padding="20px 16px">
      <SharedTooltip id="claimshandler-summary-tooltip">
        {showSummary.ownerSummary && (
          <SummarySection
            title={content.ownersClaim.title}
            data={content.ownersClaim.fields}
            variant={1}
          />
        )}
        {showSummary.surveyorSummary && (
          <SummarySection
            title={content.surveyorsAllocation.title}
            data={content.surveyorsAllocation.fields}
            variant={2}
            marginTop="16px"
          />
        )}
        {showSummary.adjusterSummary && (
          <SummarySection
            title={content.adjustersAllocation.title}
            data={content.adjustersAllocation.fields}
            variant={3}
            marginTop="16px"
          />
        )}
      </SharedTooltip>
    </Pane>
  );
};
