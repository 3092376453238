import { ClaimStatementSettings, InvoiceOverview } from '@/types';
import {
  CollapsibleTableCollapseButtons,
  CollapsibleTableHeaders,
  CollapsibleTableRows,
  useSortedCollapsibleTableData
} from '@/common/components/Table/CollapsibleTable';
import {
  ComplexTable, Pane, SortedTableHeader
} from '@instech/components';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { CollapseWrapper } from '@/common/components/Table/CollapsibleTable/CollapseColumns';
import styled from 'styled-components';
import { FitToContent } from '@/common/layout/FitToContent';
import { useMemo, useState } from 'react';
import { GroupingOption, groupingOptionsList } from '@/common/components/Table/TableGrouping/types';
import { arrayAddOrRemove } from '@/utils/array';
import { useExpandedTableRows } from '@/common/components/Table/utils';
import { useTableSchemaByGrouping } from '../InvoiceTableClaimsHandler/utils';

// Calculate the stickyTop offsets of the different table elements, based on the height of the elements
const getStickyTopValues = () => {
  const baseGapHeight = 50 + 20;
  const collapseHeight = 57;
  const subheaderHeight = 33;

  const collapseStickyTop = baseGapHeight;
  const subheaderStickyTop = collapseStickyTop + collapseHeight;
  const headerStickyTop = subheaderStickyTop + subheaderHeight;

  return {
    collapse: `${collapseStickyTop}px`,
    subheader: `${subheaderStickyTop}px`,
    header: `${headerStickyTop}px`
  };
};

const FitTableArea = styled(FitToContent)`
  padding: 0 40px 40px 0;
  box-sizing: border-box;
`;

const Spacing = styled.div`
  margin-top: 76px;
`;

interface Props {
  data: InvoiceOverview;
  title: string;
  claimStatementSettings?: ClaimStatementSettings
}
export const InvoiceTableMagicLink = ({ data, title, claimStatementSettings }: Props) => {
  const { expanded, toggleExpanded } = useExpandedTableRows(data.invoices.map(invoice => invoice.id));
  const [selectedGrouping] = useState<GroupingOption>(groupingOptionsList[0]);
  const [sortedHeader, setSortedHeader] = useState<SortedTableHeader>();
  const [collapsedGroups, setCollapsedGroups] = useState<string[]>([]);
  const tooltipId = 'invoice-table-tooltip';

  // Based on currently selected grouping option, generate and return table segments
  const tableData = useTableSchemaByGrouping(
    selectedGrouping,
    data.invoices,
    {
      selectableRows: false,
      showReview: false,
      currency: data.currency,
      claimStatementSettings,
      onRowClick: () => { },
      toggleExpanded,
      expanded
    }
  );

  const sortedTable = useSortedCollapsibleTableData(tableData.tableRows, sortedHeader);
  const stickyTop = useMemo(() => getStickyTopValues(), []);

  const handleCollapse = (range: string) => {
    const newRanges = arrayAddOrRemove(collapsedGroups, range);
    setCollapsedGroups([...newRanges]);
  };

  const handleSortedHeader = (header: SortedTableHeader) => {
    setSortedHeader(header);
  };

  return (
    <FitTableArea>
      <Pane title={title} color="green" padding="0px">
        <SharedTooltip id={tooltipId}>
          <CollapseWrapper collapsedGroups={collapsedGroups}>
            <Spacing>
              <ComplexTable layout={tableData.layout}>
                <CollapsibleTableCollapseButtons
                  segments={tableData.collapseButtons}
                  collapsedGroups={collapsedGroups}
                  onClick={handleCollapse}
                  stickyTop={stickyTop.collapse}
                />
                <CollapsibleTableHeaders
                  segments={tableData.header}
                  sortedHeader={sortedHeader}
                  setSortedHeader={handleSortedHeader}
                  subrowsHeaderText="Splits"
                  allItems={data.invoices.length}
                  selectableRows={false}
                  stickyTop={stickyTop.header}
                />
                <CollapsibleTableRows
                  tableRows={sortedTable.data}
                  minSubrowsToExpand={tableData.minSubrowsToExpand}
                  showFullComments={false}
                  selectableRows={false}
                  expandableRows
                  canDrillDown={false}
                />
              </ComplexTable>
            </Spacing>
          </CollapseWrapper>
        </SharedTooltip>
      </Pane>
    </FitTableArea>
  );
};
