import { IconButton, TableCell } from '@instech/components';
import { Apply } from '@instech/icons';
import { FC } from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled(TableCell)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px 0px 10px;

  & button {
    position: relative;
    height: 100%;
    width: 54px;
    background-color: ${props => `${props.theme.marineBlue}25`};
    cursor: initial; // do not need to show pointer here for now
  }
  & svg {
    width: 12px;
    color: ${props => props.theme.marineBlue80};
  }
`;

interface ExpandedCellButtonProps {
}
export const ExpandedCellButton: FC<ExpandedCellButtonProps> = () => (
  <ButtonWrapper>
    <IconButton icon={<Apply />} smallIcon />
  </ButtonWrapper>
);
