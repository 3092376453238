import { useTypedModal } from '@/common/modules/Modal';
import {
  defaultTransferOwnerToClaimsHandlerProps,
  transferOwnerToClaimsHandlerModal
} from '@/components/pages/claim/ClaimStatementPage/InvoiceTable/modal/TransferOwnerToClaimsHandlerModal';
import { editableStates } from '@/components/pages/invoice/InvoicePage/OwnerInvoicePage';
import { InvoiceRequestStatus } from '@/services/invoice/invoiceServices';
import { updateInvoiceState } from '@/services/invoice/invoiceStateService';
import {
  Invoice, InvoiceState, UpdateInvoiceForm
} from '@/types';
import { getInvoiceStateFromOwnerToCH } from '@/utils/invoicesTransfer';
import { ButtonGroup } from '@instech/components';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { invoiceStates } from '@/utils/constants';
import {
  GrayCloseButton, GreenCloseButton, GreenSaveButton, SubmitToCHButton
} from './buttons/HeaderButtons';
import { InvoiceTraversalButtons } from './buttons/InvoiceTraversalButtons';
import { useSaveStatus } from '../utils/SaveStatus/useSaveStatus';

const readonlyStatesForOwner: InvoiceState[] = [
  invoiceStates.AdjustersConsideration,
  invoiceStates.CostAllocation,
  invoiceStates.Finished,
  invoiceStates.FinishedNoAllocation,
  invoiceStates.FinishedSharedReview,
  invoiceStates.Review,
  invoiceStates.SharedReview,
  invoiceStates.AdjustersConsiderationNoAllocation];
const submitToClaimsHandlerStates: InvoiceState[] = [invoiceStates.New, invoiceStates.ReturnToUploader, invoiceStates.ReturnToUploaderAfterConsideration];

interface InvoiceActionButtonsOwnerProps {
  invoice: Invoice;
  saveInvoice: () => Promise<Invoice | InvoiceRequestStatus>
}
export const InvoiceActionButtonsOwner = ({ saveInvoice, invoice }: InvoiceActionButtonsOwnerProps) => {
  const navigate = useNavigate();
  const navigateToClaimStatementPage = () => navigate(`/claimstatements/${invoice.claimStatementId}`);
  const { open: openModal } = useTypedModal(transferOwnerToClaimsHandlerModal(defaultTransferOwnerToClaimsHandlerProps));
  const { setFieldError, setFieldTouched, validateForm } = useFormikContext<UpdateInvoiceForm>();
  const [saveStatus, setSaveStatus] = useSaveStatus();

  const { cache } = useSWRConfig();
  const invoiceState = invoice?.state!;

  const resetErrors = useCallback(() => {
    setFieldError('supplier', undefined);
    setFieldError('invoiceNumber', undefined);
    setFieldError('totalAmount', undefined);
    setFieldError('currency', undefined);
  }, [setFieldError]);

  const saveForm = async () => {
    setSaveStatus('saving');
    const result = await saveInvoice();
    resetErrors();
    setSaveStatus('saved');
    return result;
  };

  const touchFieldsAndValidate = useCallback(async () => {
    setFieldTouched('totalAmount', true);
    setFieldTouched('supplier', true);
    setFieldTouched('invoiceNumber', true);
    setFieldTouched('currency', true);
    setFieldTouched('paymentDate', true);
    await validateForm();
  }, [setFieldTouched, validateForm]);

  const handleSubmitToClaimsHandler = async () => {
    const updatedInvoice = await saveInvoice() as Invoice;

    openModal({
      transfer: async () => {
        await updateInvoiceState(invoice.claimStatementId, {
          destinationState: getInvoiceStateFromOwnerToCH(invoiceState),
          invoiceIdList: [invoice.id],
        }, cache);

        navigateToClaimStatementPage();
      },
      numberOfIncompleteInvoices: updatedInvoice?.record.isComplete ? 0 : 1,
      numberOfInvoices: 1,
      onValidationFailed: touchFieldsAndValidate
    }, touchFieldsAndValidate);
  };

  const submittable = submitToClaimsHandlerStates.includes(invoice.state);
  const editable = editableStates.includes(invoice.state);
  const readonly = readonlyStatesForOwner.includes(invoice.state);

  return (
    <ButtonGroup>
      <SharedTooltip
        id="invoice-traversal-tooltip"
        bodyMode={false}
        effect="solid"
        place="bottom">
        {editable && <GrayCloseButton onClick={navigateToClaimStatementPage} />}
        <InvoiceTraversalButtons claimStatementId={invoice.claimStatementId} saveAction={saveInvoice} />
        {submittable && <SubmitToCHButton onClick={handleSubmitToClaimsHandler} />}
        {readonly && <GreenCloseButton onClick={navigateToClaimStatementPage} />}
        {editable && <GreenSaveButton status={saveStatus} onClick={saveForm} />}
      </SharedTooltip>
    </ButtonGroup>
  );
};
