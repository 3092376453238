// Check if value is [object Object], without false positives from (typeof 'object')
const isRealObject = (val: any) => (!!val && typeof val === 'object' && !Array.isArray(val));

// Check if value is not Number, without false positive from (typeof 'boolean')
const isRealNumber = (val: any) => (Number(val) && typeof val !== 'boolean');

/**
 * Takes an array and a number of chunks. It returns a new array, which contains
 * arrays that are equally split segments of the original array (the last chunk is
 * smaller than the rest if it could not split evenly).
 */
export const arrayIntoChunks = <T = any>(inputArray: T[], perChunk: number) => {
  const resultArray: any[] = [];
  for (let i = 0; i < inputArray.length; i += perChunk) {
    const chunk = inputArray.slice(i, i + perChunk);
    resultArray.push(chunk);
  }
  return resultArray;
};

/**
 * Split an array into [A, B], based on a provided conditional callback.
 * Useful as a shorthand function for partitioning an array based on a
 * known set of parameters. The callback must always return a boolean.
 */
export const arrayPartition = <T = any>(inputArray: T[], isValid: (value: T) => boolean): [T[], T[]] => {
  const [pass, fail]: [T[], T[]] = [[], []];
  inputArray.forEach(item => isValid(item) ? pass.push(item) : fail.push(item));
  return [pass, fail];
};

/**
 * Wrap around an item in an array to conditionally include it, without
 * needing to define an array and use array.push() wrapped in if-statements.
 *
 * Must be spread into the array (e.g. ...arrayAddConditionally(condition, item))
 */
export const arrayAddConditionally = (condition: boolean, item: any) => (
  condition ? [item] : []
);

/**
 * Shorthand returns last item of an array, or null if array is not valid
 */
export const arrayLastItem = <T = any>(inputArray?: T[]) => {
  if (!inputArray) return null;
  return inputArray[inputArray.length - 1];
};

/**
 * Takes an array and a value. It removes the value if it is in the array, or adds the
 * value if it is not in the array. To compare value and array, it uses a stringified
 * comparison of values.
 * It then returns the altered array with/without the value.
 */
export const arrayAddOrRemove = <T = any>(array: T[], value: any): T[] => {
  const newArray = array;
  // Brute force way of making this be OK with comparing objects, covers the
  // use case that was needed when writing this but can be made a deepEqual
  const index = array.findIndex(item => JSON.stringify(item) === JSON.stringify(value));
  if (index === -1) newArray.push(value);
  else newArray.splice(index, 1);
  return newArray;
};

/**
 * Deep equal using JSON.Stringify
 */
export const deepEqual = <T>(firstElement: T, secondElement: T) => JSON.stringify(firstElement) === JSON.stringify(secondElement);

/**
 * Takes an array of objects and reduces it down to a single object. In the returned
 * object, all number values are summed together. Non-number values (string, boolean, etc.)
 * return with what-ever was the value in the first array item.
 * Primarily written for the use-case of having an array of same objects of number vals,
 * and reducing that array down to a single object of the summarized values.
 */
export const arrayOfObjectsToSingle = <T = any>(array: T[]): T => {
  const newArray = array.reduce((result: any, current: any) => {
    const summed = { ...result };
    Object.keys(current).forEach((key: any) => {
      // Recursively merge values in objects within this object
      if (isRealObject(current[key])) {
        const subObjectArray = [summed[key], current[key]];
        summed[key] = arrayOfObjectsToSingle(subObjectArray);
        return;
      }
      if (!isRealNumber(current[key])) return;
      // Merge value with its summed counterpart, but cast NaN vals to 0
      summed[key] = (Number(summed[key]) || 0) + (Number(current[key]) || 0);
    });
    return summed;
  });
  return newArray;
};

/**
 * Adds all items in a numeric array
 */
export const sum = (values: (number | undefined)[]) =>
  values.reduce((total, number) => (total ?? 0) + (number ?? 0), 0);
