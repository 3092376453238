import {
  PieQuarter, PieRefresh, PieHalf,
  PieThreeQuarters, PieFinished, PieEmpty, PieFull
} from '@instech/icons';
import React from 'react';
import styled, { css } from 'styled-components';

const State = styled.div<{ size?: string, disabled?: boolean }>`
  display: flex;
  align-items: center;
  ${props => props.disabled && css`
    color: ${props.theme.marineBlue60};
  `};
  & svg {
    width: ${props => props.size || '22px'};
    height: ${props => props.size || '22px'};
  }
`;

const StateText = styled.div`
  line-height: 16px;
  font-size: 14px;
  padding-left: 5px;
`;

interface StateIconProps {
  size?: string;
}
interface StateIconWithVariant extends StateIconProps {
  variant?: boolean;
}

export const NewEmpty = ({ size }: StateIconProps) => (
  <State size={size} data-testid="invoice-state-new-empty">
    <PieEmpty />
    <StateText>Empty</StateText>
  </State>
);

export const NewPartial = ({ size }: StateIconProps) => (
  <State size={size} data-testid="invoice-state-new-partial">
    <PieHalf />
    <StateText>Partial</StateText>
  </State>
);

export const NewFilled = ({ size }: StateIconProps) => (
  <State size={size} data-testid="invoice-state-new-partial">
    <PieFull />
    <StateText>Filled</StateText>
  </State>
);

export const Review = ({ size }: StateIconProps) => (
  <State size={size} data-testid="invoice-state-review">
    <PieQuarter />
    <StateText>CH</StateText>
  </State>
);

export const ReturnedFromClaimsHandler = ({ size, variant }: StateIconWithVariant) => {
  const text = (variant && 'Returned from Claims Handler') || 'Returned';
  return (
    <State size={size} disabled data-testid="invoice-state-returnToUploader">
      <PieRefresh />
      <StateText>{text}</StateText>
    </State>
  );
};

export const ReturnToUploader = ({ size, variant }: StateIconWithVariant) => {
  const text = (variant && 'Returned to Owner') || 'Owner';
  return (
    <State size={size} disabled data-testid="invoice-state-returnToUploader">
      <PieRefresh />
      <StateText>{text}</StateText>
    </State>
  );
};

export const CostAllocation = ({ size }: StateIconProps) => (
  <State size={size} disabled data-testid="invoice-state-costAllocation">
    <PieHalf />
    <StateText>Surveyor</StateText>
  </State>
);

export const CostReallocation = ({ size }: StateIconProps) => (
  <State size={size} disabled data-testid="invoice-state-costReallocation">
    <PieHalf />
    <StateText>Surveyor</StateText>
  </State>
);

export const AdjustersConsideration = ({ size }: StateIconProps) => (
  <State size={size} data-testid="invoice-state-adjustersConsideration">
    <PieThreeQuarters />
    <StateText>CH/Adj.</StateText>
  </State>
);

export const Finished = ({ size }: StateIconProps) => (
  <State size={size} data-testid="invoice-state-finished">
    <PieFinished />
    <StateText>Finished</StateText>
  </State>
);
