import { Invoice } from '@/types';
import { arrayOfObjectsToSingle } from '@/utils/array';
import { calculateWithExchangeRate } from '@/utils/invoice/convertCurrency';
import { invoiceStatesFinished } from '@/utils/invoice/invoiceStates';

/**
 * Returns whether all provided Subrows have a Finished state. If true, we can
 * assume their parent Row should be shown with a green Finished background.
 */
export const checkSubrowsAllFinished = (subrows: Invoice[]) => (
  subrows.every(subrow => invoiceStatesFinished.includes(subrow.state))
);

/**
 * Returns true if any of the invoice subrows under a row have a value for
 * Owners' Comment on Claim Review, so that an indicator can be displayed.
 */
export const checkSubrowComments = (subrows: Invoice[]) => (
  subrows.some(subrow => !!subrow.ownersReviewComment)
);

/**
 * This feels like such a hack solution, but ...
 * We can't use arrayOfObjectsToSingle directly here, because each Invoice can
 * have a different exchangeRate. That exchangeRate needs to be taken into
 * consideration before the data is summarized. So as a solution to that,
 * this function will go through each Invoice, bake exchangeRate to all relevant
 * values and then summarize the values once that is done.
 */
export const invoiceArrayToSingle = (data: Invoice[]): Invoice => {
  const bakeExchangeRates = data.map(invoice => {
    const { exchangeRate } = invoice.record;
    const adjCons = invoice.adjustersConsideration;
    const survCons = invoice.surveyorsConsideration;
    return ({
      ...invoice,
      adjustersConsideration: {
        ...adjCons,
        commonExpenses: Number(calculateWithExchangeRate(adjCons.commonExpenses, exchangeRate)),
        ownersWorkConsideration: Number(calculateWithExchangeRate(adjCons.ownersWorkConsideration, exchangeRate)),
        particularAverage: Number(calculateWithExchangeRate(adjCons.particularAverage, exchangeRate)),
        generalAverage: Number(calculateWithExchangeRate(adjCons.generalAverage, exchangeRate))
      },
      surveyorsConsideration: !survCons ? undefined : {
        ...survCons,
        generalExpenses: Number(calculateWithExchangeRate(survCons?.generalExpenses, exchangeRate)),
        claimConsideration: Number(calculateWithExchangeRate(survCons?.claimConsideration, exchangeRate)),
        ownersWorkConsideration: Number(calculateWithExchangeRate(survCons?.ownersWorkConsideration, exchangeRate)),
        adjusterToConsider: Number(calculateWithExchangeRate(survCons?.adjusterToConsider, exchangeRate))
      }
    });
  });
  return arrayOfObjectsToSingle(bakeExchangeRates);
};
