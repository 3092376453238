import { InvoiceRecord } from '@/types';

/**
 * For use with new state: returns true if the invoice has
 * been completely filled by the Owner, otherwise returns false
 */
export const isInvoiceFilledState = (record?: InvoiceRecord) => {
  if (!record) return false;
  const requiredVals = [record?.invoiceNumber, record?.supplier, record?.currency, record?.totalAmount];
  return requiredVals.every(val => !!val);
};

/**
 * For use with new state: returns true if the invoice has
 * been partially filled by the Owner, otherwise returns false
 */
export const isInvoicePartialState = (record?: InvoiceRecord) => {
  if (!record) return false;
  const { order, isComplete, ...inputVals } = record;
  return Object.entries(inputVals).map(([_, val]) => val).some(val => !!val);
};
