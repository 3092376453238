import {
  Breadcrumbs, breadcrumbVesselName, reusableSteps
} from '@/common/components/Breadcrumbs';
import { ExtendedPageWrapper } from '@/common/layout/Page';
import { StickyPageContent } from '@/common/layout/StickyPage';
import { HeadingWrapper, StickyPageHeading } from '@/common/layout/PageLayout';
import { useTypedModal } from '@/common/modules/Modal';
import { ClosedClaimStatementWarning } from '@/components/shared/Warnings/ClosedClaimStatementWarning';
import { useClaimStatement, useIsClosed } from '@/services/claimStatementServices';
import { updateInvoiceState } from '@/services/invoice/invoiceStateService';
import { Guid, UserInfoDto } from '@/types';
import { invoiceStates } from '@/utils/constants';
import { invoicesWithSurveyorDeviation, invoicesWithoutSurveyorDeviation } from '@/utils/invoice/invoiceTotalDeviation';
import { MenuOption, PageLoader } from '@instech/components';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useCurrentRole } from '@/common/authentication/CurrentRoleContext';
import { updateParticipantsWithRole } from '@/services/participantsService';
import { useSWRConfig } from 'swr';
import { useCurrentUser } from '@/services/userInfoServices';
import { ClaimDetails } from '../core/ClaimDetails';
import { ContactsPane } from './ContactsPane/ContactsPane';
import { InvoiceTableSurveyor, InvoiceTableSurveyorReadonly } from './InvoiceTable/InvoiceTableSurveyor';
import { transferSurveyorToAdjusterModal } from './InvoiceTable/modal/TransferSurveyorToAdjusterModal';
import {
  useAggregatedSurveyorEditableInvoicesOverview,
  useAggregatedSurveyorReadonlyInvoicesOverview,
  useAggregatedSurveyorSubmittedInvoicesOverview
} from './utils/useAggregatedInvoices';

const PageGrid = styled.div`
  display: grid;
  grid-template-columns: auto 520px;
  grid-template-areas: "details contacts";
  column-gap: 48px;
  row-gap: 40px;
`;

const GridPlacement = styled.div<{ area?: string }>`
  grid-area: ${props => props.area};
`;

const TableArea = styled.div`
  padding: 0px 40px 40px;
`;

export const SurveyorClaimStatementPage = () => {
  const { claimStatementId } = useParams();
  const role = useCurrentRole();
  const currentUser = useCurrentUser();
  const { cache } = useSWRConfig();
  const { data: claimStatement, mutate: refreshClaimStatement } = useClaimStatement(claimStatementId);
  const { data: editableOverview, mutate: refreshEditableOverview } = useAggregatedSurveyorEditableInvoicesOverview(claimStatementId);
  const { data: readonlyOverview } = useAggregatedSurveyorReadonlyInvoicesOverview(claimStatementId);
  const { data: submittedOverview, mutate: refreshSubmittedOverview } = useAggregatedSurveyorSubmittedInvoicesOverview(claimStatementId);
  const navigate = useNavigate();
  const isClosed = useIsClosed(claimStatement);

  const navigateToInvoice = (invoiceId: string) => {
    navigate(`invoices/${invoiceId}`);
  };

  // actual params provided in showTransferModal function, just setting up hook here
  const { open: openTransferModal } = useTypedModal(transferSurveyorToAdjusterModal({
    numberOfCompleteInvoices: 0,
    numberOfIncompleteInvoices: 0,
    transfer: async () => { }
  }));

  if (!claimStatement || !editableOverview || !readonlyOverview || !submittedOverview) {
    return <PageLoader />;
  }

  const transferInvoices = async (invoicesToTransfer: string[]) => {
    await updateInvoiceState(claimStatementId!, {
      destinationState: invoiceStates.AdjustersConsideration,
      invoiceIdList: invoicesToTransfer
    }, cache);
    await refreshEditableOverview();
    await refreshSubmittedOverview();
  };

  const showTransferModal = async (selectedInvoiceIdsToTransfer: string[]) => {
    const invoicesWithDeviation = invoicesWithSurveyorDeviation(selectedInvoiceIdsToTransfer, editableOverview.invoices);
    const invoicesWithoutDeviation = invoicesWithoutSurveyorDeviation(selectedInvoiceIdsToTransfer, editableOverview.invoices);
    const invoicesToTransfer = invoicesWithoutDeviation.map(x => x.id);
    openTransferModal({
      numberOfCompleteInvoices: invoicesWithoutDeviation.length,
      numberOfIncompleteInvoices: invoicesWithDeviation.length,
      transfer: async () => void await transferInvoices(invoicesToTransfer)
    });
  };

  const contextMenuOptions = (invoiceId: Guid): MenuOption[] => [
    {
      label: 'Open',
      onClick: () => navigateToInvoice(invoiceId)
    },
    {
      label: 'Submit to Claims Handler',
      onClick: isClosed ? undefined : () => showTransferModal([invoiceId])
    }
  ];

  const contextMenuOptionsSubmitted = (invoiceId: Guid): MenuOption[] => [
    {
      label: 'Open',
      onClick: () => navigateToInvoice(invoiceId)
    }
  ];

  const handleSelectParticipants = async (participants: UserInfoDto[]) => {
    await updateParticipantsWithRole({ claimStatementId, role, participants });
    if (!participants.some(p => p.id === currentUser?.id)) {
      navigate('/');
      return;
    }
    await refreshClaimStatement();
  };

  const breadcrumbSteps = [reusableSteps.myDashboardStep];
  const currentPage = breadcrumbVesselName(claimStatement.vesselName, claimStatement.isExperimental);

  return (
    <ExtendedPageWrapper>
      <StickyPageHeading>
        <HeadingWrapper>
          <Breadcrumbs
            steps={breadcrumbSteps}
            current={currentPage} />
        </HeadingWrapper>
      </StickyPageHeading>
      <StickyPageContent noTopPadding>
        {isClosed && <ClosedClaimStatementWarning />}
        <PageGrid>
          <GridPlacement area="details">
            <ClaimDetails data={claimStatement} />
          </GridPlacement>
          <GridPlacement area="contacts">
            <ContactsPane data={claimStatement}
              onSelect={handleSelectParticipants} />
          </GridPlacement>
        </PageGrid>
      </StickyPageContent>
      <TableArea>
        <InvoiceTableSurveyor
          name="drafts"
          title="Drafts"
          data={editableOverview}
          onClick={navigateToInvoice}
          onSubmit={showTransferModal}
          contextMenuOptions={contextMenuOptions}
          selectableRows
          isClosed={isClosed}
        />
        <InvoiceTableSurveyor
          name="submitted"
          title="Submitted to Claims Handler"
          data={submittedOverview}
          onClick={navigateToInvoice}
          contextMenuOptions={contextMenuOptionsSubmitted}
          isClosed={isClosed}
          showButtons={false}
          selectableRows={false}
          paneMargin="20px 0px 0px"
        />
        <InvoiceTableSurveyorReadonly
          title="Invoices not to be considered"
          data={readonlyOverview}
          onClick={navigateToInvoice}
        />
      </TableArea>
    </ExtendedPageWrapper>
  );
};
