import { useTraversalOrderContext } from '@/components/pages/claim/ClaimStatementPage/InvoiceTable/core/TraversalContext';
import { usePromptContext } from '@/common/components/Form/PromptUnsavedChanges/core/PromptOnUnsavedChanges';
import { useSharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { SlimButton } from '@instech/components';
import { ChevronLeft, ChevronRight } from '@instech/icons';
import { useNavigate } from 'react-router-dom';

const previousTooltip = 'Save and go to previous invoice';
const nextTooltip = 'Save and go to next invoice';

export interface InvoicesTraversalProps {
  claimStatementId: string;
  saveAction?: () => Promise<any>;
  isReview?: boolean;
}
export const InvoiceTraversalButtons = (invoiceTraversalInfo: InvoicesTraversalProps) => {
  const navigate = useNavigate();
  const { tooltipId } = useSharedTooltip() ?? '';
  const { skipPrompt } = usePromptContext();
  const { getInvoicesIds, getPreviousInvoiceId, getNextInvoiceId } = useTraversalOrderContext();
  const { claimStatementId, saveAction, isReview = false } = invoiceTraversalInfo;
  const invoicesIds = getInvoicesIds();

  const showTooltip = tooltipId !== '' && saveAction !== undefined;
  const routeAppend = isReview ? '/review' : '';

  const navigateBack = async () => {
    if (saveAction) {
      skipPrompt();
      await saveAction();
    }
    navigate(`/claimstatements/${claimStatementId}${routeAppend}/invoices/${getPreviousInvoiceId()}`);
  };

  const navigateForward = async () => {
    if (saveAction) {
      skipPrompt();
      await saveAction();
    }
    navigate(`/claimstatements/${claimStatementId}${routeAppend}/invoices/${getNextInvoiceId()}`);
  };

  if (invoicesIds.length <= 1) {
    return null;
  }

  return (
    <>
      <SlimButton startIcon={<ChevronLeft width="24px" />}
        variant="secondary"
        width="120px"
        onClick={navigateBack}
        data-for={tooltipId}
        data-tip={showTooltip ? previousTooltip : ''}>
        PREVIOUS
      </SlimButton>
      <SlimButton endIcon={<ChevronRight width="24px" />}
        variant="secondary"
        width="95px"
        onClick={navigateForward}
        data-for={tooltipId}
        data-tip={showTooltip ? nextTooltip : ''}>
        NEXT
      </SlimButton>
    </>
  );
};
