import {
  AdjustmentPdf, AdjustmentPdfDownloadLink, GenerateAdjustmentPdfRequest, GenerateAdjustmentPdfResponse, Guid
} from '@/types';
import useSWR, { mutate } from 'swr';
import { postAsync } from '@/services/client';
import {
  useCallback, useEffect, useState
} from 'react';
import moment from 'moment';
import { AxiosError } from 'axios';

const latestAdjustmentPdfUrl = (claimStatementId: Guid) => `claimstatements/${claimStatementId}/adjustmentpdfs/latest`;
const adjustmentPdfUrl = (claimStatementId: Guid, adjustmentPdfId: Guid) => `claimstatements/${claimStatementId}/adjustmentpdfs/${adjustmentPdfId}`;

const createAdjustmentPdf = async (claimStatementId: Guid, request: GenerateAdjustmentPdfRequest) : Promise<GenerateAdjustmentPdfResponse> => {
  const url = `claimstatements/${claimStatementId}/adjustmentpdfs`;
  const response = await postAsync(url, request);

  return response.data as GenerateAdjustmentPdfResponse;
};

export type LatestAdjustmentPdfState = {
  pdf: AdjustmentPdf | undefined,
  isValidating: boolean,
  error: string | undefined
}

export const useLatestAdjustmentPdf = (claimStatementId: Guid): LatestAdjustmentPdfState => {
  const url = latestAdjustmentPdfUrl(claimStatementId);
  const {
    data,
    error: getPdfError,
    isValidating
  } = useSWR<AdjustmentPdf | undefined, AxiosError>(url, {
    shouldRetryOnError: e => e.response?.status !== 404
  });

  // If there are no PDFs for the claim statement we will get a 404, we don't want to tread this as an error
  const error = getPdfError && getPdfError.response?.status !== 404 ? 'Failed to get latest PDF' : undefined;

  return {
    error,
    isValidating,
    pdf: data
  };
};

type IdleState = {
  state: 'idle',
  generate: (isDraft: boolean) => void
};

type GeneratingState = {
  state: 'generating'
}

type SuccessState = {
  state: 'success',
  pdf: AdjustmentPdf,
  generate: (isDraft: boolean) => void
}

type FailedState = {
  state: 'failed',
  message: string,
  generate: (isDraft: boolean) => void
}

type PdfGenerationState = IdleState | GeneratingState | SuccessState | FailedState;

export const useAdjustmentPdfGeneration = (claimStatementId: Guid): PdfGenerationState => {
  const [pdfId, setPdfId] = useState<Guid>();

  const { data: pdf, isValidating, error: getPdfError } = useSWR<AdjustmentPdf>(
    pdfId ? adjustmentPdfUrl(claimStatementId, pdfId) : null,
    {
      onErrorRetry: (error, key, options, revalidate, { retryCount }) => {
        if (retryCount >= 10) {
          return;
        }

        setTimeout(() => {
          void (async () => {
            await revalidate({
              retryCount
            });
          })();
        }, 1000 + 50*(2**retryCount));
      }
    }
  );

  const generate = useCallback((isDraft: boolean) => {
    void (async () => {
      setPdfId(undefined);

      const createResponse = await createAdjustmentPdf(claimStatementId, { isDraft });
      setPdfId(createResponse.adjustmentPdfId);
    })();
  }, [claimStatementId]);

  useEffect(() => {
    void mutate(latestAdjustmentPdfUrl(claimStatementId), pdf);
  }, [claimStatementId, pdf]);

  if (pdfId && isValidating) {
    return {
      state: 'generating'
    };
  }

  if (getPdfError && !isValidating) {
    return {
      state: 'failed',
      message: 'Failed to get PDF',
      generate
    };
  }

  if (pdf) {
    return {
      state: 'success',
      pdf,
      generate
    };
  }

  return {
    state: 'idle',
    generate
  };
};

export const useAdjustmentPdfDownloadLink = (claimStatementId: Guid, adjustmentPdfId: Guid | undefined) => {
  const url = adjustmentPdfId ? `claimstatements/${claimStatementId}/adjustmentpdfs/${adjustmentPdfId}/link` : null;
  const {
    data,
    error,
    isValidating,
  } = useSWR<AdjustmentPdfDownloadLink>(url, {
    refreshInterval: latestData => latestData ?
      moment.parseZone(latestData.expiresAt)
        .diff(moment()
          .add(5, 'minutes'), 'milliseconds') : 0
  });

  return {
    downloadLink: data,
    error,
    isLoading: isValidating
  };
};
