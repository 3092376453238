import { UpdateInvoiceForm } from '@/types';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

export const useRemoveRequiredEagerly = (field: keyof UpdateInvoiceForm) => {
  const { values, setFieldError, errors } = useFormikContext<UpdateInvoiceForm>();

  useEffect(() => {
    if (errors[field] === 'Required' && values[field]) {
      setFieldError(field, undefined);
    }
  }, [errors, field, setFieldError, values]);
};
export const useRemoveRequiredEagerlyFromNumericalField = (field: keyof UpdateInvoiceForm) => {
  const { values, setFieldError, errors } = useFormikContext<UpdateInvoiceForm>();

  useEffect(() => {
    if (errors[field] === 'Required' && values[field] === undefined) {
      setFieldError(field, undefined);
    }
  }, [errors, field, setFieldError, values]);
};
