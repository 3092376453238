import { IconButton } from '@instech/components';
import { Edit } from '@instech/icons';
import { FunctionComponent as FC } from 'react';
import styled from 'styled-components';

const StyledIconButton = styled(IconButton)`
  color: ${props => props.theme.marineBlue};
  margin-top: -12px;
  margin-right: -12px;
`;

interface Props {
  onClick: () => void;
}
export const ToggleEditButton: FC<Props> = ({ onClick }) => (
  <StyledIconButton
    data-testid="owners-claim-edit-toggle"
    icon={<Edit />}
    smallIcon
    onClick={onClick}
  />
);
