import React from 'react';
import styled from 'styled-components';
import {
  TableHeaderRow, TableSubheaderClear, TableSubheaderGroup, TableSubheaderGap
} from '@instech/components';

// styled-components does not like interpolating these components through
// TableHeaderRow, so direct style wrapping them instead.
const StyledSubheaderGroup = styled(TableSubheaderGroup)`
  max-height: 33px;
`;
const StyledSubheaderGap = styled(TableSubheaderGap)`
  max-height: 33px;
`;

interface Props {
  currency: string;
  stickyTop: string;
}
export const InvoiceTableSubheaders = ({ currency, stickyTop }: Props) => {
  const surveyorStart = 8; // use this as reference point to calculate positions
  return (
    <TableHeaderRow stickyTop={stickyTop}>
      <StyledSubheaderGap start={1} span={surveyorStart - 1} />
      <StyledSubheaderGroup start={surveyorStart} span={4}>
        {'Surveyor\'s Allocation of Costs - Invoice Currency'}
      </StyledSubheaderGroup>
      <StyledSubheaderGap start={surveyorStart + 4} span={2} />
      <TableSubheaderClear />
    </TableHeaderRow>
  );
};
