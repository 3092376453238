import {
  createContext, useMemo, useContext, useEffect, useState,
} from 'react';
import {
  ApplicationRole, FCWC, RoleApiRole
} from '@/types';
import { USER_ROLE } from '@/utils/userManager';
import { useFeatureFlagsContext } from '@/common/providers/featureFlagProvider';
import { getJsonAsync } from '@/services/client';
import { useAccount } from '@/common/authentication/AuthenticationProvider';

interface RoleContextData {
  currentRole: ApplicationRole | null;
  setCurrentRole: (props: ApplicationRole) => void;
  rolesLoaded: boolean;
}
const CurrentRoleContext = createContext<RoleContextData | null>(null);

const fetchRole = async (setCurrentRole: (arg: ApplicationRole) => void) => {
  const roleUrl = '../core/roles/user/Insify%20Claim%20Statement';
  const result = await getJsonAsync<RoleApiRole | ApplicationRole[]>(roleUrl);

  if ((result as RoleApiRole).role) {
    const roleApiRole = result as RoleApiRole;
    setCurrentRole({ name: roleApiRole.role, displayName: roleApiRole.displayName ?? roleApiRole.role } as ApplicationRole);
  } else if ((result as ApplicationRole[]).length > 0) {
    setCurrentRole((result as ApplicationRole[])[0]);
  }

  return result;
};

interface CurrentRoleProviderProps {
  applicationRole?: ApplicationRole | null;
}
export const CurrentRoleProvider: FCWC<CurrentRoleProviderProps> = ({ children, applicationRole = null }) => {
  const [currentRole, setCurrentRole] = useState<ApplicationRole | null>(applicationRole);
  const [rolesLoaded, setRolesLoaded] = useState<boolean>(false);
  const value = useMemo(() => ({
    rolesLoaded, currentRole, setCurrentRole
  }), [currentRole, rolesLoaded]);

  const setCurrentRoleValues = (role: ApplicationRole) => {
    setCurrentRole(role);
    sessionStorage.setItem(USER_ROLE, role.name);
  };

  const featureFlagObject = useFeatureFlagsContext();
  const userAccount = useAccount();
  useEffect(() => {
    if (Object.keys(featureFlagObject).length !== 0 && !userAccount.isMagicLinkUser) {
      void fetchRole(setCurrentRoleValues)
        // eslint-disable-next-line no-console -- Intentional console error catch
        .catch(console.error)
        .finally(() => setRolesLoaded(true));
    }
  }, [featureFlagObject, userAccount]);

  return (
    <CurrentRoleContext.Provider value={value}>
      {children}
    </CurrentRoleContext.Provider>
  );
};

export const useCurrentRole = () => useContext(CurrentRoleContext)?.currentRole;
export const useCurrentRoleContext = () => useContext(CurrentRoleContext) as RoleContextData;
