import { ClaimSearchData } from '@/types';
import { WarningNotification } from '@instech/components';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

export interface ErrorToggleData {
  isErrorVisible: boolean;
  statusCode?: number;
  searchData?: ClaimSearchData;
  setSearchData: (claimSearchData: ClaimSearchData) => void;
  showError: (statusCode: number, claimSearchData: ClaimSearchData) => void;
  hideError: () => void;
}

export const useErrorToggler = () : ErrorToggleData => {
  const [isErrorVisible, setVisible] = useState(false);
  const [statusCode, setStatusCode] = useState<number>();
  const [searchData, setClaimSearchData] = useState<ClaimSearchData>();

  const showError = useCallback((statusCodeValue: number, claimSearchData: ClaimSearchData) => {
    setVisible(true);
    setStatusCode(statusCodeValue);
  }, [setVisible, setStatusCode]);

  const hideError = useCallback(() => {
    setVisible(false);
    setStatusCode(undefined);
  }, [setVisible, setStatusCode]);

  const setSearchData = useCallback((claimSearchData: ClaimSearchData) => {
    if (!isErrorVisible) {
      setClaimSearchData(claimSearchData);
    }
  }, [isErrorVisible, setClaimSearchData]);

  return { isErrorVisible, statusCode, searchData, setSearchData, showError, hideError };
};

const ErrorBox = styled(WarningNotification)`
  margin-bottom: 20px;
`;

const getHeaderText = (includeCurrency?: boolean, errorToggleData?: ErrorToggleData) => {
  if (errorToggleData?.statusCode === 403) {
    return 'Chinese Wall Claim';
  }

  if (includeCurrency) {
    return 'Invalid Claim Number, Insurance Year or the Currency does not match';
  }
  return 'Invalid Claim Number or Insurance Year';
};

const getDescriptionText = (errorToggleData?: ErrorToggleData) => {
  if (errorToggleData?.statusCode === 403) {
    return 'This claim has been marked as confidential / claim wall entry. You do not have access to create a new case for this claim.';
  }

  return 'Check the values and try again.';
};

interface SearchErrorMessageProps {
  onClose: () => void;
  includeCurrency?: boolean;
  errorToggleData?: ErrorToggleData;
}
export const SearchErrorMessage = ({ onClose, includeCurrency, errorToggleData }: SearchErrorMessageProps) => (
  <ErrorBox
    headingText={getHeaderText(includeCurrency, errorToggleData)}
    descriptionText={getDescriptionText(errorToggleData)}
    size="large"
    onClose={onClose} />
);
