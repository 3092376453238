import { useCurrentUser } from '@/services/userInfoServices';
import { PageLoader } from '@instech/components';
import { HeadingWrapper, StickyPageHeading } from '@/common/layout/PageLayout';
import { Breadcrumbs, reusableSteps } from '@/common/components/Breadcrumbs';
import { CreateClaimStatementRequest, NewClaimStatement, } from '@/types';
import { useGlobalCategories } from '@/services/categoriesServices';
import { createClaimStatement } from '@/services/claimStatementServices';
import { useNavigate } from 'react-router';
import { claimStatementValidationSchema } from '@/components/pages/claim/NewClaimStatementPage/validationSchema';
import {
  FormikWithPrompt,
  usePromptContext
} from '@/common/components/Form/PromptUnsavedChanges';
import { isConnectedToIns } from '@/services/occurrenceServices';
import { StickyPageContent } from '@/common/layout/StickyPage';
import { NewClaimStatementPageInnerForm } from './NewClaimStatementPageInnerForm';

const breadcrumbSteps = [reusableSteps.myDashboardStep];

interface NewClaimStatementPageProps {
  isExperimental?: boolean;
}
/**
 * Wrapper component for the actual NewClaimPage contents, to ensure certain
 * data is loaded before moving on to render the rest of the page
 */
export const NewClaimStatementPage = ({ isExperimental = false }: NewClaimStatementPageProps) => {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const { skipPrompt } = usePromptContext();

  const { data: categories } = useGlobalCategories();

  if (currentUser === undefined || categories === undefined) {
    return <PageLoader />;
  }

  const handleCreateClaimStatementSubmit = async (form: NewClaimStatement) => {
    if (form.occurrences.length === 0) {
      return;
    }

    skipPrompt();
    const request: CreateClaimStatementRequest = {
      participantRequest: {
        brokers: form.participants.brokers.map(x => x.id),
        claimsHandlers: form.participants.claimsHandlers.map(x => x.id),
        owners: form.participants.owners.map(x => x.id),
        surveyors: form.participants.surveyors.map(x => x.id),
      },
      categories: form.categories,
      claimStatementSettings: form.claimStatementSettings,
      isExperimental: form.isExperimental,
      occurrences: form.occurrences,
    };
    const firstOccurrenceConnectedToIns = form
      .occurrences
      .filter(isConnectedToIns)[0];

    const result = await createClaimStatement(
      firstOccurrenceConnectedToIns.claimNumber!,
      firstOccurrenceConnectedToIns.insuranceYear!,
      request
    );

    if (result) {
      navigate('/');
    }
  };

  const initialValues: NewClaimStatement = {
    occurrences: [],
    isExperimental,
    categories,
    participants: {
      brokers: [],
      claimsHandlers: [currentUser],
      owners: [],
      surveyors: []
    },
    claimStatementSettings: {
      hasGeneralAverage: false
    }
  };

  return (
    <>
      <StickyPageHeading>
        <HeadingWrapper>
          <Breadcrumbs
            steps={breadcrumbSteps}
            current={isExperimental ? 'New Test Case' : 'New Claim Statement'} />
        </HeadingWrapper>
      </StickyPageHeading>
      <StickyPageContent noTopPadding>
        <FormikWithPrompt initialValues={initialValues} onSubmit={handleCreateClaimStatementSubmit} validationSchema={claimStatementValidationSchema}>
          <NewClaimStatementPageInnerForm isExperimental={isExperimental} />
        </FormikWithPrompt>
      </StickyPageContent>
    </>
  );
};

export const NewTestClaimStatementPage = () => <NewClaimStatementPage isExperimental />;
