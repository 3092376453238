import { ChevronDown } from '@instech/icons';
import React from 'react';
import styled, { css } from 'styled-components';
import { ActionTableCell } from './ActionTableCell';

const Wrapper = styled.div`
  display: flex;
  width: 54px; 
  height: 100%;
  justify-content: center;
  flex-direction: column;
`;

const SpaceAbove = styled.div`
  flex-grow: 1;
  height: calc(50% - 14px);
`;

const SpaceBelow = styled.div<{ isToggled: boolean }>`
  flex-grow: 1;
  height: calc(50% - 14px);
  background-color: ${props => props.isToggled && props.theme.marineBlue20};
  z-index: 1;
`;

const CellChip = styled.button<{ isToggled: boolean }>`
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  width: 54px;
  height: 28px;
  background-color: ${props => props.theme.marineBlue20};
  border: none;
  border-radius: 12px;
  padding: 0px 4px;
  cursor: pointer;
  ${props => props.isToggled && css`
    border-radius: 12px 12px 0px 0px;    
  `}
  & svg {
    color: ${props => props.theme.marineBlue80};
    transform: ${props => props.isToggled && 'rotate(180deg)'};
    padding-left: 1px;
  }
`;

const ChipText = styled.span`
  padding-bottom: 2px;
  color: ${props => props.theme.marineBlue};
  line-height: 14px;
  font-size: 14px;
  font-weight: 700;
`;

interface ButtonProps {
  rowId: string;
  count: number;
  isVisible: boolean;
  isToggled: boolean;
  onClick?: () => void;
}

/**
 * Component that conditionally renders a chip with a number in it.
 * The chip can be put in a toggled state by clicking on it.
 */
export const ExpandableRowButton = ({ rowId, count, isVisible, isToggled, onClick, ...props }: ButtonProps) => (
  <ActionTableCell onClick={e => e.stopPropagation()} {...props}>
    {isVisible && (
      <Wrapper>
        <SpaceAbove />
        <CellChip onClick={onClick} isToggled={isToggled} data-testid={`expand-table-row-${rowId}`}>
          <ChipText>{count}</ChipText>
          <ChevronDown width="18px" />
        </CellChip>
        <SpaceBelow isToggled={isToggled} />
      </Wrapper>
    )}
  </ActionTableCell>
);
