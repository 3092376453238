import { FCWC } from '@/types';
import { theme } from '@/utils/style/theme';
import React, { createContext, useContext } from 'react';
import { TooltipProps } from 'react-tooltip';
import { StyledTooltip } from './Components';

interface TooltipContextData {
  tooltipId: string;
}
const SharedTooltipContext = createContext<TooltipContextData | null>(null);

interface SharedTooltipProps extends TooltipProps {
  id: string;
}
/**
 * Create a ReactTooltip instance that can be shared by tooltip anchors within its context.
 * The SharedTooltip component instantiates an `id` for the tooltip, which can then be
 * retrieved as `tooltipId` with the `useSharedTooltip()` context hook.
 *
 * The tooltip is then anchored to a component by pointing `data-for={tooltipId}` and
 * `data-tip="Tooltip text"` to a suitable HTML element (div, span, etc.). For example:
 *
 * `<div data-for={tooltipId} data-tip="Hello world">Text</div>`
 *
 * If the tooltip needs to contain HTML, by setting `html` on the anchor and providing an
 * HTML string as the `data-tip`, you can sanitize the contents manually by using the
 * `@/utils/useSanitizer` helper function.
 */
export const SharedTooltip: FCWC<SharedTooltipProps> = ({ children, id, ...props }) => {
  const value = React.useMemo(() => ({
    tooltipId: id
  }), [id]);
  return (
    <SharedTooltipContext.Provider value={value}>
      <StyledTooltip
        id={id}
        className="ics-tooltip"
        type="info"
        effect="float"
        arrowColor={theme.marineBlue}
        bodyMode // listen on the Body, not per data-tip, to catch new elements mounting
        {...props}
      />
      {children}
    </SharedTooltipContext.Provider>
  );
};

export const useSharedTooltip = () => useContext(SharedTooltipContext) as TooltipContextData;
