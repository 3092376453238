import { TableHeader } from '@/common/components/Table/TanstackTable';
import { TanstackTable } from '@/common/components/Table/TanstackTable/Table';
import { TableBody } from '@/common/components/Table/TanstackTable/TableBody';
import {
  TableButtonExpand,
  TableButtonFavorite,
  TableButtonMenu,
  TableCellComment,
  TableCellNumber,
  TableCellText
} from '@/common/components/Table/TanstackTable/TableCell';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import {
  ApplicationRole,
  ClaimStatement,
  ClaimStatementFavourites,
  ClaimStatementStatus
} from '@/types';
import { arrayAddConditionally } from '@/utils/array';
import { getFriendlyDate } from '@instech/components';
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import {
  FC,
  useCallback,
  useMemo
} from 'react';
import { NoData } from './core/Components';
import { getClaimStatementSubrows } from './core/SubrowTemplate';
import { TableCellLastEdited } from './core/TableCell/TableCellLastEdited';
import { TableCellLatestStatus } from './core/TableCell/TableCellLatestStatus';
import { ClaimStatementTableData, getClaimStatementTableData } from './getClaimStatementTableData';
import { ClaimStatementTableExtra, MenuOptionsCallback } from './types';

const columnHelper = createColumnHelper<ClaimStatementTableData>();

const getColumnDef = (props: ClaimStatementTableExtra): ColumnDef<ClaimStatementTableData, any>[] => ([
  columnHelper.accessor('claimNumber', {
    header: 'Claim No',
    sortingFn: 'alphanumeric',
    cell: data => (
      <TableCellText data={data} underline />
    ),
  }),
  columnHelper.accessor('subrowCount', {
    header: '',
    meta: { emptyHeader: true },
    cell: data => (
      <TableButtonExpand data={data} counter={data.getValue()} />
    ),
  }),
  columnHelper.accessor('vesselName', {
    header: 'Vessel',
    sortingFn: 'alphanumeric',
    cell: data => (
      <TableCellText data={data} />
    ),
  }),
  columnHelper.accessor('fleetName', {
    header: 'Fleet',
    sortingFn: 'alphanumeric',
    cell: data => (
      <TableCellText data={data} />
    ),
  }),
  columnHelper.accessor('interest', {
    header: 'Interest',
    sortingFn: 'alphanumeric',
    cell: data => (
      <TableCellText data={data} />
    ),
  }),
  columnHelper.accessor('claimDate', {
    header: 'Date of Loss',
    sortingFn: 'alphanumeric',
    cell: data => (
      <TableCellText data={data} overrideValue={getFriendlyDate(data.renderValue())} />
    ),
  }),
  columnHelper.accessor('occurrences', {
    header: 'Occurrence(s)',
    sortingFn: 'alphanumeric',
    cell: data => (
      <TableCellComment data={data} tooltipId="claim-statement-table-tooltip" />
    ),
  }),
  columnHelper.accessor(data => data.claimAmount ?? undefined, {
    header: 'Claimed Amount',
    sortingFn: 'auto',
    sortUndefined: -1,
    meta: {
      align: 'right'
    },
    cell: data => (
      <TableCellNumber data={data} tooltipId="claim-statement-table-tooltip" />
    ),
  }),
  columnHelper.accessor('currency', {
    header: 'Currency',
    sortingFn: 'alphanumeric',
    cell: data => (
      <TableCellText data={data} />
    ),
  }),
  // TODO: Sort handling for this column may not be correct
  // It is sorting the whole string as text, instead of breaking it up (A) / (B) / (C)
  columnHelper.accessor('invoiceCounts', {
    header: 'Invoices',
    cell: data => (
      <TableCellText data={data} />
    ),
  }),
  ...arrayAddConditionally(
    (props.claimStatementStatus === 'Open'),
    columnHelper.accessor('latestStatus', {
      header: 'Latest Status',
      cell: data => (
        <TableCellLatestStatus data={data} role={props.role} />
      ),
    })
  ),
  columnHelper.accessor('lastEdited', {
    header: 'Last Edited / By',
    cell: data => (
      <TableCellLastEdited data={data} tooltipId="claim-statement-table-tooltip" />
    ),
  }),
  columnHelper.accessor('isFavorite', {
    header: 'Starred',
    cell: data => (
      // TODO: Optimize favouriting approach, interaction is very slow
      <TableButtonFavorite
        data={data}
        onClick={() => props.onStarClick(data.row.original.id, data.getValue())}
      />
    ),
  }),
  {
    id: 'contextMenu',
    header: '',
    meta: { emptyHeader: true },
    cell: data => (
      <TableButtonMenu data={data} menuOptions={props.getContextMenuOptions(data.row.original.claimStatement)} />
    ),
  }
]);

interface Props {
  claimStatements: ClaimStatement[];
  favourites: ClaimStatementFavourites;
  onRowClick: (claimId: string) => void;
  onStarClick: (claimId: string, selected: boolean) => void;
  getContextMenuOptions: MenuOptionsCallback;
  getClaimIsNotOwn?: (data: ClaimStatementTableData) => boolean;
  claimStatementStatus: ClaimStatementStatus;
  role?: ApplicationRole | null;
}
export const ClaimStatementTable: FC<Props> = ({
  claimStatements,
  favourites,
  onRowClick,
  onStarClick,
  getContextMenuOptions,
  getClaimIsNotOwn,
  claimStatementStatus,
  role
}) => {
  const tableData = useMemo(() => (
    getClaimStatementTableData(claimStatements, role, favourites.claimStatementIds)
  ), [claimStatements, favourites.claimStatementIds, role]);

  const columnDef = useMemo(() => getColumnDef({
    getContextMenuOptions, favourites, onStarClick, claimStatementStatus, role
  }), [claimStatementStatus, favourites, getContextMenuOptions, onStarClick, role]);

  const table = useReactTable({
    data: tableData,
    columns: columnDef,
    sortDescFirst: false,
    getRowCanExpand: (row => row.original.claimStatement.occurrences.length >= 2),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  });

  const getClaimIsFavourite = useCallback((claim: ClaimStatementTableData) => (
    favourites.claimStatementIds.includes(claim.id)
  ), [favourites]);

  // TODO: Refactor so that this uses .subrows instead of .claimStatement
  const getTableSubrows = useCallback((claim: ClaimStatementTableData) => (
    getClaimStatementSubrows(claim.claimStatement, claimStatementStatus)
  ), [claimStatementStatus]);

  if (claimStatements.length === 0) {
    return <NoData>No available data.</NoData>;
  }

  return (
    <SharedTooltip id="claim-statement-table-tooltip">
      <TanstackTable>
        <TableHeader table={table} />
        <TableBody
          table={table}
          onRowClick={claimStatement => onRowClick(claimStatement.id)}
          rowIsFaded={getClaimIsNotOwn}
          rowIsBold={getClaimIsFavourite}
          getSubrows={getTableSubrows}
        />
      </TanstackTable>
    </SharedTooltip>
  );
};
