import {
  InvoiceRecord, SurveyorsConsiderationSplit,
  AdjustersConsideration, AdjustersConsiderationSplit
} from '@/types';

const initialConsideredKeys = {
  claimConsideration: 'initialClaim',
  ownersWorkConsideration: 'initialOwnersWork',
  commonExpenses: 'initialCommonExpenses',
  particularAverage: 'initialParticularAverage',
};

type AnyRecord = InvoiceRecord | SurveyorsConsiderationSplit | AdjustersConsideration | AdjustersConsiderationSplit;

/**
 * Step through a record and verify all 'initial' values against their corresponding
 * 'consideration' values. If a value has initial but not considered value, copy the
 * latter onto the former -- so it is just the one value when it then goes on
 * to the form.
 */
export const initialValuesToConsiderations = (record: AnyRecord) => {
  // Creating new object and copying over. entries().reduce() is showing
  // some strange behaviour with certain keys that this does not.
  const newRecord: any = {};
  Object.entries(record).forEach(current => {
    const currentKey = current[0] as keyof typeof initialConsideredKeys;
    let currentVal = current[1];

    const isInitialKey = initialConsideredKeys[currentKey];
    const hasInitialVal = isInitialKey ? record[isInitialKey as keyof AnyRecord] : null;

    if (isInitialKey && hasInitialVal && !currentVal && currentVal !== 0) {
      currentVal = hasInitialVal;
    }

    newRecord[currentKey] = currentVal;
  });
  return newRecord;
};
