import { DeviationAdjuster } from '@/apps/ClaimStatement/components/Deviation';
import { FormRow } from '@/common/components/Form/FormRow/FormRow';
import { SplitItemButtons } from '@/apps/ClaimStatement/components/InvoiceSplitTable';
import { InvoiceRecord } from '@/types';
import {
  Dropdown, DropdownCreatable, LabelValuePair, TextArea
} from '@instech/components';
import { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { AdjustersCostFields } from './AdjustersCostFields';

const StyledDeviationAdjuster = styled(DeviationAdjuster)`
  margin-bottom: 20px;
`;

interface CostFieldProps {
  arrayName: string;
  index: number;
  invoiceRecord: InvoiceRecord;
  categoryOptions: LabelValuePair[];
  repairPeriodOptions: LabelValuePair[];
  occurrenceOptions: LabelValuePair[];
  onAddSplit?: () => void;
  onRemoveSplit: (index: number) => void;
  selectedCurrency?: string | null;
  useInvoiceCurrency?: boolean;
  viewAsListOfSplits?: boolean;
  showNote?: boolean;
  addInternalNote: () => void;
  deleteInternalNote: () => void;
  showApplyValuesOwner?: boolean;
  showApplyValuesSurveyor?: boolean;
  onApplyValues?: () => void;
  applyValuesDisabled?: boolean;
  showGeneralAverage: boolean;
}
export const CostFields: FC<CostFieldProps> = ({
  arrayName,
  index,
  invoiceRecord,
  categoryOptions,
  repairPeriodOptions,
  occurrenceOptions,
  onAddSplit,
  onRemoveSplit,
  selectedCurrency,
  useInvoiceCurrency,
  viewAsListOfSplits,
  showNote,
  addInternalNote,
  deleteInternalNote,
  showApplyValuesOwner,
  showApplyValuesSurveyor,
  onApplyValues,
  applyValuesDisabled,
  showGeneralAverage
}) => (
  <>
    <SharedTooltip id="adjuster-cost-fields-tags-tooltip">
      <AdjustersCostFields
        arrayName={arrayName}
        index={index}
        exchangeRate={invoiceRecord.exchangeRate}
        useInvoiceCurrency={useInvoiceCurrency}
        showGeneralAverage={showGeneralAverage} />
    </SharedTooltip>
    {!viewAsListOfSplits && (
    <StyledDeviationAdjuster
      invoice={invoiceRecord}
      selectedCurrency={selectedCurrency || ''}
      showGeneralAverage={showGeneralAverage} />
    )}
    <FormRow gutter="32px" layoutLarge="1fr 3fr">
      <div>
        <Dropdown
          name={`${arrayName}.${index}.occurrence`}
          label="Occurrence"
          options={occurrenceOptions} />
        {viewAsListOfSplits && (
          <>
            <Dropdown
              name={`${arrayName}.${index}.category`}
              label="Category"
              options={categoryOptions}
              clearable />
            <DropdownCreatable
              placeholder=""
              name={`${arrayName}.${index}.repairPeriod`}
              label="Repair Period"
              options={repairPeriodOptions}
              clearable />
          </>
        )}
      </div>
      <div>
        <TextArea
          name={`${arrayName}.${index}.adjustersComment`}
          label="Adjuster's Note"
          placeholder="Text" />
        {showNote && (
        <TextArea
          name={`${arrayName}.${index}.internalNotes`}
          label="Internal Notes - Only visible for Adjusters"
          placeholder="Text" />
        )}
      </div>
    </FormRow>
    <SplitItemButtons
      onAddNote={addInternalNote}
      onDeleteNote={deleteInternalNote}
      onAddSplit={onAddSplit}
      onRemoveSplit={() => onRemoveSplit(index)}
      showNote={showNote}
      viewAsListOfSplits={viewAsListOfSplits}
      showApplyValuesOwner={showApplyValuesOwner}
      showApplyValuesSurveyor={showApplyValuesSurveyor}
      onApplyValues={onApplyValues}
      applyValuesDisabled={applyValuesDisabled} />
  </>
);
