import { patchAsync } from '@/services/client';
import useSWR from 'swr';
import {
  AdjustmentCalculations,
  UpdateCoverRequest, UpdateInsuranceConditionsRequest
} from '../types/adjustmentCalculations';

const baseUrl = (claimStatementId: string) => `claimstatements/${claimStatementId}/adjustmentCalculations`;

/**
 * Fetches AdjustmentCalculations from API with all numeric values as Number,
 * and returns set to consuming component with numeric values as String (for form).
 */
export const useAdjustmentCalculations = (claimStatementId: string) => {
  const route = claimStatementId ? baseUrl(claimStatementId) : null;
  const { data, ...swr } = useSWR<AdjustmentCalculations>(route, { suspense: true });
  return { data: data!, ...swr };
};

export const updateCover = async (
  claimStatementId: string,
  adjustmentId: string,
  coverId: string,
  request: UpdateCoverRequest
) => {
  const route = `${baseUrl(claimStatementId)}/${adjustmentId}/covers/${coverId}`;
  return patchAsync(route, request);
};

export const updateInsuranceConditions = async (
  claimStatementId: string,
  adjustmentId: string,
  request: UpdateInsuranceConditionsRequest
) => {
  const route = `${baseUrl(claimStatementId)}/${adjustmentId}/insuranceConditions`;
  return patchAsync(route, request);
};
