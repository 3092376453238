import { FC } from 'react';
import styled from 'styled-components';
import { SummaryItemSmall } from '@/common/components/Summary';

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  // Give row items flexible breathing room, but clamp max-width
  max-width: 500px;
  gap: 20px;
  padding-bottom: 32px;
`;

const StyledSummaryItem = styled(SummaryItemSmall)`
  color: ${({ theme }) => theme.marineBlue};
`;

interface Props {
  policyConditions: string;
  interest: string;
  currency: string;
}
export const PolicySummaryItems: FC<Props> = ({ policyConditions, currency, interest }) => (
  <SummaryRow>
    <StyledSummaryItem item={{ title: 'Policy Conditions', value: policyConditions }} wrapText />
    <StyledSummaryItem item={{ title: 'Interest', value: interest }} wrapText />
    <StyledSummaryItem item={{ title: 'Insurance Currency', value: currency }} wrapText />
  </SummaryRow>
);
