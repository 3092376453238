import { Table } from '@tanstack/react-table';
import { Fragment } from 'react';
import { TableRow } from './TableRow/TableRow';

interface Props<TData> {
  /**
   * Table object created via TanStack useReactTable().
   */
  table: Table<TData>;
  /**
   * Click handler for table row.
   */
  onRowClick?: (data: TData) => void;
  /**
   * Takes a function that returns a custom TableRow implementation
   * to display subrows underneath a given row.
   */
  getSubrows?: (data: TData) => JSX.Element;
  /**
   * If function returns true, display row as faded-out and italicized.
   */
  rowIsFaded?: (data: TData) => boolean;
  /**
   * If function returns true, display row in bold text.
   */
  rowIsBold?: (data: TData) => boolean;
  /**
   * If function returns true, display row with a green background.
   */
  rowIsFinished?: (data: TData) => boolean;
}

/**
 * Component to render the body of a table. The table's structure and its rows
 * are automatically handled via Tanstack's data model, and all properties are
 * defined via the `useReactTable()` hook.
 *
 * ### Adding subrows to the table
 * Exception to the above is for adding custom subrows. This could be refactored at
 * a later point, but since our need for subrows right now is to show different data
 * than the main row is showing, this component just accepts a callback returning a
 * JSX.Element.
 *
 * Subrows with a different type and structure thåan the main row are tricky to
 * implement / not properly supported by Tanstack Table.
 */
export const TableBody = <TData extends {}>(props: Props<TData>) => (
  <tbody>
    {props.table.getRowModel().rows.map((row, index) => (
      <Fragment key={row.id}>
        <TableRow
          key={row.id}
          row={row}
          index={index}
          rowIsFaded={props.rowIsFaded}
          rowIsBold={props.rowIsBold}
          rowIsFinished={props.rowIsFinished}
          onClick={() => props.onRowClick?.(row.original)} />
        {row.getIsExpanded() && props.getSubrows?.(row.original)}
      </Fragment>
    ))}
  </tbody>
);
