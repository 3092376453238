import { ClaimsData, Occurrence } from '@/types';
import { v4 as uuidv4 } from 'uuid';

export const createOccurrenceFromClaimsData = (data: ClaimsData, order: number = 1): Occurrence => ({
  id: uuidv4(),
  insuranceId: data.insuranceId,
  mdmExternalId: data.mdmExternalId,
  claimDate: data.claimDate,
  insuranceYear: data.insuranceYear,
  claimNumber: data.claimNumber,
  name: 'TBA',
  location: '',
  interest: data.interest,
  title: ''
});

/**
 * Creates an occurrence with a specific order number that is otherwise empty.
 */
export const createOccurrenceFromOrder = (order: number): Occurrence => ({
  id: uuidv4(),
  // This value is being faked here, and then attached properly on submit
  insuranceId: '',
  insuranceYear: undefined,
  claimNumber: undefined,
  claimDate: '',
  location: '',
  name: 'TBA',
  interest: '',
  title: ''
});
