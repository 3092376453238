import { DropdownCreatable } from '@instech/components';
import { stringToLabelValuePair } from '@/utils/labelValuePair';
import { ComponentProps } from 'react';
import styled from 'styled-components';
import { HighlightEffect } from '@/common/components/HighlightedFields';
import { useInvoiceSuggestion } from './utils';

const DropdownHighlighted = styled(DropdownCreatable)`
  &[data-highlight="true"] [class*="-control"] {
    ${HighlightEffect}
  }
`;

type SupplierInputProps = {
  suggestion: string | undefined;
} & Partial<ComponentProps<typeof DropdownCreatable>>;
const SupplierInput = ({ options, suggestion, ...props }: SupplierInputProps) => {
  const value = stringToLabelValuePair(suggestion);
  const usingSuggestedValue = useInvoiceSuggestion('supplier', value);

  return (
    <DropdownHighlighted
      placeholder=""
      name="supplier"
      label="Contractor / Supplier"
      options={options}
      clearable
      required
      data-highlight={usingSuggestedValue}
      {...props} />
  );
};

export default SupplierInput;
