import { HEADER_ANIM_KEYFRAMES, HEADER_HEIGHT_VALUES } from '@/common/modules/Header/core/animationConstants';
import {
  HTMLMotionProps, motion, useScroll, useTransform
} from 'framer-motion';
import { FunctionComponent as FC } from 'react';
import styled from 'styled-components';

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  // zIndex 149, just below header and its dropdowns
  z-index: ${({ theme }) => (theme.zIndex.header - 1)};
`;

interface Props extends HTMLMotionProps<'div'> {
  className?: string;
}
/**
 * Creates a fixed Overlay at a zIndex just below the header, that implements
 * framer-motion to automatically offsets contents in a way that adheres to
 * the height of the page header.
 *
 * Extends motion.div props, and so supports all properties thereof, such as
 * initial, animate, and exit.
 */
export const HeaderAdjustedOverlay: FC<Props> = ({ children, className, initial, animate, exit, ...props }) => {
  const { scrollY } = useScroll();
  const headerHeight = useTransform(scrollY, HEADER_ANIM_KEYFRAMES, HEADER_HEIGHT_VALUES);

  return (
    <Overlay
      className={className}
      style={{ top: headerHeight }}
      initial={initial}
      animate={animate}
      exit={exit}
      {...props}>
      {children}
    </Overlay>
  );
};
