import { useCurrentRole } from '@/common/authentication/CurrentRoleContext';
import { CurrencySelector } from '@/common/components/Form/CurrencySelector/CurrencySelector';
import { LightReadonlyCollapsiblePane } from '@/common/components/Pane/ReadonlyCollapsiblePane';
import { useClaimStatement } from '@/services/claimStatementServices';
import {
  AdjustersConsiderationSplit, ApplicationRole, InvoiceRecord
} from '@/types';
import { Loader } from '@instech/components';
import { FunctionComponent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { INVOICE_PANE_MARGIN } from '@/utils/style/styleConstants';
import { roles } from '@/utils/constants';
import { SplitTableAdjuster } from '../../../EditInvoice/AdjustersConsiderationPane/core/SplitTableAdjuster';
import { ReadonlyFields } from './ReadonlyFields';
import { ReadonlySplitTableRowAdjuster } from './ReadonlySplitTableRowAdjuster';
import { useSurveyorSplitsContext } from '../../../EditInvoice/InvoiceForm/utils/SurveyorSplitsContext';
import { TotalLineReadonly } from '../../../EditInvoice/AdjustersConsiderationPane/core/TotalLine';

const getShowInternalNotes = (
  role: ApplicationRole | null | undefined,
  internalNote: string | null | undefined
) => {
  if (!internalNote) return false;
  return role?.name === roles.ClaimsHandler;
};

interface MultipleReadOnlyProps {
  record: InvoiceRecord;
  splits: AdjustersConsiderationSplit[];
  startCollapsed?: boolean;
  redactSurveyorSplits: boolean;
}
export const MultipleReadonlyConsideration: FunctionComponent<MultipleReadOnlyProps> = ({
  record,
  splits,
  startCollapsed,
  redactSurveyorSplits
}) => {
  const { claimStatementId } = useParams();
  const { data: claimstatement } = useClaimStatement(claimStatementId);
  const [selectedCurrency, setCurrency] = useState(record.currency);
  const { surveyorSplits } = useSurveyorSplitsContext();
  const role = useCurrentRole();

  const useInvoiceCurrency = selectedCurrency === record.currency;
  const showGeneralAverage = claimstatement?.claimStatementSettings?.hasGeneralAverage ?? false;

  if (!claimstatement) return <Loader />;

  const handleCurrencyChange = (currency: string) => {
    setCurrency(currency);
  };

  const { occurrences } = claimstatement;
  const getOccurrenceName = (id: string | undefined) => occurrences.find(o => o.id === id)?.name;

  const getReadonlySurveyorSplit = (splitId: string | undefined) => {
    if (!splitId) return null;
    return surveyorSplits?.find(split => split.id === splitId);
  };

  return (
    <LightReadonlyCollapsiblePane
      title="Adjuster's Consideration"
      margin={INVOICE_PANE_MARGIN}
      padding="16px"
      startCollapsed={startCollapsed}>
      <CurrencySelector
        first={record.currency}
        second={claimstatement.currency}
        selected={selectedCurrency}
        setSelected={handleCurrencyChange}
        marginBottom="16px"
        id="clh-multiple-read-only" />
      <SplitTableAdjuster viewAsListOfSplits showGeneralAverage={showGeneralAverage}>
        {splits.map(split => (
          <ReadonlySplitTableRowAdjuster
            key={split.id}
            invoiceRecord={record}
            consideration={split}
            useInvoiceCurrency={useInvoiceCurrency}
            occurrenceList={occurrences}
            surveyorSplitId={split.surveyorSplitId}
            showGeneralAverage={showGeneralAverage}>
            <ReadonlyFields
              adjusterSplit={split}
              surveyorSplit={getReadonlySurveyorSplit(split.surveyorSplitId)}
              exchangeRate={record.exchangeRate}
              useInvoiceCurrency={useInvoiceCurrency}
              occurrence={getOccurrenceName(split.occurrenceId)}
              showInternalNotes={getShowInternalNotes(role, split.internalNotes)}
              atSurveyorsDesk={redactSurveyorSplits && split.surveyorSplitId !== null}
              showGeneralAverage={showGeneralAverage}
            />
          </ReadonlySplitTableRowAdjuster>
        ))}
        <TotalLineReadonly
          selectedCurrency={selectedCurrency!}
          splits={splits}
          invoice={record}
          showGeneralAverage={showGeneralAverage}
        />
      </SplitTableAdjuster>
    </LightReadonlyCollapsiblePane>
  );
};
