import { useInvoice } from '@/services/invoice/invoiceServices';
import { useClaimStatementSuppliersAsOptions } from '@/services/suppliersService';
import {
  ApplicationRole,
  ClaimStatement, Invoice, InvoiceRecord, UpdateOwnersRecordForm
} from '@/types';
import { stringToLabelValuePair } from '@/utils/labelValuePair';
import {
  datepickerSingle, labelValuePair, numberGreaterThanZero
} from '@/utils/validationSchemas';
import {
  ButtonGroup, LabelValuePair, SlimButton
} from '@instech/components';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import { CommentAndNotes } from '../../core/CommentAndNotes';
import { ClaimInfoEdit } from './ClaimInfoEdit';
import { ClaimInfoForm } from './ClaimInfoForm';
import { EditWarning } from './EditWarning';

const ContentPadding = styled.div`
  padding: 24px;
`;

const createInitialValues = (record: InvoiceRecord) => ({
  supplier: stringToLabelValuePair(record.supplier),
  exchangeRate: record.exchangeRate || '',
  paymentDate: record.paymentDate || ''
});

const ownersClaimValidationSchema = Yup.object().shape({
  supplier: labelValuePair().required('Required'),
  exchangeRate: numberGreaterThanZero(),
  paymentDate: datepickerSingle()
});

interface InnerProps {
  formikProps: FormikProps<any>;
  claimStatement: ClaimStatement;
  invoice: Invoice;
  onClose: () => void;
  options?: LabelValuePair[];
  userRole?: ApplicationRole | null;
  showNotes?: boolean;
}
const OwnersClaimEditInner: FC<InnerProps> = ({
  formikProps,
  claimStatement,
  invoice,
  onClose,
  options,
  userRole,
  showNotes
}) => {
  const { handleSubmit, isSubmitting, dirty } = formikProps;

  return (
    <div>
      <EditWarning userRole={userRole?.name} />
      <ContentPadding>
        <ClaimInfoForm
          supplierOptions={options}
          invoiceCurrency={invoice.record.currency}
          policyCurrency={claimStatement.currency}
          totalAmount={invoice.record.totalAmount} />
        <ClaimInfoEdit record={invoice.record} />
        <CommentAndNotes
          comment={invoice.record.ownersComment}
          commentLabel="Cost Description"
          notes={invoice.record.notes}
          showNotes={showNotes} />
        <ButtonGroup alignRight marginTop="40px">
          <SlimButton variant="secondary" onClick={onClose}>CANCEL</SlimButton>
          <SlimButton onClick={handleSubmit} disabled={!dirty} loading={isSubmitting}>SAVE</SlimButton>
        </ButtonGroup>
      </ContentPadding>
    </div>
  );
};

interface Props {
  claimStatement: ClaimStatement;
  invoice: Invoice;
  onClose: () => void;
  userRole?: ApplicationRole | null;
  showNotes?: boolean;
}
export const OwnersClaimEdit: FC<Props> = ({ claimStatement, invoice, onClose, userRole, showNotes }) => {
  const { supplierOptions, mutate: refreshSuppliers } = useClaimStatementSuppliersAsOptions(claimStatement.id);
  const { updateOwnersRecord } = useInvoice(claimStatement.id, invoice.id);

  const handleUpdate = async (updateOwnersClaimForm: UpdateOwnersRecordForm) => {
    await updateOwnersRecord(updateOwnersClaimForm, invoice);
    return true;
  };

  return (
    <Formik
      initialValues={createInitialValues(invoice.record)}
      validationSchema={ownersClaimValidationSchema}
      onSubmit={async (values, helpers) => {
        helpers.setSubmitting(true);
        await handleUpdate(values);
        await refreshSuppliers();
        helpers.setSubmitting(false);
        onClose();
      }}>
      {formikProps => (
        <OwnersClaimEditInner
          formikProps={formikProps}
          options={supplierOptions}
          claimStatement={claimStatement}
          invoice={invoice}
          onClose={onClose}
          userRole={userRole}
          showNotes={showNotes}
        />
      )}
    </Formik>
  );
};
