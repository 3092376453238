import styled from 'styled-components';

/**
 * Renders a layout for displaying InfoWithLabel components in an orderly grid.
 * Min size of the layout's auto columns can be adjusted by prop. More extensive
 * grid layout changes should probably be done by just extending the component
 * as a new styled(InfoLayout).
 */
export const InfoLayout = styled.div<{ columnMin?: string }>`
  flex-grow: 1;
  display: grid;
  column-gap: 24px;
  row-gap: 24px;
  grid-template-columns: ${props => `repeat(auto-fill, minmax(${props.columnMin || '110px'}, 1fr))`};
  color: ${props => props.theme.marineBlue};
  padding: 4px 0px;
`;
