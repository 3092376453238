import { useField, useFormikContext } from 'formik';
import { useTypedModal } from '@/common/modules/Modal';
import {
  disableGeneralAverageDefaultProps,
  disableGeneralAverageModal
} from '@/components/pages/claim/core/ClaimStatementForm/ClaimStatementSettings';
import React, { useCallback } from 'react';
import { ToggleControlled } from '@instech/components';
import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  max-width: 420px;
  color: ${({ theme }) => theme.marineBlue};
  justify-content: space-between;
  align-items: center;
  & div {
    flex: 1;
    align-items: end;
  }
`;

interface GeneralAverageInputProps {
  generalAverageInUse: boolean;
}
const GeneralAverageInput = ({ generalAverageInUse }: GeneralAverageInputProps) => {
  const { submitForm, isSubmitting } = useFormikContext();
  const [, { value }, { setValue }] = useField('hasGeneralAverage');

  const { open: openDisableGeneralAverageModal } = useTypedModal(
    disableGeneralAverageModal(disableGeneralAverageDefaultProps)
  );

  const handleSubmit = useCallback(async () => {
    try {
      await submitForm();
    } catch (e) {
      await setValue(value);
    }
  }, [setValue, submitForm, value]);

  const handleToggle = useCallback(async () => {
    if (isSubmitting) {
      return;
    }

    const tryingToDisableWhenValuesAreAllocatedToGeneralAverage = value && generalAverageInUse;
    if (tryingToDisableWhenValuesAreAllocatedToGeneralAverage) {
      const disableGeneralAverage = async () => {
        await setValue(false);
        await handleSubmit();
      };
      openDisableGeneralAverageModal({ disableGeneralAverage });
    } else {
      await setValue(!value);
      await handleSubmit();
    }
  }, [generalAverageInUse, handleSubmit, isSubmitting, openDisableGeneralAverageModal, setValue, value]);

  return (
    <Content>
      <div>General Average</div>
      <ToggleControlled
        data-testid="general-average-toggle"
        name="claimStatementSettings.hasGeneralAverage"
        checked={value}
        onChange={handleToggle}
        rightLabel={value ? 'On' : 'Off'}
      />
    </Content>
  );
};

export default GeneralAverageInput;
