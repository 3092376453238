import { FC } from 'react';
import styled from 'styled-components';
import { DownloadPdfButton } from './DownloadPdfButton';

const Container = styled.div`
  background-color: ${({ theme }) => theme.white};
  padding: 20px;
`;

interface Props { }
export const SidebarFooter: FC<Props> = () => (
  <Container>
    <DownloadPdfButton />
  </Container>
);
