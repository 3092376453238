import { ClaimStatement } from '@/types';

export const prepareParticipantsFrom = (claimStatement: ClaimStatement) => {
  const { owners, brokers, surveyors, claimsHandlers } = claimStatement;
  return {
    owners: owners ?? [],
    brokers: brokers ?? [],
    surveyors: surveyors ?? [],
    claimsHandlers: claimsHandlers ?? []
  };
};
