import { DangerNotification, WarningNotification } from '@instech/components';
import { FunctionComponent as FC, useState } from 'react';
import styled from 'styled-components';
import { InstructionsPane } from '../../core/ReadOnlyInvoice/InstructionsPane';

export const InvoicePageLayout = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 60%) minmax(0, 40%);
  column-gap: 48px;
  min-height: 85vh;
  box-sizing: border-box;
  max-width: 2000px;
  @media screen and (min-width: 1600px) {
    grid-template-columns: minmax(0, 55%) minmax(0, 45%);
  }
  @media screen and (min-width: 1800px) {
    grid-template-columns: minmax(0, 50%) minmax(0, 50%);
  }
`;

const InvoiceLockedWarning = () => (
  <DangerNotification
    descriptionText="This invoice is currently being edited by another user. The invoice can only be read."
    headingText="This invoice is being edited by another user"
    size="large"
  />
);

const InvoiceConflictWarning = () => (
  <DangerNotification
    descriptionText="This invoice has been edited by someone. Please refresh the page to save your data."
    headingText="This invoice is outdated and cannot be saved"
    size="large"
  />
);

const ClaimStatementClosedWarning = () => (
  <WarningNotification
    headingText="This claim statement is closed"
    descriptionText="All invoice details are read-only, but other actions are still available."
    size="large"
  />
);

const OutdatedAdjustersConsiderationWarning = () => {
  const [show, setShow] = useState(true);

  const onClick = () => {
    setShow(false);
  };

  if (!show) return null;

  return (
    <WarningNotification
      descriptionText="You should reconsider your input."
      headingText="Surveyor has made changes to this invoice after the you made your considerations."
      size="large"
      onClose={onClick}
    />
  );
};

export interface InvoiceMessageProps {
  conflict?: boolean;
  ownsLock?: boolean;
  outdatedAdjustersConsideration?: boolean;
  isClaimStatementClosed?: boolean;
}

export const InvoiceMessages: FC<InvoiceMessageProps> = ({ conflict, ownsLock = true, outdatedAdjustersConsideration, isClaimStatementClosed = false }) => (
  <>
    {conflict && <InvoiceConflictWarning />}
    {!ownsLock && <InvoiceLockedWarning /> }
    {outdatedAdjustersConsideration && <OutdatedAdjustersConsiderationWarning />}
    {isClaimStatementClosed && <ClaimStatementClosedWarning />}
  </>
);

interface InstructionsProps {
  instructions: string | undefined;
  ownersInstructions?: boolean;
}

export const Instructions: FC<InstructionsProps> = ({ instructions, ownersInstructions = false }) => {
  const trimmedInstructions = instructions?.trim();
  if (!trimmedInstructions) {
    return null;
  }

  const title = ownersInstructions
    ? 'Comment to Owner'
    : 'Instructions to Surveyor';

  return <InstructionsPane title={title} instructions={trimmedInstructions} />;
};
