import React, { FunctionComponent } from 'react';
import {
  CheckboxTableHeaderProps, FilterableTableHeader, TextTableHeader,
  SortedTableHeader, TableHeader, TableHeaderRow, FilterableTableHeaderProps
} from '@instech/components';
import styled from 'styled-components';
import { CheckboxTableHeaderStyled } from '../../core/Components';

const EmptyTableHeader = styled(TableHeader)`
  padding: 0px;
  border-bottom: 0;
`;

interface CheckboxOrEmptyProps extends CheckboxTableHeaderProps {
  selectableRows?: boolean;
}
const CheckboxOrEmptyHeader = ({ name, selected, indeterminate, onClick, selectableRows }: CheckboxOrEmptyProps) => {
  if (!selectableRows) return <EmptyTableHeader />;
  return (
    <CheckboxTableHeaderStyled
      name={name}
      selected={selected}
      indeterminate={indeterminate}
      onClick={onClick}
      slimHeader
    />
  );
};

interface FilterableOrTextHeaderProps extends FilterableTableHeaderProps {
  noSurveyorFilter?: boolean;
}
const FilterableOrTextHeader = ({ noSurveyorFilter, ...props }: FilterableOrTextHeaderProps) => {
  if (!noSurveyorFilter) return <FilterableTableHeader {...props} />;
  return (
    <TextTableHeader
      title={props.header.title}
      center={props.header.center}
      stickyTop={props.stickyTop}
      borderColor={props.borderColor}
      className={props.className}
      slimHeader
    />
  );
};

interface Props {
  sortedHeader?: SortedTableHeader;
  setSortedHeader: (val?: any) => any;
  allItems?: number;
  selectedItems?: number;
  handleSelectAll?: () => void;
  stickyTop?: string;
  selectableRows?: boolean;
  noSurveyorFilter?: boolean;
}
/**
 * Header row for the Invoice Table. Constructed from a TableHeaderRow and individual
 * TableHeader components served from Frontend Components.
 *
 * There is a bit of repeated code going on here, where certain things need to be
 * individually bound to each header cell; hence the use of a collection of shared
 * props that are then spread out to each header cell component.
 */
export const InvoiceTableHeaders: FunctionComponent<Props> = ({
  sortedHeader,
  setSortedHeader,
  allItems = 0,
  selectedItems = 0,
  handleSelectAll = () => null,
  stickyTop,
  selectableRows,
  noSurveyorFilter
}) => {
  // Collection of props that are the same between all the header's cells
  const sharedProps = {
    sortedHeader,
    setSortedHeader,
    slimHeader: true
  };

  return (
    <TableHeaderRow stickyTop={stickyTop}>
      <CheckboxOrEmptyHeader
        name="invoice-table-checkbox-all"
        selected={allItems === selectedItems && allItems > 0}
        indeterminate={allItems !== selectedItems && selectedItems > 0}
        onClick={handleSelectAll}
        selectableRows={selectableRows}
        slimHeader
      />
      <FilterableTableHeader
        header={{
          title: 'Invoice No',
          propertyName: 'record.invoiceNumber',
          parameterName: 'record.invoiceNumber',
        }}
        {...sharedProps}
      />
      <FilterableTableHeader
        header={{
          title: 'Supplier',
          propertyName: 'record.supplier',
          parameterName: 'record.supplier',
        }}
        {...sharedProps}
      />
      <FilterableTableHeader
        header={{
          title: 'Category',
          propertyName: 'record.category',
          parameterName: 'record.category',
        }}
        {...sharedProps}
      />
      <FilterableTableHeader
        header={{
          title: 'Cost Description',
          propertyName: 'record.ownersComment',
          parameterName: 'record.ownersComment',
        }}
        {...sharedProps}
      />
      <FilterableTableHeader
        header={{
          title: 'Currency',
          propertyName: 'record.currency',
          parameterName: 'record.currency'
        }}
        {...sharedProps}
      />
      <FilterableTableHeader
        header={{
          title: 'Total',
          propertyName: 'record.totalAmount',
          parameterName: 'record.totalAmount',
          center: 'flex-end'
        }}
        {...sharedProps}
      />
      <FilterableOrTextHeader
        header={{
          title: 'General Expenses',
          propertyName: 'surveyorsConsideration.generalExpenses',
          parameterName: 'surveyorsConsideration.generalExpenses',
          center: 'flex-end'
        }}
        noSurveyorFilter={noSurveyorFilter}
        {...sharedProps}
      />
      <FilterableOrTextHeader
        header={{
          title: 'Claim',
          propertyName: 'surveyorsConsideration.claimConsideration',
          parameterName: 'surveyorsConsideration.claimConsideration',
          center: 'flex-end'
        }}
        noSurveyorFilter={noSurveyorFilter}
        {...sharedProps}
      />
      <FilterableOrTextHeader
        header={{
          title: 'Owner',
          propertyName: 'surveyorsConsideration.ownersWorkConsideration',
          parameterName: 'surveyorsConsideration.ownersWorkConsideration',
          center: 'flex-end'
        }}
        noSurveyorFilter={noSurveyorFilter}
        {...sharedProps}
      />
      <FilterableOrTextHeader
        header={{
          title: 'Adjuster to Consider',
          propertyName: 'surveyorsConsideration.adjusterToConsider',
          parameterName: 'surveyorsConsideration.adjusterToConsider',
          center: 'flex-end'
        }}
        noSurveyorFilter={noSurveyorFilter}
        {...sharedProps}
      />
      <FilterableOrTextHeader
        header={{
          title: 'Description of Consideration',
          propertyName: 'surveyorsConsideration.surveyorsComment',
          parameterName: 'surveyorsConsideration.surveyorsComment',
        }}
        noSurveyorFilter={noSurveyorFilter}
        {...sharedProps}
      />
      <EmptyTableHeader />
    </TableHeaderRow>
  );
};
