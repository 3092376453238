import { useTypedModal } from '@/common/modules/Modal';
import { ButtonGroup } from '@instech/components';
import { FC } from 'react';
import styled from 'styled-components';
import {
  AddSplitButton, ApplyOwnerValuesButton,
  ReorderSplitsButton
} from '../core/ApplyValuesButton';
import { ReorderSurveyorSplitsProps, reorderSurveyorSplitsModal } from './reorderSurveyorSplitsModal';

export const ButtonRow = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;

interface Props {
  onAddSplit: () => void;
  isMultiple?: boolean;
  showApplyValuesOwner?: boolean;
  onApplyValues?: () => void;
  applyValuesDisabled?: boolean;
  reorderSurveyorSplitsProps: ReorderSurveyorSplitsProps
}

export const SurveyorsConsiderationButtons: FC<Props> = ({
  onAddSplit,
  isMultiple,
  showApplyValuesOwner,
  onApplyValues,
  applyValuesDisabled,
  reorderSurveyorSplitsProps,
}) => {
  const { open: openReorderSplitsModal } = useTypedModal(reorderSurveyorSplitsModal(reorderSurveyorSplitsProps));

  if (!isMultiple) return null;
  return (
    <ButtonRow>
      <ButtonGroup>
        <ReorderSplitsButton onClick={openReorderSplitsModal} />
      </ButtonGroup>
      <ButtonGroup alignRight>
        {showApplyValuesOwner && <ApplyOwnerValuesButton onApplyValues={onApplyValues} disabled={applyValuesDisabled} />}
        <AddSplitButton onClick={onAddSplit} />
      </ButtonGroup>
    </ButtonRow>
  );
};
