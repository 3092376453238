import styled from 'styled-components';
import { EyeSlash, Eye } from '@instech/icons';
import { InvoiceState } from '@/types';
import { invoiceStates } from '@/utils/constants';

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${props => props.theme.marineBlue60};
  font-style: italic;
  & svg {
    margin-right: 8px;
  }
`;

interface SwitchProps {
  state: InvoiceState;
}

/**
 * Switch state of what message should be displayed.
 * For Review and ReturnToUploader, invoice is not visible to Surveyor.
 * For CostAllocation and CostReallocation, invoice is visible to Surveyor.
 */
export const SurveyorMessageSwitch = ({ state }: SwitchProps) => {
  switch (state) {
    case invoiceStates.Review:
    case invoiceStates.AdjustersConsideration:
      return (
        <MessageWrapper>
          <EyeSlash />
          Not visible for Surveyor. Select the invoice to share it.
        </MessageWrapper>
      );
    case invoiceStates.ReturnToUploader:
    case invoiceStates.ReturnToUploaderAfterConsiderationNoAllocation:
    case invoiceStates.AdjustersConsiderationNoAllocation:
    case invoiceStates.FinishedNoAllocation:
      return (
        <MessageWrapper>
          <EyeSlash />
          Not visible for Surveyor.
        </MessageWrapper>
      );
    case invoiceStates.CostReallocation:
    case invoiceStates.CostAllocation:
      return (
        <MessageWrapper>
          <Eye />
          At Surveyor&apos;s desk.
        </MessageWrapper>
      );
    case invoiceStates.SharedReview:
    case invoiceStates.ReturnToUploaderAfterSharedReview:
    case invoiceStates.FinishedSharedReview:
      return (
        <MessageWrapper>
          <Eye />
          Visible for Surveyor, not to be considered.
        </MessageWrapper>
      );
    default:
      return null;
  }
};
