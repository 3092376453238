import { UpdateInvoiceForm } from '@/types';

export const useDisplayCurrency = (
  values: UpdateInvoiceForm,
  selectedCurrency: string | undefined,
  policyCurrency: string | undefined
) => {
  if (selectedCurrency === policyCurrency) {
    return policyCurrency;
  }

  return values.currency?.value;
};
