import { FormRow } from '@/common/components/Form/FormRow/FormRow';
import { getClaimDataAsync } from '@/services/claimServices';
import { ClaimSearchData, ClaimsData } from '@/types';
import { NumberFieldControlled, SlimButton } from '@instech/components';
import { MoveLayerDown } from '@instech/icons';
import {
  FunctionComponent as FC, useEffect, useState
} from 'react';
import styled from 'styled-components';
import { AxiosError } from 'axios';
import { useErrorToggler } from '@/components/pages/claim/core/ClaimStatementForm/Occurrences/SearchErrorMessage';

const Container = styled.div`
  max-width: 930px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

// Using empty string '' as initial / empty value, as a controlled input supports
// this, but does not support other non-values (undefined, null, etc.) as empty
// initializers (https://reactjs.org/docs/forms.html#controlled-components)
interface SearchStateProp {
  number: number | string;
  year: number | string;
}

interface Props {
  onSuccess: (payload: ClaimsData) => void;
  onError: (statusCode: number, claimSearchData: ClaimSearchData) => void;
}
export const ClaimDataSearch: FC<Props> = ({ onSuccess, onError }) => {
  const [isPending, setIsPending] = useState(false);
  const [search, setSearch] = useState<SearchStateProp>({ number: '', year: '' });
  const { isErrorVisible, hideError } = useErrorToggler();

  useEffect(() => {
    if (search) {
      hideError();
    }
  }, [hideError, search]);

  const handleSearchSubmit = async () => {
    try {
      if (search.number && search.year) {
        setIsPending(true);
        const claimData = await getClaimDataAsync(search.number.toString(), search.year.toString());
        onSuccess(claimData);
      }
    } catch (e) {
      const statusCode = (e as AxiosError)!.response!.status;
      onError(statusCode, { claimNumber: search.number, insuranceYear: search.year });
    } finally {
      setIsPending(false);
    }
  };

  return (
    <Container>
      <FormRow gutter="32px" layoutLarge="1fr 1fr 156px">
        <NumberFieldControlled
          name="claimdata-search-number"
          label="Claim Number"
          placeholder="Number"
          value={search.number}
          onChange={val => setSearch(prev => ({ ...prev, number: val || '' }))}
          required
          noArrows
          touched // always touched since this only affects whether errors are shown
          error={isErrorVisible ? 'true' : undefined}
          noErrors />
        <NumberFieldControlled
          name="claimdata-search-year"
          label="Insurance Year"
          placeholder="Year"
          value={search.year}
          onChange={val => setSearch(prev => ({ ...prev, year: val || '' }))}
          required
          noArrows
          touched // always touched since this only affects whether errors are shown
          error={isErrorVisible ? 'true' : undefined}
          noErrors />
        <ButtonWrapper>
          <SlimButton
            startIcon={<MoveLayerDown />}
            onClick={handleSearchSubmit}
            loading={isPending}
            disabled={isPending}
            width="100%">
            GET CLAIM DATA
          </SlimButton>
        </ButtonWrapper>
      </FormRow>
    </Container>
  );
};
