import { InvoiceMessages } from '@/components/pages/invoice/InvoicePage/core/Components';
import { useIsClosed } from '@/services/claimStatementServices';
import {
  ClaimStatement, Invoice, LatestInstructions
} from '@/types';
import { invoiceStates } from '@/utils/constants';
import { FunctionComponent as FC } from 'react';
import { EditInvoiceSurveyor } from '../../core/EditInvoice/EditInvoiceSurveyor';
import {
  AdditionalInformationPane,
  OwnersClaimPane,
  SurveyorsConsiderationPane
} from '../../core/ReadOnlyInvoice';
import { InstructionsPane } from '../../core/ReadOnlyInvoice/InstructionsPane';
import { NavigationalActionButtons } from './NavigationalActionButtons';
import { useInvoiceConflict } from './invoiceConflict';

interface ReadOnlyProps {
  invoice: Invoice;
  claimStatement: ClaimStatement;
}

const ReadOnlyView: FC<ReadOnlyProps> = ({ claimStatement, invoice }) => (
  <>
    <OwnersClaimPane claimStatement={claimStatement} invoice={invoice} policyCurrency={claimStatement.currency} readonly />
    <AdditionalInformationPane invoice={invoice} hasSplit={(invoice?.surveyorsConsideration?.splits?.length ?? 0) > 1} />
    {invoice?.surveyorsConsideration?.isConsidered && <SurveyorsConsiderationPane invoice={invoice} />}
  </>
);

interface Props {
  invoice: Invoice;
  claimStatement: ClaimStatement;
  ownsLock: boolean;
  surveyorInstructions?: LatestInstructions;
}
/**
 * Switch component for handling whether to show the Edit or Readonly view of the
 * left-hand invoice details side of an invoice page. One such component for reach
 * role, to handle different rendering scenarios for different roles.
 * The default view is readonly, with the edit view enabled by a prop.
 */
export const InvoiceDetailsSurveyor: FC<Props> = ({ claimStatement, invoice, ownsLock, surveyorInstructions }) => {
  const { conflict, handleInvoiceConflict } = useInvoiceConflict();
  // fallback if an invoice is submitted with only space for instructions
  const trimmedInstructions = surveyorInstructions?.instructions?.trim();
  const isClosed = useIsClosed(claimStatement);
  const readonly = !ownsLock || isClosed;

  switch (invoice.state) {
    case invoiceStates.CostAllocation:
    case invoiceStates.CostReallocation:
      return (
        <div>
          {readonly && (
            <NavigationalActionButtons
              anchorId="surveyor-action-buttons"
              invoice={invoice}
            />
          )}
          <InvoiceMessages
            conflict={conflict}
            ownsLock={ownsLock}
            isClaimStatementClosed={isClosed}
          />
          {trimmedInstructions && (
            <InstructionsPane
              title="Instructions from Claims Handler"
              instructions={trimmedInstructions}
            />
          )}
          {!readonly && (
            <EditInvoiceSurveyor
              claimStatement={claimStatement}
              invoice={invoice}
              handleInvoiceConflict={handleInvoiceConflict}
            />
          )}
          {readonly && (
            <ReadOnlyView
              invoice={invoice}
              claimStatement={claimStatement}
            />
          )}
        </div>
      );
    default:
      return (
        <div>
          <NavigationalActionButtons
            anchorId="surveyor-action-buttons"
            invoice={invoice}
          />
          <InvoiceMessages isClaimStatementClosed={isClosed} />
          {trimmedInstructions && (
            <InstructionsPane
              title="Instructions from Claims Handler"
              instructions={trimmedInstructions}
            />
          )}
          <ReadOnlyView
            invoice={invoice}
            claimStatement={claimStatement}
          />
        </div>
      );
  }
};
