import { DecimalNumberField } from '@/common/components/Form/DecimalNumberField';
import { FormRow } from '@/common/components/Form/FormRow/FormRow';
import { OwnersClaimForm, UpdateInvoiceForm } from '@/types';
import {
  CollapsiblePane,
  LabelValuePair,
  TextArea,
  TextField
} from '@instech/components';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import styled from 'styled-components';
import { getParsedDecimalAsStringWithGivenPrecision } from '@/utils/number';
import { InvoiceRecognition } from '@/services/invoice/invoiceServices/useInvoiceRecognition';
import {
  CurrencyInput,
  InvoiceNumberInput,
  PaymentDateInput,
  SupplierInput,
  TotalAmountInput
} from './InputFields';
import {
  useRemoveRequiredEagerly,
  useRemoveRequiredEagerlyFromNumericalField
} from '../utils/useRemoveRequiredEagerly';
import { InternalNote } from './InternalNote';
import { useExchangeRateLabel } from '../../utils/useExchangeRateLabel';

export const ownersClaimInitialValues: OwnersClaimForm = {
  invoiceNumber: '',
  paymentDate: '',
  clientReference: '',
  ownersComment: '',
  notes: '',
};

const formGutter = '16px';
const layoutLarge = '1fr 1fr 0px 1fr 0px 1fr';

const StyledRow = styled(FormRow)`
  align-items: center;
`;

const Operator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${({ theme }) => theme.marineBlue};
`;

interface OwnersClaimPaneProps {
  policyCurrency?: string,
  supplierOptions: LabelValuePair[],
  recognitionValues?: InvoiceRecognition | undefined
}

export const OwnersClaimPane = ({ supplierOptions, policyCurrency, recognitionValues }: OwnersClaimPaneProps) => {
  const {
    values,
    validateField,
    errors,
    touched,
    setFieldValue
  } = useFormikContext<UpdateInvoiceForm>();
  const sameCurrency = policyCurrency === values.currency?.value;

  // "The issue was that the invoice total amount had some behavior to remove error messages very eagerly.
  // This could cause the app to hang. It was fixed by only eagerly removing error messages of the type "Required".
  // TODO: Investigate deeper root cause of performance issues?
  useRemoveRequiredEagerly('currency');
  useRemoveRequiredEagerlyFromNumericalField('invoiceNumber');
  useRemoveRequiredEagerlyFromNumericalField('totalAmount');
  useRemoveRequiredEagerly('supplier');

  // Without Effect, validation of paymentDate does not trigger -- ICS-736
  useEffect(() => {
    if (values.paymentDate || errors.paymentDate) {
      void validateField('paymentDate');
    }
  }, [errors.paymentDate, touched.paymentDate, validateField, values.paymentDate]);

  const exchangeRateLabel = useExchangeRateLabel(values.currency?.value, policyCurrency);
  const paidAmountLabel = sameCurrency ? 'Paid Amount' : `Paid Amount - ${policyCurrency}`;

  // Update exchange rate based on total amount and paid amount
  const updateExchangeRate = () => {
    const totalAmount = Number(values.totalAmount);
    const paidAmount = Number(values.paidAmount);
    const newExchangeRate = paidAmount / totalAmount;
    const exchangeRateRaw = totalAmount && paidAmount ? getParsedDecimalAsStringWithGivenPrecision(newExchangeRate.toFixed(13), 12, true) : '';

    // Check if there is a difference after rounding off to the sixth decimal
    if (Number((Number(values.exchangeRate) - newExchangeRate).toFixed(12)) !== 0) {
      void setFieldValue('exchangeRate', exchangeRateRaw);
    }
  };

  // Update paid amount based on total amount and exchange rate
  const updatePaidAmount = () => {
    const totalAmount = Number(values.totalAmount);
    const exchangeRate = Number(values.exchangeRate);
    const newPaidAmount = totalAmount * exchangeRate;
    const paidAmountRaw = totalAmount && exchangeRate ? getParsedDecimalAsStringWithGivenPrecision(newPaidAmount.toFixed(7), 2, true) : '';
    if (values.paidAmount !== paidAmountRaw) {
      void setFieldValue('paidAmount', paidAmountRaw);
    }
  };

  const updateExchangeRateAndPaidAmount = () => {
    updateExchangeRate();

    // Clear paid amount if total amount is cleared
    if (!values.totalAmount || values.totalAmount === '0') {
      void setFieldValue('paidAmount', '');
    }
  };

  return (
    <CollapsiblePane padding="20px" title="Owner's Claim" color="green">
      <FormRow gutter={formGutter} layoutLarge={layoutLarge} marginBottom="18px">
        <SupplierInput suggestion={recognitionValues?.supplier} options={supplierOptions} />
        <InvoiceNumberInput suggestion={recognitionValues?.invoiceNumber} />
        <Operator />
        <PaymentDateInput suggestion={recognitionValues?.paymentDate} />
        <Operator />
        <TextField placeholder="" name="clientReference" label="Client Reference" />
      </FormRow>
      <StyledRow gutter={formGutter} layoutLarge={layoutLarge} marginBottom="18px">
        <CurrencyInput suggestion={recognitionValues?.totalAmount?.currencyCode} />
        <TotalAmountInput suggestion={recognitionValues?.totalAmount?.amount} onBlur={updateExchangeRateAndPaidAmount} />
        <Operator>x</Operator>
        <DecimalNumberField
          name="exchangeRate"
          maxDecimals={12}
          label={exchangeRateLabel}
          disabled={sameCurrency}
          onBlur={updatePaidAmount} />
        <Operator>=</Operator>
        <DecimalNumberField
          name="paidAmount"
          maxDecimals={2}
          label={paidAmountLabel}
          disabled={sameCurrency}
          onBlur={updateExchangeRate} />
      </StyledRow>
      <FormRow gutter={formGutter} layoutLarge="100%" marginTop="18px">
        <TextArea name="ownersComment" label="Cost Description" placeholder="" />
      </FormRow>
      <FormRow gutter={formGutter} layoutLarge="100%" marginTop="18px">
        <InternalNote />
      </FormRow>
    </CollapsiblePane>
  );
};
