import { InvoiceState, InvoiceStateMetadata } from '@/types';
import { invoiceStates } from '@/utils/constants';

type ButtonState = 'Active' | 'Disabled';
interface ButtonDisabledStates {
  returnToUploader: ButtonState;
  share: ButtonState;
  finished: ButtonState;
  shareForReview: ButtonState;
  enableRevokeFromReview: ButtonState;
}

const isShareDisabled = (invoiceMetadata: InvoiceStateMetadata[]): boolean => {
  const validStates: InvoiceState[] = [invoiceStates.Review, invoiceStates.SharedReview, invoiceStates.AdjustersConsideration,
    invoiceStates.AdjustersConsiderationNoAllocation];
  const selectedStates = invoiceMetadata.map(x => x.state);

  // Only a single state can be selected
  const areAllStatesTheSame = selectedStates.every(s => s === selectedStates[0]);
  if (!areAllStatesTheSame) return true;
  const isValidState = validStates.includes(selectedStates[0]);
  if (!isValidState) return true;
  // Only a single item can be selected if adjusters consideration
  const hasMultipleAdjustersConsiderations = selectedStates[0] === invoiceStates.AdjustersConsideration && selectedStates.length > 1;
  if (hasMultipleAdjustersConsiderations) return true;
  // All good
  return false;
};

export const getClaimsHandlerButtonStates = (invoiceMetadata: InvoiceStateMetadata[]): ButtonDisabledStates => {
  const canReturnToUploader: InvoiceState[] = [invoiceStates.Review, invoiceStates.AdjustersConsideration, invoiceStates.SharedReview,
    invoiceStates.AdjustersConsiderationNoAllocation];
  const canBeFinished: InvoiceState[] = [invoiceStates.SharedReview, invoiceStates.AdjustersConsideration, invoiceStates.AdjustersConsiderationNoAllocation];
  const canBeSharedForReview: InvoiceState[] =
    [invoiceStates.Finished, invoiceStates.FinishedNoAllocation, invoiceStates.FinishedSharedReview, invoiceStates.AdjustersConsiderationNoAllocation,
      invoiceStates.AdjustersConsideration, invoiceStates.SharedReview];
  const selectedStates = invoiceMetadata.map(x => x.state);

  const shouldDisable = (permittedStates: InvoiceState[]) => !selectedStates.every(state => permittedStates.includes(state));
  return {
    finished: shouldDisable(canBeFinished) ? 'Disabled' : 'Active',
    returnToUploader: shouldDisable(canReturnToUploader) ? 'Disabled' : 'Active',
    share: isShareDisabled(invoiceMetadata) ? 'Disabled' : 'Active',
    shareForReview: shouldDisable(canBeSharedForReview) || invoiceMetadata.some(x => x.isSharedForReview) ? 'Disabled' : 'Active',
    enableRevokeFromReview: invoiceMetadata.every(x => x.isSharedForReview) ? 'Active' : 'Disabled'
  };
};
