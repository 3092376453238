import { FilterableTableHeaderMax, StyledEmptyTableHeader } from '@/common/components/Table/core/TableHeaderComponents';
import { theme } from '@/utils/style/theme';
import {
  CheckboxTableHeaderProps,
  SortedTableHeader,
  TableHeaderRow
} from '@instech/components';
import { FunctionComponent } from 'react';
import { CheckboxTableHeaderStyled } from '../../core/Components';

interface CheckboxOrEmptyProps extends CheckboxTableHeaderProps {
  selectableRows?: boolean;
}
const CheckboxOrEmptyHeader = ({ name, selected, indeterminate, onClick, selectableRows }: CheckboxOrEmptyProps) => {
  if (!selectableRows) {
    return <StyledEmptyTableHeader borderColor={theme.marineBlue60} slimBorder slimHeader />;
  }
  return (
    <CheckboxTableHeaderStyled
      name={name}
      selected={selected}
      indeterminate={indeterminate}
      onClick={onClick}
      borderColor={theme.marineBlue60}
      slimBorder
      slimHeader
    />
  );
};

interface Props {
  sortedHeader?: SortedTableHeader;
  setSortedHeader: (val?: any) => any;
  allItems?: number;
  selectedItems?: number;
  handleSelectAll: () => void;
  policyCurrency: string;
  stickyTop?: string;
  selectableRows?: boolean;
}
/**
 * Header row for the Invoice Table. Constructed from a TableHeaderRow and individual
 * TableHeader components served from Frontend Components.
 *
 * There is a bit of repeated code going on here, where certain things need to be
 * individually bound to each header cell; hence the use of a collection of shared
 * props that are then spread out to each header cell component.
 */
export const InvoiceTableHeaders: FunctionComponent<Props> = ({
  sortedHeader,
  setSortedHeader,
  allItems = 0,
  selectedItems = 0,
  handleSelectAll,
  policyCurrency,
  stickyTop,
  selectableRows
}) => {
  // Collection of props that are the same between all the header's cells
  const sharedProps = {
    sortedHeader,
    setSortedHeader,
    borderColor: theme.marineBlue60,
    slimBorder: true,
    slimHeader: true
  };

  return (
    <TableHeaderRow stickyTop={stickyTop}>
      <CheckboxOrEmptyHeader
        name="invoice-table-checkbox-all"
        selected={allItems === selectedItems && allItems > 0}
        indeterminate={allItems !== selectedItems && selectedItems > 0}
        onClick={handleSelectAll}
        selectableRows={selectableRows}
        slimHeader
      />
      <FilterableTableHeaderMax
        header={{
          title: 'Invoice No',
          propertyName: 'record.invoiceNumber',
          parameterName: 'record.invoiceNumber',
        }}
        {...sharedProps}
      />
      <FilterableTableHeaderMax
        header={{
          title: 'Status',
          propertyName: 'state',
          parameterName: 'state',
        }}
        {...sharedProps}
      />
      <FilterableTableHeaderMax
        header={{
          title: 'Supplier',
          propertyName: 'record.supplier',
          parameterName: 'record.supplier',
        }}
        {...sharedProps}
      />
      <FilterableTableHeaderMax
        header={{
          title: 'Category',
          propertyName: 'record.category',
          parameterName: 'record.category',
        }}
        {...sharedProps}
      />
      <FilterableTableHeaderMax
        header={{
          title: 'Location',
          propertyName: 'record.location',
          parameterName: 'record.location',
        }}
        {...sharedProps}
      />
      <FilterableTableHeaderMax
        header={{
          title: 'Cost Description',
          propertyName: 'record.ownersComment',
          parameterName: 'record.ownersComment',
        }}
        {...sharedProps}
      />
      <FilterableTableHeaderMax
        header={{
          title: 'Payment Date',
          propertyName: 'record.paymentDate',
          parameterName: 'record.paymentDate',
        }}
        {...sharedProps}
      />
      <FilterableTableHeaderMax
        header={{
          title: 'Currency',
          propertyName: 'record.currency',
          parameterName: 'record.currency'
        }}
        {...sharedProps}
      />
      <FilterableTableHeaderMax
        header={{
          title: 'Exchange Rate',
          propertyName: 'record.exchangeRate',
          parameterName: 'record.exchangeRate',
          center: 'flex-end'
        }}
        {...sharedProps}
      />
      <FilterableTableHeaderMax
        header={{
          title: 'Total - Invoice CCY',
          propertyName: 'record.totalAmount',
          parameterName: 'record.totalAmount',
          center: 'flex-end'
        }}
        {...sharedProps}
      />
      <FilterableTableHeaderMax
        header={{
          title: `Total - ${policyCurrency}`,
          propertyName: 'record.paidAmount',
          parameterName: 'record.paidAmount',
          center: 'flex-end'
        }}
        {...sharedProps}
      />
      <FilterableTableHeaderMax
        header={{
          title: 'Claim',
          propertyName: 'record.claimInPolicyCurrency',
          parameterName: 'record.claimInPolicyCurrency',
          center: 'flex-end'
        }}
        {...sharedProps}
      />
      <FilterableTableHeaderMax
        header={{
          title: 'Owner',
          propertyName: 'record.ownersWorkInPolicyCurrency',
          parameterName: 'record.ownersWorkInPolicyCurrency',
          center: 'flex-end'
        }}
        {...sharedProps}
      />
      <StyledEmptyTableHeader
        borderColor={theme.marineBlue60}
        slimBorder
        slimHeader
      />
    </TableHeaderRow>
  );
};
