import { IconButton } from '@instech/components';
import { Apply } from '@instech/icons';
import { FC } from 'react';
import styled from 'styled-components';
import { BaseTableCell } from './BaseTableCell';

const StyledTableCell = styled(BaseTableCell)`
  height: 100%;
  padding: 0px 12px !important;

  & button {
    position: relative;
    height: 100%;
    width: 54px;
    background-color: ${props => `${props.theme.marineBlue}25`};
    cursor: initial; // do not need to show pointer here for now
  }
  & svg {
    width: 12px;
    color: ${props => props.theme.marineBlue80};
  }
`;

interface Props {
  id: string;
}
export const SubrowCellExpanded: FC<Props> = ({ id }) => (
  <StyledTableCell id={id}>
    <IconButton icon={<Apply />} smallIcon />
  </StyledTableCell>
);
