import {
  useClaimStatement,
  useIsClosed
} from '@/services/claimStatementServices';
import { useInvoiceLock } from '@/services/invoice/invoiceLockService';
import {
  useInvoice, useInvoiceLink, useLatestOwnersInstructions
} from '@/services/invoice/invoiceServices';
import { InvoiceState } from '@/types';
import { invoiceStates } from '@/utils/constants';
import { PageLoader } from '@instech/components';
import { PageContent } from '@/common/layout/Page';
import { useRequireClaimStatementInvoiceId } from '@/utils/useRequireClaimStatementId';
import { InvoiceDisplay } from '../core/EditInvoice/InvoiceDisplay/InvoiceDisplay';
import { useUserSessionSlide } from '../core/EditInvoice/utils/useUserSessionSlide';
import { OwnerInvoiceHeader } from './InvoiceHeader/OwnerInvoiceHeader';
import { InvoicePageLayout } from './core/Components';
import { InvoiceDetailsOwner } from './core/InvoiceDetailsOwner';

export const editableStates: InvoiceState[] = [
  invoiceStates.ReturnToUploader,
  invoiceStates.New,
  invoiceStates.ReturnToUploaderAfterConsideration,
  invoiceStates.ReturnToUploaderAfterSharedReview,
  invoiceStates.ReturnToUploaderAfterConsiderationNoAllocation
];

export const OwnerInvoicePage = () => {
  const { claimStatementId, invoiceId } = useRequireClaimStatementInvoiceId();
  const { data: invoice, replaceInvoice } = useInvoice(claimStatementId, invoiceId);
  const { data: claimStatement } = useClaimStatement(claimStatementId);
  const { data: imageData, mutate: refresh } = useInvoiceLink(claimStatementId, invoiceId);
  const { data: ownerInstructions } = useLatestOwnersInstructions(claimStatementId, invoiceId);
  const isClosed = useIsClosed(claimStatement);
  const { ownsLock, unlockInvoice } = useInvoiceLock(editableStates, claimStatementId, invoiceId, isClosed);
  useUserSessionSlide(unlockInvoice);

  if (!invoice || !claimStatement || ownsLock === null || !ownerInstructions) {
    return <PageLoader />;
  }

  const invoiceActionPaneProp = { invoice, replaceInvoice, refresh };
  const canEdit = editableStates.includes(invoice.state);

  return (
    <div>
      <OwnerInvoiceHeader
        invoice={invoice}
        claimStatement={claimStatement}
      />
      <PageContent>
        <InvoicePageLayout>
          <InvoiceDetailsOwner
            claimStatement={claimStatement}
            invoice={invoice}
            ownsLock={ownsLock}
            ownerInstructions={ownerInstructions}
          />
          <InvoiceDisplay
            invoice={invoice}
            replaceable={canEdit && ownsLock}
            invoiceActionPane={invoiceActionPaneProp}
            ownsLock={ownsLock}
            imageUrl={imageData?.url}
            isClaimStatementClosed={isClosed} />
        </InvoicePageLayout>
      </PageContent>
    </div>
  );
};
