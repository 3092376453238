import { FCWC, SurveyorsConsiderationSplit } from '@/types';
import {
  createContext, useContext, useMemo
} from 'react';

const findSplitById = (splitId?: string, surveyorSplits?: SurveyorsConsiderationSplit[]) => {
  if (!splitId || !surveyorSplits) return undefined;
  return surveyorSplits?.find(split => split.id === splitId);
};

interface SplitsContextData {
  surveyorSplits?: SurveyorsConsiderationSplit[];
  getSplitById: (id?: string) => SurveyorsConsiderationSplit | undefined;
}
const SurveyorSplitsContext = createContext<SplitsContextData | null>(null);

interface Props {
  surveyorSplits?: SurveyorsConsiderationSplit[];
}
export const SurveyorSplitsProvider: FCWC<Props> = ({ children, surveyorSplits }) => {
  const value = useMemo(() => ({
    surveyorSplits,
    getSplitById: (id?: string) => findSplitById(id, surveyorSplits)
  }), [surveyorSplits]);
  return (
    <SurveyorSplitsContext.Provider value={value}>
      {children}
    </SurveyorSplitsContext.Provider>
  );
};

export const useSurveyorSplitsContext = () => useContext(SurveyorSplitsContext) as SplitsContextData;

export const useSurveyorSplitById = (id?: string) => useContext(SurveyorSplitsContext)?.getSplitById(id);

/**
 * Easily determine from within the context whether the invoice has been considered
 * by the surveyor. The check omitting invoices with a zero-string ID is a fallback
 * to catch older testdata. // Deprecate that part at some point?
 */
export const useInvoiceConsideredBySurveyor = () => {
  const val = useContext(SurveyorSplitsContext)?.surveyorSplits?.every((line: SurveyorsConsiderationSplit) => (
    line !== undefined && line?.id !== '00000000-0000-0000-0000-000000000000' && line.isConsidered
  ));
  return val ?? false;
};
