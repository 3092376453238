import { FocusEventHandler } from 'react';
import {
  Loader, SlimButton, TextFieldControlled
} from '@instech/components';
import { Guid } from '@/types';
import { ButtonGroupModal } from '@/common/components/ButtonGroup/ButtonGroupModal';
import styled from 'styled-components';
import { Copy } from '@instech/icons';
import { useSharedAccessKey } from '@/services/magicLinkService';
import { useModalContext } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';

const ModalText = styled.p`
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.marineBlue};
  margin: 0px;
`;

interface ShareMagicLinkModal {
  claimStatementId: Guid;
}
const ModalContent = ({ claimStatementId }: ShareMagicLinkModal) => {
  const { close } = useModalContext();

  const { accessKey } = useSharedAccessKey(claimStatementId);
  if (accessKey === null) return <Loader />;

  const copyMagicLink = async () => {
    await navigator.clipboard.writeText(accessKey);
  };

  const selectMagicLink: FocusEventHandler<HTMLInputElement> = event => {
    event.currentTarget.select();
    event.currentTarget.setSelectionRange(0, 99999); // fallback for some browsers
    // TODO: Should clicking field to select magic link also copy to clipboard?
  };

  return (
    <div>
      <ModalText>
        To share read-only access to this Claim Review, copy the link below and paste it
        into your Adjustment.
      </ModalText>
      <TextFieldControlled name="magic-link-display" onFocus={selectMagicLink} value={accessKey} readOnly />
      <ModalText>
        <i>This link will expire after 1 year (365 days) or after it is accessed 100 times, whichever comes first.</i>
      </ModalText>
      <ButtonGroupModal marginTop="24px" buttonGap="16px">
        <SlimButton variant="secondary" type="reset" onClick={close}>CANCEL</SlimButton>
        <SlimButton startIcon={<Copy />} onClick={copyMagicLink}>COPY LINK</SlimButton>
      </ButtonGroupModal>
    </div>
  );
};

export const shareMagicLinkModal = (
  { claimStatementId }: ShareMagicLinkModal
): ModalType<ShareMagicLinkModal> => ({
  component: ModalContent,
  options: {
    title: 'Share access to Claim Review',
    size: 'small',
    padding: '12px 20px 20px',
  },
  args: { claimStatementId }
});
