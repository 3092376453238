import React from 'react';
import styled from 'styled-components';
import { Phone, Send } from '@instech/icons';

const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 16px;
  margin-right: 32px;
  & svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    stroke-width: 2px;
  }
`;

export const EmailInfo = ({ email }: { email?: string | null }) => {
  if (!email) return null;
  return (
    <ContactInfo>
      <Send />
      <a href={`mailto:${email}`}>{email}</a>
    </ContactInfo>
  );
};

export const PhoneInfo = ({ phone }: { phone?: string | null; }) => {
  if (!phone) return null;
  return (
    <ContactInfo>
      <Phone />
      <span>{phone}</span>
    </ContactInfo>
  );
};
