import { ReadonlyCollapsiblePane } from '@/common/components/Pane/ReadonlyCollapsiblePane';
import {
  InfoLayout,
  InfoWithLabel,
  InfoWithLabelRequired
} from '@/common/components/Text';
import { Invoice } from '@/types';
import { hasPii, piiLabel } from '@/utils/invoice/invoicePiiOptions';
import { INVOICE_PANE_MARGIN } from '@/utils/style/styleConstants';
import { FunctionComponent } from 'react';

interface Props {
  invoice: Invoice;
  showRepairPeriod?: boolean;
  hasSplit?: boolean;

}
export const AdditionalInformationPane: FunctionComponent<Props> = ({ invoice, showRepairPeriod = false, hasSplit }) => (
  <ReadonlyCollapsiblePane title="Additional Information" margin={INVOICE_PANE_MARGIN}>
    <InfoLayout columnMin="160px">
      <InfoWithLabelRequired
        label={hasSplit ? 'Category for Summary': 'Category'}
        text={invoice.record.category} />
      {showRepairPeriod && (
      <InfoWithLabel
        label={hasSplit ? 'Repair Period for Summary': 'Repair Period'}
        text={invoice.record.repairPeriod} />
      )}
      <InfoWithLabelRequired
        label="Ship Repair Location"
        text={invoice.record.location} />
      <InfoWithLabelRequired
        label="Does this invoice contain PII?"
        text={piiLabel(invoice.record.personallyIdentifiableInformation ?? '')} />
      {hasPii(invoice.record.personallyIdentifiableInformation ?? '') && (
        <InfoWithLabel
          label="PII location in document"
          text={invoice.record.personallyIdentifiableInformationDescription} />
      )}
    </InfoLayout>
  </ReadonlyCollapsiblePane>
);
