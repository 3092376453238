import useSWR from 'swr';
import { arrayToLabelValuePair } from '@/utils/labelValuePair';
import { RepairPeriods } from '@/types';
import { multipleRepairPeriodsLabelValuePair } from '@/components/pages/invoice/core/EditInvoice/utils/multipleDropdownValue';
import { useMemo } from 'react';

export const useClaimStatementRepairPeriods = (claimStatementId: string) => {
  const swrRoute = claimStatementId ? `claimstatements/${claimStatementId}/repairperiods` : null;
  return useSWR<RepairPeriods>(swrRoute);
};

export const useClaimStatementRepairPeriodsAsOptions = (claimStatementId: string) => {
  const { data: repairPeriods, mutate } = useClaimStatementRepairPeriods(claimStatementId!);

  const repairPeriodOptions = useMemo(() => {
    const repairPeriodsUpdated = repairPeriods?.repairPeriods ? repairPeriods.repairPeriods.filter(rp => rp !== multipleRepairPeriodsLabelValuePair.value) : [];
    return arrayToLabelValuePair(repairPeriodsUpdated);
  }, [repairPeriods]);

  return { repairPeriodOptions, mutate };
};
