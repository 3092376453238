import React, { FunctionComponent, useMemo } from 'react';
import { InvoiceStateBadge } from '@/apps/ClaimStatement/components/InvoiceState';
import { CheckboxCell } from '@/common/components/Table/CheckboxCell';
import { ContextMenuCell } from '@/common/components/Table/ContextMenuCell';
import { useSharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { Guid, Invoice } from '@/types';
import { hasTotalDeviation } from '@/utils/invoice/invoiceTotalDeviation';
import {
  formatNumber, formatWithDigitsOrEmpty, truncateNumberWithFormat
} from '@/utils/number';
import {
  ellipsisOrDash, textOrAsterisk, textOrDash, textOrNone
} from '@/utils/text';
import {
  Loader, MenuOption, TableCell, TableRow, getFriendlyDate
} from '@instech/components';
import { RowNavigationHandler, TableRowWithColor } from '@/common/components/Table/core/TableRow';
import { TableCellMaxContent } from '@/common/components/Table/core/TableCell';
import { TableCellWithHover } from '@/common/components/Table/core/TableCell/TableCellWithHover';
import { TableCellEmpty, RequiredTableField } from '../../core/Components';

const formatOrEmpty = (number?: number | null, showZero?: boolean) => {
  if (showZero && number === 0) return formatNumber(number);
  return number ? truncateNumberWithFormat(number) : '-';
};

const formatOrAsterisk = (number?: number | null, showZero?: boolean) => {
  if (showZero && number === 0) return formatNumber(number);
  return number ? truncateNumberWithFormat(number) : '*';
};

const getInvoiceNo = (invoice: Invoice) => `${invoice.record.order} - ${invoice.record.invoiceNumber || '*'}`;

interface TableRowProps {
  even: boolean;
  row: Invoice;
  onClick: (invoiceId: string) => void;
  selectedRows: string[];
  onSelect: (rowId: string) => void;
  selectableRows: boolean;
  allowEmpty?: boolean;
  contextMenuOptions: (invoiceId: Guid) => MenuOption[];
}
/**
 * Refactoring the row component out to its own component above the row
 * mapping, for figuring out some variables per row before returning.
 */
const InvoiceTableRow: FunctionComponent<TableRowProps> = ({
  even,
  row,
  onClick,
  selectedRows,
  onSelect,
  selectableRows,
  allowEmpty = false,
  contextMenuOptions,
}) => {
  const { tooltipId } = useSharedTooltip();
  // just reduce the number of steps down a little
  const data = row.record;
  const hasDeviation = (row.record.claim !== null || row.record.ownersWork !== null) && hasTotalDeviation({
    total: row.record.totalAmount || '',
    claim: row.record.claim || '',
    ownersWork: row.record.ownersWork || ''
  });

  const selectedRow = useMemo(() => selectedRows.includes(row.id), [selectedRows, row.id]);
  const handleClick = () => onClick(row.id);

  return (
    <TableRowWithColor even={even} isActive>
      <CheckboxCell rowId={row.id} selected={selectedRow} onClick={() => onSelect(row.id)} selectableRows={selectableRows} />
      <RowNavigationHandler onClick={handleClick}>
        <TableCellEmpty allowEmpty={allowEmpty} isEmpty={!data.invoiceNumber}>
          <RequiredTableField isEmpty={!data.invoiceNumber}>{getInvoiceNo(row)}</RequiredTableField>
        </TableCellEmpty>
        <TableCellWithHover>
          <InvoiceStateBadge state={row.state} record={row.record} />
        </TableCellWithHover>
        <TableCellEmpty allowEmpty={allowEmpty} isEmpty={!data.supplier}>
          <RequiredTableField isEmpty={!data.supplier}>
            {textOrAsterisk(data.supplier)}
          </RequiredTableField>
        </TableCellEmpty>
        <TableCellWithHover>{textOrDash(data.category)}</TableCellWithHover>
        <TableCellWithHover>{textOrDash(data.location)}</TableCellWithHover>
        <TableCellMaxContent>
          <span data-for={tooltipId} data-tip={textOrNone(data.ownersComment)}>
            {ellipsisOrDash(data.ownersComment)}
          </span>
        </TableCellMaxContent>
        <TableCellEmpty allowEmpty={allowEmpty} isEmpty={!data.paymentDate}>{getFriendlyDate(data.paymentDate)}</TableCellEmpty>
        <TableCellEmpty allowEmpty={allowEmpty} isEmpty={!data.currency}>
          <RequiredTableField isEmpty={!data.currency}>
            {textOrAsterisk(data.currency)}
          </RequiredTableField>
        </TableCellEmpty>
        <TableCellEmpty allowEmpty={allowEmpty} isEmpty={!data.exchangeRate} right>
          {textOrDash(data.exchangeRate)}
        </TableCellEmpty>
        <TableCellEmpty allowEmpty={allowEmpty} isEmpty={!data.totalAmount && data.totalAmount !== 0} right>
          <RequiredTableField isEmpty={data.totalAmount === undefined || data.totalAmount === null}>
            <div data-for={tooltipId} data-tip={formatWithDigitsOrEmpty(data.totalAmount, true)}>
              {formatOrAsterisk(data.totalAmount, true)}
            </div>
          </RequiredTableField>
        </TableCellEmpty>
        <TableCellEmpty allowEmpty={allowEmpty} isEmpty={hasDeviation} right>
          <div data-for={tooltipId} data-tip={formatWithDigitsOrEmpty(data.paidAmount, true)}>
            {formatOrEmpty(data.paidAmount, true)}
          </div>
        </TableCellEmpty>
        <TableCellEmpty allowEmpty={allowEmpty} isEmpty={hasDeviation} right>
          <div data-for={tooltipId} data-tip={formatWithDigitsOrEmpty(data.claimInPolicyCurrency, true)}>
            {formatOrEmpty(data.claimInPolicyCurrency, true)}
          </div>
        </TableCellEmpty>
        <TableCellEmpty allowEmpty={allowEmpty} isEmpty={hasDeviation} right>
          <div data-for={tooltipId} data-tip={formatWithDigitsOrEmpty(data.ownersWorkInPolicyCurrency, true)}>
            {formatOrEmpty(data.ownersWorkInPolicyCurrency, true)}
          </div>
        </TableCellEmpty>
      </RowNavigationHandler>
      <ContextMenuCell documentId={row.id} options={contextMenuOptions(row.id)} />
    </TableRowWithColor>
  );
};

interface TableRowsProps {
  data: Invoice[];
  onClick: (invoiceId: string) => void;
  selectedRows: string[];
  onSelect: (rowId: string) => void;
  selectableRows: boolean;
  allowEmpty?: boolean;
  contextMenuOptions: (invoiceId: Guid) => MenuOption[];
}
export const InvoiceTableRows = ({ data, onClick, selectedRows, onSelect, selectableRows, allowEmpty = false, contextMenuOptions }: TableRowsProps) => {
  if (!data) {
    return (
      <TableRow even>
        <TableCell span="13"><Loader size="medium" /></TableCell>
      </TableRow>
    );
  }
  return (
    <>
      {data.map((row, i) => (
        <InvoiceTableRow
          key={row.id}
          even={i % 2 === 0}
          row={row}
          onClick={onClick}
          selectedRows={selectedRows}
          onSelect={onSelect}
          selectableRows={selectableRows}
          allowEmpty={allowEmpty}
          contextMenuOptions={contextMenuOptions}
        />
      ))}
    </>
  );
};
