import { useClaimStatement } from '@/services/claimStatementServices';
import { FunctionComponent, useEffect } from 'react';
import { Outlet, useParams } from 'react-router';
import { PageLoader } from '@instech/components';

export const RequireClaimAuth: FunctionComponent = () => {
  const { claimStatementId } = useParams();
  const { data, error } = useClaimStatement(claimStatementId);

  useEffect(() => {
    if (error?.response.status === 403 || error?.response.status === 404) {
      window.location.href = '/no-claim-access';
    }
  }, [error]);

  if (!data && !error?.response) {
    return <PageLoader />;
  }

  return <Outlet />;
};
