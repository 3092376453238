import { Row, flexRender } from '@tanstack/react-table';
import {
  FC, Fragment, useMemo
} from 'react';
import { StyledTableRow } from './Components';
import { RowClickProvider } from '../../providers/RowClickContext';

interface Props {
  row: Row<any>;
  index: number;
  onClick: () => any;
  rowIsFaded?: (data: any) => boolean;
  rowIsBold?: (data: any) => boolean;
  rowIsFinished?: (data: any) => boolean;
}
/**
 * Renders a TableRow from the provided row data, mapping visible cells
 * in the row out to individual TableCell components.
 */
export const TableRow: FC<Props> = ({ row, index, onClick, rowIsFaded, rowIsBold, rowIsFinished }) => {
  const memoizedFlags = useMemo(() => ({
    isFaded: rowIsFaded?.(row.original),
    isBold: rowIsBold?.(row.original),
    isFinished: rowIsFinished?.(row.original)
  }), [row.original, rowIsBold, rowIsFaded, rowIsFinished]);

  return (
    <StyledTableRow
      key={row.id}
      isEven={index % 2 === 0}
      isFaded={memoizedFlags.isFaded}
      isBold={memoizedFlags.isBold}
      isFinished={memoizedFlags.isFinished}>
      <RowClickProvider onClick={onClick}>
        {row.getVisibleCells().map(cell => (
          <Fragment key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Fragment>
        ))}
      </RowClickProvider>
    </StyledTableRow>
  );
};
