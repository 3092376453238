import { CheckboxCell } from '@/common/components/Table/CheckboxCell';
import { ContextMenuCell } from '@/common/components/Table/ContextMenuCell';
import { TableCellMaxContent } from '@/common/components/Table/core/TableCell';
import { RowNavigationHandler, TableRowWithColor } from '@/common/components/Table/core/TableRow';
import { useSharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { tableCellVariant } from '@/common/components/Table/types';
import {
  Guid, Invoice, InvoiceState
} from '@/types';
import { invoiceStates } from '@/utils/constants';
import {
  formatNumber, formatWithDigitsOrEmpty,
  truncateNumberWithFormat
} from '@/utils/number';
import {
  ellipsisOrDash,
  textOrDash,
  textOrNone
} from '@/utils/text';
import { MenuOption } from '@instech/components';
import { FunctionComponent, useMemo } from 'react';
import { TableCellWithHover } from '@/common/components/Table/core/TableCell/TableCellWithHover';
import { getInvoiceNumber } from '../../core/utils/getInvoiceNumber';
import { InvoiceTableSurveyorCells } from './InvoiceTableSurveyorCells';

const formatOrEmpty = (number?: number | null, showZero?: boolean) => {
  if (showZero && number === 0) return formatNumber(number);
  return number ? truncateNumberWithFormat(number) : '-';
};

const readonlyStates: InvoiceState[] = [invoiceStates.SharedReview, invoiceStates.ReturnToUploaderAfterSharedReview, invoiceStates.FinishedSharedReview];

interface TableRowProps {
  even: boolean;
  row: Invoice;
  onClick: (invoiceId: string) => void;
  selectedRows: string[];
  onSelect: (rowId: string) => void;
  selectableRows: boolean;
  contextMenuOptions?: (invoiceId: Guid) => MenuOption[];
}
/**
 * Refactoring the row component out to its own component above the row
 * mapping, for figuring out some variables per row before returning.
 */
const InvoiceTableRow: FunctionComponent<TableRowProps> = ({
  even,
  row,
  onClick,
  selectedRows,
  onSelect,
  selectableRows,
  contextMenuOptions = () => []
}) => {
  const { tooltipId } = useSharedTooltip();
  // just reduce the number of steps down a little
  const data = row.record;
  const consideration = row.surveyorsConsideration;

  const readOnly = readonlyStates.includes(row.state);

  const selectedRow = useMemo(() => selectedRows.includes(row.id), [selectedRows, row.id]);

  const handleClick = () => onClick(row.id);

  return (
    <TableRowWithColor even={even} isActive>
      <CheckboxCell rowId={row.id} selected={selectedRow} onClick={() => onSelect(row.id)} selectableRows={selectableRows} />
      <RowNavigationHandler onClick={handleClick}>
        <TableCellMaxContent>{getInvoiceNumber(row)}</TableCellMaxContent>
        <TableCellMaxContent>{textOrDash(data.supplier)}</TableCellMaxContent>
        <TableCellWithHover>{textOrDash(data.category)}</TableCellWithHover>
        <TableCellMaxContent>
          <span data-for={tooltipId} data-tip={textOrNone(data.ownersComment)}>
            {ellipsisOrDash(data.ownersComment)}
          </span>
        </TableCellMaxContent>
        <TableCellMaxContent>{textOrDash(data.currency)}</TableCellMaxContent>
        <TableCellMaxContent right>
          <div data-for={tooltipId} data-tip={formatWithDigitsOrEmpty(data.totalAmount, true)}>
            {formatOrEmpty(data.totalAmount, true)}
          </div>
        </TableCellMaxContent>
        <InvoiceTableSurveyorCells
          tooltipId={tooltipId}
          considerations={consideration}
          isReadonly={readOnly}
        />
      </RowNavigationHandler>
      <ContextMenuCell
        documentId={row.id}
        options={contextMenuOptions(row.id)}
        variant={tableCellVariant.secondary}
      />
    </TableRowWithColor>
  );
};

interface TableRowsProps {
  data: Invoice[];
  onClick: (invoiceId: string) => void;
  selectedRows?: string[];
  onSelect?: (rowId: string) => void;
  selectableRows: boolean;
  contextMenuOptions?: (invoiceId: Guid) => MenuOption[];
}
export const InvoiceTableRows = ({
  data,
  onClick,
  selectedRows = [],
  onSelect = () => null,
  selectableRows,
  contextMenuOptions
}: TableRowsProps) => (
  <>
    {data.map((row, i) => (
      <InvoiceTableRow
        key={row.id}
        even={i % 2 === 0}
        row={row}
        onClick={onClick}
        selectedRows={selectedRows}
        onSelect={onSelect}
        selectableRows={selectableRows}
        contextMenuOptions={contextMenuOptions}
      />
    ))}
  </>
);
