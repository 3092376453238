import styled from 'styled-components';

const IconCell = styled.td`
  width: 48px;
  & svg {
    color: ${props => props.theme.marineBlue};
  }
`;

export default IconCell;
