import {
  SplitTableRow, TableRowItem, TableRowInner,
} from '@/apps/ClaimStatement/components/InvoiceSplitTable';
import { Emphasize, TableRowTag } from '@/apps/ClaimStatement/components/InvoiceSplitTable/SplitTableComponents';
import { useSharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import {
  FCWC, InvoiceRecord, SurveyorsConsiderationSplitForm
} from '@/types';
import { calculateSurveyorValues } from '@/utils/invoice/calculateSummaries';
import { useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';

interface Props {
  invoiceRecord: InvoiceRecord;
  consideration: SurveyorsConsiderationSplitForm;
  useInvoiceCurrency?: boolean;
  startCollapsed: boolean;
  isMultiple?: boolean;
}
export const SplitTableRowSurveyor: FCWC<Props> = ({
  children,
  invoiceRecord,
  consideration,
  useInvoiceCurrency,
  startCollapsed,
  isMultiple
}) => {
  const { tooltipId } = useSharedTooltip();
  // existing splits and first split initialize collapsed, others initialize open
  const [isCollapsed, setIsCollapsed] = useState(startCollapsed);

  const { occurrence, category, claim, ownersWork, genExpenses, adjConsideration, splitSum } = useMemo(
    () => calculateSurveyorValues(consideration, invoiceRecord.exchangeRate, useInvoiceCurrency),
    [consideration, invoiceRecord.exchangeRate, useInvoiceCurrency]
  );

  if (!isMultiple) {
    return <div>{children}</div>;
  }

  const handleCollapse = () => setIsCollapsed(!isCollapsed);

  return (
    <>
      <SplitTableRow
        handleCollapse={handleCollapse}
        isCollapsed={isCollapsed}>
        <TableRowItem>{occurrence}</TableRowItem>
        <TableRowItem>{category}</TableRowItem>
        <TableRowItem justify="right">{claim}</TableRowItem>
        <TableRowTag tag={consideration.claimConsiderationTag?.value} tooltipId={tooltipId} />
        <TableRowItem justify="right">{ownersWork}</TableRowItem>
        <TableRowItem justify="right">{genExpenses}</TableRowItem>
        <TableRowTag tag={consideration.generalExpensesTag?.value} tooltipId={tooltipId} />
        <TableRowItem justify="right">{adjConsideration}</TableRowItem>
        <TableRowItem justify="right" emphasize={'Medium' as Emphasize}>{splitSum}</TableRowItem>
      </SplitTableRow>
      <TableRowInner>
        <AnimateHeight duration={250} height={isCollapsed ? 0 : 'auto'}>
          {children}
        </AnimateHeight>
      </TableRowInner>
    </>
  );
};
