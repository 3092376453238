const skippedDomains = [
  'insifyclmstmtmain.blob.core.windows.net'
];

/**
 * Helper func for client.interceptors to run down a list of domains that
 * should not have additional headers (auth bearer token, user role, etc.)
 * attached to it.
 */
export const isSkippedDomain = (url?: string) => {
  const res = skippedDomains.filter(domain => url?.includes(domain));
  if (res.length) return true;
  return false;
};
