import { TableSubrow } from '@/common/components/Table/TanstackTable/TableBody';
import {
  BaseTableCell, SubrowCellExpanded, TableCellNumberBase, TableCellTextBase
} from '@/common/components/Table/TanstackTable/TableCell';
import { BaseCellProps } from '@/common/components/Table/TanstackTable/TableCell/BaseTableCell';
import { TableCellConsideredBase } from '@/common/components/Table/TanstackTable/TableCell/TableCellConsidered';
import { tableCellVariant } from '@/common/components/Table/TanstackTable/types';
import {
  ClaimStatementSettings, InvoiceState, Nullable
} from '@/types';
import { invoiceStatesConsidered } from '@/utils/invoice/invoiceStates';
import { FC } from 'react';
import { SurveyorMessageSwitch } from '../../old/core/InvoiceCells/Components';
import { CHInvoiceTableDataByCategory } from './tableDataByCategory';

interface SurveyorCellsProps extends BaseCellProps {
  tooltipId?: string;
  data: CHInvoiceTableDataByCategory['surveyor'];
  state: InvoiceState;
  exchangeRate: Nullable<number>;
  isConsidered?: boolean;
}
const SubrowSurveyorCells: FC<SurveyorCellsProps> = ({
  id,
  tooltipId,
  data,
  state,
  exchangeRate,
  isConsidered,
  headerGroup,
  cellVariant
}) => {
  const showConsideredView = invoiceStatesConsidered.includes(state);

  if (!isConsidered || !showConsideredView) {
    return (
      <BaseTableCell
        id={`${id}-surveyorMessage`}
        headerGroup={headerGroup}
        cellVariant={cellVariant}
        columnBorder="left"
        span={4}>
        <SurveyorMessageSwitch state={state} />
      </BaseTableCell>
    );
  }

  return (
    <>
      <TableCellConsideredBase
        id={`${id}-generalExpenses`}
        tooltipId={tooltipId}
        value={data.generalExpenses}
        exchangeRate={exchangeRate}
        headerGroup={headerGroup}
        cellVariant={cellVariant}
        align="right"
        columnBorder="left"
        isConsidered
      />
      <TableCellConsideredBase
        id={`${id}-claimConsideration`}
        tooltipId={tooltipId}
        value={data.claimConsideration}
        exchangeRate={exchangeRate}
        headerGroup={headerGroup}
        cellVariant={cellVariant}
        align="right"
        isConsidered
      />
      <TableCellConsideredBase
        id={`${id}-ownersWorkConsideration`}
        tooltipId={tooltipId}
        value={data.ownersWorkConsideration}
        exchangeRate={exchangeRate}
        headerGroup={headerGroup}
        cellVariant={cellVariant}
        align="right"
        isConsidered
      />
      <TableCellConsideredBase
        id={`${id}-adjusterToConsider`}
        tooltipId={tooltipId}
        value={data.adjusterToConsider}
        exchangeRate={exchangeRate}
        headerGroup={headerGroup}
        cellVariant={cellVariant}
        align="right"
        isConsidered
      />
    </>
  );
};

interface Props {
  data: CHInvoiceTableDataByCategory;
  tooltipId?: string;
  settings?: ClaimStatementSettings;
}
const SubrowTemplate: FC<Props> = ({ data, tooltipId, settings }) => (
  <>
    {data.subrows.map((invoice, index) => (
      <TableSubrow
        key={invoice.id}
        isFirstRow={index === 0}
        isLastRow={index === (data.subrows.length - 1)}
        isEven={index % 2 === 0}>
        <BaseTableCell id={`${invoice.id}-firstGap`} />
        <SubrowCellExpanded id={`${invoice.id}-subrowExpand`} />
        <TableCellTextBase
          id={`${invoice.id}-invoiceNo`}
          value={invoice.invoiceNo}
        />
        <TableCellNumberBase
          id={`${invoice.id}-totalAmount`}
          tooltipId={tooltipId}
          value={invoice.totalAmount}
          align="right" />
        <TableCellNumberBase
          id={`${invoice.id}-claimInPolicyCurrency`}
          tooltipId={tooltipId}
          value={invoice.owner.claimInPolicyCurrency}
          headerGroup="owner"
          columnBorder="left"
          align="right" />
        <TableCellNumberBase
          id={`${invoice.id}-ownersWorkInPolicyCurrency`}
          tooltipId={tooltipId}
          value={invoice.owner.ownersWorkInPolicyCurrency}
          headerGroup="owner"
          align="right" />
        <SubrowSurveyorCells
          id={invoice.id}
          tooltipId={tooltipId}
          data={invoice.surveyor}
          state={invoice.state}
          exchangeRate={invoice.exchangeRate}
          headerGroup="surveyor"
          cellVariant={tableCellVariant.secondary}
          isConsidered={invoice.surveyor.isConsidered}
        />
        <TableCellConsideredBase
          id={`${invoice.id}-commonExpenses`}
          tooltipId={tooltipId}
          value={invoice.adjuster.commonExpenses}
          exchangeRate={invoice.exchangeRate}
          headerGroup="adjuster"
          cellVariant={tableCellVariant.tertiary}
          columnBorder="left"
          align="right"
          isConsidered
        />
        <TableCellConsideredBase
          id={`${invoice.id}-generalAverage`}
          tooltipId={tooltipId}
          value={invoice.adjuster.generalAverage}
          exchangeRate={invoice.exchangeRate}
          headerGroup="adjuster"
          cellVariant={tableCellVariant.tertiary}
          align="right"
          isConsidered
        />
        <TableCellConsideredBase
          id={`${invoice.id}-particularAverage`}
          tooltipId={tooltipId}
          value={invoice.adjuster.particularAverage}
          exchangeRate={invoice.exchangeRate}
          headerGroup="adjuster"
          cellVariant={tableCellVariant.tertiary}
          align="right"
          isConsidered
        />
        <TableCellConsideredBase
          id={`${invoice.id}-ownersWorkConsideration`}
          tooltipId={tooltipId}
          value={invoice.adjuster.ownersWorkConsideration}
          exchangeRate={invoice.exchangeRate}
          headerGroup="adjuster"
          cellVariant={tableCellVariant.tertiary}
          align="right"
          isConsidered
        />
      </TableSubrow>
    ))}
  </>
);

export const getSubrowsInvoiceByCategory = (
  data: CHInvoiceTableDataByCategory,
  settings?: ClaimStatementSettings,
  tooltipId?: string,
) => (
  <SubrowTemplate data={data} settings={settings} tooltipId={tooltipId} />
);
