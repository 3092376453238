import { InvoiceState } from '@/types';
import { invoiceStates } from '@/utils/constants';

export const queryParameterFormatter = (include: InvoiceState[]) =>
  include.map(value => `include=${value}`).join('&');

const OwnerEditable = queryParameterFormatter([
  invoiceStates.New,
  invoiceStates.ReturnToUploader,
  invoiceStates.ReturnToUploaderAfterConsideration,
  invoiceStates.ReturnToUploaderAfterSharedReview,
  invoiceStates.ReturnToUploaderAfterConsiderationNoAllocation
]);

const OwnerViewable = queryParameterFormatter([
  invoiceStates.Review,
  invoiceStates.CostAllocation,
  invoiceStates.CostReallocation,
  invoiceStates.SharedReview,
  invoiceStates.AdjustersConsideration,
  invoiceStates.AdjustersConsiderationNoAllocation,
  invoiceStates.Finished,
  invoiceStates.FinishedNoAllocation,
  invoiceStates.FinishedSharedReview
]);

const ClaimsHandlerViewable = queryParameterFormatter([
  invoiceStates.Review,
  invoiceStates.ReturnToUploader,
  invoiceStates.ReturnToUploaderAfterConsideration,
  invoiceStates.ReturnToUploaderAfterSharedReview,
  invoiceStates.ReturnToUploaderAfterConsiderationNoAllocation,
  invoiceStates.CostAllocation,
  invoiceStates.CostReallocation,
  invoiceStates.SharedReview,
  invoiceStates.AdjustersConsideration,
  invoiceStates.AdjustersConsiderationNoAllocation,
  invoiceStates.Finished,
  invoiceStates.FinishedNoAllocation,
  invoiceStates.FinishedSharedReview
]);

const SurveyorEditable = queryParameterFormatter([
  invoiceStates.CostAllocation,
  invoiceStates.CostReallocation
]);

const SurveyorViewable = queryParameterFormatter([
  invoiceStates.SharedReview,
  invoiceStates.ReturnToUploaderAfterSharedReview,
  invoiceStates.FinishedSharedReview
]);

const SurveyorSubmitted = queryParameterFormatter([
  invoiceStates.AdjustersConsideration,
  invoiceStates.ReturnToUploaderAfterConsideration,
  invoiceStates.Finished
]);

export const queries = {
  OwnerEditable,
  OwnerViewable,
  ClaimsHandlerViewable,
  SurveyorEditable,
  SurveyorViewable,
  SurveyorSubmitted
};
