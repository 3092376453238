import { CollapsibleSubrow } from '@/common/components/Table/CollapsibleTable/types';
import { SurveyorsConsiderationSplit } from '@/types';

export const tableSchemaSubrow = (
  split: SurveyorsConsiderationSplit,
  selectableRows: boolean
): CollapsibleSubrow => ({
  id: split.id,
  onClick: () => { },
  segments: [
    {
      key: 'before-expanded-button-space',
      cellType: 'gap',
      span: '1',
      beforeSubrows: true,
      shouldSkip: selectableRows
    },
    {
      key: 'after-expanded-button-space',
      cellType: 'gap',
      span: '2'
    },
    {
      key: 'category',
      cellType: 'text',
      value: split.category
    },
    {
      key: 'before-consideration-gap',
      cellType: 'gap',
      span: '3'
    },
    {
      key: 'generalExpenses',
      cellType: 'digitsTooltip',
      value: split.generalExpenses,
      align: 'right'
    },
    {
      key: 'claimConsideration',
      cellType: 'digitsTooltip',
      value: split.claimConsideration,
      align: 'right'
    },
    {
      key: 'ownersWorkConsideration',
      cellType: 'digitsTooltip',
      value: split.ownersWorkConsideration,
      align: 'right'
    },
    {
      key: 'adjusterToConsider',
      cellType: 'digitsTooltip',
      value: split.adjustersConsideration,
      align: 'right'
    },
    {
      key: 'surveyorsComment',
      cellType: 'comment',
      value: split.surveyorsComment
    },
    {
      key: 'context-menu-space',
      cellType: 'gap',
      span: '1'
    }
  ]
});
