import React, { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import { TruncatingText } from '@instech/components';
import { InfoWithLabelProps } from './types';
import { SmallLabel } from './Components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const MultilineValue = styled.span`
  font-size: 16px;
  line-height: 14px;
  white-space: pre-wrap;
`;

const TruncatingValue = styled(TruncatingText)`
  font-size: 16px;
  line-height: 14px;
`;

/**
 * Renders a piece of text with a label above it. Use together with
 * `<InfoLayout />` to auto-handle the layouting out of these boxes.
 */
export const InfoWithLabel: FC<InfoWithLabelProps> = ({ label, text, multiline, className }) => {
  const textString = text?.toString() || '-';
  return (
    <Container className={className}>
      {label && <SmallLabel nowrap>{label}</SmallLabel>}
      {multiline
        ? <MultilineValue>{textString}</MultilineValue>
        : <TruncatingValue text={textString} wrap />}
    </Container>
  );
};
