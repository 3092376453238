import { Instructions, InvoiceMessages } from '@/components/pages/invoice/InvoicePage/core/Components';
import {
  ClaimStatement, Invoice, LatestInstructions
} from '@/types';
import { FunctionComponent as FC } from 'react';
import { EditInvoiceAdjuster } from '../../../core/EditInvoice/EditInvoiceAdjuster';
import { AdditionalInformationPane, OwnersClaimPane } from '../../../core/ReadOnlyInvoice';
import { AdjustersConsiderationPane } from '../../../core/ReadOnlyInvoice/AdjustersConsiderationPane';
import { NavigationalActionButtons } from '../NavigationalActionButtons';
import { useInvoiceConflict } from '../invoiceConflict';

interface Props {
  invoice: Invoice;
  claimStatement: ClaimStatement;
  ownsLock: boolean;
  isClaimStatementClosed: boolean;
  surveyorInstructions?: LatestInstructions;
  ownerInstructions?: LatestInstructions;
}

export const NotAllocatedInvoiceDetails: FC<Props> = ({
  invoice,
  claimStatement,
  ownsLock,
  isClaimStatementClosed,
  surveyorInstructions,
  ownerInstructions
}) => {
  const { conflict, handleInvoiceConflict } = useInvoiceConflict();

  const readonly = isClaimStatementClosed || !ownsLock;
  const hasAdjustersConsiderationSplit = invoice?.adjustersConsideration?.splits?.length > 1;
  return (
    <div>
      {readonly && (
        <NavigationalActionButtons
          anchorId="claims-handler-action-buttons"
          invoice={invoice} />
      )}
      <InvoiceMessages
        conflict={conflict}
        ownsLock={ownsLock}
        isClaimStatementClosed={isClaimStatementClosed} />
      <Instructions instructions={ownerInstructions?.instructions} ownersInstructions />
      <Instructions instructions={surveyorInstructions?.instructions} />
      <OwnersClaimPane
        claimStatement={claimStatement}
        invoice={invoice}
        policyCurrency={claimStatement.currency}
        useEditButton={ownsLock && !isClaimStatementClosed}
        readonly={isClaimStatementClosed} />
      {readonly && (
        <AdditionalInformationPane
          invoice={invoice}
          showRepairPeriod
          hasSplit={hasAdjustersConsiderationSplit} />
      )}
      {!readonly && (
        <EditInvoiceAdjuster
          claimStatement={claimStatement}
          invoice={invoice}
          handleInvoiceConflict={handleInvoiceConflict}
          ownsLock={ownsLock} />
      )}
      {(readonly && invoice?.adjustersConsideration?.isConsidered) && (
        <AdjustersConsiderationPane
          invoice={invoice} />
      )}
    </div>
  );
};
