import {
  InfoLayout, InfoWithLabel, InfoWithLabelRequired
} from '@/common/components/Text';
import { InvoiceRecord } from '@/types';
import { FunctionComponent as FC } from 'react';
import { formatNumberWithTwoDecimalsOrNull } from '../utils';

interface Props {
  record: InvoiceRecord;
}
export const ClaimInfoEdit: FC<Props> = ({ record }) => {
  // cast invoice currency to empty string if it is nullish, for the labelling
  const currencyOrEmpty = record.currency || '';
  return (
    <InfoLayout columnMin="130px">
      <InfoWithLabelRequired
        label="Invoice Total Amount"
        text={formatNumberWithTwoDecimalsOrNull(record.totalAmount)}
      />
      <InfoWithLabelRequired
        label="Currency"
        text={currencyOrEmpty}
      />
      <InfoWithLabel
        label="Invoice Number"
        text={record.invoiceNumber}
      />
      <InfoWithLabel
        label="Client Reference"
        text={record.clientReference}
      />
    </InfoLayout>
  );
};
