import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { RadioButtons, SlimButton } from '@instech/components';
import {
  Guid, InvoiceState, InvoiceStateMetadata
} from '@/types';
import { ModalType } from '@/common/modules/Modal/types';
import { useModalContext } from '@/common/modules/Modal';
import { ButtonGroupModal } from '@/common/components/ButtonGroup/ButtonGroupModal';
import { invoiceStates } from '@/utils/constants';
import { useLatestSurveyorsInstructions } from '@/services/invoice/invoiceServices';
import { SurveyorInstructions } from './core/SurveyorInstructions';

const getShareOptions = (invoices: InvoiceStateMetadata[]) => {
  const optionVal = invoices.length > 1 ? 'invoices' : 'invoice';
  const labelVal = invoices.length > 1 ? 'these invoices' : 'this invoice';

  return ({
    shareLabel: `Should the Surveyor consider ${labelVal}?`,
    shareOptions: [
      `Yes, share ${optionVal} for Surveyor's consideration`,
      `No, share ${optionVal} with view access only`
    ]
  });
};

const statesSharableWithSurveyor: InvoiceState[] = [invoiceStates.Review, invoiceStates.AdjustersConsiderationNoAllocation];

// Override colors and alignment for radio buttons
const StyledRadioButtons = styled(RadioButtons)`
  color: ${props => props.theme.marineBlue};
  margin-bottom: 32px;
  & label {
    padding-left: 0px;
  }
`;

const StyledText = styled.div`
  color: ${props => props.theme.marineBlue};
   margin-bottom: 32px;
`;

interface SharedTextProps {
  numberOfInvoices: number;
}

const SharedText = ({ numberOfInvoices }: SharedTextProps) => {
  const optionVal = numberOfInvoices > 1 ? 'these invoices' : 'this invoice';
  const text = `The Surveyor already has view only access to ${optionVal}.
  Clicking 'Share' will give the Surveyor editing access to do their cost allocation.`;
  return (
    <StyledText>
      {text}
    </StyledText>
  );
};

interface TransferClaimsHandlerToSurveyorProps {
  claimStatementId: Guid;
  firstInvoiceId: Guid;
  invoices: InvoiceStateMetadata[];
  transfer: (destinationState: InvoiceState, instructions: string) => Promise<string>;
}

const ModalContent = ({ claimStatementId, firstInvoiceId, invoices, transfer }: TransferClaimsHandlerToSurveyorProps) => {
  const { close } = useModalContext();
  const { data } = useLatestSurveyorsInstructions(claimStatementId, firstInvoiceId);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { shareLabel, shareOptions } = getShareOptions(invoices);
  const isSharedReview = invoices[0].state === invoiceStates.SharedReview;
  const sharableWithOptionsForSurveyor = statesSharableWithSurveyor.includes(invoices[0].state);

  const handleTransfer = async (values: any) => {
    setSubmitting(true);
    const destinationState = values.stateOptions === shareOptions[0] ? invoiceStates.CostAllocation : invoiceStates.SharedReview;
    const trimInstructions = values.instructions.trim();
    await transfer(destinationState, trimInstructions);
    close();
  };

  return (
    <Formik
      initialValues={{ stateOptions: shareOptions[0], instructions: data?.instructions ?? '' }}
      onSubmit={handleTransfer}
    >
      <Form>
        {sharableWithOptionsForSurveyor && (
          <StyledRadioButtons
            name="stateOptions"
            label={shareLabel}
            options={shareOptions}
            noErrors />
        )}
        {isSharedReview && <SharedText numberOfInvoices={invoices.length} />}
        <SurveyorInstructions
          numberOfInvoices={invoices.length}
          options={shareOptions}
          hasBeenShared={isSharedReview} />
        <ButtonGroupModal marginTop="24px">
          <SlimButton onClick={close} variant="secondary">
            CANCEL
          </SlimButton>
          <SlimButton type="submit" width="60px" disabled={submitting} loading={submitting}>
            SHARE
          </SlimButton>
        </ButtonGroupModal>
      </Form>
    </Formik>
  );
};

export const transferClaimsHandlerToSurveyorModal = (
  {
    claimStatementId,
    firstInvoiceId,
    invoices,
    transfer
  }: TransferClaimsHandlerToSurveyorProps
): ModalType<TransferClaimsHandlerToSurveyorProps> => ({
  component: ModalContent,
  options: {
    title: invoices.length > 1
      ? `Share selected invoices (${invoices.length}) with Surveyor`
      : 'Share with Surveyor',
    size: 'small',
    padding: '24px'
  },
  args: {
    claimStatementId,
    firstInvoiceId,
    invoices,
    transfer
  }
});
