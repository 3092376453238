import { useTypedModal } from '@/common/modules/Modal';
import { UserInfo, UserInfoDto } from '@/types';
import { FunctionComponent as FC } from 'react';
import {
  ButtonGroup,
  SlimButton,
  SlimButtonProps
} from '@instech/components';
import { Plus } from '@instech/icons';
import { FetchUsersOptions, useCurrentUser } from '@/services/userInfoServices';
import { selectUsersModal } from '@/components/pages/claim/core/SelectUsersModal/SelectUsersModal';

const AddButton = (props: SlimButtonProps) => (
  <SlimButton variant="secondary" startIcon={<Plus />} {...props}>ADD</SlimButton>
);

interface Props {
  selected?: UserInfo[] | UserInfoDto[];
  onSelect: (user: UserInfo[]) => void;
  fetchUsersOptions: FetchUsersOptions;
  allowEmptySelection?: boolean;
}
export const SelectUsers: FC<Props> = ({ selected, onSelect, fetchUsersOptions, allowEmptySelection = true }) => {
  const currentUser = useCurrentUser();
  const { open: openModal } = useTypedModal(selectUsersModal({
    fetchUsersOptions,
    onSelect: user => onSelect(user),
    selectedUsers: selected,
    allowEmptySelection,
    showUpdateNotificationWarning: true,
    isDisabled: user => user.companyId !== currentUser?.companyId
  }));

  return (
    <ButtonGroup alignRight>
      <AddButton onClick={openModal} />
    </ButtonGroup>
  );
};
