import {
  CollapsibleButtonSegment, CollapsibleHeaderSegment, CollapsibleSubheaderSegment
} from '@/common/components/Table/CollapsibleTable/types';
import { collapseGroups } from '@/common/components/Table/utils';
import { theme } from '@/utils/style/theme';
import { CSTableProps } from '../useSchemaByTableGrouping';

const borderColor = {
  status: theme.marineBlue60,
  particulars: theme.status.blue,
  surveyor: theme.status.purple,
  adjuster: theme.marineBlue60
};

export const tableSchemaForPrintCollapse = (props: CSTableProps): CollapsibleButtonSegment[] => {
  const hasGenAvg = props.claimStatementSettings?.hasGeneralAverage;
  return ([
    {
      key: 'invoice-details-gap',
      span: 2
    },
    {
      key: 'particulars',
      title: 'Particulars',
      span: 5
    },
    {
      key: 'owner-gap',
      title: 'Owner',
      span: 1
    },
    {
      key: 'surveyor',
      title: 'Surveyor',
      span: 1
    },
    {
      key: 'adjuster',
      title: 'Adjuster',
      span: 4 + (hasGenAvg ? 1 : 0)
    }
  ]);
};

export const tableSchemaForPrintSubheader = (props: CSTableProps): CollapsibleSubheaderSegment[] => {
  const hasGenAvg = props.claimStatementSettings?.hasGeneralAverage;
  return ([
    {
      key: 'invoice-details-gap',
      span: 2
    },
    {
      key: 'particulars',
      group: collapseGroups.particulars,
      border: true,
      span: 5
    },
    {
      key: 'owner',
      group: collapseGroups.owner,
      border: true,
      span: 1
    },
    {
      key: 'surveyor-gap',
      group: collapseGroups.surveyor,
      border: true,
      span: 1
    },
    {
      key: 'adjusters-allocation',
      title: `Adjuster's Allocation of Costs - ${props.currency}`,
      group: collapseGroups.adjuster,
      border: true,
      span: 3 + (hasGenAvg ? 1 : 0)
    },
    {
      key: 'adjuster-gap',
      group: collapseGroups.adjuster,
      span: 1
    },
  ]);
};

export const tableSchemaForPrintHeader = (props: CSTableProps): CollapsibleHeaderSegment[] => ([
  {
    title: 'Invoice No',
    propName: 'invoiceNumber',
    borderColor: borderColor.status,
  },
  {
    title: 'Supplier',
    propName: 'supplier',
    borderColor: borderColor.particulars,
    group: collapseGroups.particulars,
    border: true,
  },
  {
    title: 'Category',
    propName: 'category',
    borderColor: borderColor.particulars,
    group: collapseGroups.particulars,
  },
  {
    title: 'Currency',
    propName: 'currency',
    borderColor: borderColor.particulars,
    group: collapseGroups.particulars,
  },
  {
    title: 'Total - Invoice CCY',
    propName: 'totalAmount',
    borderColor: borderColor.particulars,
    group: collapseGroups.particulars,
  },
  {
    title: `Total - ${props.currency}`,
    propName: 'paidAmount',
    borderColor: borderColor.particulars,
    group: collapseGroups.particulars,
    align: 'flex-end'
  },
  {
    title: 'Cost Description',
    propName: 'ownersComment',
    borderColor: borderColor.particulars,
    group: collapseGroups.owner,
    border: true
  },
  {
    title: 'Description of Consideration',
    propName: 'surveyorsComment',
    borderColor: borderColor.surveyor,
    group: collapseGroups.surveyor,
    border: true
  },
  {
    title: 'Common Expenses',
    propName: 'adjusterCommonExpenses',
    borderColor: borderColor.adjuster,
    group: collapseGroups.adjuster,
    border: true,
    align: 'flex-end',
  },
  {
    title: 'General Average',
    propName: 'adjusterGeneralAverage',
    borderColor: borderColor.adjuster,
    group: collapseGroups.adjuster,
    align: 'flex-end',
    shouldSkip: !props.claimStatementSettings?.hasGeneralAverage
  },
  {
    title: 'Particular Average',
    propName: 'adjusterParticularAverage',
    borderColor: borderColor.adjuster,
    group: collapseGroups.adjuster,
    align: 'flex-end',
  },
  {
    title: 'Remainder',
    propName: 'adjusterOwnersWorkConsideration',
    borderColor: borderColor.adjuster,
    group: collapseGroups.adjuster,
    align: 'flex-end',
  },
  {
    title: 'Adjuster\'s Note',
    propName: 'adjusterAdjustersComment',
    borderColor: borderColor.adjuster,
    group: collapseGroups.adjuster,
  },
]);
