import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const segmentDotBase = css`
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
`;

const SegmentLine = styled.div<{ isActive: boolean }>`
  position: relative;
  height: 48px;
  width: 1px;
  background-color: ${({ theme }) => theme.marineBlue};
  &:before {
    ${segmentDotBase};
    width: 16px;
    height: 16px;
    border: 1px solid ${({ theme }) => theme.marineBlue};
    background-color: ${({ theme }) => theme.marineBlue10};
    transition: opacity 0.2s ease-in;
    opacity: 0;
    // Snap to opacity 1 if selected
    ${props => props.isActive && css`
      opacity: 1 !important;
    `}
  }
  &:after {
    ${segmentDotBase};
    width: 13px;
    height: 13px;
    background-color: ${({ theme }) => theme.marineBlue60};
    transition: opacity 0.2s ease-in;
    opacity: 0;
    // Snap to opacity 1 if selected
    ${props => props.isActive && css`
      opacity: 1 !important;
    `}
  }
`;

const Segment = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  background-color: ${({ theme }) => theme.marineBlue10};
  padding: 0px 16px;
  cursor: pointer;
  // When hovering segment, show faded selection dot
  &:hover ${SegmentLine}:after {
    opacity: 0.5;
  }
`;

const SegmentText = styled.div`
  padding-left: 16px;
  color: ${({ theme }) => theme.marineBlue};
`;

interface Props {
  name: string;
  path: string;
  isActive?: boolean;
}
export const ChapterSegment: FC<Props> = ({ name, path, isActive = false }) => (
  <Link to={`./${path}`}>
    <Segment data-testid={isActive ? 'active-adjustment-chapter' : ''}>
      <SegmentLine isActive={isActive} />
      <SegmentText>{name}</SegmentText>
    </Segment>
  </Link>
);
