import { roles } from '@/utils/constants';
import { WarningNotification } from '@instech/components';
import { FunctionComponent as FC } from 'react';

const getDescriptionText = (userRole?: string) => {
  if (userRole === roles.Surveyor) return 'Consider if you should return the invoice to Claims Handler with a comment.';
  if (userRole === roles.ClaimsHandler) return 'Consider if you should return the invoice to Owner with a comment.';
  return undefined;
};

interface Props {
  userRole?: string;
}
export const EditWarning: FC<Props> = ({ userRole }) => (
  <WarningNotification
    size="large"
    headingText="You are editing Owner's Claim. We recommend proceeding with caution."
    descriptionText={getDescriptionText(userRole)}
  />
);
