import { InvoiceOverviewSurveyor } from '@/types';
import { useEffect } from 'react';

export const useUnmovedInvoices = (data: InvoiceOverviewSurveyor, selectedRows: string[], setSelectedRows: (rows: string[]) => void) => useEffect(() => {
  const invoiceIdList = data.invoices.map(invoice => invoice.id);
  const unmovedInvoices = selectedRows.filter(rowId => invoiceIdList.includes(rowId));
  if (unmovedInvoices.length !== selectedRows.length) {
    setSelectedRows(unmovedInvoices);
  }
}, [data, selectedRows, setSelectedRows]);
