import { FC, useState } from 'react';
import { ClaimSearchData, UserInfo } from '@/types';
import { roles } from '@/utils/constants';
import styled from 'styled-components';
import { UserNotificationsInfobox } from '@/components/pages/claim/core/SelectUsersModal/UserNotificationsInfobox';
import { SelectUserPane } from './SelectUserPane';
import { ClaimAddedUsers } from './claimAddedUsers';

const NotificationWrapper = styled.div`
  padding: 33px 0 20px 0;
`;
interface Props {
  claimSearchData: ClaimSearchData;
  users: ClaimAddedUsers;
  onUsersChange: (users: Partial<ClaimAddedUsers>) => Promise<void>;
  notificationLocation: 'outside' | 'modal';
}
export const SelectUsers: FC<Props> = ({ users, claimSearchData, onUsersChange, notificationLocation }) => {
  const [showUserNotificationsInfoBox, setShowUserNotificationsInfoBox] = useState(false);

  const handleSelectUsers = async (newUsers: Partial<ClaimAddedUsers>) => {
    setShowUserNotificationsInfoBox(true);
    await onUsersChange(newUsers);
  };

  const handleSelectOwner = (newUsers: UserInfo[]) => handleSelectUsers({ owners: newUsers });
  const handleSelectBroker = (newUsers: UserInfo[]) => handleSelectUsers({ brokers: newUsers });
  const handleSelectSurveyors = (newUsers: UserInfo[]) => handleSelectUsers({ surveyors: newUsers });
  const handleSelectClaimsHandler = (newUsers: UserInfo[]) => handleSelectUsers({ claimsHandlers: newUsers });

  const showModalWarning = notificationLocation === 'modal';
  return (
    <>
      {notificationLocation === 'outside' && showUserNotificationsInfoBox && (
        <NotificationWrapper>
          <UserNotificationsInfobox />
        </NotificationWrapper>
      )}
      <SelectUserPane
        selected={users.owners}
        onSelect={handleSelectOwner}
        claimSearchData={claimSearchData}
        allowEmptySelection
        showUpdateNotificationWarning={showModalWarning}
        role={roles.Owner}
        title={roles.Owner} />
      <SelectUserPane
        selected={users.brokers}
        onSelect={handleSelectBroker}
        claimSearchData={claimSearchData}
        allowEmptySelection
        role={roles.Broker}
        title={roles.Broker}
        showUpdateNotificationWarning={showModalWarning} />
      <SelectUserPane
        selected={users.surveyors}
        onSelect={handleSelectSurveyors}
        claimSearchData={claimSearchData}
        allowEmptySelection
        showUpdateNotificationWarning={showModalWarning}
        role={roles.Surveyor}
        title={roles.Surveyor} />
      <SelectUserPane
        selected={users.claimsHandlers}
        onSelect={handleSelectClaimsHandler}
        claimSearchData={claimSearchData}
        allowEmptySelection={false}
        title="Claims Handler / Adjuster"
        role={roles.ClaimsHandler}
        showUpdateNotificationWarning={showModalWarning}
      />
    </>
  );
};
