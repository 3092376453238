import { HelpTextLayout } from '@/apps/Adjustment/components/HelpText';
import { CollapsiblePane } from '@instech/components';
import { FC } from 'react';

interface Props {
}
export const HelpTextPane: FC<Props> = () => (
  <CollapsiblePane color="green" title="Help Text" padding="20px">
    <HelpTextLayout>
      <p>Consider reference to previous POA.</p>
      <p>Consider elaborating on cause of damage / incidence of loss in relation to policy conditions.</p>
      <p>Consider further explanation of why it is appropriate for items in the claim to have been treated as they have.</p>
      <p>Consider commenting common repair expenses.</p>
      <p>Consider commenting on repairs undertaken at different locations/times.</p>
      <p>Consider commenting on temporary repairs, if applicable.</p>
      <p>Comment on General Average, if applicable.</p>
      <p>Describe and explain the settlement in collision cases (division of blame/approved claims/final payment).</p>
    </HelpTextLayout>
  </CollapsiblePane>
);
