import {
  createContext, useContext, useEffect, useState
} from 'react';
import { FCWC } from '@/types';
import { PreloadFonts } from './core/PreloadFonts';

const createLoadErrorMessage = (failedFont: string) =>
  new Error(`FontPreloader failed to find and load font: ${failedFont}`);

const FontPreloadContext = createContext(false);

const fontLoadList = [
  '16px CalibriWeb Light',
  '16px CalibriWeb'
];

/**
 * Allows for the application to pend in a given set of fonts have been loaded
 * into memory and are ready to be used by the browser(using document.fonts.load).
 * Approaching it like this allows us to bundle font-preloading together with
 * awaiting user info, feature flags, and similar such content in a single set.
 *
 * Fonts are provided to font-load in constant set `fontLoadList`, and added as
 * Elements in component `PreloadFonts`. This will force first-time use of listed
 * fonts upon load as this Provider is mounted into React tree.
 */
export const FontPreloadProvider: FCWC = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    void Promise.all(fontLoadList.map(font => document.fonts.load(font)))
      .then(async fonts => {
        // should return false if filter returns any empty arrays (e.g. failed font load)
        const failedLoad = fonts.findIndex(font => !font.length);
        if (failedLoad >= 0) throw createLoadErrorMessage(fontLoadList[failedLoad]);
      })
      // eslint-disable-next-line no-console
      .catch(e => console.error(e));
    void document.fonts.ready.then(() => {
      setIsLoaded(true);
    });
  }, []);

  return (
    <FontPreloadContext.Provider value={isLoaded}>
      <PreloadFonts />
      {children}
    </FontPreloadContext.Provider>
  );
};

/**
 * Get boolean status for whether fonts have been preloaded, to pend application
 * at high level until necessary fonts are ready to be used.
 */
export const useFontPreload = () => useContext(FontPreloadContext);
