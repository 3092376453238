import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import {
  ButtonGroup, Loader, SlimButton, TextArea
} from '@instech/components';
import { ModalType } from '@/common/modules/Modal/types';
import { useModalContext } from '@/common/modules/Modal';
import { Guid } from '@/types';
import { useLatestOwnersInstructions } from '@/services/invoice/invoiceServices';

interface ReturnClaimsHandlerToOwnerProps {
  claimStatementId: Guid;
  firstInvoiceId: Guid;
  numberOfInvoices: number;
  onReturn: (message: string) => Promise<string>;
}
const ModalContent = ({ claimStatementId, firstInvoiceId, numberOfInvoices, onReturn }: ReturnClaimsHandlerToOwnerProps) => {
  const { close } = useModalContext();
  const { data } = useLatestOwnersInstructions(claimStatementId, firstInvoiceId);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const labelText = `Feedback to Owner${numberOfInvoices > 1 ? ` (will be applied to all ${numberOfInvoices} invoices)` : ''}`;

  const handleReturn = async (values: any) => {
    setSubmitting(true);
    const trimInstructions = values.instructions.trim();
    const response = await onReturn(trimInstructions);
    if (response === 'success') {
      close();
    } else {
      setSubmitting(false);
    }
  };

  if (firstInvoiceId && !data) {
    return <Loader />;
  }

  return (
    <Formik
      initialValues={{ instructions: data?.instructions ?? '' }}
      onSubmit={handleReturn}
    >
      {({ handleSubmit }) => (
        <Form>
          <TextArea
            name="instructions"
            label={labelText}
            placeholder="Text"
            noErrors
          />
          <ButtonGroup alignRight marginTop="24px">
            <SlimButton variant="secondary" onClick={close}>
              CANCEL
            </SlimButton>
            <SlimButton onClick={handleSubmit} width="49px" disabled={submitting} loading={submitting}>
              SEND
            </SlimButton>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  );
};

export const defaultReturnToOwnerProps: ReturnClaimsHandlerToOwnerProps = {
  claimStatementId: '',
  firstInvoiceId: '',
  numberOfInvoices: 0,
  onReturn: () => Promise.resolve('')
};

export const returnClaimsHandlerToOwnerModal = (
  { claimStatementId, firstInvoiceId, numberOfInvoices, onReturn }: ReturnClaimsHandlerToOwnerProps
): ModalType<ReturnClaimsHandlerToOwnerProps> => ({
  component: ModalContent,
  options: {
    title: `Return${numberOfInvoices > 1 ? ` ${numberOfInvoices} selected invoices` : ''} to Owner`,
    size: 'small',
    padding: '12px 24px 24px'
  },
  args: {
    claimStatementId,
    firstInvoiceId,
    numberOfInvoices,
    onReturn
  }
});
