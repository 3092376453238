import { InvoiceRequestStatus } from '@/services/invoice/invoiceServices';
import { Invoice } from '@/types';
import { invokeForSingleClick } from '@/utils/clickHandler';
import { ButtonGroup } from '@instech/components';
import { useNavigate } from 'react-router-dom';
import { MouseEvent } from 'react';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import {
  GrayNextButton, GrayPreviousButton, GraySkipAllButton, GreenDoneButton
} from './buttons/BatchPageActionButtons';

interface InvoiceActionButtonsOwnerProps {
  invoice: Invoice;
  saveInvoice: () => Promise<Invoice | InvoiceRequestStatus>;
  invoiceBatchList: string[];
  invoiceBatchNumber: number;
  batchId: string;
}
export const InvoiceActionButtonsBatchPage = ({ saveInvoice, invoice, invoiceBatchList, invoiceBatchNumber, batchId }: InvoiceActionButtonsOwnerProps) => {
  const navigate = useNavigate();
  const navigateToClaimStatementPage = () => navigate(`/claimstatements/${invoice.claimStatementId}`);
  const navigateToNumber = (number: number) => navigate(`/claimstatements/${invoice.claimStatementId}/batch/${batchId}/invoices/${number}`);

  const handlePrevious = async (event: MouseEvent) => {
    await saveInvoice();
    invokeForSingleClick(event, () => navigateToNumber(invoiceBatchNumber - 1));
  };
  const handleNext = async (event: MouseEvent) => {
    await saveInvoice();
    invokeForSingleClick(event, () => navigateToNumber(invoiceBatchNumber + 1));
  };
  const handleDone = async (event: any) => {
    await saveInvoice();
    invokeForSingleClick(event, () => navigateToClaimStatementPage());
  };

  const onLastInvoice = invoiceBatchNumber === invoiceBatchList.length;

  return (
    <SharedTooltip
      id="invoice-batch-tooltip"
      bodyMode={false}
      effect="solid"
      place="bottom">
      <ButtonGroup>
        <GraySkipAllButton onClick={navigateToClaimStatementPage} />
        <GrayPreviousButton invoiceBatchList={invoiceBatchList} invoiceBatchNumber={invoiceBatchNumber} onClick={handlePrevious} />
        {!onLastInvoice && <GrayNextButton invoiceBatchList={invoiceBatchList} invoiceBatchNumber={invoiceBatchNumber} onClick={handleNext} />}
        {onLastInvoice && <GreenDoneButton onClick={handleDone} />}
      </ButtonGroup>
    </SharedTooltip>
  );
};
