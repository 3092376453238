import { CollapsiblePane, RichTextEditor } from '@instech/components';
import { FC } from 'react';
import styled from 'styled-components';

const EditorWrapper = styled.div`
  .editor-inner {
    border: 1px solid ${({ theme }) => theme.marineBlue60};
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 5px 0px;
  }
`;

interface Props { }
export const InsuranceConditionsPane: FC<Props> = () => (
  <CollapsiblePane color="green" title="Insurance Conditions" padding="20px" margin="20px 0px 0px">
    <EditorWrapper>
      <RichTextEditor name="insuranceConditions" />
    </EditorWrapper>
  </CollapsiblePane>
);
