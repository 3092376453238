import moment from 'moment/moment';

const formatOrNull = (date: string | null | undefined, format: string): string | null => {
  if (date === undefined) {
    return null;
  }

  // cast to Moment to handle both null-ish and non-date string values
  const dateObj = moment(date);
  return dateObj.isValid() ? dateObj.format(format) : null;
};

export const formatDateOrNull = (date: string | null | undefined) =>
  formatOrNull(date, 'DD.MM.YYYY');

export const formatDateWithTimeOrNull = (date: string | null | undefined) =>
  formatOrNull(date, 'DD.MM.YYYY HH:mm:ss');
