import {
  SplitTableRow, TableRowInner, TableRowItem
} from '@/apps/ClaimStatement/components/InvoiceSplitTable';
import { Emphasize, TableRowTag } from '@/apps/ClaimStatement/components/InvoiceSplitTable/SplitTableComponents';
import {
  AdjustersConsiderationSplitForm, FCWC, InvoiceRecord
} from '@/types';
import { calculateAdjusterSummaryValues } from '@/utils/invoice/calculateSummaries';
import { useField } from 'formik';
import { useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import styled from 'styled-components';
import { TruncatingText } from '@instech/components';
import { useSharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { useInvoiceConsideredBySurveyor, useSurveyorSplitById } from '../../InvoiceForm/utils/SurveyorSplitsContext';

const DeletedTableRowItem = styled(TableRowItem)<{hasGeneralAverage?: boolean}>`
  grid-column-start: 1;
  grid-column-end: ${props => (props.hasGeneralAverage ? 11 : 10)};
  justify-content: center;
  color: ${props => props.theme.marineBlue80};
  background: ${props => props.theme.adjuster.deleted};
  size: 14px;
  padding-left: 15%;
`;

interface Props {
  arrayName: string,
  index: number,
  invoiceRecord: InvoiceRecord;
  adjusterSplit: AdjustersConsiderationSplitForm;
  useInvoiceCurrency?: boolean;
  startCollapsed: boolean;
  surveyorSplitId?: string;
  viewAsListOfSplits?: boolean;
  showGeneralAverage: boolean;
}
export const SplitTableRowAdjuster: FCWC<Props> = ({
  arrayName,
  index,
  children,
  invoiceRecord,
  adjusterSplit,
  useInvoiceCurrency,
  startCollapsed,
  surveyorSplitId,
  viewAsListOfSplits,
  showGeneralAverage
}) => {
  // existing splits and first split initialize collapsed, others initialize open
  const [isCollapsed, setIsCollapsed] = useState(startCollapsed);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [deletedField] = useField(`${arrayName}.${index}.isDeleted`);
  const surveyorSplit = useSurveyorSplitById(surveyorSplitId);
  const isConsidered = useInvoiceConsideredBySurveyor();
  const { tooltipId } = useSharedTooltip();
  const { exchangeRate } = invoiceRecord;
  const isDeletedByAdjuster = deletedField.value;

  const { category, occurrence, commonExpenses, generalAverage, particularAvg, ownersWork, splitSum, removedSplitSum, surveyorDeviation } = useMemo(() => {
    const result = calculateAdjusterSummaryValues(adjusterSplit, surveyorSplit, exchangeRate, useInvoiceCurrency, isConsidered, isInitialized);
    setIsInitialized(true);
    return result;
  }, [adjusterSplit, surveyorSplit, exchangeRate, isConsidered, useInvoiceCurrency, isInitialized]);

  if (!viewAsListOfSplits) {
    return <div>{children}</div>;
  }

  const handleCollapse = () => setIsCollapsed(!isCollapsed);
  return (
    <>
      <SplitTableRow
        handleCollapse={handleCollapse}
        isCollapsed={isCollapsed}
        isDeleted={isDeletedByAdjuster}>
        {isDeletedByAdjuster ? (
          <>
            <DeletedTableRowItem hasGeneralAverage={showGeneralAverage}>Deleted by Adjuster</DeletedTableRowItem>
            <TableRowItem emphasize="Deleted" justify="flex-end">{removedSplitSum}</TableRowItem>
          </>
        ) : (
          <>
            <TableRowItem hideOverflow><TruncatingText text={occurrence} /></TableRowItem>
            <TableRowItem hideOverflow><TruncatingText text={category} /></TableRowItem>
            <TableRowItem justify="right">{commonExpenses}</TableRowItem>
            <TableRowTag tag={adjusterSplit.commonExpensesTag?.value} tooltipId={tooltipId} />
            {showGeneralAverage && <TableRowItem justify="right">{generalAverage}</TableRowItem>}
            <TableRowItem justify="right">{particularAvg}</TableRowItem>
            <TableRowTag tag={adjusterSplit.particularAverageTag?.value} tooltipId={tooltipId} />
            <TableRowItem justify="right">{ownersWork}</TableRowItem>
            <TableRowTag tag={adjusterSplit.ownersWorkConsiderationTag?.value} tooltipId={tooltipId} />
            <TableRowItem justify="right" emphasize={'Medium' as Emphasize}>{splitSum}</TableRowItem>
            <TableRowItem justify="flex-end">{surveyorDeviation}</TableRowItem>
          </>
        )}
      </SplitTableRow>
      <TableRowInner>
        <AnimateHeight duration={250} height={isCollapsed ? 0 : 'auto'}>
          {children}
        </AnimateHeight>
      </TableRowInner>
    </>
  );
};
