import { useModalContext } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import { downloadSearchData } from '@/services/invoice/utils/downloadHelper';
import { MediaType } from '@/services/invoice/utils/getFile';
import { Guid } from '@/types';
import {
  ButtonGroup, Checkbox, SlimButton
} from '@instech/components';
import { Formik } from 'formik';
import styled from 'styled-components';

const TextWrapper = styled.div`
  color: ${props => props.theme.marineBlue};
  padding: 32px 48px;
`;

const BoldText = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 16px;
`;

export const ExportTypes = {
  adjustingCalculation: 'adjustingCalculation',
  interestCalculation: 'interestCalculation',
  allInvoices: 'allInvoices',
  byCategory: 'byCategory'
};

type ExportOption = 'adjustingCalculation' | 'interestCalculation' | 'allInvoices' | 'byCategory' | 'invoicesForAdjustment';

export const exportAndDownload = (claimStatementId: Guid, exportOption: ExportOption, fromClaimReview?: boolean) => {
  const queryParam = fromClaimReview ? '?fromClaimReview=true' : '';
  void downloadSearchData(`claimstatements/${claimStatementId}/${exportOption}${queryParam}`, MediaType.Excel);
};

type ExportForm = { [key in ExportOption]: boolean };
interface ExportClaimStatementModal {
  claimStatementId: Guid;
}
const ModalContent = ({ claimStatementId }: ExportClaimStatementModal) => {
  const { close } = useModalContext();

  const handleExport = async (values: ExportForm) => {
    if (values.adjustingCalculation) await exportAndDownload(claimStatementId, 'adjustingCalculation');
    if (values.interestCalculation) await exportAndDownload(claimStatementId, 'interestCalculation');
    if (values.allInvoices) await exportAndDownload(claimStatementId, 'allInvoices', false);
    if (values.byCategory) await exportAndDownload(claimStatementId, 'byCategory', false);
    if (values.invoicesForAdjustment) await exportAndDownload(claimStatementId, 'invoicesForAdjustment', false);
    close();
  };

  return (
    <Formik
      onSubmit={handleExport}
      initialValues={{ adjustingCalculation: false, interestCalculation: false, allInvoices: false } as ExportForm}>
      {({ submitForm, values, initialValues }) => (
        <>
          <TextWrapper>
            <BoldText>Options</BoldText>
            <Checkbox noErrors noTopLabel name="interestCalculation" rightLabel="Interest Calculation - Finished invoices" />
            <Checkbox noErrors noTopLabel name="adjustingCalculation" rightLabel="Adjusting (invoice level) - Finished invoices" />
            <Checkbox noErrors noTopLabel name="byCategory" rightLabel="Adjusting (group by category) - Finished invoices" />
            <Checkbox noErrors noTopLabel name="allInvoices" rightLabel="Complete invoice table with all columns" />
            <Checkbox noErrors noTopLabel name="invoicesForAdjustment" rightLabel="Adjusting (Claim Statement Data)" />
          </TextWrapper>
          <ButtonGroup alignRight marginTop="24px">
            <SlimButton variant="secondary" type="reset" onClick={close}>
              CANCEL
            </SlimButton>
            <SlimButton type="submit" disabled={values === initialValues} onClick={submitForm}>
              EXPORT TO EXCEL
            </SlimButton>
          </ButtonGroup>
        </>
      )}
    </Formik>
  );
};

export const exportClaimStatementModal = (
  { claimStatementId }: ExportClaimStatementModal
): ModalType<ExportClaimStatementModal> => ({
  component: ModalContent,
  options: {
    title: 'Export to Excel',
    size: 'small',
    padding: '12px 20px 20px'
  },
  args: { claimStatementId }
});
