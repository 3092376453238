import { FunctionComponent as FC } from 'react';
import { tableCellVariant } from '@/common/components/Table/types';
import { collapseGroups } from '@/common/components/Table/utils';
import { InvoiceState } from '@/types';
import { TableCellWithHover } from '@/common/components/Table/core/TableCell/TableCellWithHover';
import { SurveyorMessageSwitch } from './Components';

interface NotConsideredProps {
  state: InvoiceState;
  span: string;
  isFinished: boolean;
  onClick?: () => void;
}

export const NotConsideredView: FC<NotConsideredProps> = ({ state, span = '5', isFinished, onClick }) => (
  <TableCellWithHover
    data-collapse-group={collapseGroups.surveyor}
    data-cell-variant={tableCellVariant.secondary}
    data-collapse-border
    data-is-finished={isFinished}
    onClick={onClick}
    span={span}>
    <SurveyorMessageSwitch state={state} />
  </TableCellWithHover>
);
