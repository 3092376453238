import { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

/**
 * A hook that scrolls to the top of the page whenever an entry
 * is pushed to the history.
 */
export const useScrollToTop = () => {
  const navigationType = useNavigationType();
  const location = useLocation();

  useEffect(() => {
    if (navigationType === 'PUSH') {
      window.scrollTo({ top: 0 });
    }

    // Subscribing to location.key because navigationType,
    // may be 'PUSH' many times in a row.
  }, [navigationType, location.key]);
};
