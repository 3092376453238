import { FormikWithPrompt } from '@/common/components/Form/PromptUnsavedChanges';
import { UpdateAdjustersInvoiceResponse } from '@/services/invoice/invoiceServices';
import {
  AdjustersConsiderationSplitForm,
  UpdateAdjustersConsiderationForm,
  ClaimStatement,
  Invoice
} from '@/types';
import { DropdownOptions, LabelValuePair } from '@instech/components';
import { Form, useFormikContext } from 'formik';
import {
  FC, useEffect, useState
} from 'react';
import { createPortal } from 'react-dom';
import { useAnchor } from '@/utils/hooks/useAnchor';
import { SurveyorsConsiderationPane } from '@/components/pages/invoice/core/ReadOnlyInvoice';
import { AdditionalInformationPane } from '../AdditionalInformationPane/AdditionalInformationPane';
import { AdjustersConsiderationPane } from '../AdjustersConsiderationPane/AdjustersConsiderationPane';
import { AdjusterTransferInvoiceType } from '../EditInvoiceAdjuster';
import { invoiceDataValidationSchema } from '../utils/validationSchema';
import {
  createdMultipleGrouped, multipleCategoriesLabelValuePair, multipleRepairPeriodsLabelValuePair
} from '../utils/multipleDropdownValue';
import { InvoiceActionButtonsAdjuster } from './Headers/InvoiceActionButtonsAdjuster';
import { ConsiderationProvider } from './utils/ConsiderationProvider';
import { SurveyorSplitsProvider } from './utils/SurveyorSplitsContext';
import { useResetConsiderations } from './utils/useResetConsiderations';
import { InvoiceButtonReturnToOwner } from './Headers/InvoiceButtonReturnToOwner';
import { SaveStatusContextProvider } from './utils/SaveStatus/SaveStatusContext';
import { createInitialValuesAdjuster } from '../initialValues';

interface InvoiceFormAdjusterProps {
  invoice: Invoice;
  claimStatement: ClaimStatement;
  categoryOptions: LabelValuePair[];
  repairPeriodOptions: LabelValuePair[];
  locationOptions: LabelValuePair[];
  occurrenceOptions: LabelValuePair[];
  transferInvoice: AdjusterTransferInvoiceType;
  updateInvoice: (updateInvoiceForm: UpdateAdjustersConsiderationForm) => Promise<UpdateAdjustersInvoiceResponse>;
  ownsLock: boolean;
}

const InvoiceInformationInnerForm: FC<InvoiceFormAdjusterProps> = ({
  invoice,
  claimStatement,
  transferInvoice,
  categoryOptions,
  repairPeriodOptions,
  locationOptions,
  occurrenceOptions,
  updateInvoice,
  ownsLock
}) => {
  const { values, setValues, dirty } = useFormikContext<UpdateAdjustersConsiderationForm>();
  const [computedCategories, setComputedCategories] = useState<DropdownOptions>([]);
  const [computedRepairPeriods, setComputedRepairPeriods] = useState<DropdownOptions>([]);

  const actionButtonsAnchor = useAnchor('claims-handler-action-buttons');
  const returnButtonAnchor = useAnchor('claims-handler-owner-return-button');
  const showGeneralAverage = claimStatement?.claimStatementSettings?.hasGeneralAverage ?? false;

  useEffect(() => {
    const splitCategories = values.splits.map(x => x.category?.value!);
    const isAllTheSame = splitCategories.every(v => v === splitCategories[0]);
    if (!isAllTheSame) {
      const grouped = createdMultipleGrouped(categoryOptions.slice(), { ...multipleCategoriesLabelValuePair });
      setComputedCategories(grouped);
    } else {
      setComputedCategories(categoryOptions);
    }
  }, [categoryOptions, values.splits]);

  useEffect(() => {
    const splitRepairPeriods = values.splits.map(x => x.repairPeriod?.value!);
    const isAllTheSameRepairPeriod = splitRepairPeriods.every(v => v === splitRepairPeriods[0]);
    if (!isAllTheSameRepairPeriod) {
      const grouped = createdMultipleGrouped(repairPeriodOptions.slice(), multipleRepairPeriodsLabelValuePair);
      setComputedRepairPeriods(grouped);
    } else {
      setComputedRepairPeriods(repairPeriodOptions);
    }
  }, [repairPeriodOptions, values.splits]);

  const updateSplitsOrder = (splits: AdjustersConsiderationSplitForm[]) => {
    void setValues(oldVals => ({ ...oldVals, splits }));
  };

  // Handle resetting considerations as values in form change
  useResetConsiderations(values, setValues, showGeneralAverage);

  return (
    <Form>
      <SaveStatusContextProvider dirty={dirty}>
        {actionButtonsAnchor && createPortal(
          <InvoiceActionButtonsAdjuster transferInvoice={transferInvoice} invoice={invoice} updateInvoice={updateInvoice} />,
          actionButtonsAnchor
        )}
        {(returnButtonAnchor && ownsLock) && createPortal(
          <InvoiceButtonReturnToOwner invoice={invoice} updateInvoice={updateInvoice} />,
          returnButtonAnchor
        )}
        <SurveyorSplitsProvider surveyorSplits={invoice.surveyorsConsideration?.splits}>
          <AdditionalInformationPane
            categoryOptions={computedCategories}
            repairPeriodOptions={computedRepairPeriods}
            locationOptions={locationOptions}
            hasSplit={values.splits.length > 1}
            fieldsRequired
            showRepairPeriod
          />
          {invoice?.surveyorsConsideration?.isConsidered && <SurveyorsConsiderationPane invoice={invoice} startCollapsed />}
          <ConsiderationProvider>
            <AdjustersConsiderationPane
              invoice={invoice}
              policyCurrency={claimStatement?.currency}
              categoryOptions={categoryOptions}
              repairPeriodOptions={repairPeriodOptions}
              occurrenceOptions={occurrenceOptions}
              updateSplitsOrder={updateSplitsOrder}
              showGeneralAverage={showGeneralAverage}
            />
          </ConsiderationProvider>
        </SurveyorSplitsProvider>
      </SaveStatusContextProvider>
    </Form>
  );
};

// Form deliberately does not have re-init -- ICS-1018
export const InvoiceFormAdjuster: FC<InvoiceFormAdjusterProps> = ({
  invoice,
  claimStatement,
  updateInvoice,
  transferInvoice,
  categoryOptions,
  repairPeriodOptions,
  locationOptions,
  occurrenceOptions,
  ownsLock
}) => (
  <FormikWithPrompt
    initialValues={createInitialValuesAdjuster(
      invoice.record,
      invoice.adjustersConsideration.splits,
      occurrenceOptions,
      claimStatement?.claimStatementSettings?.hasGeneralAverage
    )}
    validationSchema={invoiceDataValidationSchema}
    onSubmit={() => { }}>
    <InvoiceInformationInnerForm
      invoice={invoice}
      claimStatement={claimStatement}
      transferInvoice={transferInvoice}
      categoryOptions={categoryOptions}
      repairPeriodOptions={repairPeriodOptions}
      locationOptions={locationOptions}
      occurrenceOptions={occurrenceOptions}
      updateInvoice={updateInvoice}
      ownsLock={ownsLock}
    />
  </FormikWithPrompt>
);
