import { SkeletonLoader } from '@/common/components/Animation/SkeletonLoader';
import { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';

const StyledSkeletonLoader = styled(SkeletonLoader) <{ width: string }>`
  height: 52px;
  width: ${props => props.width};
  // Need a little breathing room, does not look good when the tabs are touching
  &:not(:first-child) {
    margin-left: 4px;
  }
`;

const TabButton = styled.button<{ width: string, isActiveTab: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  width: auto;
  min-width: ${props => props.width};
  padding: 8px 16px;
  border: 0;
  cursor: pointer;
  // Same spacing as the loader, so there are no unwanted content shifts.
  &:not(:first-child) {
    margin-left: 4px;
  }
  ${props => props.isActiveTab && css`
    background-color: ${p => p.theme.white};
    color: ${p => p.theme.marineBlue};
    border-bottom: 4px solid ${p => p.theme.green};
    padding-bottom: 4px;
  `}
  ${props => !props.isActiveTab && css`
    background-color: ${p => p.theme.whiteBlue};
    color: ${p => p.theme.marineBlue60};
  `}
`;

const getCountText = (count?: number) => (count ? `(${count})` : '');

interface Props {
  title: string;
  width?: string;
  count?: number;
  isLoading?: boolean;
  isActiveTab: boolean;
  onClick: () => void;
}
export const InvoiceTab: FC<Props> = ({ title, width = '140px', count, isLoading, isActiveTab, onClick }) => {
  const memoizedTitle = useMemo(() => {
    if (isLoading) {
      return '...';
    }
    return `${title} ${getCountText(count)}`;
  }, [title, count, isLoading]);

  const handleClick = () => {
    if (!isLoading) onClick();
  };

  if (isLoading) {
    return <StyledSkeletonLoader width={width} />;
  }

  return (
    <TabButton width={width} isActiveTab={isActiveTab} onClick={handleClick}>
      {memoizedTitle}
    </TabButton>
  );
};
