import {
  SplitTableRow, TableRowItem, TableRowInner,
} from '@/apps/ClaimStatement/components/InvoiceSplitTable';
import { TableRowTag } from '@/apps/ClaimStatement/components/InvoiceSplitTable/SplitTableComponents';
import { useSharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import {
  FCWC,
  InvoiceRecord, Occurrence, SurveyorsConsiderationSplit
} from '@/types';
import { calculateReadonlySurveyorSummaryValues } from '@/utils/invoice/calculateSummaries';
import { useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import styled from 'styled-components';

const DarkTableRowItem = styled(TableRowItem)`
  background-color: ${props => props.theme.marineBlue20};
`;

interface Props {
  consideration: SurveyorsConsiderationSplit;
  invoiceRecord: InvoiceRecord;
  useInvoiceCurrency?: boolean;
  occurrenceList: Occurrence[];
}
export const ReadonlySplitTableRowSurveyor: FCWC<Props> = ({
  children,
  useInvoiceCurrency,
  invoiceRecord,
  consideration,
  occurrenceList
}) => {
  const { tooltipId } = useSharedTooltip();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const { occurrence, category, claim, ownersWork, genExpenses, adjConsideration, splitSum } = useMemo(
    () => calculateReadonlySurveyorSummaryValues(consideration, invoiceRecord.exchangeRate, useInvoiceCurrency, occurrenceList),
    [consideration, invoiceRecord.exchangeRate, useInvoiceCurrency, occurrenceList]
  );

  const handleCollapse = () => setIsCollapsed(!isCollapsed);

  return (
    <>
      <SplitTableRow
        handleCollapse={handleCollapse}
        isCollapsed={isCollapsed}>
        <TableRowItem>{occurrence}</TableRowItem>
        <TableRowItem>{category}</TableRowItem>
        <TableRowItem justify="right">{claim}</TableRowItem>
        <TableRowTag tag={consideration.claimConsiderationTag} tooltipId={tooltipId} />
        <TableRowItem justify="right">{ownersWork}</TableRowItem>
        <TableRowItem justify="right">{genExpenses}</TableRowItem>
        <TableRowTag tag={consideration.generalExpensesTag} tooltipId={tooltipId} />
        <TableRowItem justify="right">{adjConsideration}</TableRowItem>
        <DarkTableRowItem justify="right">{splitSum}</DarkTableRowItem>
      </SplitTableRow>
      <TableRowInner>
        <AnimateHeight duration={250} height={isCollapsed ? 0 : 'auto'}>
          {children}
        </AnimateHeight>
      </TableRowInner>
    </>
  );
};
