import { BaseTableCell } from '@/common/components/Table/TanstackTable/TableCell';
import { useRowClick } from '@/common/components/Table/TanstackTable/providers/RowClickContext';
import { getHeaderGroup, getMergedMeta } from '@/common/components/Table/TanstackTable/utils';
import {
  ApplicationRole,
  Nullable
} from '@/types';
import { CellContext } from '@tanstack/react-table';
import { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import { ClaimStatementTableData } from '../../../getClaimStatementTableData';
import { LatestStatus, StatusChip } from './StatusChip';

const ChipContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

interface Props {
  data: CellContext<ClaimStatementTableData, any>;
  role: Nullable<ApplicationRole>;
}
export const TableCellLatestStatus: FC<Props> = ({ data, role }) => {
  const { onRowClick } = useRowClick();
  const mergedMeta = getMergedMeta(data.column);
  const headerGroup = getHeaderGroup(data.column);
  const latestStatus = data.getValue<LatestStatus>();
  return (
    <BaseTableCell
      id={data.cell.id}
      headerGroup={headerGroup}
      cellVariant={mergedMeta.tableCellVariant}
      columnBorder={mergedMeta.columnBorder}
      onClick={onRowClick}>
      <ChipContainer>
        <StatusChip role={role} latestStatus={latestStatus} />
      </ChipContainer>
    </BaseTableCell>
  );
};
