import styled from 'styled-components';
import { TableCellMaxContent } from './TableCellMaxContent';
import { TableCellHoverStyles } from './TableCellHoverStyles';

interface TableCellProps {
  underline?: boolean;
}
export const StyledTextCell = styled(TableCellMaxContent) <TableCellProps>`
  text-decoration: ${props => props.underline && 'underline'};
  ${TableCellHoverStyles};
`;
