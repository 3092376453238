import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  background-color: ${props => props.theme.marineBlue10};
  padding: 4px 16px;
  border-radius: 2px;
  font-size: 14px;
  color: ${props => props.theme.marineBlue};
  height: 32px;
  align-items: center;
  font-weight: bold;
`;

interface Props {
  selected?: number;
}
/**
 * Displays a small nugget element that shows how many invoices
 * have been selected above an invoice table.
 */
export const SelectedInvoices = ({ selected = 0 }: Props) => (
  <Container>{`${selected} Selected`}</Container>
);
