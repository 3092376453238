import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

const useInvoiceSuggestion = <T, >(name: string, suggestion: T | undefined) => {
  const { dirty } = useFormikContext();
  const [, read, write] = useField<T>(name);
  const [usingSuggestedValue, setUsingSuggestedValue] = useState(false);
  const [hasSuggested, setHasSuggested] = useState(false);

  useEffect(() => {
    if (hasSuggested) {
      return;
    }
    if (!suggestion) {
      return;
    }

    setHasSuggested(true);
    if (read.touched || read.value) {
      return;
    }

    void write.setValue(suggestion);
    setUsingSuggestedValue(true);
  }, [hasSuggested, read.touched, read.value, suggestion, write]);

  // Clear the yellow background
  useEffect(() => {
    if (read.touched && usingSuggestedValue) {
      setUsingSuggestedValue(false);
    }
  }, [usingSuggestedValue, read.touched]);

  // When saving
  useEffect(() => {
    if (!dirty && usingSuggestedValue) {
      setUsingSuggestedValue(false);
    }
  }, [dirty, usingSuggestedValue]);

  return usingSuggestedValue;
};

export default useInvoiceSuggestion;
