export const tableCellVariant = {
  secondary: 'secondary',
  tertiary: 'tertiary'
} as const;

export type TableColumnBorder = 'left' | 'right';

export type TableCellAlign = 'left' | 'center' | 'right';

export type TableCellVariantType = keyof typeof tableCellVariant;
