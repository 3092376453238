import styled from 'styled-components';

export const TitleText = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: ${props => props.theme.marineBlue};
`;

export const LargeBoldText = styled.p`
  font-weight: bold;
  font-size: 18px;
  color: ${props => props.theme.marineBlue};
  margin: 0px;
`;

export const BoldText = styled.p`
  font-weight: bold;
  margin: 0;
`;

export const RegularText = styled.p`
  color: ${props => props.theme.marineBlue};
  margin: 0px;
`;

/**
 * Displays an anchor element as a block, with an underline.
 */
export const LinkBlock = styled.a`
  display: block;
  color: ${props => props.theme.marineBlue};
  text-decoration: underline;
`;

export const SmallLabel = styled.div<{ nowrap?: boolean }>`
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  color: ${props => props.theme.marineBlue};
  margin-bottom: 8px;
  // Ensure single line per label when needed
  white-space: ${props => props.nowrap && 'nowrap'};
`;
