import { TableSubrow } from '@/common/components/Table/TanstackTable/TableBody';
import { BaseTableCell, SubrowCellExpanded } from '@/common/components/Table/TanstackTable/TableCell';
import { TableCellTextBase } from '@/common/components/Table/TanstackTable/TableCell/TableCellText';
import { ClaimStatement, ClaimStatementStatus } from '@/types';
import { getClaimNumber } from '@/utils/claimStatement/getClaimNumber';
import { textOrDash } from '@/utils/text';
import { getFriendlyDate } from '@instech/components';
import { FC, useMemo } from 'react';

const spanWithStatus = 7;
const spanWithoutStatus = 6;

interface Props {
  data: ClaimStatement;
  status: ClaimStatementStatus;
}
const ClaimStatementSubrows: FC<Props> = ({ data, status }) => {
  const finalColSpan = useMemo(() => (
    status === 'Open' ? spanWithStatus : spanWithoutStatus
  ), [status]);
  return (
    <>
      {data.occurrences.map((occurrence, index) => (
        <TableSubrow
          key={occurrence.id}
          isFirstRow={index === 0}
          isLastRow={index === (data.occurrences.length - 1)}
          isEven={index % 2 === 0}>
          <TableCellTextBase id={`${occurrence.id}-claimNumber`} value={getClaimNumber(occurrence.claimNumber, false)} />
          <SubrowCellExpanded id={`${occurrence.id}-subrowExpand`} />
          <BaseTableCell id={`${occurrence.id}-firstGap`} span={2} />
          <TableCellTextBase id={`${occurrence.id}-interest`} value={textOrDash(occurrence.interest)} />
          <TableCellTextBase id={`${occurrence.id}-claimDate`} value={getFriendlyDate(occurrence.claimDate)} />
          <TableCellTextBase id={`${occurrence.id}-occurrenceName`} value={occurrence.name} />
          <BaseTableCell id={`${occurrence.id}-finalGap`} span={finalColSpan} />
        </TableSubrow>
      ))}
    </>
  );
};

export const getClaimStatementSubrows = (data: ClaimStatement, status: ClaimStatementStatus) => (
  <ClaimStatementSubrows data={data} status={status} />
);
