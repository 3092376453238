import { FC } from 'react';
import styled from 'styled-components';
import { InformationForm } from '@/apps/Adjustment/pages/AdjustmentPage/chapters/InsuranceConditions/InformationForm';
import { useRequireClaimStatementId } from '@/utils/useRequireClaimStatementId';
import { useAdjustmentCalculations } from '@/apps/Adjustment/services/adjustmentCalculationsService';
import { useClaimStatement } from '@/services/claimStatementServices';
import { useWindowEventListener } from '@/utils/useWindowEventListener';
import { PolicyInformationPane } from './core';
import { GuidePane } from './core/PolicyInformationPane/GuidePane';

const Layout = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 32px;
  box-sizing: border-box;
`;

interface Props { }

export const InsuranceConditions: FC<Props> = () => {
  const claimStatementId = useRequireClaimStatementId();
  const { data: claimStatement } = useClaimStatement(claimStatementId, { suspense: true });
  const { data: adjustmentCalculations, mutate } = useAdjustmentCalculations(claimStatementId);

  /**
   * Formik is initialized with values from SWR, which are outdated if we do not
   * mutate the form. An alternative to this fix would be to add the
   * `enableReinitialize` prop to the Formik component.
   */
  useWindowEventListener('autosaveFinished', () => {
    void mutate();
  });

  return (
    <Layout>
      <div>
        <PolicyInformationPane adjustmentCalculations={adjustmentCalculations} interest={claimStatement!.interest} />
        <InformationForm adjustmentCalculations={adjustmentCalculations} />
      </div>
      <GuidePane />
    </Layout>
  );
};
