import { InvoiceStateBadge } from '@/apps/ClaimStatement/components/InvoiceState';
import { IntrinsicSpanProps, InvoiceState } from '@/types';
import { CellContext } from '@tanstack/react-table';
import { FunctionComponent as FC } from 'react';
import { BaseTableCell } from './BaseTableCell';
import { getMergedMeta, getHeaderGroup } from '../utils';
import { useRowClick } from '../providers/RowClickContext';

interface Props extends IntrinsicSpanProps {
  data: CellContext<any, any>;
}
/**
 * Renders out a table cell with a badge displaying an invoice state.
 *
 * TODO: Improve type handling for this component.
 * Typing isn't strict enough here to prevent using this component when it isn't
 * sure that the value provided is an InvoiceState, but need to examine how to improve.
 */
export const TableCellState: FC<Props> = ({ data }) => {
  const { onRowClick } = useRowClick();
  const mergedMeta = getMergedMeta(data.column);
  const headerGroup = getHeaderGroup(data.column);
  const invoiceState = data.getValue<InvoiceState>();
  return (
    <BaseTableCell
      id={data.cell.id}
      headerGroup={headerGroup}
      cellVariant={mergedMeta.tableCellVariant}
      columnBorder={mergedMeta.columnBorder}
      onClick={onRowClick}>
      <InvoiceStateBadge state={invoiceState} />
    </BaseTableCell>
  );
};
