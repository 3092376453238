import { Pane, SlimButton } from '@instech/components';
import { FC, useState } from 'react';
import { Occurrence } from '@/types';
import { useTypedModal } from '@/common/modules/Modal';
import { Plus } from '@instech/icons';
import styled from 'styled-components';
import {
  createOccurrenceFromInsModal,
  createOccurrenceFromOrder
} from '@/components/pages/claim/core/ClaimStatementForm/Occurrences';
import {
  CostAllocationSummary,
  TransferAllocatedCostRequest
} from '@/services/occurrenceServices';
import {
  dispatchAutosaveFinishedEvent,
  dispatchAutosaveStartedEvent
} from '@/common/components/Autosave';
import { OccurrenceItem } from './OccurrenceItem';

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const OccurrenceButton = styled(SlimButton)`
  margin: 10px;

  & span {
    font-size: 14px;
  }
`;

interface Props {
  currency: string,
  isExperimentalClaimStatement: boolean,
  initialOccurrences?: Occurrence[],
  onClickCreateOccurrence: (occurrence: Occurrence) => Promise<void>,
  onClickUpdateOccurrence: (occurrence: Occurrence) => Promise<void>,
  onClickDeleteOccurrence: (occurrence: Occurrence) => Promise<void>,
  onTransferInvoiceSplits: (occurrenceId: string, request: TransferAllocatedCostRequest) => Promise<void>,
  costAllocationSummary: CostAllocationSummary | undefined;
}

export const Occurrences: FC<Props> = ({
  currency,
  isExperimentalClaimStatement,
  initialOccurrences: occurrences = [],
  onClickCreateOccurrence,
  onClickUpdateOccurrence,
  onClickDeleteOccurrence,
  onTransferInvoiceSplits,
  costAllocationSummary
}) => {
  const occurrenceOrder = occurrences.length + 1;
  const occurrencesConnectedToIns = occurrences.filter(occurrence =>
    occurrence.insuranceYear !== undefined &&
    occurrence.claimNumber !== null &&
    occurrence.claimNumber !== undefined);
  const [isCreateManualOccurrenceButtonLoading, setIsCreateManualOccurrenceButtonLoading] = useState(false);

  const handleCreateManualOccurrence = async () => {
    if (occurrences.length === 0) {
      return;
    }

    dispatchAutosaveStartedEvent();
    setIsCreateManualOccurrenceButtonLoading(true);
    try {
      await onClickCreateOccurrence(createOccurrenceFromOrder(occurrenceOrder));
    } finally {
      setIsCreateManualOccurrenceButtonLoading(false);
      dispatchAutosaveFinishedEvent();
    }
  };

  const { open: openCreateOccurrenceFromIns } = useTypedModal(createOccurrenceFromInsModal({
    modalTitle: 'Add Occurrence from INS+',
    occurrenceOrderNumber: occurrences.length + 1,
    currency,
    isExperimentalClaimStatement,
    onSubmit: onClickCreateOccurrence,
  }));

  const hasAllocatedCosts = (occurrenceId: string) => {
    if (costAllocationSummary === undefined) {
      return undefined;
    }

    if (occurrenceId in costAllocationSummary) {
      return costAllocationSummary[occurrenceId].hasAllocatedCost;
    }

    return false;
  };

  return (
    <Pane title="Occurrences" color="green" padding="16px" margin="20px 0px 0px">
      {occurrences.map((occurrence: Occurrence, index: number) => (
        <OccurrenceItem
          key={occurrence.id}
          index={index}
          occurrences={occurrences}
          currency={currency}
          disabled={occurrencesConnectedToIns.length === 1 && occurrencesConnectedToIns[0] === occurrence}
          isExperimentalClaimStatement={isExperimentalClaimStatement}
          onClickUpdateOccurrence={onClickUpdateOccurrence}
          onClickDeleteOccurrence={onClickDeleteOccurrence}
          onTransferInvoiceSplits={onTransferInvoiceSplits}
          hasAllocatedCosts={hasAllocatedCosts(occurrence.id)}
        />
      ))}
      <ButtonGroup>
        <OccurrenceButton
          loading={isCreateManualOccurrenceButtonLoading}
          disabled={isCreateManualOccurrenceButtonLoading}
          width="214px"
          variant="secondary"
          startIcon={<Plus />}
          onClick={handleCreateManualOccurrence}>
          ADD MANUAL OCCURRENCE
        </OccurrenceButton>
        <OccurrenceButton variant="secondary" startIcon={<Plus />} onClick={openCreateOccurrenceFromIns}>
          ADD OCCURRENCE FROM INS+
        </OccurrenceButton>
      </ButtonGroup>
    </Pane>
  );
};
