import { DropdownOptions, LabelValuePair } from '@instech/components';

export const multipleCategoriesLabelValuePair: LabelValuePair = {
  value: 'Multiple',
  label: 'Multiple Categories'
};

export const multipleRepairPeriodsLabelValuePair: LabelValuePair = {
  value: 'Multiple',
  label: 'Multiple Repair Periods'
};

/**
 * Util function to create a set of DropdownOptions, with the initial category values
 * listed first, followed by a divider and then the label 'Multiple Categories'.
 */
export const createdMultipleGrouped = (originalValues: LabelValuePair[], multipleLabelValuePair: LabelValuePair): DropdownOptions => [
  {
    options: originalValues
  }, {
    label: '—————',
    options: [multipleLabelValuePair]
  }
];
