import { Table } from '@tanstack/react-table';
import { FC } from 'react';
import styled from 'styled-components';
import { StyledTableCheckbox } from '../../core/Components';

const Container = styled.div`
  padding: 3px 12px;
  min-height: 42px;
  box-sizing: border-box;
`;

interface Props {
  table: Table<any>;
}
export const HeaderButtonCheckbox: FC<Props> = ({ table }) => (
  <Container>
    <StyledTableCheckbox
      name="invoice-table-header-checkbox"
      selected={table.getIsAllRowsSelected()}
      indeterminate={table.getIsSomeRowsSelected()}
      onChange={table.getToggleAllRowsSelectedHandler()}
      noErrors
      noTopLabel
    />
  </Container>
);
