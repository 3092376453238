import { useModalContext } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import { InvoiceReplaceRequestStatus } from '@/services/invoice/invoiceServices';
import { ButtonGroup, SlimButton } from '@instech/components';
import React, { useEffect, useState } from 'react';
import { replaceStatusToContentMap } from './ModalContentComponents';

interface ReplaceInvoiceProps {
  replace: () => Promise<InvoiceReplaceRequestStatus>;
  refresh: () => void;
}

const ModalContent = ({ replace, refresh }: ReplaceInvoiceProps) => {
  const [replaceRequest, setReplaceRequest] = useState<Promise<InvoiceReplaceRequestStatus>>();
  const [replaceResponse, setReplaceResponse] = useState<InvoiceReplaceRequestStatus>({ uploadStatus: 'Uninitialized' });
  const [timeoutId, setTimeoutId] = useState<number>();
  const { close } = useModalContext();

  useEffect(() => {
    if (replaceResponse.uploadStatus === 'Uninitialized') {
      setReplaceRequest(replace());
      setReplaceResponse({ uploadStatus: 'inProgress' });
    }
  }, [replace, replaceResponse]);

  useEffect(() => {
    void (async () => {
      if (replaceRequest) {
        const response = await replaceRequest;
        setReplaceResponse(response);
        if (response.uploadStatus === 'Success') {
          refresh();
        }
      }
    })();
  }, [replaceRequest, timeoutId, refresh]);

  useEffect(() => {
    if (replaceResponse.uploadStatus !== 'inProgress' && timeoutId !== undefined) {
      clearTimeout(timeoutId);
      setTimeoutId(undefined);
    }
  }, [replaceResponse.uploadStatus, timeoutId]);

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      if (replaceResponse.uploadStatus === 'inProgress') {
        setReplaceResponse({ uploadStatus: 'Failed' });
      }
    }, 30 * 1000);
    setTimeoutId(timeout);
  }, [replaceResponse.uploadStatus]);

  return (
    <div>
      { replaceStatusToContentMap[replaceResponse.uploadStatus] }
      <ButtonGroup alignRight>
        <SlimButton disabled={['inProgress', 'Uninitialized'].includes(replaceResponse.uploadStatus)} onClick={close}>
          CLOSE
        </SlimButton>
      </ButtonGroup>
    </div>
  );
};

export const replaceInvoiceModal = (
  { replace, refresh }: ReplaceInvoiceProps
): ModalType<ReplaceInvoiceProps> => ({
  component: ModalContent,
  options: {
    title: 'Replace Invoice',
    size: 'small',
    padding: '12px 20px 20px'
  },
  args: {
    replace,
    refresh
  }
});
