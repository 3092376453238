import useSWR from 'swr';
import { Categories } from '@/types';
import { arrayToLabelValuePair } from '@/utils/labelValuePair';
import { LabelValuePair } from '@instech/components';

export const useGlobalCategories = () => {
  const url = 'categories';
  return useSWR<string[]>(url);
};

export const useClaimStatementCategories = (claimStatementId: string) => {
  const swrRoute = claimStatementId ? `claimstatements/${claimStatementId}` : null;
  return useSWR<Categories>(swrRoute);
};

export const useClaimStatementCategoriesAsOptions = (claimStatementId: string): LabelValuePair[] => {
  const { data: categories } = useClaimStatementCategories(claimStatementId!);

  if (!categories) return [];

  return arrayToLabelValuePair(categories?.categories ?? []);
};
