import { ImagePreview, ImageView } from '@instech/components';
import { FC } from 'react';
import styled from 'styled-components';

const ValidationMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 180px;
  color: ${props => props.theme.marineBlue};
`;

interface ImageViewBlockProps {
  imageData?: ImagePreview | null;
  uploadError?: string;
}

export const ImageViewBlock: FC<ImageViewBlockProps> = ({ imageData, uploadError }) => {
  if (imageData === null) {
    return null;
  }
  if (uploadError) {
    return <ValidationMessage>{uploadError}</ValidationMessage>;
  }
  return <ImageView noBorder width="300px" height="180px" image={imageData} />;
};
