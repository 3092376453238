import React from 'react';
import { PageContent } from '@/common/layout/Page';
import { StopCircle } from '@instech/icons';
import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  & > div {
    max-width: 600px;
  }
`;

const NoAccess = styled.div`
  background-color:  ${props => props.theme.whiteBlue};
`;

const Icon = styled.div`
  text-align: center;
`;

const Header = styled.div`
  font-weight: 700;
  font-size: 60px;
  color: ${props => props.theme.marineBlue};
  text-align: center;
`;

const Body = styled.div`
  margin-top: 40px;
  font-weight: 400;
  font-size: 28px;
  color: ${props => props.theme.marineBlue};
  text-align: center;
`;

export const MagicLinkNoAccessView = () => (
  <PageContent>
    <Layout>
      <NoAccess>
        <Icon><StopCircle /></Icon>
        <Header>Expired Link</Header>
        <Body>This link has expired. If you still require access please contact the person who provided you with the link.</Body>
      </NoAccess>
    </Layout>
  </PageContent>
);
