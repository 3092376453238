import { TableRowItem } from '@/apps/ClaimStatement/components/InvoiceSplitTable';
import { AdjustersConsiderationSplitForm, InvoiceRecord } from '@/types';
import { arrayOfObjectsToSingle } from '@/utils/array';
import { formatNumberWithTwoDecimals } from '@/utils/number';
import { calculateAdjusterTotalLine } from '@/utils/invoice/calculateSummaries';
import { FunctionComponent as FC } from 'react';
import getCurrentValue from '@/apps/ClaimStatement/components/Deviation/getCurrentValue';
import { getTotalLineDeviation, sumSplitValue } from './utils';
import { ReduceConsiderationReturn } from './types';
import { SplitRowStyle } from './Components';

const reduceConsiderationArray = (consideration: AdjustersConsiderationSplitForm[]) => {
  const valsToReduce: ReduceConsiderationReturn[] = consideration.map(item => ({
    commonExpenses: getCurrentValue(item.commonExpenses, item.initialCommonExpenses, item.form.commonExpensesConsidered),
    generalAverage: getCurrentValue(item.generalAverage, item.generalAverage, item.form.generalAverageConsidered),
    particularAverage: getCurrentValue(item.particularAverage, item.initialParticularAverage, item.form.particularAvgConsidered),
    ownersWorkConsideration: getCurrentValue(item.ownersWorkConsideration, item.initialOwnersWork, item.form.ownersWorkConsidered)
  }));
  // Pass values with fallback in case the only remaining split was deleted
  const valuesOrDefault = !valsToReduce.length
    ? [{ commonExpenses: 0, particularAverage: 0, ownersWorkConsideration: 0, generalAverage: 0 }]
    : valsToReduce;
  return arrayOfObjectsToSingle(valuesOrDefault);
};

interface Props {
  splits: AdjustersConsiderationSplitForm[],
  invoice: InvoiceRecord,
  selectedCurrency: string,
  showGeneralAverage: boolean
}
export const TotalLineEdit: FC<Props> = ({ splits, invoice, selectedCurrency, showGeneralAverage }) => {
  const notDeletedSplits: AdjustersConsiderationSplitForm[] = splits.filter(x => !x.isDeleted)
    .map(x => ({ ...x, generalAverage: showGeneralAverage ? x.generalAverage : null }));
  const considerationVals = reduceConsiderationArray(notDeletedSplits);

  const totalLine = {
    commonExpenses: sumSplitValue(notDeletedSplits.map(s => s.commonExpenses)),
    generalAverage: sumSplitValue(notDeletedSplits.map(s => s.generalAverage)),
    particularAverage: sumSplitValue(notDeletedSplits.map(s => s.particularAverage)),
    ownersWork: sumSplitValue(notDeletedSplits.map(s => s.ownersWorkConsideration)),
  };
  const { commonExpenses, generalAverage, particularAvg, ownersWork, splitSum } =
    calculateAdjusterTotalLine(totalLine, invoice.exchangeRate, invoice.currency === selectedCurrency);

  const deviation = formatNumberWithTwoDecimals(getTotalLineDeviation(considerationVals, invoice, selectedCurrency));
  const deviationWithCurrency = `${deviation} ${selectedCurrency || ''}`;
  const isDeviation = deviation !== '0.00';

  return (
    <SplitRowStyle>
      <TableRowItem emphasize="Medium">Sum</TableRowItem>
      <TableRowItem justify="right" emphasize="Medium" />
      <TableRowItem justify="right" emphasize="Medium">{commonExpenses}</TableRowItem>
      <TableRowItem justify="right" emphasize="Medium" />
      {showGeneralAverage && <TableRowItem justify="right" emphasize="Medium">{generalAverage}</TableRowItem>}
      <TableRowItem justify="right" emphasize="Medium">{particularAvg}</TableRowItem>
      <TableRowItem justify="right" emphasize="Medium" />
      <TableRowItem justify="right" emphasize="Medium">{ownersWork}</TableRowItem>
      <TableRowItem justify="right" emphasize="Medium" />
      <TableRowItem justify="right" emphasize="High">{splitSum}</TableRowItem>
      <TableRowItem justify="right" emphasize="Medium" errorText={isDeviation} noWrap>
        {deviationWithCurrency}
      </TableRowItem>
      <TableRowItem emphasize="Medium" />
    </SplitRowStyle>
  );
};
