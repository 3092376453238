import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const Tab = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  line-height: 14px;
  padding: 0 40px;
  border-radius: 6px 6px 0px 0px;
  font-family: CalibriWeb;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: ${props => props.theme.marineBlue};
  color: #fff;
  a:visited {
    color:  #fff;
  }
  ${props => props.selected && css`
    background: ${props.theme.whiteBlue};
    color: ${props.theme.marineBlue};
    a:visited {
      color: ${props.theme.marineBlue};
    }
  `};
`;

interface Props {
  name: string;
  to: string;
  showTab?: boolean;
  isActive?: boolean;
}
export const TabItem: FC<Props> = ({ name, to, showTab = false, isActive = false }) => {
  if (!showTab) return null;
  return (
    <Link to={to}>
      <Tab selected={isActive}>{name}</Tab>
    </Link>
  );
};
