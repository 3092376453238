import styled, { css } from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 22px;
`;

interface TableHeaderProps {
  span?: string | number;
  slimHeader?: boolean;
  align?: 'left' | 'right' | 'center';
  noPointer?: boolean;
}
export const HeaderContent = styled.button<TableHeaderProps>`
  display: flex;
  justify-content: space-between;
  cursor: ${props => props.noPointer ? 'initial' : 'pointer'};
  height: 100%;
  width: 100%;
  min-height: 42px;
  padding: 10px 16px;
  border: 0px;
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.marineBlue};
  font-size: 14px;
  font-weight: bold;
  box-sizing: border-box;
  text-align: ${props => props.align ?? 'left'};
  ${props => props.span && css`
    grid-column: span ${props.span};
  `}
  ${props => props.slimHeader && css`
    padding-top: 2px;
    padding-bottom: 2px;
  `}
  h2 {
    margin: 0;
  }
`;

interface HeaderContainerProps {
  stickyTop?: string;
  borderWidth?: string;
  borderColor?: string;
}
/**
 * Outermost wrapper component for a TableHeader component. Should be
 * the top parent element of any component intended to be used as a
 * table column's TableHeader.
 */
export const HeaderContainer = styled.th<HeaderContainerProps>`
  position: relative;
  background-color: ${props => props.theme.white};
  padding: 0px;
  border: 0px;
  border-top: 1px solid transparent;
  border-bottom: ${props => `${props.borderWidth} solid ${props.theme.marineBlue60}`};
  text-align: left;
  ${props => props.borderColor && css`
    border-bottom-color: ${props.borderColor};
  `};  
  ${props => props.stickyTop && css`
    position: sticky;
    top: ${props.stickyTop};
    z-index: 5;
  `}
`;
