import moment from 'moment';
import * as Yup from 'yup';

/**
 * Validate as a LabelValuePair type ({ label, value }). The default return
 * value of a Dropdown and DropdownCreatable that allows single selection.
 */
export const labelValuePair = () => Yup.object()
  .shape({ label: Yup.string(), value: Yup.string() })
  .default(undefined)
  .nullable();

/**
 * Validate as number greater than 0. No clamps or limits on decimals.
 */
export const numberGreaterThanZero = () => Yup.number()
  .typeError('Not a valid number').moreThan(0, 'Value must be greater than 0');

/**
 * Validate string as the expected return value from a single-value datepicker.
 */
export const datepickerSingle = () => Yup.string().nullable()
  .test('is-invalid-date', 'Invalid date', date => (
    [null, undefined, ''].includes(date) || moment.utc(date, true).isValid()
  ));

/**
* Validate string as trimmed, nullable, and treating empty values as null-
* Doing trim in .transform() instead of .trim() because the combo shows a validation
* error on whitespace; we just want to do validation without whitespace.
*/
export const stringTrimmedSanitized = () => Yup.string().nullable()
  .transform((value: string, originalValue: string) => !value ? null : originalValue.trim());

/**
 * Validate number, converting not-a-numbers to undefined.
 */
export const numberOrUndefined = () => Yup.number().default(undefined).transform(val => Number.isNaN(val) ? undefined : val);
