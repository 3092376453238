import { collapseGroups } from '@/common/components/Table/utils';
import { Invoice } from '@/types';
import { CollapsibleRow } from '@/common/components/Table/CollapsibleTable/types';
import { invoiceStatesFinished } from '@/utils/invoice/invoiceStates';
import { getStateSortingKey } from '@/utils/invoice/sortInvoiceData';
import { InvoiceSurveyorCells, InvoiceAdjusterCells } from '../../core/InvoiceCells';
import { CSTableProps } from '../useSchemaByTableGrouping';

const getInvoiceNo = (invoice: Invoice) => {
  let value = `${invoice.record.order} - `;
  if (invoice.record.invoiceNumber) {
    value = `${value}${invoice.record.invoiceNumber}`;
  }
  return value;
};

export const noGroupingRow = (
  rowData: Invoice,
  props: CSTableProps,
  invoiceIdList: string[]
): CollapsibleRow => {
  const { record } = rowData;
  const { showReview, claimStatementSettings } = props;
  const expanded = props.expanded[rowData.id];

  const onRowClick = () => props.onRowClick(rowData.id, invoiceIdList);
  const toggleExpanded = () => props.toggleExpanded(rowData.id);

  return ({
    id: rowData.id,
    isFinished: invoiceStatesFinished.includes(rowData.state),
    onClick: onRowClick,
    toggleExpanded,
    expanded,
    sortBy: {
      state: getStateSortingKey(rowData.state),
      surveyorGeneralExpenses: rowData.surveyorsConsideration?.generalExpenses,
      surveyorClaimConsideration: rowData.surveyorsConsideration?.claimConsideration,
      surveyorOwnersWorkConsideration: rowData.surveyorsConsideration?.ownersWorkConsideration,
      surveyorAdjusterToConsider: rowData.surveyorsConsideration?.adjusterToConsider,
      surveyorsComment: rowData.surveyorsConsideration?.surveyorsComment,
      adjusterCommonExpenses: rowData.adjustersConsideration.adjustersComment,
      adjusterParticularAverage: rowData.adjustersConsideration.particularAverage,
      adjusterOwnersWorkConsideration: rowData.adjustersConsideration.ownersWorkConsideration,
      adjusterAdjustersComment: rowData.adjustersConsideration.adjustersComment,
    },
    segments: [
      {
        key: 'invoiceNumber',
        cellType: 'text',
        value: getInvoiceNo(rowData)
      },
      {
        key: 'isSharedForReview',
        cellType: 'review',
        value: rowData.isSharedForReview,
        shouldSkip: !showReview,
      },
      {
        key: 'state',
        cellType: 'state',
        value: rowData.state,
      },
      {
        key: 'supplier',
        cellType: 'text',
        value: record.supplier,
        group: collapseGroups.particulars,
        border: true
      },
      {
        key: 'category',
        cellType: 'text',
        value: record.category,
        group: collapseGroups.particulars,
      },
      {
        key: 'currency',
        cellType: 'text',
        value: record.currency,
        group: collapseGroups.particulars,
      },
      {
        key: 'totalAmount',
        cellType: 'digitsTooltip',
        value: record.totalAmount,
        group: collapseGroups.particulars,
        align: 'right'
      },
      {
        key: 'paidAmount',
        cellType: 'digitsTooltip',
        value: record.paidAmount,
        group: collapseGroups.particulars,
        align: 'right'
      },
      {
        key: 'claimInPolicyCurrency',
        cellType: 'digitsTooltip',
        value: record.claimInPolicyCurrency,
        group: collapseGroups.owner,
        border: true,
        align: 'right'
      },
      {
        key: 'ownersWorkInPolicyCurrency',
        cellType: 'digitsTooltip',
        value: record.ownersWorkInPolicyCurrency,
        group: collapseGroups.owner,
        align: 'right'
      },
      {
        key: 'ownersComment',
        cellType: 'comment',
        value: record.ownersComment,
        group: collapseGroups.owner
      },
      {
        key: 'surveyorsConsideration',
        cellType: 'custom',
        value: (
          <InvoiceSurveyorCells
            data={rowData.surveyorsConsideration}
            state={rowData.state}
            exchangeRate={record.exchangeRate}
            onClick={onRowClick}
            showComment
          />
        )
      },
      {
        key: 'adjustersConsideration',
        cellType: 'custom',
        value: (
          <InvoiceAdjusterCells
            data={rowData.adjustersConsideration}
            state={rowData.state}
            reviewComment={rowData.ownersReviewComment}
            exchangeRate={record.exchangeRate}
            showGeneralAverage={claimStatementSettings?.hasGeneralAverage}
            showAdjusterComment
            showReviewComment={props.showReviewComment}
            showReviewDraftComment={!!rowData.ownersReviewDraftComment}
            onClick={onRowClick}
          />
        )
      }
    ]
  });
};
