import React from 'react';
import styled from 'styled-components';
import { useAccount } from '@/common/authentication/AuthenticationProvider';
import { BoxButton } from '@instech/components';
import { ErrorView, ErrorViewContent } from './core/ErrorView';
import { ErrorCodes } from './core/ErrorCodes';

const errorMessage = `
  The request has not been applied because it lacks valid
  authentication credentials for the target resource.
`;

const Container = styled.div`
  max-width: 412px;
  margin: 0px auto;
  padding: 64px 8px 80px;
`;

const LogoutContent = ({ logout }: { logout: () => void }) => (
  <Container>
    <BoxButton width="100%" onClick={logout}>Log Out</BoxButton>
  </Container>
);

export const UnauthorizedPage = ({ reset }: { reset?: () => void }) => {
  const { logout, isAuthenticated } = useAccount();
  return (
    <ErrorView
      type={ErrorCodes.unauthorized}
      title="Unauthorized"
      message={errorMessage}
    >
      {isAuthenticated ? <LogoutContent logout={logout} /> : <ErrorViewContent reset={reset} /> }
    </ErrorView>
  );
};
