import { useCurrentRole } from '@/common/authentication/CurrentRoleContext';
import { ApplicationRole } from '@/types';
import { PortalSelector } from 'insify-remote-component-loader';
import { useAccount } from '@/common/authentication/AuthenticationProvider';
import React from 'react';

export const PortalSelectorLoader = () => {
  const account = useAccount();
  const role = useCurrentRole() as ApplicationRole | undefined;

  return (
    <PortalSelector
      account={account}
      role={role}
      portal={{ name: 'Insify Claim Statement', displayName: 'Claim Statement' }}
    />
  );
};
