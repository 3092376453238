import { truncateDecimals } from '../number';

// same function as react-format-number uses to add thousands separators,
// but they didn't expose it :(
const addThousandSeparator = (value: string): string => {
  const thousandsGroupRegex = /(\d)(?=(\d{3})+(?!\d))/g;
  let index = value.search(/[1-9]/);
  index = index === -1 ? value.length : index;
  return (
    value.substring(0, index) +
    value.substring(index, value.length).replace(thousandsGroupRegex, '$1,')
  );
};

export const formatCurrency = (value: number): string => {
  // If the value is .00 then do not display decimals
  const noDecimals = value === Math.floor(value);
  const numDecimals = noDecimals ? 0 : 2;

  const truncate = truncateDecimals(value, numDecimals);
  // Cast values with decimals to fixed, so ex. 0.2 becomes 0.20
  return noDecimals ? String(truncate) : truncate.toFixed(2);
};

export const multiplyWithPrecision = (amount: number, exchangeRate: number) => {
  // Max number of allowed decimals are 8. value = 2 + exchange rate = 6
  const factor = 10 ** 8;
  return Math.round((amount * exchangeRate) * factor) / factor;
};

export const convertCurrency = (
  amount?: string | number | null,
  exchangeRate?: string | number | null,
  allowZero?: boolean,
  thousandSeparator?: boolean
): string => {
  // Separate !amount checks to ensure linter that value will be present
  if (!amount || !Number(amount) || !exchangeRate) {
    if (allowZero && (amount !== undefined && amount !== null && amount !== '')) {
      return '0';
    }
    return '';
  }

  // Throwing values around here, but agnostic function props and internal
  // conversion makes this function a little easier to work with
  const amountNumber = typeof amount === 'string' ? parseFloat(amount) : amount;
  const exchangeRateNumber = typeof exchangeRate === 'string' ? parseFloat(exchangeRate) : exchangeRate;
  const formattedNumber = formatCurrency(multiplyWithPrecision(amountNumber, exchangeRateNumber));

  if (thousandSeparator) {
    return addThousandSeparator(formattedNumber);
  }
  return formattedNumber;
};

export type ExchangeCalculation = {
  exchangeRate: number | null;
  allowZero: boolean
}

export const getExchangeCalculationValues = (
  exchangeRate?: number | null | undefined,
  useInvoiceCurrency?: boolean
): ExchangeCalculation => {
  // Simplify some values before running through calculations
  const exchangeVal = useInvoiceCurrency ? 1 : exchangeRate || null;
  const allowZero = !!Number(exchangeVal); // if no exchange rate, prefer showing "-"

  return { exchangeRate: exchangeVal, allowZero };
};

export const calculateWithExchangeRate = (
  value?: string | number | null,
  exchangeRate?: number | null
) => {
  if (!value || !exchangeRate || !Number(value)) return undefined;
  if (exchangeRate === 1) return value;

  return multiplyWithPrecision(Number(value), exchangeRate);
};
