import React from 'react';
import { useField } from 'formik';
import {
  CurrencyFieldControlled,
  CurrencyFieldControlledProps
} from '@instech/components';
import { NumberFormatValues } from 'react-number-format';
import { NumberInput } from '@/common/components/Form/NumberInput';

interface CurrencyFieldLimitedProps extends Omit<CurrencyFieldControlledProps, 'type' | 'defaultValue'> {
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
}
export const CurrencyFieldLimited = ({
  name,
  ...props
}: CurrencyFieldLimitedProps) => {
  const [field, , helpers] = useField(name);

  const handleChange = async (evt: NumberFormatValues) => {
    await helpers.setValue(evt.floatValue);
  };

  return (
    <NumberInput
      name={field.name}
      value={field.value}
      customInput={CurrencyFieldControlled}
      decimalScale={2}
      onValueChange={handleChange}
      setValue={helpers.setValue}
      {...props}
    />
  );
};
