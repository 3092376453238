/* eslint-disable react/no-unknown-property */
import {
  useEffect, useMemo, useRef, useState
} from 'react';
import styled from 'styled-components';
import { MenuOption } from '../types';
import { MenuItem } from './MenuItem';

interface StyledMenuProps {
  topPosition: number;
  leftPosition: number;
  maxWidth: number;
  $visible: boolean;
}
const StyledMenu = styled.div<StyledMenuProps>`
  visibility: ${props => props.$visible ? 'visible' : 'hidden'};
  position: fixed;
  display: flex;
  flex-direction: column;
  width: max-content;
  min-width: 177px;
  max-width: ${props => props.maxWidth}px;
  border: 1px solid ${props => props.theme.marineBlue60};
  border-radius: 4px;
  background: ${props => props.theme.white};
  box-sizing: border-box;
  z-index: 30; /* Needs a z-index defined to render over neighbours */
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
  top: ${props => `${props.topPosition}px`};
  left: ${props => `${props.leftPosition}px`};
`;

interface MenuProps {
  id: string;
  setShowing: (showing: boolean) => void;
  options: MenuOption[];
  parentXPosition: number;
  parentYPosition: number;
}

export const Menu = ({ id, setShowing, options, parentXPosition, parentYPosition }: MenuProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const maxWidth = 200;

  const position = useMemo(() => {
    const menuMargin = 20; // at least 20px from screen edge
    const buttonWidth = 36; // align position with parent button

    const { offsetWidth, offsetHeight } = document.documentElement;
    const menuWidth = ref.current?.getBoundingClientRect().width ?? 0;
    const menuHeight = ref.current?.getBoundingClientRect().height ?? 0;

    const flipLeft = offsetWidth <= (parentXPosition + menuWidth + menuMargin);
    const flipUp = offsetHeight <= (parentYPosition + menuHeight + menuMargin);

    const leftPos = parentXPosition - (flipLeft ? (menuWidth - buttonWidth) : 0);
    const topPos = parentYPosition - (flipUp ? (menuHeight + buttonWidth) : 0);

    return { leftPos, topPos };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- manually controlled
  }, [ref.current, parentYPosition, parentXPosition]);

  useEffect(() => {
    setIsVisible(true);
  }, [position]);

  const handleClick = (callback?: () => void) => {
    if (!callback) return;
    callback();
    setShowing(false);
  };

  return (
    <StyledMenu
      id={id}
      leftPosition={position.leftPos}
      topPosition={position.topPos}
      ref={ref}
      maxWidth={maxWidth}
      $visible={isVisible}>
      {options.map(option => (
        <MenuItem
          key={`${id}-${option.label}`}
          label={option.label}
          disabled={!option.onClick}
          onClick={() => handleClick(option.onClick)}
        />
      ))}
    </StyledMenu>
  );
};
