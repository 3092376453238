import {
  TableHeaderRow,
  TableSubheaderClear,
  TableSubheaderGap,
  TableSubheaderGroup
} from '@instech/components';
import styled from 'styled-components';

const StyledSubheaderGap = styled(TableSubheaderGap)`
  height: 33px;
`;

const StyledSubheaderGroup = styled(TableSubheaderGroup)`
  height: 33px;
`;

interface Props {
  currency: string;
  stickyTop: string;
}
export const InvoiceTableSubheaders = ({ currency, stickyTop }: Props) => {
  const ownerStart = 12; // use this as reference point to calculate positions
  return (
    <TableHeaderRow stickyTop={stickyTop}>
      <StyledSubheaderGap start={1} span={ownerStart - 1} />
      <StyledSubheaderGroup start={ownerStart} span={3}>
        {`Owner's Claim - ${currency}`}
      </StyledSubheaderGroup>
      <StyledSubheaderGap start={ownerStart + 3} span={1} />
      <TableSubheaderClear />
    </TableHeaderRow>
  );
};
