import { AutosaveFinishedEvent, AutosaveStartedEvent } from './types';

/**
 * Dispatches window event that autosave has begun for a form.
 * The dispatched event is picked up by AutosaveState, which then
 * updates the state display.
 */
export const dispatchAutosaveStartedEvent = () => {
  const saveEvent = new CustomEvent<AutosaveStartedEvent>('autosaveStarted', {});
  window.dispatchEvent(saveEvent);
};

/**
 * Dispatches window event that autosave has completed for a form.
 */
export const dispatchAutosaveFinishedEvent = () => {
  const saveEvent = new CustomEvent<AutosaveFinishedEvent>('autosaveFinished', {});
  window.dispatchEvent(saveEvent);
};
