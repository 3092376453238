import { DeviationOwner } from '@/apps/ClaimStatement/components/Deviation';
import { CurrencySelector } from '@/common/components/Form/CurrencySelector/CurrencySelector';
import {
  InvoiceRecord, TotalValuesForm, UpdateInvoiceForm
} from '@/types';
import { hasTotalDeviation } from '@/utils/invoice/invoiceTotalDeviation';
import { CollapsiblePane, CurrencyFieldControlled } from '@instech/components';
import { Link } from '@instech/icons';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { convertCurrency } from '@/utils/invoice/convertCurrency';
import { INVOICE_PANE_MARGIN } from '@/utils/style/styleConstants';
import { NumberInput } from '@/common/components/Form/NumberInput';
import { useDisplayCurrency } from './useDisplayCurrency';
import { useFormattedAmount } from './useFormattedAmount';
import { calculateDeviation } from '../utils/calculateDeviation';

export const FieldErrorColor = styled.div<{ error?: boolean }>`
  ${props => props.error && css`
    input { border-color: ${props.theme.red}; }
    label { color: ${props.theme.red}; }
  `}
`;

const FormRow = styled.div<{ hide?: boolean }>`
  display: ${props => props.hide ? 'none' : 'flex'};
  align-items: center;
`;

const LinkIcon = styled(Link)`
  font-size: 40px;
  width: 40px;
  color: ${props => props.theme.border.gray};
`;

const Line = styled.hr`
  width: 25px;
  border: 0;
  height: 1px;
  background-color: ${props => props.theme.border.gray};
`;

export const totalInitialValues: TotalValuesForm = {
  claim: '',
  ownersWork: ''
};

interface TotalPaneProps {
  invoiceRecord: InvoiceRecord;
  policyCurrency?: string;
}
/**
 * This component is using a hacky workaround to do the error colouring around
 * its fields. Because, at least for now, the error display happening here is
 * not validation through Formik; instead it is an error coding on a discrepancy
 * between values that only this pane alone knows the logic behind.
 *
 * It could maybe be refactored to be handled via Formik and validation there,
 * but Formik generally does not permit form submission with actual validation
 * errors present in the form?
 */
export const TotalPane = ({ invoiceRecord, policyCurrency }: TotalPaneProps) => {
  const { values, setFieldValue } = useFormikContext<UpdateInvoiceForm>();
  const [hasError, setHasError] = useState(false);
  const [selectedCurrency, setCurrency] = useState(values?.currency?.value);
  const { totalAmount: total, claim, ownersWork, currency } = values;

  // useEffect hook to delay the update of hasError to be a debounced
  // update from the update of the values themselves, to prevent flickering
  useEffect(() => {
    const hasDeviation = hasTotalDeviation({ total, claim, ownersWork });
    setHasError(hasDeviation && !(claim === '' && ownersWork === ''));
    setCurrency(currency?.value);

    return () => {
      setHasError(false);
    };
  }, [total, claim, ownersWork, currency]);

  const updateSubscriberField = (subscribingField: string, sourceValue: string) => {
    const totalAmount = parseFloat(values.totalAmount as string);
    if (sourceValue === '' || sourceValue === '-') {
      void setFieldValue(subscribingField, Number.isNaN(totalAmount) || values.totalAmount === '' ? '' : totalAmount);
    } else {
      const displayValue = calculateDeviation(totalAmount, sourceValue);
      void setFieldValue(subscribingField, displayValue);
    }
  };

  const updateField = (fieldValue: string, fieldName: 'claim' | 'ownersWork') => {
    void setFieldValue(fieldName, fieldValue);
    const subscribingField = fieldName === 'ownersWork' ? 'claim' : 'ownersWork';
    updateSubscriberField(subscribingField, fieldValue);
  };

  const displayCurrency = useDisplayCurrency(values, selectedCurrency, policyCurrency);
  const useInvoiceCurrency = displayCurrency === values.currency?.value;
  const formattedAmount = useFormattedAmount(values, useInvoiceCurrency, policyCurrency);

  return (
    <CollapsiblePane padding="20px" margin={INVOICE_PANE_MARGIN} title={`Total ${formattedAmount}`} color="green">
      <FieldErrorColor error={hasError}>
        <CurrencySelector
          first={values.currency?.value}
          second={policyCurrency}
          selected={selectedCurrency}
          setSelected={setCurrency}
          id="total-pane"
        />
        <FormRow hide={!useInvoiceCurrency}>
          <NumberInput
            name="claim"
            label="Claim"
            currency={displayCurrency}
            value={claim}
            setValue={value => updateField(value, 'claim')}
            onValueChange={evt => {
              if (evt.floatValue === (claim ? parseFloat(claim) : undefined)) {
                return;
              }
              updateField(evt.floatValue?.toString() ?? '', 'claim');
            }}
            customInput={CurrencyFieldControlled}
            placeholder=""
            decimalScale={2}
          />
          <Line />
          <LinkIcon />
          <Line />
          <NumberInput
            name="ownersWork"
            label="Owner"
            currency={displayCurrency}
            value={ownersWork}
            setValue={value => updateField(value, 'ownersWork')}
            onValueChange={evt => {
              if (evt.floatValue === (ownersWork ? parseFloat(ownersWork) : undefined)) {
                return;
              }
              updateField(evt.floatValue?.toString() ?? '', 'ownersWork');
            }}
            customInput={CurrencyFieldControlled}
            placeholder=""
            decimalScale={2}
          />
        </FormRow>
        {!useInvoiceCurrency && (
          <FormRow>
            <CurrencyFieldControlled
              name="claim-shadow"
              label="Claim"
              currency={displayCurrency}
              disabled
              value={convertCurrency(values.claim, values.exchangeRate, true, true)}
              placeholder=""
            />
            <Line />
            <LinkIcon />
            <Line />
            <CurrencyFieldControlled
              name="ownersWork-shadow"
              label="Owner"
              currency={displayCurrency}
              disabled
              value={convertCurrency(values.ownersWork, values.exchangeRate, true, true)}
              placeholder=""
            />
          </FormRow>
        )}
        {hasError && (
          <DeviationOwner
            selectedCurrency={displayCurrency || ''}
          />
        )}
      </FieldErrorColor>
    </CollapsiblePane>
  );
};
