type ErrorCodesType = {
  unauthorized: 401,
  notFound: 404,
  notAllowed: 405
};

export const ErrorCodes: ErrorCodesType = {
  unauthorized: 401,
  notFound: 404,
  notAllowed: 405
};

export type ErrorCodeValueTypes = ErrorCodesType[keyof ErrorCodesType];
