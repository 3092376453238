import { LabelValuePair } from '@instech/components';

export const piiOptions: LabelValuePair[] = [
  { label: 'No', value: 'No' },
  { label: 'Yes', value: 'Yes' },
  { label: 'Yes - Information is concerning EU citizen(s)', value: 'IncludesEu' }
];

export const hasPii = (piiValue: string) => ['Yes', 'IncludesEu'].includes(piiValue);

export const piiLabel = (piiValue: string) => piiOptions.find(option => option.value === piiValue)?.label;
