import { FC, useCallback } from 'react';
import { useLatestAdjustmentPdf, useAdjustmentPdfDownloadLink } from '@/services/adjustmentPdfServices';
import { useRequireClaimStatementId } from '@/utils/useRequireClaimStatementId';
import { Loader, SlimButton } from '@instech/components';
import { Download } from '@instech/icons';

export const DownloadPdfButton: FC = () => {
  const claimStatementId = useRequireClaimStatementId();
  const { pdf, isValidating: isLoadingPdf } = useLatestAdjustmentPdf(claimStatementId);
  const downloadLinkState = useAdjustmentPdfDownloadLink(claimStatementId, pdf?.id);

  const downloadPdf = useCallback(() => {
    if (downloadLinkState.downloadLink) {
      window.open(downloadLinkState.downloadLink?.url, '_blank', 'noreferrer');
    }
  }, [downloadLinkState]);

  if (isLoadingPdf) {
    return <Loader />;
  }

  return (
    <SlimButton
      variant="secondary"
      startIcon={<Download />}
      onClick={downloadPdf}
      disabled={!pdf}>
      DOWNLOAD ADJUSTMENT PDF
    </SlimButton>
  );
};
