import { useModalContext } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import { ButtonGroup, SlimButton } from '@instech/components';
import { WarningFilled } from '@instech/icons';
import styled from 'styled-components';

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 32px 0;
`;

const WarningIcon = styled(WarningFilled)`
  height: 52px;
  width: 55px;
  margin-bottom: 16px;
  color: ${props => props.theme.marineBlue};
`;

const MessageText = styled.div`
  text-align: center;
  color: ${props => props.theme.marineBlue};
  & > p {
    margin: 0px;
  }
`;

const BoldText = styled.div`
  font-weight: 600;
  margin-bottom: 20px;
`;

interface DisableGeneralAverageProps {
  disableGeneralAverage: () => Promise<any>;
}

const ModalContent = ({ disableGeneralAverage }: DisableGeneralAverageProps) => {
  const { close } = useModalContext();

  const handleCancel = async () => {
    await disableGeneralAverage();
    close();
  };

  return (
    <>
      <ContentContainer>
        <WarningIcon />
        <MessageText>
          <BoldText>All costs allocated to General Average will be moved</BoldText>
          <p>
            All costs allocated to General Average will show as deviations on the
            individual invoices and thus need to be reconsidered and reallocated by
            the adjuster. Once you continue and save, this action cannot be undone.
          </p>
        </MessageText>
      </ContentContainer>
      <ButtonGroup alignRight>
        <SlimButton variant="secondary" onClick={close}>CANCEL</SlimButton>
        <SlimButton onClick={handleCancel}>CONTINUE</SlimButton>
      </ButtonGroup>
    </>
  );
};

export const disableGeneralAverageModal = ({ disableGeneralAverage }: DisableGeneralAverageProps): ModalType<DisableGeneralAverageProps> => ({
  component: ModalContent,
  options: {
    title: 'Turn off General Average',
    size: 'small'
  },
  args: {
    disableGeneralAverage
  }
});
export const disableGeneralAverageDefaultProps: DisableGeneralAverageProps = { disableGeneralAverage: () => Promise.resolve() };
