import { AxiosResponse } from 'axios';
import { StagedInvoice } from '@/types';

export const createHandleUpload = (
  uploadInvoices: (files: File[]) => [StagedInvoice, Promise<AxiosResponse<any, any>> | undefined][],
  setStagedInvoices: (invoices: StagedInvoice[]) => void,
  stagedInvoices: StagedInvoice[],
  setInvoiceResponses: (responses: [string, Promise<AxiosResponse<any, any>>][]) => void
) => async (files: File[]) => {
  const invoiceResponseList = uploadInvoices(files).filter(i => i.filter(file => file).length > 0);
  const invoices = invoiceResponseList.map(([invoice, _]) => invoice);
  const responses = invoiceResponseList
    .map(([invoice, response]) => [invoice.id, response])
    .filter(([_, response]) => response !== undefined) as [string, Promise<AxiosResponse>][];

  setStagedInvoices([...stagedInvoices, ...invoices.filter(invoice => !stagedInvoices.find(staged => staged.name === invoice.name))]);
  setInvoiceResponses(responses);
};
