import { CheckboxControlled } from '@instech/components';
import styled from 'styled-components';
import { TableCellAlign } from '../types';

/**
 * CheckboxControlled component with an added styling to resize checkbox container.
 */
export const StyledTableCheckbox = styled(CheckboxControlled)`
  & span {
    width: 16px;
    height: 16px;
  }
`;

interface StyledCellProps {
  align?: TableCellAlign;
}
/**
 * Reusable cell content wrapper, for handling content align and other
 * similar properties that may be used by the cell.
 */
export const CellContent = styled.div <StyledCellProps>`
  display: flex;
  justify-content: ${props => props.align};
`;
