import { FormikWithPrompt } from '@/common/components/Form/PromptUnsavedChanges';
import { InvoiceRequestStatus } from '@/services/invoice/invoiceServices';
import {
  ClaimStatement,
  Invoice, SurveyorsConsiderationSplitForm, UpdateSurveyorsConsiderationForm
} from '@/types';
import { DropdownOptions, LabelValuePair } from '@instech/components';
import { Form, useFormikContext } from 'formik';
import {
  FC,
  useCallback, useEffect, useState
} from 'react';
import { createPortal } from 'react-dom';
import { useIsClosed } from '@/services/claimStatementServices';
import { useAnchor } from '@/utils/hooks/useAnchor';
import { OwnersClaimPane } from '../../ReadOnlyInvoice';
import { AdditionalInformationPane } from '../AdditionalInformationPane/AdditionalInformationPane';
import { SurveyorsConsiderationPane } from '../SurveyorsConsiderationPane';
import { createInitialValuesSurveyor } from '../initialValues';
import { invoiceDataValidationSchema } from '../utils/validationSchema';
import { createdMultipleGrouped, multipleCategoriesLabelValuePair } from '../utils/multipleDropdownValue';
import { InvoiceActionButtonsSurveyor } from './Headers/InvoiceActionButtonsSurveyor';
import { ConsiderationProvider } from './utils/ConsiderationProvider';
import { resetSurveyorFormWithConsidered } from './utils/resetWithConsidered';
import { useInvoiceLockContext } from '../../../InvoicePage/utils/InvoiceLockContext';

interface InvoiceFormSurveyorProps {
  invoice: Invoice;
  claimStatement: ClaimStatement;
  locationOptions: LabelValuePair[];
  categoryOptions: LabelValuePair[];
  occurrenceOptions: LabelValuePair[];
  updateInvoice: (updateInvoiceForm: UpdateSurveyorsConsiderationForm) => Promise<InvoiceRequestStatus>;
}

const InvoiceInformationInnerForm: FC<InvoiceFormSurveyorProps> = (
  { invoice, claimStatement, categoryOptions, locationOptions, occurrenceOptions, updateInvoice }
) => {
  const [computedCategories, setComputedCategories] = useState<DropdownOptions>([]);
  const { resetForm, values, setValues } = useFormikContext<UpdateSurveyorsConsiderationForm>();

  const { ownsLock } = useInvoiceLockContext();
  const isClaimStatementClosed = useIsClosed();
  const buttonAnchor = useAnchor('surveyor-action-buttons');

  const onSubmit = useCallback(() => (async () => {
    const result = await updateInvoice(values);
    if (result === 'success') {
      resetForm({ values: resetSurveyorFormWithConsidered(values) });
    }
    return result;
  })(), [values, resetForm, updateInvoice]);

  useEffect(() => {
    const splitCategories = values.splits.map(x => x.category?.value!);
    const isAllTheSame = splitCategories.every(v => v === splitCategories[0]);
    if (!isAllTheSame) {
      const grouped = createdMultipleGrouped(categoryOptions.slice(), { ...multipleCategoriesLabelValuePair });
      setComputedCategories(grouped);
    } else {
      setComputedCategories(categoryOptions);
    }
  }, [categoryOptions, values.splits]);

  const updateSplitsOrder = (splitOrder: SurveyorsConsiderationSplitForm[]) => {
    const editedInvoiceWithNewOrder = { ...values, splits: splitOrder };
    void setValues(editedInvoiceWithNewOrder);
  };

  return (
    <Form>
      {buttonAnchor && createPortal(
        <InvoiceActionButtonsSurveyor invoice={invoice} saveInvoice={onSubmit} ownsLock />,
        buttonAnchor
      )}
      <OwnersClaimPane
        claimStatement={claimStatement}
        invoice={invoice}
        policyCurrency={claimStatement?.currency}
        useEditButton={ownsLock}
        readonly={isClaimStatementClosed}
      />
      <AdditionalInformationPane
        categoryOptions={computedCategories}
        locationOptions={locationOptions}
        hasSplit={values.splits.length > 1}
      />
      <ConsiderationProvider>
        <SurveyorsConsiderationPane
          invoice={invoice}
          policyCurrency={claimStatement?.currency}
          categoryOptions={categoryOptions}
          occurrenceOptions={occurrenceOptions}
          updateSplitsOrder={updateSplitsOrder}
        />
      </ConsiderationProvider>
    </Form>
  );
};

export const InvoiceFormSurveyor: FC<InvoiceFormSurveyorProps> = (
  { invoice, claimStatement, categoryOptions, locationOptions, occurrenceOptions, updateInvoice }
) => (
  <FormikWithPrompt
    initialValues={createInitialValuesSurveyor(
      invoice.record,
      occurrenceOptions,
      invoice.surveyorsConsideration?.splits,
      invoice.surveyorsConsideration?.isConsidered
    )}
    validationSchema={invoiceDataValidationSchema}
    onSubmit={() => { }}>
    <InvoiceInformationInnerForm
      invoice={invoice}
      claimStatement={claimStatement}
      categoryOptions={categoryOptions}
      locationOptions={locationOptions}
      occurrenceOptions={occurrenceOptions}
      updateInvoice={updateInvoice}
    />
  </FormikWithPrompt>
);
