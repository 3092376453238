/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import {
  DefaultErrorMessage, ErrorBoundary, Loader
} from '@instech/components';
import {
  FC,
  Suspense, useEffect,
  useRef,
  useState
} from 'react';
import { createPortal } from 'react-dom';
import { FCWC } from '@/types';
import { ModalProvider } from './ModalContext';
import { ModalLayout } from './ModalLayout';
import { ModalOptions } from './types';

let modalRoot = document.getElementById('modal');
if (!modalRoot) {
  modalRoot = document.createElement('div');
  modalRoot.setAttribute('id', 'modal');
  modalRoot.setAttribute('data-testid', 'modal');
  document.body.appendChild(modalRoot);
}

interface ModalProps {
  options: ModalOptions;
  closeModal: () => void;
  onCancel: () => void;
}
export const Modal: FCWC<ModalProps> = ({ children, options, closeModal, onCancel }) => {
  const elRef = useRef<any>(null); // should type better, this is a quick escape for now
  if (!elRef.current) {
    elRef.current = document.createElement('div');
  }

  useEffect(() => {
    modalRoot?.appendChild(elRef.current);
    document.body.style.overflow = 'hidden';

    return () => {
      modalRoot?.removeChild(elRef.current);
      document.body.style.overflow = '';
    };
  }, []);

  return createPortal(
    <ModalLayout options={options} closeModal={closeModal} onCancel={onCancel}>{children}</ModalLayout>,
    elRef.current
  );
};

interface ModalContentProps {
  component: () => JSX.Element;
  args: any;
}
const ModalContent: FC<ModalContentProps> = ({ component: Component, args }) => (
  <Component {...args} />
);

interface ErrorProps {
  reset?: () => void;
}

const errorMessage: FC<ErrorProps> = ({ reset }) => (
  <DefaultErrorMessage reset={reset} />
);

interface ModalHandlerProps extends ModalProps, ModalContentProps { }

export const ModalHandler: FCWC = ({ children }) => {
  const [modal, setModal] = useState<ModalHandlerProps | null>(null);
  const close = () => setModal(null);

  return (
    <ModalProvider open={setModal} close={close}>
      {children}
      {modal && (
        <Modal options={modal.options} closeModal={close} onCancel={modal.onCancel}>
          <ErrorBoundary component={() => errorMessage({ reset: close })}>
            <Suspense fallback={<Loader />}>
              <ModalContent
                component={modal.component}
                args={modal.args}
              />
            </Suspense>
          </ErrorBoundary>
        </Modal>
      )}
    </ModalProvider>
  );
};
