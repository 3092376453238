import { TableSubrow } from '@/common/components/Table/TanstackTable/TableBody';
import { BaseTableCell, SubrowCellExpanded } from '@/common/components/Table/TanstackTable/TableCell';
import { TableCellTextBase } from '@/common/components/Table/TanstackTable/TableCell/TableCellText';
import { useTableOptionsContext } from '@/common/components/Table/TanstackTable/providers/TableOptionsContext';
import { tableCellVariant } from '@/common/components/Table/TanstackTable/types';
import { ClaimStatementSettings } from '@/types';
import { FC } from 'react';
import { CHInvoiceTableDataNoGrouping } from './tableDataNoGrouping';
import { SplitSurveyorCells } from '../../TableCells/SplitSurveyorCells';
import { SplitAdjusterCells } from '../../TableCells/SplitAdjusterCells';

interface Props {
  data: CHInvoiceTableDataNoGrouping;
  settings?: ClaimStatementSettings;
}
const SubrowTemplate: FC<Props> = ({ data, settings }) => {
  const tableOptions = useTableOptionsContext();
  return (
    <>
      {data.splits.map((split, index) => (
        <TableSubrow
          key={split.id}
          isFirstRow={index === 0}
          isLastRow={index === (data.splits.length - 1)}
          isEven={index % 2 === 0}>
          <BaseTableCell id={`${split.id}-firstGap`} />
          <SubrowCellExpanded id={`${split.id}-subrowExpand`} />
          <BaseTableCell id={`${split.id}-secondGap`} span={3} />
          <BaseTableCell id={`${split.id}-particularsGapStart`} headerGroup="particulars" columnBorder="left" />
          <TableCellTextBase
            id={`${split.id}-category`}
            value={split.category}
            headerGroup="particulars" />
          <BaseTableCell id={`${split.id}-particularsGapEnd`} headerGroup="particulars" span={3} />
          <BaseTableCell id={`${split.id}-ownersGap`} headerGroup="owner" span={3} columnBorder="left" />
          <SplitSurveyorCells
            split={split}
            state={data.state}
            headerGroup="surveyor"
            cellVariant={tableCellVariant.secondary}
            exchangeRate={data.exchangeRate}
            showFullComments={tableOptions.showFullComments} />
          <SplitAdjusterCells
            split={split}
            headerGroup="adjuster"
            cellVariant={tableCellVariant.tertiary}
            exchangeRate={data.exchangeRate}
            showFullComments={tableOptions.showFullComments}
            showGeneralAverage={settings?.hasGeneralAverage}
            showReviewComment={data.showReviewComment} />
        </TableSubrow>
      ))}
    </>
  );
};

export const getSubrowsInvoiceNoGrouping = (data: CHInvoiceTableDataNoGrouping, settings?: ClaimStatementSettings) => (
  <SubrowTemplate data={data} settings={settings} />
);
