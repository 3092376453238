import { CollapsibleSubrow } from '@/common/components/Table/CollapsibleTable/types';
import { collapseGroups } from '@/common/components/Table/utils';
import { Invoice } from '@/types';
import { getInvoiceNumber } from '../../../../core/utils/getInvoiceNumber';
import { InvoiceAdjusterCells, InvoiceSurveyorCells } from '../../core/InvoiceCells';
import { CSTableProps } from '../useSchemaByTableGrouping';

export const groupByCategorySubrow = (
  subrow: Invoice,
  props: CSTableProps,
  subrowsIdList: string[],
): CollapsibleSubrow => {
  const { claimStatementSettings } = props;
  const onRowClick = () => props.onRowClick(subrow.id, subrowsIdList);

  return ({
    id: subrow.id,
    onClick: onRowClick,
    segments: [
      {
        key: 'particular-cell-invoice-number',
        cellType: 'text',
        value: getInvoiceNumber(subrow),
      },
      {
        key: 'paidAmount',
        cellType: 'digitsTooltip',
        value: subrow.record.paidAmount,
        align: 'right'
      },
      {
        key: 'claimInPolicyCurrency',
        cellType: 'digitsTooltip',
        value: subrow.record.claimInPolicyCurrency,
        group: collapseGroups.owner,
        border: true,
        align: 'right'
      },
      {
        key: 'ownersWorkInPolicyCurrency',
        cellType: 'digitsTooltip',
        value: subrow.record.ownersWorkInPolicyCurrency,
        group: collapseGroups.owner,
        align: 'right'
      },
      {
        key: 'surveyor-cells',
        cellType: 'custom',
        value: (
          <InvoiceSurveyorCells
            data={subrow.surveyorsConsideration}
            state={subrow.state}
            exchangeRate={subrow.record.exchangeRate} />
        )
      },
      {
        key: 'adjuster-cells',
        cellType: 'custom',
        value: (
          <InvoiceAdjusterCells
            data={subrow.adjustersConsideration}
            state={subrow.state}
            reviewComment={subrow.ownersReviewComment}
            showGeneralAverage={!!claimStatementSettings?.hasGeneralAverage}
            showReviewComment={props.showReviewComment}
            showReviewDraftComment={!!subrow.ownersReviewDraftComment}
            usePrintSchema={props.usePrintSchema}
            exchangeRate={subrow.record.exchangeRate} />
        )
      }
    ]
  });
};
