import { SlimButton } from '@instech/components';
import { Refresh } from '@instech/icons';
import { FunctionComponent as FC } from 'react';
import styled from 'styled-components';

// Styling could be done on the button directly, but we want to color the container later on
const DeletedContainer = styled.div`
  text-align: end;
  background: ${props => props.theme.adjuster.deleted};
  & span {
    font-size: 14px;
  }
`;

interface DeletedItemProps {
  onRestore: () => void;
}

export const DeletedSplitItemAdjuster: FC<DeletedItemProps> = ({ onRestore }) => (
  <DeletedContainer>
    <SlimButton
      variant="tertiary"
      startIcon={<Refresh width="18px" height="18px" />}
      onClick={onRestore}>
      RESTORE DELETED SPLIT
    </SlimButton>
  </DeletedContainer>
);
