import { apiRouteAdjustersConsideration } from '@/services/invoice/utils/invoiceApiRoute';
import {
  AdjustersConsiderationSplitForm,
  Guid,
  Invoice,
  UpdateAdjustersConsiderationForm,
  UpdateAdjustersConsiderationRequest
} from '@/types';
import useSWR from 'swr';
import { putAsync } from '../../client';
import { UpdateAdjustersConsiderationSplitRequest, updateAdjustersConsiderationRequestSchema } from '../utils/adjustersConsiderationRequestSchema';
import { UpdateAdjustersInvoiceResponse } from './index';

const noInitialOrUpdatedValues = (split: AdjustersConsiderationSplitForm): boolean =>
  !split.initialParticularAverage
  && !split.initialCommonExpenses
  && !split.initialOwnersWork
  && !split.initialCommonExpenses
  && !split.ownersWorkConsideration
  && !split.commonExpenses
  && !split.generalAverage
  && !split.particularAverage;

type Considerations = Pick<UpdateAdjustersConsiderationSplitRequest,
  'isCommonExpensesConsidered' | 'isOwnersWorkConsiderationConsidered' | 'isParticularAverageConsidered' | 'isGeneralAverageConsidered'>;

const evaluateConsiderations = (split: AdjustersConsiderationSplitForm, manySplits: boolean): Considerations => {
  if (noInitialOrUpdatedValues(split)) {
    return {
      isParticularAverageConsidered: !!manySplits,
      isCommonExpensesConsidered: !!manySplits,
      isOwnersWorkConsiderationConsidered: !!manySplits,
      isGeneralAverageConsidered: !!manySplits
    };
  }

  const particularAverageByDisjunction = !split.particularAverage &&
    !split.initialParticularAverage &&
    (split.form.commonExpensesConsidered || split.form.ownersWorkConsidered || split.form.generalAverageConsidered);

  const commonExpensesByDisjunction = !split.commonExpenses &&
    !split.initialCommonExpenses &&
    (split.form.particularAvgConsidered || split.form.ownersWorkConsidered || split.form.generalAverageConsidered);

  const ownersWorkByDisjunction = !split.ownersWorkConsideration &&
    !split.initialOwnersWork &&
    (split.form.particularAvgConsidered || split.form.commonExpensesConsidered || split.form.generalAverageConsidered);

  const generalAverageByDisjunction = !split.generalAverage &&
    (split.form.particularAvgConsidered || split.form.commonExpensesConsidered || split.form.ownersWorkConsidered);

  return {
    isParticularAverageConsidered: split.form.particularAvgConsidered || particularAverageByDisjunction,
    isOwnersWorkConsiderationConsidered: split.form.ownersWorkConsidered || ownersWorkByDisjunction,
    isCommonExpensesConsidered: split.form.commonExpensesConsidered || commonExpensesByDisjunction,
    isGeneralAverageConsidered: split.form.generalAverageConsidered || generalAverageByDisjunction
  };
};

export interface AdjustersConsiderationSplitUpdate {
  isCommonExpensesConsidered: boolean | undefined;
  isOwnersWorkConsiderationConsidered: boolean | undefined;
  isParticularAverageConsidered: boolean | undefined;
  category?: string | null;
  repairPeriod?: string;
  adjustersComment?: string | null;
  internalNotes?: string | null;
  occurrenceId: string;
  initialCategory?: string | null;
  initialOccurrenceId?: string;
  isSuggested?: boolean;
}

const isAnyFieldModified = (split: AdjustersConsiderationSplitUpdate): boolean =>
  !!split.isCommonExpensesConsidered ||
  !!split.isParticularAverageConsidered ||
  !!split.isOwnersWorkConsiderationConsidered ||
  split.occurrenceId !== split.initialOccurrenceId ||
  (split.category ?? null) !== (split.initialCategory ?? null) ||
  !!split.repairPeriod ||
  !!split.adjustersComment?.trim() ||
  !!split.internalNotes?.trim();

export const canPersistAdjustersSplit = (split: AdjustersConsiderationSplitUpdate): boolean =>
  !split.isSuggested || isAnyFieldModified(split);

const createRequestPayload = (
  updateForm: UpdateAdjustersConsiderationForm,
  etag: string
): UpdateAdjustersConsiderationRequest =>
  updateAdjustersConsiderationRequestSchema.cast({
    splits: updateForm.splits.map(split => ({
      ...split,
      category: split.category?.value,
      repairPeriod: split.repairPeriod?.value,
      occurrenceId: split.occurrence.value,
      particularAverage: split.form.particularAvgConsidered ? split.particularAverage : undefined,
      particularAverageTag: split.particularAverageTag?.value,
      generalAverage: split.form.generalAverageConsidered ? split.generalAverage : undefined,
      ownersWorkConsideration: split.form.ownersWorkConsidered ? split.ownersWorkConsideration : undefined,
      ownersWorkConsiderationTag: split.ownersWorkConsiderationTag?.value,
      commonExpenses: split.form.commonExpensesConsidered ? split.commonExpenses : undefined,
      commonExpensesTag: split.commonExpensesTag?.value,
      ...evaluateConsiderations(split, updateForm.splits.length > 1)
    })).filter(canPersistAdjustersSplit),
    etag,
    category: updateForm.category?.value,
    repairPeriod: updateForm.repairPeriod?.value,
    location: updateForm.location?.value,
    personallyIdentifiableInformation: updateForm.personallyIdentifiableInformation?.value,
    personallyIdentifiableInformationDescription: updateForm.personallyIdentifiableInformationDescription
  }, { stripUnknown: true });

/**
 * Call to update invoice, specifically for parts and data that can be altered by an Adjuster
 * when they are making the Adjuster's Considerations
 */
export const createUpdateInvoiceAdjuster = (claimStatementId: Guid, invoiceId: Guid, mutate: ReturnType<typeof useSWR<Invoice>>['mutate']) =>
  async (updateForm: UpdateAdjustersConsiderationForm, etag: string):
  Promise<UpdateAdjustersInvoiceResponse> => {
    const requestBody = createRequestPayload(updateForm, etag);

    const response = await putAsync(apiRouteAdjustersConsideration(claimStatementId, invoiceId), requestBody);
    if (response.status === 200) {
      await mutate();
      return {
        status: 'success',
        invoice: response.data
      };
    }
    if (response.status === 409 || response.status === 403) {
      return {
        status: 'conflict',
        invoice: response.data
      };
    }
    return {
      status: 'error',
      invoice: response.data
    };
  };
