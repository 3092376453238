import { BaseTableCell } from '@/common/components/Table/TanstackTable/TableCell';
import { TableCellCommentBase } from '@/common/components/Table/TanstackTable/TableCell/TableCellComment';
import { TableCellVariantType } from '@/common/components/Table/types';
import { FunctionComponent as FC } from 'react';
import { CHInvoiceTableSplit } from '../TableViews/TableViewNoGrouping/tableDataNoGrouping';
import { SplitCellContent, SplitRowMessageWrapper } from './Components';

const getDynamicSpanWidth = (dynamicCells: (boolean | undefined)[]) => {
  const adjusterCellCount = 4;
  const extraSpanWidth = dynamicCells.filter(val => !!val).length;
  return (adjusterCellCount + extraSpanWidth);
};

interface NewSplitRowAdjusterProps {
  split: CHInvoiceTableSplit;
  headerGroup?: string;
  cellVariant?: TableCellVariantType;
  exchangeRate?: number | null;
  showFullComments?: boolean;
  showGeneralAverage?: boolean;
  showReviewComment?: boolean;
}
export const SplitAdjusterCells: FC<NewSplitRowAdjusterProps> = ({
  split,
  headerGroup,
  cellVariant,
  exchangeRate,
  showFullComments,
  showGeneralAverage,
  showReviewComment
}) => {
  const { isDeleted, ...adjuster } = split.adjuster;
  const tooltipId = 'invoice-page-tooltip';

  const isConsidered = true;

  if (isDeleted) {
    return (
      <BaseTableCell
        id={`${split.id}-adjusterMessage`}
        columnBorder="left"
        cellVariant={cellVariant}
        headerGroup={headerGroup}
        span={getDynamicSpanWidth([showGeneralAverage, showReviewComment])}>
        <SplitRowMessageWrapper>Deleted by Adjuster.</SplitRowMessageWrapper>
      </BaseTableCell>
    );
  }
  return (
    <>
      <BaseTableCell
        id={`${split.id}-commonExpenses`}
        columnBorder="left"
        cellVariant={cellVariant}
        headerGroup={headerGroup}>
        <SplitCellContent tooltipId={tooltipId} value={adjuster.commonExpenses} exchangeRate={exchangeRate} isConsidered={isConsidered} />
      </BaseTableCell>
      {showGeneralAverage && (
        <BaseTableCell
          id={`${split.id}-generalAverage`}
          cellVariant={cellVariant}
          headerGroup={headerGroup}>
          <SplitCellContent tooltipId={tooltipId} value={adjuster.generalAverage} exchangeRate={exchangeRate} isConsidered={isConsidered} />
        </BaseTableCell>
      )}
      <BaseTableCell
        id={`${split.id}-particularAverage`}
        cellVariant={cellVariant}
        headerGroup={headerGroup}>
        <SplitCellContent tooltipId={tooltipId} value={adjuster.particularAverage} exchangeRate={exchangeRate} isConsidered={isConsidered} />
      </BaseTableCell>
      <BaseTableCell
        id={`${split.id}-ownersWorkConsideration`}
        cellVariant={cellVariant}
        headerGroup={headerGroup}>
        <SplitCellContent tooltipId={tooltipId} value={adjuster.ownersWorkConsideration} exchangeRate={exchangeRate} isConsidered={isConsidered} />
      </BaseTableCell>
      <TableCellCommentBase
        id={`${split.id}-adjustersComment`}
        value={adjuster.adjustersComment}
        cellVariant={cellVariant}
        headerGroup={headerGroup}
        tooltipId={tooltipId}
        showFullComments={showFullComments}
      />
    </>
  );
};
