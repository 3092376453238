import {
  DndContext, DragEndEvent, UniqueIdentifier
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { ButtonGroup, SlimButton } from '@instech/components';
import { PropsWithChildren } from 'react';

export type ReorderSplitsSaveButtonState = 'idle' | 'saving';

interface ReorderSplitsActionButtonsProps {
  onClose: () => void;
  onCancel: () => void;
  saveButtonState: ReorderSplitsSaveButtonState;
}
export const ReorderSplitsActionButtons = ({ onClose, onCancel, saveButtonState }: ReorderSplitsActionButtonsProps) => (
  <ButtonGroup alignRight>
    <SlimButton variant="secondary" onClick={onCancel}>CANCEL</SlimButton>
    <SlimButton onClick={onClose} loading={saveButtonState === 'saving'} disabled={saveButtonState === 'saving'}>CLOSE</SlimButton>
  </ButtonGroup>
);

interface SortingContextProps {
  onDragEnd: (event: DragEndEvent) => void;
  items: UniqueIdentifier[];
}
export const SortingContext = ({ children, onDragEnd, items }: PropsWithChildren<SortingContextProps>) => (
  <DndContext onDragEnd={onDragEnd}>
    <SortableContext strategy={verticalListSortingStrategy} items={items}>
      {children}
    </SortableContext>
  </DndContext>
);
