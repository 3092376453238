import { Loader } from '@instech/components';
import { FC } from 'react';
import styled from 'styled-components';

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
`;

interface Props { }
export const TableLoader: FC<Props> = () => (
  <LoaderContainer>
    <Loader size="large" />
  </LoaderContainer>
);

export const NoData = styled.div`
  font-style: italic;
  margin: 8px 20px;
`;
