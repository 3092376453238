import {
  adjusterCommonExpensesTags,
  adjusterOwnersWorkTags,
  adjusterParticularAverageTags,
  AdjustersConsiderationSplit,
  AdjustersConsiderationSplitForm,
  InvoiceRecord,
  UpdateAdjustersConsiderationForm,
  UpdateInvoiceForm
} from '@/types';
import { LabelValuePair } from '@instech/components';
import { stringToLabelValuePair } from '@/utils/labelValuePair';
import {
  multipleCategoriesLabelValuePair,
  multipleRepairPeriodsLabelValuePair
} from '@/components/pages/invoice/core/EditInvoice/utils/multipleDropdownValue';
import { getSplitWithReset } from '@/components/pages/invoice/core/EditInvoice/InvoiceForm/utils/useResetConsiderations';
import { v4 as uuidv4 } from 'uuid';

const getCurrentValue = <T>(value: T | null, initialValue: T | null, isConsidered: boolean): T | undefined => {
  const hasValue = !!value || value === 0;
  const hasInitialValue = !!initialValue;

  if (hasInitialValue && !isConsidered && !hasValue) {
    return initialValue;
  }

  return value ?? undefined;
};

const initialValuesToConsiderations = (record: AdjustersConsiderationSplit) => {
  const record2: AdjustersConsiderationSplit = {
    ...record,
    ownersWorkConsideration: getCurrentValue(record.ownersWorkConsideration, record.initialOwnersWork, record.isOwnersWorkConsiderationConsidered === true),
    commonExpenses: getCurrentValue(record.commonExpenses, record.initialCommonExpenses, record.isCommonExpensesConsidered === true),
    particularAverage: getCurrentValue(record.particularAverage, record.initialParticularAverage, record.isParticularAverageConsidered === true),
  };

  return record2;
};

export const newAdjustersConsiderationFormValues = (
  isConsidered?: boolean,
  hasInternalNotes?: boolean,
  startCollapsed?: boolean,
  isOutdated?: boolean
): AdjustersConsiderationSplitForm['form'] => ({
  arrayId: uuidv4(),
  startCollapsed: startCollapsed ?? false,
  hasInternalNotes: hasInternalNotes ?? false,
  ownersWorkConsidered: isConsidered ?? true,
  particularAvgConsidered: isConsidered ?? true,
  commonExpensesConsidered: isConsidered ?? true,
  generalAverageConsidered: isConsidered ?? true,
  isOutdated: isOutdated ?? false
});

export const newAdjustersConsiderationItem = (
  firstOccurrence: LabelValuePair,
  consideration?: AdjustersConsiderationSplitForm
): AdjustersConsiderationSplitForm => ({
  form: newAdjustersConsiderationFormValues(),
  id: uuidv4(),
  commonExpenses: undefined,
  ownersWorkConsideration: undefined,
  particularAverage: undefined,
  generalAverage: undefined,
  adjustersComment: '',
  internalNotes: '',
  initialParticularAverage: undefined,
  initialOwnersWork: undefined,
  initialCommonExpenses: undefined,
  category: consideration?.category ?? undefined,
  repairPeriod: consideration?.repairPeriod ?? undefined,
  occurrence: consideration?.occurrence ?? firstOccurrence,
});

// some pairs have a different label than value
const piiLabelValuePair = (piiValue?: string | null) => {
  if (piiValue === 'IncludesEu') return { value: 'IncludesEu', label: 'Yes - Information is concerning EU citizen(s)' };
  return stringToLabelValuePair(piiValue);
};

const dropdownOptionsInfo = (invoice: InvoiceRecord): UpdateInvoiceForm => ({
  category: invoice.category === multipleCategoriesLabelValuePair.value ? multipleCategoriesLabelValuePair : stringToLabelValuePair(invoice.category),
  location: stringToLabelValuePair(invoice.location),
  repairPeriod: invoice.repairPeriod === multipleRepairPeriodsLabelValuePair.value ?
    multipleRepairPeriodsLabelValuePair : stringToLabelValuePair(invoice.repairPeriod),
  personallyIdentifiableInformation: piiLabelValuePair(invoice.personallyIdentifiableInformation),
  personallyIdentifiableInformationDescription: invoice.personallyIdentifiableInformationDescription ?? ''
});

const filterAwayNulls = (split: AdjustersConsiderationSplit): UpdateInvoiceForm => {
  if (!split) return {};
  return Object.entries(split)
    .filter(value => value[1] !== null)
    .reduce((accumulated, current) => ({
      ...accumulated,
      [current[0]]: current[1]
    }), {});
};

export const createInitialValuesAdjuster = (
  invoiceRecord: InvoiceRecord,
  adjusterSplits: AdjustersConsiderationSplit[],
  occurrenceOptions: LabelValuePair[],
  showGeneralAverage?: boolean
): UpdateAdjustersConsiderationForm => {
  const splits = adjusterSplits ?? [];
  const categoryValueIfOneSplit = splits.length === 1 && (invoiceRecord.category !== multipleCategoriesLabelValuePair.value)
    ? invoiceRecord.category
    : undefined;
  const repairPeriodValueIfOneSplit = splits.length === 1 && (invoiceRecord.repairPeriod !== multipleRepairPeriodsLabelValuePair.value)
    ? invoiceRecord.repairPeriod
    : undefined;

  const initialValues = ({
    ...dropdownOptionsInfo(invoiceRecord),
    splits: adjusterSplits.map(initialValuesToConsiderations).map(split => {
      const category = categoryValueIfOneSplit ?? split.category;
      const occurence = occurrenceOptions.find(o => o.value === split.occurrenceId) ?? occurrenceOptions[0];
      return {
        ...newAdjustersConsiderationItem(occurrenceOptions[0]),
        form: {
          ...newAdjustersConsiderationFormValues(split.isConsidered, !!split?.internalNotes, true, split.isOutdated),
          ownersWorkConsidered: split.isOwnersWorkConsiderationConsidered ?? split.isConsidered,
          commonExpensesConsidered: split.isCommonExpensesConsidered ?? split.isConsidered,
          generalAverageConsidered: split.isGeneralAverageConsidered ?? split.isConsidered,
          particularAvgConsidered: split.isParticularAverageConsidered ?? split.isConsidered
        },
        ...filterAwayNulls(split),
        repairPeriod: stringToLabelValuePair(repairPeriodValueIfOneSplit ?? split.repairPeriod),
        ownersWorkConsiderationTag: adjusterOwnersWorkTags.find(tag => tag.value === split?.ownersWorkConsiderationTag),
        commonExpensesTag: adjusterCommonExpensesTags.find(tag => tag.value === split?.commonExpensesTag),
        particularAverageTag: adjusterParticularAverageTags.find(tag => tag.value === split?.particularAverageTag),
        occurrence: occurence,
        category: stringToLabelValuePair(category),
        initialCategory: category,
        initialOccurrenceId: occurence?.value,
        isSuggested: split.isSuggested
      };
    })
  });

  // BLUE-4538 -- Ensure initial values are as expected by useResetConsiderations()
  // Defining as a new object, as direct .map() assignment doesn't seem to work consistently
  const initialValsWithReset = ({
    ...initialValues,
    splits: initialValues.splits.map(split => {
      const splitWithReset = getSplitWithReset(split, showGeneralAverage);
      return splitWithReset; // Explicit return of invoked function for SonarCloud's sake
    })
  });

  return initialValsWithReset;
};
