import { SlimButton } from '@instech/components';
import { SendFilled } from '@instech/icons';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { SelectedInvoices } from '../../core/SelectedInvoices';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px 40px 8px;
`;

interface InvoiceButtonsProps {
  selected: number;
  onOpen: () => void;
  onSubmit: () => void;
  isClaimStatementClosed: boolean;
}
export const InvoiceButtons: FunctionComponent<InvoiceButtonsProps> = ({ selected, onOpen, onSubmit, isClaimStatementClosed }) => {
  const disabled = selected <= 0;
  return (
    <Container>
      <SelectedInvoices selected={selected} />
      <SlimButton variant="secondary" onClick={onOpen} disabled={disabled}>OPEN</SlimButton>
      <SlimButton startIcon={<SendFilled />} onClick={onSubmit} disabled={disabled || isClaimStatementClosed}>SUBMIT TO CLAIMS HANDLER</SlimButton>
    </Container>
  );
};
