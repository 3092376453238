import { DecimalNumberField } from '@/common/components/Form/DecimalNumberField';
import { FormRow } from '@/common/components/Form/FormRow/FormRow';
import {
  DatePicker, DropdownCreatable, LabelValuePair
} from '@instech/components';
import { Form, useFormikContext } from 'formik';
import { FunctionComponent as FC } from 'react';
import { getParsedDecimalAsStringWithGivenPrecision } from '@/utils/number';
import { UpdateOwnersRecordForm } from '@/types';
import { useExchangeRateLabel } from '../../../utils/useExchangeRateLabel';

interface Props {
  supplierOptions?: LabelValuePair[];
  invoiceCurrency?: string | null;
  policyCurrency?: string | null;
  totalAmount?: number | null;
}
export const ClaimInfoForm: FC<Props> = ({ supplierOptions, invoiceCurrency, policyCurrency, totalAmount }) => {
  const { values, setFieldValue } = useFormikContext<UpdateOwnersRecordForm>();
  const formGutter = '32px';
  const hasPolicyCurrency = invoiceCurrency === policyCurrency;
  const exchangeRateLabel = useExchangeRateLabel(invoiceCurrency, policyCurrency);

  // Update paid amount based on total amount and exchange rate
  const updatePaidAmount = () => {
    const exchangeRate = Number(values.exchangeRate);
    const newPaidAmount = totalAmount && exchangeRate ? totalAmount * exchangeRate : 0;
    const paidAmountRaw = totalAmount && exchangeRate ? getParsedDecimalAsStringWithGivenPrecision(newPaidAmount.toFixed(7), 2, true) : '';
    if (values.paidAmount !== paidAmountRaw) {
      void setFieldValue('paidAmount', paidAmountRaw);
    }
  };

  return (
    <Form>
      <FormRow gutter={formGutter} marginBottom="20px" layoutLarge="1fr 1fr 1fr">
        <DropdownCreatable
          placeholder=""
          name="supplier"
          label="Contractor / Supplier"
          options={supplierOptions || []} // TODO: update to self-load options
          required />
        <DecimalNumberField
          name="exchangeRate"
          maxDecimals={12}
          label={exchangeRateLabel}
          disabled={hasPolicyCurrency}
          onBlur={updatePaidAmount} />
        <DatePicker
          name="paymentDate"
          label="Payment Date"
          putPlaceholderAbove />
      </FormRow>
    </Form>
  );
};
