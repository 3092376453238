import { Table } from '@tanstack/react-table';
import { FC } from 'react';
import { TableHeaderCell } from './TableHeaderCell';
import { StyledHeaderRow, StyledTableHeader } from './Components';

interface Props {
  table: Table<any>;
  borderWidth?: string;
}
/**
 * Renders a row of table header elements.
 */
export const TableHeader: FC<Props> = ({ table, borderWidth }) => (
  <StyledTableHeader>
    {table.getHeaderGroups().map(headerGroup => (
      <StyledHeaderRow key={headerGroup.id}>
        {headerGroup.headers.map(header => (
          <TableHeaderCell
            key={header.id}
            header={header}
            borderWidth={borderWidth} />
        ))}
      </StyledHeaderRow>
    ))}
  </StyledTableHeader>
);
