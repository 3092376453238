import { ComponentProps } from 'react';
import { stringToLabelValuePair } from '@/utils/labelValuePair';
import styled from 'styled-components';
import { DropdownCurrency } from '@/common/components/Form/DropdownCurrency/DropdownCurrency';
import { HighlightEffect } from '@/common/components/HighlightedFields';
import { useInvoiceSuggestion } from './utils';

const CurrencyDropdownHighlighted = styled(DropdownCurrency)`
  &[data-highlight="true"] [class*="-control"] {
    ${HighlightEffect}
  }
`;

type CurrencyInputProps = {
  suggestion: string | undefined;
} & Partial<ComponentProps<typeof CurrencyDropdownHighlighted>>
const CurrencyInput = ({ suggestion, ...props }: CurrencyInputProps) => {
  const value = stringToLabelValuePair(suggestion);
  const usingSuggestedValue = useInvoiceSuggestion('currency', value);
  return (
    <CurrencyDropdownHighlighted
      name="currency"
      placeholder=""
      label="Currency"
      required
      data-highlight={usingSuggestedValue}
      {...props} />
  );
};

export default CurrencyInput;
