import { CommentAndNotes } from '@/components/pages/invoice/core/ReadOnlyInvoice/core/CommentAndNotes';
import { CurrencySelector } from '@/common/components/Form/CurrencySelector/CurrencySelector';
import { WarningCollapsiblePane } from '@/common/components/Pane';
import { DarkReadonlyCollapsiblePane, ReadonlyCollapsiblePane } from '@/common/components/Pane/ReadonlyCollapsiblePane';
import { InfoLayout, InfoWithLabel } from '@/common/components/Text';
import { useClaimStatement } from '@/services/claimStatementServices';
import {
  Invoice, InvoiceRecord, SurveyorsConsiderationSplit
} from '@/types';
import { formatWithDigitsOrEmpty, getConvertedCurrencyText } from '@/utils/number';
import { Loader } from '@instech/components';
import { EyeSlash } from '@instech/icons';
import { FunctionComponent, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { INVOICE_PANE_MARGIN } from '@/utils/style/styleConstants';
import { NoExchangeRateInfo } from '../../EditInvoice/core/NoExchangeRateInfo';
import { MultipleReadonlyConsideration } from './SurveyorsConsiderationReadonly/MultipleReadonlyConsideration';
import { SingleReadonlySurveyorsConsiderationPane } from './SurveyorsConsiderationReadonly/SingleReadonlySurveyorsConsiderationPane';

const hasValue = (value?: string | null) => {
  if (value == null) return false;
  return value !== '';
};

interface ReadOnlyWithCurrencyProps {
  record: SurveyorsConsiderationSplit | undefined;
  invoiceRecord: InvoiceRecord;
}

const ReadonlyFieldsWithCurrency = ({ record, invoiceRecord }: ReadOnlyWithCurrencyProps) => {
  const { claimStatementId } = useParams();
  const { data: claimstatement } = useClaimStatement(claimStatementId);
  const [selectedCurrency, setCurrency] = useState(invoiceRecord.currency);
  const useInvoiceCurrency = selectedCurrency === invoiceRecord.currency;

  if (!claimstatement) return <Loader />;

  const handleCurrencyChange = (currency: string) => {
    setCurrency(currency);
  };

  const noExchangeRateVisible = (claimstatement.currency === selectedCurrency && !invoiceRecord.exchangeRate);

  return (
    <>
      <CurrencySelector
        first={invoiceRecord?.currency}
        second={claimstatement.currency}
        selected={selectedCurrency}
        setSelected={handleCurrencyChange}
        id="surveyor-read-only-consideration-pane"
      />
      <NoExchangeRateInfo isVisible={noExchangeRateVisible} />
      <InfoLayout columnMin="160px">
        <InfoWithLabel
          label="Claim"
          text={getConvertedCurrencyText(record?.claimConsideration, invoiceRecord.exchangeRate, useInvoiceCurrency)}
        />
        <InfoWithLabel
          label="Owner"
          text={getConvertedCurrencyText(record?.ownersWorkConsideration, invoiceRecord.exchangeRate, useInvoiceCurrency)}
        />
        <InfoWithLabel
          label="General Expenses"
          text={getConvertedCurrencyText(record?.generalExpenses, invoiceRecord.exchangeRate, useInvoiceCurrency)}
        />
        <InfoWithLabel
          label="Adjuster to Consider"
          text={getConvertedCurrencyText(record?.adjustersConsideration, invoiceRecord.exchangeRate, useInvoiceCurrency)}
        />
      </InfoLayout>
      <CommentAndNotes
        comment={record?.surveyorsComment}
        commentLabel="Description of Consideration"
        notes={record?.internalNotes}
        showNotes={hasValue(record?.internalNotes)}
        showBorder
      />
    </>
  );
};

interface ReadOnlyProps {
  record: SurveyorsConsiderationSplit | undefined;
}

const ReadonlyFields = ({ record }: ReadOnlyProps) => (
  <>
    <InfoLayout columnMin="160px">
      <InfoWithLabel
        label="Claim"
        text={formatWithDigitsOrEmpty(record?.claimConsideration, true)}
      />
      <InfoWithLabel
        label="Owner"
        text={formatWithDigitsOrEmpty(record?.ownersWorkConsideration, true)}
      />
      <InfoWithLabel
        label="General Expenses"
        text={formatWithDigitsOrEmpty(record?.generalExpenses, true)}
      />
      <InfoWithLabel
        label="Adjuster to Consider"
        text={formatWithDigitsOrEmpty(record?.adjustersConsideration, true)}
      />
    </InfoLayout>
    <CommentAndNotes
      comment={record?.surveyorsComment}
      commentLabel="Description of Consideration"
      notes={record?.internalNotes}
      showNotes={hasValue(record?.internalNotes)}
      showBorder
    />
  </>
);

export const DarkSingleReadonlyConsideration: FunctionComponent<ReadOnlyProps> = ({ record }) => (
  <DarkReadonlyCollapsiblePane title="Surveyor's Consideration" margin="40px 0px 0px 0px">
    <ReadonlyFields record={record} />
  </DarkReadonlyCollapsiblePane>
);

export const OutdatedConsideration: FunctionComponent<ReadOnlyProps> = ({ record }) => (
  <WarningCollapsiblePane
    title="Surveyor's Consideration"
    margin="8px 0px 0px 0px"
    padding="16px"
    tooltip="Adjusters’s consideration is based on previous values from Surveyor.">
    <ReadonlyFields record={record} />
  </WarningCollapsiblePane>
);

export const SingleReadonlyConsideration: FunctionComponent<ReadOnlyWithCurrencyProps> = ({ record, invoiceRecord }) => (
  <ReadonlyCollapsiblePane title="Surveyor's Consideration" margin={INVOICE_PANE_MARGIN}>
    <ReadonlyFieldsWithCurrency record={record} invoiceRecord={invoiceRecord} />
  </ReadonlyCollapsiblePane>
);

export const ReadOnlySurveyorsConsideration: FunctionComponent<ReadOnlyWithCurrencyProps> = ({ record, invoiceRecord }) => (
  <ReadonlyCollapsiblePane title="Surveyor's Consideration" margin={INVOICE_PANE_MARGIN}>
    <ReadonlyFieldsWithCurrency record={record} invoiceRecord={invoiceRecord} />
  </ReadonlyCollapsiblePane>
);

const SurveyorsDeskLabel = styled.p`
  color: ${props => props.theme.marineBlue60};
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
`;
const NotVisibleIcon = styled(EyeSlash)`
  margin-right: 8px;
`;
const AtSurveyorsDeskConsiderationContent = () => (
  <SurveyorsDeskLabel>
    <NotVisibleIcon />
    At Surveyor’s desk.
  </SurveyorsDeskLabel>
);

export const AtSurveyorsDeskConsideration = () => (
  <ReadonlyCollapsiblePane startCollapsed title="Surveyor’s Consideration" margin={INVOICE_PANE_MARGIN}>
    <AtSurveyorsDeskConsiderationContent />
  </ReadonlyCollapsiblePane>
);

export const AtSurveyorsDeskSubSplit = () => (
  <DarkReadonlyCollapsiblePane title="Surveyor’s Consideration" margin={INVOICE_PANE_MARGIN}>
    <AtSurveyorsDeskConsiderationContent />
  </DarkReadonlyCollapsiblePane>
);

const DeletedLabel = styled.span`
  font-weight: bold;
  color: ${props => props.theme.marineBlue};
`;
export const DeletedBySurveyorConsideration = () => (
  <DarkReadonlyCollapsiblePane
    title="Split deleted by Surveyor"
    margin="8px 0px 0px 0px"
    padding="16px">
    <DeletedLabel>Surveyor has deleted this split.</DeletedLabel>
  </DarkReadonlyCollapsiblePane>
);

interface Props {
  invoice: Invoice;
  startCollapsed?: boolean;
}
export const SurveyorsConsiderationPane: FunctionComponent<Props> = ({ invoice, startCollapsed }) => {
  const { surveyorsConsideration: consideration, record: invoiceRecord } = invoice;
  const isMultiple = consideration && consideration.splits?.length > 1;
  const isSingle = !isMultiple && !!consideration?.splits;
  return (
    <>
      {isMultiple && <MultipleReadonlyConsideration record={invoiceRecord} splits={consideration.splits} startCollapsed={startCollapsed} />}
      {isSingle && <SingleReadonlySurveyorsConsiderationPane record={consideration.splits[0]} invoiceRecord={invoiceRecord} />}
    </>
  );
};
