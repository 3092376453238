import styled from 'styled-components';

/**
 * Stylized div element for displaying a blank box with an animated swipe -- or a skeleton loader.
 * This element can be used on its own or in combination with other element to create an immediate
 * outline of components that are about to be rendered, ahead of any data being loaded.
 *
 * This component in its current style will try to fill whichever container it is placed inside.
 * Most likely, you will want to extend this style and adapt it to whatever component it is being
 * used as a skeleton for.
 */
export const SkeletonLoader = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(102, 138, 170, 0.2);
  background: 
    linear-gradient(
      to right,
      rgba(102, 138, 170, 0.2) 8%,
      rgba(102, 138, 170, 0.3) 18%,
      rgba(102, 138, 170, 0.2) 33%
    );
  background-size: 800px 100px;
  animation: loading-wave 1.5s infinite ease-out;
  cursor: wait;
  @keyframes loading-wave {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
`;
