import { useCurrentRole } from '@/common/authentication/CurrentRoleContext';
import { TitleText } from '@/common/components/Text';
import { PageContent } from '@/common/layout/Page';
import { useFavourites } from '@/services/claimStatementFavouritesService';
import { useClaimStatementOverview } from '@/services/claimStatementServices';
import { downloadInvoicesData } from '@/services/invoice/utils/downloadHelper';
import { MediaType } from '@/services/invoice/utils/getFile';
import {
  AvailableInvoiceStatuses, ClaimStatement, ClaimStatementStatus
} from '@/types';
import {
  DropdownControlled, MenuOption, Pane
} from '@instech/components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ClaimStatementTable } from './ClaimStatementTable';
import { TableLoader } from './ClaimStatementTable/core/Components';
import { getNonClaimHandlerMenuOptions } from './ClaimStatementTable/core/claimStatementTableOptions';

const TableArea = styled.div`
  padding-top: 40px;
`;

const DropdownControlledStyled = styled(DropdownControlled)`
  width: 360px;
`;

const ButtonsRow = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const hasAnyInvoicesToDownload = (claimStatement: ClaimStatement) =>
  claimStatement.invoiceCounts.total > 0 ||
  claimStatement.invoiceCounts.sharedWithClaimsHandler > 0 ||
  claimStatement.invoiceCounts.sharedWithSurveyor > 0 ||
  claimStatement.invoiceCounts.returnedFromSurveyor > 0 ||
  claimStatement.invoiceCounts.finished > 0;

export const ClaimStatementDashboard = () => {
  const [claimStatementStatus, setClaimStatementStatus] = useState<ClaimStatementStatus>('Open');
  const { data: claimStatements } = useClaimStatementOverview(claimStatementStatus);
  const { data: favourites, deleteFavourite, addFavourite } = useFavourites();
  const role = useCurrentRole();
  const navigate = useNavigate();

  const navigateToClaimStatement = (claimStatementId: string) => {
    navigate(`claimstatements/${claimStatementId}`);
  };

  const onStarClick = async (claimStatementId: string, selected: boolean) => {
    if (selected) await deleteFavourite(claimStatementId);
    else await addFavourite(claimStatementId);
  };

  const handleStatusSelect = (val: any) => {
    setClaimStatementStatus(val.value);
  };

  const handleDownloadInvoicesData = async (claimStatementId: string) => {
    await downloadInvoicesData(claimStatementId, MediaType.Zip);
  };

  const getContextMenuOptions = (claimStatement: ClaimStatement): MenuOption[] =>
    getNonClaimHandlerMenuOptions(
      claimStatement,
      navigateToClaimStatement,
      hasAnyInvoicesToDownload(claimStatement) ? handleDownloadInvoicesData : undefined
    );

  return (
    <PageContent>
      <TitleText>Claims dashboard</TitleText>
      <ButtonsRow>
        <DropdownControlledStyled
          label="Status"
          name="status"
          defaultValue={{ label: claimStatementStatus, value: claimStatementStatus }}
          options={AvailableInvoiceStatuses.map(s => ({ label: s, value: s })) || []}
          onChange={handleStatusSelect} />
      </ButtonsRow>
      <TableArea>
        {!claimStatements || !favourites
          ? <TableLoader />
          : (
            <Pane color="green" padding="16px 0px 0px">
              <ClaimStatementTable
                claimStatements={claimStatements}
                favourites={favourites}
                onRowClick={navigateToClaimStatement}
                onStarClick={onStarClick}
                getContextMenuOptions={getContextMenuOptions}
                claimStatementStatus={claimStatementStatus}
                role={role}
              />
            </Pane>
          )}
      </TableArea>
    </PageContent>
  );
};
