import * as Yup from 'yup';
import { documentCategoryValues } from '@/services/supportingDocumentsServices';
import { stringMaxLength } from '@instech/components';

export const attachmentValidationSchema = Yup.object({
  documentName: stringMaxLength(60),
  documentCategory: Yup.object({
    label: Yup.string(),
    value: Yup.string().oneOf([
      documentCategoryValues.additionalInformation,
      documentCategoryValues.calculations,
      documentCategoryValues.technicalDocumentation
    ])
  }).notRequired(),
  pii: Yup.mixed().required('This is a required field.'),
  piiDescription: stringMaxLength(110).notRequired()
});
