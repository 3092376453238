import { TableCell } from '@instech/components';
import styled from 'styled-components';
import { TableCellHoverStyles } from './TableCellHoverStyles';

/**
 * TableCell component that will always expand to be at least wide
 * enough to have all of its contents on a single line. Useful together
 * with other components that truncate cell contents.
 */
export const TableCellMaxContent = styled(TableCell)`
  white-space: nowrap;
  min-width: 100%;
  width: max-content;
  ${TableCellHoverStyles}
`;
