import { FileDropzone } from '@/common/components/FileDropzone';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { useTypedModal } from '@/common/modules/Modal';
import {
  DocumentCategoryValue, SupportingDocumentResponse, documentCategories,
} from '@/services/supportingDocumentsServices';
import { ClaimStatementInvoiceId } from '@/types';
import { ContextMenu } from '@instech/components';
import { ComponentProps, FunctionComponent } from 'react';
import { useCurrentRole } from '@/common/authentication/CurrentRoleContext';
import { FileTable, IconCell } from '@/common/components/Table';
import { acceptedTypes } from '../../core/utils';
import {
  Content, Header, Wrapper
} from '../TabPaneComponents';
import { DropzoneContainer } from '../core/Components';
import { defaultDeleteSupportingDocumentModal, deleteSupportingDocumentModal } from '../core/Modals/DeleteSupportingDocumentModal';
import { defaultEditAttachmentsProps, editAttachmentModal } from './modal/EditAttachmentModal';
import { defaultUploadAttachmentsProps, uploadAttachmentModal } from './modal/UploadAttachmentModal';

const categoryLabel = (value: DocumentCategoryValue) =>
  documentCategories.find(category => category.value.toLowerCase() === value.toLowerCase())?.label;

const attachmentsTooltipId = 'attachments-tooltip' as const;

interface AttachmentsPaneProps {
  compositeInvoiceId: ClaimStatementInvoiceId;
  attachments: SupportingDocumentResponse[];
  isClaimStatementClosed: boolean;
}
export const AttachmentsPane: FunctionComponent<AttachmentsPaneProps> = ({
  compositeInvoiceId,
  attachments,
  isClaimStatementClosed
}) => {
  const userRole = useCurrentRole();
  const { open: openUploadModal } = useTypedModal(uploadAttachmentModal(defaultUploadAttachmentsProps));
  const { open: openEditModal } = useTypedModal(editAttachmentModal(defaultEditAttachmentsProps));
  const { open: openDeleteModal } = useTypedModal(deleteSupportingDocumentModal(defaultDeleteSupportingDocumentModal));

  const handleUpload: ComponentProps<typeof FileDropzone>['handleUpload'] = files =>
    openUploadModal({ attachment: files[0] as File, claimStatementInvoiceId: compositeInvoiceId });

  const uploadInstructions = (
    <span>
      Please do not upload a file containing personal identifiable
      information unless strictly necessary to support your claim.
    </span>
  );

  return (
    <Wrapper>
      <Header>
        <span>Documents relevant for your claim can be uploaded here.</span>
      </Header>
      <Content>
        <SharedTooltip id={attachmentsTooltipId}>
          <FileTable>
            <thead>
              <tr>
                <th>File Name</th>
                <th>Document Name</th>
                <th>Document Category</th>
                <th>PII</th>
                <th aria-label="context menu" />
              </tr>
            </thead>
            <tbody>
              {(!attachments || attachments.length === 0) && (
                <tr>
                  <td colSpan={4}>No attachments have been uploaded yet.</td>
                </tr>
              )}
              {attachments && attachments.length > 0 && attachments.map(attachment => (
                <tr key={attachment.id}>
                  <td><a href={attachment.fileInformation.path}>{attachment.fileInformation.filename}</a></td>
                  <td>{attachment.documentName}</td>
                  <td>{categoryLabel(attachment.documentCategory)}</td>
                  <td data-for={attachmentsTooltipId} data-tip={attachment.piiDescription}>
                    {attachment.pii === 'No' ? 'No' : 'Yes'}
                  </td>
                  <IconCell>
                    <ContextMenu
                      data-testid={`context-menu-${attachment.id}`}
                      options={[
                        {
                          label: 'Edit',
                          onClick: isClaimStatementClosed ? null : () =>
                            openEditModal({ id: { ...compositeInvoiceId, supportingDocumentId: attachment.id }, supportingDocument: attachment })
                        },
                        {
                          label: 'Delete',
                          onClick: userRole?.name === attachment?.createdBy?.role && !isClaimStatementClosed ?
                            () => openDeleteModal({
                              id: {
                                ...compositeInvoiceId,
                                supportingDocumentId: attachment.id,
                                documentName: attachment.documentName || attachment.fileInformation.filename
                              }
                            }) :
                            null
                        }
                      ]} />
                  </IconCell>
                </tr>
              ))}
            </tbody>
          </FileTable>
        </SharedTooltip>
        {!isClaimStatementClosed && (
          <DropzoneContainer>
            <FileDropzone
              acceptedTypes={acceptedTypes}
              handleUpload={handleUpload}
              instructions={uploadInstructions}
              id="attachments-uploader" />
          </DropzoneContainer>
        )}
      </Content>
    </Wrapper>
  );
};
