import styled from 'styled-components';

interface FormRowProps {
  marginTop?: string;
  marginBottom?: string;
  gutter: string;
  layoutLarge: string;
  layoutMedium?: string;
  layoutSmall?: string;
}
/**
 * Form row layouting component. Should be later retired and replaced with a
 * same named component in @instech/components after it has been retooled to use
 * Grid instead of Flexbox to handle the form row layout.
 */
export const FormRow = styled.div<FormRowProps>`
  display: grid;
  grid-gap: ${props => props.gutter || '0px'};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  grid-template-columns: ${props => props.layoutLarge || '1fr'};
  @media (max-width: 899px) {
    grid-template-columns: ${props => props.layoutMedium};
  }
  @media (max-width: 599px) {
    grid-template-columns: ${props => props.layoutSmall};
  }
`;
