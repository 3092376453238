import React from 'react';
import styled from 'styled-components';
import { theme } from '@/utils/style/theme';
import { InvoiceUploadStatus } from '@/types';

interface StatusProps {
  backgroundColor: string;
  color?: string;
}

// Deliberately defying the specs a tiny bit on this style to
// compensate for display differences between Figma and its styles
const StatusItem = styled.div<StatusProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 100%;
  max-width: 220px;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color ? props.color : props.theme.marineBlue};
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  padding: 4px;
  box-sizing: border-box;
`;

const FileUploadedStatus = () => (
  <StatusItem backgroundColor={theme.lightGreen}>File uploaded</StatusItem>
);

const UploadingStatus = () => (
  <StatusItem backgroundColor={theme.notification.warnIconBackground}>Uploading</StatusItem>
);

const ErrorStatus = () => (
  <StatusItem backgroundColor={theme.warning} color={theme.white}>Failed - Will not be uploaded</StatusItem>
);

const DuplicateStatus = () => (
  <StatusItem backgroundColor={theme.marineBlue50} color={theme.white}>Duplicate - Will not be uploaded</StatusItem>
);

const TooBigStatus = () => (
  <StatusItem backgroundColor={theme.warning} color={theme.white}>Upload failed - Exceeding 25 MB</StatusItem>
);

const InvalidTypeStatus = () => (
  <StatusItem backgroundColor={theme.warning} color={theme.white}>Upload failed - Unsupported format</StatusItem>
);

export const getStatusIcon = (status: InvoiceUploadStatus) => {
  switch (status) {
    case 'Success': return <FileUploadedStatus />;
    case 'inProgress': return <UploadingStatus />;
    case 'Failed': return <ErrorStatus />;
    case 'Duplicate': return <DuplicateStatus />;
    case 'SizeTooLarge': return <TooBigStatus />;
    case 'InvalidFileType': return <InvalidTypeStatus />;
    default: return <UploadingStatus />;
  }
};
