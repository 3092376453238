import {
  useCallback, useEffect, useState
} from 'react';
import styled, { css } from 'styled-components';
import { cssVariables, useCSSVariableContext } from '@/common/providers/cssVariableProvider';
import { FCWC } from '@/types';

const StickyHeading = styled.div <{ showShadow?: boolean }>`
  position: sticky;
  left: 0;
  right: 0;
  top: 50px;
  background-color: ${props => props.theme.whiteBlue};
  width: ${`calc(100vw - ${cssVariables.scrollbarWidth})`};
  margin: 0px -2px; // small negative margin to hide occasional edge gap
  border-bottom: 1px solid transparent;
  box-sizing: border-box;
  z-index: ${props => props.theme.zIndex.docked};
  transition: box-shadow 0.2s ease-out;
  ${props => props.showShadow && css`
    box-shadow: ${props.theme.boxShadow.header};
    border-bottom-color: ${props.theme.marineBlue40};
  `}
`;

export const StickyPageHeading: FCWC = ({ children }) => {
  const [showShadow, setShowShadow] = useState(false);
  const { setScrollbarWidth, useForceVariableRefresh } = useCSSVariableContext();

  useForceVariableRefresh(setScrollbarWidth);

  const updateShadowDisplay = useCallback(() => {
    if (window.scrollY >= 50 && !showShadow) setShowShadow(true);
    if (window.scrollY < 50 && showShadow) setShowShadow(false);
  }, [showShadow]);

  useEffect(() => {
    window.addEventListener('scroll', updateShadowDisplay);
    return () => window.removeEventListener('scroll', updateShadowDisplay);
  }, [updateShadowDisplay]);

  return (
    <StickyHeading showShadow={showShadow}>
      {children}
    </StickyHeading>
  );
};
