import { useTypedModal } from '@/common/modules/Modal';
import { ButtonGroup } from '@instech/components';
import { FC } from 'react';
import styled from 'styled-components';
import {
  AddSplitButton, ApplyOwnerValuesButton, ApplySurveyorValuesButton, ReorderSplitsButton
} from '../core/ApplyValuesButton';
import { ReorderAdjusterSplitsProps, reorderAdjusterSplitsModal } from './reorderAdjusterSplitsModal';

export const ButtonRow = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;

interface Props {
  onAddSplit: () => void;
  viewAsListOfSplits?: boolean;
  multipleSplits: boolean;
  showApplyValuesSurveyor?: boolean;
  showApplyValuesOwner?: boolean;
  onApplyValues?: () => void;
  applyValuesDisabled?: boolean;
  reorderSplitsModalProps: ReorderAdjusterSplitsProps;
}

export const AdjustersConsiderationButtons: FC<Props> = ({
  onAddSplit,
  viewAsListOfSplits,
  multipleSplits,
  showApplyValuesSurveyor,
  showApplyValuesOwner,
  onApplyValues,
  applyValuesDisabled,
  reorderSplitsModalProps,
}) => {
  const { open: openReorderSplitsModal } = useTypedModal(reorderAdjusterSplitsModal(reorderSplitsModalProps));

  if (!viewAsListOfSplits) return null;
  return (
    <ButtonRow>
      <ButtonGroup>
        {multipleSplits && (
          <ReorderSplitsButton onClick={openReorderSplitsModal} />
        )}
      </ButtonGroup>
      <ButtonGroup alignRight>
        {showApplyValuesSurveyor && <ApplySurveyorValuesButton onApplyValues={onApplyValues} disabled={applyValuesDisabled} />}
        {showApplyValuesOwner && <ApplyOwnerValuesButton onApplyValues={onApplyValues} disabled={applyValuesDisabled} />}
        <AddSplitButton onClick={onAddSplit} />
      </ButtonGroup>
    </ButtonRow>
  );
};
