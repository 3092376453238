import {
  AccessKey, ClaimsHandlerInvoiceSummary, ClaimStatement, Guid, InvoiceOverview
} from '@/types';
import { aggregateOverview } from '@/components/pages/claim/ClaimStatementPage/utils/useAggregatedInvoices';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { getAsync, postJsonAsync } from './client';

export type RedeemRequestStatus = 'error' | 'access' | 'noaccess';

export const useSharedAccessKey = (claimStatementId:Guid) => {
  const [accessKey, setAccessKey] = useState<string|null>(null);
  useEffect(() => {
    const loadNewAccessKey = async () => {
      const accessKeyResponse = await postJsonAsync<AccessKey>(`access/shareforreview/${claimStatementId}`);
      setAccessKey(`${window.location.origin}/shared-for-review/${accessKeyResponse.key}`);
    };

    void loadNewAccessKey();
  }, [claimStatementId]);

  return { accessKey };
};

export const useDownloadAccessKey = (claimStatementId:Guid) => {
  const [accessKey, setAccessKey] = useState<string|null>(null);
  useEffect(() => {
    const loadNewAccessKey = async () => {
      const accessKeyResponse = await postJsonAsync<AccessKey>(`access/download/${claimStatementId}`);
      setAccessKey(`${window.location.origin}/shared-for-download/${accessKeyResponse.key}`);
    };

    void loadNewAccessKey();
  }, [claimStatementId]);

  return { accessKey };
};

export const useRedeemAccessKey = (accessKeyValue?: string) => {
  const [accessKeyStatus, setAccessKeyStatus] = useState<RedeemRequestStatus|null>(null);

  useEffect(() => {
    const redeemAccessKey = async () => {
      if (accessKeyValue) {
        const response = await getAsync(`access/${accessKeyValue}`).catch(exception => exception.response);
        if (response.status === 200) {
          setAccessKeyStatus('access');
        } else if (response.status === 401 || response.status === 403) {
          setAccessKeyStatus('noaccess');
        } else setAccessKeyStatus('error');
      }
    };

    void redeemAccessKey();
  }, [accessKeyValue]);

  return { accessKeyStatus };
};

export const useMagicLinkClaimStatement = (accessKey: string | undefined) => {
  const swrRoute = accessKey ? `shareforreview/${accessKey}/claimstatement` : null;
  return useSWR<ClaimStatement>(swrRoute);
};

const useMagicLinkInvoicesForReview = (accessKey: string | undefined) => {
  const swrRoute = accessKey ? `shareforreview/${accessKey}/invoices` : null;
  return useSWR<InvoiceOverview>(swrRoute);
};

export const useAggregatedMagicLinkInvoicesForReview = (accessKey: string | undefined) => {
  const { data, mutate } = useMagicLinkInvoicesForReview(accessKey);
  const aggregatedOverview = aggregateOverview(data) as InvoiceOverview;
  return { data: aggregatedOverview, mutate };
};

export const useMagicLinkReviewSummary = (accessKey: string | undefined) => {
  const swrRoute = accessKey ? `shareforreview/${accessKey}/summary` : null;
  return useSWR<ClaimsHandlerInvoiceSummary>(swrRoute);
};
