import React, { FunctionComponent, useState } from 'react';
import { InvoiceOverviewSurveyor } from '@/types';
import { theme } from '@/utils/style/theme';
import { FitToContent } from '@/common/layout/FitToContent';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import {
  Pane, ComplexTable, SortedTableHeader
} from '@instech/components';
import { useSortedInvoiceData } from '@/utils/invoice/sortInvoiceData';
import { InvoiceTableHeaders } from './core/InvoiceTableHeaders';
import { InvoiceTableRows } from './core/InvoiceTableRows';
import { InvoiceTableSubheaders } from './core/InvoiceTableSubheaders';
import { useTraversalOrderContext } from '../core/TraversalContext';
import { useStickyTopValues } from './utils';

interface Props {
  title: string;
  data: InvoiceOverviewSurveyor;
  onClick: (invoiceId: string) => void;
}

export const InvoiceTableSurveyorReadonly: FunctionComponent<Props> = ({
  data,
  onClick,
  title
}) => {
  const [sortedHeader, setSortedHeader] = useState<SortedTableHeader>();
  const { setCurrentTraversalOrder } = useTraversalOrderContext();

  const handleSortedHeader = (header: SortedTableHeader) => {
    setSortedHeader(header);
  };

  const sortedTable = useSortedInvoiceData(data, sortedHeader);

  const tableLayout = '64px repeat(11, auto) 64px';
  const stickyTop = useStickyTopValues(false);
  const hasInvoices = data.invoices?.length > 0;

  const handleOnClick = (invoiceId: string) => {
    setCurrentTraversalOrder(sortedTable.data.map(x => x.id), invoiceId);
    onClick(invoiceId);
  };

  // Should not show anything if there are no invoices
  if (!hasInvoices) {
    return null;
  }

  return (
    <FitToContent>
      <Pane title={title} color={theme.marineBlue60} padding="8px 0px 0px" margin="20px 0px 0px">
        <SharedTooltip id="invoice-table-readonly-tooltip">
          <ComplexTable layout={tableLayout}>
            <InvoiceTableSubheaders
              currency={data.currency}
              stickyTop={stickyTop.subheader}
            />
            <InvoiceTableHeaders
              sortedHeader={sortedHeader}
              setSortedHeader={handleSortedHeader}
              allItems={data.invoices.length}
              stickyTop={stickyTop.header}
              selectableRows={false}
              noSurveyorFilter
            />
            <InvoiceTableRows
              data={sortedTable.data}
              onClick={handleOnClick}
              selectableRows={false}
            />
          </ComplexTable>
        </SharedTooltip>
      </Pane>
    </FitToContent>
  );
};
