import { LabelValuePair, RadioButtonsControlled } from '@instech/components';
import React, { FC } from 'react';
import styled from 'styled-components';

const Anchor = styled.div`
  position: relative;
  width: 100%;
  height: 0px;
`;

const Container = styled.div`
  position: absolute;
  top: 4px;
  left: 0;
  border: 1px solid;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.border.gray};
  box-shadow: ${({ theme }) => theme.boxShadow.dropdown};
  padding: 12px 0px; // + padding from radio buttons for 20px padding
  width: max-content;
  min-width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  z-index: 30; // TODO: Should probably be provided by theme
`;

const StyledRadioButtons = styled(RadioButtonsControlled)`
  & label {
    padding: 8px 20px;
    color: ${({ theme }) => theme.marineBlue};
    &:hover {
      background-color: ${({ theme }) => theme.lightBlue};
    }
  }
`;

interface Props {
  name: string;
  options: LabelValuePair[];
  selected: LabelValuePair;
  onChange: (value: string) => void;
}
export const TagDropdown: FC<Props> = ({ name, options, selected, onChange }) => (
  <Anchor>
    <Container>
      <StyledRadioButtons
        name={name}
        options={options.map(x => x.label)}
        selected={selected.label}
        onChange={onChange}
        noLabel
        noErrors />
    </Container>
  </Anchor>
);
