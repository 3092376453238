import { useEffect } from 'react';
import { Invoice, StagedInvoice } from '@/types';

export const useInvoiceUpdates = (stagedInvoices: StagedInvoice[], setStagedInvoices: (invoices: StagedInvoice[]) => void, data?: Invoice[]) =>
  useEffect(() => {
    if (!data) return;

    const updatedInvoices = data.filter(d => stagedInvoices
      .find(s => d.id === s.id && d.uploadStatus !== s.uploadStatus)) as StagedInvoice[];

    if (updatedInvoices.length === 0) {
      return;
    }
    const updatedStagedInvoices = stagedInvoices.reduce((staged, next) => {
      const invoice = updatedInvoices.find(ui => ui.id === next.id);
      return [
        ...staged,
        invoice !== undefined ? invoice : next,
      ];
    }, [] as StagedInvoice[]);

    setStagedInvoices(updatedStagedInvoices);
  }, [data, stagedInvoices, setStagedInvoices]);
