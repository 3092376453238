import { TableCell } from '@instech/components';
import styled from 'styled-components';
import { TableCellHoverStyles } from './core/TableCell/TableCellHoverStyles';

interface CellProps {
  width?: string;
}
/**
 * Wrapper component for TableCells whose contents have a particular action
 * beyond clicking into the table row contents (e.g. to an invoice). Useful
 * for components like checkboxes, context menus, etc.
 */
export const ActionTableCell = styled(TableCell) <CellProps>`
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  cursor: initial;
  box-sizing: border-box;
  width: ${props => props.width};
  ${TableCellHoverStyles}
`;
