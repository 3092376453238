import React, { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import { InfoWithLabel } from './InfoWithLabel';
import { InfoWithLabelProps } from './types';

const StyledInfoWithLabel = styled(InfoWithLabel) <{ error: boolean }>`
  &, & > div {
    color: ${props => props.error && props.theme.red};
  }
`;

/**
 * InfoWithLabel component that colors text red if provided text value
 * is not valid and would be displayed as a dash. Use together with
 * `<InfoLayout />` to auto-handle the layouting out of these boxes.
 */
export const InfoWithLabelRequired: FC<InfoWithLabelProps> = ({ label, text, multiline, className }) => (
  <StyledInfoWithLabel
    className={className}
    multiline={multiline}
    label={label}
    text={text}
    error={!text}
  />
);
