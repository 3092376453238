import { FunctionComponent as FC } from 'react';
import { InvoiceRecord, InvoiceState } from '@/types';
import { useCurrentRole } from '@/common/authentication/CurrentRoleContext';
import { invoiceStates, roles } from '@/utils/constants';
import { isInvoiceFilledState, isInvoicePartialState } from '@/utils/invoice/sortInvoiceData';
import {
  Review,
  ReturnedFromClaimsHandler,
  CostAllocation,
  CostReallocation,
  AdjustersConsideration,
  Finished,
  NewFilled,
  NewPartial,
  NewEmpty,
  ReturnToUploader
} from './StateIcons';

const getNewState = (record?: InvoiceRecord, size?: string) => {
  if (isInvoiceFilledState(record)) {
    return <NewFilled size={size} />;
  }
  if (isInvoicePartialState(record)) {
    return <NewPartial size={size} />;
  }
  return <NewEmpty size={size} />;
};

/**
 * Returns a JSX.Element showing an invoice state corresponding with provided `state`.
 * Useful for showing in an overview of invoice details, where the invoice currently is.
 *
 * Default icon size is 22px, set by the underlying style components.
 */
interface InvoiceStateProps {
  state: InvoiceState;
  record?: InvoiceRecord;
  size?: string;
  onInvoicePage?: boolean;
}
export const InvoiceStateBadge: FC<InvoiceStateProps> = ({ state, record, size, onInvoicePage }) => {
  const currentRole = useCurrentRole();
  const isOwner = currentRole?.name === roles.Owner;
  const isBroker = currentRole?.name === roles.Broker;

  switch (state) {
    case invoiceStates.New:
      return getNewState(record, size);

    case invoiceStates.Review:
      return <Review size={size} />;

    case invoiceStates.ReturnToUploader:
    case invoiceStates.ReturnToUploaderAfterConsideration:
    case invoiceStates.ReturnToUploaderAfterSharedReview:
    case invoiceStates.ReturnToUploaderAfterConsiderationNoAllocation:
      if (isOwner || isBroker) {
        return <ReturnedFromClaimsHandler size={size} variant={onInvoicePage} />;
      }
      return <ReturnToUploader size={size} variant={onInvoicePage} />;

    case invoiceStates.CostAllocation:
      return <CostAllocation size={size} />;

    case invoiceStates.CostReallocation:
      return <CostReallocation size={size} />;

    case invoiceStates.SharedReview:
    case invoiceStates.AdjustersConsideration:
    case invoiceStates.AdjustersConsiderationNoAllocation:
      return <AdjustersConsideration size={size} />;

    case invoiceStates.Finished:
    case invoiceStates.FinishedNoAllocation:
    case invoiceStates.FinishedSharedReview:
      return <Finished size={size} />;

    default:
      return null;
  }
};
