import { Cover } from '@/apps/Adjustment/types/adjustmentCalculations';
import * as Yup from 'yup';
import { datepickerSingle } from '@/utils/validationSchemas';

export const createInitialValues = (cover: Cover) => {
  const periodFrom = cover.periodFrom.current;
  const periodTo = cover.periodTo.current;
  const insuredValue = cover.insuredValue.current;

  return {
    periodFrom,
    periodTo,
    insuredValue: insuredValue?.amount ?? null
  };
};

export const policyFormValidationSchema = Yup.object({
  periodFrom: datepickerSingle(),
  periodTo: datepickerSingle(),
});
