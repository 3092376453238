import React, { FunctionComponent } from 'react';
import {
  ApplicationRole, ClaimStatement, Invoice, InvoiceState
} from '@/types';
import { useCurrentRole } from '@/common/authentication/CurrentRoleContext';
import { ReadonlyCollapsiblePane } from '@/common/components/Pane/ReadonlyCollapsiblePane';
import { useTypedModal } from '@/common/modules/Modal';
import { invoiceStates, roles } from '@/utils/constants';
import { OwnersClaimRead } from './OwnersClaimRead';
import { ownersClaimEditModal } from './OwnersClaimEdit';

const claimsHandlerEditable: InvoiceState[] = [
  invoiceStates.Review,
  invoiceStates.SharedReview,
  invoiceStates.AdjustersConsideration,
  invoiceStates.AdjustersConsiderationNoAllocation
];
const surveyorEditable: InvoiceState[] = [
  invoiceStates.CostAllocation,
  invoiceStates.CostReallocation
];

const checkUserEditMode = (
  useEditButton?: boolean,
  userRole?: ApplicationRole | null,
  state?: InvoiceState
) => {
  if (!useEditButton || !userRole || !state) return false;
  if (userRole.name === roles.ClaimsHandler) return claimsHandlerEditable.includes(state);
  if (userRole.name === roles.Surveyor) return surveyorEditable.includes(state);
  return false;
};

interface Props {
  claimStatement: ClaimStatement;
  invoice: Invoice;
  policyCurrency: string;
  showNotes?: boolean;
  // defining these as separate checks in case of regression issues
  useEditButton?: boolean;
  readonly: boolean;
}
export const OwnersClaimPane: FunctionComponent<Props> = ({
  claimStatement,
  invoice,
  policyCurrency,
  showNotes,
  useEditButton,
  readonly
}) => {
  const userRole = useCurrentRole();
  const canEdit = checkUserEditMode(useEditButton, userRole, invoice.state) && !readonly;

  const { open: openOwnersClaimEditModal } = useTypedModal(ownersClaimEditModal({
    claimStatement, invoice, userRole, showNotes
  }));

  return (
    <ReadonlyCollapsiblePane title="Owner's Claim">
      <OwnersClaimRead
        invoice={invoice}
        policyCurrency={policyCurrency}
        onToggleEdit={openOwnersClaimEditModal}
        showEditButton={canEdit}
        showNotes={showNotes}
      />
    </ReadonlyCollapsiblePane>
  );
};
