import { FunctionComponent as FC } from 'react';
import { tableCellVariant } from '@/common/components/Table/types';
import { collapseGroups } from '@/common/components/Table/utils';
import { TableCellComment } from '@/common/components/Table/core/TableCell';
import { InvoiceState, SurveyorsConsideration } from '@/types';
import { invoiceStatesFinished } from '@/utils/invoice/invoiceStates';
import { invoiceStates } from '@/utils/constants';
import { useRowProps } from '@/common/components/Table/core/TableRow';
import { TableCell } from '@instech/components';
import { SurveyorMessageSwitch } from './Components';

const getSurveyorsComment = (data?: SurveyorsConsideration) => {
  if (!data?.splits || data?.splits.length === 1) {
    return data?.surveyorsComment;
  }
  const noValidComments = data?.splits.map(split => split.surveyorsComment).every(comment => !comment);
  if (noValidComments) return '-';
  return 'Multiple';
};

const consideredStates: InvoiceState[] = [
  invoiceStates.AdjustersConsideration,
  invoiceStates.ReturnToUploaderAfterConsideration,
  invoiceStates.Finished
];

interface Props {
  data?: SurveyorsConsideration;
  state: InvoiceState;
}

/**
 * Switch handler for deciding what to output to the Surveyor cell(s) on the
 * Claims Handler table.
 */
export const PrintSurveyorCells: FC<Props> = ({ data, state }) => {
  // Destructure props of possibly undefined object as destructured value or undefined.
  const { isConsidered } = { ...data };

  const showConsideredView = consideredStates.includes(state);
  const surveyorsComment = getSurveyorsComment(data);
  const isFinished = invoiceStatesFinished.includes(state);

  const { tooltipId, showFullComments } = useRowProps();

  // If the data record is null or not considered display default values
  if (!data || !isConsidered || !showConsideredView) {
    return (
      <TableCell
        data-collapse-group={collapseGroups.surveyor}
        data-cell-variant={tableCellVariant.secondary}
        data-collapse-border
        data-is-finished={isFinished}
        span="1">
        <SurveyorMessageSwitch state={state} />
      </TableCell>
    );
  }

  return (
    <TableCellComment
      data-collapse-group={collapseGroups.surveyor}
      data-cell-variant={tableCellVariant.secondary}
      data-collapse-border
      data-is-finished={isFinished}
      comment={surveyorsComment}
      showFullComment={showFullComments}
      tooltipId={tooltipId} />
  );
};
