import { FunctionComponent as FC } from 'react';
import { ClaimSearchData, UserInfo } from '@/types';
import { useTypedModal } from '@/common/modules/Modal';
import { selectUsersModal } from '@/components/pages/claim/core/SelectUsersModal/SelectUsersModal';
import { SelectMultiUsersPane } from './core/SelectMultiUsersPane';

interface Props {
  selected?: UserInfo[];
  role: string;
  title: string;
  allowEmptySelection: boolean;
  claimSearchData?: ClaimSearchData;
  onSelect: (users: UserInfo[]) => Promise<void>;
  showUpdateNotificationWarning: boolean;
}
export const SelectUserPane: FC<Props> = ({ selected, role, claimSearchData, onSelect, showUpdateNotificationWarning, title, allowEmptySelection }) => {
  const { open: openModal } = useTypedModal(selectUsersModal({
    fetchUsersOptions: { role, claimSearchData, limitRoleAndCompany: false },
    onSelect: user => onSelect(user),
    selectedUsers: selected,
    allowEmptySelection,
    showUpdateNotificationWarning
  }));

  return (
    <SelectMultiUsersPane title={title} selected={selected} openModal={openModal} margin="0px 0px 0px" />
  );
};
