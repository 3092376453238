import { CellContext } from '@tanstack/react-table';
import { FC } from 'react';
import { TableMenu } from '../core/ContextMenu/TableMenu';
import { BaseButtonCell } from './BaseButtonCell';

interface Props {
  data: CellContext<any, any>;
  menuOptions: any[];
}
export const TableButtonMenu: FC<Props> = ({ data, menuOptions }) => {
  const { id } = data.cell;
  return (
    <BaseButtonCell data={data}>
      <TableMenu
        id={`tableMenu-${id}`}
        data-testid={`context-menu-${id}`}
        options={menuOptions}
        closeOnScroll />
    </BaseButtonCell>
  );
};
