import { FunctionComponent as FC } from 'react';
import { IntrinsicSpanProps } from '@/types';
import { Eye } from '@instech/icons';
import { TableCellMaxContent } from './TableCellMaxContent';

interface Props extends IntrinsicSpanProps {
  sharedForReview: boolean;
}
export const TableCellReview: FC<Props> = ({ sharedForReview, ...props }) => (
  <TableCellMaxContent {...props}>
    <span>{sharedForReview ? <Eye /> : null}</span>
  </TableCellMaxContent>
);
