import { useClaimStatementInvoicesForReview } from '@/services/claimStatementServices';
import {
  useClaimsHandlerInvoicesOverview,
  useSurveyorEditableInvoicesOverview,
  useSurveyorReadonlyInvoicesOverview,
  useSurveyorSubmittedInvoicesOverview
} from '@/services/invoice/invoiceServices';
import {
  AdjustersConsideration,
  Invoice, InvoiceOverview,
  InvoiceOverviewSurveyor,
  SurveyorsConsideration
} from '@/types';

const singleSurveyorsCommentOrEmpty = (consideration?: SurveyorsConsideration): SurveyorsConsideration | undefined => {
  if (!consideration) return undefined;
  const comments = consideration?.splits?.filter(x => x.surveyorsComment).map(x => x.surveyorsComment);

  return {
    ...consideration,
    surveyorsComment: comments?.length === 1 ? comments[0] : ''
  };
};

const singleAdjustersCommentOrEmpty = (consideration: AdjustersConsideration): AdjustersConsideration => {
  const comments = consideration?.splits?.filter(x => x.adjustersComment).map(x => x.adjustersComment);

  return {
    ...consideration,
    adjustersComment: comments?.length === 1 ? comments[0] : ''
  };
};

const aggregateInvoices = (invoices: Invoice[]): Invoice[] =>
  invoices.map(x => ({
    ...x,
    surveyorsConsideration: singleSurveyorsCommentOrEmpty(x.surveyorsConsideration),
    adjustersConsideration: singleAdjustersCommentOrEmpty(x.adjustersConsideration)
  }));

export const aggregateOverview = (overview: { invoices: Invoice[] } | undefined) => {
  if (!overview) return null;

  const invoices = aggregateInvoices(overview?.invoices);
  return {
    ...overview,
    invoices
  };
};

export const useAggregatedSurveyorEditableInvoicesOverview = (claimStatementId: string | undefined) => {
  const { data, mutate } = useSurveyorEditableInvoicesOverview(claimStatementId);
  const aggregatedOverview = aggregateOverview(data) as InvoiceOverviewSurveyor;
  return { data: aggregatedOverview, mutate };
};

export const useAggregatedSurveyorReadonlyInvoicesOverview = (claimStatementId: string | undefined) => {
  const { data, mutate } = useSurveyorReadonlyInvoicesOverview(claimStatementId);
  const aggregatedOverview = aggregateOverview(data) as InvoiceOverviewSurveyor;
  return { data: aggregatedOverview, mutate };
};

export const useAggregatedSurveyorSubmittedInvoicesOverview = (claimStatementId: string | undefined) => {
  const { data, mutate } = useSurveyorSubmittedInvoicesOverview(claimStatementId);
  const aggregatedOverview = aggregateOverview(data) as InvoiceOverviewSurveyor;
  return { data: aggregatedOverview, mutate };
};

export const useAggregatedClaimsHandlerInvoicesOverview = (claimStatementId: string | undefined) => {
  const { data, mutate } = useClaimsHandlerInvoicesOverview(claimStatementId);
  const aggregatedOverview = aggregateOverview(data) as InvoiceOverview;
  return { data: aggregatedOverview, mutate };
};

export const useAggregatedClaimsHandlerInvoicesForReview = (claimStatementId: string | undefined) => {
  const { data, mutate } = useClaimStatementInvoicesForReview(claimStatementId);
  const aggregatedOverview = aggregateOverview(data) as InvoiceOverview;
  return { data: aggregatedOverview, mutate };
};
