import { theme } from '@/utils/style/theme';
import { InfoCircle } from '@instech/icons';
import { FC } from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  & svg {
    display: block;
    height: 38px;
    width: 38px;
  }
`;

const InformHeader = styled.h4`
  font-size: 18px;
  margin: 0px;
  color: ${p => p.theme.marineBlue};
`;

const InformContent = styled.span`
  font-size: 16px;
  color: ${p => p.theme.marineBlue};
`;

const TabPane = styled.div`
  display: flex;
  flex-direction: row;
  border-left: 4px solid ${p => p.theme.marineBlue};
  background: #003C7140;
  gap: 14px;
  padding: 12px;
`;

const InformWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const UserNotificationsInfobox: FC = () => (
  <TabPane>
    <IconWrapper>
      <InfoCircle color={theme.marineBlue} />
    </IconWrapper>
    <InformWrapper>
      <InformHeader>Automatic email and portal notifications</InformHeader>
      <InformContent>
        Users you give access to for the first time will automatically receive notifications.
      </InformContent>
    </InformWrapper>
  </TabPane>
);
