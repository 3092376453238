import React from 'react';
import styled, { css } from 'styled-components';
import { ChevronLeft } from '@instech/icons';

const Container = styled.div<{ collapsed?: boolean; }>`
  display: flex;
  align-items: flex-start;
  height: 100%;
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.marineBlue};
  ${props => !props.collapsed && css`
    padding-left: 10px;
    & svg {
      color: ${props.theme.marineBlue60};
      padding-right: 4px;
    }
  `}
  ${props => props.collapsed && css`
    position: absolute;
    top: 0px;
    z-index: 10 !important;
  `}
`;

const ContentAlign = styled.div<{ collapsed?: boolean; }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 25px;
  ${props => props.collapsed && css`
    writing-mode: vertical-rl;
    width: 32px;
    height: 80px;
    background-color: ${props.theme.white};
  `}
`;

interface Props {
  title: string;
  collapsed?: boolean;
  onClick: (groupName: string) => any;
}
export const CollapseButton = ({ title, collapsed, onClick }: Props) => (
  <Container collapsed={collapsed} data-collapse-border>
    <ContentAlign
      collapsed={collapsed}
      onClick={() => onClick(title)}
      data-testid={`collapse-button-${title}`}>
      {!collapsed && <ChevronLeft />}
      {title}
    </ContentAlign>
  </Container>
);
