/* eslint-disable react/no-unknown-property */
import {
  LinkButton, ScreenReaderOnly, useCloseOnClickOutside
} from '@instech/components';
import { MenuDots } from '@instech/icons';
import {
  FunctionComponent as FC, useCallback, useEffect, useRef, useState
} from 'react';
import styled from 'styled-components';
import { Menu } from './core/Menu';
import { TableMenuProps } from './types';

const Anchor = styled.div<{ size: string; }>`
  position: relative;
  height: ${props => props.size};
`;

const getXPosition = (element: HTMLDivElement | null) => (
  (element === null) ? 0 : element.getBoundingClientRect().left
);

const getYPosition = (element: HTMLDivElement | null) => (
  (element === null) ? 0 : element.getBoundingClientRect().bottom
);

/**
 * ### Context Menu component
 *
 * The menu must be rendered with an icon (defaults to MenuDots).
 * It can not display text, but you can supply a text for screen readers.
 * Icons used should be imported from [@instech/icons](https://github.com/InsuranceTechnologySolutions/its-js-icons)
 * as a JSX.Element.
 *
 * Note: Top-level element in the component is an anchor div, but the typing for
 * the component is that of a button; as the button element that opens the context
 * menu is the most central part of the component.
 */
export const TableMenu: FC<TableMenuProps> = ({
  id,
  size = '42px',
  options,
  color,
  screenReaderTitle = 'More options',
  closeOnScroll = false,
  ...props
}) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [showing, setShowing] = useState(false);

  useCloseOnClickOutside(showing, setShowing, anchorRef);

  const handleHideMenu = useCallback((_: Event) => {
    setShowing(false);
  }, [setShowing]);

  useEffect(() => {
    if (!showing || !closeOnScroll) {
      return () => null;
    }
    document.addEventListener('scroll', handleHideMenu);
    window.addEventListener('resize', handleHideMenu);
    return () => {
      document.removeEventListener('scroll', handleHideMenu);
      window.removeEventListener('resize', handleHideMenu);
    };
  }, [showing, setShowing, closeOnScroll, handleHideMenu]);

  return (
    <Anchor key={id} ref={anchorRef} size={size}>
      <LinkButton
        startIcon={<MenuDots />}
        color={color}
        height={size}
        onClick={() => setShowing(!showing)}
        srTextOnly
        data-testid="context-menu"
        {...props}>
        <ScreenReaderOnly>{screenReaderTitle}</ScreenReaderOnly>
      </LinkButton>
      {showing && (
        <Menu
          id={id}
          setShowing={setShowing}
          options={options}
          parentXPosition={getXPosition(anchorRef.current)}
          parentYPosition={getYPosition(anchorRef.current)}
        />
      )}
    </Anchor>
  );
};
