import { Guid, InvoiceState } from '@/types';
import { Cache } from 'swr';
import { postAsync } from '../client';
import { apiRouteInvoice } from './utils/invoiceApiRoute';

interface UpdateInvoiceStateRequest {
  destinationState: InvoiceState;
  invoiceIdList: Guid[];
  instructions?: string;
}

const invoiceStateUrl = (claimStatementId: Guid) => `/claimstatements/${claimStatementId}/invoicestates`;

/**
 * Take a provided set of Invoices and change them to have a provided set of InvoiceStates.
 * While doing this, also go through the set of invoices and purge any SWR keys holding their
 * data, as these keys will be stale with the state update.
 */
export const updateInvoiceState = async (
  claimStatementId: Guid,
  request: UpdateInvoiceStateRequest,
  cache: Cache<any>
) => {
  const result = await postAsync(invoiceStateUrl(claimStatementId), request);
  request.invoiceIdList.forEach(invoiceId => {
    const route = apiRouteInvoice(claimStatementId, invoiceId);
    cache.delete(route);
  });
  return result;
};
