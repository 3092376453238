import { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import { CommentAndNotes } from '@/components/pages/invoice/core/ReadOnlyInvoice/core/CommentAndNotes';
import { Invoice } from '@/types';
import { ClaimInfoRead } from './ClaimInfoRead';
import { ToggleEditButton } from './ToggleEditButton';

const Flex = styled.div`
  display: flex;
`;

interface Props {
  invoice: Invoice;
  policyCurrency: string;
  onToggleEdit: () => void;
  showEditButton: boolean;
  showNotes?: boolean;
}
export const OwnersClaimRead: FC<Props> = ({
  invoice,
  policyCurrency,
  onToggleEdit,
  showEditButton,
  showNotes
}) => (
  <div>
    <Flex>
      <ClaimInfoRead record={invoice.record} policyCurrency={policyCurrency} />
      {showEditButton && <ToggleEditButton onClick={onToggleEdit} />}
    </Flex>
    <CommentAndNotes
      comment={invoice.record.ownersComment}
      commentLabel="Cost Description"
      notes={invoice.record.notes}
      showNotes={showNotes}
      showBorder
    />
  </div>
);
