import styled from 'styled-components';

export const PageGrid = styled.div`
  display: grid;
  grid-template-columns: auto 420px;
  grid-template-areas:
    "vessel owner"
    "occurrence participant"
    "subgrid participant";
  column-gap: 48px;
  row-gap: 40px;
`;

export const GridPlacement = styled.div<{ area?: string }>`
  grid-area: ${props => props.area};
`;
