import React from 'react';
import {
  Formik as ActualFormik, FormikValues, FormikConfig, FormikProps
} from 'formik';
import { PromptStateHandler } from './core/PromptStateHandler';

// Determine if the children of <ActualFormik /> are ReactNodes
// or a formikBag function, and return accordingly
const funcOrReactNode = (children: any, formikBag: FormikProps<any>) => {
  if (typeof children === 'function') {
    return (children as (bag: FormikProps<any>) => React.ReactNode)(formikBag as FormikProps<any>);
  }
  return children;
};

interface CusomConfig<T> extends FormikConfig<T> {
  formId?: string;
}
/**
 * Formik wrapper that adds a prompt if the user tries to exit the
 * form with unsaved changes.
 *
 * @param formId - Used by the Prompt to detect if there are unsaved changes
 * on the page. Optional if there is just one form on the page, required
 * if there are multiple forms on the page
 */
export const FormikWithPrompt = <T extends FormikValues = FormikValues>(
  { children, formId, ...restProps }: CusomConfig<T>
) => (
  <ActualFormik {...restProps}>
    {formikBag => (
      <PromptStateHandler formId={formId} dirty={formikBag.dirty}>
        {funcOrReactNode(children, formikBag)}
      </PromptStateHandler>
    )}
  </ActualFormik>
); // eslint-disable-line indent -- false-positive lint
