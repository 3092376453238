import { numberOrUndefined } from '@/utils/validationSchemas';
import * as Yup from 'yup';

const updateAdjustersConsiderationSplitRequestSchema = Yup.object().shape({
  commonExpenses: numberOrUndefined(),
  commonExpensesTag: Yup.string(),
  ownersWorkConsideration: numberOrUndefined(),
  ownersWorkConsiderationTag: Yup.string(),
  particularAverage: numberOrUndefined(),
  particularAverageTag: Yup.string(),
  generalAverage: numberOrUndefined(),
  isCommonExpensesConsidered: Yup.bool(),
  isOwnersWorkConsiderationConsidered: Yup.bool(),
  isGeneralAverageConsidered: Yup.bool(),
  isParticularAverageConsidered: Yup.bool(),
  category: Yup.string(),
  repairPeriod: Yup.string(),
  occurrenceId: Yup.string(),
  adjustersComment: Yup.string(),
  internalNotes: Yup.string(),
  id: Yup.string(),
  surveyorSplitId: Yup.string(),
  isDeleted: Yup.bool(),
});

export interface UpdateAdjustersConsiderationSplitRequest extends Yup.InferType<typeof updateAdjustersConsiderationSplitRequestSchema>{}
export const updateAdjustersConsiderationRequestSchema = Yup.object().shape({
  category: Yup.string(),
  repairPeriod: Yup.string(),
  location: Yup.string(),
  etag: Yup.string(),
  personallyIdentifiableInformation: Yup.string(),
  personallyIdentifiableInformationDescription: Yup.string(),
  splits: Yup.array().of(updateAdjustersConsiderationSplitRequestSchema)
});
