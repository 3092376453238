import styled from 'styled-components';
import { Warning } from '@instech/icons';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: .5rem;
  color: ${props => props.theme.marineBlue};
  background-color: ${props => props.theme.lightFlatRed};
  padding: .5rem 1rem;
  position: relative;
  &:before {
    content: ' ';
    display: inline-block;
    width: 4px;
    height: 100%;
    background-color: ${props => props.theme.red};
    position: absolute;
    left: 0;
  }
`;

export type UploadErrorProps = {
  error: string
}

export const UploadError = ({ error }:UploadErrorProps) => (
  <Container>
    <Warning />
    {error}
  </Container>
);
