import {
  SummaryDataWithTooltip,
  SummaryItemLabel,
  SummaryItemValue
} from '@/common/components/Summary';
import { useSharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { formatOrEmpty, formatWithDigitsOrEmpty } from '@/utils/number';
import styled from 'styled-components';

const Container = styled.div<{ marginTop?: string; variant?: number }>`
  padding: 8px 12px;
  padding-bottom: 16px;
  margin-top: ${props => props.marginTop};
  background-color: ${props => (
    (props.variant === 1 && props.theme.marineBlue10) ||
    (props.variant === 2 && props.theme.marineBlue20) ||
    (props.variant === 3 && props.theme.marineBlue30)
  )};
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.theme.marineBlue};
  margin-bottom: 8px;
`;

const Layout = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto auto;
  grid-column-gap: 20px;
  // hacky hacky but it fakes expected layout for every other grid row 🧙‍♂️
  margin-bottom: -16px;

  @media screen and (max-width: 1700px) {
    grid-template-columns: repeat(2, auto);
  }
`;

const SubgridSummaryItem = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 2;
  margin-bottom: 16px;

  & ${SummaryItemLabel} {
    align-self: flex-end;
  }
  & ${SummaryItemValue} {
    align-self: flex-start;
  }
`;

interface Props {
  readonly title: string;
  readonly data?: SummaryDataWithTooltip[];
  readonly marginTop?: string;
  readonly variant: 1 | 2 | 3;
}
export const SummarySection = ({ title, data, marginTop, variant }: Props) => {
  const { tooltipId } = useSharedTooltip();
  return (
    <Container marginTop={marginTop} variant={variant}>
      <Title>{title}</Title>
      <Layout>
        {data && data.filter(x => !x.hideData).map(item => (
          <SubgridSummaryItem key={item.title}>
            <SummaryItemLabel>{item.title}</SummaryItemLabel>
            <SummaryItemValue
              data-for={item.showTooltip && tooltipId}
              data-tip={formatWithDigitsOrEmpty(item.value)}>
              {item.showRaw ? item.value : formatOrEmpty(item.value)}
            </SummaryItemValue>
          </SubgridSummaryItem>
        ))}
      </Layout>
    </Container>
  );
};
