import React, { FunctionComponent as FC } from 'react';
import { ButtonGroup, ButtonGroupProps } from '@instech/components';

/**
 * Specialized ButtonGroup component that defaults certain properties
 * across all modals, such as having a wider gap between buttons and
 * defaulting to a right-side alignment.
 */
export const ButtonGroupModal: FC<ButtonGroupProps> = ({
  children,
  alignRight = true,
  spaceBetween,
  reverse,
  marginTop,
  buttonGap = '32px'
}) => (
  <ButtonGroup
    alignRight={alignRight}
    spaceBetween={spaceBetween}
    reverse={reverse}
    marginTop={marginTop}
    buttonGap={buttonGap}>
    {children}
  </ButtonGroup>
);
