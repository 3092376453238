import { FC } from 'react';
import { TitleText } from '@/common/components/Text';

export const createVesselTitle = (
  vesselName?: string,
  prefix: string = '',
  isExperimental: boolean = false
): string => {
  const nameString = vesselName || 'VESSEL NAME';
  const testString = isExperimental ? '- TEST' : '';

  if (prefix) {
    return `${prefix} - ${nameString} ${testString}`;
  }
  return `${nameString} ${testString}`;
};

interface Props {
  vesselName?: string;
  prefix?: string;
  isExperimental?: boolean;
}
export const VesselTitle: FC<Props> = ({ vesselName, prefix, isExperimental }) => (
  <TitleText>
    {createVesselTitle(vesselName, prefix, isExperimental)}
  </TitleText>
);
