import { CurrencySelector } from '@/common/components/Form/CurrencySelector/CurrencySelector';
import {
  AdjustersConsiderationSplitForm,
  Invoice, UpdateAdjustersConsiderationForm
} from '@/types';
import { CollapsiblePane, LabelValuePair } from '@instech/components';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { INVOICE_PANE_MARGIN } from '@/utils/style/styleConstants';
import { NoExchangeRateInfo } from '../core/NoExchangeRateInfo';
import { useConsiderationContext } from '../InvoiceForm/utils/ConsiderationProvider';
import { ConsiderationFields } from './ConsiderationFields';
import { multipleCategoriesLabelValuePair, multipleRepairPeriodsLabelValuePair } from '../utils/multipleDropdownValue';
import { useSynchronizedLabelValues } from '../utils/useSynchronizedLabelValues';

interface Props {
  invoice: Invoice;
  policyCurrency?: string | null;
  categoryOptions: LabelValuePair[];
  repairPeriodOptions: LabelValuePair[];
  occurrenceOptions: LabelValuePair[];
  updateSplitsOrder: (splitsIdOrder: AdjustersConsiderationSplitForm[]) => void;
  showGeneralAverage: boolean;
}

export const AdjustersConsiderationPane = ({
  invoice,
  policyCurrency,
  categoryOptions,
  repairPeriodOptions,
  occurrenceOptions,
  updateSplitsOrder,
  showGeneralAverage
}: Props) => {
  const { setConsiderationData } = useConsiderationContext();
  const [selectedCurrency, setCurrency] = useState(invoice.record.currency);
  const { values } = useFormikContext<UpdateAdjustersConsiderationForm>();

  useEffect(() => {
    setConsiderationData(values.splits); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSynchronizedLabelValues<UpdateAdjustersConsiderationForm>(invoice.record.category, multipleCategoriesLabelValuePair, 'category');
  useSynchronizedLabelValues<UpdateAdjustersConsiderationForm>(invoice.record.repairPeriod, multipleRepairPeriodsLabelValuePair, 'repairPeriod');

  const handleOnBlur = (event: any) => {
    setConsiderationData(values.splits);
  };

  // Function passes resetForm because transferInvoice is defined outside form context,
  // but needs to be able to reset the form after calling API. Could be an idea to refactor
  // transferInvoice so it lives inside Formik instead of outside it.
  const handleCurrencyChange = (currency: string) => {
    setCurrency(currency);
  };

  // fields should convert values but there is no exchange rate, so show relevant info message to user
  const noExchangeRateVisible = (policyCurrency === selectedCurrency && !invoice.record.exchangeRate);

  return (
    <CollapsiblePane padding="20px" margin={INVOICE_PANE_MARGIN} title="Adjuster's Consideration" color="green">
      <CurrencySelector
        first={invoice.record.currency}
        second={policyCurrency}
        selected={selectedCurrency}
        setSelected={handleCurrencyChange}
        id="clh-consideration-pane" />
      <NoExchangeRateInfo isVisible={noExchangeRateVisible} />
      <div onBlur={handleOnBlur}>
        <ConsiderationFields
          invoice={invoice}
          selectedCurrency={selectedCurrency}
          categoryOptions={categoryOptions}
          repairPeriodOptions={repairPeriodOptions}
          occurrenceOptions={occurrenceOptions}
          updateSplitsOrder={updateSplitsOrder}
          showGeneralAverage={showGeneralAverage}
        />
      </div>
    </CollapsiblePane>
  );
};
