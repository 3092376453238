// Keyframes for the header scroll animations (scrollY values)
export const HEADER_ANIM_KEYFRAMES = [0, 50];
export const LOGOTEXT_ANIM_KEYFRAMES = [0, 24];
// Start/end values for header height in animation
export const HEADER_HEIGHT_VALUES = [100, 50];
// Start/end values for width and height of logo
export const LOGO_WIDTH_VALUES = [128, 32];
export const LOGO_HEIGHT_VALUES = [48, 32];
// Start/end values for position offset for logo
export const LOGO_TOP_VALUES = [-8, 9];
// Start/end values for text opacity
export const TEXT_ALPHA_VALUES = [1, 0];
