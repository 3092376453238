const getCurrentValue = (
  value: string | number | null | undefined,
  initialValue: string | number | undefined | null,
  isConsidered: boolean | undefined
): number => {
  const valueIsNumeric = value !== undefined && value !== null && !Number.isNaN(Number(value));
  if (valueIsNumeric) {
    return Number(value);
  }

  if (isConsidered) {
    return 0;
  }

  return Number(initialValue ?? 0);
};

export default getCurrentValue;
