import { invoiceStates } from '@/utils/constants';
import { InvoiceState } from '@/types';

// CostAllocation is for Surveyor
const showApplyValuesOwnerStates: InvoiceState[] = [invoiceStates.Review, invoiceStates.SharedReview, invoiceStates.CostAllocation,
  invoiceStates.AdjustersConsiderationNoAllocation];
const showApplyValuesSurveyorStates: InvoiceState[] = [invoiceStates.AdjustersConsideration];

export const getShowApplyValuesOwner = (state: InvoiceState) => showApplyValuesOwnerStates.includes(state);
export const getShowApplyValuesSurveyor = (state: InvoiceState) => showApplyValuesSurveyorStates.includes(state);
