import { useMemo } from 'react';
import { SortOrder, SortedTableHeader } from '@instech/components';
import { CollapsibleRow, CollapsibleTableRowData } from '../types';
import { TableRow, TableRowData } from '../../SortableTable/types';

type SortRow = TableRow | CollapsibleRow;

interface SortDataReturn<T = CollapsibleTableRowData> {
  data: T[];
  header?: SortedTableHeader;
}

// Set of values that should be sorted lower than actual values
const isNonValue = (val: any) => {
  if (val === null || val === undefined || val === '-') return true;
  return false;
};

// Find a 'sortBy' or 'segment[].key' in the row that matches the provided
// sortedHeader.propertyName, and return its corresponding value if it exists
const findParamInRow = (row?: SortRow, propName?: string) => {
  const sortByValue = row?.sortBy?.[propName || ''];
  // only discard actually undefined values, avoid nullish false-positives
  if (sortByValue !== undefined) return sortByValue;

  const foundSegment = row?.segments.find(segment => segment.key === propName);
  if (foundSegment) return foundSegment.value;

  return null;
};

const findSortParams = (itemA?: SortRow, itemB?: SortRow, sortedHeader?: SortedTableHeader) => {
  const paramA = findParamInRow(itemA, sortedHeader?.propertyName);
  const paramB = findParamInRow(itemB, sortedHeader?.propertyName);
  return [paramA, paramB];
};

/**
 * Compare two values and use a given SortOrder to determine
 * how the values should compare to one another in an Array.sort().
 */
export const compareValuesForSorting = (a: any, b: any, dir: SortOrder): number => {
  const sort = dir === SortOrder.Ascending ? 1 : -1;

  // Default output if working with non-values
  if (isNonValue(a) && isNonValue(b)) return sort * 0;
  if (isNonValue(a)) return sort * 1;
  if (isNonValue(b)) return sort * -1;

  // Apply sorting if both values are real
  if (a > b) return sort * 1;
  if (a < b) return sort * -1;
  return sort * 0;
};

/**
 * Sorting function built for the data structure used by the Collapsible Table components.
 * The sort will order the main row elements (does not yet support subrow sorting).
 */
export const sortTableData = <T>(data: T[], sortedHeader?: SortedTableHeader) => {
  if (!data || !sortedHeader) {
    return { data };
  }

  const sortedData = data as CollapsibleTableRowData[];
  const { propertyName, sortOrder } = sortedHeader;

  sortedData.sort((itemA, itemB) => {
    if (propertyName === 'subrows.length') {
      return compareValuesForSorting(itemA.subrows?.length, itemB.subrows?.length, sortOrder);
    }
    const [paramA, paramB] = findSortParams(itemA.row, itemB.row, sortedHeader);
    return compareValuesForSorting(paramA, paramB, sortOrder);
  });

  return { data: sortedData, header: sortedHeader };
};

/**
 * Hook implementation of sorting in a Collapsible table.
 *
 * Use on the output from a TableSchemaReturn together with a SortedTableHeader, and it will
 * automatically handle sorting of the table data as the header key and sort order changes.
 */
export const useSortedCollapsibleTableData = (
  data: CollapsibleTableRowData[],
  sortedHeader?: SortedTableHeader
): SortDataReturn<CollapsibleTableRowData> => useMemo(() => sortTableData(data, sortedHeader), [data, sortedHeader]);

/**
 * Hook implementation of sorting in a regular, sortable table
 *
 * Use on the output from a TableSchemaReturn together with a SortedTableHeader, and it will
 * automatically handle sorting of the table data as the header key and sort order changes.
 *
 * TODO: It feels double-up to make separate hooks here but, so this should be refactored some time
 * It feels very convoluted to wrangle the type of `data` to support both variants of TableRowData,
 * both collapsible and not, while still having type safety on the output. 🥲
 */
export const useSortedTableData = (
  data: TableRowData[],
  sortedHeader?: SortedTableHeader
): SortDataReturn<TableRowData> => useMemo(() => sortTableData(data, sortedHeader), [data, sortedHeader]);
