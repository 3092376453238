import React, { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import { TruncatingText } from '@instech/components';
import { tagText } from '@/utils/tags';
import { InfoWithLabelAndTagProps } from './types';
import { StyledTag } from '../Tags';
import { SmallLabel } from './Components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const MultilineValue = styled.span`
  font-size: 16px;
  line-height: 14px;
  white-space: pre-wrap;
`;

const TruncatingValue = styled(TruncatingText)`
  font-size: 16px;
  line-height: 14px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  text: string;
  hideTag: boolean;
  tooltipId?: string;
  tag?: string;
}

const SingleLineWithTag: FC<Props> = ({ text, hideTag, tooltipId, tag }) => (
  <Wrapper>
    <TruncatingValue text={text} wrap />
    {!hideTag && (
      <StyledTag
        data-for={tooltipId}
        data-tip={tag}
        data-testid="readonly-InfoWithLabel-tag"
        $filled />
    )}
  </Wrapper>
);

/**
 * Renders a piece of text with a label above it. Use together with
 * `<InfoLayout />` to auto-handle the layouting out of these boxes.
 */
export const InfoWithLabelAndTag: FC<InfoWithLabelAndTagProps> = ({ label, text, multiline, tag, tooltipId }) => {
  const textString = text?.toString() || '-';
  const hideTag = !tag;
  const tagToShow = tagText(tag);
  return (
    <Container>
      {label && <SmallLabel nowrap>{label}</SmallLabel>}
      {multiline
        ? <MultilineValue>{textString}</MultilineValue>
        : <SingleLineWithTag text={textString} hideTag={hideTag} tooltipId={tooltipId} tag={tagToShow} />}
    </Container>
  );
};
