import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useSharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { InvoiceOverviewSurveyor } from '@/types';
import { formatOrEmpty, formatWithDigitsOrEmpty } from '@/utils/number';

const Container = styled.div<{ showButtons?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 0px;
  color: ${props => props.theme.marineBlue};
  padding: 12px 0 20px 40px;
  // if there are buttons, the buttons handle the spacing here
  padding-bottom: ${props => props.showButtons && '12px'};
`;

const SummaryItem = styled.div`
  min-width: 150px;
  padding-right: 72px;
`;

const ItemLabel = styled.div`
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 16px;
`;

const ItemText = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

interface Props {
  data: InvoiceOverviewSurveyor;
  showButtons?: boolean;
}
export const InvoiceSummary: FunctionComponent<Props> = ({ data, showButtons }) => {
  const { tooltipId } = useSharedTooltip();
  return (
    <Container showButtons={showButtons}>
      <SummaryItem>
        <ItemLabel>Invoices</ItemLabel>
        <ItemText>{data.totalInvoices || '-'}</ItemText>
      </SummaryItem>
      <SummaryItem>
        <ItemLabel>General Expenses</ItemLabel>
        <ItemText data-for={tooltipId} data-tip={formatWithDigitsOrEmpty(data.surveyorTotalGeneralExpenses, true)}>
          {formatOrEmpty(data.surveyorTotalGeneralExpenses)}
        </ItemText>
      </SummaryItem>
      <SummaryItem>
        <ItemLabel>Claim</ItemLabel>
        <ItemText data-for={tooltipId} data-tip={formatWithDigitsOrEmpty(data.surveyorTotalClaim, true)}>
          {formatOrEmpty(data.surveyorTotalClaim)}
        </ItemText>
      </SummaryItem>
      <SummaryItem>
        <ItemLabel>Owner</ItemLabel>
        <ItemText data-for={tooltipId} data-tip={formatWithDigitsOrEmpty(data.surveyorTotalOwnersWork, true)}>
          {formatOrEmpty(data.surveyorTotalOwnersWork)}
        </ItemText>
      </SummaryItem>
      <SummaryItem>
        <ItemLabel>Adjuster to Consider</ItemLabel>
        <ItemText data-for={tooltipId} data-tip={formatWithDigitsOrEmpty(data.surveyorAdjustorToConsider, true)}>
          {formatOrEmpty(data.surveyorAdjustorToConsider)}
        </ItemText>
      </SummaryItem>
    </Container>
  );
};
