import { useModalContext } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import { ButtonGroup, SlimButton } from '@instech/components';
import styled from 'styled-components';

const RegularText = styled.div`
  text-align: left;
  color: ${props => props.theme.marineBlue};
  margin-top: 21px;
`;

interface Props {
  markOwnersWorkComplete: () => void;
}

const ModalContent = ({ markOwnersWorkComplete }: Props) => {
  const { close } = useModalContext();

  const handleClick = () => {
    markOwnersWorkComplete();
    close();
  };

  return (
    <div>
      <RegularText>
        Marking the Claim Statement as Complete means that you consider that you are finished with uploading
        and submitting invoices and the Claims Handler will receive a notification.
      </RegularText>
      <RegularText>
        You will still be able to upload and submit new invoices but will then have to reconfirm that the claim statement is complete.
      </RegularText>
      <ButtonGroup alignRight marginTop="24px">
        <SlimButton variant="secondary" onClick={close}>
          CANCEL
        </SlimButton>
        <SlimButton onClick={handleClick}>
          CONTINUE
        </SlimButton>
      </ButtonGroup>
    </div>
  );
};

export const markOwnersWorkCompleteModal = (
  { markOwnersWorkComplete }: Props
): ModalType<Props> => ({
  component: ModalContent,
  options: {
    title: 'Mark Claim Statement as Complete',
    size: 'small',
    padding: '12px 20px 20px'
  },
  args: { markOwnersWorkComplete }
});
