import { InvoiceRequestStatus } from '@/services/invoice/invoiceServices';
import { Invoice, UpdateInvoiceForm } from '@/types';
import { useCallback } from 'react';

/**
 * A memoized function that saves the invoice.
 *
 * The `updateInvoice` function is passed to `<InvoiceFormOwner />` as a function argument and not as a prop.
 * This makes React think it will never change, but it does, and causes the Linter to give off a warning
 * that the prop is redundant. By taking it out of its context, the `updateInvoice` function can live
 * happily in the dependency array.
 */
export const useSaveInvoice = (updateInvoice: (updateInvoiceForm: UpdateInvoiceForm) => Promise<Invoice | InvoiceRequestStatus>, values: UpdateInvoiceForm) =>
  useCallback(() => updateInvoice(values), [values, updateInvoice]);
