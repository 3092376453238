import React from 'react';
import styled from 'styled-components';

const PreloadCalibriRegular = styled.span`
  font-family: 'CalibriWeb';
`;

const PreloadCalibriBold = styled(PreloadCalibriRegular)`
  font-weight: bold;
`;

const PreloadCalibriItalic = styled(PreloadCalibriRegular)`
  font-weight: italic;
`;

const PreloadCalibriLight = styled.div`
  font-family: 'CalibriWeb Light';
`;

export const PreloadFonts = () => (
  <div>
    <PreloadCalibriRegular />
    <PreloadCalibriBold />
    <PreloadCalibriItalic />
    <PreloadCalibriLight />
  </div>
);
