import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { InfoWithLabel } from '@/common/components/Text/InfoWithLabel';
import { textOrDash } from '@/utils/text';

const StyledInfoWithLabel = styled(InfoWithLabel)`
  padding-top: 20px;
  & span {
    font-family: 'CalibriWeb Light';
    color: ${props => props.theme.marineBlue};
    white-space: pre-wrap;
  }
`;

const Wrapper = styled.div<{ showBorder?: boolean }>`
  width: 100%;
  ${props => !props.showBorder && css`
    margin-top: 16px;
  `}
  ${props => props.showBorder && css`
    border-top: 1px solid ${props.theme.marineBlue60};
    margin-top: 16px;
  `}
`;

interface CommentAndNotesProps {
  comment?: string | null;
  commentLabel: string;
  notes?: string | null;
  showNotes?: boolean;
  showBorder?: boolean;
}
export const CommentAndNotes: FunctionComponent<CommentAndNotesProps> = ({
  comment,
  commentLabel,
  notes,
  showNotes,
  showBorder
}) => {
  const notesLabel = 'Internal Notes - Only visible for users with your role';

  return (
    <Wrapper showBorder={showBorder}>
      <StyledInfoWithLabel label={commentLabel} text={textOrDash(comment)} />
      {showNotes && (
        <StyledInfoWithLabel label={notesLabel} text={textOrDash(notes)} />
      )}
      <div id="claims-handler-owner-return-button" />
    </Wrapper>
  );
};
