export const autosaveStatus = {
  saved: 'saved',
  pending: 'pending'
} as const;

export type AutosaveStatus = keyof typeof autosaveStatus;

export interface AutosaveStartedEvent { }
export interface AutosaveFinishedEvent { }

declare global {
  interface GlobalEventHandlersEventMap {
    autosaveStarted: CustomEvent<AutosaveStartedEvent>;
    autosaveFinished: CustomEvent<AutosaveFinishedEvent>;
  }
}
