import { GroupingOption } from '@/common/components/Table/TableGrouping/types';
import { ManagedTableSelectState } from '@/common/components/Table/TanstackTable/hooks/useManagedTableSelect';
import { ClaimStatementSettings, InvoiceOverview } from '@/types';
import { FC } from 'react';
import { InvoiceTableCHByCategory } from './TableViewByCategory';
import { InvoiceTableCHNoGrouping } from './TableViewNoGrouping';

interface Props {
  data: InvoiceOverview;
  settings?: ClaimStatementSettings;
  tableSelectState: ManagedTableSelectState;
  onRowClick: (invoiceId: string, invoiceIdList: string[]) => void;
  selectedGrouping: GroupingOption;
  showFullComments?: boolean;
}
/**
 * Component for showing a particular version of the Invoice Table depending
 * on the user's selected options.
 */
export const InvoiceTableViews: FC<Props> = ({
  data,
  settings,
  tableSelectState,
  onRowClick,
  selectedGrouping,
  showFullComments
}) => {
  if (selectedGrouping === 'No group') {
    return (
      <InvoiceTableCHNoGrouping
        data={data}
        claimStatementSettings={settings}
        tableSelectState={tableSelectState}
        onRowClick={onRowClick}
        showFullComments={showFullComments} />
    );
  }
  if (selectedGrouping === 'Category') {
    return (
      <InvoiceTableCHByCategory
        data={data}
        claimStatementSettings={settings}
        tableSelectState={tableSelectState}
        onRowClick={onRowClick}
        showFullComments={showFullComments} />
    );
  }
  return null;
};
