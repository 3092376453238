import { CheckboxCell } from '@/common/components/Table/CheckboxCell';
import { ExpandableRowButton } from '@/common/components/Table/ExpandableRowButton';
import {
  TableRowWithColor, RowPropsProvider, RowNavigationHandler
} from '@/common/components/Table/core/TableRow';
import { useSharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import {
  Loader, TableCell, TableRow
} from '@instech/components';
import React, { FunctionComponent as FC, useMemo } from 'react';
import { arrayPartition } from '@/utils/array';
import { ExpandableRowWrapper } from '../ExpandableTableRow';
import { CollapsibleTableSubrow } from './CollapsibleTableSubrow';
import {
  CollapsibleRow, CollapsibleRowSegment, CollapsibleTableRowData
} from './types';
import { TableRowCell } from '../core/TableRowCell';

const createCellProps = (row: CollapsibleRow, segment: CollapsibleRowSegment) => {
  const mapKey = `${row.id}-${segment.key}`;
  const props = {
    'data-collapse-group': segment.group,
    'data-cell-variant': segment.variant,
    'data-collapse-border': segment.border,
    'data-is-finished': row.isFinished,
    'right': segment.align === 'right',
    'underline': segment.underline
  };
  return { mapKey, props };
};

interface TableRowProps {
  rowData: CollapsibleTableRowData;
  even: boolean;
  selectedRows: string[];
  onSelect: (rowId: string) => void;
  minSubrowsToExpand: number;
  selectableRows?: boolean;
  expandableRows?: boolean;
  canDrillDown?: boolean
  showFullComments?: boolean;
  // TODO: Cleanup orphaned types?
  expandAllSplits?: boolean;
  isSplitVisible?: boolean;
}
/**
 * Refactoring the row component out to its own component above the row
 * mapping, for figuring out some variables per row before returning.
 */
const CollapsibleTableRow: FC<TableRowProps> = ({
  rowData,
  even,
  selectedRows,
  onSelect,
  selectableRows,
  expandableRows,
  minSubrowsToExpand,
  canDrillDown,
  showFullComments
}) => {
  const { tooltipId } = useSharedTooltip();
  const { row, subrows } = rowData;

  const subrowCount = subrows?.length || 0;
  const rowCanExpand = subrowCount >= minSubrowsToExpand;

  const rowIsSelected = useMemo(() => selectedRows.includes(row.id), [selectedRows, row.id]);
  const [beforeSubrowColumn, afterSubrowColumn] = arrayPartition(row.segments, elem => !!elem.beforeSubrows);

  return (
    <RowPropsProvider
      tooltipId={tooltipId}
      showFullComments={showFullComments}>
      <TableRowWithColor even={even} isActive={canDrillDown}>
        <RowNavigationHandler onClick={row.onClick}>
          {beforeSubrowColumn.map(segment => {
            const { mapKey, props } = createCellProps(row, segment);
            return <TableRowCell key={mapKey} segment={segment} {...props} />;
          })}
        </RowNavigationHandler>
        {selectableRows && (
          <CheckboxCell
            data-is-finished={row.isFinished}
            rowId={row.id}
            selected={rowIsSelected}
            onClick={() => onSelect(row.id)}
            selectableRows={selectableRows}
          />
        )}
        {expandableRows && (
          <ExpandableRowButton
            rowId={row.id}
            count={subrowCount}
            isVisible={rowCanExpand}
            isToggled={row.expanded}
            onClick={row.toggleExpanded}
            data-is-finished={row.isFinished}
          />
        )}
        <RowNavigationHandler onClick={row.onClick}>
          {afterSubrowColumn.map(segment => {
            const { mapKey, props } = createCellProps(row, segment);
            return <TableRowCell key={mapKey} segment={segment} {...props} />;
          })}
        </RowNavigationHandler>
      </TableRowWithColor>
      {(rowCanExpand && subrows) && (
        <ExpandableRowWrapper isExpanded={row.expanded}>
          {subrows.map((subrow, index) => (
            <CollapsibleTableSubrow
              key={subrow.id}
              subrow={subrow}
              even={index % 2 === 0}
              selectableRows={selectableRows}
              showFullComments={showFullComments}
            />
          ))}
        </ExpandableRowWrapper>
      )}
    </RowPropsProvider>
  );
};

interface TableRowsProps {
  tableRows: CollapsibleTableRowData[];
  selectedRows?: string[];
  onSelect?: (rowId: string) => void;
  minSubrowsToExpand?: number;
  selectableRows?: boolean;
  expandableRows?: boolean;
  canDrillDown?: boolean;
  showFullComments?: boolean;
}
export const CollapsibleTableRows: FC<TableRowsProps> = ({
  tableRows,
  selectedRows = [],
  onSelect = () => null,
  selectableRows,
  expandableRows,
  minSubrowsToExpand = 1,
  canDrillDown,
  showFullComments
}) => {
  // TODO: Imagine this shouldn't be a "span 21" any longer
  if (!tableRows) {
    return (
      <TableRow even>
        <TableCell span="21"><Loader size="medium" /></TableCell>
      </TableRow>
    );
  }
  return (
    <>
      {tableRows.map((rowData, i) => (
        <CollapsibleTableRow
          key={rowData.row.id}
          even={i % 2 === 0}
          canDrillDown={canDrillDown}
          rowData={rowData}
          selectedRows={selectedRows}
          onSelect={onSelect}
          selectableRows={selectableRows}
          expandableRows={expandableRows}
          minSubrowsToExpand={minSubrowsToExpand}
          showFullComments={showFullComments}
        />
      ))}
    </>
  );
};
