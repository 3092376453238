import { theme } from '@/utils/style/theme';
import styled, { css } from 'styled-components';
import { tableCellVariant } from '../../types';

type ColorVariants = 'first' | 'second' | 'third'

// TODO: Update function to also return Empty palette
const getColorPalette = (variant: ColorVariants, finished: boolean = false) => {
  const colorSet = theme.table[variant];
  if (finished) {
    return ({ oddColor: colorSet.greenOdd, evenColor: colorSet.greenEven });
  }
  return ({ oddColor: colorSet.odd, evenColor: colorSet.even });
};

const getVariantCellColors = (
  variant: ColorVariants,
  even: boolean = false,
  finished: boolean = false
) => {
  const { oddColor, evenColor } = getColorPalette(variant, finished);
  return css`${even ? evenColor : oddColor}`;
};

const getVariantHoverColors = (
  variant: ColorVariants,
  even: boolean = false,
  finished: boolean = false
) => {
  const { oddColor, evenColor } = getColorPalette(variant, finished);
  const color = even ? evenColor : oddColor;
  return css`${`color-mix(in oklab, ${color}, ${theme.marineBlue} 10%)`}`;
};

interface TableRowProps {
  isEven?: boolean;
  isFaded?: boolean;
  isBold?: boolean;
  isFinished?: boolean;
}
/**
 * (odd/even of &) in this component applies :nth-child styles to only the row
 * components, while not applying the same styles to any accompanying subrows.
 */
export const StyledTableRow = styled.tr<TableRowProps>`
  cursor: pointer;
  & td {
    background-color: ${props => getVariantCellColors('first', props.isEven, props.isFinished)};
    font-weight: ${props => props.isBold && 'bold'};
    font-style: ${props => props.isFaded && 'italic'};
    color: ${props => props.isFaded && 'rgb(102, 138, 170)'};
  }
  &:hover > td {
    background-color: ${props => getVariantHoverColors('first', props.isEven, props.isFinished)};
  }

  & td[data-cell-variant="${tableCellVariant.secondary}"] {
    background-color: ${props => getVariantCellColors('second', props.isEven, props.isFinished)};
  }
  &:hover > td[data-cell-variant="${tableCellVariant.secondary}"] {
    background-color: ${props => getVariantHoverColors('second', props.isEven, props.isFinished)};
  }

  & td[data-cell-variant="${tableCellVariant.tertiary}"] {
    background-color: ${props => getVariantCellColors('third', props.isEven, props.isFinished)};
  }
  &:hover > td[data-cell-variant="${tableCellVariant.tertiary}"] {
    background-color: ${props => getVariantHoverColors('third', props.isEven, props.isFinished)};
  }
`;

interface TableSubrowProps {
  isEven?: boolean;
  isFirstRow: boolean;
  isLastRow: boolean;
}
export const TableSubrow = styled.tr<TableSubrowProps>`
  position: relative;
  ${props => css`
    & > td {
      background-color: ${props.isEven ? props.theme.marineBlue20 : props.theme.marineBlue10};
      &[data-cell-variant="${tableCellVariant.secondary}"],
      &[data-cell-variant="${tableCellVariant.tertiary}"] {
        background-color: ${props.isEven ? props.theme.marineBlue30 : props.theme.marineBlue20};
      }
    }
    &:hover > td {
      background-color: ${props.theme.marineBlue30};
      &[data-cell-variant="${tableCellVariant.secondary}"],
      &[data-cell-variant="${tableCellVariant.tertiary}"] {
        background-color: ${props.theme.marineBlue40};
      }
    }
  `}
  ${props => props.isFirstRow && css`
    & > td:first-child:before {
      display: block;
      position: absolute;
      content: '';
      z-index: 1;
      height: 20px;
      width: 100%;
      top: 0px;
      left: 0px;
      right: 0px;
      background: linear-gradient(180deg, rgba(0, 30, 60, 0.13) 0%, rgba(0, 30, 60, 0) 100%);
    }
  `}
  ${props => props.isLastRow && css`
    & > td:first-child:after {
      display: block;
      position: absolute;
      content: '';
      z-index: 1;
      height: 20px;
      width: 100%;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background: linear-gradient(0deg, rgba(0, 30, 60, 0.13) 0%, rgba(0, 30, 60, 0) 100%);
    }
  `}
`;
