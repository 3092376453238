import { PageContent } from '@/common/layout/Page';
import { useClaimStatement, useIsClosed } from '@/services/claimStatementServices';
import {
  useInvoiceLink,
  useInvoiceSharedForReview
} from '@/services/invoice/invoiceServices';
import { PageLoader } from '@instech/components';
import { useParams } from 'react-router';
import { useCurrentRole } from '@/common/authentication/CurrentRoleContext';
import { InvoiceDisplay } from '../core/EditInvoice/InvoiceDisplay/InvoiceDisplay';
import { ReviewInvoiceHeader } from './InvoiceHeader/ReviewInvoiceHeader';
import { InvoicePageLayout } from './core/Components';
import { InvoiceDetailsReview } from './core/InvoiceDetailsReview';

export const ReviewInvoicePage = () => {
  const currentRole = useCurrentRole();
  const { claimStatementId, invoiceId } = useParams();
  const { data: invoice } = useInvoiceSharedForReview(claimStatementId, invoiceId);
  const { data: claimStatement } = useClaimStatement(claimStatementId);
  const { data: imageData } = useInvoiceLink(claimStatementId, invoiceId);
  const isClosed = useIsClosed(claimStatement);

  if (!invoice || !currentRole || !claimStatement) {
    return <PageLoader />;
  }

  return (
    <div>
      <ReviewInvoiceHeader
        invoice={invoice}
        claimStatement={claimStatement} />
      <PageContent>
        <InvoicePageLayout>
          <InvoiceDetailsReview
            // flush and re-render if invoice changes
            key={invoice.id}
            invoice={invoice}
            claimStatement={claimStatement}
            isClosed={isClosed}
            currentRole={currentRole}
          />
          <InvoiceDisplay invoice={invoice} ownsLock imageUrl={imageData?.url} isClaimStatementClosed={isClosed} />
        </InvoicePageLayout>
      </PageContent>
    </div>
  );
};
