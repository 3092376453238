import { FC } from 'react';
import styled from 'styled-components';
import { SavingIndicator } from '@/common/components/Autosave';
import { HeadingWrapper, StickyPageHeading } from '@/common/layout/PageLayout';
import { Breadcrumbs } from '@/common/components/Breadcrumbs';

const breadcrumbSteps = [{ label: 'My claims dashboard', to: '/' }];

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Box = styled.div<{ justify: 'start' | 'center' | 'end' }>`
  justify-self: ${props => props.justify};
  align-self: center;
`;

export const AdjustmentOverview: FC = () => (
  <StickyPageHeading>
    <HeadingWrapper>
      <Layout>
        <Box justify="start">
          <Breadcrumbs
            steps={breadcrumbSteps}
            current="VESSEL NAME" />
        </Box>
        <Box justify="end">
          <SavingIndicator />
        </Box>
      </Layout>
    </HeadingWrapper>
  </StickyPageHeading>
);
