import { SlimButton } from '@instech/components';
import React, {
  DragEventHandler, FunctionComponent, MouseEventHandler, useState
} from 'react';
import styled, { css } from 'styled-components';

interface HoverProp {
  isHovering: boolean;
}
const DropzoneArea = styled.label<HoverProp>`
  display: flex;
  justify-content: center;
  background-color: ${props => props.isHovering ? props.theme.white : props.theme.marineBlue10};
  color: ${props => props.theme.marineBlue};
  text-align: center;
  padding: 56px 24px;
  border: 2px dashed ${props => props.isHovering ? props.theme.marineBlue : props.theme.marineBlue60};
  border-radius: 5px;
  transition: background-color 0.1s ease-in;
  // pointer event on child element causes pointer event on parent, which results in
  // this prop constantly toggling and causing the styles t oflicker. So when user is
  // hovering a file on the drop area, all children must ignore pointer events
  ${props => props.isHovering && css`
    & > * { pointer-events: none; }
  `}
`;

const Content = styled.div`
  width: 600px;
`;

const HeaderText = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 40px;
`;

const SubTextMain = styled.div`
  color: ${props => props.theme.marineBlue60};
  margin-bottom: 24px;
  font-weight: bold;
`;

const DividerWithText = styled.div`
  display: flex;
  flex-direction: row;
  :before, :after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: 10px;
  }
  font-weight: bold;
  margin: 40px 70px;
`;

const UploadButton = styled(SlimButton)`
  width: 200px;
  text-transform: uppercase;
  font-weight: bold;
`;

interface DropzoneProps {
  id: string;
  handleUpload: (files: File[]) => void;
  instructions?: string | JSX.Element;
  acceptedTypes: string[];
  acceptedTypesDisplay?: JSX.Element;
  multiple?: boolean;
  title?: string;
}

export const FileDropzone: FunctionComponent<DropzoneProps> = ({
  id,
  handleUpload,
  instructions,
  acceptedTypes,
  acceptedTypesDisplay,
  multiple,
  title,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const onClick: MouseEventHandler = event => {
    event.stopPropagation();
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.click();
    }
  };

  const handleChange = (event: any) => {
    const selected = Array.from(event.target.files) as File[];
    setIsHovering(false);
    handleUpload(selected);
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = '';
    }
  };

  const startHover: DragEventHandler<HTMLSpanElement> = event => {
    event.stopPropagation();
    event.preventDefault();
    if (!isHovering) {
      setIsHovering(true);
    }
  };

  const endHover: DragEventHandler<HTMLSpanElement> = event => {
    event.stopPropagation();
    event.preventDefault();
    setIsHovering(false);
    if (event.type === 'drop') {
      const files = Array.from(event.dataTransfer.files);
      handleUpload(files);
      if (hiddenFileInput.current) {
        hiddenFileInput.current.value = '';
      }
    }
  };
  const headerText = title ?? (multiple ? 'Drop your files here' : 'Drop your file here');

  const uploadButtonText = multiple ? 'Select file(s)' : 'Select file';
  return (
    <DropzoneArea
      htmlFor={id}
      onDragEnter={startHover}
      onDragOver={startHover}
      onDragEnd={endHover}
      onDragLeave={endHover}
      onDrop={endHover}
      onClick={event => event.preventDefault()}
      isHovering={isHovering}
    >
      <Content>
        <HeaderText>{headerText}</HeaderText>
        {instructions && <SubTextMain>{instructions}</SubTextMain>}
        {acceptedTypesDisplay}
        <DividerWithText>or</DividerWithText>
        <UploadButton onClick={onClick}>{uploadButtonText}</UploadButton>
      </Content>
      <input
        id={id}
        type="file"
        multiple={multiple}
        accept={acceptedTypes.join(', ')}
        ref={hiddenFileInput}
        onChange={handleChange}
        onClick={onClick}
        hidden
      />
    </DropzoneArea>
  );
};
