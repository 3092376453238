import * as yup from 'yup';
import { FileMetadata } from '@/types';
import { ValidExtension } from './validExtensions';
import { arrayLastItem } from '../array';

export type FileValidationError = 'InvalidFileType' | 'SizeTooLarge';

export const invoiceFileTypes: ValidExtension[] = [
  '.pdf',
  '.jpg',
  '.jpeg',
  '.jfif',
  '.png',
];

export const adjustedClaimFileTypes: ValidExtension[] = [
  '.pdf',
];

export const imageFileTypes: ValidExtension[] = [
  '.jpg',
  '.jpeg',
  '.jfif',
  '.png',
];

export const supportingDocumentFileTypes: ValidExtension[] = [
  '.pdf',
  '.doc',
  '.docx',
  '.docm',
  '.ppt',
  '.pptx',
  '.xls',
  '.xlsx',
  '.xlsb',
  '.xlsm',
  '.csv',
  '.txt',
  '.rtf',
  '.jpg',
  '.jpeg',
  '.jfif',
  '.png',
  '.bmp',
  '.gif',
  '.jfif',
  '.tiff',
  '.tif',
  '.zip',
  '.htm',
  '.html',
  '.ics',
  '.msg',
  '.eml',
];

/**
 * Extrapolate the metadata of a File and return it without
 * the file blob itself
 */
export const getFileMetadata = (file: File): FileMetadata => {
  const fileType = arrayLastItem(file.name.split('.'));
  const extension = fileType ? `.${fileType}` : '';

  return ({
    name: file.name,
    extension,
    size: file.size,
    lastModified: file.lastModified
  });
};

const fileValidationSchema = (validExtensions: string[], maxSize: number = 1024 * 1024 * 25) => (
  yup.mixed()
    .test('file-type', 'InvalidFileType', (file: File) => {
      const { extension } = getFileMetadata(file);
      return validExtensions.includes(extension.toLowerCase());
    })
    .test('too-large', 'SizeTooLarge', (file: File) => (
      file.size <= maxSize
    ))
);

export const validateFiletypeAndSize = (file: File, validExtensions: string[], maxSize?: number): FileValidationError | undefined => {
  const validationSchema = fileValidationSchema(validExtensions, maxSize);
  try {
    validationSchema.validateSync(file);
  } catch (error: any) {
    if (error.name === 'ValidationError') {
      return (error as yup.ValidationError).message as FileValidationError;
    }
  }
  return undefined;
};
