import { InternalNotes } from '@/apps/Adjustment/components/InternalNotes';
import { FC } from 'react';
import styled from 'styled-components';
import { UpdateAdjustmentOverviewRequest } from '@/apps/Adjustment/types';
import { useAdjustmentOverview } from '@/apps/Adjustment/services/adjustmentOverviewService';
import { useRequireClaimStatementId } from '@/utils/useRequireClaimStatementId';
import { Loader } from '@instech/components';
import { HelpText } from './core/HelpText';
import { SummaryOfFactsAndDatesPane } from './core/SummaryOfFactsAndDatesPane';

const Layout = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 32px;
  box-sizing: border-box;
`;

interface Props { }
export const SummaryOfFactsAndDates: FC<Props> = () => {
  const claimStatementId = useRequireClaimStatementId();
  const { data: adjustmentOverview, mutate, updateAdjustmentOverview } = useAdjustmentOverview(claimStatementId);

  if (!adjustmentOverview) {
    return <Loader />;
  }

  const handleSave = async (request: UpdateAdjustmentOverviewRequest) => {
    const result = await updateAdjustmentOverview(adjustmentOverview.id, request);
    await mutate(result.data);
  };

  const saveInternalNotes = async (form: { internalNote: string }) => {
    const request = { summaryInternalNote: { value: form.internalNote } };
    await handleSave(request);
  };

  return (
    <Layout>
      <div>
        <SummaryOfFactsAndDatesPane
          summary={adjustmentOverview.summaryOfFactsAndDates.summary}
          save={handleSave} />
        <InternalNotes
          fieldName="internalNote"
          value={adjustmentOverview.summaryOfFactsAndDates.internalNote}
          onSave={saveInternalNotes} />
      </div>
      <HelpText />
    </Layout>
  );
};
