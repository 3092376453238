import { SlimButton } from '@instech/components';
import { Download } from '@instech/icons';
import React from 'react';

interface Props {
  invoiceUrl: string;
}

export const InvoiceDownload = ({ invoiceUrl }: Props) => (
  <a href={invoiceUrl}>
    <SlimButton
      variant="secondary"
      height="40px"
      width="181px"
      startIcon={<Download />}>
      DOWNLOAD INVOICE
    </SlimButton>
  </a>
);
