import { clampNumber } from '@/utils/number';
import { useScroll, useTransform } from 'framer-motion';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { FCWC } from '@/types';
import { HEADER_ANIM_KEYFRAMES, HEADER_HEIGHT_VALUES } from '../modules/Header/core/animationConstants';

export const cssVariables = {
  scrollbarWidth: 'var(--scrollbarWidth)',
  headerHeight: 'var(--headerHeight)'
};

interface CSSVariableProps {
  useForceVariableRefresh: (callback: () => void) => void;
  setScrollbarWidth: () => void;
  setHeaderHeight: () => void;
}

const CSSVariableContext = createContext({} as CSSVariableProps);

/**
 * If necessary, force a refresh of a provided CSS variable. This
 * refresh happens after the consuming component is mounted and painted.
 */
const useForceVariableRefresh = (callback: () => void) => useEffect(() => {
  setTimeout(() => callback(), 0);
}, [callback]);

/**
 * Handles the lifecycle of CSS properties that are provided as global CSS variables.
 * Use case is for properties that change during application use, but are consumed
 * by a number of components that should not have to individually to re-render.
 */
export const CSSVariableProvider: FCWC = ({ children }) => {
  const { scrollY } = useScroll();
  const headerHeight = useTransform(scrollY, HEADER_ANIM_KEYFRAMES, HEADER_HEIGHT_VALUES);

  const setScrollbarWidth = useCallback(() => {
    const scrollbarWidth = clampNumber(window.innerWidth - document.documentElement.clientWidth, 0);
    document.documentElement.style.setProperty('--scrollbarWidth', `${scrollbarWidth}px`);
  }, []);

  const setHeaderHeight = useCallback(() => {
    document.documentElement.style.setProperty('--headerHeight', `${headerHeight.get()}px`);
  }, [headerHeight]);

  useEffect(() => {
    setScrollbarWidth(); // Initialize value on first paint
    window.addEventListener('resize', setScrollbarWidth, false);
    const unsubscribeHeight = headerHeight.on('change', setHeaderHeight);
    return () => {
      window.removeEventListener('resize', setScrollbarWidth, false);
      unsubscribeHeight(); // Calling return value of .on() unsubs event
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const values = useMemo(() => ({
    setScrollbarWidth, setHeaderHeight, useForceVariableRefresh
  }), [setHeaderHeight, setScrollbarWidth]);

  return (
    <CSSVariableContext.Provider value={values}>
      {children}
    </CSSVariableContext.Provider>
  );
};

export const useCSSVariableContext = () => useContext<CSSVariableProps>(CSSVariableContext);
