import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { postData } from './getFile';

export type DownloadRequestStatus = 'error' | 'success';

const downloadBlob = (blob: Blob | MediaSource, filename: string) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
};

export const addTimezoneOffset = (uri: string) => {
  const timeZoneOffset = new Date().getTimezoneOffset();
  return `${uri}${uri.indexOf('?') < 0 ? '?' : '&'}timezoneOffset=${timeZoneOffset}`;
};

export const parseBlobData = (response: AxiosResponse, type: string) => {
  const [, headers] = response.headers['content-disposition'].split('filename=');
  let [result,] = headers.split(';');
  if (result.startsWith('"')) result = result.slice(1, -1);
  const returnedFileName = decodeURIComponent(result);

  downloadBlob(new Blob([response.data], { type }), returnedFileName);
};

export const downloadSearchData = async (uri: string, type: string) => {
  const { data: response } = await postData(addTimezoneOffset(uri), null, {
    headers: {
      Accept: type
    },
    responseType: 'blob',
    timeout: 300000
  });

  if (response && response.status === 200) {
    parseBlobData(response, type);
  }
};

export const useDownloadData = (uri: string, type: string) => {
  const [downloadStatus, setDownloadStatus] = useState<DownloadRequestStatus|null>(null);

  const triggerDownload = async () => {
    setDownloadStatus(null);
    const { data: response } = await postData(addTimezoneOffset(uri), null, {
      headers: {
        Accept: type
      },
      responseType: 'blob'
    }).catch(exception => exception.response);
    if (response?.status === 200) {
      parseBlobData(response, type);
      setDownloadStatus('success');
    } else setDownloadStatus('error');
  };

  useEffect(() => {
    void triggerDownload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { downloadStatus, triggerDownload };
};

export const downloadInvoicesData = async (claimStatementId: string, type: string) => {
  const { data: response } = await postData(`ClaimStatementDownload/${claimStatementId}/invoicesdata`, null, {
    headers: {
      Accept: type
    },
    responseType: 'blob'
  }).catch(exception => exception.response);
  if (response?.status === 200) {
    parseBlobData(response, type);
  }
};
