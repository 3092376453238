import { HeaderAdjustedOverlay } from '@/common/layout/PageLayout';
import { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import { MotionAnimProps } from '@/utils/animation';
import { HiddenInPrint } from '@/common/layout/Page';
import { SidebarContents } from './SidebarContents';

const MotionContainer = styled(HeaderAdjustedOverlay)`
  right: 0;
`;

const enterExitAnim: MotionAnimProps = {
  initial: { right: '-300px' },
  animate: { right: '0px' },
  exit: { right: '-300px' },
  transition: { ease: 'easeOut', duration: 0.3 }
};

interface Props {
  isVisible: boolean;
  onClose: () => void;
}
export const SavePdfSidebar: FC<Props> = ({ isVisible, onClose }) => (
  <AnimatePresence>
    {isVisible && (
      <MotionContainer {...enterExitAnim} data-testid="pdf-sidebar-motion-container">
        <HiddenInPrint>
          <SidebarContents isVisible={isVisible} onClose={onClose} />
        </HiddenInPrint>
      </MotionContainer>
    )}
  </AnimatePresence>
);
