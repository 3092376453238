import { UpdateAdjustmentOverviewRequest } from '@/apps/Adjustment/types/adjustmentOverview';
import { CollapsiblePane, RichTextEditor } from '@instech/components';
import { Formik } from 'formik';
import { FC } from 'react';
import styled from 'styled-components';
import { FormWithAutoSave } from '../../../core/Components';

const EditorWrapper = styled.div`
  .editor-inner {
    border: 1px solid ${({ theme }) => theme.marineBlue60};
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 5px 0px;
    min-height: 400px;
  }
`;

const initialValues = (adjustersNote: string | null | undefined) => ({
  adjustersNote: adjustersNote ?? '',
});

interface Props {
  adjustersNote: string | null | undefined;
  save: (request: UpdateAdjustmentOverviewRequest) => Promise<void>;
}
export const AdjustersNotePane: FC<Props> = ({ adjustersNote, save }) => {
  const handleSubmit = async (form: { adjustersNote: string }) => {
    const request = { adjustersNote: { value: form.adjustersNote } };
    await save(request);
  };

  return (
    <CollapsiblePane color="green" title="Adjuster's Note" padding="20px">
      <Formik
        initialValues={initialValues(adjustersNote)}
        onSubmit={handleSubmit}
      >
        <FormWithAutoSave>
          <EditorWrapper>
            <RichTextEditor name="adjustersNote" />
          </EditorWrapper>
        </FormWithAutoSave>
      </Formik>
    </CollapsiblePane>
  );
};
