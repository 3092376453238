import useSWR from 'swr';
import { Guid, ClaimStatementFavourites } from '@/types';
import client from '@/services/client';

const apiRoute = () => '/claimstatementfavourites';
const apiRouteFavourite = (statementId: Guid) => `${apiRoute()}/${statementId}`;
export type FavouritesRequestStatus = 'error' | 'success';

export const useFavourites = () => {
  const { data, mutate, error } = useSWR<ClaimStatementFavourites>(apiRoute());

  const addFavourite = async (statementId: Guid): Promise<FavouritesRequestStatus> => {
    const response = await client.put(apiRouteFavourite(statementId));

    if (response.status === 200) {
      await mutate(response.data);
      return 'success';
    }
    return 'error';
  };

  const deleteFavourite = async (statementId: Guid): Promise<FavouritesRequestStatus> => {
    const response = await client.delete(`${apiRouteFavourite(statementId)}`);
    if (response!.status === 200) {
      await mutate(response.data);
      return 'success';
    }
    return 'error';
  };

  return { data, addFavourite, deleteFavourite, error };
};
