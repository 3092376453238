import { NextArrow, PreviousArrow } from '@/common/layout/PageLayout';
import { nextButtonSuffix, previousButtonSuffix } from '@/components/pages/invoice/BatchInvoicePage/core/batchHeaderTextUtils';
import { SlimButton } from '@instech/components';
import { FC } from 'react';

const dataFor = 'invoice-batch-tooltip';

const tipsSaveGoNext = 'Save and go to next invoice';
const tipsSaveGoPrevious = 'Save and go to previous invoice';

interface ButtonProps {
  onClick?: (event?: any) => any;
}

interface NavigationalButtonProps extends ButtonProps {
  invoiceBatchNumber: number;
  invoiceBatchList: string[];
}

export const GraySkipAllButton: FC<ButtonProps> = ({ onClick }) =>
  <SlimButton variant="secondary" onClick={onClick}>SKIP ALL</SlimButton>;

export const GrayPreviousButton: FC<NavigationalButtonProps> = ({ onClick, invoiceBatchNumber, invoiceBatchList }) => (
  <SlimButton variant="secondary"
    data-tip={tipsSaveGoPrevious}
    data-for={dataFor}
    startIcon={<PreviousArrow />}
    onClick={onClick}
    disabled={invoiceBatchNumber === 1}>
    {`PREVIOUS INVOICE ${previousButtonSuffix(invoiceBatchNumber, invoiceBatchList)}`}
  </SlimButton>
);

export const GrayNextButton: FC<NavigationalButtonProps> = ({ onClick, invoiceBatchNumber, invoiceBatchList }) => (
  <SlimButton data-tip={tipsSaveGoNext} data-for={dataFor} endIcon={<NextArrow />} onClick={onClick}>
    {`NEXT INVOICE ${nextButtonSuffix(invoiceBatchNumber, invoiceBatchList)}`}
  </SlimButton>
);

export const GreenDoneButton = ({ onClick }: ButtonProps) =>
  <SlimButton endIcon={<NextArrow />} onClick={onClick}>DONE</SlimButton>;
