import { PageLoader } from '@instech/components';
import { useRedeemAccessKey } from '@/services/magicLinkService';
import { MagicLinkReviewPage } from '@/components/pages/claim/ClaimStatementPage/MagicLinkReviewPage';
import { useParams } from 'react-router-dom';
import React from 'react';
import { MagicLinkNoAccessView } from '@/components/pages/error/MagicLinkNoAccessView';

export const SharedAccessRouter = () => {
  const { accessKey } = useParams();
  const { accessKeyStatus } = useRedeemAccessKey(accessKey);
  if (accessKeyStatus == null) return <PageLoader />;
  if (accessKeyStatus === 'error' || accessKeyStatus === 'noaccess') return <MagicLinkNoAccessView />;
  return <MagicLinkReviewPage />;
};
