import useSWR from 'swr';
import { getJsonAsync } from './client';

const keyUrl = 'configuration/applicationinsights/key';

export const useApplicationInsightsKey = () => useSWR<string>(keyUrl);

export const getApplicationInsightsKey = async () => {
  const result = await getJsonAsync(keyUrl);
  return result;
};
