import { ApplicationRole } from '@/types';
import { roles } from './constants';

/**
 * Check if provided user role is Owner or Broker in just a single line.
 */
export const checkIfOwnerOrBroker = (currentRole: ApplicationRole) => {
  const roleName = currentRole?.name || '';
  return [roles.Owner, roles.Broker].includes(roleName);
};
