import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.marineBlue};
  margin-bottom: 60px;
`;

export const Header = styled.div`
  height: 100px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.marineBlue10};
  color: ${props => props.theme.marineBlue};
`;

export const Content = styled.div`
  background-color: ${props => props.theme.white};
  height: 100%;
  flex-grow: 1;
  padding: 20px;
`;

export const PlaceholderText = styled.h3`
  text-align: center;
  margin: 32px;
  color: ${props => props.theme.marineBlue60};
`;
