import { InvoiceState } from '@/types';
import { getStateSortingKey } from '@/utils/invoice/sortInvoiceData';

/**
 * Provide as `sortingFn` for a table column showing a <TableCellState /> cell, to
 * translate the state values to a sorting index.
 *
 * TODO: Could do to improve typing, but it is a bit diffuse how to do that but keep it generic.
 * TODO: Need support for Record, passed to getStateSortingKey, to interpret partial new invoices.
 */
export const sortByInvoiceState = (rowA: any, rowB: any, columnId: any): number => {
  const rowAValue = getStateSortingKey(rowA.getValue(columnId) as InvoiceState);
  const rowBValue = getStateSortingKey(rowB.getValue(columnId) as InvoiceState);
  return (
    rowAValue < rowBValue ? -1 : 1
  );
};
