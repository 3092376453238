import {
  AdjustersConsideration,
  AdjustersConsiderationSplit, Invoice, InvoiceState, Nullable, SurveyorsConsideration, SurveyorsConsiderationSplit
} from '@/types';
import { textOrDash } from '@/utils/text';

export type CHInvoiceTableSplit = {
  id: string;
  category: string;
  surveyor: {
    id?: string;
    isConsidered: boolean;
    generalExpenses: string;
    claimConsideration: string;
    ownersWorkConsideration: string;
    adjustersConsideration: string;
    surveyorsComment: string;
  },
  adjuster: {
    surveyorSplitId?: string;
    isDeleted: boolean;
    commonExpenses: string;
    generalAverage: string;
    particularAverage: string;
    ownersWorkConsideration: string;
    adjustersComment: string;
  },
}

export type CHInvoiceTableDataNoGrouping = {
  id: string;
  invoiceNo: string;
  isSharedForReview: boolean;
  state: InvoiceState;
  supplier: string;
  category: string;
  currency: string;
  exchangeRate: Nullable<number>;
  showGeneralAverage?: boolean;
  showReviewComment?: boolean;
  totalAmount: string;
  paidAmount: string;
  owner: {
    claimInPolicyCurrency: string;
    ownersWorkInPolicyCurrency: string;
    ownersComment: string;
  },
  surveyor: {
    isConsidered: boolean;
    generalExpenses: string;
    claimConsideration: string;
    ownersWorkConsideration: string;
    adjusterToConsider: string;
    surveyorsComment: string;
  },
  adjuster: {
    commonExpenses: string;
    generalAverage: string;
    particularAverage: string;
    ownersWorkConsideration: string;
    adjustersComment: string;
  },
  subrowCount: number;
  splits: CHInvoiceTableSplit[];
};

type GroupSplitsReturn = {
  adjuster: AdjustersConsiderationSplit;
  surveyor?: SurveyorsConsiderationSplit;
}[];

const getAdjustersComment = (data?: AdjustersConsideration | null) => {
  if (!data?.splits || data?.splits.length === 1) {
    return textOrDash(data?.adjustersComment);
  }
  const noValidComments = data?.splits.map(split => split.adjustersComment).every(comment => !comment);
  return noValidComments ? '-' : 'Multiple';
};

const getSurveyorsComment = (data?: SurveyorsConsideration) => {
  if (!data?.splits || data?.splits.length === 1) {
    return textOrDash(data?.surveyorsComment);
  }
  const noValidComments = data?.splits.map(split => split.surveyorsComment).every(comment => !comment);
  return noValidComments ? '-' : 'Multiple';
};

/**
 * Groups Surveyor splits together with their corresponding Adjuster split
 */
const groupSurveyorAdjusterSplits = (data: Invoice): GroupSplitsReturn => {
  const { adjustersConsideration, surveyorsConsideration } = data;
  const list: GroupSplitsReturn = [];
  adjustersConsideration.splits.forEach(adjusterSplit => {
    const surveyorSplit = surveyorsConsideration?.splits.find(item => item.id === adjusterSplit.surveyorSplitId);
    list.push({ adjuster: adjusterSplit, surveyor: surveyorSplit });
  });
  return list;
};

/**
 * Using an intermediary function to map Invoice to a table-structured set of data.
 * The table API prefers working with consistent, defined values, so passing over data
 * and addressing potential undefined entries resolves that.
 */
export const getTableDataNoGrouping = (invoices: Invoice[]): CHInvoiceTableDataNoGrouping[] => (
  invoices.map(invoice => ({
    id: invoice.id,
    invoiceNo: `${invoice.record.order} - ${invoice.record.invoiceNumber ?? ''}`,
    isSharedForReview: !!invoice.isSharedForReview,
    state: invoice.state,
    supplier: textOrDash(invoice.record.supplier),
    category: textOrDash(invoice.record.category),
    currency: textOrDash(invoice.record.currency),
    exchangeRate: invoice.record.exchangeRate,
    totalAmount: textOrDash(invoice.record.totalAmount),
    paidAmount: textOrDash(invoice.record.paidAmount),
    owner: {
      claimInPolicyCurrency: textOrDash(invoice.record.claimInPolicyCurrency),
      ownersWorkInPolicyCurrency: textOrDash(invoice.record.ownersWorkInPolicyCurrency),
      ownersComment: textOrDash(invoice.record.ownersComment),
    },
    surveyor: {
      isConsidered: !!invoice.surveyorsConsideration?.isConsidered,
      generalExpenses: textOrDash(invoice.surveyorsConsideration?.generalExpenses),
      claimConsideration: textOrDash(invoice.surveyorsConsideration?.claimConsideration),
      ownersWorkConsideration: textOrDash(invoice.surveyorsConsideration?.ownersWorkConsideration),
      adjusterToConsider: textOrDash(invoice.surveyorsConsideration?.adjusterToConsider),
      surveyorsComment: getSurveyorsComment(invoice.surveyorsConsideration)
    },
    adjuster: {
      commonExpenses: textOrDash(invoice.adjustersConsideration?.commonExpenses),
      generalAverage: textOrDash(invoice.adjustersConsideration.generalAverage),
      particularAverage: textOrDash(invoice.adjustersConsideration?.particularAverage),
      ownersWorkConsideration: textOrDash(invoice.adjustersConsideration?.ownersWorkConsideration),
      adjustersComment: getAdjustersComment(invoice.adjustersConsideration)
    },
    subrowCount: invoice.adjustersConsideration.splits.length,
    splits: groupSurveyorAdjusterSplits(invoice).map(split => ({
      id: split.adjuster.id,
      category: textOrDash(split.adjuster.category),
      surveyor: {
        id: split.surveyor?.id,
        isConsidered: !!split.surveyor?.isConsidered,
        generalExpenses: textOrDash(split.surveyor?.generalExpenses),
        claimConsideration: textOrDash(split.surveyor?.claimConsideration),
        ownersWorkConsideration: textOrDash(split.surveyor?.ownersWorkConsideration),
        adjustersConsideration: textOrDash(split.surveyor?.adjustersConsideration),
        surveyorsComment: textOrDash(split.surveyor?.surveyorsComment),
      },
      adjuster: {
        surveyorSplitId: split.adjuster.surveyorSplitId,
        isDeleted: !!split.adjuster?.isDeleted,
        commonExpenses: textOrDash(split.adjuster?.commonExpenses),
        generalAverage: textOrDash(split.adjuster?.generalAverage),
        particularAverage: textOrDash(split.adjuster?.particularAverage),
        ownersWorkConsideration: textOrDash(split.adjuster?.ownersWorkConsideration),
        adjustersComment: textOrDash(split.adjuster?.adjustersComment),
      }
    }))
  }))
);
