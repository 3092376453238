import styled from 'styled-components';

/**
 * Content wrapper that aligns all elements within to be
 * horizontally centered.
 */
export const ContentHorizontalCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${props => props.theme.marineBlue};
`;
