import React from 'react';
import { useFormikContext } from 'formik';
import { UpdateInvoiceForm } from '@/types';
import { formatNumberWithTwoDecimals, truncateDecimals } from '@/utils/number';
import { getOwnersDeviation } from '@/utils/invoice/invoiceTotalDeviation';
import { convertCurrency } from '@/utils/invoice/convertCurrency';
import { Deviation } from './Deviation';

interface Props {
  selectedCurrency: string;
}
export const DeviationOwner = ({ selectedCurrency }: Props) => {
  const { values } = useFormikContext<UpdateInvoiceForm>();
  const deviation = getOwnersDeviation({
    totalInvoiceAmount: Number(values.totalAmount),
    claim: Number(values.claim),
    ownersWork: Number(values.ownersWork)
  });

  const usingInvoiceCurr = !values.currency?.value || (values.currency?.value === selectedCurrency);
  const deviationInCurrency = Number(usingInvoiceCurr ? deviation : convertCurrency(deviation, values.exchangeRate));

  const truncateDeviation = truncateDecimals(deviationInCurrency, 2);
  const deviationString = formatNumberWithTwoDecimals(truncateDeviation);

  if (deviationInCurrency === 0) {
    return null;
  }

  return <Deviation currency={selectedCurrency} deviation={deviationString} isNumber />;
};
