import { HelpTextLayout } from '@/apps/Adjustment/components/HelpText';
import { CollapsiblePane } from '@instech/components';
import { FC } from 'react';

interface Props {
}
export const GuidePane: FC<Props> = () => (
  <CollapsiblePane color="green" title="Guide for Insurance Conditions" padding="20px">
    <HelpTextLayout>
      <p>Refer to the applicable insurance conditions and the relevant deductible.</p>
      <p>
        Are clauses deleted or special clauses included in the insurance contract?
        If so, refer those that affect the compensation.
      </p>
    </HelpTextLayout>
  </CollapsiblePane>
);
