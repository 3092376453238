import { IntrinsicSpanProps } from '@/types';
import { Eye } from '@instech/icons';
import { CellContext } from '@tanstack/react-table';
import { FunctionComponent as FC } from 'react';
import { BaseTableCell } from './BaseTableCell';
import { getHeaderGroup, getMergedMeta } from '../utils';
import { useRowClick } from '../providers/RowClickContext';

interface Props extends IntrinsicSpanProps {
  data: CellContext<any, any>;
}
/**
 * Renders out a table cell that displays an Eye icon if the provided cell value
 * is truthy.
 *
 * This is just a loose association with the provided value; but need to examine
 * TanStack cell typing more closely to understand how to reinforce specific value
 * types. Or otherwise provide the data separate from the CellContext.
 */
export const TableCellReview: FC<Props> = ({ data }) => {
  const { onRowClick } = useRowClick();
  const mergedMeta = getMergedMeta(data.column);
  const headerGroup = getHeaderGroup(data.column);
  const isSharedForReview = !!data.getValue();
  return (
    <BaseTableCell
      id={data.cell.id}
      headerGroup={headerGroup}
      cellVariant={mergedMeta.tableCellVariant}
      columnBorder={mergedMeta.columnBorder}
      onClick={onRowClick}>
      <span>{isSharedForReview ? <Eye /> : null}</span>
    </BaseTableCell>
  );
};
