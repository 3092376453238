import { useEffect } from 'react';
import { useAccount } from '@/common/authentication/AuthenticationProvider';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { useApplicationInsightsKey } from '@/services/configurationServices';

export const useAppInsights = () => {
  const { isAuthenticated, user } = useAccount();
  const { data: key } = useApplicationInsightsKey();

  useEffect(() => {
    const fetchAppInsightsKey = async () => {
      try {
        const appInsights = new ApplicationInsights({
          config: {
            instrumentationKey: key,
            maxBatchInterval: 30000,
            accountId: user.email,
            enableAutoRouteTracking: true
          }
        });
        appInsights.loadAppInsights();
        const telemetryInitializer = envelope => {
          // eslint-disable-next-line no-param-reassign
          envelope.data.application = 'Insify.Claimstatement.Web';
        };
        appInsights.addTelemetryInitializer(telemetryInitializer);
      } catch (error) {
        // Fail silently
      }
    };

    if (isAuthenticated && key) {
      void fetchAppInsightsKey();
    }
  }, [isAuthenticated, key, user.email]);
};
