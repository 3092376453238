import { Instructions, InvoiceMessages } from '@/components/pages/invoice/InvoicePage/core/Components';
import {
  ClaimStatement, Invoice, LatestInstructions
} from '@/types';
import { FunctionComponent as FC } from 'react';
import { EditFinishedButton } from '../../../core/EditInvoice/InvoiceForm/Headers/buttons/EditFinishedButton';
import {
  AdditionalInformationPane, OwnersClaimPane, SurveyorsConsiderationPane
} from '../../../core/ReadOnlyInvoice';
import { AdjustersConsiderationPane } from '../../../core/ReadOnlyInvoice/AdjustersConsiderationPane';
import { NavigationalActionButtons } from '../NavigationalActionButtons';

interface Props {
  invoice: Invoice;
  claimStatement: ClaimStatement;
  isClaimStatementClosed: boolean;
  surveyorInstructions?: LatestInstructions;
  ownerInstructions?: LatestInstructions;
}

export const ReadonlyInvoiceDetails: FC<Props> = ({
  invoice,
  claimStatement,
  isClaimStatementClosed,
  surveyorInstructions,
  ownerInstructions
}) => (
  <div>
    <NavigationalActionButtons
      anchorId="claims-handler-action-buttons"
      invoice={invoice}
      additionalActionButtons={<EditFinishedButton invoice={invoice} />} />
    <InvoiceMessages isClaimStatementClosed={isClaimStatementClosed} />
    <Instructions instructions={ownerInstructions?.instructions} ownersInstructions />
    <Instructions instructions={surveyorInstructions?.instructions} />
    <OwnersClaimPane
      claimStatement={claimStatement}
      invoice={invoice}
      policyCurrency={claimStatement.currency}
      readonly={isClaimStatementClosed} />
    <AdditionalInformationPane
      invoice={invoice}
      showRepairPeriod
      hasSplit={invoice?.adjustersConsideration?.splits?.length > 1} />
    {invoice?.surveyorsConsideration?.isConsidered && (
      <SurveyorsConsiderationPane
        invoice={invoice}
        startCollapsed />
    )}
    {invoice?.adjustersConsideration?.isConsidered && (
      <AdjustersConsiderationPane
        invoice={invoice} />
    )}
  </div>
);
