import React, {
  useMemo, useRef, useState
} from 'react';
import { useField } from 'formik';
import {
  FieldLabel, FieldLayout, InputField, LabelValuePair, useCloseOnClickOutside, withFormId
} from '@instech/components';
import { getParsedDecimalAsStringWithGivenPrecision } from '@/utils/number';
import { validateLimitedNumberInput } from '@/utils/input';
import styled from 'styled-components';
import { convertCurrency } from '@/utils/invoice/convertCurrency';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { TagButton } from './core/TagButton';
import { TagDropdown } from './core/TagDropdown';

const noneOption = { label: 'None', value: '' };

const createTagOptions = (options: LabelValuePair[]) => ([
  noneOption,
  ...options
]);

const RelativeAnchor = styled.div`
  position: relative;
`;

const HighlightedInput = styled(InputField) <{ highlight?: boolean }>`
  && input {
    position: relative;
    background-color: ${props => props.highlight ? props.theme.lightYellow30 : ''};
    box-shadow: ${props => props.highlight ? `inset 6px 0 0 0 ${props.theme.lightYellow}` : ''};

    &:disabled {
      color: ${props => props.theme.marineBlue60};
      background-color: ${props => props.highlight ? props.theme.marineBlue10 : props.theme.disabled25};
      box-shadow: ${props => props.highlight ? `inset 6px 0 0 0 ${props.theme.marineBlue20}` : ''};
    };
  }
`;

interface CurrencyFieldLimitedWithTagProps {
  name: string;
  tagFieldName: string;
  tagOptions: LabelValuePair[];
  tagTooltipId?: string;
  convertRate?: number | null;
  formId?: string;
  label: string;
  placeholder?: string;
  noLabel?: boolean;
  disabled?: boolean;
  required?: boolean;
  optional?: boolean;
  className?: string;
  emptyWithoutConvertRate?: boolean;
}
export const CurrencyFieldLimitedWithTag = ({
  name,
  label,
  formId,
  tagFieldName,
  tagOptions,
  tagTooltipId,
  convertRate,
  placeholder = '',
  noLabel = false,
  disabled,
  required = false,
  optional = false,
  className,
  emptyWithoutConvertRate = false,
  ...fieldProps
}: CurrencyFieldLimitedWithTagProps) => {
  const [field, meta, helpers] = useField(name);
  const [tagField, , tagHelpers] = useField(tagFieldName);
  const [showDropdown, setShowDropdown] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedTag, setSelectedTag] = useState<LabelValuePair>(tagField.value ?? noneOption);

  const fieldId = withFormId(name, formId);
  const dropdownName = `${name}-tag-dropdown`;
  const showFieldError = meta.touched && meta.error;

  const setValidValue = async (value: string) => {
    const maxLength = 21;
    const { value: validVal, validInput } = validateLimitedNumberInput(value, { maxLength });

    if (validInput) {
      await helpers.setValue(validVal);
    }
  };

  const handleInputBlur: React.FocusEventHandler<HTMLInputElement> = async event => {
    await setValidValue(event.currentTarget.value);

    if (event.target.value === '') {
      setSelectedTag(noneOption);
      await tagHelpers.setValue(noneOption);
    }
  };

  const handleValueChange = async (evt: NumberFormatValues) => {
    if (disabled) return;

    await helpers.setTouched(true);
    await helpers.setValue(evt.floatValue);
  };

  useCloseOnClickOutside(showDropdown, setShowDropdown, anchorRef);

  const [options] = useMemo(() => [createTagOptions(tagOptions)], [tagOptions]);

  const toggleDropdown = () => setShowDropdown(prevVal => !prevVal);

  const handlePaste = async (e: React.ClipboardEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const clipboardData = e.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData('text');
    const normalizedVal = getParsedDecimalAsStringWithGivenPrecision(pastedData);
    await setValidValue(normalizedVal);
  };

  const handleSelectTag = async (newTag: string) => {
    const findTag = options.find(option => option.label === newTag);
    if (findTag) {
      setShowDropdown(false);
      setSelectedTag(findTag);
      await tagHelpers.setValue(findTag);
    }
  };

  // Extract to variable so it can be altered and converted
  let fieldVal = field.value;

  // applies conversion rate to displayed value when disabled
  if (convertRate) {
    fieldVal = convertCurrency(fieldVal, convertRate, true);
  }

  // allows field to show empty when it cannot convert rate
  if (!convertRate && emptyWithoutConvertRate) {
    fieldVal = '';
  }

  return (
    <FieldLayout className={className}>
      {!noLabel && (
        <FieldLabel
          htmlFor={fieldId}
          required={required}
          optional={optional}
          disabled={disabled}
          error={showFieldError}>
          {label}
        </FieldLabel>
      )}
      <RelativeAnchor ref={anchorRef}>
        <HighlightedInput hasEndIcon error={showFieldError}>
          <NumericFormat
            id={fieldId}
            name={name}
            type="text"
            value={fieldVal}
            placeholder={placeholder}
            onBlur={handleInputBlur}
            onPaste={handlePaste}
            disabled={disabled}
            {...fieldProps}
            thousandSeparator
            isAllowed={values =>
              !values.floatValue ||
              (values.floatValue <= Number.MAX_SAFE_INTEGER && values.floatValue >= Number.MIN_SAFE_INTEGER)}
            onValueChange={handleValueChange}
          />
          <TagButton
            onClick={toggleDropdown}
            disabled={disabled}
            selectedTag={selectedTag}
            tagTooltipId={tagTooltipId}
          />
        </HighlightedInput>
        {showDropdown && (
          <TagDropdown
            name={dropdownName}
            options={options}
            selected={selectedTag}
            onChange={handleSelectTag}
          />
        )}
      </RelativeAnchor>
    </FieldLayout>
  );
};
