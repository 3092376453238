import { AvailableColors, CollapsiblePane } from '@instech/components';
import styled from 'styled-components';
import { FCWC } from '@/types';

const StyledCollapsiblePane = styled(CollapsiblePane)`
  & > div:last-child {
    border-top-color: ${props => props.theme.marineBlue60};
    background-color: ${props => props.theme.lightFlatWhite};
  }
`;

const DarkStyledCollapsiblePane = styled(CollapsiblePane)`
  & > div:last-child {
    border-top-color: ${props => props.theme.marineBlue60};
    background-color: ${props => props.theme.marineBlue20};
  }
`;

export interface CollapsiblePaneProps {
  title?: string | JSX.Element;
  icon?: JSX.Element;
  margin?: string;
  padding?: string;
  color?: AvailableColors;
  contentScroll?: boolean;
  startCollapsed?: boolean;
}

/**
 * Specialized collapsible pane component styled to suit a 'readonly' appearance.
 * Used in situations where there is a pane with editable content, which should
 * have an alternate display for when the contents are not editable.
 */
export const ReadonlyCollapsiblePane: FCWC<CollapsiblePaneProps> = (
  { children, ...paneProps }
) => (
  <StyledCollapsiblePane {...paneProps}>
    {children}
  </StyledCollapsiblePane>
);

const LightStyledCollapsiblePane = styled(CollapsiblePane)`
  & > div:last-child {
    border-top-color: ${props => props.theme.marineBlue60};
    background-color: ${props => props.theme.white};
  }
`;

export const LightReadonlyCollapsiblePane: FCWC<CollapsiblePaneProps> = (
  { children, ...paneProps }
) => (
  <LightStyledCollapsiblePane {...paneProps}>
    {children}
  </LightStyledCollapsiblePane>
);

export const DarkReadonlyCollapsiblePane: FCWC<CollapsiblePaneProps> = (
  { children, ...paneProps }
) => (
  <DarkStyledCollapsiblePane {...paneProps}>
    {children}
  </DarkStyledCollapsiblePane>
);
