import { collapseGroups } from '@/common/components/Table/utils';
import { Invoice } from '@/types';
import { CollapsibleRow } from '@/common/components/Table/CollapsibleTable/types';
import { invoiceStatesFinished } from '@/utils/invoice/invoiceStates';
import { getStateSortingKey } from '@/utils/invoice/sortInvoiceData';
import { InvoiceAdjusterCells } from '../../core/InvoiceCells';
import { PrintSurveyorCells } from '../../core/InvoiceCells/PrintSurveyorCells';
import { CSTableProps } from '../useSchemaByTableGrouping';

const getInvoiceNo = (invoice: Invoice) => {
  let value = `${invoice.record.order} - `;
  if (invoice.record.invoiceNumber) {
    value = `${value}${invoice.record.invoiceNumber}`;
  }
  return value;
};

export const tableSchemaForPrintRow = (
  rowData: Invoice,
  props: CSTableProps
): CollapsibleRow => {
  const { record } = rowData;
  const { claimStatementSettings } = props;
  const expanded = props.expanded[rowData.id];

  return ({
    id: rowData.id,
    isFinished: invoiceStatesFinished.includes(rowData.state),
    onClick: () => { },
    toggleExpanded: () => { },
    expanded,
    sortBy: {
      state: getStateSortingKey(rowData.state),
      surveyorGeneralExpenses: rowData.surveyorsConsideration?.generalExpenses,
      surveyorClaimConsideration: rowData.surveyorsConsideration?.claimConsideration,
      surveyorOwnersWorkConsideration: rowData.surveyorsConsideration?.ownersWorkConsideration,
      surveyorAdjusterToConsider: rowData.surveyorsConsideration?.adjusterToConsider,
      surveyorsComment: rowData.surveyorsConsideration?.surveyorsComment,
      adjusterCommonExpenses: rowData.adjustersConsideration.adjustersComment,
      adjusterParticularAverage: rowData.adjustersConsideration.particularAverage,
      adjusterOwnersWorkConsideration: rowData.adjustersConsideration.ownersWorkConsideration,
      adjusterAdjustersComment: rowData.adjustersConsideration.adjustersComment,
    },
    segments: [
      {
        key: 'invoiceNumber',
        cellType: 'text',
        value: getInvoiceNo(rowData)
      },
      {
        key: 'supplier',
        cellType: 'text',
        value: record.supplier,
        group: collapseGroups.particulars,
        border: true
      },
      {
        key: 'category',
        cellType: 'text',
        value: record.category,
        group: collapseGroups.particulars,
      },
      {
        key: 'currency',
        cellType: 'text',
        value: record.currency,
        group: collapseGroups.particulars,
      },
      {
        key: 'totalAmount',
        cellType: 'digitsTooltip',
        value: record.totalAmount,
        group: collapseGroups.particulars,
        align: 'right'
      },
      {
        key: 'paidAmount',
        cellType: 'digitsTooltip',
        value: record.paidAmount,
        group: collapseGroups.particulars,
        align: 'right'
      },
      {
        key: 'ownersComment',
        cellType: 'comment',
        value: record.ownersComment,
        group: collapseGroups.owner,
        border: true
      },
      {
        key: 'surveyorsConsideration',
        cellType: 'custom',
        value: (
          <PrintSurveyorCells data={rowData.surveyorsConsideration} state={rowData.state} />
        )
      },
      {
        key: 'adjustersConsideration',
        cellType: 'custom',
        value: (
          <InvoiceAdjusterCells
            data={rowData.adjustersConsideration}
            exchangeRate={record.exchangeRate}
            showGeneralAverage={claimStatementSettings?.hasGeneralAverage}
            state={rowData.state}
            showAdjusterComment
          />
        )
      }
    ]
  });
};
