import { ButtonGroup, SlimButton } from '@instech/components';
import { RefreshDotted } from '@instech/icons';
import { useFormikContext } from 'formik';
import { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { useTypedModal } from '@/common/modules/Modal';
import {
  defaultReturnToOwnerProps,
  returnClaimsHandlerToOwnerModal
} from '@/components/pages/claim/ClaimStatementPage/InvoiceTable/modal/ReturnClaimsHandlerToOwnerModal';
import { UpdateAdjustersInvoiceResponse } from '@/services/invoice/invoiceServices';
import { updateInvoiceState } from '@/services/invoice/invoiceStateService';
import {
  Invoice,
  InvoiceState,
  UpdateAdjustersConsiderationForm
} from '@/types';
import { invoiceStates } from '@/utils/constants';
import { getInvoiceStateFromCHToOwner } from '@/utils/invoicesTransfer';
import { resetAdjusterFormWithConsidered } from '../utils/resetWithConsidered';
import { useSaveStatusContext } from '../utils/SaveStatus/SaveStatusContext';

const checkCanTransferInvoice = (state: InvoiceState) => (
  state !== invoiceStates.Review &&
  state !== invoiceStates.AdjustersConsideration &&
  state !== invoiceStates.SharedReview &&
  state !== invoiceStates.AdjustersConsiderationNoAllocation
);

interface Props {
  invoice: Invoice;
  updateInvoice: (updateInvoiceForm: UpdateAdjustersConsiderationForm) => Promise<UpdateAdjustersInvoiceResponse>;
}
export const InvoiceButtonReturnToOwner: FC<Props> = ({ invoice, updateInvoice }) => {
  const { values, resetForm } = useFormikContext<UpdateAdjustersConsiderationForm>();
  const { saveStatus, setSaveStatus } = useSaveStatusContext();
  const navigate = useNavigate();
  const { claimStatementId } = useParams();
  const { cache } = useSWRConfig();

  const { open: openReturnModal } = useTypedModal(returnClaimsHandlerToOwnerModal(defaultReturnToOwnerProps));

  const saveInvoice = useCallback(async () => {
    setSaveStatus('saving');
    const result = await updateInvoice(values);
    if (result.status === 'success') {
      resetForm({ values: resetAdjusterFormWithConsidered(values, result.invoice.adjustersConsideration) });
    }
    setSaveStatus('saved');
    return result;
  }, [resetForm, setSaveStatus, updateInvoice, values]);

  const returnInvoiceToOwner = useCallback(async (returnInvoice: Invoice, instructions: string | undefined) => {
    if (claimStatementId === undefined || checkCanTransferInvoice(returnInvoice.state)) {
      return 'failed';
    }

    await updateInvoiceState(claimStatementId, {
      destinationState: getInvoiceStateFromCHToOwner(returnInvoice.state),
      instructions,
      invoiceIdList: [returnInvoice.id]
    }, cache);

    navigate(`/claimstatements/${claimStatementId}`);
    return 'success';
  }, [cache, claimStatementId, navigate]);

  const returnToOwner = async () => {
    // If the invoice needs to be saved before returning, its state will update.
    // So let's cache it, and then swap it with the saveInvoice() result if necessary.
    let invoiceToReturn = invoice;
    if (saveStatus === 'saving') {
      return;
    }
    if (saveStatus === 'unsaved') {
      const result = await saveInvoice();
      invoiceToReturn = result.invoice;
      if (result.status !== 'success') {
        return;
      }
    }
    openReturnModal({
      claimStatementId,
      firstInvoiceId: invoiceToReturn.id,
      numberOfInvoices: 1,
      onReturn: instructions => returnInvoiceToOwner(invoiceToReturn, instructions)
    });
  };

  return (
    <ButtonGroup alignRight>
      <SlimButton startIcon={<RefreshDotted />} variant="secondary" onClick={returnToOwner}>
        RETURN TO OWNER
      </SlimButton>
    </ButtonGroup>
  );
};
