import { SlimButton, SlimButtonProps } from '@instech/components';
import {
  Apply, Reorder, Plus
} from '@instech/icons';
import { FC } from 'react';

interface Props {
  onApplyValues?: () => void;
  disabled?: boolean;
}

interface InternalProps extends Props {
  text: string;
}

const ApplyValuesButton: FC<InternalProps> = ({ onApplyValues, text, disabled }) => (
  <SlimButton variant="quaternary" startIcon={<Apply />} onClick={onApplyValues} disabled={disabled}>{text}</SlimButton>
);

export const ApplyOwnerValuesButton: FC<Props> = ({ onApplyValues, disabled }) => (
  <ApplyValuesButton onApplyValues={onApplyValues} text="APPLY OWNER VALUES" disabled={disabled} />
);

export const ApplySurveyorValuesButton: FC<Props> = ({ onApplyValues, disabled }) => (
  <ApplyValuesButton onApplyValues={onApplyValues} text="APPLY SURVEYOR VALUES" disabled={disabled} />
);

export const ReorderSplitsButton = (props: SlimButtonProps) => (
  <SlimButton startIcon={<Reorder />} variant="tertiary" {...props}>REORDER SPLITS</SlimButton>
);

export const AddSplitButton = (props: SlimButtonProps) => (
  <SlimButton variant="secondary" startIcon={<Plus />} {...props}>ADD SPLIT</SlimButton>
);
