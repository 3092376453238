import { useTypedModal } from '@/common/modules/Modal';
import { InvoiceReplaceRequestStatus } from '@/services/invoice/invoiceServices';
import { invoiceFileTypes } from '@/utils/file';
import { SlimButton } from '@instech/components';
import { RefreshDotted } from '@instech/icons';
import React, { MouseEventHandler } from 'react';
import { replaceInvoiceModal } from '../../modal/ReplaceInvoiceModal';

interface Props {
  replaceInvoice: (invoice: File) => Promise<InvoiceReplaceRequestStatus>;
  refresh: () => void;
  disabled?: boolean;
}
export const InvoiceActionsPane = ({ replaceInvoice, refresh, disabled }: Props) => {
  const hiddenReplaceFileInput = React.useRef<HTMLInputElement>(null);

  const { open: openReplaceModal } = useTypedModal(replaceInvoiceModal({
    replace: () => Promise.reject(new Error('Not implemented')),
    refresh: () => Promise.reject(new Error('Not implemented'))
  }));

  const onClick: MouseEventHandler = event => {
    event.stopPropagation();
    if (hiddenReplaceFileInput.current !== null) {
      hiddenReplaceFileInput.current.click();
    }
  };

  const handleChange = (event: any) => {
    const selected = event.target.files[0] as File;
    openReplaceModal({
      replace: () => replaceInvoice(selected),
      refresh
    });

    if (hiddenReplaceFileInput.current) {
      hiddenReplaceFileInput.current.value = '';
    }
  };

  return (
    <>
      <SlimButton
        variant="secondary"
        height="40px"
        width="228px"
        onClick={onClick}
        disabled={disabled}
        startIcon={<RefreshDotted />}>
        REPLACE INVOICE DOCUMENT
      </SlimButton>
      {!disabled && (
        <input
            type="file"
            accept={invoiceFileTypes.join(', ')}
            onClick={onClick}
            onChange={handleChange}
            hidden
            ref={hiddenReplaceFileInput}
            data-testid="replace-invoice-input"
        />
      )}
    </>
  );
};
