import { Invoice } from '@/types';

export const getDeviatingInvoices = (invoices: Invoice[]) =>
  invoices.filter(x => x.adjustersConsideration?.deviationPresent || !x.adjustersConsideration?.isConsidered);

export const getIncompleteInvoices = (invoices: Invoice[]) =>
  invoices.filter(invoice => !invoice.record.exchangeRate || !invoice.record.paymentDate || !invoice.record.personallyIdentifiableInformation
  || !invoice.record.category || !invoice.record.location);

export const getCompleteInvoices = (invoices: Invoice[], deviatingInvoices: Invoice[], incompleteInvoices: Invoice[]) =>
  invoices.filter(invoice => !incompleteInvoices.includes(invoice) && !deviatingInvoices.includes(invoice));
