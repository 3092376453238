import { useModalContext } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import { ButtonGroup, SlimButton } from '@instech/components';
import { ContentHorizontalCenter } from '@/common/layout/Content';
import { WarningLarge } from '@/common/components/Icon/WarningLarge';
import { BoldText, Description } from './core/Components';

const ModalContent = () => {
  const { close } = useModalContext();
  return (
    <div>
      <ContentHorizontalCenter>
        <WarningLarge />
        <BoldText>One or more invoices have a deviating total sum</BoldText>
        <Description>
          <div>The sum must equal the invoice total amount.</div>
          <div>Please correct before submitting.</div>
        </Description>
      </ContentHorizontalCenter>
      <ButtonGroup alignRight marginTop="40px">
        <SlimButton onClick={close}>CLOSE</SlimButton>
      </ButtonGroup>
    </div>
  );
};

export const deviationFromTotalModal = (): ModalType<{}> => ({
  component: ModalContent,
  options: {
    title: 'Deviating from Total Amount',
    size: 'small',
    padding: '12px 20px 20px'
  }
});
