import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { TextArea } from '@instech/components';

const Instructions = styled(TextArea)`
  & div > textarea {
    min-height: 140px;
  } 
`;

export interface SurveyorValues {
  stateOptions?: string;
  instructions: string;
}

const defaultReadonlyInstructions = `This invoice is for your information only. No consideration required.
If you would like to comment on this invoice please contact the Claims Handler.`;

interface InstructionsProps {
  numberOfInvoices: number;
  options: string[];
  hasBeenShared: boolean;
}
export const SurveyorInstructions = ({ numberOfInvoices, options, hasBeenShared }: InstructionsProps) => {
  const { values, setFieldValue } = useFormikContext<SurveyorValues>();
  const [isChanged, setIsChanged] = useState(false);

  // Check if Instruction change might be manual input, and set flag if it seems to be.
  // Just a proxy check, because directly checking for actual user input would take more time.
  useEffect(() => {
    if (isChanged) return;
    if (values.instructions && values.instructions !== defaultReadonlyInstructions) {
      setIsChanged(true);
    }
  }, [values.instructions]); // eslint-disable-line react-hooks/exhaustive-deps

  // If user clicked a radio button but Instructions have not been manually changed,
  // add or remove the default readonly instructions depending on user selection.
  // Do not clear latest instructions if the invoice has been shared previously.
  useEffect(() => {
    if (isChanged) return;
    if (hasBeenShared) return;
    if (values.stateOptions === options[0]) void setFieldValue('instructions', '');
    if (values.stateOptions === options[1]) void setFieldValue('instructions', defaultReadonlyInstructions);
  }, [values.stateOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const labelText = `Instructions to Surveyor${numberOfInvoices > 1 ? ` (will be applied to all ${numberOfInvoices} invoices)` : ''}`;

  return (
    <Instructions
      name="instructions"
      label={labelText}
      placeholder="Text"
      noErrors
    />
  );
};
