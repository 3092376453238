import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter, createRoutesFromElements,
  Route, RouterProvider
} from 'react-router-dom';
import {
  DefaultErrorMessage, ErrorBoundary, AppInsightsProvider
} from '@instech/components';
import { AuthenticationProvider } from '@/common/authentication/AuthenticationProvider';
import { createBrowserHistory } from 'history';
import { pdfjs } from 'react-pdf';
import 'pdfjs-dist/build/pdf.worker.entry';
import 'react-app-polyfill/stable';
import './common/fonts.scss';
import './common/custom.css';
import 'normalize.css';
import { App } from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') ?? undefined;
const rootElement = document.getElementById('root');
const appInsightsApplicationName = 'Insify.ClaimStatement.Web';

const browserHistory = createBrowserHistory({ window });

const browserRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/*" element={<App />} />
  ),
  { basename: baseUrl }
);

pdfjs.GlobalWorkerOptions.workerSrc = (window as any).pdfjsWorker;

const getCookieValue = (name: string): string => {
  const nameLenPlus = (name.length + 1);
  return document.cookie
    .split(';')
    .map(c => c.trim())
    .filter(cookie => cookie.substring(0, nameLenPlus) === `${name}=`)
    .map(cookie => decodeURIComponent(cookie.substring(nameLenPlus)))[0];
};

// opt into Concurrent Mode
// @ts-ignore
createRoot(rootElement).render(
  <AppInsightsProvider
    application={appInsightsApplicationName}
    browserHistory={browserHistory}
    connectionString={getCookieValue('AppInsightsConnectionString')}>
    <ErrorBoundary component={DefaultErrorMessage}>
      <AuthenticationProvider>
        <RouterProvider router={browserRouter} />
      </AuthenticationProvider>
    </ErrorBoundary>
  </AppInsightsProvider>
);
