import { Loader } from '@instech/components';
import { FC } from 'react';
import styled from 'styled-components';

const ModalContent = styled.div`
  max-width: 100%;
  padding: 12px 24px 24px 24px;
  box-sizing: border-box;
`;

export const ModalContentLarge = styled(ModalContent)`
  width: 1110px;
`;

export const ModalContentMedium = styled(ModalContent)`
  width: 716px;
`;

export const ModalContentSmall = styled(ModalContent)`
  width: 512px;
`;

const AreaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.marineBlue};
  width: 100%;
  height: 120px;
`;

export const ModalLoader: FC = () => (
  <AreaWrapper><Loader size="medium" /></AreaWrapper>
);
