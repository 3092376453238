import { Formik } from 'formik';
import { FormWithAutoSave } from '@/apps/Adjustment/pages/AdjustmentPage/core/Components';
import { InsuranceConditionsPane } from '@/apps/Adjustment/pages/AdjustmentPage/chapters/InsuranceConditions/core';
import { updateInsuranceConditions } from '@/apps/Adjustment/services/adjustmentCalculationsService';
import { CollapsiblePane, TextArea } from '@instech/components';
import { AdjustmentCalculations } from '@/apps/Adjustment/types/adjustmentCalculations';
import {
  dispatchAutosaveFinishedEvent,
  dispatchAutosaveStartedEvent
} from '@/common/components/Autosave';

const createInitialValues = (adjustmentCalculations: AdjustmentCalculations) => {
  const { insuranceCondition } = adjustmentCalculations;

  return {
    internalNotes: insuranceCondition.internalNotes ?? '',
    insuranceConditions: insuranceCondition.insuranceConditions ?? ''
  };
};

type InformationFormProps = {
  adjustmentCalculations: AdjustmentCalculations
}
export const InformationForm = ({ adjustmentCalculations }: InformationFormProps) => {
  const handleSubmit = async (data: AdjustmentCalculations['insuranceCondition']) => {
    dispatchAutosaveStartedEvent();
    try {
      await updateInsuranceConditions(adjustmentCalculations.claimStatementId, adjustmentCalculations.id, data);
    } finally {
      dispatchAutosaveFinishedEvent();
    }
  };

  return (
    <Formik initialValues={createInitialValues(adjustmentCalculations)} onSubmit={handleSubmit}>
      <FormWithAutoSave>
        <InsuranceConditionsPane />
        <CollapsiblePane color="green" title="Internal notes" padding="20px" margin="20px 0 0">
          <TextArea name="internalNotes" label="Internal Notes - Only visible for CH/Adjuster" />
        </CollapsiblePane>
      </FormWithAutoSave>
    </Formik>
  );
};
