import { TableCellComment } from '@/common/components/Table/core/TableCell';
import { TableCellMaxContent } from '@/common/components/Table/core/TableCell/TableCellMaxContent';
import { useRowProps } from '@/common/components/Table/core/TableRow';
import { tableCellVariant } from '@/common/components/Table/types';
import { collapseGroups } from '@/common/components/Table/utils';
import { TableCell } from '@instech/components';
import { FunctionComponent as FC } from 'react';
import { getFieldValue, getTooltipValue } from '@/components/pages/claim/ClaimStatementPage/InvoiceTable/core/utils/getConsideredValues';
import { SplitRowDataType, SplitRowMessageWrapper } from '../../../TableCells/Components';

const getDynamicSpanWidth = (dynamicCells: (boolean | undefined)[]) => {
  const extraSpanWidth = dynamicCells.filter(val => !!val).length;
  return (4 + extraSpanWidth);
};

export interface SplitRowAdjusterProps {
  split: SplitRowDataType;
  exchangeRate?: number | null;
  showGeneralAverage?: boolean;
  showReviewComment?: boolean;
}
export const SplitAdjusterCells: FC<SplitRowAdjusterProps> = ({
  split,
  exchangeRate,
  showGeneralAverage,
  showReviewComment
}) => {
  const { tooltipId, showFullComments } = useRowProps();
  const { isDeleted, ...adjuster } = split.adjuster;

  const isConsidered = true;

  if (isDeleted) {
    return (
      <TableCellMaxContent
        data-collapse-border
        data-cell-variant={tableCellVariant.tertiary}
        data-collapse-group={collapseGroups.adjuster}
        span={getDynamicSpanWidth([showGeneralAverage, showReviewComment]).toString()}>
        <SplitRowMessageWrapper>Deleted by Adjuster.</SplitRowMessageWrapper>
      </TableCellMaxContent>
    );
  }
  return (
    <>
      <TableCellMaxContent
        data-collapse-border
        data-cell-variant={tableCellVariant.tertiary}
        data-collapse-group={collapseGroups.adjuster}
        right>
        <div data-for={tooltipId} data-tip={getTooltipValue(isConsidered, adjuster.commonExpenses, exchangeRate)}>
          {getFieldValue(isConsidered, adjuster.commonExpenses, exchangeRate)}
        </div>
      </TableCellMaxContent>
      {showGeneralAverage && (
        <TableCellMaxContent
          data-cell-variant={tableCellVariant.tertiary}
          data-collapse-group={collapseGroups.adjuster}
          right>
          <div data-for={tooltipId} data-tip={getTooltipValue(isConsidered, adjuster.generalAverage, exchangeRate)}>
            {getFieldValue(isConsidered, adjuster.generalAverage, exchangeRate)}
          </div>
        </TableCellMaxContent>
      )}
      <TableCellMaxContent
        data-cell-variant={tableCellVariant.tertiary}
        data-collapse-group={collapseGroups.adjuster}
        right>
        <div data-for={tooltipId} data-tip={getTooltipValue(isConsidered, adjuster.particularAverage, exchangeRate)}>
          {getFieldValue(isConsidered, adjuster.particularAverage, exchangeRate)}
        </div>
      </TableCellMaxContent>
      <TableCellMaxContent
        data-cell-variant={tableCellVariant.tertiary}
        data-collapse-group={collapseGroups.adjuster}
        right>
        <div data-for={tooltipId} data-tip={getTooltipValue(isConsidered, adjuster.ownersWorkConsideration, exchangeRate)}>
          {getFieldValue(isConsidered, adjuster.ownersWorkConsideration, exchangeRate)}
        </div>
      </TableCellMaxContent>
      <TableCellComment
        data-cell-variant={tableCellVariant.tertiary}
        data-collapse-group={collapseGroups.adjuster}
        comment={adjuster.adjustersComment}
        showFullComment={showFullComments}
        tooltipId={tooltipId}
      />
      {showReviewComment && (
        <TableCell
          data-cell-variant={tableCellVariant.tertiary}
          data-collapse-group={collapseGroups.adjuster}
        />
      )}
    </>
  );
};
