import { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useCurrentRole } from '@/common/authentication/CurrentRoleContext';
import { UserRoles } from '@/types';
import { roles } from '@/utils/constants';
import { TabItem } from './TabItem';
import {
  getClaimTabTitle,
  isValidRole,
  useIsClaimsHandlerAssignedClaimsHandlerOnCase,
  useParamsFromPath
} from './utils';

const TabGroup = styled.div`
  grid-area: tabs;
  margin-top: auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

interface TabProps {
  isActive: boolean;
}
interface TabWithRoleProps extends TabProps {
  claimId: string | undefined;
  roleName?: UserRoles;
}

const DashboardTab: FunctionComponent<TabProps> = ({ isActive }) => (
  <TabItem name="DASHBOARD" to="/" showTab isActive={isActive} />
);

const ClaimStatementTab: FunctionComponent<TabWithRoleProps> = ({ claimId, roleName, isActive }) => {
  const validRoles = [roles.Owner, roles.Broker, roles.ClaimsHandler, roles.Surveyor];
  return (
    <TabItem
      name={getClaimTabTitle(roleName)}
      to={`/claimstatements/${claimId}`}
      showTab={!!claimId && isValidRole(roleName, validRoles)}
      isActive={isActive} />
  );
};

const ReviewTab: FunctionComponent<TabWithRoleProps> = ({ claimId, roleName, isActive }) => {
  const validRoles = [roles.Owner, roles.Broker, roles.ClaimsHandler];
  return (
    <TabItem
      name="CLAIM REVIEW"
      to={`/claimstatements/${claimId}/review`}
      showTab={!!claimId && isValidRole(roleName, validRoles)}
      isActive={isActive} />
  );
};

const AdjustmentTab: FunctionComponent<TabWithRoleProps> = ({ claimId, roleName, isActive }) => (
  <TabItem
    name="ADJUSTMENT"
    to={`/claimstatements/${claimId}/adjustments`}
    showTab={!!claimId && isValidRole(roleName, [roles.ClaimsHandler])}
    isActive={isActive}
  />
);

export const Tabs: FunctionComponent = () => {
  const { claimStatementId } = useParamsFromPath('/claimstatements/:claimStatementId/*');
  const { adjustmentId } = useParamsFromPath('/adjustments/:adjustmentId/*');
  const role = useCurrentRole();
  const { pathname } = useLocation();

  const claimId = claimStatementId ?? adjustmentId;

  const onDashboardPage = !(claimStatementId || adjustmentId);
  const onReviewPage = pathname.includes('review');
  const onAdjustmentPage = pathname.includes('adjustments');
  const onClaimPage = pathname.includes('claimstatements') && !onReviewPage && !onAdjustmentPage;
  const isAssignedToCurrentUser = useIsClaimsHandlerAssignedClaimsHandlerOnCase(claimId ?? '', role);

  return (
    <TabGroup>
      <DashboardTab isActive={onDashboardPage} />
      <ClaimStatementTab claimId={claimId} roleName={role?.name} isActive={onClaimPage} />
      {isAssignedToCurrentUser && (
        <>
          <ReviewTab claimId={claimId} roleName={role?.name} isActive={onReviewPage} />
          <AdjustmentTab claimId={claimId} roleName={role?.name} isActive={onAdjustmentPage} />
        </>
      )}
    </TabGroup>
  );
};
