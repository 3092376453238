import { useSharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { InvoiceOverview } from '@/types';
import { formatOrEmpty, formatWithDigitsOrEmpty } from '@/utils/number';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 0px;
  color: ${props => props.theme.marineBlue};
  padding: 12px 40px;
`;

const SummaryItem = styled.div`
  min-width: 150px;
  padding-right: 72px;
`;

const ItemLabel = styled.div`
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 16px;
`;

const ItemText = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

interface Props {
  data: InvoiceOverview;
  showIncomplete: boolean;
}
export const InvoiceSummary: FunctionComponent<Props> = ({ data, showIncomplete }) => {
  const { tooltipId } = useSharedTooltip();
  return (
    <Container>
      <SummaryItem>
        <ItemLabel>Invoices</ItemLabel>
        <ItemText>{data.totalInvoices || '-'}</ItemText>
      </SummaryItem>
      {showIncomplete && (
        <SummaryItem>
          <ItemLabel>Incomplete</ItemLabel>
          <ItemText>{data.incompleteInvoices || '-'}</ItemText>
        </SummaryItem>
      )}
      <SummaryItem>
        <ItemLabel>Policy Amount</ItemLabel>
        <ItemText data-for={tooltipId} data-tip={formatWithDigitsOrEmpty(data.total, true)}>
          {formatOrEmpty(data.total)}
        </ItemText>
      </SummaryItem>
      <SummaryItem>
        <ItemLabel>Claim</ItemLabel>
        <ItemText data-for={tooltipId} data-tip={formatWithDigitsOrEmpty(data.claim, true)}>
          {formatOrEmpty(data.claim)}
        </ItemText>
      </SummaryItem>
      <SummaryItem>
        <ItemLabel>Owner</ItemLabel>
        <ItemText data-for={tooltipId} data-tip={formatWithDigitsOrEmpty(data.ownersWork, true)}>
          {formatOrEmpty(data.ownersWork)}
        </ItemText>
      </SummaryItem>
    </Container>
  );
};
