import {
  ApplicationRole, ClaimStatement, Invoice
} from '@/types';
import { FunctionComponent as FC } from 'react';
import {
  AdditionalInformationPane,
  OwnersClaimPane,
  OwnersCommentPane,
  AdjustersConsiderationPane,
  SurveyorsConsiderationPane
} from '../../core/ReadOnlyInvoice';
import { NavigationalActionButtons } from './NavigationalActionButtons';

interface Props {
  invoice: Invoice;
  claimStatement: ClaimStatement;
  isClosed: boolean;
  currentRole: ApplicationRole;
}
/**
 * Switch component for handling whether to show the Edit or Readonly view of the
 * left-hand invoice details side of an invoice page. One such component for reach
 * role, to handle different rendering scenarios for different roles.
 *
 * The default view is readonly, with the edit view enabled by a prop.
 * Note that AdditionalInformationPane is included in EditInvoiceAdjuster
 * so it needs to be added separately for readonly, i.e. when locked.
 */
export const InvoiceDetailsReview: FC<Props> = ({ invoice, claimStatement, isClosed, currentRole }) =>
  (
    <div>
      <NavigationalActionButtons
          anchorId="review-action-buttons"
          invoice={invoice}
          isReview />
      <OwnersClaimPane
        claimStatement={claimStatement}
        invoice={invoice}
        policyCurrency={claimStatement.currency}
        showNotes={invoice.record.notes !== null}
        readonly={isClosed} />
      <AdditionalInformationPane invoice={invoice}
                                 showRepairPeriod={currentRole.name === 'ClaimsHandler'}
                                 hasSplit={(invoice?.adjustersConsideration?.splits?.length ?? 0) > 1} />
      {invoice?.surveyorsConsideration?.isConsidered && <SurveyorsConsiderationPane invoice={invoice} />}
      {invoice?.adjustersConsideration?.isConsidered && <AdjustersConsiderationPane invoice={invoice} />}
      <OwnersCommentPane
        currentRole={currentRole}
        invoice={invoice}
        isReadonly={isClosed}
      />
    </div>
  );
