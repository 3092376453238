import { CollapsibleTableRowData, CollapsibleTableSchemaReturn } from '@/common/components/Table/CollapsibleTable/types';
import { Invoice } from '@/types';
import { noGroupingCollapse, noGroupingHeader } from './noGroupingHeaders';
import { noGroupingRow } from './noGroupingRow';
import { SubrowDataType, noGroupingSubrow } from './noGroupingSubrow';
import { CSTableProps } from '../useSchemaByTableGrouping';

// Schema with no grouping have splits as subrows, so min 2 subrows to expand
const MIN_SUBROWS_TO_EXPAND = 2;

// Groups Surveyor splits together with their corresponding Adjuster split
const groupSurveyorAdjusterSplits = (data: Invoice) => {
  const { adjustersConsideration, surveyorsConsideration } = data;
  const list: SubrowDataType[] = [];
  adjustersConsideration.splits.forEach(adjusterSplit => {
    const surveyorSplit = surveyorsConsideration?.splits.find(item => item.id === adjusterSplit.surveyorSplitId);
    list.push({ adjuster: adjusterSplit, surveyor: surveyorSplit });
  });
  return list;
};

// Crunch through and return ready table rows and subrows
const composeTableRowData = (
  data: Invoice[],
  props: CSTableProps
): CollapsibleTableRowData[] => {
  // Have to map this out here before it can be sent downward
  const invoiceIdList = data.map(row => row.id);
  const segments = data.map(row => ({
    row: noGroupingRow(row, props, invoiceIdList),
    subrows: groupSurveyorAdjusterSplits(row).map(
      subrow => noGroupingSubrow(subrow, row, props)
    )
  }));
  return segments;
};

/**
 * Takes table data, as well as properties needed for table cell contents, and
 * returns a an object of the different parts of the table schema (header sections,
 * table row content, etc.)
 */
export const createTableSchemaNoGrouping = (
  data: Invoice[],
  props: CSTableProps
): CollapsibleTableSchemaReturn => {
  const { claimStatementSettings } = props;

  const selectColumn = props.selectableRows ? '64px' : '';
  const reviewColumn = props.showReview ? 'auto' : '';
  const genAvgColumn = claimStatementSettings?.hasGeneralAverage ? 'auto' : '';
  const commentColumn = props.showReviewComment ? 'auto' : '';

  return ({
    layout: `${selectColumn} auto ${reviewColumn} ${genAvgColumn} repeat(19, auto) ${commentColumn}`,
    subrowsHeaderText: 'Splits',
    minSubrowsToExpand: MIN_SUBROWS_TO_EXPAND,
    selectableRows: props.selectableRows,
    collapseButtons: noGroupingCollapse(props),
    header: noGroupingHeader(props),
    tableRows: composeTableRowData(data, props),
  });
};
