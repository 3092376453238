import React from 'react';
import { SurveyorsConsiderationSplit } from '@/types';
import { DarkReadonlyCollapsiblePane } from '@/common/components/Pane/ReadonlyCollapsiblePane';
import { CommentAndNotes } from '@/components/pages/invoice/core/ReadOnlyInvoice/core/CommentAndNotes';
import {
  InfoLayout, InfoWithLabel, InfoWithLabelAndTag
} from '@/common/components/Text';
import { formatWithDigitsOrEmpty } from '@/utils/number';
import { useSharedTooltip } from '@/common/components/Tooltip/SharedTooltip';

const hasValue = (value?: string | null) => {
  if (value == null) return false;
  return value !== '';
};

interface Props {
  record: SurveyorsConsiderationSplit | undefined;
}

export const DarkSingleReadonlySurveyorsConsideration = ({ record }: Props) => {
  const { tooltipId } = useSharedTooltip();
  return (
    <DarkReadonlyCollapsiblePane title="Surveyor's Consideration" margin="8px 0px 0px 0px" padding="16px">
      <InfoLayout columnMin="160px">
        <InfoWithLabelAndTag
          label="Claim"
          text={formatWithDigitsOrEmpty(record?.claimConsideration, true)}
          tag={record?.claimConsiderationTag}
          tooltipId={tooltipId}
        />
        <InfoWithLabel
          label="Owner"
          text={formatWithDigitsOrEmpty(record?.ownersWorkConsideration, true)}
        />
        <InfoWithLabelAndTag
          label="General Expenses"
          text={formatWithDigitsOrEmpty(record?.generalExpenses, true)}
          tag={record?.generalExpensesTag}
          tooltipId={tooltipId}
        />
        <InfoWithLabel
          label="Adjuster to Consider"
          text={formatWithDigitsOrEmpty(record?.adjustersConsideration, true)}
        />
      </InfoLayout>
      <CommentAndNotes
        comment={record?.surveyorsComment}
        commentLabel="Description of Consideration"
        notes={record?.internalNotes}
        showNotes={hasValue(record?.internalNotes)}
        showBorder
      />
    </DarkReadonlyCollapsiblePane>
  );
};
