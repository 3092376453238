import {
  Dispatch, SetStateAction, createContext, useContext, useEffect, useMemo, useState
} from 'react';
import { FCWC } from '@/types';
import { SaveButtonStatus } from './types';

interface SaveStatusContextData {
  saveStatus: SaveButtonStatus,
  setSaveStatus: Dispatch<SetStateAction<SaveButtonStatus>>
}
const SaveStatusContext = createContext<SaveStatusContextData | null>(null);

interface Props {
  dirty: boolean;
}
/**
 * Save status handler for use between multiple components.
 * Use useSaveStatus if the status only needs to be handled within
 * a single component.
 */
export const SaveStatusContextProvider: FCWC<Props> = ({ children, dirty }) => {
  const [saveStatus, setSaveStatus] = useState<SaveButtonStatus>('saved');

  useEffect(() => {
    if (dirty && saveStatus === 'saved') {
      setSaveStatus('unsaved');
    }
  }, [dirty, saveStatus]);

  useEffect(() => {
    if (!dirty && saveStatus === 'unsaved') {
      setSaveStatus('saved');
    }
  }, [dirty, saveStatus]);

  const value = useMemo(() => ({
    saveStatus, setSaveStatus
  }), [saveStatus]);

  return (
    <SaveStatusContext.Provider value={value}>
      {children}
    </SaveStatusContext.Provider>
  );
};

export const useSaveStatusContext = () => useContext(SaveStatusContext) as SaveStatusContextData;
