import { useModalContext } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import { ButtonGroup, SlimButton } from '@instech/components';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Guid } from '@/types';
import { ContentHorizontalCenter } from '@/common/layout/Content';
import { LargeBoldText, RegularText } from '@/common/components/Text';
import { WarningLarge } from '@/common/components/Icon/WarningLarge';

const TextWrapper = styled.div`
  text-align: center;
  padding: 10px 0;
`;

const StyledBoldText = styled(LargeBoldText)`
  margin-bottom: 15px;
`;

interface DeleteClaimStatementModal {
  onDelete: (claimStatementId: Guid) => Promise<void>;
  claimStatementId: Guid;
}
const ModalContent = ({ onDelete, claimStatementId }: DeleteClaimStatementModal) => {
  const { close } = useModalContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    await onDelete(claimStatementId);
    close();
  };
  return (
    <>
      <ContentHorizontalCenter>
        <WarningLarge />
        <TextWrapper>
          <StyledBoldText>The test claim statement will be deleted</StyledBoldText>
          <RegularText>Are you sure you want to delete the test claim statement?</RegularText>
          <RegularText>You can&apos;t undo this action.</RegularText>
        </TextWrapper>
      </ContentHorizontalCenter>
      <ButtonGroup alignRight marginTop="24px">
        <SlimButton variant="secondary" onClick={close}>
          CANCEL
        </SlimButton>
        <SlimButton onClick={handleDelete} disabled={isLoading} loading={isLoading} width="64px">
          DELETE
        </SlimButton>
      </ButtonGroup>
    </>
  );
};

export const deleteClaimStatementModal = ({ onDelete, claimStatementId }: DeleteClaimStatementModal): ModalType<DeleteClaimStatementModal> => ({
  component: ModalContent,
  options: {
    title: 'Delete',
    size: 'small',
    padding: '12px 20px 20px'
  },
  args: {
    onDelete,
    claimStatementId
  }
});

export const defaultTransferOwnerToClaimsHandlerProps: DeleteClaimStatementModal = {
  onDelete: async () => { },
  claimStatementId: ''
};
