import { Pane } from '@instech/components';
import { FC } from 'react';
import { PathMatch, useMatch } from 'react-router';
import { ChapterGroup } from './core/ChapterGroup';
import { ChapterSegment } from './core/ChapterSegment';
import { SidebarFooter } from './core/SidebarFooter';

export interface ChapterSegmentGroup {
  title: string;
  startOpen?: boolean;
  segments: {
    name: string;
    path: string;
  }[];
}

type ChapterMatch = PathMatch<'id' | 'chapter'> | null;

const checkMatch = (pathMatch: ChapterMatch, path: string) => {
  if (!pathMatch || !pathMatch.params.chapter) return false;
  return path.includes(pathMatch.params.chapter);
};

interface Props {
  chapters: ChapterSegmentGroup[];
}
/**
 * Renders a sidebar with navigable chapters divided into groups.
 * Each group can be expanded or collapsed by the user, with a `title` rendered
 * at the top with segments underneath. Marking a group with`startOpen` makes it
 * appear open on initial component render.
 *
 * Each segment must have a `name` and a `path`. The name can be any free text,
 * but the path should just be a URL slug (ex. 'conclusion'); other synctactic
 * sugar for the router is handled by the segment component.
 */
export const ChapterSidebar: FC<Props> = ({ chapters }) => {
  const overviewPathMatch = useMatch('claimstatements/:id/adjustments/overview/:chapter');
  const calculationsPathMatch = useMatch('claimstatements/:id/adjustments/calculation/:chapter');
  const pathMatch = overviewPathMatch ?? calculationsPathMatch;

  return (
    <Pane title="Chapters" color="green" padding="0px">
      {chapters.map(group => (
        <ChapterGroup
          key={group.title}
          title={group.title}>
          {group.segments.map(segment => (
            <ChapterSegment
              key={segment.name}
              name={segment.name}
              path={segment.path}
              isActive={checkMatch(pathMatch, segment.path)}
            />
          ))}
        </ChapterGroup>
      ))}
      <SidebarFooter />
    </Pane>
  );
};
