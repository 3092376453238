import { TableCellComment } from '@/common/components/Table/core/TableCell';
import { TableCellMaxContent } from '@/common/components/Table/core/TableCell/TableCellMaxContent';
import { useRowProps } from '@/common/components/Table/core/TableRow';
import { tableCellVariant } from '@/common/components/Table/types';
import { collapseGroups } from '@/common/components/Table/utils';
import { FunctionComponent as FC } from 'react';
import { getFieldValue, getTooltipValue } from '@/components/pages/claim/ClaimStatementPage/InvoiceTable/core/utils/getConsideredValues';
import { SplitRowMessageWrapper, SplitRowSurveyorProps } from '../../../TableCells/Components';

export const SplitSurveyorCells: FC<SplitRowSurveyorProps> = ({ split, state, exchangeRate }) => {
  const createdByAdjuster = !split.adjuster.surveyorSplitId && !split.surveyor;
  const deletedBySurveyor = split.adjuster.surveyorSplitId && !split.surveyor;
  const atSurveyorsDesk = ['CostAllocation', 'CostReallocation'];

  const { tooltipId, showFullComments } = useRowProps();

  // Safely destructure possibly undefined object to prevent throwing errors
  const { isConsidered, ...surv } = { ...split.surveyor };

  if (createdByAdjuster) {
    return (
      <TableCellMaxContent
        data-collapse-border
        data-cell-variant={tableCellVariant.secondary}
        data-collapse-group={collapseGroups.surveyor}
        span="5">
        <SplitRowMessageWrapper>Created by Adjuster.</SplitRowMessageWrapper>
      </TableCellMaxContent>
    );
  }
  if (deletedBySurveyor && !atSurveyorsDesk.includes(state)) {
    return (
      <TableCellMaxContent
        data-collapse-border
        data-cell-variant={tableCellVariant.secondary}
        data-collapse-group={collapseGroups.surveyor}
        span="5">
        <SplitRowMessageWrapper>Deleted by Surveyor.</SplitRowMessageWrapper>
      </TableCellMaxContent>
    );
  }
  if (deletedBySurveyor && atSurveyorsDesk.includes(state)) {
    return (
      <TableCellMaxContent
        data-collapse-border
        data-cell-variant={tableCellVariant.secondary}
        data-collapse-group={collapseGroups.surveyor}
        span="5">
        <SplitRowMessageWrapper>At Surveyor&apos;s desk.</SplitRowMessageWrapper>
      </TableCellMaxContent>
    );
  }
  return (
    <>
      <TableCellMaxContent
        data-collapse-border
        data-cell-variant={tableCellVariant.secondary}
        data-collapse-group={collapseGroups.surveyor}
        right>
        <div data-for={tooltipId} data-tip={getTooltipValue(isConsidered, surv?.generalExpenses, exchangeRate)}>
          {getFieldValue(isConsidered, surv?.generalExpenses, exchangeRate)}
        </div>
      </TableCellMaxContent>
      <TableCellMaxContent
        data-cell-variant={tableCellVariant.secondary}
        data-collapse-group={collapseGroups.surveyor}
        right>
        <div data-for={tooltipId} data-tip={getTooltipValue(isConsidered, surv?.claimConsideration, exchangeRate)}>
          {getFieldValue(isConsidered, surv?.claimConsideration, exchangeRate)}
        </div>
      </TableCellMaxContent>
      <TableCellMaxContent
        data-cell-variant={tableCellVariant.secondary}
        data-collapse-group={collapseGroups.surveyor}
        right>
        <div data-for={tooltipId} data-tip={getTooltipValue(isConsidered, surv?.ownersWorkConsideration, exchangeRate)}>
          {getFieldValue(isConsidered, surv?.ownersWorkConsideration, exchangeRate)}
        </div>
      </TableCellMaxContent>
      <TableCellMaxContent
        data-cell-variant={tableCellVariant.secondary}
        data-collapse-group={collapseGroups.surveyor}
        right>
        <div data-for={tooltipId} data-tip={getTooltipValue(isConsidered, surv?.adjustersConsideration, exchangeRate)}>
          {getFieldValue(isConsidered, surv?.adjustersConsideration, exchangeRate)}
        </div>
      </TableCellMaxContent>
      <TableCellComment
        data-cell-variant={tableCellVariant.secondary}
        data-collapse-group={collapseGroups.surveyor}
        comment={surv?.surveyorsComment}
        showFullComment={showFullComments}
        tooltipId={tooltipId}
      />
    </>
  );
};
