import { FormikWithPrompt } from '@/common/components/Form/PromptUnsavedChanges';
import { Pane, TextArea } from '@instech/components';
import { Form } from 'formik';
import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { Invoice } from '@/types';
import {
  OwnersReviewComment, OwnersReviewCommentResponse, updateOwnersReviewComment
} from '@/services/invoice/invoiceServices/updateOwnersReviewComment';
import { LargeBoldText } from '@/common/components/Text';
import { CommentActionsButtonGroup } from './CommentActionsButtonGroup';

// would be nice if TextArea supported this as an inline
// prop instead of needing a style override here
const StyledTextArea = styled(TextArea)`
  & textarea {
    height: 104px;
  }
`;

const DarkStyledPane = styled(Pane)`
  & > div:last-child {
    border-top-color: ${props => props.theme.marineBlue60};
    background-color: ${props => props.theme.lightFlatWhite};
  }
`;

const CommentText = styled.div`
  color: ${props => props.theme.marineBlue};
  white-space: pre-line;
`;

const createInitialValues = (invoice: Invoice): OwnersReviewComment => ({
  ownersReviewComment: invoice.ownersReviewComment ?? '',
  ownersReviewDraftComment: invoice.ownersReviewDraftComment ?? ''
});

interface Props {
  invoice: Invoice;
}
export const OwnersCommentForm: FC<Props> = ({ invoice }) => {
  const initialValues = useMemo(() => createInitialValues(invoice), [invoice]);
  let ownerReviewComment = initialValues.ownersReviewComment;

  const handleSave = async (values: OwnersReviewComment): Promise<OwnersReviewCommentResponse> => {
    const result = await updateOwnersReviewComment(invoice.claimStatementId, invoice.id, values);
    ownerReviewComment = result.values.ownersReviewComment;
    return result;
  };

  return (
    <FormikWithPrompt
      initialValues={initialValues}
      onSubmit={() => { }}
      enableReinitialize>
      {() => (
        <Form data-testid="owners-review-comment-edit">
          <LargeBoldText>Submitted comment</LargeBoldText>
          <DarkStyledPane padding="15px" margin="10px 0 20px 0">
            <CommentText>{ownerReviewComment}</CommentText>
          </DarkStyledPane>
          <input type="hidden" name="ownersReviewComment" />
          <StyledTextArea
            name="ownersReviewDraftComment"
            placeholder="" />
          <CommentActionsButtonGroup submitValues={handleSave} />
        </Form>
      )}
    </FormikWithPrompt>
  );
};
