import * as Yup from 'yup';
import { ClaimsData } from '@/types';
import { datepickerSingle } from '@/utils/validationSchemas';

const claimsDataSchema = Yup.object()
  .shape({
    id: Yup.string(),
    insuranceYear: Yup.number(),
    claimNumber: Yup.number(),
    claimsHandler: Yup.string().nullable(),
    claimDate: Yup.string().nullable(),
    currency: Yup.string().nullable(),
    fleetName: Yup.string().nullable(),
    imoNumber: Yup.number().nullable(),
    buildYear: Yup.number().nullable(),
    class: Yup.string().nullable(),
    vesselName: Yup.string().nullable(),
    claimEvent: Yup.string().nullable(),
    interest: Yup.string().nullable()
  })
  .default(undefined)
  .required('Claims data is required');

/**
 * Runs a validation on ClaimsData fetched from INS to ensure that
 * it is as expected before enabling new claim statement submit
 */
export const validateClaimsData = async (data: ClaimsData) => {
  const errorsFound: { path: string, message: string }[] = [];
  const validated = await claimsDataSchema.validate(data, { abortEarly: false })
    .catch((errors: any) => errors.inner.forEach(
      ({ path, message }: any) => errorsFound.push({ path, message })
    ));
  return {
    status: errorsFound.length ? 'error' : 'success',
    data: validated,
    errors: errorsFound
  };
};

/**
 * Validation schema for claim edit form
 */
export const claimStatementValidationSchema = Yup.object({
  occurrences: Yup.array(Yup.object({
    name: Yup.string()
      .test('is-not-blank', 'Required', value => value !== undefined && value.trim().length > 0)
      .max(110, 'Cannot be longer than 110 characters'),
    claimDate: datepickerSingle()
  })),
  participantRequest: Yup.object({
    owners: Yup.array(Yup.string()),
    brokers: Yup.array(Yup.string()),
    surveyors: Yup.array(Yup.string()),
    claimsHandlers: Yup.array(Yup.string()).min(1)
  }),
  claimStatementSettings: Yup.object({
    hasGeneralAverage: Yup.bool()
  }),
  isExperimental: Yup.bool()
});
