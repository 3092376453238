import { getClaimDataAsync } from '@/services/claimServices';
import { ClaimsData } from '@/types';
import { useState, useEffect } from 'react';

export const useClaimData = (claimNumber?: number, insuranceYear?: number) => {
  const [claimsData, setClaimsData] = useState<ClaimsData>();

  useEffect(() =>
    void (async () => {
      if (claimNumber && insuranceYear && !claimsData) {
        const claimDataResponse = await getClaimDataAsync(`${claimNumber}`, `${insuranceYear}`);
        setClaimsData(claimDataResponse);
      }
    })(), [claimsData, claimNumber, insuranceYear]);

  return claimsData;
};
