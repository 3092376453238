import { updateInvoiceState } from '@/services/invoice/invoiceStateService';
import { Invoice, InvoiceState } from '@/types';
import { AxiosResponse } from 'axios';
import { invoiceStates } from './constants';

/**
 * Return proper destination state when the Claims Handler needs to transfer an invoice to the Owner
 */
export const getInvoiceStateFromCHToOwner = (invoiceState: InvoiceState): InvoiceState => {
  switch (invoiceState) {
    case invoiceStates.AdjustersConsideration: return invoiceStates.ReturnToUploaderAfterConsideration;
    case invoiceStates.AdjustersConsiderationNoAllocation: return invoiceStates.ReturnToUploaderAfterConsiderationNoAllocation;
    case invoiceStates.SharedReview: return invoiceStates.ReturnToUploaderAfterSharedReview;
    default: return invoiceStates.ReturnToUploader;
  }
};

/**
 * Return proper destination state when the Owner needs to transfer an invoice to the Claims handler / Adjuster
 */
export const getInvoiceStateFromOwnerToCH = (invoiceState: InvoiceState): InvoiceState => {
  switch (invoiceState) {
    case invoiceStates.ReturnToUploaderAfterConsideration: return invoiceStates.AdjustersConsideration;
    case invoiceStates.ReturnToUploaderAfterSharedReview: return invoiceStates.SharedReview;
    case invoiceStates.ReturnToUploaderAfterConsiderationNoAllocation: return invoiceStates.AdjustersConsiderationNoAllocation;
    default: return invoiceStates.Review;
  }
};

/**
 * Return proper destination state when the CH needs to transfer an invoice to the Finished state
 */
export const getInvoiceStateToFinished = (invoiceState: InvoiceState): InvoiceState => {
  switch (invoiceState) {
    case invoiceStates.AdjustersConsiderationNoAllocation: return invoiceStates.FinishedNoAllocation;
    case invoiceStates.SharedReview: return invoiceStates.FinishedSharedReview;
    default: return invoiceStates.Finished;
  }
};

/**
 * Return proper destination state when the CH needs to transfer an invoice from the Finished states back
 */
export const getInvoiceStateBackFromFinished = (invoiceState: InvoiceState): InvoiceState => {
  switch (invoiceState) {
    case invoiceStates.FinishedNoAllocation: return invoiceStates.AdjustersConsiderationNoAllocation;
    case invoiceStates.FinishedSharedReview: return invoiceStates.SharedReview;
    default: return invoiceStates.AdjustersConsideration;
  }
};

const groupInvoicesByState = (invoices: Invoice[]): ({ [key: string]: Invoice[] }) => invoices.reduce((stateGroup: any, invoice) => {
  const { state } = invoice;
  stateGroup[state!] = stateGroup[state!] ?? []; // eslint-disable-line no-param-reassign
  stateGroup[state!].push(invoice);
  return stateGroup;
}, {});

/**
 * Groups invoices by state and transfer the groups one by one depends on the destination state
 */
export const transferInvoicesInGroups = async (
  claimStatementId: string,
  invoices: Invoice[],
  stateSelector: (state: InvoiceState) => InvoiceState,
  refreshCallBack: () => Promise<any>,
  cache: any,
  instructions?: string
): Promise<'success' | 'failed'> => {
  if (claimStatementId === undefined) {
    return 'failed';
  }
  const groupedByState = groupInvoicesByState(invoices);

  const transfers: Promise<AxiosResponse<any, any>>[] = [];
  Object.keys(groupedByState).forEach(async state => {
    const destinationState = stateSelector(state as InvoiceState);
    const updateState = updateInvoiceState(claimStatementId, {
      destinationState,
      instructions,
      invoiceIdList: groupedByState[state].map(invoice => invoice.id)
    }, cache);
    transfers.push(updateState);
  });

  try {
    await Promise.all(transfers);
    await refreshCallBack();
    return 'success';
  } catch (error) {
    return 'failed';
  }
};

// If owner has marked his/her work as complete, we should remove the flag if a new invoice is submitted
export const shouldRemoveOwnersWorkComplete = (invoicesToTransfer: Invoice[], ownersWorkComplete: boolean) => {
  if (!ownersWorkComplete) return false;
  return invoicesToTransfer.some(invoice => invoice.state === invoiceStates.New);
};
