// Enum to keep track of the different collapse group names
// eslint-disable-next-line no-shadow
export enum collapseGroups {
  particulars = 'Particulars',
  owner = 'Owner',
  surveyor = 'Surveyor',
  adjuster = 'Adjuster'
}

// export collapseGroups enum as iterable array
export const collapseGroupList = Object.values(collapseGroups);
