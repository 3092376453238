import { TextField, TextFieldControlledProps } from '@instech/components';
import styled from 'styled-components';
import { HighlightEffect } from '@/common/components/HighlightedFields';
import { useInvoiceSuggestion } from './utils';

const TextFieldHighlighted = styled(TextField)`
  & input[data-highlight="true"] {
    ${HighlightEffect}
  }
`;

type InvoiceNumberInputProps = { suggestion: string | undefined } & Partial<Omit<TextFieldControlledProps, 'as'>>;
const InvoiceNumberInput = ({ suggestion, ...props }: InvoiceNumberInputProps) => {
  const usingSuggestedValue = useInvoiceSuggestion('invoiceNumber', suggestion);

  return <TextFieldHighlighted placeholder="" name="invoiceNumber" label="Invoice Number" required data-highlight={usingSuggestedValue} {...props} />;
};

export default InvoiceNumberInput;
