import {
  useClaimStatement,
  useUpdateClaimStatementSettings, useUpdateParticipants
} from '@/services/claimStatementServices';
import {
  ClaimSearchData, ClaimStatement, Occurrence, Participants
} from '@/types';
import { useRequireClaimStatementId } from '@/utils/useRequireClaimStatementId';
import { PageLoader } from '@instech/components';
import { ComponentProps } from 'react';
import { HeadingWrapper, StickyPageHeading } from '@/common/layout/PageLayout';
import { Breadcrumbs, reusableSteps } from '@/common/components/Breadcrumbs';
import { ClaimsDataDetails } from '@/components/pages/claim/core/ClaimDetails';
import { SelectUsers } from '@/components/pages/claim/core/ClaimStatementForm/SelectUsers';
import {
  useCostAllocationSummary,
  useOccurrences
} from '@/services/occurrenceServices';
import { SavingIndicator } from '@/common/components/Autosave';
import { StickyPageContent } from '@/common/layout/StickyPage';
import { ClaimStatementSettingsPanel } from './core/ClaimStatementSettingsPanel';
import {
  Occurrences, prepareParticipantsFrom, useClaimData
} from './core';
import { PageGrid } from '../core/ClaimStatementForm';
import { EditClaimStatementProps } from './types';

/**
 * Replacing null value with empty string (or undefined for uncontrolled fields) eliminates the warning.
 */
const prepareOccurrencesFrom = (claimStatement: ClaimStatement) =>
  claimStatement.occurrences?.map(occurrence => ({
    ...occurrence,
    location: occurrence.location === null ? '' : occurrence.location
  })) ?? [];

const breadcrumbSteps = [reusableSteps.myDashboardStep];

export const EditClaimStatementPage = () => {
  const claimStatementId = useRequireClaimStatementId();
  const cs = useClaimStatement(claimStatementId);
  const { data: claimStatement, mutate: refreshClaimStatement } = cs;
  const claimsData = useClaimData(claimStatement?.claimNumber, claimStatement?.insuranceYear);
  const updateClaimStatementSettings = useUpdateClaimStatementSettings(claimStatementId);
  const updateParticipants = useUpdateParticipants(claimStatementId);
  const { createOccurrence, updateOccurrence, deleteOccurrence, transferAllocatedCost } = useOccurrences(claimStatementId);
  const { data: costAllocationSummary, mutate: refreshCostAllocationSummary } = useCostAllocationSummary(claimStatementId);

  if (!claimStatement || !claimsData) {
    return <PageLoader />;
  }

  const participants = prepareParticipantsFrom(claimStatement);
  const occurrences = prepareOccurrencesFrom(claimStatement);
  const claimStatementData: EditClaimStatementProps = {
    claimStatementId,
    claimsData,
    occurrences,
    clsNo: claimStatement.clsNo,
    isExperimental: claimStatement.isExperimental,
    claimStatementSettings: claimStatement.claimStatementSettings,
    hasAllocatedGeneralAverage: claimStatement.hasAllocatedGeneralAverage
  };
  const claimSearchData: ClaimSearchData = {
    ...claimStatementData.claimsData,
    claimStatementId: claimStatementData.claimStatementId
  };

  const generalAverage: ComponentProps<typeof ClaimStatementSettingsPanel>['generalAverage'] = {
    inUse: claimStatement?.hasAllocatedGeneralAverage ?? false,
    isEnabled: claimStatement?.claimStatementSettings?.hasGeneralAverage ?? false,
  };

  const handleClaimStatementSettingsChanged: ComponentProps<typeof ClaimStatementSettingsPanel>['onChange'] = async form => {
    await updateClaimStatementSettings(form.hasGeneralAverage);
    await refreshClaimStatement();
  };

  const handleParticipantsChanged: ComponentProps<typeof SelectUsers>['onUsersChange'] = async newParticipants => {
    const updatedParticipants: Participants = {
      owners: (newParticipants.owners ?? participants.owners).map(x => x.id),
      claimsHandlers: (newParticipants.claimsHandlers ?? participants.claimsHandlers).map(x => x.id),
      surveyors: (newParticipants.surveyors ?? participants.surveyors).map(x => x.id),
      brokers: (newParticipants.brokers ?? participants.brokers).map(x => x.id)
    };

    await updateParticipants(updatedParticipants);
    await refreshClaimStatement();
  };

  const handleClickCreateOccurrence = async (occurrence: Occurrence) => {
    await createOccurrence(occurrence);
    await refreshClaimStatement();
  };
  const handleClickUpdateOccurrence = async (occurrence: Occurrence) => {
    await updateOccurrence(occurrence);
    await refreshClaimStatement();
  };
  const handleClickDeleteOccurrence = async (occurrence: Occurrence) => {
    await deleteOccurrence(occurrence.id);
    await refreshCostAllocationSummary();
    await refreshClaimStatement();
  };

  const title = claimStatement.isExperimental ? 'Edit Claim Statement - TEST' : 'Edit Claim Statement';
  return (
    <>
      <StickyPageHeading>
        <HeadingWrapper>
          <Breadcrumbs steps={breadcrumbSteps} current={title} />
          <SavingIndicator />
        </HeadingWrapper>
      </StickyPageHeading>
      <StickyPageContent noTopPadding>
        <PageGrid>
          <div>
            <ClaimsDataDetails
              data={claimStatementData.claimsData}
              isExperimentalClaimStatement={claimStatement.isExperimental}
              maxColumns={5} />
            <Occurrences
              initialOccurrences={claimStatement?.occurrences ?? []}
              currency={claimStatement.currency}
              isExperimentalClaimStatement={claimStatement.isExperimental}
              onClickCreateOccurrence={handleClickCreateOccurrence}
              onClickUpdateOccurrence={handleClickUpdateOccurrence}
              onClickDeleteOccurrence={handleClickDeleteOccurrence}
              onTransferInvoiceSplits={transferAllocatedCost}
              costAllocationSummary={costAllocationSummary?.occurrences}
            />
          </div>
          <div>
            <SelectUsers users={participants} claimSearchData={claimSearchData} onUsersChange={handleParticipantsChanged} notificationLocation="modal" />
            <ClaimStatementSettingsPanel onChange={handleClaimStatementSettingsChanged} generalAverage={generalAverage} />
          </div>
        </PageGrid>
      </StickyPageContent>
    </>
  );
};
