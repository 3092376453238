import React, { FunctionComponent, useState } from 'react';
import { AdjustersConsiderationSplit, InvoiceRecord } from '@/types';
import { ReadonlyCollapsiblePane } from '@/common/components/Pane/ReadonlyCollapsiblePane';
import { CurrencySelector } from '@/common/components/Form/CurrencySelector/CurrencySelector';
import {
  InfoLayout, InfoWithLabel, InfoWithLabelAndTag
} from '@/common/components/Text';
import { getConvertedCurrencyText } from '@/utils/number';
import { useParams } from 'react-router-dom';
import { Loader } from '@instech/components';
import { useClaimStatement } from '@/services/claimStatementServices';
import styled from 'styled-components';
import { INVOICE_PANE_MARGIN } from '@/utils/style/styleConstants';
import { useSharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { CommentAndNotes } from '../../core/CommentAndNotes';
import { NoExchangeRateInfo } from '../../../EditInvoice/core/NoExchangeRateInfo';

export const InfoLayoutStyled = styled(InfoLayout)<{$showGeneralAverage?: boolean}>`
  grid-template-columns:  ${props => props.$showGeneralAverage ? '25% 25% 25% 25%': '33% 33% 33%'};
`;

const hasValue = (value?: string | null) => value !== null && value !== '';

interface ReadonlyProps {
  record?: AdjustersConsiderationSplit;
  invoiceRecord: InvoiceRecord;
}

export const SingleReadonlyAdjustersConsiderationPane: FunctionComponent<ReadonlyProps> = ({ record, invoiceRecord }) => {
  const { claimStatementId } = useParams();
  const { data: claimstatement } = useClaimStatement(claimStatementId);
  const { tooltipId } = useSharedTooltip();
  const [selectedCurrency, setCurrency] = useState(invoiceRecord.currency);
  const useInvoiceCurrency = selectedCurrency === invoiceRecord.currency;

  if (!claimstatement) return <Loader />;

  const handleCurrencyChange = (currency: string) => {
    setCurrency(currency);
  };

  const noExchangeRateVisible = (claimstatement.currency === selectedCurrency && !invoiceRecord.exchangeRate);
  const showGeneralAverage = claimstatement.claimStatementSettings?.hasGeneralAverage;

  return (
    <ReadonlyCollapsiblePane title="Adjuster's Consideration" margin={INVOICE_PANE_MARGIN}>
      <CurrencySelector
        first={invoiceRecord?.currency}
        second={claimstatement.currency}
        selected={selectedCurrency}
        setSelected={handleCurrencyChange}
        id="adjuster-read-only-consideration-pane" />
      <NoExchangeRateInfo isVisible={noExchangeRateVisible} />
      <InfoLayoutStyled columnMin="160px" $showGeneralAverage={showGeneralAverage}>
        <InfoWithLabelAndTag
          label="Common Expenses"
          text={getConvertedCurrencyText(record?.commonExpenses, invoiceRecord.exchangeRate, useInvoiceCurrency)}
          tag={record?.commonExpensesTag}
          tooltipId={tooltipId} />
        {showGeneralAverage && (
          <InfoWithLabel
          label="General Average"
          text={getConvertedCurrencyText(record?.generalAverage, invoiceRecord.exchangeRate, useInvoiceCurrency)} />
        )}
        <InfoWithLabelAndTag
          label="Particular Average"
          text={getConvertedCurrencyText(record?.particularAverage, invoiceRecord.exchangeRate, useInvoiceCurrency)}
          tag={record?.particularAverageTag}
          tooltipId={tooltipId} />
        <InfoWithLabelAndTag
          label="Remainder"
          text={getConvertedCurrencyText(record?.ownersWorkConsideration, invoiceRecord.exchangeRate, useInvoiceCurrency)}
          tag={record?.ownersWorkConsiderationTag}
          tooltipId={tooltipId} />
      </InfoLayoutStyled>
      <CommentAndNotes
        comment={record?.adjustersComment}
        commentLabel="Adjuster's Note"
        notes={record?.internalNotes}
        showNotes={hasValue(record?.internalNotes)}
        showBorder />
    </ReadonlyCollapsiblePane>
  );
};
