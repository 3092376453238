import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Reorder } from '@instech/icons';
import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

const StyledSortableRow = styled.tr`
  cursor: grab;
  :active {
    cursor: grabbing;
  }
`;

export const ReorderTableRow = ({ id, children }: PropsWithChildren<{ id: string }>) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id, resizeObserverConfig: {} });
  const transformString = CSS.Translate.toString(transform);

  return (
    <StyledSortableRow
      ref={setNodeRef}
      // Prefer passing these values directly, not via SC, for performance reasons
      style={{ transition, transform: transformString }}
      {...listeners}
      {...attributes}>
      {children}
    </StyledSortableRow>
  );
};

export const ReorderCell = () => (
  <td aria-label="reorder"><Reorder /></td>
);

interface NumberCellProps {
  dark?: boolean;
  deleted?: boolean;
}
export const NumberCell = styled.td<NumberCellProps>`
  text-align: right;
  ${props => props.dark && css`
    background-color: ${props.theme.marineBlue20};
  `}
  ${props => props.deleted && css`
    color: ${props.theme.marineBlue80};
    background: ${props.theme.adjuster.deleted};
  `}
`;

export const ReorderTable = styled.table`
  width: 100%;
  border-spacing: 0 4px;
  color: ${props => props.theme.marineBlue};

  thead tr {
    font-weight: bold;
    background-color: initial;
  }
  tr {
    text-align: left;
    padding: 8px;
    background-color: ${props => props.theme.marineBlue10};
    padding-right: 44px;
  }
  td {
    padding: 8px;
  }
`;

export const DeletedTableRowItem = styled.td`
  text-align: center;
  color: ${props => props.theme.marineBlue80};
  background: ${props => props.theme.adjuster.deleted};
`;
