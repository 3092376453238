import { Star } from '@instech/icons';
import { CellContext } from '@tanstack/react-table';
import { FC } from 'react';
import styled from 'styled-components';
import { BaseButtonCell } from './BaseButtonCell';

const FlexAlign = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const IconWrapper = styled.div<{ starred?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: ${props => props.theme.marineBlue};
  cursor: pointer;
  & svg {
    width: 16px;
    height: 16px;
  }
  & svg path {
    stroke-width: 2px;
    fill: ${props => props.starred ? 'currentColor' : 'transparent'};
    transition: fill 0.1s ease-in;
  }
  &:hover {
    color: ${props => props.theme.marineBlue75};
  }
`;

interface FavoriteButtonProps {
  data: CellContext<any, any>;
  onClick: () => void;
}
export const TableButtonFavorite: FC<FavoriteButtonProps> = ({ data, onClick }) => {
  const isFavorite = data.getValue<boolean>();
  return (
    <BaseButtonCell data={data}>
      <FlexAlign>
        <IconWrapper onClick={onClick} starred={isFavorite}>
          <Star />
        </IconWrapper>
      </FlexAlign>
    </BaseButtonCell>
  );
};
