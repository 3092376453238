import useSWR from 'swr';
import { deleteAsync, postFormAsync } from '@/services/client';
import {
  useCallback, useMemo, useState
} from 'react';
import axios, { AxiosError } from 'axios';
import { validateFiletypeAndSize } from '@/utils/file';

type AdjustedPdf = {
  filename: string;
  filepath: string;
  createdAt: string;
  lastModifiedAt: string;
}

const getErrorMessageFromStatus = (status: number) => {
  switch (status) {
    case 400:
      return 'Validation error: please ensure the file is a PDF and less than 25MB in size';
    case 500:
      return 'Internal server error';
    default:
      return 'An error occurred while uploading the adjustment PDF';
  }
};

const getErrorMessageFromResponseData = (message: string | undefined) => {
  if (message?.includes('Unacceptable')) {
    return 'Please ensure the file is a PDF';
  }
  if (message?.includes('File')) {
    return 'This file is too big. Please ensure the file is under 25MB';
  }
  return undefined;
};

const getClientValidationErrorMessage = (err: string) => {
  switch (err) {
    case 'InvalidFileType':
      return 'Please ensure the file is a PDF';
    case 'SizeTooLarge':
      return 'This file is too big. Please ensure the file is under 25MB';
    default:
      return 'An error occurred while uploading the adjustment PDF';
  }
};

const pdfUrl = (claimStatementId: string) => `claimstatements/${claimStatementId}/adjustmentpdfs/adjusted-claim`;

const usePdfOfAdjustment = (claimStatementId: string) => {
  const pdfRoute = useMemo(() => claimStatementId ? pdfUrl(claimStatementId) : null, [claimStatementId]);
  const { data: pdf, mutate } = useSWR<AdjustedPdf>(pdfRoute, {
    onErrorRetry: (error, _key, _config, revalidate, { retryCount }) => {
      if (error.response.status === 404) return;

      if (retryCount >= 3) return;

      setTimeout(() => revalidate({ retryCount }), 5000);
    },
    onError: (err: AxiosError) => {
      if (err.response?.status === 404) {
        void mutate(undefined, false);
      }
    }
  });
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string>();

  const uploadAdjustmentPdf = useCallback(async (file: File[]) => {
    if (pdfRoute === null) {
      return;
    }

    const adjustmentPdf = file.at(0);
    if (adjustmentPdf === undefined) {
      return;
    }

    const err = validateFiletypeAndSize(adjustmentPdf, ['.pdf'], 1024 * 1024 * 25);
    if (err) {
      setUploadErrorMessage(getClientValidationErrorMessage(err));
      return;
    }

    const formData = new FormData();
    formData.append('adjustmentPdf', adjustmentPdf);

    try {
      await postFormAsync(pdfRoute, formData);
      if (uploadErrorMessage) setUploadErrorMessage(undefined);
      await mutate();
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const errorMessage = getErrorMessageFromResponseData(error.response?.data?.message) ?? getErrorMessageFromStatus(error.response?.status ?? 0);
        setUploadErrorMessage(errorMessage);
      }
    }
  }, [mutate, pdfRoute, uploadErrorMessage]);

  const deletePdf = useCallback(async () => {
    if (pdfRoute === null) {
      return;
    }

    await deleteAsync(pdfRoute);
    await mutate();
  }, [mutate, pdfRoute]);

  return { pdf, uploadAdjustmentPdf, uploadErrorMessage, deletePdf };
};

export default usePdfOfAdjustment;
