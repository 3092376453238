import { useCurrentRole } from '@/common/authentication/CurrentRoleContext';
import { TitleText } from '@/common/components/Text';
import { PageContent } from '@/common/layout/Page';
import { useTypedModal } from '@/common/modules/Modal';
import { useFeatureFlag } from '@/common/providers/featureFlagProvider';
import { useFavourites } from '@/services/claimStatementFavouritesService';
import { refreshClaimStatementOverview } from '@/services/claimStatementServices';
import { useAllClaimStatements } from '@/services/dashboard/claimHandlerDashboardServices';
import { useCurrentUser } from '@/services/userInfoServices';
import {
  AvailableInvoiceStatuses, ClaimStatement, Guid
} from '@/types';
import { arrayToLabelValuePair, stringToLabelValuePair } from '@/utils/labelValuePair';
import {
  ButtonGroup,
  DropdownControlled,
  LabelValuePair,
  MenuOption,
  Pane,
  SlimButton
} from '@instech/components';
import { Plus } from '@instech/icons';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ClaimStatementTable } from './ClaimStatementTable';
import { TableLoader } from './ClaimStatementTable/core/Components';
import { getClaimHandlerMenuOptions } from './ClaimStatementTable/core/claimStatementTableOptions';
import { getIsAssignedToUser } from './ClaimStatementTable/core/utils';
import {
  closeClaimStatementModal, defaultCloseClaimStatementModalProps, reopenClaimStatementModal
} from './ClaimStatementTable/modal/CloseClaimStatementModal';
import { defaultTransferOwnerToClaimsHandlerProps, deleteClaimStatementModal } from './ClaimStatementTable/modal/DeleteClaimStatementModal';
import { ClaimStatementTableData } from './ClaimStatementTable/getClaimStatementTableData';

const TableArea = styled.div`
  padding-top: 40px;
`;

const DropdownControlledStyled = styled(DropdownControlled)`
  width: 360px;
`;

const TopRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const ButtonsRow = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const DropdownWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 32px;
`;

export const ClaimsHandlerClaimStatementDashboard = () => {
  const {
    data: claimStatements = [],
    isValidating,
    deleteClaimStatement,
    claimsHandlerOptions,
    claimStatementStatus
  } = useAllClaimStatements();
  const { data: favourites, deleteFavourite, addFavourite } = useFavourites();
  const canCloseClaim = useFeatureFlag('canCloseClaim');
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const role = useCurrentRole();

  // Need to be updated with a redirect to the correct claim. Uses existing page for now
  const navigateToNewClaimStatement = () => navigate('/claim');
  const navigateToNewTestClaimStatement = () => navigate('/testClaim');
  const navigateToClaimStatement = (claimStatementId: string) => navigate(`claimstatements/${claimStatementId}`);
  const navigateToEditClaimStatement = (claimStatementId: string) => navigate(`claimstatements/${claimStatementId}/edit`);

  const handleRowClick = (claimStatementId: string) => {
    const claimStatement = claimStatements?.find(c => c.id === claimStatementId);

    if (!claimStatement) return;

    const claimHandlerIds = claimStatement.claimsHandlers?.map(x => x.id) ?? [];

    if (currentUser && claimHandlerIds.includes(currentUser?.id)) {
      navigateToClaimStatement(claimStatementId);
    } else if (!claimStatement.isClosed) {
      navigateToEditClaimStatement(claimStatementId);
    }
  };

  const onStarClick = async (claimStatementId: string, selected: boolean) => {
    if (selected) await deleteFavourite(claimStatementId);
    else await addFavourite(claimStatementId);
  };

  const handleStatusSelect = async (val: any) => {
    claimStatementStatus.setSelectedStatus(val.value);
  };

  const handleClaimsHandlerSelect = (newSelection: LabelValuePair[]) => {
    claimsHandlerOptions.setSelectedOptions(newSelection.map(x => x.value));
  };

  const editClaimStatement = (claimStatementId: Guid) => {
    navigate(`claimstatements/${claimStatementId}/edit`);
  };

  const { open: openDeleteModal } = useTypedModal(deleteClaimStatementModal(defaultTransferOwnerToClaimsHandlerProps));
  const { open: openCloseModal } = useTypedModal(closeClaimStatementModal(defaultCloseClaimStatementModalProps));
  const { open: openReopenModal } = useTypedModal(reopenClaimStatementModal(defaultCloseClaimStatementModalProps));

  const handleDeleteClaimStatement = (claimStatementId: Guid) => {
    openDeleteModal({
      onDelete: async () => {
        await deleteClaimStatement(claimStatementId);
      },
      claimStatementId
    });
  };

  const handleCloseOrReopenClaim = (claimStatementId: Guid) => {
    const isClosed = claimStatements.find(claimStatement => claimStatement.id === claimStatementId)?.isClosed;
    const remainingClaimStatements = claimStatements.filter(claimStatement => claimStatement.id !== claimStatementId);
    const modalProps = { claimStatementId, onClaimStatementStatusChange: () => refreshClaimStatementOverview(remainingClaimStatements) };
    const openModal = isClosed ? openReopenModal : openCloseModal;
    openModal(modalProps);
  };

  const getContextMenuOptions = (claimStatement: ClaimStatement): MenuOption[] =>
    getClaimHandlerMenuOptions(
      claimStatement,
      canCloseClaim,
      navigateToClaimStatement,
      editClaimStatement,
      handleDeleteClaimStatement,
      handleCloseOrReopenClaim,
      handleCloseOrReopenClaim,
      currentUser?.id
    );

  const getClaimIsNotOwn = useCallback((claim: ClaimStatementTableData) => (
    !getIsAssignedToUser(currentUser?.id, claim.claimStatement.claimsHandlers)
  ), [currentUser]);

  return (
    <PageContent>
      <TopRow>
        <TitleText>Claims dashboard</TitleText>
      </TopRow>
      <ButtonsRow>
        <DropdownWrapper>
          <DropdownControlledStyled
            label="Status"
            name="status"
            defaultValue={stringToLabelValuePair(claimStatementStatus.selectedStatus)}
            options={arrayToLabelValuePair(AvailableInvoiceStatuses)}
            onChange={handleStatusSelect} />
          {claimsHandlerOptions.options && (
            <DropdownControlledStyled
              isMulti
              label="Claims Handler"
              name="claimsHandler"
              value={claimsHandlerOptions.selectedOptions}
              options={claimsHandlerOptions.options}
              onChange={handleClaimsHandlerSelect}
            />
          )}
        </DropdownWrapper>
        <ButtonGroup buttonGap="24px" marginTop="21px">
          <SlimButton variant="secondary" startIcon={<Plus />} onClick={navigateToNewTestClaimStatement}>NEW TEST CASE</SlimButton>
          <SlimButton startIcon={<Plus />} onClick={navigateToNewClaimStatement}>NEW CLAIM STATEMENT</SlimButton>
        </ButtonGroup>
      </ButtonsRow>
      <TableArea>
        {(!favourites || isValidating)
          ? <TableLoader />
          : (
            <Pane color="green" padding="16px 0px 0px">
              <ClaimStatementTable
                claimStatements={claimStatements}
                favourites={favourites}
                onRowClick={handleRowClick}
                onStarClick={onStarClick}
                getContextMenuOptions={getContextMenuOptions}
                getClaimIsNotOwn={getClaimIsNotOwn}
                claimStatementStatus={claimStatementStatus.selectedStatus}
                role={role}
              />
            </Pane>
          )}
      </TableArea>
    </PageContent>
  );
};
