import { CheckboxControlledStyled } from '@/components/pages/claim/ClaimStatementPage/InvoiceTable/core/Components';
import React from 'react';
import { ActionTableCell } from './ActionTableCell';

interface CheckboxCellProps {
  rowId: string;
  selected: boolean;
  onClick?: () => void;
  selectableRows: boolean;
}
/**
 * Component featuring a readily set-up checkbox that can be used in the rows
 * of a table. The cell's click-area is reduced to be just around the checkbox
 * itself, preventing propagation in case the table row above it has a click
 * handler of sort (such as clicking an entire row to navigate to another page).
 * ...props spread is to propagate data-attributes from TableRows component.
 */
export const CheckboxCell = ({ rowId, selected, onClick, selectableRows, ...props }: CheckboxCellProps) => (
  <ActionTableCell onClick={e => e.stopPropagation()} width="64px" {...props}>
    {selectableRows && (
      <CheckboxControlledStyled
        name={`${rowId}-checkbox`}
        selected={selected}
        onChange={onClick}
        noTopLabel
        noErrors
      />
    )}
  </ActionTableCell>
);
