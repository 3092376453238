import { editableStates } from '@/components/pages/invoice/InvoicePage/SurveyorInvoicePage';
import { InvoiceRequestStatus } from '@/services/invoice/invoiceServices';
import { Invoice } from '@/types';
import { ButtonGroup } from '@instech/components';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { useNavigate } from 'react-router-dom';
import { InvoiceTraversalButtons } from './buttons/InvoiceTraversalButtons';
import {
  GrayCloseButton, GreenCloseButton, GreenSaveButton
} from './buttons/HeaderButtons';
import { useSaveStatus } from '../utils/SaveStatus/useSaveStatus';

interface InvoiceActionButtonsSurveyorProps {
  ownsLock?: boolean;
  invoice: Invoice;
  saveInvoice: () => Promise<Invoice | InvoiceRequestStatus>
}
export const InvoiceActionButtonsSurveyor = ({ ownsLock, saveInvoice, invoice }: InvoiceActionButtonsSurveyorProps) => {
  const navigate = useNavigate();
  const navigateToClaimStatementPage = () => navigate(`/claimstatements/${invoice.claimStatementId}`);
  const [saveStatus, setSaveStatus] = useSaveStatus();

  const editable = ownsLock && editableStates.includes(invoice.state);
  const readonly = !ownsLock || !editableStates.includes(invoice.state);

  const handleSave = async () => {
    setSaveStatus('saving');
    await saveInvoice();
    setSaveStatus('saved');
  };

  return (
    <ButtonGroup>
      <SharedTooltip
        id="invoice-traversal-tooltip"
        bodyMode={false}
        effect="solid"
        place="bottom">
        {editable && <GrayCloseButton onClick={navigateToClaimStatementPage} />}
        <InvoiceTraversalButtons claimStatementId={invoice.claimStatementId} saveAction={saveInvoice} />
        {editable && <GreenSaveButton status={saveStatus} onClick={handleSave} />}
        {readonly && <GreenCloseButton onClick={navigateToClaimStatementPage} />}
      </SharedTooltip>
    </ButtonGroup>
  );
};
