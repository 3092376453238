import { InformationNotification } from '@instech/components';
import { FunctionComponent as FC } from 'react';
import styled from 'styled-components';

const StyledInfoNotification = styled(InformationNotification)`
  margin-bottom: 20px;
`;

interface Props {
  isVisible?: boolean;
}
export const NoExchangeRateInfo: FC<Props> = ({ isVisible }) => {
  const text = 'Unable to show Policy Currency values, as no Exchange Rate is entered.';

  if (!isVisible) {
    return null;
  }

  return (
    <StyledInfoNotification size="small" headingText={text} />
  );
};
