import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { PdfPreviewPane } from '@/apps/Adjustment/pages/AdjustmentPage/chapters/ClaimStatementInformation/core/PdfPreviewPane';
import { DetailType } from '@/components/pages/claim/core/ClaimDetails/types';
import {
  ClaimStatementInformationData, OccurrenceInformation, OccurrencesRequest, UpdateAdjustmentOverviewRequest, VesselInformation
} from '@/apps/Adjustment/types';
import { v4 as uuidv4 } from 'uuid';
import {
  CollapsiblePane, Loader, TabbedPane, TextArea
} from '@instech/components';
import { useAdjustmentOverview } from '@/apps/Adjustment/services/adjustmentOverviewService';
import { useRequireClaimStatementId } from '@/utils/useRequireClaimStatementId';
import { Formik } from 'formik';
import { SummaryItemSmall } from '@/common/components/Summary';
import { FormWithAutoSave } from '../../core/Components';
import { OccurrenceList } from './core/OccurrenceList';
import { ClaimStatementInfomrationImage } from './core/ClaimStatementInfomrationImage';

const Layout = styled.div`
  display: grid;
  grid-template-columns: minmax(600px, auto) minmax(min-content, 580px);
  grid-gap: 32px;
  box-sizing: border-box;
`;

const Container = styled.div`
  flex-grow: 1;
  display: grid;
  column-gap: 24px;
  row-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  color: ${props => props.theme.marineBlue};
  padding: 4px 0px;
`;

const LoaderContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: 68px;
`;

const createFieldDetail = (id: string, title: string, value?: string | number): DetailType =>
  ({ id, title, value: value ? value.toString() : '-' });

const getClaimFields = (data?: VesselInformation): DetailType[] => {
  if (!data) return [];
  return ([
    createFieldDetail(uuidv4(), 'Vessel Name', data.vesselName),
    createFieldDetail(uuidv4(), 'Build Year', data.buildYear),
    createFieldDetail(uuidv4(), 'Gross Tonnage', data.grossTonnage),
    createFieldDetail(uuidv4(), 'IMO', data.imoNumber),
  ]);
};

const initialValues = (claimStatementInfoData: ClaimStatementInformationData | null | undefined) => ({
  occurrences: (claimStatementInfoData?.occurrences ?? []) as OccurrencesRequest[],
  claimStatementInformationInternalNote: claimStatementInfoData?.internalNote
});

const newOccurenceTab = (title: string, occurrence: OccurrenceInformation, index: number) => ({
  title, component: <OccurrenceList occurrence={occurrence || []} index={index} />
});

interface Props { }
export const ClaimStatementInformation: FC<Props> = () => {
  const claimStatementId = useRequireClaimStatementId();
  const { data: adjustmentOverview, mutate, updateAdjustmentOverview } = useAdjustmentOverview(claimStatementId);

  const tabs = useMemo(
    () => adjustmentOverview?.claimStatementInformation.occurrences.map((occurrence, index) => newOccurenceTab(occurrence.name, occurrence, index)),
    [adjustmentOverview]
  );

  if (!adjustmentOverview) {
    return <LoaderContainer><Loader /></LoaderContainer>;
  }

  const details = getClaimFields(adjustmentOverview?.claimStatementInformation.vesselInformation);

  const handleSave = async (request: UpdateAdjustmentOverviewRequest) => {
    const result = await updateAdjustmentOverview(adjustmentOverview.id, request);
    await mutate(result.data);
  };

  const handleSubmit = async (form: { occurrences: OccurrencesRequest[], claimStatementInformationInternalNote?: string }) => {
    const request = { occurrences: form.occurrences, claimStatementInformationInternalNote: { value: form.claimStatementInformationInternalNote } };
    await handleSave(request);
  };

  return (
    <Layout>
      <div>
        <Formik initialValues={initialValues(adjustmentOverview?.claimStatementInformation)} onSubmit={handleSubmit} enableReinitialize>
          <FormWithAutoSave>
            <CollapsiblePane color="green" title="Vessel Information" padding="20px">
              <InfoRow>
                <Container>
                  {details?.map(detail => (
                    <SummaryItemSmall key={detail.id} item={detail} wrapText />
                  ))}
                </Container>
              </InfoRow>
            </CollapsiblePane>
            <TabbedPane
              title="Occurrences"
              color="green"
              tabs={tabs!}
              margin="20px 0px 0px 0px"
              padding="20px"
            />
            <ClaimStatementInfomrationImage claimStatementId={claimStatementId} adjustmentId={adjustmentOverview.id} />
            <CollapsiblePane color="green" title="Internal Notes" padding="20px" margin="20px 0px 0px">
              <TextArea
                name="claimStatementInformationInternalNote"
                label="Internal Notes - Only visible for CH/Adjuster" />
            </CollapsiblePane>
          </FormWithAutoSave>
        </Formik>
      </div>
      <PdfPreviewPane />
    </Layout>
  );
};
