import { Invoice, AdjustersConsiderationForm } from '@/types';

const numOrZero = (val?: number | string | null) => Number(val) || 0;

const twoDigits = (number: number) => Math.round(number * 100) / 100;

const reduceNumbers = (a: any, b: any) => (Number(a) || 0) + (Number(b) || 0);

interface OwnersDeviationData {
  totalInvoiceAmount?: number;
  claim?: number;
  ownersWork?: number;
}
export const getOwnersDeviation = (deviationData: OwnersDeviationData) => {
  const total = numOrZero(deviationData.totalInvoiceAmount);
  const claim = numOrZero(deviationData.claim);
  const ownersWork = numOrZero(deviationData.ownersWork);

  const sumSplit = claim + ownersWork;
  return twoDigits(sumSplit - total);
};

interface SurveyorsDeviationData {
  totalInvoiceAmount?: number;
  generalExpenses?: number;
  claimConsideration?: number;
  ownersWorkConsideration?: number;
  adjustersConsideration?: number;
}
export const getSurveyorsDeviation = (deviationData: SurveyorsDeviationData) => {
  const total = numOrZero(deviationData.totalInvoiceAmount);
  const generalExpenses = numOrZero(deviationData.generalExpenses);
  const claimCons = numOrZero(deviationData.claimConsideration);
  const ownersWorkCons = numOrZero(deviationData.ownersWorkConsideration);
  const adjustersCons = numOrZero(deviationData.adjustersConsideration);

  const sumConsideration = generalExpenses + claimCons + ownersWorkCons + adjustersCons;

  return twoDigits(sumConsideration - total);
};

interface AdjustersDeviationData {
  totalInvoiceAmount?: number;
  commonExpenses?: string | number;
  generalAverage?: string | number;
  particularAverage?: string | number;
  ownersWorkConsideration?: string | number;
}
export const getAdjustersDeviation = (deviationData: AdjustersDeviationData) => {
  const total = numOrZero(deviationData.totalInvoiceAmount);
  const commonExpenses = numOrZero(deviationData.commonExpenses);
  const generalAverage = numOrZero(deviationData.generalAverage);
  const particularAvg = numOrZero(deviationData.particularAverage);
  const ownersWorkCons = numOrZero(deviationData.ownersWorkConsideration);

  const sumConsideration = commonExpenses + generalAverage + particularAvg + ownersWorkCons;
  return twoDigits(sumConsideration - total);
};

interface TotalDeviationProps {
  claim?: number | string | null;
  ownersWork?: number | string | null;
  total?: number | string | null;
}

/**
 * Test boolean on whether Owner's invoice values have a deviation.
 */
export const hasTotalDeviation = (values: TotalDeviationProps) => {
  const deviation = getOwnersDeviation({
    totalInvoiceAmount: Number(values.total),
    claim: Number(values.claim),
    ownersWork: Number(values.ownersWork)
  });
  return deviation !== 0;
};

// used on Mark as Finished from Claimshandler, where the data is not updated to the invoice yet
export const hasAdjustersDeviationForm = (totalAmount: number | null | undefined, form: AdjustersConsiderationForm) => {
  const activeSplits = form.splits.filter(split => !split.isDeleted).map(split => ({
    commonExpenses: split.form.commonExpensesConsidered ? split.commonExpenses : 0,
    generalAverage: split.form.generalAverageConsidered ? split.generalAverage : 0,
    particularAverage: split.form.particularAvgConsidered ? split.particularAverage : 0,
    ownersWorkConsideration: split.form.ownersWorkConsidered ? split.ownersWorkConsideration : 0,
  }));
  if (activeSplits.length === 0) return true;

  const splitDeviations = activeSplits.reduce((total, consideration) => ({
    commonExpenses: reduceNumbers(total.commonExpenses, consideration.commonExpenses),
    generalAverage: reduceNumbers(total.generalAverage, consideration.generalAverage),
    particularAverage: reduceNumbers(total.particularAverage, consideration.particularAverage),
    ownersWorkConsideration: reduceNumbers(total.ownersWorkConsideration, consideration.ownersWorkConsideration)
  }));

  const deviation = getAdjustersDeviation({
    totalInvoiceAmount: totalAmount ?? undefined,
    commonExpenses: splitDeviations.commonExpenses ?? undefined,
    generalAverage: splitDeviations.generalAverage ?? undefined,
    particularAverage: splitDeviations.particularAverage ?? undefined,
    ownersWorkConsideration: splitDeviations.ownersWorkConsideration ?? undefined
  });

  return deviation !== 0;
};

/**
 * Run through provided invoice Ids, find corresponding data, and return True if
 * any one invoice of those provided has deviation in consideration vs invoice total.
 */
export const invoicesWithSurveyorDeviation = (selectedRows: string[], invoices: Invoice[]): Invoice[] =>
  invoices
    .filter(x => selectedRows.includes(x.id))
    .filter(x => x.surveyorsConsideration?.deviationPresent || !x.surveyorsConsideration?.isConsidered);

export const invoicesWithoutSurveyorDeviation = (selectedRows: string[], invoices: Invoice[]): Invoice[] =>
  invoices
    .filter(x => selectedRows.includes(x.id))
    .filter(x => !x.surveyorsConsideration?.deviationPresent && x.surveyorsConsideration?.isConsidered);
