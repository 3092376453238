import { Breadcrumbs, reusableSteps } from '@/common/components/Breadcrumbs';
import {
  HeadingWrapper,
  NextArrow,
  StickyPageHeading
} from '@/common/layout/PageLayout';
import { ClaimStatement } from '@/types';
import { ButtonGroup, SlimButton } from '@instech/components';
import { FunctionComponent } from 'react';

const getBreadcrumbs = (vesselName: string) => ([
  reusableSteps.myDashboardStep,
  { label: vesselName, to: './../..' },
]);

interface Props {
  claimStatement: ClaimStatement;
  onSubmit: () => void;
  onCancel: () => void;
  disableSubmit?: boolean;
}
export const UploadPageHeading: FunctionComponent<Props> = ({
  claimStatement,
  onSubmit,
  onCancel,
  disableSubmit,
}) => {
  const currentPage = 'Upload invoices';
  const breadcrumbSteps = getBreadcrumbs(claimStatement.vesselName);
  return (
    <StickyPageHeading>
      <HeadingWrapper>
        <Breadcrumbs steps={breadcrumbSteps} current={currentPage} />
        <ButtonGroup>
          <SlimButton
            variant="secondary"
            width="90px"
            onClick={onCancel}>
            CANCEL
          </SlimButton>
          <SlimButton
            width="90px"
            endIcon={<NextArrow />}
            onClick={onSubmit}
            disabled={disableSubmit}>
            NEXT
          </SlimButton>
        </ButtonGroup>
      </HeadingWrapper>
    </StickyPageHeading>
  );
};
