import { useClaimStatementCategoriesAsOptions } from '@/services/categoriesServices';
import { InvoiceRequestStatus, useInvoice } from '@/services/invoice/invoiceServices';
import { useClaimStatementLocationsAsOptions } from '@/services/locationsServices';
import {
  ClaimStatement, Invoice, UpdateSurveyorsConsiderationForm
} from '@/types';
import { LabelValuePair, PageLoader } from '@instech/components';
import { InvoiceFormSurveyor } from './InvoiceForm/InvoiceFormSurveyor';

interface EditInvoiceProps {
  claimStatement: ClaimStatement;
  invoice: Invoice;
  handleInvoiceConflict: () => void;
}
export const EditInvoiceSurveyor = ({ claimStatement, invoice, handleInvoiceConflict }: EditInvoiceProps) => {
  const { updateInvoiceSurveyor } = useInvoice(claimStatement.id, invoice.id);
  const { locationOptions, mutate: refreshLocations } = useClaimStatementLocationsAsOptions(claimStatement.id);
  const categoryOptions = useClaimStatementCategoriesAsOptions(claimStatement.id);
  const occurrenceOptions: LabelValuePair[] = claimStatement.occurrences.map(occurrence => ({ label: occurrence.name, value: occurrence.id }));

  const handleUpdate = async (updateForm: UpdateSurveyorsConsiderationForm): Promise<InvoiceRequestStatus> => {
    const result = await updateInvoiceSurveyor(updateForm, invoice);
    if (result === 'conflict') handleInvoiceConflict();
    await refreshLocations();

    return result;
  };

  if (!locationOptions || !categoryOptions) {
    return <PageLoader />;
  }

  return (
    <InvoiceFormSurveyor
      invoice={invoice}
      claimStatement={claimStatement}
      locationOptions={locationOptions}
      categoryOptions={categoryOptions}
      occurrenceOptions={occurrenceOptions}
      updateInvoice={handleUpdate}
    />
  );
};
