export const transferOwnerToClaimsHandlerModalTexts = (numberOfIncompleteInvoices: number, numberOfInvoices: number) => {
  if (numberOfIncompleteInvoices === numberOfInvoices) {
    // None of the invoices are complete
    return {
      title: numberOfInvoices === 1 ? 'This invoice is incomplete.' : 'These invoices are incomplete.',
      body: ['Please enter the required information before submitting.']
    };
  }

  if (numberOfIncompleteInvoices > 0) {
    // Some of the invoices are complete
    const completeInvoices = numberOfInvoices - numberOfIncompleteInvoices;
    return {
      title: `${completeInvoices === 1 ? '1 invoice' : `${completeInvoices} invoices`} will be submitted to Claims Handler.`,
      body: ["Submitted invoices will be moved to your 'Submitted to Claims Handler' table. You will not be able to edit them after submitting."],
      footer: `${numberOfIncompleteInvoices === 1 ? '1 invoice is' : `${numberOfIncompleteInvoices} invoices are`} incomplete and will not be submitted.`
    };
  }

  if (numberOfInvoices === 1) {
    // There is only one invoice, and it is complete
    return {
      title: 'This invoice will be submitted to Claims Handler.',
      body: ["It will be moved to your 'Submitted to Claims Handler' table.", 'You will not be able to edit the invoice after submitting.']
    };
  }

  // All invoices are complete
  return {
    title: `${numberOfInvoices} invoices will be submitted to Claims Handler.`,
    body: ["They will be moved to your 'Submitted to Claims Handler' table.", 'You will not be able to edit the invoices after submitting.']
  };
};
