import { Invoice } from '@/types';
import { ButtonGroup } from '@instech/components';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { useAnchor } from '@/utils/hooks/useAnchor';
import { GreenCloseButton } from '../../core/EditInvoice/InvoiceForm/Headers/buttons/HeaderButtons';
import { InvoiceTraversalButtons } from '../../core/EditInvoice/InvoiceForm/Headers/buttons/InvoiceTraversalButtons';

interface NavigationalActionButtonProps {
  anchorId: string;
  invoice: Invoice;
  isReview?: boolean;
  additionalActionButtons?: JSX.Element;
}
export const NavigationalActionButtons = ({ anchorId, invoice, isReview = false, additionalActionButtons }: NavigationalActionButtonProps) => {
  const actionButtonsAnchor = useAnchor(anchorId);
  const navigate = useNavigate();
  const reviewSuffix = isReview ? '/review': '';
  const navigateToClaimStatementPage = () => navigate(`/claimstatements/${invoice.claimStatementId}${reviewSuffix}`);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {actionButtonsAnchor && createPortal(
        <ButtonGroup>
          {additionalActionButtons}
          <InvoiceTraversalButtons claimStatementId={invoice.claimStatementId} isReview={isReview} />
          <GreenCloseButton onClick={navigateToClaimStatementPage} />
        </ButtonGroup>,
        actionButtonsAnchor
      )}
    </>
  );
};
