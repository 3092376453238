import { ModalType } from '@/common/modules/Modal/types';
import {
  NumberCell, ReorderSplitsActionButtons,
  ReorderTableRow,
  SortingContext, ReorderTable, useReorderSplits
} from '@/apps/ClaimStatement/components/InvoiceSplitTable/ReorderSplitsModal';
import { SurveyorsConsiderationSplitForm } from '@/types';
import { calculateSurveyorValues } from '@/utils/invoice/calculateSummaries';
import { Reorder } from '@instech/icons';

export interface ReorderSurveyorSplitsProps {
  splits: SurveyorsConsiderationSplitForm[];
  updateSplitsOrder: (newSplitOrder: SurveyorsConsiderationSplitForm[]) => void;
  exchangeRate: number | null | undefined;
  usingInvoiceCurrency: boolean;
}
const ModalContent = ({ splits, updateSplitsOrder, exchangeRate, usingInvoiceCurrency }: ReorderSurveyorSplitsProps) => {
  const { handleDragEnd, orderedSplits, saveStatus, handleSave, handleClose } = useReorderSplits(splits.map(split => ({
    values: calculateSurveyorValues(split, exchangeRate, usingInvoiceCurrency),
    id: split.id,
    originalSplit: split
  })), updateSplitsOrder);

  return (
    <SortingContext onDragEnd={handleDragEnd} items={orderedSplits.map(split => split.id!)}>
      <ReorderTable>
        <thead>
          <tr>
            <th aria-label="Drag handler" />
            <th>Occurrence</th>
            <th>Category</th>
            <NumberCell>Claim</NumberCell>
            <NumberCell>Owner&apos;s</NumberCell>
            <NumberCell>Gen. Exp.</NumberCell>
            <NumberCell>Adj. Cons.</NumberCell>
            <NumberCell>Split sum</NumberCell>
          </tr>
        </thead>
        <tbody>
          {orderedSplits.map(({ id, values }) => (
            <ReorderTableRow id={id!} key={id}>
              <td aria-label="reorder"><Reorder /></td>
              <td>{values.occurrence}</td>
              <td>{values.category}</td>
              <NumberCell>{values.claim}</NumberCell>
              <NumberCell>{values.ownersWork}</NumberCell>
              <NumberCell>{values.genExpenses}</NumberCell>
              <NumberCell>{values.adjConsideration}</NumberCell>
              <NumberCell>{values.splitSum}</NumberCell>
            </ReorderTableRow>
          ))}
        </tbody>
      </ReorderTable>
      <ReorderSplitsActionButtons saveButtonState={saveStatus} onClose={handleSave} onCancel={handleClose} />
    </SortingContext>
  );
};

export const reorderSurveyorSplitsModal = (props: ReorderSurveyorSplitsProps): ModalType<ReorderSurveyorSplitsProps> => ({
  component: ModalContent,
  options: {
    title: 'Reorder Splits',
    size: 'large',
    padding: '20px'
  },
  args: props,
});
