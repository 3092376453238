import { Formik } from 'formik';
import styled from 'styled-components';
import { ButtonGroup, SlimButton } from '@instech/components';
import { useModalContext } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import { CompositeSupportingDocumentId, useSupportingDocuments } from '@/services/supportingDocumentsServices';
import { ContentHorizontalCenter } from '@/common/layout/Content';
import { LargeBoldText, RegularText } from '@/common/components/Text';
import { WarningLarge } from '@/common/components/Icon/WarningLarge';

const TextWrapper = styled.div`
  text-align: center;
  padding: 10px 0;
`;

const StyledBoldText = styled(LargeBoldText)`
  margin-bottom: 15px;
`;

interface DeleteSupportingDocumentModal {
  id: CompositeSupportingDocumentId;
}
const ModalContent = ({ id }: DeleteSupportingDocumentModal) => {
  const { close } = useModalContext();
  const { deleteSupportingDocument } = useSupportingDocuments(id);

  const handleDelete = async () => {
    await deleteSupportingDocument(id.supportingDocumentId);
    close();
  };

  return (
    <Formik initialValues={{}} onSubmit={handleDelete}>
      {({ submitForm, isSubmitting }) => (
        <>
          <ContentHorizontalCenter>
            <WarningLarge />
            <TextWrapper>
              <StyledBoldText>The document will be deleted</StyledBoldText>
              <RegularText>{`Are you sure you want to delete "${id.documentName}"?`}</RegularText>
              <RegularText>You can&apos;t undo this action.</RegularText>
            </TextWrapper>
          </ContentHorizontalCenter>
          <ButtonGroup alignRight marginTop="24px">
            <SlimButton variant="secondary" onClick={close}>
              CANCEL
            </SlimButton>
            <SlimButton onClick={submitForm} disabled={isSubmitting} loading={isSubmitting} width="64px">
              DELETE
            </SlimButton>
          </ButtonGroup>
        </>
      )}
    </Formik>
  );
};

export const deleteSupportingDocumentModal =
  ({ id: compositeSupportingDocumentId }: DeleteSupportingDocumentModal): ModalType<DeleteSupportingDocumentModal> => ({
    component: ModalContent,
    options: {
      title: 'Delete Document',
      size: 'small',
      padding: '12px 20px 20px'
    },
    args: { id: compositeSupportingDocumentId }
  });

export const defaultDeleteSupportingDocumentModal: DeleteSupportingDocumentModal = {
  id: { claimStatementId: '', invoiceId: '', supportingDocumentId: '', documentName: '' }
};
