import { InvoiceRecord } from '@/types';
import { LabelValuePair } from '@instech/components';
import { useField } from 'formik';
import { FunctionComponent as FC, useMemo } from 'react';
import styled from 'styled-components';
import { calculateSurveyorReadonlySplitValues } from '@/utils/invoice/calculateSummaries';
import { DividerLine } from '@/apps/ClaimStatement/components/InvoiceSplitTable';
import { useSurveyorSplitById } from '../../../InvoiceForm/utils/SurveyorSplitsContext';
import { CostFields } from '../CostFields';
import { DeletedSplitItemAdjuster } from './DeletedSplitItemAdjuster';
import { ReadonlySurveyorItem } from './ReadonlySurveyorItem';

const Container = styled.div<{ isMultiple?: boolean; isDeleted?: boolean }>`
  padding: ${props => props.isMultiple && '0px 16px 16px;'};
  background: ${props => props.isDeleted ? props.theme.adjuster.deleted : 'inherit'};
`;

interface Props {
  arrayName: string;
  index: number;
  invoiceRecord: InvoiceRecord;
  categoryOptions: LabelValuePair[];
  repairPeriodOptions: LabelValuePair[];
  occurrenceOptions: LabelValuePair[];
  onAddSplit?: () => void;
  onRemoveSplit: (index: number) => void;
  onRestoreSplit?: (index: number) => void;
  selectedCurrency?: string | null;
  surveyorSplitId?: string;
  useInvoiceCurrency?: boolean;
  viewAsListOfSplits?: boolean;
  showApplyValuesOwner?: boolean;
  showApplyValuesSurveyor?: boolean;
  onApplyValues?: () => void;
  applyValuesDisabled?: boolean;
  showGeneralAverage: boolean;
}

export const SplitItemAdjuster: FC<Props> = ({
  arrayName,
  index,
  invoiceRecord,
  categoryOptions,
  repairPeriodOptions,
  occurrenceOptions,
  onAddSplit,
  onRemoveSplit,
  onRestoreSplit,
  selectedCurrency,
  surveyorSplitId,
  useInvoiceCurrency,
  viewAsListOfSplits,
  showApplyValuesOwner,
  showApplyValuesSurveyor,
  onApplyValues,
  applyValuesDisabled,
  showGeneralAverage
}) => {
  const [showNoteField, , showNoteHelpers] = useField(`${arrayName}.${index}.form.hasInternalNotes`);
  const [, , noteHelpers] = useField(`${arrayName}.${index}.internalNotes`);
  const [deletedField, , deletedFieldHelper] = useField(`${arrayName}.${index}.isDeleted`);
  const [outdatedField] = useField(`${arrayName}.${index}.form.isOutdated`);
  const surveyorSplit = useSurveyorSplitById(surveyorSplitId);
  const showNote = showNoteField.value;
  const isOutdated = outdatedField.value;
  const isDeletedByAdjuster = deletedField.value;
  const isDeletedBySurveyor = surveyorSplitId !== undefined && !surveyorSplit;

  const readonlySurveyorSplit = useMemo(
    () => calculateSurveyorReadonlySplitValues(surveyorSplit, invoiceRecord.exchangeRate, useInvoiceCurrency),
    [surveyorSplit, invoiceRecord.exchangeRate, useInvoiceCurrency]
  );

  // On click, flag boolean to show field
  const addInternalNote = () => {
    showNoteHelpers.setValue(true);
  };

  // On click, flag boolean to hide field and clear actual note field
  const deleteInternalNote = () => {
    showNoteHelpers.setValue(false);
    noteHelpers.setValue('');
  };

  const restoreItem = () => {
    if (deletedFieldHelper) {
      deletedFieldHelper.setValue(false);
    }
    if (onRestoreSplit) onRestoreSplit(index);
  };

  return (
    <Container isMultiple={viewAsListOfSplits} isDeleted={isDeletedByAdjuster}>
      {viewAsListOfSplits && <DividerLine />}
      {isDeletedByAdjuster && viewAsListOfSplits && (
        <DeletedSplitItemAdjuster onRestore={restoreItem} />
      )}
      {!isDeletedByAdjuster && (
        <CostFields
          arrayName={arrayName}
          index={index}
          invoiceRecord={invoiceRecord}
          categoryOptions={categoryOptions}
          repairPeriodOptions={repairPeriodOptions}
          occurrenceOptions={occurrenceOptions}
          onAddSplit={onAddSplit}
          onRemoveSplit={onRemoveSplit}
          selectedCurrency={selectedCurrency}
          useInvoiceCurrency={useInvoiceCurrency}
          viewAsListOfSplits={viewAsListOfSplits}
          showNote={showNote}
          addInternalNote={addInternalNote}
          deleteInternalNote={deleteInternalNote}
          showApplyValuesOwner={showApplyValuesOwner}
          showApplyValuesSurveyor={showApplyValuesSurveyor}
          onApplyValues={onApplyValues}
          applyValuesDisabled={applyValuesDisabled}
          showGeneralAverage={showGeneralAverage}
        />
      )}
      <ReadonlySurveyorItem
        viewAsListOfSplits={viewAsListOfSplits}
        isOutdated={isOutdated}
        item={readonlySurveyorSplit}
        deletedByAdjuster={isDeletedByAdjuster}
        deletedBySurveyor={isDeletedBySurveyor}
      />
    </Container>
  );
};
