import { LeftArrow, RightArrow } from '@instech/icons';
import styled, { css } from 'styled-components';

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px; // min height even when buttons are not in heading
  padding: 20px 40px;
`;

export const HeadingLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 32px;
`;

export const HeaderText = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: ${props => props.theme.marineBlue};
`;

const NavigationArrow = css`
  height: 20px;
  stroke-width: 2;
`;

export const PreviousArrow = styled(LeftArrow)`
  ${NavigationArrow}
`;

export const NextArrow = styled(RightArrow)`
  ${NavigationArrow}
`;
