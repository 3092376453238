import { CollapsibleTableSchemaReturn } from '@/common/components/Table/CollapsibleTable/types';
import { ExpandedRows } from '@/common/components/Table/utils';
import { ClaimStatementSettings, Invoice } from '@/types';
import { useMemo } from 'react';
import { GroupingOption, groupingOptions } from '@/common/components/Table/TableGrouping/types';
import { createTableSchemaByCategory } from './tableSchemaByCategory/tableSchemaByCategory';
import { createTableSchemaForPrint } from './tableSchemaForPrint/tableSchemaForPrint';
import { createTableSchemaNoGrouping } from './tableSchemaNoGrouping/tableSchemaNoGrouping';

export interface CSTableProps {
  selectableRows: boolean;
  showReview: boolean;
  currency: string;
  claimStatementSettings?: ClaimStatementSettings;
  usePrintSchema?: boolean;
  showReviewComment?: boolean;
  showDraftReviewComment?: boolean;
  onRowClick: (id: string, idList: string[]) => void;
  toggleExpanded: (id: string) => void;
  expanded: ExpandedRows;
}

/**
 * Memo hook for invoice table schemas for the Claims Handler. Able to provide
 * one of several different configurations depending on the provided `grouping`.
 *
 * Any properties outside of the grouping and data should be added to the `props`
 * object and then retrieved where needed. Do this with properties that are
 * at least semi-constant, and avoid doing it with props that knowingly will
 * change often. In those scenarios, you may want to use a Context around the
 * table instead.
 */
export const useTableSchemaByGrouping = (
  grouping: GroupingOption,
  data: Invoice[],
  props: CSTableProps
): CollapsibleTableSchemaReturn => useMemo(() => {
  const { usePrintSchema } = props;
  switch (grouping) {
    case groupingOptions.category:
      // Hard disabled canSelect on Category for now
      return createTableSchemaByCategory(data, props);
    case groupingOptions.noGroup:
      if (usePrintSchema) return createTableSchemaForPrint(data, props);
      return createTableSchemaNoGrouping(data, props);
    default:
      return createTableSchemaNoGrouping(data, props);
  }
}, [data, grouping, props]);
