import { InvoiceMessages } from '@/components/pages/invoice/InvoicePage/core/Components';
import { useIsClosed } from '@/services/claimStatementServices';
import {
  ClaimStatement, Invoice, LatestInstructions
} from '@/types';
import { invoiceStates } from '@/utils/constants';
import { FunctionComponent as FC } from 'react';
import { EditInvoiceOwner } from '../../core/EditInvoice/EditInvoiceOwner';
import { AdditionalInformationPane, OwnersClaimPane } from '../../core/ReadOnlyInvoice';
import { InstructionsPane } from '../../core/ReadOnlyInvoice/InstructionsPane';
import { NavigationalActionButtons } from './NavigationalActionButtons';
import { useInvoiceConflict } from './invoiceConflict';

interface Props {
  invoice: Invoice;
  claimStatement: ClaimStatement;
  ownsLock: boolean;
  ownerInstructions?: LatestInstructions;
}

interface ReadOnlyProps {
  invoice: Invoice;
  claimStatement: ClaimStatement;
}

const hasValue = (value?: string | null) => typeof value === 'string' && value.length > 0;

const ReadOnlyView: FC<ReadOnlyProps> = ({ claimStatement, invoice }) => (
  <>
    <OwnersClaimPane
      claimStatement={claimStatement}
      invoice={invoice}
      showNotes={hasValue(invoice.record?.notes)}
      policyCurrency={claimStatement.currency}
      readonly />
    <AdditionalInformationPane invoice={invoice} />
  </>
);

/**
 * Switch component for handling whether to show the Edit or Readonly view of the
 * left-hand invoice details side of an invoice page. One such component for reach
 * role, to handle different rendering scenarios for different roles.
 * The default view is readonly, with the edit view enabled by a prop.
 */
export const InvoiceDetailsOwner: FC<Props> = ({ invoice, claimStatement, ownsLock, ownerInstructions }) => {
  const { conflict, handleInvoiceConflict } = useInvoiceConflict();
  // fallback if an invoice is submitted with only space for instructions
  const trimmedInstructions = ownerInstructions?.instructions?.trim();
  const isClosed = useIsClosed(claimStatement);
  const isReadonly = !ownsLock || isClosed;

  switch (invoice.state) {
    case invoiceStates.New:
    case invoiceStates.ReturnToUploader:
    case invoiceStates.ReturnToUploaderAfterConsideration:
    case invoiceStates.ReturnToUploaderAfterConsiderationNoAllocation:
    case invoiceStates.ReturnToUploaderAfterSharedReview:
      return (
        <div>
          {isReadonly && (
            <NavigationalActionButtons
              anchorId="owner-action-buttons"
              invoice={invoice}
            />
          )}
          <InvoiceMessages
            conflict={conflict}
            ownsLock={ownsLock}
            isClaimStatementClosed={isClosed}
          />
          {trimmedInstructions && (
            <InstructionsPane
              title="Feedback from Claims Handler"
              instructions={trimmedInstructions}
            />
          )}
          {!isReadonly && (
            <EditInvoiceOwner
              claimStatement={claimStatement}
              invoice={invoice}
              handleInvoiceConflict={handleInvoiceConflict}
            />
          )}
          {isReadonly && (
            <ReadOnlyView
              claimStatement={claimStatement}
              invoice={invoice}
            />
          )}
        </div>
      );
    default:
      return (
        <div>
          <NavigationalActionButtons
            anchorId="owner-action-buttons"
            invoice={invoice}
          />
          <InvoiceMessages isClaimStatementClosed={isClosed} />
          {trimmedInstructions && (
            <InstructionsPane
              title="Feedback from Claims Handler"
              instructions={trimmedInstructions}
            />
          )}
          <ReadOnlyView
            claimStatement={claimStatement}
            invoice={invoice}
          />
        </div>
      );
  }
};
