import { patchAsync, postFormAsync } from '@/services/client';
import useSWR from 'swr';
import { AxiosResponse } from 'axios';
import { validateFiletypeAndSize } from '@/utils/file';
import { FileValidationError, imageFileTypes } from '@/utils/file/file';
import {
  AdjustmentOverview, ClaimStatementInformationImage, UpdateAdjustmentOverviewRequest
} from '../types';

const baseUrl = (claimStatementId: string) => `claimstatements/${claimStatementId}/adjustmentOverview`;

export const useAdjustmentOverview = (claimStatementId: string) => {
  const { data, mutate, error } = useSWR<AdjustmentOverview>(baseUrl(claimStatementId), { suspense: true });
  const updateAdjustmentOverview = async (adjustmentId: string, updateData: UpdateAdjustmentOverviewRequest) => {
    // Empty name should be replaced with TBA
    const updatedRequestData = {
      ...updateData,
      occurrences: updateData.occurrences?.map(occurrence => ({
        ...occurrence,
        name: occurrence.name ? occurrence.name : 'TBA'
      })),
    };

    return patchAsync<AdjustmentOverview>(`${baseUrl(claimStatementId)}/${adjustmentId}`, updatedRequestData);
  };

  return { data: data!, mutate, updateAdjustmentOverview, error };
};

export const useAdjClaimStatementInformationImage = (claimStatementId: string, adjustmentId: string) => {
  const imageUrl = `${baseUrl(claimStatementId)}/${adjustmentId}/csinformationimages`;

  const { data, mutate, error } = useSWR<ClaimStatementInformationImage>(imageUrl);

  const uploadInfoImage = async (image: File):
    Promise<FileValidationError | AxiosResponse<ClaimStatementInformationImage, any>> => {
    const errorMessage = validateFiletypeAndSize(image, imageFileTypes);
    if (errorMessage) {
      return errorMessage;
    }

    const formData = new FormData();
    formData.append('imageFile', image as Blob);
    const response = postFormAsync(imageUrl, formData);
    return response;
  };

  return { data, mutate, uploadInfoImage, error };
};
