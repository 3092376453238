import { Breadcrumb, Breadcrumbs } from '@/common/components/Breadcrumbs';
import { InvoiceStateBadge } from '@/apps/ClaimStatement/components/InvoiceState';
import { InvoiceRecord, InvoiceState } from '@/types';
import { prependDashOrEmpty } from '@/utils/text';
import styled from 'styled-components';
import { FC } from 'react';

const FlexTitle = styled.div`
  display: flex;
  vertical-align: center;
`;

const Title = styled.span`
  margin-right: 16px;
`;

interface TitleProps {
  invoiceRecord: InvoiceRecord;
  state?: InvoiceState;
}
const TitleAndBadge: FC<TitleProps> = ({ state, invoiceRecord }) => {
  const title = `Invoice ${invoiceRecord.order} ${prependDashOrEmpty(invoiceRecord.invoiceNumber)}`;
  return (
    <FlexTitle>
      <Title>{title}</Title>
      {state && (
        <InvoiceStateBadge
          state={state}
          record={invoiceRecord}
          size="24px"
          onInvoicePage />
      )}
    </FlexTitle>
  );
};

interface TitleAndBreadcrumbsProps {
  invoiceRecord: InvoiceRecord;
  state?: InvoiceState;
  breadcrumbs: Breadcrumb[];
}
/**
 * Display breadcrumbs and title as part of an Invoice page header.
 * Providing prop `state: InvoiceState` to the component will also show the
 * state of the invoice to the right of the invoice title.
 */
export const TitleAndBreadcrumbs: FC<TitleAndBreadcrumbsProps> = ({ invoiceRecord, state, breadcrumbs }) => (
  <Breadcrumbs
    steps={breadcrumbs}
    current={<TitleAndBadge invoiceRecord={invoiceRecord} state={state} />}
  />
);
