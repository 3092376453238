import { ModalType } from '@/common/modules/Modal/types';
import { InfoCircle } from '@instech/icons';
import styled from 'styled-components';

const Text = styled.div`
  color: ${props => props.theme.marineBlue};
`;

const TitleContent = styled.span`
  display: flex;
  align-items: flex-end;
  font-weight: normal;

  svg {
    margin-right: 12px;
    height: 20px;
    width: 20px;
  }
  g {
    stroke-width: 2px;
  }
`;

const Title = () => (
  <TitleContent>
    <InfoCircle />
    Personal Identifiable Information (PII)
  </TitleContent>
);

const ModalContent = () => (
  <Text>
    <p>Personal Identifiable Information (PII) refers to any information relating to an identified or identifiable natural.</p>
    <p>
      This means someone who can be identified, directly or indirectly, in particular by reference to an identifier such as a name,
      and identification number, location data, an online identifier or to one or more factors specific to the physical,
      physiological, genetic, mental, economic, cultural or social identity of that natural person.
    </p>
    <p>
      Perosnal data may include but is not limited to a private phone number, email address, educational background,
      financial and payment details, details of certificates and diplomas, education and skills, marital status, nationality,
      job title, CV etc.
    </p>
  </Text>
);

export const piiModal = (): ModalType => ({
  component: ModalContent,
  options: {
    title: <Title />,
    size: 'small',
    padding: '12px 20px 20px'
  },
});
