import { FC } from 'react';
import {
  ButtonGroup, Loader, SlimButton
} from '@instech/components';

interface ModalButtonsProps {
  onSave: () => void;
  onCancel: () => void;
  isDisabled?: boolean;
  isPending?: boolean;
}
export const ModalCancelSaveButtons: FC<ModalButtonsProps> = ({ onSave, onCancel, isDisabled, isPending }) => (
  <ButtonGroup alignRight marginTop="24px">
    <SlimButton variant="secondary" width="150px" onClick={onCancel}>
      CANCEL
    </SlimButton>
    <SlimButton width="150px" disabled={isDisabled} onClick={onSave}>
      {!isPending ? 'SAVE' : <Loader size="small" />}
    </SlimButton>
  </ButtonGroup>
);
