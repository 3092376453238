import { FCWC } from '@/types';
import {
  createContext, useContext, useMemo
} from 'react';

export type RowClick = {
  onRowClick?: () => void;
}

const RowClickContext = createContext<RowClick | null>(null);

interface Props {
  onClick?: () => void;
}
/**
 * Provides access to onClick handlers for a table row to cells within
 * the row. This is done instead of adding a click handler to the row
 * itself, to avoid issues stemming from selective click-event propagation.
 */
export const RowClickProvider: FCWC<Props> = ({ children, onClick }) => {
  const value = useMemo(() => ({
    onRowClick: onClick
  }), [onClick]);

  return (
    <RowClickContext.Provider value={value}>
      {children}
    </RowClickContext.Provider>
  );
};

export const useRowClick = () => useContext(RowClickContext) as RowClick;
