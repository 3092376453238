import {
  BaseTableCell, TableCellComment, TableCellConsidered
} from '@/common/components/Table/TanstackTable/TableCell';
import { getHeaderGroup, getMergedMeta } from '@/common/components/Table/TanstackTable/utils';
import { InvoiceState, Nullable } from '@/types';
import { invoiceStatesConsidered } from '@/utils/invoice/invoiceStates';
import { CellContext } from '@tanstack/react-table';
import { FunctionComponent as FC } from 'react';
import { SurveyorMessageSwitch } from '../old/core/InvoiceCells/Components';

interface SurveyorCellsProps {
  data: CellContext<any, any>;
  showComment?: boolean;
  state: InvoiceState;
  exchangeRate: Nullable<number>;
  isConsidered?: boolean;
}
/**
 * Renders out a number cell with a tooltip if the surveyor has done a
 * consideration. Otherwise, this cell will span multiple rows and display
 * a message instead.
 */
export const SurveyorInvoiceCells: FC<SurveyorCellsProps> = ({ data, showComment, state, exchangeRate, isConsidered }) => {
  const mergedMeta = getMergedMeta(data.column);
  const headerGroup = getHeaderGroup(data.column);
  const colSpan = showComment ? 5 : 4;

  const showConsideredView = invoiceStatesConsidered.includes(state);

  if (!isConsidered || !showConsideredView) {
    return (
      <BaseTableCell
        id={data.cell.id}
        headerGroup={headerGroup}
        cellVariant={mergedMeta.tableCellVariant}
        columnBorder={mergedMeta.columnBorder}
        span={colSpan}>
        <SurveyorMessageSwitch state={state} />
      </BaseTableCell>
    );
  }

  return (
    <TableCellConsidered
      data={data}
      tooltipId="invoice-table-tooltip"
      isConsidered={isConsidered}
      exchangeRate={exchangeRate}
    />
  );
};

interface ConditionalCellProps {
  data: CellContext<any, any>;
  tooltipId: string;
  state: InvoiceState;
  exchangeRate: Nullable<number>;
  isConsidered?: boolean;
}
/**
 * Renders out a number cell with a tooltip if the surveyor has done a
 * consideration. Otherwise, this cell will not render (its space should be
 * taken by <SurveyorInvoiceCells /> instead).
 */
export const SurveyorConsideredCell: FC<ConditionalCellProps> = ({ data, tooltipId, state, exchangeRate, isConsidered }) => {
  const showConsideredView = invoiceStatesConsidered.includes(state);
  if (!isConsidered || !showConsideredView) {
    return null;
  }
  return (
    <TableCellConsidered
      data={data}
      tooltipId={tooltipId}
      isConsidered={isConsidered}
      exchangeRate={exchangeRate}
    />
  );
};

interface CommentCellProps {
  data: CellContext<any, any>;
  tooltipId: string;
  state: InvoiceState;
  isConsidered?: boolean;
}
export const SurveyorCommentCell: FC<CommentCellProps> = ({ data, tooltipId, state, isConsidered }) => {
  const showConsideredView = invoiceStatesConsidered.includes(state);
  if (!isConsidered || !showConsideredView) {
    return null;
  }
  return (
    <TableCellComment data={data} tooltipId={tooltipId} />
  );
};
