import { useTypedModal } from '@/common/modules/Modal';
import { Loader, SlimButton } from '@instech/components';
import { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import { cancelModal } from './modal/CancelModal';

const Wrapper = styled.div`
  display: flex;
  padding-left: 48px;
  align-items: end;
  gap: 32px;

  button {
    height: 40px;
  }
`;

interface Props {
  onCancel: () => void;
  hasFetched?: boolean;
  isDirty?: boolean;
  isPending?: boolean;
}
export const CancelSaveButtons: FC<Props> = ({ onCancel, hasFetched, isDirty, isPending }) => {
  const { open: openCancelModal } = useTypedModal(cancelModal({
    cancelCallback: onCancel
  }));
  // Only show the modal if the save button is also active
  const handleCancel = !isDirty ? onCancel : openCancelModal;
  return (
    <Wrapper>
      <SlimButton variant="secondary" width="80px" onClick={handleCancel}>CANCEL</SlimButton>
      <SlimButton width="170px" type="submit" disabled={!hasFetched || !isDirty || isPending}>
        {isPending ? <Loader size="small" /> : 'CREATE CLAIM STATEMENT'}
      </SlimButton>
    </Wrapper>
  );
};
