import { useClaimStatement } from '@/services/claimStatementServices';
import { useInvoice } from '@/services/invoice/invoiceServices';
import { PageLoader } from '@instech/components';
import { FunctionComponent as FC } from 'react';
import { EditInvoiceOwner } from '../core/EditInvoice/EditInvoiceOwner';
import { BatchUploadData } from '../core/EditInvoice/InvoiceForm/InvoiceFormOwner';

interface Props {
  claimStatementId: string;
  invoiceId: string;
  batchUploadData: BatchUploadData;
}
/**
 * This switch component is just a temporary workaround to make the BatchInvoicePage
 * adhere to a new architecture for the InvoicePage. Later on, might make sense to
 * refactor the BatchInvoicePage so that batch view of invoices is something handled
 * in the InvoiceHeader component?
 * But this is here because the EditInvoice and ReadonlyInvoice pages don't exist in
 * the same way in InvoicePage as they did when BatchInvoicePage was implemented.
 */
export const InvoiceDetailsBatchPage: FC<Props> = ({ claimStatementId, invoiceId, batchUploadData }) => {
  const { data: invoice } = useInvoice(claimStatementId, invoiceId);
  const { data: claimStatement } = useClaimStatement(claimStatementId);

  if (!invoice || !claimStatement) {
    return <PageLoader />;
  }

  return <EditInvoiceOwner claimStatement={claimStatement} invoice={invoice} batchUploadData={batchUploadData} />;
};
