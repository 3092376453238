import { Header, flexRender } from '@tanstack/react-table';
import { FC } from 'react';
import { CollapsibleHeaderContainer } from './Components';
import { CollapseButton } from '../../../CollapsibleTable/CollapseColumns';

interface Props {
  header: Header<any, unknown>;
  onClick: (range: string) => void;
  isCollapsed?: boolean;
  className?: string;
}
export const CollapsibleHeaderCell: FC<Props> = ({ header, onClick, isCollapsed, className }) => {
  const { meta: columnMeta, header: columnHeader } = header.column.columnDef;
  // TODO: Improve this once CollapseButton has a better text handling
  const headerText = flexRender(columnHeader, header.getContext()) as string;
  return (
    <CollapsibleHeaderContainer colSpan={header.colSpan} className={className}>
      {!columnMeta?.emptyCollapseHeader && (
        <CollapseButton
          title={headerText}
          collapsed={isCollapsed}
          onClick={() => onClick(header.column.id)} />
      )}
    </CollapsibleHeaderContainer>
  );
};
