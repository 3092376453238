import { LabelValuePair } from '@instech/components';
import useSWR from 'swr';
import { textTrimAll } from '@/utils/text';
import { piiOptions } from '@/utils/invoice/invoicePiiOptions';
import {
  ClaimStatementInvoiceId, Guid, PersonallyIdentifiableInformationOption, UserRoles
} from '@/types';
import {
  deleteAsync,
  patchAsync,
  postFormAsync
} from './client';

export const supportingDocumentsUrl = (id: ClaimStatementInvoiceId) =>
  `claimstatements/${id.claimStatementId}/invoices/${id.invoiceId}/supportingDocuments` as const;

export const documentCategoryValues = {
  calculations: 'Calculations',
  technicalDocumentation: 'TechnicalDocumentation',
  additionalInformation: 'AdditionalInformation',
  paymentConfirmation: 'PaymentConfirmation'
} as const;

export const documentCategories: LabelValuePair[] = [
  { value: documentCategoryValues.calculations, label: 'Calculations' },
  { value: documentCategoryValues.technicalDocumentation, label: 'Technical Documentation' },
  { value: documentCategoryValues.additionalInformation, label: 'Additional Information' }
];

export type DocumentCategory = typeof documentCategories[number] | '';
export type DocumentCategoryValue = typeof documentCategoryValues[keyof typeof documentCategoryValues];

export interface CompositeSupportingDocumentId {
  claimStatementId: Guid,
  invoiceId: Guid,
  supportingDocumentId: Guid,
  documentName?: string
}

export interface SupportingDocumentForm {
  documentName: string;
  documentCategory: DocumentCategory | '';
  pii: typeof piiOptions[number] | '';
  piiDescription: string;
}

export interface SupportingDocumentCreatedBy {
  role: UserRoles
}

export interface SupportingDocumentResponse {
  id: Guid;
  fileInformation: {
    path: string;
    filename: string;
  };
  createdBy: SupportingDocumentCreatedBy
  documentName: string;
  documentCategory: DocumentCategoryValue;
  pii: PersonallyIdentifiableInformationOption;
  piiDescription: string;
}

export interface SupportingDocumentListResponse {
  list: SupportingDocumentResponse[];
}

export const uploadSupportingDocument = (id: ClaimStatementInvoiceId, attachment: File, supportingDocumentData: SupportingDocumentForm) => {
  const url = supportingDocumentsUrl(id);
  const formData = new FormData();
  const pii = (supportingDocumentData.pii as typeof piiOptions[number]).value;
  formData.append('document', attachment as Blob);
  formData.append('documentName', textTrimAll(supportingDocumentData.documentName));
  formData.append('documentCategory', (supportingDocumentData.documentCategory as typeof documentCategories[number]).value ?? 0);
  formData.append('pii', pii);
  formData.append('piiDescription', pii === 'No' ? '' : textTrimAll(supportingDocumentData.piiDescription));
  return postFormAsync(url, formData);
};

const deleteSupportingDocument = (id: CompositeSupportingDocumentId) => {
  const url = `${supportingDocumentsUrl(id)}/${id.supportingDocumentId}`;
  return deleteAsync(url);
};

export const useSupportingDocuments = (compositeInvoiceId: ClaimStatementInvoiceId) => {
  const swrRoute = supportingDocumentsUrl(compositeInvoiceId);
  const { data, mutate } = useSWR<SupportingDocumentListResponse>(swrRoute);
  const supportingDocuments = data?.list ?? [];

  const isPending = !data;
  const attachments = supportingDocuments.filter(doc => doc.documentCategory !== 'PaymentConfirmation');
  const paymentConfirmations = supportingDocuments.filter(doc => doc.documentCategory === 'PaymentConfirmation');

  const deleteAndRefresh = async (supportingDocumentId: string) => {
    await deleteSupportingDocument({ ...compositeInvoiceId, supportingDocumentId });
    await mutate();
  };

  return {
    isPending,
    attachments,
    paymentConfirmations,
    refreshSupportingDocuments: mutate,
    deleteSupportingDocument: deleteAndRefresh
  };
};

interface Request {
  documentName?: string;
  documentCategory?: string;
  pii: string;
  piiDescription: string | null;
}
export const updateSupportingDocument = (id: CompositeSupportingDocumentId, form: SupportingDocumentForm) => {
  const url = `${supportingDocumentsUrl(id)}/${id.supportingDocumentId}`;
  const pii = (form.pii as typeof piiOptions[number]).value;
  const request: Request = {
    documentName: textTrimAll(form.documentName),
    documentCategory: (form.documentCategory as typeof documentCategories[number]).value,
    pii,
    piiDescription: pii === 'No' ? '' : textTrimAll(form.piiDescription)
  };
  return patchAsync(url, request);
};
