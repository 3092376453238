import React, {
  FC, useMemo, useRef, useState
} from 'react';
import styled from 'styled-components';
import {
  FieldLayout, FieldLabel, InputField,
  FieldFooter, withFormId, useCloseOnClickOutside,
  LabelValuePair
} from '@instech/components';
import { useField } from 'formik';
import { convertCurrency } from '@/utils/invoice/convertCurrency';
import { NumberFormatValues } from 'react-number-format';
import { NumberInput } from '@/common/components/Form/NumberInput';
import { TextFieldWithTagsProps } from './core/types';
import { TagButton } from './core/TagButton';
import { TagDropdown } from './core/TagDropdown';

const noneOption = { label: 'None', value: '' };

const createTagOptions = (options: LabelValuePair[]) => ([
  noneOption,
  ...options
]);

const RelativeAnchor = styled.div`
  position: relative;
`;

const HighlightedInput = styled(InputField) <{ highlight?: boolean }>`
  && input {
    position: relative;
    background-color: ${props => props.highlight ? props.theme.lightYellow30 : ''};
    box-shadow: ${props => props.highlight ? `inset 6px 0 0 0 ${props.theme.lightYellow}` : ''};

    &:disabled {
      color: ${props => props.theme.marineBlue60};
      background-color: ${props => props.highlight ? props.theme.marineBlue10 : props.theme.disabled25};
      box-shadow: ${props => props.highlight ? `inset 6px 0 0 0 ${props.theme.marineBlue20}` : ''};
    };
  }
`;

/**
 * ### Directly controlled text input with tagging
 *
 * This component creates a text field input, which supports all the features of a
 * HighlightedTextFieldControlled. Its unique feature is that it also renders a Tag
 * icon in the right end of the field.
 *
 * By clicking this Tag icon, the user sees a dropdown list of available tags. The
 * user can select one of these tags, causing the icon to fill in. This chosen tag
 * can then also be saved into the consuming form using the `onChangeTag` callback
 * handler.
 *
 * #### Support for react-tooltip
 *
 * This component supports `react-tooltip` by providing the ID of the tooltip to
 * `tagTooltipId`. The React Tooltip provider has to be provided by the consuming
 * form -- since usually there would be just one tooltip provider for an entire
 * form, or even an entire page.
 */

export const HighlightedFieldWithTags: FC<TextFieldWithTagsProps> = ({
  name,
  tagFieldName,
  initialFieldName,
  isConsideredFieldName,
  formId,
  label,
  placeholder,
  noLabel = false,
  noErrors = false,
  tagOptions,
  tagTooltipId,
  convertRate,
  emptyWithoutConvertRate,
  limitValidInput = true,
  required = false,
  optional = false,
  disabled = false,
  maxLength = 21,
  readOnly,
  className,
  ...fieldProps
}) => {
  const [field, meta, helpers] = useField(name);
  const [tagField, , tagHelpers] = useField(tagFieldName);
  const [initialField] = useField(initialFieldName);
  const [isConsideredValue, , consideredFieldHelpers] = useField<boolean>(isConsideredFieldName);

  const anchorRef = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedTag, setSelectedTag] = useState<LabelValuePair>(tagField.value ?? noneOption);
  const fieldId = withFormId(name, formId);
  const dropdownName = `${name}-tag-dropdown`;
  const showFieldError = meta.touched && meta.error;

  // Extract to variable so it can be altered and converted
  let fieldVal = field.value;
  const highlight = !isConsideredValue.value && !meta.touched && !!initialField.value;
  // applies conversion rate to displayed value when displayed disabled
  if (convertRate) {
    fieldVal = convertCurrency(fieldVal, convertRate, true);
  }

  // allows field to show empty when it cannot convert rate
  if (!convertRate && emptyWithoutConvertRate) {
    fieldVal = '';
  }

  useCloseOnClickOutside(showDropdown, setShowDropdown, anchorRef);

  const [options] = useMemo(() => {
    const createOptions = createTagOptions(tagOptions);

    return [createOptions];
  }, [tagOptions]);

  const handleFocus = async () => {
    if (convertRate) return;
    if (!isConsideredValue.value && field.value !== '') {
      await consideredFieldHelpers.setValue(true);
    }
    if (highlight) {
      await helpers.setTouched(true);
    }
  };

  const handleValueChange = async (evt: NumberFormatValues) => {
    if (disabled) return;

    await consideredFieldHelpers.setValue(true);
    await helpers.setTouched(true);
    await helpers.setValue(evt.floatValue);
  };

  const handleSelectTag = async (newTag: string) => {
    const findTag = options.find(option => option.label === newTag);

    if (findTag) {
      setShowDropdown(false);
      setSelectedTag(findTag);
      await tagHelpers.setValue(findTag);

      if (selectedTag.value !== findTag.value) {
        await consideredFieldHelpers.setValue(true);
      }
    }
  };

  const handleInputBlur = async (evt: React.FocusEvent<HTMLInputElement>) => {
    await consideredFieldHelpers.setValue(true);

    if (evt.target.value === '') {
      setSelectedTag(noneOption);
      await tagHelpers.setValue(noneOption);
    }
  };

  const toggleDropdown = () => setShowDropdown(prevVal => !prevVal);

  return (
    <FieldLayout className={className}>
      {!noLabel && (
        <FieldLabel
          htmlFor={fieldId}
          required={required}
          optional={optional}
          disabled={disabled}
          error={showFieldError}>
          {label}
        </FieldLabel>
      )}
      <RelativeAnchor ref={anchorRef}>
        <HighlightedInput highlight={highlight} hasEndIcon error={showFieldError}>
          <NumberInput
            id={fieldId}
            name={name}
            value={fieldVal}
            placeholder={placeholder}
            setValue={helpers.setValue}
            onFocus={handleFocus}
            onBlur={handleInputBlur}
            readOnly={readOnly}
            disabled={disabled}
            thousandSeparator
            {...fieldProps}
            decimalScale={2}
            onValueChange={handleValueChange}
          />
          <TagButton
            onClick={toggleDropdown}
            disabled={disabled}
            selectedTag={selectedTag}
            tagTooltipId={tagTooltipId}
          />
        </HighlightedInput>
        {showDropdown && (
          <TagDropdown
            name={dropdownName}
            options={options}
            selected={selectedTag}
            onChange={handleSelectTag}
          />
        )}
      </RelativeAnchor>
      {!noErrors && (
        <FieldFooter
          error={meta.error}
          touched={meta.touched}
          value={meta.value}
        />
      )}
    </FieldLayout>
  );
};
