import {
  AdjustersConsiderationSplit, Invoice, SurveyorsConsiderationSplit
} from '@/types';
import { collapseGroups } from '@/common/components/Table/utils';
import { CollapsibleSubrow } from '@/common/components/Table/CollapsibleTable/types';
import { SplitAdjusterCells } from '../../core/SplitCells';
import { PrintSplitSurveyorCells } from '../../core/SplitCells/PrintSplitSurveyorCells';
import { CSTableProps } from '../useSchemaByTableGrouping';

export interface SubrowDataType {
  adjuster: AdjustersConsiderationSplit;
  surveyor?: SurveyorsConsiderationSplit;
}

export const tableSchemaForPrintSubrow = (
  subrowData: SubrowDataType,
  rowData: Invoice,
  props: CSTableProps
): CollapsibleSubrow => {
  const { state, record } = rowData;
  const { claimStatementSettings } = props;

  return ({
    id: subrowData.adjuster.id,
    segments: [
      {
        key: 'after-expanded-button-space',
        cellType: 'gap',
        span: '1'
      },
      {
        key: 'particular-cells-start',
        cellType: 'gap',
        group: collapseGroups.particulars,
        border: true,
      },
      {
        key: 'particular-cell-category',
        cellType: 'text',
        value: subrowData.adjuster.category,
        group: collapseGroups.particulars,
      },
      {
        key: 'particular-cells-end',
        cellType: 'gap',
        span: '3',
        group: collapseGroups.particulars,
      },
      {
        key: 'owner-cells',
        cellType: 'text',
        span: '1',
        group: collapseGroups.owner,
        border: true,
      },
      {
        key: 'surveyor-cells',
        cellType: 'custom',
        value: (
          <PrintSplitSurveyorCells split={subrowData} state={state} />
        )
      },
      {
        key: 'adjuster-cells',
        cellType: 'custom',
        value: (
          <SplitAdjusterCells
            split={subrowData}
            exchangeRate={record.exchangeRate}
            showGeneralAverage={!!claimStatementSettings?.hasGeneralAverage} />
        )
      }
    ]
  });
};
