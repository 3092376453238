import { CollapsiblePane, TextArea } from '@instech/components';
import { Formik } from 'formik';
import { FC } from 'react';
import { FormWithAutoSave } from '../../pages/AdjustmentPage/core/Components';

const initialValues = (internalNote?: string | null) =>
  ({ internalNote: internalNote ?? '' });

type FormValues = { internalNote: string };

interface Props {
  fieldName: string;
  value: string | null | undefined;
  onSave: (form: FormValues) => Promise<void>;
  padding?: string;
  margin?: string;
}
export const InternalNotes: FC<Props> = ({ fieldName, value, onSave, padding = '20px', margin = '20px 0px 0px' }) => (
  <CollapsiblePane color="green" title="Internal notes" padding={padding} margin={margin}>
    <Formik initialValues={initialValues(value)} onSubmit={onSave}>
      <FormWithAutoSave>
        <TextArea
          name={fieldName}
          label="Internal Notes - Only visible for CH/Adjuster" />
      </FormWithAutoSave>
    </Formik>
  </CollapsiblePane>
);
