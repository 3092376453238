import { patchAsync } from '@/services/client';
import useSWR from 'swr';
import { AdjustmentCalculations } from '@/apps/Adjustment/types';

export type UpdateAdjustedClaimRequest = {
  dueToAssured?: number;
  underwritersFeeAndExpenses?: number;
  totalClaimOnPolicy?: number;
  dueDate?: string;
}

type AdjustedClaimData = {
  adjustedClaim: UpdateAdjustedClaimRequest;
  dueDateSet: boolean;
}

const baseUrl = (claimStatementId: string) => `claimstatements/${claimStatementId}/adjustmentCalculations`;

export const useAdjustedClaim = (claimStatementId: string | undefined) => {
  const route = claimStatementId ? baseUrl(claimStatementId) : null;
  const { data, mutate } = useSWR<AdjustmentCalculations>(route, { suspense: true });
  const { adjustedClaim, id: adjustmentId } = data!;

  const adjustedClaimData: AdjustedClaimData = {
    adjustedClaim: {
      dueToAssured: adjustedClaim.dueToAssured,
      underwritersFeeAndExpenses: adjustedClaim.underwritersFeeAndExpenses,
      totalClaimOnPolicy: adjustedClaim.totalClaimOnPolicy,
      dueDate: adjustedClaim.dueDate.value
    },
    dueDateSet: adjustedClaim.dueDate.isValueSetExplicitly || false
  };

  const updateAdjustedClaim = async (
    request: UpdateAdjustedClaimRequest
  ) => {
    if (!adjustmentId) return;
    const requestWithDateOnly = { ...request, dueDate: request.dueDate?.split('T')[0] };
    await patchAsync<{}>(`${route}/${adjustmentId}/adjusted-claim`, requestWithDateOnly);
    await mutate();
  };

  const resetDueDate = async () => {
    await updateAdjustedClaim({ ...adjustedClaimData.adjustedClaim, dueDate: undefined });
  };

  return { adjustedClaimData, updateAdjustedClaim, resetDueDate };
};
