import { DeviationSurveyor } from '@/apps/ClaimStatement/components/Deviation';
import { FormRow } from '@/common/components/Form/FormRow/FormRow';
import { DividerLine, SplitItemButtons } from '@/apps/ClaimStatement/components/InvoiceSplitTable';
import { InvoiceRecord } from '@/types';
import {
  Dropdown, LabelValuePair, TextArea
} from '@instech/components';
import { useField } from 'formik';
import { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import { SurveyorsCostFields } from './SurveyorsCostFields';

const Container = styled.div<{ isMultiple?: boolean; }>`
  padding: ${props => props.isMultiple && '0px 16px 16px;'};
`;

const StyledDeviationSurveyor = styled(DeviationSurveyor)`
  margin-bottom: 20px;
`;

// Commented out code is for the Apply Values button
interface Props {
  arrayName: string;
  index: number;
  invoiceRecord: InvoiceRecord;
  categoryOptions: LabelValuePair[];
  occurrenceOptions: LabelValuePair[];
  onAddSplit?: () => void;
  onRemoveSplit: (index: number) => void;
  selectedCurrency?: string | null;
  useInvoiceCurrency?: boolean;
  isMultiple?: boolean;
}
export const SplitItemSurveyor: FC<Props> = ({
  arrayName,
  index,
  invoiceRecord,
  categoryOptions,
  occurrenceOptions,
  onAddSplit,
  onRemoveSplit,
  selectedCurrency,
  useInvoiceCurrency,
  isMultiple
}) => {
  const [showNoteField, , showNoteHelpers] = useField(`${arrayName}.${index}.form.hasInternalNotes`);
  const [, , noteHelpers] = useField(`${arrayName}.${index}.internalNotes`);

  const showNote = showNoteField.value;

  // On click, flag boolean to show field
  const addInternalNote = () => {
    showNoteHelpers.setValue(true);
  };

  // On click, flag boolean to hide field and clear actual note field
  const deleteInternalNote = () => {
    showNoteHelpers.setValue(false);
    noteHelpers.setValue('');
  };

  return (
    <Container isMultiple={isMultiple}>
      {isMultiple && <DividerLine />}
      <SurveyorsCostFields
        arrayName={arrayName}
        index={index}
        exchangeRate={invoiceRecord.exchangeRate}
        useInvoiceCurrency={useInvoiceCurrency} />
      {!isMultiple && (
        <StyledDeviationSurveyor
          invoice={invoiceRecord}
          selectedCurrency={selectedCurrency || ''} />
      )}
      <FormRow gutter="32px" layoutLarge="1fr 3fr">
        <div>
          <Dropdown
            name={`${arrayName}.${index}.occurrence`}
            label="Occurrence"
            options={occurrenceOptions} />
          {isMultiple && (
            <Dropdown
              name={`${arrayName}.${index}.category`}
              label="Category"
              options={categoryOptions}
              clearable />
          )}
        </div>
        <div>
          <TextArea
            name={`${arrayName}.${index}.surveyorsComment`}
            label="Description of Consideration"
            placeholder="Text" />
          {showNote && (
            <TextArea
              name={`${arrayName}.${index}.internalNotes`}
              label="Internal Notes - Only visible for Surveyors"
              placeholder="Text" />
          )}
        </div>
      </FormRow>
      <SplitItemButtons
        onAddNote={addInternalNote}
        onDeleteNote={deleteInternalNote}
        onAddSplit={onAddSplit}
        onRemoveSplit={() => onRemoveSplit(index)}
        showNote={showNote}
        viewAsListOfSplits={isMultiple}
      />
    </Container>
  );
};
