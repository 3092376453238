import { TanstackTable } from '@/common/components/Table/TanstackTable/Table';
import { TableBody } from '@/common/components/Table/TanstackTable/TableBody/TableBody';
import {
  TableButtonCheckbox,
  TableButtonExpand,
  TableCellComment,
  TableCellConsidered,
  TableCellNumber,
  TableCellReview,
  TableCellState,
  TableCellText
} from '@/common/components/Table/TanstackTable/TableCell';
import { HeaderButtonCheckbox, TableHeaderCollapsible } from '@/common/components/Table/TanstackTable/TableHeader';
import { ManagedTableSelectState } from '@/common/components/Table/TanstackTable/hooks/useManagedTableSelect';
import { useTableOptionsSetup } from '@/common/components/Table/TanstackTable/providers/TableOptionsContext';
import { sortByInvoiceState } from '@/common/components/Table/TanstackTable/utils';
import { tableCellVariant } from '@/common/components/Table/types';
import { ClaimStatementSettings, InvoiceOverview } from '@/types';
import { arrayAddConditionally } from '@/utils/array';
import { invoiceStatesFinished } from '@/utils/invoice/invoiceStates';
import { theme } from '@/utils/style/theme';
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import {
  FC, useCallback,
  useMemo
} from 'react';
import {
  SurveyorCommentCell, SurveyorConsideredCell, SurveyorInvoiceCells
} from '../../TableCells/InvoiceSurveyorCells';
import { getSubrowsInvoiceNoGrouping } from './SubrowTemplateNoGrouping';
import { CHInvoiceTableDataNoGrouping, getTableDataNoGrouping } from './tableDataNoGrouping';

const columnHelper = createColumnHelper<CHInvoiceTableDataNoGrouping>();

interface InvoiceTableDefProps {
  policyCurrency: string;
  claimStatementSettings?: ClaimStatementSettings;
}
const getColumnDef = (props: InvoiceTableDefProps): ColumnDef<CHInvoiceTableDataNoGrouping, any>[] => ([
  columnHelper.group({
    id: 'ungrouped',
    meta: {
      emptyCollapseHeader: true
    },
    columns: [
      {
        id: 'table-select',
        meta: {
          customHeader: true
        },
        header: ({ table }) => (
          <HeaderButtonCheckbox table={table} />
        ),
        cell: data => (
          <TableButtonCheckbox data={data} />
        ),
      },
      columnHelper.accessor('subrowCount', {
        header: 'Splits',
        cell: data => (
          <TableButtonExpand data={data} counter={data.getValue()} />
        ),
      }),
      columnHelper.accessor('invoiceNo', {
        header: 'Invoice No',
        sortingFn: 'alphanumeric',
        cell: data => (
          <TableCellText data={data} />
        ),
      }),
      columnHelper.accessor('isSharedForReview', {
        header: 'Review',
        sortingFn: 'auto',
        cell: data => (
          <TableCellReview data={data} />
        ),
      }),
      columnHelper.accessor('state', {
        header: 'Status',
        sortingFn: sortByInvoiceState,
        cell: data => (
          <TableCellState data={data} />
        ),
      }),
    ]
  }),
  columnHelper.group({
    id: 'particulars',
    header: 'Particulars',
    meta: {
      headerBorderColor: theme.status.blue
    },
    columns: [
      columnHelper.accessor('supplier', {
        header: 'Supplier',
        sortingFn: 'alphanumeric',
        meta: {
          columnBorder: 'left'
        },
        cell: data => (
          <TableCellText data={data} />
        ),
      }),
      columnHelper.accessor('category', {
        header: 'Category',
        sortingFn: 'alphanumeric',
        cell: data => (
          <TableCellText data={data} />
        ),
      }),
      columnHelper.accessor('currency', {
        header: 'Currency',
        sortingFn: 'alphanumeric',
        cell: data => (
          <TableCellText data={data} />
        ),
      }),
      columnHelper.accessor('totalAmount', {
        header: 'Total - Invoice CCY',
        sortingFn: 'alphanumeric',
        meta: {
          align: 'right'
        },
        cell: data => (
          <TableCellNumber data={data} tooltipId="invoice-page-tooltip" />
        ),
      }),
      columnHelper.accessor('paidAmount', {
        header: `Total - ${props.policyCurrency}`,
        sortingFn: 'alphanumeric',
        meta: {
          align: 'right'
        },
        cell: data => (
          <TableCellNumber data={data} tooltipId="invoice-page-tooltip" />
        ),
      }),
    ]
  }),
  columnHelper.group({
    id: 'owner',
    header: 'Owner',
    meta: {
      headerBorderColor: theme.status.blue
    },
    columns: [
      columnHelper.accessor('owner.claimInPolicyCurrency', {
        header: 'Claim',
        sortingFn: 'alphanumeric',
        meta: {
          align: 'right',
          columnBorder: 'left'
        },
        cell: data => (
          <TableCellNumber data={data} tooltipId="invoice-page-tooltip" />
        ),
      }),
      columnHelper.accessor('owner.ownersWorkInPolicyCurrency', {
        header: 'Owner',
        meta: {
          align: 'right'
        },
        cell: data => (
          <TableCellNumber data={data} tooltipId="invoice-page-tooltip" />
        ),
      }),
      columnHelper.accessor('owner.ownersComment', {
        header: 'Cost Description',
        cell: data => (
          <TableCellComment data={data} tooltipId="invoice-page-tooltip" />
        ),
      }),
    ]
  }),
  columnHelper.group({
    id: 'surveyor',
    header: 'Surveyor',
    meta: {
      headerBorderColor: theme.status.purple,
      tableCellVariant: tableCellVariant.secondary
    },
    columns: [
      columnHelper.accessor('surveyor.generalExpenses', {
        header: 'Gen. Expenses',
        meta: {
          align: 'right',
          columnBorder: 'left'
        },
        cell: data => (
          <SurveyorInvoiceCells
            data={data}
            showComment
            state={data.row.original.state}
            exchangeRate={data.row.original.exchangeRate}
            isConsidered={data.row.original.surveyor.isConsidered}
          />
        ),
      }),
      columnHelper.accessor('surveyor.claimConsideration', {
        header: 'Claim',
        meta: {
          align: 'right',
        },
        cell: data => (
          <SurveyorConsideredCell
            data={data}
            tooltipId="invoice-page-tooltip"
            state={data.row.original.state}
            exchangeRate={data.row.original.exchangeRate}
            isConsidered={data.row.original.surveyor.isConsidered}
          />
        ),
      }),
      columnHelper.accessor('surveyor.ownersWorkConsideration', {
        header: 'Owner',
        meta: {
          align: 'right',
        },
        cell: data => (
          <SurveyorConsideredCell
            data={data}
            tooltipId="invoice-page-tooltip"
            state={data.row.original.state}
            exchangeRate={data.row.original.exchangeRate}
            isConsidered={data.row.original.surveyor.isConsidered}
          />
        ),
      }),
      columnHelper.accessor('surveyor.adjusterToConsider', {
        header: 'Adjuster to Consider',
        meta: {
          align: 'right',
        },
        cell: data => (
          <SurveyorConsideredCell
            data={data}
            tooltipId="invoice-page-tooltip"
            state={data.row.original.state}
            exchangeRate={data.row.original.exchangeRate}
            isConsidered={data.row.original.surveyor.isConsidered}
          />
        ),
      }),
      columnHelper.accessor('surveyor.surveyorsComment', {
        header: 'Description of Consideration',
        cell: data => (
          <SurveyorCommentCell
            data={data}
            tooltipId="invoice-page-tooltip"
            state={data.row.original.state}
            isConsidered={data.row.original.surveyor.isConsidered}
          />
        ),
      }),
    ]
  }),
  columnHelper.group({
    id: 'adjuster',
    header: 'Adjuster',
    meta: {
      tableCellVariant: tableCellVariant.tertiary
    },
    columns: [
      columnHelper.accessor('adjuster.commonExpenses', {
        header: 'Common Expenses',
        meta: {
          columnBorder: 'left',
          align: 'right'
        },
        cell: data => (
          <TableCellConsidered
            data={data}
            tooltipId="invoice-page-tooltip"
            exchangeRate={data.row.original.exchangeRate}
            isConsidered
          />
        ),
      }),
      ...arrayAddConditionally(
        !!props.claimStatementSettings?.hasGeneralAverage,
        columnHelper.accessor('adjuster.generalAverage', {
          header: 'General Average',
          meta: {
            align: 'right',
          },
          cell: data => (
            <TableCellConsidered
              data={data}
              tooltipId="invoice-page-tooltip"
              exchangeRate={data.row.original.exchangeRate}
              isConsidered
            />
          ),
        })
      ),
      columnHelper.accessor('adjuster.particularAverage', {
        header: 'Particular Average',
        meta: {
          align: 'right'
        },
        cell: data => (
          <TableCellConsidered
            data={data}
            tooltipId="invoice-page-tooltip"
            exchangeRate={data.row.original.exchangeRate}
            isConsidered
          />
        ),
      }),
      columnHelper.accessor('adjuster.ownersWorkConsideration', {
        header: 'Remainder',
        meta: {
          align: 'right'
        },
        cell: data => (
          <TableCellConsidered
            data={data}
            tooltipId="invoice-page-tooltip"
            exchangeRate={data.row.original.exchangeRate}
            isConsidered
          />
        ),
      }),
      columnHelper.accessor('adjuster.adjustersComment', {
        header: 'Adjuster\'s Note',
        cell: data => (
          <TableCellComment data={data} tooltipId="invoice-page-tooltip" />
        ),
      }),
    ]
  }),
]);

interface Props {
  data: InvoiceOverview;
  claimStatementSettings?: ClaimStatementSettings;
  tableSelectState: ManagedTableSelectState;
  onRowClick: (invoiceId: string, invoiceIdList: string[]) => void;
  showFullComments?: boolean;
}
export const InvoiceTableCHNoGrouping: FC<Props> = ({
  data,
  claimStatementSettings,
  tableSelectState,
  onRowClick,
  showFullComments
}) => {
  const tableData = useMemo(() => getTableDataNoGrouping(data.invoices), [data.invoices]);

  const columnDef = useMemo(() => getColumnDef({
    policyCurrency: data.currency,
    claimStatementSettings
  }), [claimStatementSettings, data.currency]);

  const table = useReactTable({
    data: tableData,
    columns: columnDef,
    state: {
      rowSelection: tableSelectState.rowSelection
    },
    sortDescFirst: false,
    enableRowSelection: true,
    getRowId: row => row.id,
    onRowSelectionChange: tableSelectState.setRowSelection,
    getRowCanExpand: (row => row.original.splits.length >= 2),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  });

  const tableOptions = useTableOptionsSetup({
    showFullComments
  });

  const getTableSubrows = useCallback((values: CHInvoiceTableDataNoGrouping) => (
    getSubrowsInvoiceNoGrouping(values, claimStatementSettings)
  ), [claimStatementSettings]);

  return (
    <div>
      <TanstackTable options={tableOptions}>
        <TableHeaderCollapsible table={table} />
        <TableBody
          table={table}
          onRowClick={invoice => onRowClick(invoice.id, data.invoices.map(x => x.id))}
          rowIsFinished={invoice => invoiceStatesFinished.includes(invoice.state)}
          getSubrows={getTableSubrows}
        />
      </TanstackTable>
    </div>
  );
};
