import {
  ButtonGroup, Dropdown, LabelValuePair, SlimButton, TextField
} from '@instech/components';
import { useField, useFormikContext } from 'formik';
import styled from 'styled-components';
import { hasPii, piiOptions } from '@/utils/invoice/invoicePiiOptions';
import { SupportingDocumentForm, documentCategories } from '@/services/supportingDocumentsServices';

export const FormContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 12px;
`;

export const DocumentNameField = () => (
  <TextField placeholder="" name="documentName" label="Document Name" maxLength={60} />
);

export const DocumentCategoryDropdown = () => (
  <Dropdown placeholder="" name="documentCategory" label="Document Category" options={documentCategories} />
);

export const PiiDropdown = () => (
  <Dropdown
    required
    placeholder=""
    name="pii"
    label="Does this invoice contain PII?"
    options={piiOptions} />
);

export const PiiDescriptionField = () => {
  const [{ value: pii }] = useField('pii');

  if (!hasPii((pii as LabelValuePair).value ?? '')) {
    return null;
  }

  return <TextField placeholder="" name="piiDescription" label="PII location in the document" maxLength={110} />;
};

export const ActionButtonsGroup = ({ close }: { close: () => void }) => {
  const { isSubmitting, submitForm } = useFormikContext<SupportingDocumentForm>();

  return (
    <ButtonGroup alignRight marginTop="12px">
      <SlimButton variant="secondary" onClick={close}>CANCEL</SlimButton>
      <SlimButton onClick={submitForm} loading={isSubmitting} disabled={isSubmitting}>SAVE</SlimButton>
    </ButtonGroup>
  );
};
