import React, { createContext, useContext } from 'react';
import { PageLoader } from '@instech/components';
import { useClaims } from '@/services/management/managementService';
import { FCWC } from '@/types';

export interface User {
  name?: string;
  email?: string;
}

interface ContextValue {
  isAuthenticated: boolean;
  isMagicLinkUser: boolean;
  user: User | null;
  logout: () => void;
}

const AuthenticationContext = createContext<ContextValue | undefined>(undefined);

export const AuthenticationProvider: FCWC = ({ children }) => {
  const { data, loading } = useClaims();

  const value = React.useMemo(() => {
    if (!data) return {} as ContextValue;
    const isMagicLinkUser = data.claims?.some(claim => claim.type === 'amr' && claim.value === 'magic_link') ?? false;
    const userInfo: User = {
      name: data.claims?.find(i => i.type === 'name')?.value,
      email: data.claims?.find(i => i.type === 'email')?.value
    };
    const logout = () => {
      const logoutUrl = data.claims?.find(i => i.type === 'bff:logout_url')?.value;
      if (logoutUrl) window.location.href = logoutUrl;
    };
    return { ...data, user: userInfo, isAuthenticated: true, isMagicLinkUser, logout } as ContextValue;
  }, [data]);

  if (data === null || loading) return <PageLoader />;
  return (
    <AuthenticationContext.Provider
      value={value}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAccount = () => useContext<ContextValue>(AuthenticationContext as React.Context<ContextValue>);
