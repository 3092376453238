import { ApplicationRole, UserInfoDto } from '@/types';
import { putAsync } from './client';

type UpdateParticipantsWithRoleParams = {
    claimStatementId?: string;
    role?: ApplicationRole | null
    participants: UserInfoDto[];
};

export const updateParticipantsWithRole = async ({ claimStatementId, role, participants }:UpdateParticipantsWithRoleParams) => {
  if (!role || !claimStatementId) return;
  const participantIds = participants.map(p => p.id);
  const url = `claimstatements/${claimStatementId}/participants/role/${role.name}`;
  await putAsync(url, { users: participantIds });
};
