import { useModalContext } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import {
  CompositeSupportingDocumentId,
  SupportingDocumentForm,
  SupportingDocumentResponse,
  updateSupportingDocument,
  useSupportingDocuments
} from '@/services/supportingDocumentsServices';
import { Formik } from 'formik';
import {
  ActionButtonsGroup,
  DocumentNameField, FormContent, PiiDescriptionField, PiiDropdown
} from '../../core/ModalFormComponents';
import { paymentConfirmationValidationSchema } from '../paymentConfirmationValidationSchema';
import { createInitialEditPaymentConfirmationValues } from '../utils';

interface EditPaymentConfirmationsProps {
  id: CompositeSupportingDocumentId;
  supportingDocument: SupportingDocumentResponse;
}
const ModalContent = ({ id, supportingDocument }: EditPaymentConfirmationsProps) => {
  const { close } = useModalContext();
  const initialValues = createInitialEditPaymentConfirmationValues(supportingDocument);
  const { refreshSupportingDocuments } = useSupportingDocuments(id);

  const Update = async (values: SupportingDocumentForm) => {
    await updateSupportingDocument(id, values);
    await refreshSupportingDocuments();
    close();
  };

  return (
    <Formik initialValues={initialValues} validationSchema={paymentConfirmationValidationSchema} onSubmit={Update}>
      {() => (
        <>
          <FormContent>
            <DocumentNameField />
            <PiiDropdown />
            <PiiDescriptionField />
          </FormContent>
          <ActionButtonsGroup close={close} />
        </>
      )}
    </Formik>
  );
};
type EditPaymentConfirmationModal = (props: EditPaymentConfirmationsProps, onCancel?: () => void) => ModalType<EditPaymentConfirmationsProps>;
export const editPaymentConfirmationModal: EditPaymentConfirmationModal = (props, onCancel = () => null) => ({
  component: ModalContent,
  options: {
    title: 'Edit Payment Confirmation',
    size: 'medium',
    padding: '20px'
  },
  args: props,
  onCancel
});

export const defaultEditPaymentConfirmationsProps: EditPaymentConfirmationsProps = {
  id: { claimStatementId: '', invoiceId: '', supportingDocumentId: '' },
  supportingDocument: {} as SupportingDocumentResponse
};
