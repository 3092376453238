import { FunctionComponent as FC } from 'react';
import { textOrDash } from '@/utils/text';
import { IntrinsicSpanProps } from '@/types';
import { StyledTextCell } from './Components';

interface Props extends IntrinsicSpanProps {
  value: string | null;
  underline?: boolean;
}
export const TableCellText: FC<Props> = ({ value, underline, ...props }) => (
  <StyledTextCell underline={underline} {...props}>
    <span>{textOrDash(value)}</span>
  </StyledTextCell>
);
