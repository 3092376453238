import { PageContent } from '@/common/layout/Page';
import { useClaimStatement, useIsClosed } from '@/services/claimStatementServices';
import { useInvoiceLock } from '@/services/invoice/invoiceLockService';
import {
  useLatestSurveyorsInstructions, useInvoice, useInvoiceLink,
  useLatestOwnersInstructions
} from '@/services/invoice/invoiceServices';
import { InvoiceState } from '@/types';
import { PageLoader } from '@instech/components';
import { useParams } from 'react-router-dom';
import { invoiceStates } from '@/utils/constants';
import { useEffect } from 'react';
import { KeyedMutator } from 'swr';
import { InvoiceDisplay } from '../core/EditInvoice/InvoiceDisplay/InvoiceDisplay';
import { InvoicePageLayout } from './core/Components';
import { ClaimsHandlerInvoiceHeader } from './InvoiceHeader/ClaimsHandlerInvoiceHeader';
import { useUserSessionSlide } from '../core/EditInvoice/utils/useUserSessionSlide';
import { InvoiceDetailsClaimsHandler } from './core/InvoiceDetailsClaimsHandler';

export const editableStates: InvoiceState[] = [
  invoiceStates.Review,
  invoiceStates.SharedReview,
  invoiceStates.AdjustersConsideration,
  invoiceStates.AdjustersConsiderationNoAllocation,
];

const useForceCleanRefresh = <T, >(refresh: KeyedMutator<T>) =>
  useEffect(() => void refresh(undefined, { revalidate: true }), [refresh]);

export const ClaimsHandlerInvoicePage = () => {
  const { claimStatementId, invoiceId } = useParams();
  const { data: invoice, mutate: refreshInvoice } = useInvoice(claimStatementId, invoiceId);
  const { data: claimStatement } = useClaimStatement(claimStatementId);
  const { data: imageData } = useInvoiceLink(claimStatementId, invoiceId);
  const { data: surveyorInstructions } = useLatestSurveyorsInstructions(claimStatementId, invoiceId);
  const { data: ownerInstructions } = useLatestOwnersInstructions(claimStatementId, invoiceId);
  const isClosed = useIsClosed(claimStatement);
  const { ownsLock, unlockInvoice } = useInvoiceLock(editableStates, claimStatementId, invoiceId, isClosed);
  useUserSessionSlide(unlockInvoice);
  useForceCleanRefresh(refreshInvoice);

  if (!invoice || !claimStatement || ownsLock === null || !surveyorInstructions || !ownerInstructions) {
    return <PageLoader />;
  }

  return (
    <div>
      <ClaimsHandlerInvoiceHeader
        invoice={invoice}
        claimStatement={claimStatement} />
      <PageContent>
        <InvoicePageLayout>
          <InvoiceDetailsClaimsHandler
            invoice={invoice}
            claimStatement={claimStatement}
            ownsLock={ownsLock}
            isClaimStatementClosed={isClosed}
            surveyorInstructions={surveyorInstructions}
            ownerInstructions={ownerInstructions}
          />
          <InvoiceDisplay invoice={invoice} ownsLock={ownsLock} imageUrl={imageData?.url} isClaimStatementClosed={isClosed} />
        </InvoicePageLayout>
      </PageContent>
    </div>
  );
};
