import { RightAlignedSplitTableHeader, SplitTableHeader } from '@/apps/ClaimStatement/components/InvoiceSplitTable';
import { FCWC } from '@/types';
import { TableHeaderRow } from '@instech/components';
import styled from 'styled-components';

const GridLayout = styled.div<{ $showGeneralAverage?: boolean }>`
  display: grid;
  grid-template-columns: 
    repeat(2, minmax(115px, auto)) // Occurrence & Category
    minmax(auto, 1fr) // CE
    16px // CE tag
    ${props => props.$showGeneralAverage ? 'repeat(2, minmax(auto, 1fr))' : 'minmax(auto, 1fr)'} //GA, PA
    16px // PA tag
    minmax(auto, 1fr) // Remainder
    16px // Remainder tag
    repeat(2, minmax(auto, 1fr)) // Split Sum, Deviation
    56px; // Expand button
`;

interface Props {
  viewAsListOfSplits?: boolean;
  showGeneralAverage: boolean;
}
export const SplitTableAdjuster: FCWC<Props> = ({ children, viewAsListOfSplits, showGeneralAverage }) => {
  if (!viewAsListOfSplits) {
    return <div>{children}</div>;
  }
  return (
    <GridLayout $showGeneralAverage={showGeneralAverage}>
      <TableHeaderRow>
        <SplitTableHeader
          header={{
            title: 'Occurrence',
            propertyName: 'occurrence',
            parameterName: 'occurrence'
          }}
        />
        <SplitTableHeader
          header={{
            title: 'Category',
            propertyName: 'category',
            parameterName: 'category'
          }}
        />
        <RightAlignedSplitTableHeader
          header={{
            title: 'CE',
            propertyName: 'commonExpenses',
            parameterName: 'commonExpenses'
          }}
        />
        <SplitTableHeader
          header={{
            title: '',
            propertyName: 'commonExpensesTag',
            parameterName: 'commonExpensesTag'
          }}
        />
        {showGeneralAverage && (
          <RightAlignedSplitTableHeader
            header={{
              title: 'GA',
              propertyName: 'generalAverage',
              parameterName: 'generalAverage',
            }}
          />
        )}
        <RightAlignedSplitTableHeader
          header={{
            title: 'PA',
            propertyName: 'particularAverage',
            parameterName: 'particularAverage'
          }}
        />
        <SplitTableHeader
          header={{
            title: '',
            propertyName: 'particularAverageTag',
            parameterName: 'particularAverageTag'
          }}
        />
        <RightAlignedSplitTableHeader
          header={{
            title: 'Remainder',
            propertyName: 'ownersWorkConsideration',
            parameterName: 'ownersWorkConsideration'
          }}
        />
        <SplitTableHeader
          header={{
            title: '',
            propertyName: 'ownersWorkConsiderationTag',
            parameterName: 'ownersWorkConsiderationTag'
          }}
        />
        <RightAlignedSplitTableHeader
          header={{
            title: 'Split Sum',
            propertyName: 'splitSum',
            parameterName: 'splitSum'
          }}
        />
        <RightAlignedSplitTableHeader
          header={{
            title: 'Deviation',
            propertyName: 'deviation',
            parameterName: 'deviation'
          }}
        />
        <div />
      </TableHeaderRow>
      {children}
    </GridLayout>
  );
};
