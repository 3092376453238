import { theme } from '@/utils/style/theme';
import { TableCell } from '@instech/components';
import styled, { css } from 'styled-components';
import { FCWC } from '@/types';
import { FilterableTableHeaderMax } from '../../core/TableHeaderComponents';

// Generate style for how a group should appear when the flag has been
// set to collapse it. Auto-generated from the list of groups that are
// currently collapsed in the table.
const createCollapseCSS = (cellClassName: string, collapsedGroups: string[]) => {
  let styles = '';
  collapsedGroups.forEach(group => {
    styles += `
    [data-collapse-group="${group}"] {
      padding: 0px;
      width: 0px;
      min-width: 0px;
      overflow: hidden;
      & > * {
        display: none;
      }
    }
    ${cellClassName}[data-collapse-group="${group}"][data-collapse-border] {
      padding: 0px;
      width: 100%;
      min-width: 32px;
      overflow: hidden;
      background-color: ${theme.marineBlue20} !important;
      & > * {
        display: none;
      }
    }
    `;
  });
  return css`${styles}`;
};

// The part of the header that is collapsed still needs to draw a BG and bottom border,
// so this targets a Tableheader cell in the collapsed group, the one that has the left-side
// collapse border, and makes it still visible (with all children hidden) so it draws the
// bg and border for this part of the table header.
const createCollapseHeaderCSS = (headerClassName: string, collapsedGroups: string[]) => {
  let styles = '';
  collapsedGroups.forEach(group => {
    styles += `
    ${headerClassName}[data-collapse-group="${group}"][data-collapse-border] {
      padding: 0px;
      width 100%;
      min-width: 32px;
      overflow: hidden;
      background-color: ${theme.white};
      & > * {
        display: none;
      }
    }
    `;
  });
  return css`${styles}`;
};

interface ContainerProps {
  collapsedGroups: string[];
}
const Container = styled.div<ContainerProps>`
  display: flex;
  // Style element with collapse border attribute to have left border
  [data-collapse-border] {
    border-left: 1px solid ${props => props.theme.marineBlue60};
  }
  ${props => createCollapseCSS(TableCell, props.collapsedGroups)}
  ${props => createCollapseHeaderCSS(FilterableTableHeaderMax, props.collapsedGroups)};
`;

interface Props {
  collapsedGroups: string[];
}
export const CollapseWrapper: FCWC<Props> = ({ children, collapsedGroups }) => (
  <Container collapsedGroups={collapsedGroups}>
    {children}
  </Container>
);
