export const acceptedTypes = [
  '.pdf',
  '.doc',
  '.docx',
  '.docm',
  '.ppt',
  '.pptx',
  '.txt',
  '.rtf',
  '.xls',
  '.xlsx',
  '.xlsb',
  '.xlsm',
  '.csv',
  '.jpg',
  '.jpeg',
  '.jfif',
  '.bmp',
  '.gif',
  '.tiff',
  '.tif',
  '.png',
  '.zip',
  '.htm',
  '.html',
  '.ics',
  '.msg',
  '.eml'
];
