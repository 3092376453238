export const NOT_APPLICABLE = 'N/A';

/**
 * Height of header when the user has scrolled far enough
 * down for it to collape into a narrow bar.
 */
export const COLLAPSED_HEADER_HEIGHT = 50;

/**
 * Height of the inner page heading, with breadcrumb and buttons,
 * that can sticky to the top of the page as the user scrolls.
 */
export const PAGE_HEADING_HEIGHT = 80;

export const invoiceStates = {
  New: 'New',
  Review: 'Review',
  ReturnToUploader: 'ReturnToUploader',
  ReturnToUploaderAfterConsideration: 'ReturnToUploaderAfterConsideration',
  ReturnToUploaderAfterSharedReview: 'ReturnToUploaderAfterSharedReview',
  ReturnToUploaderAfterConsiderationNoAllocation: 'ReturnToUploaderAfterConsiderationNoAllocation',
  CostAllocation: 'CostAllocation',
  CostReallocation: 'CostReallocation',
  SharedReview: 'SharedReview',
  AdjustersConsideration: 'AdjustersConsideration',
  AdjustersConsiderationNoAllocation: 'AdjustersConsiderationNoAllocation',
  Finished: 'Finished',
  FinishedNoAllocation: 'FinishedNoAllocation',
  FinishedSharedReview: 'FinishedSharedReview',
  Deleted: 'Deleted'
} as const;

export const roles = {
  ClaimsHandler: 'ClaimsHandler',
  Owner: 'Owner',
  Broker: 'Broker',
  Surveyor: 'Surveyor'
};

export const invoiceStatuses = {
  Open: 'Open',
  Closed: 'Closed',
} as const;
