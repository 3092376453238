import React, { useState } from 'react';
import { InvoiceRecord, SurveyorsConsiderationSplit } from '@/types';
import { ReadonlyCollapsiblePane } from '@/common/components/Pane/ReadonlyCollapsiblePane';
import { CommentAndNotes } from '@/components/pages/invoice/core/ReadOnlyInvoice/core/CommentAndNotes';
import {
  InfoLayout, InfoWithLabel, InfoWithLabelAndTag
} from '@/common/components/Text';
import { getConvertedCurrencyText } from '@/utils/number';
import { CurrencySelector } from '@/common/components/Form/CurrencySelector/CurrencySelector';
import { useClaimStatement } from '@/services/claimStatementServices';
import { useParams } from 'react-router-dom';
import { Loader } from '@instech/components';
import { INVOICE_PANE_MARGIN } from '@/utils/style/styleConstants';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { NoExchangeRateInfo } from '../../../EditInvoice/core/NoExchangeRateInfo';

const hasValue = (value?: string | null) => {
  if (value == null) return false;
  return value !== '';
};

interface Props {
  invoiceRecord: InvoiceRecord;
  record: SurveyorsConsiderationSplit;
}

export const SingleReadonlySurveyorsConsiderationPane = ({ record, invoiceRecord }: Props) => {
  const { claimStatementId } = useParams();
  const { data: claimstatement } = useClaimStatement(claimStatementId);
  const [selectedCurrency, setCurrency] = useState(invoiceRecord.currency);
  const useInvoiceCurrency = selectedCurrency === invoiceRecord.currency;

  if (!claimstatement) return <Loader />;

  const handleCurrencyChange = (currency: string) => {
    setCurrency(currency);
  };

  const noExchangeRateVisible = (claimstatement.currency === selectedCurrency && !invoiceRecord.exchangeRate);
  const tooltipId = 'surveyor-readonly-fields-tags-tooltip';

  return (
    <ReadonlyCollapsiblePane title="Surveyor's Consideration" margin={INVOICE_PANE_MARGIN}>
      <CurrencySelector
        first={invoiceRecord?.currency}
        second={claimstatement.currency}
        selected={selectedCurrency}
        setSelected={handleCurrencyChange}
        id="surveyor-read-only-consideration-pane"
      />
      <NoExchangeRateInfo isVisible={noExchangeRateVisible} />
      <SharedTooltip id={tooltipId}>
        <InfoLayout columnMin="160px">
          <InfoWithLabelAndTag
            label="Claim"
            text={getConvertedCurrencyText(record?.claimConsideration, invoiceRecord.exchangeRate, useInvoiceCurrency)}
            tag={record?.claimConsiderationTag}
            tooltipId={tooltipId}
          />
          <InfoWithLabel
            label="Owner"
            text={getConvertedCurrencyText(record?.ownersWorkConsideration, invoiceRecord.exchangeRate, useInvoiceCurrency)}
          />
          <InfoWithLabelAndTag
            label="General Expenses"
            text={getConvertedCurrencyText(record?.generalExpenses, invoiceRecord.exchangeRate, useInvoiceCurrency)}
            tag={record?.generalExpensesTag}
            tooltipId={tooltipId}
          />
          <InfoWithLabel
            label="Adjuster to Consider"
            text={getConvertedCurrencyText(record?.adjustersConsideration, invoiceRecord.exchangeRate, useInvoiceCurrency)}
          />
        </InfoLayout>
      </SharedTooltip>
      <CommentAndNotes
        comment={record?.surveyorsComment}
        commentLabel="Description of Consideration"
        notes={record?.internalNotes}
        showNotes={hasValue(record?.internalNotes)}
        showBorder
      />
    </ReadonlyCollapsiblePane>
  );
};
