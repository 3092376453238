import { FC } from 'react';
import styled from 'styled-components';
import { PageLoader } from '@instech/components';
import { useFeatureFlag } from '@/common/providers/featureFlagProvider';
import { AdjustmentOverview } from '../../layout/AdjustmentOverview';
import { ChapterSidebar } from '../../components/ChapterSidebar';
import { ChapterRouting } from './core/ChapterRouting';
import { chapterSidebarList } from './core/chapterSidebarList';

const ContentLayout = styled.div`
  display: flex;
  gap: 32px;
  padding: 0px 40px 120px;
`;

const SidebarContainer = styled.div`
  flex-shrink: 0;
  width: 272px;
`;

const ChapterContainer = styled.div`
  flex-grow: 1;
`;

interface Props {
}
export const AdjustmentPage: FC<Props> = () => {
  const featureFlagsLoaded = useFeatureFlag('featureFlagsLoaded');

  if (!featureFlagsLoaded) {
    return <PageLoader />;
  }

  return (
    <div>
      <AdjustmentOverview />
      <ContentLayout>
        <SidebarContainer>
          <ChapterSidebar chapters={chapterSidebarList} />
        </SidebarContainer>
        <ChapterContainer>
          <ChapterRouting />
        </ChapterContainer>
      </ContentLayout>
    </div>
  );
};
