import {
  ClaimsData,
  ClaimSearchData,
  NewClaimStatement,
  Occurrence
} from '@/types';
import {
  Form, useField, useFormikContext
} from 'formik';
import { SelectUsers } from '@/components/pages/claim/core/ClaimStatementForm/SelectUsers';
import { ClaimsDataDetails } from '@/components/pages/claim/core/ClaimDetails';
import { ComponentProps, useState } from 'react';
import {
  createOccurrenceFromClaimsData,
  SearchErrorMessage,
  useErrorToggler
} from '@/components/pages/claim/core/ClaimStatementForm/Occurrences';
import { PageGrid } from '@/components/pages/claim/core/ClaimStatementForm';
import { useNavigate } from 'react-router';
import { ClaimStatementSettingsPanel } from './core/ClaimStatementSettingsPanel';
import { SearchWrapper } from './core/StyledPageComponents';
import { validateClaimsData } from './validationSchema';
import { ClaimDataSearch } from './core/ClaimDataSearch';
import { CancelSaveButtons } from './core/CancelSaveButtons';
import { Occurrences } from './core/Occurrences';

type NewClaimStatementPageInnerFormProps = {
  isExperimental: boolean;
}
const NewClaimStatementPageInnerForm = ({ isExperimental }: NewClaimStatementPageInnerFormProps) => {
  const errorToggleData = useErrorToggler();
  const [fetchedClaimData, setFetchedClaimData] = useState<ClaimsData | undefined>();
  const claimSearchData: ClaimSearchData = {
    claimStatementId: errorToggleData.searchData?.claimStatementId,
    claimNumber: errorToggleData.searchData?.claimNumber,
    insuranceYear: errorToggleData.searchData?.insuranceYear
  };
  const { isSubmitting, dirty } = useFormikContext();
  const [currency, setCurrency] = useState('');
  const [,, occurrenceHelpers] = useField<Occurrence[]>('occurrences');
  const [, { value: users }, { setValue: setUsers }] = useField<NewClaimStatement['participants']>('participants');
  const navigate = useNavigate();
  const navigateToDashboard = () => navigate('/');

  // After claim data has been fetched and the form updated,
  // do some other updates as a callback follow-up
  const claimDataFetchCallback = async (data: ClaimsData): Promise<void> => {
    errorToggleData.hideError();
    errorToggleData.setSearchData({
      claimNumber: data.claimNumber,
      insuranceYear: data.insuranceYear
    });
    const validation = await validateClaimsData(data);
    if (validation.status !== 'success') {
      return;
    }
    setFetchedClaimData(data);
    setCurrency(data.currency);

    await occurrenceHelpers.setValue([
      createOccurrenceFromClaimsData(data)
    ]);
  };

  const handleUsersChanged: ComponentProps<typeof SelectUsers>['onUsersChange'] = async newUsers => {
    await setUsers({
      ...users,
      ...newUsers
    });
  };

  return (
    <Form>
      <SearchWrapper>
        <ClaimDataSearch onSuccess={claimDataFetchCallback} onError={errorToggleData.showError} />
        <CancelSaveButtons onCancel={navigateToDashboard} isPending={isSubmitting} isDirty={dirty} hasFetched />
      </SearchWrapper>
      {errorToggleData.isErrorVisible && (
        <SearchErrorMessage onClose={errorToggleData.hideError} errorToggleData={errorToggleData} />
      )}
      <PageGrid>
        <div>
          <ClaimsDataDetails data={fetchedClaimData} isExperimentalClaimStatement={isExperimental} maxColumns={5} />
          <Occurrences currency={currency} isExperimentalClaimStatement={isExperimental} />
        </div>
        <div>
          <SelectUsers
            users={users}
            claimSearchData={claimSearchData}
            onUsersChange={handleUsersChanged}
            notificationLocation="outside" />
          <ClaimStatementSettingsPanel />
        </div>
      </PageGrid>
    </Form>
  );
};

export { NewClaimStatementPageInnerForm };
