import { COLLAPSED_HEADER_HEIGHT, PAGE_HEADING_HEIGHT } from '@/utils/constants';
import { useMemo } from 'react';

/**
 * Calculate the stickyTop offsets of the different table elements, based on the height of the elements
 */
export const useStickyTopValues = (selectableRows: boolean) => useMemo(() => {
  const baseGapHeight = COLLAPSED_HEADER_HEIGHT + PAGE_HEADING_HEIGHT;
  const buttonHeight = selectableRows ? 68 : 0;
  const subheaderHeight = 33;

  const buttonsStickyTop = baseGapHeight;
  const subheaderStickyTop = buttonsStickyTop + buttonHeight;
  const headerStickyTop = subheaderStickyTop + subheaderHeight;

  return {
    buttons: `${buttonsStickyTop}px`,
    subheader: `${subheaderStickyTop}px`,
    header: `${headerStickyTop}px`
  };
}, [selectableRows]);
