import React, { FunctionComponent } from 'react';
import { ReadonlyCollapsiblePane } from '@/common/components/Pane/ReadonlyCollapsiblePane';
import { ClaimsHandlerInstructions } from './ClaimsHandlerInstructions';

interface Props {
  instructions: string;
  title: string;
}
export const InstructionsPane: FunctionComponent<Props> = ({ instructions, title }) => (
  <ReadonlyCollapsiblePane title={title} padding="20px 16px 28px" margin="0px 0px 28px">
    <ClaimsHandlerInstructions instructions={instructions} />
  </ReadonlyCollapsiblePane>
);
