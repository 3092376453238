import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { OccurrenceInformation } from '@/apps/Adjustment/types';
import { DatePicker, TextField } from '@instech/components';
import { SummaryItemSmall } from '@/common/components/Summary';
import { textOrDash } from '@/utils/text';

const OccurrencesWrapper = styled.div`
    margin-top: 10px;
`;

const InputFieldsWrapper = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 2fr 1fr 1fr;
`;

const InformationFieldsWrapper = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 250px 250px 250px;
    margin: 12px 0px;
`;

interface OccurrenceListProps {
  occurrence: OccurrenceInformation;
  index: number;
}
export const OccurrenceList: FunctionComponent<OccurrenceListProps> = ({ occurrence, index }) => (
  <OccurrencesWrapper>
    <InputFieldsWrapper>
      <TextField placeholder="" name={`occurrences.${index}.name`} label="Occurrence Name *" />
      <TextField placeholder="" name={`occurrences.${index}.location`} label="Loss Location" />
      <DatePicker name={`occurrences.${index}.claimDate`} label="Date of Loss" />
    </InputFieldsWrapper>
    <InformationFieldsWrapper>
      <SummaryItemSmall item={{ title: 'Claim Number', value: `${textOrDash(occurrence.claimNumber)}` }} wrapText />
      <SummaryItemSmall item={{ title: 'Insurance Year', value: `${textOrDash(occurrence.insuranceYear)}` }} wrapText />
      <SummaryItemSmall item={{ title: 'Interest', value: `${textOrDash(occurrence.interest)}` }} wrapText />
    </InformationFieldsWrapper>
  </OccurrencesWrapper>
);
