import { LabelValuePair } from '@instech/components';
import { useClaimsHandlers, useCurrentUser } from '@/services/userInfoServices';
import { useEffect, useState } from 'react';

type ClaimsHandlerOptionsState = {
  options: LabelValuePair[],
  selectedOptions: LabelValuePair[];
  setSelectedOptions: (selectedValues: string[]) => void;
}
export const useClaimsHandlerOptions: () => ClaimsHandlerOptionsState = () => {
  const { data: claimsHandlers } = useClaimsHandlers();
  const currentUser = useCurrentUser();

  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    if (currentUser) {
      setSelected([currentUser.id]);
    }
  }, [currentUser]);

  const setSelectedOptions = (selectedValues: string[]) => {
    setSelected(selectedValues);
  };

  if (!currentUser || !claimsHandlers) {
    return {
      options: [],
      selectedOptions: [],
      setSelectedOptions
    };
  }

  const claimsHandlerOptions = claimsHandlers?.map(s => ({
    label: s.fullName,
    value: s.userInfoId
  })) || [];

  claimsHandlerOptions.sort((a, b) => a.label.localeCompare(b.label));

  const currentUserOption = {
    label: 'My Claim Statements',
    value: currentUser.id
  };

  const options = [currentUserOption, ...claimsHandlerOptions.filter(c => c.value !== currentUser?.id) || []];

  const selectedOptions = options.filter(option => selected.includes(option.value));

  return {
    options,
    setSelectedOptions,
    selectedOptions
  };
};
