import { TableGroupingRadio } from '@/common/components/Table/TableGrouping';
import { GroupingOption, groupingOptionsList } from '@/common/components/Table/TableGrouping/types';
import { useManagedTableSelect } from '@/common/components/Table/TanstackTable/hooks/useManagedTableSelect';
import { useTypedModal } from '@/common/modules/Modal';
import { shareInvoicesForReview } from '@/services/invoice/invoiceServices';
import { updateInvoiceState } from '@/services/invoice/invoiceStateService';
import {
  ClaimStatementSettings, InvoiceOverview, InvoiceState
} from '@/types';
import { invoiceStates } from '@/utils/constants';
import {
  getInvoiceStateFromCHToOwner, getInvoiceStateToFinished, transferInvoicesInGroups
} from '@/utils/invoicesTransfer';
import { CheckboxControlled, Pane } from '@instech/components';
import {
  FC,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { useSWRConfig } from 'swr';
import { RefreshCommands } from '../../ClaimsHandlerClaimStatementPage';
import { TableHeaderButtons } from '../core/Components';
import { NoInvoicePane } from '../core/NoInvoicePane';
import { useTraversalOrderContext } from '../core/TraversalContext';
import { defaultMarkAsFinshedProps, markAsFinishedModal } from '../modal/MarkAsFinishedModal';
import { returnClaimsHandlerToOwnerModal } from '../modal/ReturnClaimsHandlerToOwnerModal';
import { returnSingleInvoiceClaimsHandlerToSurveyorModal } from '../modal/ReturnSingleInvoiceClaimsHandlerToSurveyorModal';
import { transferClaimsHandlerToSurveyorModal } from '../modal/TransferClaimsHandlerToSurveyorModal';
import { InvoiceTableViews } from './TableViews';
import { InvoiceButtons } from './core/InvoiceButtons';
import { useClaimsHandlerDisableButtons } from './hooks/useClaimsHandlerDisableButtons';
import { useClaimsHandlerSelectedStates } from './hooks/useClaimsHandlerSelectedStates';
import {
  getCompleteInvoices, getDeviatingInvoices, getIncompleteInvoices
} from './utils';

const TableContainer = styled.div`
  padding-top: 40px;
`;

const StyledHeaderContainer = styled(TableHeaderButtons)`
  display: flex;
  align-items: center;
  padding: 0px 20px 20px;
  gap: 20px;
`;

const StyledCheckbox = styled(CheckboxControlled)`
  // The CheckboxControlled styles were loading after these styles in the feature environment for some reason.
  // This caused the width to remain 100%.
  width: auto !important;
  color: ${props => props.theme.marineBlue};
`;

interface InnerProps {
  data: InvoiceOverview;
  claimStatementSettings?: ClaimStatementSettings;
  onRowClick: (invoiceId: string) => void;
  onInvoicesUpdateSuccess: (refreshCommands?: RefreshCommands) => Promise<void>;
}
const InvoiceTableClaimsHandlerInner: FC<InnerProps> = ({
  data,
  claimStatementSettings,
  onRowClick,
  onInvoicesUpdateSuccess
}) => {
  const { claimStatementId } = useParams();
  const { cache } = useSWRConfig();

  const [selectedGrouping, setSelectedGrouping] = useState<GroupingOption>(groupingOptionsList[0]);
  const [showFullComments, setShowFullComments] = useState<boolean>(false);
  const [showFullCommentsCheckbox, setShowFullCommentsCheckbox] = useState<boolean>(true);

  const { tableState, selectionArray, updateSelection } = useManagedTableSelect({});
  const { setCurrentTraversalOrder } = useTraversalOrderContext();

  // Cache a list of the states of the invoice rows that have been selected
  const selectedStates = useClaimsHandlerSelectedStates(data, selectionArray);
  // Get an overview of what state-changing buttons should be enabled/disabled
  const disabledButtons = useClaimsHandlerDisableButtons(data, selectedStates);

  // Reset what rows are selected when the grouping changes
  useEffect(() => updateSelection([]), [selectedGrouping, updateSelection]);

  const handleSetGrouping = (grouping: GroupingOption) => {
    setShowFullCommentsCheckbox(grouping === 'No group');
    setSelectedGrouping(grouping);
  };

  const transferInvoices = async (invoicesIds: string[], destinationState: InvoiceState, instructions?: string) => {
    if (claimStatementId === undefined) {
      return 'failed';
    }

    await updateInvoiceState(claimStatementId, {
      destinationState,
      instructions,
      invoiceIdList: invoicesIds
    }, cache);

    updateSelection([...selectionArray.filter(x => !invoicesIds.includes(x))]);
    await onInvoicesUpdateSuccess();
    return 'success';
  };

  const transferInvoiceGroups = async (
    invoiceStateFactoryCallback: (state: InvoiceState) => InvoiceState,
    instructions?: string,
    selected: string[] = selectionArray
  ) => {
    const invoicesToReturn = data.invoices.filter(x => selected.includes(x.id));
    const result = await transferInvoicesInGroups(
      claimStatementId!,
      invoicesToReturn,
      invoiceStateFactoryCallback,
      onInvoicesUpdateSuccess,
      cache,
      instructions
    );

    if (result === 'success') {
      updateSelection([...selected.filter(x => !invoicesToReturn.map(y => y.id).includes(x))]);
    }

    return result;
  };

  const { open: openReturnModal } = useTypedModal(returnClaimsHandlerToOwnerModal({
    claimStatementId: claimStatementId!,
    numberOfInvoices: selectionArray.length,
    firstInvoiceId: selectionArray.length === 1 ? selectionArray[0] : '',
    onReturn: async instructions => transferInvoiceGroups(getInvoiceStateFromCHToOwner, instructions)
  }));

  const { open: openTransferModal } = useTypedModal(transferClaimsHandlerToSurveyorModal({
    claimStatementId: claimStatementId!,
    firstInvoiceId: selectionArray.length === 1 ? selectionArray[0] : '',
    invoices: selectedStates,
    transfer: async (destinationState, instructions) => transferInvoices(selectionArray, destinationState, instructions)
  }));

  const { open: openReturnToSurveyorModal } = useTypedModal(returnSingleInvoiceClaimsHandlerToSurveyorModal({
    invoices: selectedStates,
    transfer: async (destinationState, instructions) => transferInvoices(selectionArray, destinationState, instructions),
    claimStatementId: claimStatementId!
  }));

  const { open: openMarkAsFinishedModal } = useTypedModal(markAsFinishedModal(defaultMarkAsFinshedProps));

  const handleMarkAsFinished = async (invoicesToFinish: string[]) => {
    await transferInvoiceGroups(getInvoiceStateToFinished, undefined, invoicesToFinish);
  };

  const handleFinished = async () => {
    const selectedInvoices = data.invoices.filter(invoice => selectionArray.includes(invoice.id));
    const deviatingInvoices = getDeviatingInvoices(selectedInvoices);
    const incompleteInvoices = getIncompleteInvoices(selectedInvoices);
    const completeInvoices = getCompleteInvoices(selectedInvoices, deviatingInvoices, incompleteInvoices);

    // show modal if some invoices cannot be finished, otherwise just finish them
    if (completeInvoices.length !== selectedInvoices.length) {
      const invoicesToFinish = completeInvoices.map(invoice => invoice.id);
      const finishInvoices = () => handleMarkAsFinished(invoicesToFinish);
      openMarkAsFinishedModal({
        totalInvoiceCount: selectedInvoices.length,
        completeInvoiceCount: completeInvoices.length,
        deviatingInvoiceCount: deviatingInvoices.length,
        incompleteInvoiceCount: incompleteInvoices.length,
        finishInvoices
      });
      return;
    }

    await handleMarkAsFinished(selectionArray);
  };

  const handleOnOpen = () => {
    setCurrentTraversalOrder(selectionArray);
    onRowClick(selectionArray[0]);
  };

  const handleReturn = async () => {
    openReturnModal();
  };

  const handleShareWithSurveyor = async () =>
    selectedStates[0].state === invoiceStates.AdjustersConsideration ? openReturnToSurveyorModal() : openTransferModal();

  const handleShareForReview = async () => {
    if (selectionArray.length === 0 || !claimStatementId) return;
    await shareInvoicesForReview(claimStatementId, { invoiceIdList: selectionArray, IsSharedForReview: true });
    await onInvoicesUpdateSuccess({ refreshInvoiceOverview: true, refreshSummary: false });
    updateSelection([]);
  };

  const handleRevokeFromReview = async () => {
    if (selectionArray.length === 0 || !claimStatementId) return;
    await shareInvoicesForReview(claimStatementId, { invoiceIdList: selectionArray, IsSharedForReview: false });
    await onInvoicesUpdateSuccess({ refreshInvoiceOverview: true, refreshSummary: false });
    updateSelection([]);
  };

  const handleOnRowClick = useCallback((invoiceId: string, invoiceIdList: string[]) => {
    setCurrentTraversalOrder(invoiceIdList, invoiceId);
    onRowClick(invoiceId);
  }, [onRowClick, setCurrentTraversalOrder]);

  return (
    <div>
      <TableGroupingRadio
        selected={selectedGrouping}
        onChange={handleSetGrouping}
      />
      <Pane title="Invoices" color="green" padding="20px 0px 0px">
        <StyledHeaderContainer>
          <InvoiceButtons
            selected={selectionArray.length}
            onOpen={handleOnOpen}
            onFinished={handleFinished}
            onReturn={handleReturn}
            onShareWithSurveyor={handleShareWithSurveyor}
            onShareForReview={handleShareForReview}
            onRevokeFromReview={handleRevokeFromReview}
            disableReturn={disabledButtons.return}
            disableShare={disabledButtons.share}
            disableShareForReview={disabledButtons.shareForReview}
            disableFinished={disabledButtons.finished}
            enableRevokeFromReview={disabledButtons.revokeFromReview} />
          {showFullCommentsCheckbox && (
            <div>
              <StyledCheckbox
                name="showFullComments"
                rightLabel="Show full comments"
                selected={showFullComments}
                onChange={() => setShowFullComments(!showFullComments)}
                noTopLabel
                noErrors />
            </div>
          )}
        </StyledHeaderContainer>
        <InvoiceTableViews
          data={data}
          settings={claimStatementSettings}
          tableSelectState={tableState}
          onRowClick={handleOnRowClick}
          selectedGrouping={selectedGrouping}
          showFullComments={showFullComments} />
      </Pane>
    </div>
  );
};

interface Props extends InnerProps {
  showTable?: boolean;
}
export const InvoiceTableClaimsHandler: FC<Props> = ({
  data,
  claimStatementSettings,
  onRowClick,
  onInvoicesUpdateSuccess,
  showTable = true
}) => {
  if (!showTable) {
    return (
      <TableContainer>
        <NoInvoicePane />
      </TableContainer>
    );
  }

  return (
    <TableContainer>
      <InvoiceTableClaimsHandlerInner
        data={data}
        claimStatementSettings={claimStatementSettings}
        onRowClick={onRowClick}
        onInvoicesUpdateSuccess={onInvoicesUpdateSuccess}
      />
    </TableContainer>
  );
};
