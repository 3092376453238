import styled from 'styled-components';

export const TextWrapper = styled.div`
  padding-bottom: 56px;
  text-align: center;
  color: ${props => props.theme.marineBlue};
`;

export const RegularText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.marineBlue};
  font-size: 16px;

  svg {
    margin-right: 8px;
  }
`;

export const EmphasizedText = styled.em`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.marineBlue};
  font-size: 18px;
  margin-top: 40px;
  font-style: italic;

  svg {
    margin-right: 8px;
  }
`;

export const FooterText = styled(RegularText)`
  margin-top: 40px;
  font-style: italic;
`;

export const BoldText = styled.div`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 18px;
`;

export const Description = styled.div`
  font-family: 'CalibriWeb Light';
  margin-top: 12px;
  text-align: center;
`;
