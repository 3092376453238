import {
  adjusterCommonExpensesTags, adjusterOwnersWorkTags, adjusterParticularAverageTags, surveyorClaimConsiderationTags, surveyorGeneralExpensesTags
} from '@/types';

const aggregatedTags = [
  ...adjusterCommonExpensesTags,
  ...adjusterParticularAverageTags,
  ...adjusterOwnersWorkTags,
  ...surveyorClaimConsiderationTags,
  ...surveyorGeneralExpensesTags
];

export const tagText = (tag?: string | null) => {
  if (!tag) return '';
  return aggregatedTags.find(t => t.value === tag)?.label;
};
