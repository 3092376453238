import {
  ellipsisOrDash, textOrDash, textOrNone
} from '@/utils/text';
import { CellContext } from '@tanstack/react-table';
import { FC } from 'react';
import styled from 'styled-components';
import { useTableOptionsContext } from '../providers/TableOptionsContext';
import { TableCellAlign } from '../types';
import { getHeaderGroup, getMergedMeta } from '../utils';
import { BaseCellProps, BaseTableCell } from './BaseTableCell';

interface StyledCellProps {
  align?: 'left' | 'right' | 'center';
}

const FullCommentCell = styled(BaseTableCell)`
  min-width: 500px;
  max-width: 900px;
  white-space: pre-wrap;
`;

const CellContent = styled.div <StyledCellProps>`
  text-align: ${props => props.align};
`;

interface BaseCommentCellProps extends BaseCellProps {
  id: string;
  value: string | undefined;
  align?: TableCellAlign;
  showFullComments?: boolean;
  tooltipId?: string;
  onClick?: () => void;
}
/**
 * TableCellComment, but outside of the Tanstack cell context, so that properties
 * can be provided and the component can be composed manually.
 */
export const TableCellCommentBase: FC<BaseCommentCellProps> = ({
  id,
  value,
  headerGroup,
  cellVariant,
  columnBorder,
  align,
  span,
  showFullComments,
  tooltipId,
  onClick
}) => {
  const sharedProps = { headerGroup, cellVariant, columnBorder, span, onClick };

  if (showFullComments) {
    return (
      <FullCommentCell id={id} {...sharedProps}>
        <CellContent align={align}>
          {textOrDash(value)}
        </CellContent>
      </FullCommentCell>
    );
  }
  return (
    <BaseTableCell id={id} {...sharedProps}>
      <CellContent align={align} data-for={tooltipId} data-tip={textOrNone(value)}>
        {ellipsisOrDash(value)}
      </CellContent>
    </BaseTableCell>
  );
};

/**
 * Simple table cell component for displaying text as-is. Use this if
 * the value is in the table data as it should be, and it just has to
 * be rendered out.
 */
interface CommentCellProps {
  data: CellContext<any, any>;
  overrideValue?: string;
  tooltipId?: string;
}
export const TableCellComment: FC<CommentCellProps> = ({
  data,
  overrideValue,
  tooltipId
}) => {
  const mergedMeta = getMergedMeta(data.column);
  const headerGroup = getHeaderGroup(data.column);
  const tableOptions = useTableOptionsContext();
  const value = data.renderValue();

  return (
    <TableCellCommentBase
      id={data.cell.id}
      value={overrideValue ?? value}
      headerGroup={headerGroup}
      cellVariant={mergedMeta.tableCellVariant}
      columnBorder={mergedMeta.columnBorder}
      align={mergedMeta.align}
      showFullComments={tableOptions.showFullComments}
      tooltipId={tooltipId}
    />
  );
};
