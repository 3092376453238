import React, { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import { UserInfoDto } from '@/types';
import { CollapsedUserDetails } from './CollapsedUserDetails';

const NoUsersContainer = styled.div`
  color: ${props => props.theme.marineBlue};
  font-style: italic;
`;

// auto-insert gap between UserDetails in the list
// if there are more than one
const UserDetailWrapper = styled.div`
  color: ${props => props.theme.marineBlue};
  & > div + div {
    margin-top: 20px;
  }
`;

interface Props {
  users?: UserInfoDto[];
  startOpen?: boolean;
  noUsersMessage?: string;
}
/**
 * Displays an array of User Details (name, email, phone number) in a list.
 * If there is only one, it will render open. All of the rendered User Details
 * can be collapsed by the user.
 *
 * If there are multiple, users will initially render closed. If `startOpen` is true,
 * this will override and all users will initially render open.
 *
 * A fallback message when there are no users, can be provided via `noUsersMessage`.
 */
export const CollapsedUserDetailsList: FC<Props> = ({ users, startOpen = false, noUsersMessage }) => {
  const hasMultipleUsers = users ? users.length > 1 : false;

  if (!users?.length) {
    return (
      <NoUsersContainer>{noUsersMessage}</NoUsersContainer>
    );
  }

  return (
    <UserDetailWrapper>
      {users?.map(user => (
        <CollapsedUserDetails
          key={`${user.fullName} + ${user.id}}`}
          user={user}
          startCollapsed={!startOpen && hasMultipleUsers} />
      ))}
    </UserDetailWrapper>
  );
};
