import { SplitTableHeader, RightAlignedSplitTableHeader } from '@/apps/ClaimStatement/components/InvoiceSplitTable';
import { FCWC } from '@/types';
import { TableHeaderRow } from '@instech/components';
import styled from 'styled-components';

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 
    repeat(2, minmax(115px, auto)) // Occurrence & Catgory
    minmax(auto, 1fr) // Claim
    16px // Claim tag
    repeat(2, minmax(auto, 1fr)) // Owner, GE 
    16px // GE tag
    repeat(2, minmax(auto, 1fr)) // Adj. Cons, Sum
    56px; // Expand button
`;

interface Props {
  isMultiple?: boolean;
}
export const SplitTableSurveyor: FCWC<Props> = ({ children, isMultiple }) => {
  if (!isMultiple) {
    return <div>{children}</div>;
  }
  return (
    <GridLayout>
      <TableHeaderRow>
        <SplitTableHeader
          header={{
            title: 'Occurrence',
            propertyName: 'occurrence',
            parameterName: 'occurrence'
          }}
        />
        <SplitTableHeader
          header={{
            title: 'Category',
            propertyName: 'category',
            parameterName: 'category'
          }}
        />
        <RightAlignedSplitTableHeader
          header={{
            title: 'Claim',
            propertyName: 'claim',
            parameterName: 'claim'
          }}
        />
        <SplitTableHeader
          header={{
            title: '',
            propertyName: 'claimConsiderationTag',
            parameterName: 'claimConsiderationTag'
          }}
        />
        <RightAlignedSplitTableHeader
          header={{
            title: 'Owner',
            propertyName: 'ownersWork',
            parameterName: 'ownersWork'
          }}
        />
        <RightAlignedSplitTableHeader
          header={{
            title: 'Gen. Exp.',
            propertyName: 'generalExpenses',
            parameterName: 'generalExpenses'
          }}
        />
        <SplitTableHeader
          header={{
            title: '',
            propertyName: 'generalExpensesTag',
            parameterName: 'generalExpensesTag'
          }}
        />
        <RightAlignedSplitTableHeader
          header={{
            title: 'Adj. Cons.',
            propertyName: 'adjustersConsideration',
            parameterName: 'adjustersConsideration'
          }}
        />
        <RightAlignedSplitTableHeader
          header={{
            title: 'Split Sum',
            propertyName: 'splitSum',
            parameterName: 'splitSum'
          }}
        />
        <div />
      </TableHeaderRow>
      {children}
    </GridLayout>
  );
};
