import { Pane } from '@instech/components';
import { FC } from 'react';
import { FieldArray } from 'formik';
import { OccurrenceInnerForm } from './OccurrenceInnerForm';

interface Props {
  currency: string;
  isExperimentalClaimStatement: boolean;
}

export const Occurrences: FC<Props> = ({
  currency,
  isExperimentalClaimStatement,
}) => (
  <Pane title="Occurrences" color="green" padding="16px" margin="20px 0px 0px">
    <FieldArray
        name="occurrences"
        render={arrayHelpers =>
          <OccurrenceInnerForm currency={currency} arrayHelpers={arrayHelpers} isExperimentalClaimStatement={isExperimentalClaimStatement} />}
      />
  </Pane>
);
