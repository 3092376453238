import {
  Occurrence, SurveyorsConsiderationSplit, SurveyorsConsiderationSplitForm
} from '@/types';
import { convertCurrency, getExchangeCalculationValues } from '@/utils/invoice/convertCurrency';
import {
  createSumOrDash,
  formatWithDigitsOrDash,
  formatWithTruncateOrEmpty,
  numberOrDash
} from '@/utils/number';
import { ellipsisOrDash, textOrDash } from '@/utils/text';

const sum = (splits: SurveyorsConsiderationSplitForm[], key: keyof SurveyorsConsiderationSplitForm) => {
  const values = splits.map(s => s[key]);
  const allEmpty = values.every(value => value === '');
  if (allEmpty) {
    return undefined;
  }
  return values.filter(f => f !== undefined)
    .reduce((partialSum, nextNumber) => Number(partialSum ?? 0) + Number(nextNumber), undefined) as number | undefined;
};

// different types requires some duplicate code
const readonlySum = (splits: SurveyorsConsiderationSplit[], key: keyof SurveyorsConsiderationSplit) => {
  const values = splits.map(s => s[key]);
  const allEmpty = values.every(value => value === '');
  if (allEmpty) {
    return undefined;
  }
  return values.filter(f => f !== undefined)
    .reduce((partialSum, nextNumber) => Number(partialSum ?? 0) + Number(nextNumber), undefined) as number | undefined;
};

function convertTotals(
  adjustersConsideration: number | undefined,
  exchangeVal: number | null,
  allowZero: boolean,
  claimConsideration: number | undefined,
  generalExpenses: number | undefined,
  ownersWorkConsideration: number | undefined
) {
  const adjustersConsiderationTotal = numberOrDash(convertCurrency(adjustersConsideration, exchangeVal, allowZero));
  const claimConsiderationTotal = numberOrDash(convertCurrency(claimConsideration, exchangeVal, allowZero));
  const generalExpensesTotal = numberOrDash(convertCurrency(generalExpenses, exchangeVal, allowZero));
  const ownersWorkConsiderationTotal = numberOrDash(convertCurrency(ownersWorkConsideration, exchangeVal, allowZero));

  const totalSum = numberOrDash(
    convertCurrency(
      createSumOrDash([adjustersConsideration, claimConsideration, generalExpenses, ownersWorkConsideration]),
      exchangeVal,
      allowZero
    )
  );

  return {
    adjustersConsiderationTotal: formatWithDigitsOrDash(adjustersConsiderationTotal, true),
    claimConsiderationTotal: formatWithDigitsOrDash(claimConsiderationTotal, true),
    generalExpensesTotal: formatWithDigitsOrDash(generalExpensesTotal, true),
    ownersWorkConsiderationTotal: formatWithDigitsOrDash(ownersWorkConsiderationTotal, true),
    totalSum: formatWithDigitsOrDash(totalSum, true)
  };
}

const calculateSingleSplitValues = (
  useInvoiceCurrency: boolean | undefined,
  exchangeRate: number | null | undefined,
  consideration: SurveyorsConsiderationSplitForm | SurveyorsConsiderationSplit,
  occurrence: string,
  category: string,
  considerationValue: string | number | undefined | null,
  ownersWorkValue: string | number | undefined | null,
) => {
  const exchangeMeta = getExchangeCalculationValues(exchangeRate, useInvoiceCurrency);
  const claim = numberOrDash(convertCurrency(considerationValue, exchangeMeta.exchangeRate, exchangeMeta.allowZero));
  const ownersWork = numberOrDash(convertCurrency(ownersWorkValue, exchangeMeta.exchangeRate, exchangeMeta.allowZero));
  const genExpenses = numberOrDash(convertCurrency(consideration.generalExpenses, exchangeMeta.exchangeRate, exchangeMeta.allowZero));
  const adjConsideration = numberOrDash(convertCurrency(consideration.adjustersConsideration, exchangeMeta.exchangeRate, exchangeMeta.allowZero));

  // Reduce sum to single number, or return dash if all values are dashes
  const getSplitSum = createSumOrDash([claim, ownersWork, genExpenses, adjConsideration]);

  return {
    occurrence,
    category,
    claim: formatWithTruncateOrEmpty(claim),
    ownersWork: formatWithTruncateOrEmpty(ownersWork),
    genExpenses: formatWithTruncateOrEmpty(genExpenses),
    adjConsideration: formatWithTruncateOrEmpty(adjConsideration),
    splitSum: formatWithTruncateOrEmpty(getSplitSum)
  };
};

const calculateSingleSurveyorSplitFormValues = (
  useInvoiceCurrency: boolean | undefined,
  exchangeRate: number | null | undefined,
  consideration: SurveyorsConsiderationSplitForm,
  occurrence: string,
  category: string
) => {
  const considerationValue = consideration.form.claimConsidered ? consideration.claimConsideration : consideration.initialClaim;
  const ownersWorkValue = consideration.form.ownersWorkConsidered ? consideration.ownersWorkConsideration : consideration.initialOwnersWork;

  return calculateSingleSplitValues(useInvoiceCurrency, exchangeRate, consideration, occurrence, category, considerationValue, ownersWorkValue);
};

const calculateSingleSurveyorSplitValues = (
  useInvoiceCurrency: boolean | undefined,
  exchangeRate: number | null | undefined,
  consideration: SurveyorsConsiderationSplit,
  occurrence: string,
  category: string
) => {
  const considerationValue = consideration.claimConsideration ?? consideration.initialClaim;
  const ownersWorkValue = consideration.ownersWorkConsideration ?? consideration.initialOwnersWork;

  return calculateSingleSplitValues(useInvoiceCurrency, exchangeRate, consideration, occurrence, category, considerationValue, ownersWorkValue);
};

export const calculateSurveyorValues = (
  consideration: SurveyorsConsiderationSplitForm,
  exchangeRate?: number | null,
  useInvoiceCurrency?: boolean
) => {
  const category = textOrDash(consideration.category?.value);
  const occurrence = ellipsisOrDash(consideration.occurrence?.label, 10);

  // Simplify some values before running through calculations
  return calculateSingleSurveyorSplitFormValues(useInvoiceCurrency, exchangeRate, consideration, occurrence, category);
};

export const calculateReadonlySurveyorSummaryValues = (
  consideration: SurveyorsConsiderationSplit,
  exchangeRate?: number | null,
  useInvoiceCurrency?: boolean,
  occurrenceList?: Occurrence[]
) => {
  const category = textOrDash(consideration.category);
  const occurrenceName = occurrenceList?.find(o => o.id === consideration.occurrenceId)?.name;
  const occurrence = ellipsisOrDash(occurrenceName, 10);

  return calculateSingleSurveyorSplitValues(useInvoiceCurrency, exchangeRate, consideration, occurrence, category);
};

export const calculateSurveyorSummaryValues = (
  splits: SurveyorsConsiderationSplitForm[],
  exchangeRate?: number | null,
  useInvoiceCurrency?: boolean
) => {
  const exchangeMeta = getExchangeCalculationValues(exchangeRate, useInvoiceCurrency);

  const adjustersConsideration = sum(splits, 'adjustersConsideration');
  const claimConsideration = sum(splits, 'claimConsideration');
  const generalExpenses = sum(splits, 'generalExpenses');
  const ownersWorkConsideration = sum(splits, 'ownersWorkConsideration');

  return convertTotals(adjustersConsideration, exchangeMeta.exchangeRate, exchangeMeta.allowZero, claimConsideration, generalExpenses, ownersWorkConsideration);
};

export const calculateMultipleReadonlySurveyorSummaryValues = (
  splits: SurveyorsConsiderationSplit[],
  exchangeRate?: number | null,
  useInvoiceCurrency?: boolean
) => {
  const exchangeMeta = getExchangeCalculationValues(exchangeRate, useInvoiceCurrency);

  const adjustersConsideration = readonlySum(splits, 'adjustersConsideration');
  const claimConsideration = readonlySum(splits, 'claimConsideration');
  const generalExpenses = readonlySum(splits, 'generalExpenses');
  const ownersWorkConsideration = readonlySum(splits, 'ownersWorkConsideration');

  return convertTotals(adjustersConsideration, exchangeMeta.exchangeRate, exchangeMeta.allowZero, claimConsideration, generalExpenses, ownersWorkConsideration);
};

/**
 * Used to calculate Surveyor's Consideration values in a consideration line
 * when viewed readonly within the Adjuster's Consideration Pane.
 */
export const calculateSurveyorReadonlySplitValues = (
  surveyorSplit?: SurveyorsConsiderationSplit,
  exchangeRate?: number | null,
  useInvoiceCurrency?: boolean
): SurveyorsConsiderationSplit | null => {
  if (!surveyorSplit) return null;
  const exchangeMeta = getExchangeCalculationValues(exchangeRate, useInvoiceCurrency);
  return {
    ...surveyorSplit,
    generalExpenses: convertCurrency(surveyorSplit.generalExpenses, exchangeMeta.exchangeRate, exchangeMeta.allowZero),
    claimConsideration: convertCurrency(surveyorSplit.claimConsideration, exchangeMeta.exchangeRate, exchangeMeta.allowZero),
    ownersWorkConsideration: convertCurrency(surveyorSplit.ownersWorkConsideration, exchangeMeta.exchangeRate, exchangeMeta.allowZero),
    adjustersConsideration: convertCurrency(surveyorSplit.adjustersConsideration, exchangeMeta.exchangeRate, exchangeMeta.allowZero)
  };
};
