import { OrAnyString } from '@/types';

/**
 * Enum as the master record of all allowed table grouping options
 */
export enum groupingOptions {
  noGroup = 'No group',
  category = 'Category'
}

/**
 * Type for a single table grouping option
 */
export type GroupingOption = OrAnyString<groupingOptions>;

/**
 * Available table grouping options as a list of strings
 */
export const groupingOptionsList = Object.values(groupingOptions);
