import {
  ApplicationRole,
  ClaimStatement,
  LatestClaimsHandlerStatus,
  LatestClaimsHandlerStatusDict,
  LatestOwnerStatus,
  LatestOwnerStatusDict,
  LatestSurveyorStatus,
  LatestSurveyorStatusDict,
  Nullable
} from '@/types';
import { FC } from 'react';
import styled from 'styled-components';

const ownerStatusLabelDictionary: Record<LatestOwnerStatus, string> = {
  [LatestOwnerStatusDict.newClaimStatement]: 'New claim statement',
  [LatestOwnerStatusDict.returnedInvoices]: 'Returned invoice(s)',
  [LatestOwnerStatusDict.invoicesToReview]: 'Invoice(s) to review',
  [LatestOwnerStatusDict.claimStatementMarkedAsComplete]: 'Claim marked as completed',
  [LatestOwnerStatusDict.claimStatementClosed]: '',
  [LatestOwnerStatusDict.noStatus]: '',
};

const surveyorStatusLabelDictionary: Record<LatestSurveyorStatus, string> = {
  [LatestSurveyorStatusDict.newClaimStatement]: 'New claim statement',
  [LatestSurveyorStatusDict.newInvoicesToConsider]: 'New invoice(s) to consider',
  [LatestSurveyorStatusDict.considerationMarkedAsComplete]: 'Consideration marked as completed',
  [LatestSurveyorStatusDict.claimStatementClosed]: '',
  [LatestSurveyorStatusDict.noStatus]: '',
};

const claimsHandlerStatusLabelDictionary: Record<LatestClaimsHandlerStatus, string> = {
  [LatestClaimsHandlerStatusDict.newClaimStatement]: 'New claim statement',
  [LatestClaimsHandlerStatusDict.claimStatementClosed]: '',
  [LatestClaimsHandlerStatusDict.noStatus]: '',
  [LatestClaimsHandlerStatusDict.newInvoicesToConsider]: 'New invoice(s) to consider',
  [LatestClaimsHandlerStatusDict.ownersClaimCompleted]: "Owner's claim complete",
  [LatestClaimsHandlerStatusDict.surveyorsConsiderationCompleted]: "Surveyor's consideration complete",
  [LatestClaimsHandlerStatusDict.adjustersConsiderationCompleted]: "Adjuster's consideration complete",
};

export type LatestStatus = ClaimStatement['latestStatus'];

const ChipElement = styled.div`
  background-color: ${props => props.theme.green50};
  padding: 5px 15px;
`;

interface StatusProps<T extends string> {
  status: T;
  dictionary: Record<T, string>;
}
const Status = <T extends string>({ status, dictionary }: StatusProps<T>) => {
  if (!(status in dictionary)) return null;

  const label = dictionary[status];
  if (label.length === 0) return null;

  return <ChipElement>{label}</ChipElement>;
};

interface StatusChipProps {
  role: Nullable<ApplicationRole>;
  latestStatus: LatestStatus;
}
export const StatusChip: FC<StatusChipProps> = ({ role, latestStatus }) => {
  switch ((role?.name ?? '').toLowerCase()) {
    case 'owner':
    case 'broker':
      return <Status status={latestStatus.owner} dictionary={ownerStatusLabelDictionary} />;
    case 'surveyor':
      return <Status status={latestStatus.surveyor} dictionary={surveyorStatusLabelDictionary} />;
    case 'claimshandler':
      return <Status status={latestStatus.claimsHandler} dictionary={claimsHandlerStatusLabelDictionary} />;
    default:
      return null;
  }
};
