import { useCurrentRole } from '@/common/authentication/CurrentRoleContext';
import { createBrowserHistory } from 'history';
import { FeedbackAndSupport } from 'insify-remote-component-loader';
import { useAccount } from '@/common/authentication/AuthenticationProvider';
import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { HelpSection } from './HelpSection';

const ContentArea = styled.div`
  margin: 0 auto;
`;

const portal = {
  name: 'claimstatement',
  displayName: 'Claim Statement'
};

const FeedbackAndSupportPage = () => {
  const account = useAccount();
  const role = useCurrentRole() as React.ComponentProps<typeof FeedbackAndSupport>['role'];
  const navigate = useNavigate();
  const history = createBrowserHistory();
  history.push = (to, state) => navigate(to, state);
  history.replace = (to, state) => navigate(to, { ...state, replace: true });

  const helpSection = { content: <HelpSection /> };
  const toEmail = 'icssd@instech.atlassian.net';

  return (
    <ContentArea>
      <FeedbackAndSupport
        account={account}
        role={role}
        history={history}
        portal={portal}
        helpSection={helpSection}
        toEmail={toEmail} />
    </ContentArea>
  );
};

export { FeedbackAndSupportPage };
