import { ClaimsData } from '@/types';
import { Pane } from '@instech/components';
import { FunctionComponent as FC } from 'react';
import { InformationView } from './InformationView';
import { useDetailsClaimsData } from './utils';
import { VesselTitle } from './VesselTitle';

interface Props {
  data?: ClaimsData;
  prefix?: string;
  isExperimentalClaimStatement: boolean;
  maxColumns?: number;
}
/**
 * Variant of ClaimDetails that still uses ClaimsData type, instead of
 * the ClaimStatement that the ClaimDetails component now uses.
 */
export const ClaimsDataDetails: FC<Props> = ({
  data,
  prefix = '',
  isExperimentalClaimStatement = false,
  maxColumns = 4
}) => {
  const vesselInfoDetails = useDetailsClaimsData(data);

  return (
    <>
      <VesselTitle
        vesselName={data?.vesselName}
        prefix={prefix}
        isExperimental={isExperimentalClaimStatement}
      />
      <Pane color="green" padding="20px">
        <InformationView
          details={vesselInfoDetails.details}
          maxColumns={maxColumns}
          showCircle
        />
      </Pane>
    </>
  );
};
