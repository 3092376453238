import useSWR from 'swr';
import {
  AssignedUser, ClaimSearchData, UserInfo, UserRoles
} from '@/types';

type FetchRoleAndCompanyByToken = {
  claimStatementId: string;
  limitRoleAndCompany: true;
}

type FetchByRole = {
  role: UserRoles;
  limitRoleAndCompany: false;
  claimSearchData?: ClaimSearchData;
}

export type FetchUsersOptions = FetchByRole | FetchRoleAndCompanyByToken;

const buildRoleUrl = (fetchOptions: FetchUsersOptions) => {
  if (fetchOptions.limitRoleAndCompany) {
    const { claimStatementId } = fetchOptions;
    return `/claimstatements/${claimStatementId}/participants/eligible`;
  }

  const { role, claimSearchData } = fetchOptions;
  const params = new URLSearchParams();
  Object.entries(claimSearchData ?? {}).forEach(entry => {
    const [key, value] = entry;
    if (value) {
      params.append(key, value);
    }
  });

  const queryParameters = params.toString();
  return `/roles/${role}/users?${queryParameters}`;
};

export const useAllUsersByOptions = (fetchOptions: FetchUsersOptions) => {
  const url = buildRoleUrl(fetchOptions);
  const { data, error } = useSWR<UserInfo[]>(url);
  if (error) {
    throw error;
  }
  return data;
};

export const useCurrentUser = () => {
  const { data, error } = useSWR<UserInfo>('/userinfo/current');
  if (error) {
    throw error;
  }
  return data;
};

export const useClaimsHandlers = () => useSWR<AssignedUser[]>('/userinfo/claimshandlers');
