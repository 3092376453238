import React, { FunctionComponent as FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { DownArrow } from '@instech/icons';
import { UserInfoDto } from '@/types';
import { EmailInfo, PhoneInfo } from './core/Components';

interface Collapsible {
  isCollapsed: boolean;
}

const Container = styled.div`
  color: ${props => props.theme.marineBlue};
  font-weight: bold;
`;

const CollapseBar = styled.div<Collapsible>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4px;
  border-bottom: 2px solid ${props => props.theme.marineBlue60};
  cursor: pointer;
  & svg {
    flex-shrink: 0;
    width: 18px;
    stroke-width: 3px;
    // point up when details are shown, down when details are closed
    transform: ${props => !props.isCollapsed && 'rotate(180deg)'};
  }
`;

const CollapseInner = styled.div<Collapsible>`
  display: flex;
  flex-wrap: wrap;
  padding: 4px 0px;
  overflow-y: hidden;
  ${props => props.isCollapsed && css`
    height: 0px;
  `}
`;

const UserName = styled.div`
  font-size: 18px;
  line-height: 18px;
  margin-right: 24px;
`;

interface Props {
  user: UserInfoDto;
  startCollapsed?: boolean;
}
/**
 * Displays a single set of User Details (name, email, phone number), in a togglable
 * component that can be collapsed / expanded.
 */
export const CollapsedUserDetails: FC<Props> = ({ user, startCollapsed = false }) => {
  const [collapsed, setCollapsed] = useState(startCollapsed);
  const toggleCollapsed = () => setCollapsed(!collapsed);

  return (
    <Container>
      <CollapseBar isCollapsed={collapsed} onClick={toggleCollapsed}>
        <UserName>{user.fullName}</UserName>
        <DownArrow data-testid="user-details-toggle-icon" />
      </CollapseBar>
      <CollapseInner isCollapsed={collapsed} aria-hidden={collapsed}>
        <EmailInfo email={user.email} />
        <PhoneInfo phone={user.phoneNumber} />
      </CollapseInner>
    </Container>
  );
};
