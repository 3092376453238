import { CheckboxTableHeaderStyled } from '@/components/pages/claim/ClaimStatementPage/InvoiceTable/core/Components';
import { arrayPartition } from '@/utils/array';
import { theme } from '@/utils/style/theme';
import { SortedTableHeader, TableHeaderRow } from '@instech/components';
import { FunctionComponent } from 'react';
import { FilterableTableHeaderMax, StyledEmptyTableHeader } from '../core/TableHeaderComponents';
import { CollapsibleHeaderSegment } from './types';

interface SegmentProps {
  segment: CollapsibleHeaderSegment;
  setSortedHeader: (val?: any) => any;
  sortedHeader?: SortedTableHeader;
  slimBorder?: boolean;
}

const Segment: FunctionComponent<SegmentProps> = ({
  segment,
  setSortedHeader,
  sortedHeader,
  slimBorder
}) => {
  if (segment.shouldSkip) return null;
  if (segment.specialRole === 'space') {
    return (
      <StyledEmptyTableHeader
        key={segment.propName}
        borderColor={segment.borderColor}
        data-collapse-group={segment.group}
        data-collapse-border={segment.border}
        slimBorder={slimBorder}
        slimHeader />
    );
  }
  return (
    <FilterableTableHeaderMax
      key={segment.propName}
      header={{
        title: segment.title,
        propertyName: segment.propName,
        parameterName: segment.propName,
        // TODO: Need to tidy this up in the library, alignment is messy
        center: segment.align === 'flex-end' ? 'flex-end' : false,
      }}
      borderColor={segment.borderColor}
      data-collapse-group={segment.group}
      data-collapse-border={segment.border}
      sortedHeader={sortedHeader}
      setSortedHeader={setSortedHeader}
      slimBorder={slimBorder}
      slimHeader />
  );
};

interface Props {
  segments: CollapsibleHeaderSegment[];
  sortedHeader?: SortedTableHeader;
  setSortedHeader: (val?: any) => any;
  selectableRows?: boolean;
  headerCheckboxName?: string;
  subrowsHeaderText?: string;
  handleSelectAll?: () => void;
  allItems?: number;
  selectedItems?: number;
  stickyTop?: string;
  showCheckboxBorder?: boolean;
  slimBorder?: boolean;
}
export const CollapsibleTableHeaders: FunctionComponent<Props> = ({
  segments,
  sortedHeader,
  setSortedHeader,
  selectableRows,
  headerCheckboxName = 'collapsible-table-checkbox-all',
  subrowsHeaderText,
  handleSelectAll = () => null,
  allItems = 0,
  selectedItems = 0,
  stickyTop,
  showCheckboxBorder = true,
  slimBorder
}) => {
  const [headersBeforeSubrows, headersAfterSubrows] = arrayPartition(segments, elem => !!elem.beforeSubrows);
  return (
    <TableHeaderRow stickyTop={stickyTop}>
      {headersBeforeSubrows.map((segment: CollapsibleHeaderSegment) => (
        <Segment
          key={segment.propName}
          segment={segment}
          setSortedHeader={setSortedHeader}
          sortedHeader={sortedHeader}
          slimBorder={slimBorder} />
      ))}
      {selectableRows && (
        <CheckboxTableHeaderStyled
          key={headerCheckboxName}
          name={headerCheckboxName}
          selected={allItems === selectedItems && allItems > 0}
          indeterminate={allItems !== selectedItems && selectedItems > 0}
          onClick={handleSelectAll}
          borderColor={showCheckboxBorder ? theme.marineBlue60 : ''}
          slimBorder={slimBorder}
          slimHeader />
      )}
      {subrowsHeaderText && (
        <FilterableTableHeaderMax
          key="subrows-header"
          header={{
            title: subrowsHeaderText,
            propertyName: 'subrows.length',
            parameterName: 'subrows.length',
          }}
          borderColor={showCheckboxBorder ? theme.marineBlue60 : ''}
          sortedHeader={sortedHeader}
          setSortedHeader={setSortedHeader}
          slimBorder={slimBorder}
          slimHeader />
      )}
      {headersAfterSubrows.map((segment: CollapsibleHeaderSegment) => (
        <Segment
          key={segment.propName}
          segment={segment}
          setSortedHeader={setSortedHeader}
          sortedHeader={sortedHeader}
          slimBorder={slimBorder} />
      ))}
    </TableHeaderRow>
  );
};
