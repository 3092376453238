import { CollapsibleRow } from '@/common/components/Table/CollapsibleTable/types';
import { ContextMenuCell } from '@/common/components/Table/ContextMenuCell';
import { ExpandedRows } from '@/common/components/Table/utils';
import { Invoice, SurveyorsConsideration } from '@/types';
import { MenuOption } from '@instech/components';
import { getInvoiceNumber } from '../../../core/utils/getInvoiceNumber';

const getSurveyorsComment = (data?: SurveyorsConsideration | null) => {
  if (!data?.splits || data?.splits.length === 1) {
    return data?.surveyorsComment;
  }
  const noValidComments = data?.splits.map(split => split.surveyorsComment).every(comment => !comment);
  if (noValidComments) return '-';
  return 'Multiple';
};

export const surveyorTableRow = (
  invoice: Invoice,
  onRowClick: (id: string) => void,
  selectableRows: boolean,
  toggleExpanded: (id: string) => void,
  expanded: ExpandedRows,
  contextMenuOptions?: (invoiceId: string) => MenuOption[]
): CollapsibleRow => ({
  id: invoice.id,
  isFinished: false,
  toggleExpanded: () => toggleExpanded(invoice.id),
  onClick: () => onRowClick(invoice.id),
  expanded: expanded[invoice.id],
  segments: [
    {
      key: 'select',
      cellType: 'gap',
      shouldSkip: selectableRows,
      beforeSubrows: true
    },
    {
      key: 'invoiceNumber',
      cellType: 'text',
      value: getInvoiceNumber(invoice)
    },
    {
      key: 'supplier',
      cellType: 'text',
      value: invoice.record.supplier
    },
    {
      key: 'category',
      cellType: 'text',
      value: invoice.record.category
    },
    {
      key: 'ownersComment',
      cellType: 'comment',
      value: invoice.record.ownersComment
    },
    {
      key: 'currency',
      cellType: 'text',
      value: invoice.record.currency
    },
    {
      key: 'totalAmount',
      cellType: 'digitsTooltip',
      value: invoice.record.totalAmount,
      align: 'right'
    },
    {
      key: 'generalExpenses',
      cellType: 'digitsTooltip',
      value: invoice.surveyorsConsideration?.generalExpenses,
      variant: 'secondary',
      align: 'right'
    },
    {
      key: 'claimConsideration',
      cellType: 'digitsTooltip',
      value: invoice.surveyorsConsideration?.claimConsideration,
      variant: 'secondary',
      align: 'right'
    },
    {
      key: 'ownersWorkConsideration',
      cellType: 'digitsTooltip',
      value: invoice.surveyorsConsideration?.ownersWorkConsideration,
      variant: 'secondary',
      align: 'right'
    },
    {
      key: 'adjusterToConsider',
      cellType: 'digitsTooltip',
      value: invoice.surveyorsConsideration?.adjusterToConsider,
      variant: 'secondary',
      align: 'right'
    },
    {
      key: 'surveyorsComment',
      cellType: 'comment',
      value: getSurveyorsComment(invoice.surveyorsConsideration),
      variant: 'secondary'
    },
    {
      key: 'contextMenu',
      cellType: 'custom',
      value: contextMenuOptions
        ? <ContextMenuCell documentId={invoice.id} options={contextMenuOptions(invoice.id)} variant="secondary" />
        : null
    }
  ]
});
