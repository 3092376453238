import { BaseTableCell } from '@/common/components/Table/TanstackTable/TableCell';
import { TableCellCommentBase } from '@/common/components/Table/TanstackTable/TableCell/TableCellComment';
import { TableCellVariantType } from '@/common/components/Table/TanstackTable/types';
import { InvoiceState } from '@/types';
import { FunctionComponent as FC } from 'react';
import { SplitCellContent, SplitRowMessageWrapper } from './Components';
import { CHInvoiceTableSplit } from '../TableViews/TableViewNoGrouping/tableDataNoGrouping';

const atSurveyorsDesk = ['CostAllocation', 'CostReallocation'];

interface SplitRowSurveyorProps {
  split: CHInvoiceTableSplit;
  state: InvoiceState;
  exchangeRate?: number | null;
  headerGroup?: string;
  cellVariant?: TableCellVariantType;
  showFullComments?: boolean;
}
export const SplitSurveyorCells: FC<SplitRowSurveyorProps> = ({
  split,
  state,
  exchangeRate,
  headerGroup,
  cellVariant,
  showFullComments,
}) => {
  // Using presence of surveyor split ID to determine if it exists or not
  const createdByAdjuster = !split.surveyor.id && !split.adjuster.surveyorSplitId;
  const deletedBySurveyor = !split.surveyor.id && !!split.adjuster.surveyorSplitId;

  // Safely destructure possibly undefined object to prevent throwing errors
  const { isConsidered, ...surveyor } = { ...split.surveyor };
  const tooltipId = 'invoice-page-tooltip';

  if (createdByAdjuster) {
    return (
      <BaseTableCell
        id={`${split.id}-surveyorMessage`}
        columnBorder="left"
        cellVariant={cellVariant}
        headerGroup={headerGroup}
        span={5}>
        <SplitRowMessageWrapper>Created by Adjuster.</SplitRowMessageWrapper>
      </BaseTableCell>
    );
  }
  if (deletedBySurveyor && !atSurveyorsDesk.includes(state)) {
    return (
      <BaseTableCell
        id={`${split.id}-surveyorMessage`}
        columnBorder="left"
        cellVariant={cellVariant}
        headerGroup={headerGroup}
        span={5}>
        <SplitRowMessageWrapper>Deleted by Surveyor.</SplitRowMessageWrapper>
      </BaseTableCell>
    );
  }
  if (deletedBySurveyor && atSurveyorsDesk.includes(state)) {
    return (
      <BaseTableCell
        id={`${split.id}-surveyorMessage`}
        columnBorder="left"
        cellVariant={cellVariant}
        headerGroup={headerGroup}
        span={5}>
        <SplitRowMessageWrapper>At Surveyor&apos;s desk.</SplitRowMessageWrapper>
      </BaseTableCell>
    );
  }

  return (
    <>
      <BaseTableCell id={`${split.id}-generalExpenses`} columnBorder="left" cellVariant={cellVariant} headerGroup={headerGroup}>
        <SplitCellContent tooltipId={tooltipId} value={surveyor.generalExpenses} exchangeRate={exchangeRate} isConsidered={isConsidered} />
      </BaseTableCell>
      <BaseTableCell id={`${split.id}-claimConsideration`} cellVariant={cellVariant} headerGroup={headerGroup}>
        <SplitCellContent tooltipId={tooltipId} value={surveyor.claimConsideration} exchangeRate={exchangeRate} isConsidered={isConsidered} />
      </BaseTableCell>
      <BaseTableCell id={`${split.id}-ownersWorkConsideration`} cellVariant={cellVariant} headerGroup={headerGroup}>
        <SplitCellContent tooltipId={tooltipId} value={surveyor.ownersWorkConsideration} exchangeRate={exchangeRate} isConsidered={isConsidered} />
      </BaseTableCell>
      <BaseTableCell id={`${split.id}-adjustersConsideration`} cellVariant={cellVariant} headerGroup={headerGroup}>
        <SplitCellContent tooltipId={tooltipId} value={surveyor.adjustersConsideration} exchangeRate={exchangeRate} isConsidered={isConsidered} />
      </BaseTableCell>
      <TableCellCommentBase
        id={`${split.id}-surveyorsComment`}
        value={surveyor.surveyorsComment}
        cellVariant={cellVariant}
        headerGroup={headerGroup}
        tooltipId={tooltipId}
        showFullComments={showFullComments}
      />
    </>
  );
};
