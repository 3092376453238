import { Breadcrumbs } from '@/common/components/Breadcrumbs';
import { HeadingWrapper, StickyPageHeading } from '@/common/layout/PageLayout';
import { useInvoice } from '@/services/invoice/invoiceServices';
import { ClaimStatement } from '@/types';
import { FC } from 'react';
import { vesselBreadcrumbSteps } from '../../InvoicePage/InvoiceHeader/core/utils';
import { editInvoiceTitle } from './batchHeaderTextUtils';

interface BatchInvoiceHeaderProps {
  claimStatementId: string;
  invoiceBatchList: string[];
  invoiceBatchNumber: number;
  claimStatement: ClaimStatement;
}
export const BatchInvoiceHeader: FC<BatchInvoiceHeaderProps> = ({
  claimStatementId,
  invoiceBatchList,
  invoiceBatchNumber,
  claimStatement
}) => {
  const { data: invoice } = useInvoice(claimStatementId, invoiceBatchList[invoiceBatchNumber - 1]);

  const breadcrumbs = vesselBreadcrumbSteps(claimStatement.id, claimStatement.vesselName);
  const currentPage = editInvoiceTitle(invoice?.record.order, invoice?.record.invoiceNumber);

  return (
    <StickyPageHeading>
      <HeadingWrapper>
        <Breadcrumbs steps={breadcrumbs} current={currentPage} />
        <div id="batch-action-buttons" />
      </HeadingWrapper>
    </StickyPageHeading>
  );
};
