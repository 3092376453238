import React, { FunctionComponent } from 'react';
import { InfoWithLabel, InfoWithLabelAndTag } from '@/common/components/Text';
import { getConvertedCurrencyText } from '@/utils/number';
import styled from 'styled-components';
import { DividerLine } from '@/apps/ClaimStatement/components/InvoiceSplitTable';
import { useSharedTooltip } from '@/common/components/Tooltip/SharedTooltip';

const Container = styled.div`
  color: ${props => props.theme.marineBlue};
  padding: 0px 16px 20px;
`;

const GridLayout = styled.div`
  display: grid;
  column-gap: 24px;
  row-gap: 32px;
  grid-template-columns: repeat(4, 1fr);
`;

// Comments and notes should be 3 columns wide
const InfoLabelWide = styled(InfoWithLabel)`
  grid-column: span 3;
`;

interface FieldProps {
  claimConsideration?: string | number | null | undefined;
  ownersWorkConsideration?: string | number | null | undefined;
  generalExpenses?: string | number | null | undefined;
  adjustersConsideration?: string | number | null | undefined;
  exchangeRate?: number | null;
  category?: string;
  surveyorsComment?: string | null;
  internalNotes?: string | null;
  useInvoiceCurrency: boolean;
  occurrence: string | undefined;
  showInternalNotes: boolean;
  claimConsiderationTag?: string | null;
  generalExpensesTag?: string | null;
}
export const ReadonlyFields: FunctionComponent<FieldProps> = ({
  claimConsideration,
  ownersWorkConsideration,
  generalExpenses,
  adjustersConsideration,
  exchangeRate,
  category,
  surveyorsComment,
  internalNotes,
  useInvoiceCurrency,
  occurrence,
  showInternalNotes,
  claimConsiderationTag,
  generalExpensesTag
}) => {
  const { tooltipId } = useSharedTooltip();
  return (
    <Container>
      <DividerLine />
      <GridLayout>
        <InfoWithLabelAndTag
          label="Claim"
          text={getConvertedCurrencyText(claimConsideration, exchangeRate, useInvoiceCurrency)}
          tag={claimConsiderationTag}
          tooltipId={tooltipId} />
        <InfoWithLabel
          label="Owner"
          text={getConvertedCurrencyText(ownersWorkConsideration, exchangeRate, useInvoiceCurrency)} />
        <InfoWithLabelAndTag
          label="General Expenses"
          text={getConvertedCurrencyText(generalExpenses, exchangeRate, useInvoiceCurrency)}
          tag={generalExpensesTag}
          tooltipId={tooltipId} />
        <InfoWithLabel
          label="Adjuster to Consider"
          text={getConvertedCurrencyText(adjustersConsideration, exchangeRate, useInvoiceCurrency)} />
        <InfoWithLabel
          label="Occurrence"
          text={occurrence} />
        <InfoLabelWide
          label="Description of Consideration"
          text={surveyorsComment}
          multiline />
        <InfoWithLabel
          label="Category"
          text={category} />
        {showInternalNotes && (
        <InfoLabelWide
          label="Internal Notes - Only visible for Surveyors"
          text={internalNotes}
          multiline />
        )}
      </GridLayout>
    </Container>
  );
};
