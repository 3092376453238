import React from 'react';
import styled from 'styled-components';
import { ButtonGroup, SlimButton } from '@instech/components';
import { ModalType } from '@/common/modules/Modal/types';
import { useModalContext } from '@/common/modules/Modal';

const TextWrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 112px;
  text-align: center;
  color: ${props => props.theme.marineBlue};
`;

const BoldText = styled.div`
  font-weight: bold;
  margin-bottom: 20px;
`;

interface CancelUploadProps {
  cancelBatch: () => Promise<'error' | 'success'>;
  navigateToInvoice: () => void;
}

const ModalContent = ({ cancelBatch, navigateToInvoice }: CancelUploadProps) => {
  const { close } = useModalContext();

  const handleCancel = async () => {
    const result = await cancelBatch();
    if (result === 'success') {
      close();
      navigateToInvoice();
    }
  };

  return (
    <div>
      <TextWrapper>
        <BoldText>Do you want to cancel the upload?</BoldText>
        <div>Uploaded invoices will not be saved</div>
      </TextWrapper>
      <ButtonGroup alignRight>
        <SlimButton variant="secondary" onClick={close}>
          CONTINUE UPLOAD
        </SlimButton>
        <SlimButton onClick={handleCancel}>
          CANCEL UPLOAD
        </SlimButton>
      </ButtonGroup>
    </div>
  );
};

export const cancelUploadModal = (
  { cancelBatch, navigateToInvoice }: CancelUploadProps
): ModalType<CancelUploadProps> => ({
  component: ModalContent,
  options: {
    title: 'Cancel',
    size: 'small'
  },
  args: {
    cancelBatch,
    navigateToInvoice
  }
});
