import { useFeatureFlag } from '@/common/providers/featureFlagProvider';
import { ButtonGroup, SlimButton } from '@instech/components';
import {
  Download, SendFilled, Link as LinkIcon
} from '@instech/icons';
import { FunctionComponent as FC } from 'react';

interface Props {
  openShareModal?: () => any;
  openShareDownloadLinkModal?: () => any;
  openExportModal?: () => any;
  downloadLinkDisabled: boolean;
}
export const ShareButtons: FC<Props> = ({ openShareModal, openExportModal, openShareDownloadLinkModal, downloadLinkDisabled }) => {
  const showMagicLinksButton = useFeatureFlag('magicLinks');
  return (
    <ButtonGroup>
      {(openShareModal && showMagicLinksButton) && (
        <SlimButton variant="secondary" startIcon={<SendFilled />} onClick={openShareModal}>
          SHARE ACCESS
        </SlimButton>
      )}
      {openShareDownloadLinkModal && (
        <SlimButton variant="secondary" startIcon={<LinkIcon />} onClick={openShareDownloadLinkModal} disabled={downloadLinkDisabled}>
          SHARE DOWNLOAD LINK
        </SlimButton>
      )}
      {openExportModal && (
        <SlimButton startIcon={<Download />} onClick={openExportModal}>
          EXPORT TO EXCEL
        </SlimButton>
      )}
    </ButtonGroup>
  );
};
