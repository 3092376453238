import { invoiceDataValidationSchema } from '@/components/pages/invoice/core/EditInvoice/utils/validationSchema';
import {
  Guid,
  Invoice, SurveyorsConsiderationSplitForm, UpdateSurveyorsConsiderationForm, UpdateSurveyorsConsiderationRequest
} from '@/types';
import useSWR from 'swr';
import { putAsync } from '../../client';
import { apiRouteSurveyorsConsideration } from '../utils/invoiceApiRoute';
import { InvoiceRequestStatus } from '.';

// Submitting Surveyor's Consideration includes some hanging props that need to be removed
const stripSurveyorFormValues = (split: SurveyorsConsiderationSplitForm) => {
  const { form, ...restValues } = split;
  return restValues;
};

/**
 * Call to update invoice, specifically for parts and data that can be altered by a Surveyor
 * when they are making the Surveyor's Considerations.
 */
export const createUpdateInvoiceSurveyor = (claimStatementId: Guid, invoiceId: Guid, mutate: ReturnType<typeof useSWR<Invoice>>['mutate']) =>
  async (updateForm: UpdateSurveyorsConsiderationForm, invoice: Invoice): Promise<InvoiceRequestStatus> => {
    const castedUpdateInvoice: UpdateSurveyorsConsiderationForm = invoiceDataValidationSchema
      .cast(updateForm, { assert: false });

    // Before submit, have to map through and strip away unneeded properties
    // and turn any LabelValuePair sets into their respective value String.
    // First split item is also spread into the request for redundancy.
    const { splits, deviationPresent } = castedUpdateInvoice;
    const requestValues: UpdateSurveyorsConsiderationRequest = {
      splits: splits.map(split => ({
        ...stripSurveyorFormValues(split),
        category: split.category?.value,
        occurrenceId: split.occurrence?.value,
        id: split.id,
        generalExpensesTag: split.generalExpensesTag?.value,
        claimConsiderationTag: split.claimConsiderationTag?.value,
      })),
      deviationPresent,
      etag: invoice.etag,
      category: castedUpdateInvoice.category?.value,
      location: castedUpdateInvoice.location?.value,
      personallyIdentifiableInformation: updateForm.personallyIdentifiableInformation?.value,
      personallyIdentifiableInformationDescription: updateForm.personallyIdentifiableInformationDescription
    };

    const response = await putAsync(apiRouteSurveyorsConsideration(claimStatementId!, invoiceId!), requestValues);

    if (response.status === 200) {
      await mutate();
      return 'success';
    }
    if (response.status === 409 || response.status === 403) {
      return 'conflict';
    }
    return 'error';
  };
