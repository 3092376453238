import { CollapsibleButtonSegment, CollapsibleHeaderSegment } from '@/common/components/Table/CollapsibleTable/types';
import { collapseGroups } from '@/common/components/Table/utils';
import { theme } from '@/utils/style/theme';
import { CSTableProps } from '../useSchemaByTableGrouping';

const borderColor = {
  status: theme.marineBlue60,
  particulars: theme.status.blue,
  surveyor: theme.status.purple,
  adjuster: theme.marineBlue60
};

export const noGroupingCollapse = (props: CSTableProps): CollapsibleButtonSegment[] => {
  const hasGenAvg = props.claimStatementSettings?.hasGeneralAverage;
  const showComment = props.showReviewComment;
  return ([
    {
      key: 'invoice-details-gap',
      span: (3 + (props.showReview ? 1 : 0))
    },
    {
      key: 'particulars',
      title: 'Particulars',
      span: 5
    },
    {
      key: 'owner',
      title: 'Owner',
      span: 3
    },
    {
      key: 'surveyor',
      title: 'Surveyor',
      span: 5
    },
    {
      key: 'adjuster',
      title: 'Adjuster',
      span: 4 + (hasGenAvg ? 1 : 0) + (showComment ? 1 : 0)
    }
  ]);
};

export const noGroupingHeader = (props: CSTableProps): CollapsibleHeaderSegment[] => ([
  {
    title: 'Invoice No',
    propName: 'invoiceNumber',
    borderColor: borderColor.status,
  },
  {
    title: 'Review',
    propName: 'isSharedForReview',
    borderColor: borderColor.status,
    shouldSkip: !props.showReview
  },
  {
    title: 'Status',
    propName: 'state',
    borderColor: borderColor.status,
  },
  {
    title: 'Supplier',
    propName: 'supplier',
    borderColor: borderColor.particulars,
    group: collapseGroups.particulars,
    border: true,
  },
  {
    title: 'Category',
    propName: 'category',
    borderColor: borderColor.particulars,
    group: collapseGroups.particulars,
  },
  {
    title: 'Currency',
    propName: 'currency',
    borderColor: borderColor.particulars,
    group: collapseGroups.particulars,
  },
  {
    title: 'Total - Invoice CCY',
    propName: 'totalAmount',
    borderColor: borderColor.particulars,
    group: collapseGroups.particulars,
  },
  {
    title: `Total - ${props.currency}`,
    propName: 'paidAmount',
    borderColor: borderColor.particulars,
    group: collapseGroups.particulars,
    align: 'flex-end'
  },
  {
    title: 'Claim',
    propName: 'claimInPolicyCurrency',
    borderColor: borderColor.particulars,
    group: collapseGroups.owner,
    border: true,
    align: 'flex-end'
  },
  {
    title: 'Owner',
    propName: 'ownersWorkInPolicyCurrency',
    borderColor: borderColor.particulars,
    group: collapseGroups.owner,
    align: 'flex-end'
  },
  {
    title: 'Cost Description',
    propName: 'ownersComment',
    borderColor: borderColor.particulars,
    group: collapseGroups.owner,
  },
  {
    title: 'Gen. Expenses',
    propName: 'surveyorGeneralExpenses',
    borderColor: borderColor.surveyor,
    group: collapseGroups.surveyor,
    align: 'flex-end',
    border: true
  },
  {
    title: 'Claim',
    propName: 'surveyorClaimConsideration',
    borderColor: borderColor.surveyor,
    group: collapseGroups.surveyor,
    align: 'flex-end',
  },
  {
    title: 'Owner',
    propName: 'surveyorOwnersWorkConsideration',
    borderColor: borderColor.surveyor,
    group: collapseGroups.surveyor,
    align: 'flex-end',
  },
  {
    title: 'Adjuster to Consider',
    propName: 'surveyorAdjusterToConsider',
    borderColor: borderColor.surveyor,
    group: collapseGroups.surveyor,
    align: 'flex-end',
  },
  {
    title: 'Description of Consideration',
    propName: 'surveyorsComment',
    borderColor: borderColor.surveyor,
    group: collapseGroups.surveyor,
  },
  {
    title: 'Common Expenses',
    propName: 'adjusterCommonExpenses',
    borderColor: borderColor.adjuster,
    group: collapseGroups.adjuster,
    border: true,
    align: 'flex-end',
  },
  {
    title: 'General Average',
    propName: 'adjusterGeneralAverage',
    borderColor: borderColor.adjuster,
    group: collapseGroups.adjuster,
    align: 'flex-end',
    shouldSkip: !props.claimStatementSettings?.hasGeneralAverage
  },
  {
    title: 'Particular Average',
    propName: 'adjusterParticularAverage',
    borderColor: borderColor.adjuster,
    group: collapseGroups.adjuster,
    align: 'flex-end',
  },
  {
    title: 'Remainder',
    propName: 'adjusterOwnersWorkConsideration',
    borderColor: borderColor.adjuster,
    group: collapseGroups.adjuster,
    align: 'flex-end',
  },
  {
    title: 'Adjuster\'s Note',
    propName: 'adjusterAdjustersComment',
    borderColor: borderColor.adjuster,
    group: collapseGroups.adjuster,
  },
  {
    specialRole: 'space',
    propName: 'ownerReviewComment',
    borderColor: borderColor.adjuster,
    group: collapseGroups.adjuster,
    shouldSkip: !props.showReviewComment
  }
]);
