import {
  AdjustersConsiderationSplit, AdjustersConsiderationSplitForm,
  Occurrence, SurveyorsConsiderationSplit
} from '@/types';
import {
  calculateWithExchangeRate,
  convertCurrency,
  formatCurrency,
  getExchangeCalculationValues,
} from '@/utils/invoice/convertCurrency';
import {
  createSumOrDash,
  formatWithDigitsOrDash,
  formatWithTruncateOrEmpty,
  numberOrDash,
  truncateWithDigitsOrDash
} from '@/utils/number';
import { ellipsisOrDash, textOrDash } from '@/utils/text';
import { NOT_APPLICABLE } from '@/utils/constants';

export const createSplitSum = (
  numbers: (string | number | null | undefined)[],
  reversed: boolean = false
): number | string => {
  const allDashes = numbers.every(value => value === '-');
  const reduction = numbers.reduce((current, last) => (Number(last) || 0) + (Number(current) || 0));
  if (allDashes) {
    return '-';
  }
  return reversed ? Number(reduction) * -1 : Number(reduction);
};

export const calculateSummaryValues = (consideration: AdjustersConsiderationSplitForm) => {
  const category = textOrDash(consideration.category?.value);
  const commonExpenses = numberOrDash(consideration.commonExpenses || consideration.initialCommonExpenses);
  const particularAvg = numberOrDash(consideration.particularAverage || consideration.initialParticularAverage);
  const ownersWork = numberOrDash(consideration.ownersWorkConsideration || consideration.initialOwnersWork);

  // Reduce sum to single number, or return dash if all values are dashes
  const getSplitSum = createSplitSum([commonExpenses, particularAvg, ownersWork]);
  const splitSum = (Number(getSplitSum)) ? formatCurrency(Number(getSplitSum)) : getSplitSum;
  const occurrence = ellipsisOrDash(consideration.occurrence.label, 10);

  return { occurrence, category, commonExpenses, particularAvg, ownersWork, splitSum };
};

export const calculateSurveyorDeviation = (
  adjusterSplit: AdjustersConsiderationSplit | AdjustersConsiderationSplitForm,
  surveyorSplit: SurveyorsConsiderationSplit | null | undefined,
  isConsidered: boolean,
  exchangeRate: number | null | undefined = 1,
  useInvoiceCurrency: boolean | undefined = true,
) => {
  if (!isConsidered) return NOT_APPLICABLE;

  const { exchangeRate: exchRate, allowZero } = getExchangeCalculationValues(exchangeRate, useInvoiceCurrency);

  const adjusterSumRaw = createSplitSum([
    numberOrDash(adjusterSplit.commonExpenses),
    numberOrDash(adjusterSplit.generalAverage),
    numberOrDash(adjusterSplit.particularAverage),
    numberOrDash(adjusterSplit.ownersWorkConsideration)
  ]);

  const surveyorsSumRaw = createSplitSum([
    numberOrDash(surveyorSplit?.generalExpenses),
    numberOrDash(surveyorSplit?.claimConsideration),
    numberOrDash(surveyorSplit?.ownersWorkConsideration),
    numberOrDash(surveyorSplit?.adjustersConsideration)
  ]);

  const adjustersConvertedSum = convertCurrency(adjusterSumRaw, exchRate, allowZero);
  const surveyorsConvertedSum = convertCurrency(surveyorsSumRaw, exchRate, allowZero);

  const surveyorsSumToUse = !useInvoiceCurrency ? surveyorsConvertedSum : surveyorsSumRaw;

  const surveyorsSum = surveyorsSumToUse === '-' ? 0 : Number(surveyorsSumToUse);
  const adjustersSum = adjustersConvertedSum === '-' ? 0 : Number(adjustersConvertedSum);

  if (Number.isNaN(adjustersSum - surveyorsSum)) {
    return NOT_APPLICABLE;
  }
  return adjustersSum - surveyorsSum;
};

export const calculateAdjusterSummaryValues = (
  adjusterSplit: AdjustersConsiderationSplitForm,
  surveyorSplit: SurveyorsConsiderationSplit | undefined,
  exchangeRate: number | null | undefined,
  useInvoiceCurrency: boolean | undefined,
  isConsidered: boolean,
  isInitialized: boolean | undefined,
) => {
  // Simplify some values before running through calculations
  const exchangeMeta = getExchangeCalculationValues(exchangeRate, useInvoiceCurrency);

  const commonExpensesVal = isInitialized
    ? adjusterSplit.commonExpenses
    : adjusterSplit.commonExpenses || adjusterSplit.initialCommonExpenses;

  const particularAvgVal = isInitialized
    ? adjusterSplit.particularAverage
    : adjusterSplit.particularAverage || adjusterSplit.initialParticularAverage;

  const ownersWorkVal = isInitialized
    ? adjusterSplit.ownersWorkConsideration
    : adjusterSplit.ownersWorkConsideration || adjusterSplit.initialOwnersWork;

  const claimConsideration = numberOrDash(convertCurrency(surveyorSplit?.claimConsideration, exchangeMeta.exchangeRate, exchangeMeta.allowZero));
  const ownersWorkConsideration = numberOrDash(convertCurrency(surveyorSplit?.ownersWorkConsideration, exchangeMeta.exchangeRate, exchangeMeta.allowZero));
  const adjustersConsideration = numberOrDash(convertCurrency(surveyorSplit?.adjustersConsideration, exchangeMeta.exchangeRate, exchangeMeta.allowZero));
  const generalExpenses = numberOrDash(convertCurrency(surveyorSplit?.generalExpenses, exchangeMeta.exchangeRate, exchangeMeta.allowZero));

  const category = textOrDash(adjusterSplit.category?.value);
  const occurrence = ellipsisOrDash(adjusterSplit.occurrence.label, 10);
  const commonExpenses = numberOrDash(convertCurrency(commonExpensesVal, exchangeMeta.exchangeRate, exchangeMeta.allowZero));
  const particularAvg = numberOrDash(convertCurrency(particularAvgVal, exchangeMeta.exchangeRate, exchangeMeta.allowZero));
  const ownersWork = numberOrDash(convertCurrency(ownersWorkVal, exchangeMeta.exchangeRate, exchangeMeta.allowZero));
  const generalAverage = numberOrDash(convertCurrency(adjusterSplit.generalAverage, exchangeMeta.exchangeRate, exchangeMeta.allowZero));

  // Reduce sum to single number, or return dash if all values are dashes
  const getSplitSum = createSplitSum([commonExpenses, particularAvg, ownersWork, generalAverage]);
  const removedGetSplitSum = createSplitSum([claimConsideration, ownersWorkConsideration, adjustersConsideration, generalExpenses], true);

  const splitSum = (Number(getSplitSum)) ? formatCurrency(Number(getSplitSum)) : getSplitSum;
  const removedSplitSum = (Number(removedGetSplitSum)) ? formatCurrency(Number(removedGetSplitSum)) : removedGetSplitSum;

  const surveyorDeviation = calculateSurveyorDeviation(adjusterSplit, surveyorSplit, isConsidered, exchangeRate, useInvoiceCurrency);
  const surveyorDeviationDisplay = surveyorDeviation === NOT_APPLICABLE
    ? NOT_APPLICABLE : formatWithDigitsOrDash(surveyorDeviation, true);

  return {
    category,
    occurrence,
    commonExpenses: formatWithTruncateOrEmpty(commonExpenses),
    generalAverage: formatWithTruncateOrEmpty(generalAverage),
    particularAvg: formatWithTruncateOrEmpty(particularAvg),
    ownersWork: formatWithTruncateOrEmpty(ownersWork),
    splitSum: formatWithTruncateOrEmpty(splitSum),
    removedSplitSum: formatWithTruncateOrEmpty(removedSplitSum),
    surveyorDeviation: surveyorDeviationDisplay
  };
};

function calculateSingleAdjusterSplitValues(
  adjusterSplit: AdjustersConsiderationSplit,
  surveyorSplit: SurveyorsConsiderationSplit | undefined,
  useInvoiceCurrency: boolean | undefined,
  exchangeRate: number | null | undefined,
  isConsidered: boolean,
  occurrence: string,
  category: string
) {
  const exchangeMeta = getExchangeCalculationValues(exchangeRate, useInvoiceCurrency);
  const commonExpensesVal = numberOrDash(convertCurrency(adjusterSplit.commonExpenses, exchangeMeta.exchangeRate, exchangeMeta.allowZero));
  const generalAverageVal = numberOrDash(convertCurrency(adjusterSplit.generalAverage, exchangeMeta.exchangeRate, exchangeMeta.allowZero));
  const ownersWorkVal = numberOrDash(convertCurrency(adjusterSplit.ownersWorkConsideration, exchangeMeta.exchangeRate, exchangeMeta.allowZero));
  const particularAverageVal = numberOrDash(convertCurrency(adjusterSplit.particularAverage, exchangeMeta.exchangeRate, exchangeMeta.allowZero));

  // Reduce sum to single number, or return dash if all values are dashes
  const getSplitSum = createSplitSum([commonExpensesVal, generalAverageVal, ownersWorkVal, particularAverageVal]);
  const removedGetSplitSum = createSplitSum([commonExpensesVal, generalAverageVal, ownersWorkVal, particularAverageVal], true);

  const surveyorDeviation = calculateSurveyorDeviation(adjusterSplit, surveyorSplit, isConsidered, exchangeRate, useInvoiceCurrency);
  const surveyorDeviationDisplay = surveyorDeviation === NOT_APPLICABLE
    ? NOT_APPLICABLE : formatWithDigitsOrDash(surveyorDeviation, true);

  return {
    occurrence,
    category,
    ownersWork: formatWithTruncateOrEmpty(ownersWorkVal),
    commonExpenses: formatWithTruncateOrEmpty(commonExpensesVal),
    generalAverage: formatWithTruncateOrEmpty(generalAverageVal),
    particularAverage: formatWithTruncateOrEmpty(particularAverageVal),
    splitSum: formatWithTruncateOrEmpty(getSplitSum),
    removedSplitSum: formatWithTruncateOrEmpty(removedGetSplitSum),
    surveyorDeviation: surveyorDeviationDisplay
  };
}

export const calculateReadonlyAdjusterSummaryValues = (
  adjusterSplit: AdjustersConsiderationSplit,
  surveyorSplit: SurveyorsConsiderationSplit | undefined,
  isConsidered: boolean,
  exchangeRate?: number | null,
  useInvoiceCurrency?: boolean,
  occurrenceList?: Occurrence[],
) => {
  const category = textOrDash(adjusterSplit.category);
  const occurrenceName = occurrenceList?.find(o => o.id === adjusterSplit.occurrenceId)?.name;
  const occurrence = ellipsisOrDash(occurrenceName, 10);

  return calculateSingleAdjusterSplitValues(
    adjusterSplit,
    surveyorSplit,
    useInvoiceCurrency,
    exchangeRate,
    isConsidered,
    occurrence,
    category,
  );
};

export const calculateAdjusterTotalLine = (
  totalLine: {
    commonExpenses?: number;
    generalAverage?: number;
    particularAverage?: number;
    ownersWork?: number;
  },
  exchangeRate?: number | null | undefined,
  useInvoiceCurrency?: boolean,
) => {
  const exchangeMeta = getExchangeCalculationValues(exchangeRate, useInvoiceCurrency);
  const commonExpensesValue = calculateWithExchangeRate(totalLine.commonExpenses, exchangeMeta.exchangeRate);
  const generalAverageValue = calculateWithExchangeRate(totalLine.generalAverage, exchangeMeta.exchangeRate);
  const particularAvgValue = calculateWithExchangeRate(totalLine.particularAverage, exchangeMeta.exchangeRate);
  const ownersWorkValue = calculateWithExchangeRate(totalLine.ownersWork, exchangeMeta.exchangeRate);

  // Reduce sum to single number, or return dash if all values are dashes
  const splitSum = createSumOrDash([commonExpensesValue, particularAvgValue, ownersWorkValue, generalAverageValue]);

  const formattedCommonExpenses = truncateWithDigitsOrDash(commonExpensesValue);
  const formattedGeneralAverage = truncateWithDigitsOrDash(generalAverageValue);
  const formattedParticularAvg = truncateWithDigitsOrDash(particularAvgValue);
  const formattedOwnersWork = truncateWithDigitsOrDash(ownersWorkValue);
  const formattedSplitSum = truncateWithDigitsOrDash(splitSum);

  return {
    commonExpenses: formattedCommonExpenses,
    generalAverage: formattedGeneralAverage,
    particularAvg: formattedParticularAvg,
    ownersWork: formattedOwnersWork,
    splitSum: formattedSplitSum
  };
};
