import { FunctionComponent as FC } from 'react';
import { IntrinsicSpanProps, InvoiceState } from '@/types';
import { InvoiceStateBadge } from '@/apps/ClaimStatement/components/InvoiceState';
import { TableCellMaxContent } from './TableCellMaxContent';

interface Props extends IntrinsicSpanProps {
  state: InvoiceState;
}
export const TableCellState: FC<Props> = ({ state, ...props }) => (
  <TableCellMaxContent {...props}>
    <InvoiceStateBadge state={state} />
  </TableCellMaxContent>
);
