import { ChapterSegmentGroup } from '@/apps/Adjustment/components/ChapterSidebar';

export const chapterSidebarList: ChapterSegmentGroup[] = [
  {
    title: 'Adjustment',
    startOpen: true,
    segments: [
      { name: 'Claim Statement Information', path: 'overview/claim-statement-information' },
      { name: 'Insurance Conditions', path: 'overview/insurance-conditions' },
      { name: 'Summary of Facts & Dates', path: 'overview/summary-facts' },
      { name: "Adjuster's Note", path: 'overview/adjusters-note' },
      { name: 'Adjusted Claim', path: 'overview/adjusted-claim' }
    ]
  }
];
