import { SlimButton, SlimButtonProps } from '@instech/components';
import { Approved, SendFilled } from '@instech/icons';
import styled from 'styled-components';
import { SaveButtonStatus } from '../../utils/SaveStatus/types';

// Override button transition to include opacity transition
// Could probably be moved into the Frontend Components library, since
// opacity transition smoothes out disable/enable flagging
const SlimButtonTransition = styled(SlimButton)`
  transition:
    color 0.2s ease,
    background-color 0.2s ease,
    border-color 0.2s ease,
    width 0.3s ease,
    opacity 0.2s ease;
`;

interface ButtonProps {
  onClick?: (event?: any) => any;
}

export const SubmitToCHButton = ({ onClick }: ButtonProps) => (
  <SlimButton variant="secondary" onClick={onClick}>SUBMIT TO CLAIMS HANDLER</SlimButton>
);

export const ShareSurveyorButton = ({ onClick }: ButtonProps) => (
  <SlimButton
    variant="secondary"
    padding="0 4px"
    startIcon={<SendFilled />}
    onClick={onClick}>
    SHARE WITH SURVEYOR
  </SlimButton>
);

interface MarkAsFinishedProps extends ButtonProps {
  submitting: boolean;
}
export const MarkAsFinishedButton = ({ onClick, submitting }: MarkAsFinishedProps) => (
  <SlimButton
    variant="secondary"
    width="170px"
    loading={submitting}
    disabled={submitting}
    onClick={onClick}
    startIcon={<Approved width="24px" />}>
    MARK AS FINISHED
  </SlimButton>
);

export const GrayCloseButton = ({ onClick }: ButtonProps) => (
  <SlimButton data-testid="gray-close-button" variant="secondary" onClick={onClick}>
    CLOSE
  </SlimButton>
);

export const GreenCloseButton = ({ onClick }: ButtonProps) => (
  <SlimButton data-testid="green-close-button" padding="0 4px" variant="primary" onClick={onClick}>
    CLOSE
  </SlimButton>
);

interface GreenSaveButtonProps extends ButtonProps {
  status: SaveButtonStatus;
}
export const GreenSaveButton = ({ onClick, status }: GreenSaveButtonProps) => {
  const disabled = status !== 'unsaved';
  const loading = status === 'saving';
  const startIcon = status === 'saved' ? <Approved /> : undefined;
  const text = { unsaved: 'SAVE', saved: 'SAVED', saving: '' }[status];

  return (
    <SlimButtonTransition
      startIcon={startIcon}
      variant="primary"
      height="40px"
      width="104px"
      disabled={disabled}
      loading={loading}
      onClick={onClick}>
      {text}
    </SlimButtonTransition>
  );
};

export const EditInvoiceButton = (props: SlimButtonProps) => (
  <SlimButton padding="0 4px" width="129px" variant="secondary" {...props}>
    EDIT INVOICE
  </SlimButton>
);
