import { Header, flexRender } from '@tanstack/react-table';
import { FunctionComponent } from 'react';
import {
  HeaderContainer,
  HeaderContent,
  TitleContainer
} from './Components';
import { FilterIcons, } from './FilterIcons';
import { getMergedMeta } from '../../utils';

interface HeaderProps {
  header: Header<any, unknown>;
  slimHeader?: boolean;
  borderWidth?: string;
  className?: string;
}
/**
 */
export const TableHeaderCell: FunctionComponent<HeaderProps> = ({
  header,
  slimHeader = false,
  borderWidth = '1px',
  className
}) => {
  const { meta: columnMeta, header: columnHeader } = header.column.columnDef;
  const mergedMeta = getMergedMeta(header.column);
  // Attach if column header has parent, otherwise React will ignore
  const headerGroup = header.column.parent?.id ?? undefined;

  // Alternate rendering that only shows container, but no content
  if (columnMeta?.emptyHeader) {
    return (
      <HeaderContainer
        borderColor={mergedMeta?.headerBorderColor}
        borderWidth={borderWidth}
        colSpan={header.colSpan}
        data-header-group={headerGroup}
        className={className}>
        <HeaderContent slimHeader={slimHeader} noPointer />
      </HeaderContainer>
    );
  }

  // Renders only the header container, allowing a custom implementation within
  if (columnMeta?.customHeader) {
    return (
      <HeaderContainer
        borderColor={mergedMeta?.headerBorderColor}
        borderWidth={borderWidth}
        colSpan={header.colSpan}
        data-header-group={headerGroup}
        className={className}>
        {flexRender(columnHeader, header.getContext())}
      </HeaderContainer>
    );
  }

  return (
    <HeaderContainer
      borderColor={mergedMeta?.headerBorderColor}
      borderWidth={borderWidth}
      colSpan={header.colSpan}
      data-header-group={headerGroup}
      className={className}>
      <HeaderContent
        slimHeader={slimHeader}
        align={columnMeta?.align}
        onClick={() => header.column.toggleSorting()}>
        <TitleContainer>
          {flexRender(columnHeader, header.getContext())}
        </TitleContainer>
        <FilterIcons sortDirection={header.column.getIsSorted()} />
      </HeaderContent>
    </HeaderContainer>
  );
};
