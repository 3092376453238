import {
  CollapsibleButtonSegment, CollapsibleHeaderSegment, CollapsibleSubheaderSegment
} from '@/common/components/Table/CollapsibleTable/types';
import { collapseGroups } from '@/common/components/Table/utils';
import { theme } from '@/utils/style/theme';
import { CSTableProps } from '../useSchemaByTableGrouping';

const borderColor = {
  status: theme.marineBlue60,
  particulars: theme.status.blue,
  surveyor: theme.status.purple,
  adjuster: theme.marineBlue60
};

export const groupByCategoryCollapse = (props: CSTableProps): CollapsibleButtonSegment[] => {
  const hasGenAvg = props.claimStatementSettings?.hasGeneralAverage;
  const showComment = (props.showReviewComment && !props.usePrintSchema);
  return ([
    {
      key: 'invoice-details-gap',
      span: 3
    },
    {
      key: 'owner',
      title: 'Owner',
      span: 2
    },
    {
      key: 'surveyor',
      title: 'Surveyor',
      span: 4
    },
    {
      key: 'adjuster',
      title: 'Adjuster',
      span: 3 + (hasGenAvg ? 1 : 0) + (showComment ? 1 : 0)
    }
  ]);
};

export const groupByCategorySubheader = (props: CSTableProps): CollapsibleSubheaderSegment[] => {
  const hasGenAvg = props.claimStatementSettings?.hasGeneralAverage;
  const showComment = (props.showReviewComment && !props.usePrintSchema);
  return ([
    {
      key: 'invoice-details-gap',
      span: 3
    },
    {
      key: 'owners-claim',
      title: `Owner's Claim - ${props.currency}`,
      group: collapseGroups.owner,
      border: true,
      span: 2
    },
    {
      key: 'surveyors-allocation',
      title: `Surveyor's Allocation of Costs - ${props.currency}`,
      group: collapseGroups.surveyor,
      border: true,
      span: 4
    },
    {
      key: 'adjusters-allocation',
      title: `Adjuster's Allocation of Costs - ${props.currency}`,
      group: collapseGroups.adjuster,
      border: true,
      span: 3 + (hasGenAvg ? 1 : 0) + (showComment ? 1 : 0)
    }
  ]);
};

export const groupByCategoryHeader = (props: CSTableProps): CollapsibleHeaderSegment[] => ([
  {
    title: 'Category',
    propName: 'category',
    borderColor: borderColor.status,
  },
  {
    title: `Total - ${props.currency}`,
    propName: 'paidAmount',
    borderColor: borderColor.status,
    align: 'flex-end'
  },
  {
    title: 'Claim',
    propName: 'claimInPolicyCurrency',
    borderColor: borderColor.particulars,
    group: collapseGroups.owner,
    border: true,
    align: 'flex-end'
  },
  {
    title: 'Owner',
    propName: 'ownersWorkInPolicyCurrency',
    borderColor: borderColor.particulars,
    group: collapseGroups.owner,
    align: 'flex-end'
  },
  {
    title: 'Gen. Expenses',
    propName: 'surveyorGeneralExpenses',
    borderColor: borderColor.surveyor,
    group: collapseGroups.surveyor,
    align: 'flex-end',
    border: true
  },
  {
    title: 'Claim',
    propName: 'surveyorClaimConsideration',
    borderColor: borderColor.surveyor,
    group: collapseGroups.surveyor,
    align: 'flex-end',
  },
  {
    title: 'Owner',
    propName: 'surveyorOwnersWorkConsideration',
    borderColor: borderColor.surveyor,
    group: collapseGroups.surveyor,
    align: 'flex-end',
  },
  {
    title: 'Adjuster to Consider',
    propName: 'surveyorAdjusterToConsider',
    borderColor: borderColor.surveyor,
    group: collapseGroups.surveyor,
    align: 'flex-end',
  },
  {
    title: 'Common Expenses',
    propName: 'adjusterCommonExpenses',
    borderColor: borderColor.adjuster,
    group: collapseGroups.adjuster,
    border: true,
    align: 'flex-end',
  },
  {
    title: 'General Average',
    propName: 'adjusterGeneralAverage',
    borderColor: borderColor.adjuster,
    group: collapseGroups.adjuster,
    align: 'flex-end',
    shouldSkip: !props.claimStatementSettings?.hasGeneralAverage
  },
  {
    title: 'Particular Average',
    propName: 'adjusterParticularAverage',
    borderColor: borderColor.adjuster,
    group: collapseGroups.adjuster,
    align: 'flex-end',
  },
  {
    title: 'Remainder',
    propName: 'adjusterOwnersWorkConsideration',
    borderColor: borderColor.adjuster,
    group: collapseGroups.adjuster,
    align: 'flex-end',
  },
  {
    specialRole: 'space',
    propName: 'ownerReviewComment',
    borderColor: borderColor.adjuster,
    group: collapseGroups.adjuster,
    shouldSkip: (!props.showReviewComment || props.usePrintSchema)
  }
]);
