import React from 'react';
import styled, { css } from 'styled-components';

const StyledMenuItem = styled.button<{ onClick?: (e: Event) => void; }>`
  display: flex;
  border: none;
  background: none;
  padding: 11px 21px;
  border-radius: 4px;
  color: ${props => props.theme.marineBlue};

  ${props => props.disabled && css`
    color: ${props.theme.marineBlue50};
    cursor: not-allowed;
  `};

  ${props => !props.disabled && css`
    cursor: pointer;
    &:hover {
      background: ${props.theme.flatWhite};
    }
  `}
`;

const Text = styled.span`
  font-family: inherit;
  font-size: 16px;
  text-align: left;
  line-height: 26px;
`;

interface Props {
  label: string;
  disabled?: boolean;
  onClick?: () => void;
}
export const MenuItem = ({ label, disabled, onClick }: Props) => (
  <StyledMenuItem
    disabled={disabled}
    onClick={onClick ?
      evt => {
        onClick();
        evt.stopPropagation();
      } : undefined}
  >
    <Text>{label}</Text>
  </StyledMenuItem>
);
