import { useClaimStatementCategoriesAsOptions } from '@/services/categoriesServices';
import {
  InvoiceRequestStatus,
  useInvoice
} from '@/services/invoice/invoiceServices';
import { useClaimStatementLocationsAsOptions } from '@/services/locationsServices';
import { useClaimStatementSuppliersAsOptions } from '@/services/suppliersService';
import {
  ClaimStatement, Invoice, UpdateInvoiceForm
} from '@/types';
import { PageLoader } from '@instech/components';
import {
  BatchUploadData,
  InvoiceFormOwner
} from './InvoiceForm/InvoiceFormOwner';

interface EditInvoiceProps {
  claimStatement: ClaimStatement;
  invoice: Invoice;
  handleInvoiceConflict?: () => void;
  batchUploadData?: BatchUploadData;
}
export const EditInvoiceOwner = ({ claimStatement, invoice, handleInvoiceConflict = () => {}, batchUploadData }: EditInvoiceProps) => {
  const { updateInvoice } = useInvoice(claimStatement.id, invoice.id);
  const { locationOptions, mutate: refreshLocations } = useClaimStatementLocationsAsOptions(claimStatement.id);
  const { supplierOptions, mutate: refreshSuppliers } = useClaimStatementSuppliersAsOptions(claimStatement.id);
  const categoryOptions = useClaimStatementCategoriesAsOptions(claimStatement.id);

  const handleUpdate = async (updateInvoiceForm: UpdateInvoiceForm): Promise<Invoice | InvoiceRequestStatus> => {
    const result = await updateInvoice(updateInvoiceForm, invoice);
    if (result === 'conflict') handleInvoiceConflict();
    await refreshLocations();
    await refreshSuppliers();

    return result;
  };

  if (!locationOptions || !supplierOptions || !categoryOptions) {
    return <PageLoader />;
  }

  return (
    <InvoiceFormOwner
      claimStatement={claimStatement}
      invoice={invoice}
      supplierOptions={supplierOptions}
      locationOptions={locationOptions}
      categoryOptions={categoryOptions}
      updateInvoice={handleUpdate}
      batchUploadData={batchUploadData}
    />
  );
};
