import { FC } from 'react';
import styled from 'styled-components';
import { TruncatingText } from '@instech/components';
import { SummaryData } from './types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  color: ${props => props.theme.marineBlue};
  margin-bottom: 8px;
  white-space: nowrap; // Ensure single line per label
`;

const Value = styled(TruncatingText)`
  font-size: 16px;
  line-height: 14px;
`;

interface Props {
  item: SummaryData;
  wrapText: boolean;
  className?: string;
}
export const SummaryItemSmall: FC<Props> = ({ item, wrapText, className }) => (
  <Container className={className}>
    <Label>{item.title}</Label>
    <Value text={item.value} wrap={wrapText} />
  </Container>
);
