import { WarningLarge } from '@/common/components/Icon/WarningLarge';
import { LargeBoldText, RegularText } from '@/common/components/Text';
import { ContentHorizontalCenter } from '@/common/layout/Content';
import { useModalContext } from '@/common/modules/Modal/ModalContext';
import { ModalType } from '@/common/modules/Modal/types';
import { setClaimStatementClosed } from '@/services/claimStatementServices';
import { Guid } from '@/types';
import { ButtonGroup, SlimButton } from '@instech/components';
import { useState } from 'react';
import styled from 'styled-components';

const TextWrapper = styled.div`
  text-align: center;
  padding: 10px 0;
`;

const StyledBoldText = styled(LargeBoldText)`
  margin-bottom: 15px;
`;

interface CloseClaimStatementModal {
  claimStatementId: Guid;
  isClosed?: boolean;
  onClaimStatementStatusChange: () => Promise<any>
}
const ModalContent = ({ claimStatementId, isClosed, onClaimStatementStatusChange }: CloseClaimStatementModal) => {
  const { close } = useModalContext();
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseOrReopen = async () => {
    setIsLoading(true);
    await setClaimStatementClosed(claimStatementId, !isClosed);
    await onClaimStatementStatusChange();
    setIsLoading(false);
    close();
  };

  return (
    <>
      <ContentHorizontalCenter>
        <WarningLarge />
        <TextWrapper>
          <StyledBoldText>{`This claim statement will be ${isClosed ? 'reopened' : 'closed'}.`}</StyledBoldText>
          <RegularText>{`Are you sure you want to ${isClosed ? 'reopen' : 'close'} the claim statement?`}</RegularText>
          {!isClosed && (
            <RegularText>This action will make the claim statement read-only for all roles.</RegularText>
          )}
        </TextWrapper>
      </ContentHorizontalCenter>
      <ButtonGroup alignRight marginTop="24px">
        <SlimButton variant="secondary" onClick={close}>
          CANCEL
        </SlimButton>
        <SlimButton onClick={handleCloseOrReopen} disabled={isLoading} loading={isLoading} width="64px">
          {isClosed ? 'REOPEN' : 'CLOSE'}
        </SlimButton>
      </ButtonGroup>
    </>
  );
};

export const closeClaimStatementModal = (
  { claimStatementId, onClaimStatementStatusChange }: CloseClaimStatementModal
): ModalType<CloseClaimStatementModal> => ({
  component: ModalContent,
  options: {
    title: 'Close Claim Statement',
    size: 'small',
    padding: '12px 20px 20px'
  },
  args: {
    claimStatementId,
    isClosed: false,
    onClaimStatementStatusChange
  }
});

export const reopenClaimStatementModal = (
  { claimStatementId, onClaimStatementStatusChange }: CloseClaimStatementModal
): ModalType<CloseClaimStatementModal> => ({
  component: ModalContent,
  options: {
    title: 'Reopen Claim Statement',
    size: 'small',
    padding: '12px 20px 20px'
  },
  args: {
    claimStatementId,
    isClosed: true,
    onClaimStatementStatusChange
  }
});

export const defaultCloseClaimStatementModalProps: CloseClaimStatementModal = {
  claimStatementId: '',
  isClosed: false,
  onClaimStatementStatusChange: () => Promise.resolve()
};
