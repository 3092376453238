export const editInvoiceTitle = (orderNumber?: number, invoiceNumber?: string | null) => {
  let title = 'Invoice';
  title += orderNumber ? ` ${orderNumber} -` : '';
  title += invoiceNumber ? ` ${invoiceNumber}` : '';
  return title;
};

export const previousButtonSuffix = (invoiceBatchNumber: number, invoiceBatchList: any[]) => {
  if (invoiceBatchNumber === 1) {
    return '';
  }

  return ` (${invoiceBatchNumber - 1}/${invoiceBatchList.length})`;
};
export const nextButtonSuffix = (invoiceBatchNumber: number, invoiceBatchList: any[]) => ` (${invoiceBatchNumber + 1}/${invoiceBatchList.length})`;
