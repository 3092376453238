const SectionSubheaderStyle = {
  fontSize: '14px',
  fontWeight: 700,
  marginBottom: 0,
};

const SectionParagraphStyle = {
  marginTop: 0,
};

const MailtoStyle = {
  textDecoration: 'underline'
};

const HelpSection = () => (
  <>
    <h5 style={SectionSubheaderStyle}>For question regarding a specific claim</h5>
    <p style={SectionParagraphStyle}>Please contact the Claims Handler associated with that specific claim.</p>
    <h5 style={SectionSubheaderStyle}>For question regarding how to use the portal</h5>
    <p style={SectionParagraphStyle}>
      Please send an email to either
      {' '}
      <a style={MailtoStyle} href="mailto:steinar.sivertsen@norclub.com">steinar.sivertsen@norclub.com</a>
      {' '}
      or
      {' '}
      <a style={MailtoStyle} href="mailto:tonje.knudsen@norclub.com">tonje.knudsen@norclub.com</a>
    </p>
    <h5 style={SectionSubheaderStyle}>For technical support or submitting feedback</h5>
    <p style={SectionParagraphStyle}>Please fill out the New Support Request form.</p>
    <p style={SectionParagraphStyle}>
      When contacting us about a problem you are having,
      {' '}
      please consider providing the following information (when relevant) so we can better assist you:
    </p>
    <ul>
      <li>A sensible step-by-step description of the issue</li>
      <li>Any screenshots to clarify your issue or question</li>
      <li>The claim number, policy year and vessel name if the issue is specifically related to a particular assignment</li>
    </ul>
  </>
);

export { HelpSection };
