import { HeadingWrapper, StickyPageHeading } from '@/common/layout/PageLayout';
import { ClaimStatement, Invoice } from '@/types';
import { TitleAndBreadcrumbs } from './core/TitleAndBreadcrumbs';
import { vesselBreadcrumbSteps } from './core/utils';

interface InvoiceHeaderClaimsHandlerProps {
  invoice: Invoice;
  claimStatement: ClaimStatement;
}
export const ClaimsHandlerInvoiceHeader = ({ invoice, claimStatement }: InvoiceHeaderClaimsHandlerProps) => {
  const { id, vesselName, isExperimental } = claimStatement;
  const breadcrumbs = vesselBreadcrumbSteps(id, vesselName, isExperimental);
  return (
    <StickyPageHeading>
      <HeadingWrapper>
        <TitleAndBreadcrumbs
          breadcrumbs={breadcrumbs}
          state={invoice.state}
          invoiceRecord={invoice.record} />
        <div id="claims-handler-action-buttons" />
      </HeadingWrapper>
    </StickyPageHeading>
  );
};
