import { FCWC } from '@/types';
import styled, { css } from 'styled-components';
import { TableCellVariantType, TableColumnBorder } from '../types';

interface StyledCellProps {
  border?: 'left' | 'right';
}
const BaseTableCellStyle = styled.td<StyledCellProps>`
  height: 48px; // TD rendering can always grow height, so this is a min-height
  padding: 10px 20px;
  border: 0px;
  box-sizing: border-box;
  ${props => props.border && css`
    border-${props.border}: 1px solid ${props.theme.marineBlue};
  `};
`;

export interface BaseCellProps {
  id: string;
  headerGroup?: string;
  cellVariant?: TableCellVariantType;
  columnBorder?: TableColumnBorder;
  span?: number;
  onClick?: () => void;
  className?: string;
}
/**
 * Handles rendering TableCells and the content within each. Note that there
 * is a decoupling between the cell and its content; this only renders the cell
 * itself, and any styling relevant to the content should be provided together
 * with said content.
 *
 * Use this component directly, or as a wrapper, if you are making a table cell
 * that displays info to the user (and there are no suitable existing cells).
 */
export const BaseTableCell: FCWC<BaseCellProps> = ({
  id,
  headerGroup,
  cellVariant,
  columnBorder,
  span,
  onClick,
  className,
  children
}) => (
  <BaseTableCellStyle
    key={id}
    data-header-group={headerGroup}
    data-cell-variant={cellVariant}
    border={columnBorder}
    colSpan={span}
    onClick={onClick}
    className={className}>
    {children}
  </BaseTableCellStyle>
);
