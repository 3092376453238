import { CellButton, TableRow } from '@instech/components';
import { ChevronUp } from '@instech/icons';
import styled from 'styled-components';
import { FCWC } from '@/types';
import { TableRowItem } from './SplitTableComponents';

export const ToggleButton = styled(TableRowItem) <{ collapsed?: boolean, deleted?: boolean }>`
  padding: 0;
  background: ${props => props.deleted ? props.theme.adjuster.deleted : props.theme.marineBlue10};
  svg {
    color: ${props => props.theme.marineBlue60};
    transform: ${props => props.collapsed && 'rotate(180deg)'};
  }
`;

interface Props {
  handleCollapse: () => void;
  isCollapsed: boolean;
  isDeleted?: boolean;
}
export const SplitTableRow: FCWC<Props> = ({ children, handleCollapse, isCollapsed, isDeleted = false }) => (
  <TableRow>
    {children}
    <ToggleButton collapsed={isCollapsed} deleted={isDeleted} data-testid="split-row-toggle-button">
      <CellButton icon={<ChevronUp />} onClick={handleCollapse} />
    </ToggleButton>
  </TableRow>
);
