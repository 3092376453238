import { Guid } from '@/types';
import { putAsync } from '@/services/client';
import { InvoiceRequestStatus } from '.';
import { apiRouteOwnersReview } from '../utils/invoiceApiRoute';

export interface OwnersReviewComment {
  ownersReviewComment: string;
  ownersReviewDraftComment: string;
}

export interface OwnersReviewCommentResponse {
  status: InvoiceRequestStatus;
  values: {
    ownersReviewComment: string;
    ownersReviewDraftComment: string;
  };
}

export const updateOwnersReviewComment = async (
  claimStatementId: Guid,
  invoiceId: Guid,
  request: OwnersReviewComment
): Promise<OwnersReviewCommentResponse> => {
  const result = await putAsync<OwnersReviewComment>(apiRouteOwnersReview(claimStatementId, invoiceId), request);
  // Result doesn't send data back, so just sending request values back after response
  if (result.status === 200) {
    return {
      values: request,
      status: 'success'
    };
  }
  return {
    values: request,
    status: 'error'
  };
};
