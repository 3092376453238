import { FormRow } from '@/common/components/Form/FormRow/FormRow';
import {
  surveyorClaimConsiderationTags,
  surveyorGeneralExpensesTags,
  UpdateSurveyorsConsiderationForm
} from '@/types';
import { convertCurrency } from '@/utils/invoice/convertCurrency';
import { CurrencyFieldControlled } from '@instech/components';
import { useFormikContext } from 'formik';
import { FunctionComponent as FC } from 'react';
import { HighlightedFieldWithTags } from '@/common/components/Form/FieldWithTags';
import { CurrencyFieldLimitedWithTag } from '@/common/components/Form/FieldWithTags/CurrencyFieldLimitedWithTag';
import { useSharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { HighlightedNumericalField } from '@/components/pages/invoice/core/EditInvoice/core/HighlightedNumericalField';
import { CurrencyFieldLimited } from '../../core/CurrencyFieldLimited';

interface CostFieldProps {
  arrayName: string;
  index: number;
  useInvoiceCurrency?: boolean;
  exchangeRate?: number | null;
}
export const SurveyorsCostFields: FC<CostFieldProps> = ({ arrayName, index, exchangeRate, useInvoiceCurrency }) => {
  const { values } = useFormikContext<UpdateSurveyorsConsiderationForm>();
  const { adjustersConsideration } = values.splits[index];
  const { tooltipId } = useSharedTooltip();

  if (useInvoiceCurrency) {
    return (
      <FormRow gutter="32px" layoutLarge="repeat(4, 1fr)">
        <HighlightedFieldWithTags
          label="Claim"
          name={`${arrayName}.${index}.claimConsideration`}
          initialFieldName={`${arrayName}.${index}.initialClaim`}
          isConsideredFieldName={`${arrayName}.${index}.form.claimConsidered`}
          limitValidInput
          tagTooltipId={tooltipId}
          tagFieldName={`${arrayName}.${index}.claimConsiderationTag`}
          tagOptions={surveyorClaimConsiderationTags} />
        <HighlightedNumericalField
          label="Owner"
          name={`${arrayName}.${index}.ownersWorkConsideration`}
          initialFieldName={`${arrayName}.${index}.initialOwnersWork`}
          isConsideredFieldName={`${arrayName}.${index}.form.ownersWorkConsidered`}
        />
        <CurrencyFieldLimitedWithTag
          label="General Expenses"
          name={`${arrayName}.${index}.generalExpenses`}
          tagTooltipId={tooltipId}
          tagFieldName={`${arrayName}.${index}.generalExpensesTag`}
          tagOptions={surveyorGeneralExpensesTags} />
        <CurrencyFieldLimited
          label="Adjuster to Consider"
          name={`${arrayName}.${index}.adjustersConsideration`}
          placeholder="" />
      </FormRow>
    );
  }

  return (
    <FormRow gutter="32px" layoutLarge="repeat(4, 1fr)">
      <HighlightedFieldWithTags
        label="Claim"
        name={`${arrayName}.${index}.claimConsideration`}
        initialFieldName={`${arrayName}.${index}.initialClaim`}
        isConsideredFieldName={`${arrayName}.${index}.form.claimConsidered`}
        convertRate={exchangeRate}
        emptyWithoutConvertRate
        tagTooltipId={tooltipId}
        tagFieldName={`${arrayName}.${index}.claimConsiderationTag`}
        tagOptions={[]}
        disabled
      />
      <HighlightedNumericalField
        label="Owner"
        name={`${arrayName}.${index}.ownersWorkConsideration`}
        initialFieldName={`${arrayName}.${index}.initialOwnersWork`}
        isConsideredFieldName={`${arrayName}.${index}.form.ownersWorkConsidered`}
        convertRate={exchangeRate}
        emptyWithoutConvertRate
        disabled
      />
      <CurrencyFieldLimitedWithTag
        label="General Expenses"
        name={`${arrayName}.${index}.generalExpenses`}
        tagTooltipId={tooltipId}
        tagFieldName={`${arrayName}.${index}.generalExpensesTag`}
        convertRate={exchangeRate}
        emptyWithoutConvertRate
        tagOptions={[]}
        disabled
      />
      <CurrencyFieldControlled
        label="Adjuster to Consider"
        name={`${arrayName}.${index}.adjustersConsideration`}
        value={convertCurrency(adjustersConsideration, exchangeRate, !!exchangeRate, true)}
        placeholder=""
        disabled
      />
    </FormRow>
  );
};
