import styled from 'styled-components';

/**
 * Component used to render a dividing line between a table row and
 * the contents within when the row is expanded.
 */
export const DividerLine = styled.div`
  height: 1px;
  width: 100%;
  margin-bottom: 20px;
  background-color: ${props => props.theme.marineBlue40};
  box-sizing: border-box;
`;
