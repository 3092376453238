import {
  ClaimStatement, Invoice, LatestInstructions
} from '@/types';
import { invoiceStates } from '@/utils/constants';
import { FunctionComponent as FC } from 'react';
import { CostReAllocationInvoiceDetails } from './CostReAllocationInvoiceDetails';
import { AdjustersConsiderationInvoiceDetails } from './AdjustersConsiderationInvoiceDetails';
import { NotAllocatedInvoiceDetails } from './NotAllocatedInvoiceDetails';
import { ReadonlyInvoiceDetails } from './ReadonlyInvoiceDetails';

interface Props {
  invoice: Invoice;
  claimStatement: ClaimStatement;
  ownsLock: boolean;
  isClaimStatementClosed: boolean;
  surveyorInstructions?: LatestInstructions;
  ownerInstructions?: LatestInstructions;
}
/**
 * Switch component for handling whether to show the Edit or Readonly view of the
 * left-hand invoice details side of an invoice page. One such component for reach
 * role, to handle different rendering scenarios for different roles.
 * The default view is readonly, with the edit view enabled by a prop.
 * Note that AdditionalInformationPane is included in EditInvoiceAdjuster
 * so it needs to be added separately for readonly, i.e. when locked.
 */
export const InvoiceDetailsClaimsHandler: FC<Props> = ({
  invoice,
  claimStatement,
  ownsLock,
  isClaimStatementClosed,
  surveyorInstructions,
  ownerInstructions
}) => {
  switch (invoice.state) {
    case invoiceStates.Review:
    case invoiceStates.SharedReview:
    case invoiceStates.AdjustersConsiderationNoAllocation:
      return (
        <NotAllocatedInvoiceDetails
          invoice={invoice}
          claimStatement={claimStatement}
          ownsLock={ownsLock}
          isClaimStatementClosed={isClaimStatementClosed}
          surveyorInstructions={surveyorInstructions}
          ownerInstructions={ownerInstructions} />
      );
    case invoiceStates.CostReallocation:
      return (
        <CostReAllocationInvoiceDetails
          invoice={invoice}
          claimStatement={claimStatement}
          surveyorInstructions={surveyorInstructions}
          isClaimStatementClosed={isClaimStatementClosed}
          ownerInstructions={ownerInstructions} />
      );
    case invoiceStates.AdjustersConsideration:
      return (
        <AdjustersConsiderationInvoiceDetails
          invoice={invoice}
          claimStatement={claimStatement}
          ownsLock={ownsLock}
          isClaimStatementClosed={isClaimStatementClosed}
          surveyorInstructions={surveyorInstructions}
          ownerInstructions={ownerInstructions} />
      );
    default:
      return (
        <ReadonlyInvoiceDetails
          invoice={invoice}
          claimStatement={claimStatement}
          isClaimStatementClosed={isClaimStatementClosed}
          surveyorInstructions={surveyorInstructions}
          ownerInstructions={ownerInstructions} />
      );
  }
};
