/* eslint-disable react/forbid-prop-types */
import React, { createContext, useContext, } from 'react';
import { FCWC } from '@/types';
import { ModalContextData, ModalType } from './types';

export const ModalContext = createContext<ModalContextData>(null as unknown as ModalContextData);

export const ModalProvider: FCWC<ModalContextData> = ({ children, open, close }) => {
  const value = React.useMemo(() => ({
    open, close
  }), [open, close]);
  return (
    <ModalContext.Provider value={value}>
      {children}
    </ModalContext.Provider>
  );
};

/**
 * Retrieve context functions to open and close the modal view.
 *
 * The main thing you will need the ModalContext inside a component for is to access and
 * call the modal `close()` function.
 *
 * Do not use this to directly open a modal via `open(ModalContent)`, unless you really
 * need to; ideally you should set up the modal with `useTypedModal()`.
 *
 */
export const useModalContext = () => useContext(ModalContext);

/**
 * Initialize a Modal with typing provided.
 *
 * Use this when creating a new modal with specific props and arguments, and do not use
 * `useModalContext()` to directly call `open(ModalContent)` unless you have a good reason
 * to do that instead.
 *
 * Function param is an object of three props; `component`, `options`, and an optional `args`.
 * `component` returns the actual contents rendered out into the modal.
 *
 * Use `options` to provide properties relevant to the modal itself; modal title, modal size,
 * padding around modal content. Use `args` to provide props via the Modal hook to the modal
 * `component` (ex. send config to modal content, create callback to return data).
 *
 * ### Modal size controlled by content
 *
 * Normally the modal has a set of pre-defined sizes to choose from, but if there is a situation
 * in which size should be controlled by the modal content (ex. modal size should change while
 * it is open, or the size is non-standard), then `options.size` can be set to `'content'`. This
 * will cause the modal to auto-size to the width of its content.
 */
export function useTypedModal<T>(modalObject: ModalType<T>) {
  const { open, close } = useModalContext();
  const payload = modalObject;
  return {
    open: (context: Partial<T> = {}, onCancel: () => void = () => null) => open({ ...payload, args: { ...payload.args, ...context }, onCancel }),
    close
  };
}
