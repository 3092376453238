import { useModalContext } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import {
  ApplicationRole, ClaimStatement, Invoice
} from '@/types';
import { OwnersClaimEdit } from './OwnersClaimEdit';

interface EditModalProps {
  claimStatement: ClaimStatement;
  invoice: Invoice;
  userRole?: ApplicationRole | null;
  showNotes?: boolean;
}
export const ModalContent = ({ claimStatement, invoice, userRole, showNotes }: EditModalProps) => {
  const { close } = useModalContext();
  return (
    <OwnersClaimEdit
      claimStatement={claimStatement}
      invoice={invoice}
      userRole={userRole}
      onClose={close}
      showNotes={showNotes}
    />
  );
};

export const ownersClaimEditModal = ({
  claimStatement,
  invoice,
  userRole,
  showNotes
}: EditModalProps): ModalType<EditModalProps> => ({
  component: ModalContent,
  options: {
    title: "Edit Owner's Claim",
    size: 'medium',
    padding: '0px'
  },
  args: {
    claimStatement,
    invoice,
    userRole,
    showNotes
  }
});
