/**
 * Insert an artificial delay before executing the next block of code;
 * async Promise wrapper for a javascript setTimeout()
 */
export const delay = async (time: number) => (
  new Promise<boolean>(resolve => {
    setTimeout(() => resolve(true), time);
  })
);

/**
 * Returns a promise after a specified delay. Useful for delaying a call
 * that is too fast for the desired user experience.
 */
export const delayPromise = async (promise: Promise<any>, time: number) => {
  await delay(time);
  return promise;
};
