import { HeadingWrapper, StickyPageHeading } from '@/common/layout/PageLayout';
import { ClaimStatement, Invoice } from '@/types';
import { TitleAndBreadcrumbs } from './core/TitleAndBreadcrumbs';
import { claimReviewBreadcrumbSteps } from './core/utils';

interface InvoiceHeaderReviewProps {
  invoice: Invoice;
  claimStatement: ClaimStatement;
}
export const ReviewInvoiceHeader = ({ invoice, claimStatement }: InvoiceHeaderReviewProps) => {
  const { id, vesselName, isExperimental } = claimStatement;
  const breadcrumbs = claimReviewBreadcrumbSteps(id, vesselName, isExperimental);
  return (
    <StickyPageHeading>
      <HeadingWrapper>
        <TitleAndBreadcrumbs
          breadcrumbs={breadcrumbs}
          state={invoice.state}
          invoiceRecord={invoice.record} />
        <div id="review-action-buttons" />
      </HeadingWrapper>
    </StickyPageHeading>
  );
};
