import { CollapsibleTableRowData, CollapsibleTableSchemaReturn } from '@/common/components/Table/CollapsibleTable/types';
import { Invoice } from '@/types';
import {
  tableSchemaForPrintCollapse, tableSchemaForPrintHeader, tableSchemaForPrintSubheader
} from './tableSchemaForPrintHeaders';
import { tableSchemaForPrintRow } from './tableSchemaForPrintRow';
import { SubrowDataType, tableSchemaForPrintSubrow } from './tableSchemaForPrintSubrow';
import { CSTableProps } from '../useSchemaByTableGrouping';

// Schema with no grouping have splits as subrows, so min 2 subrows to expand
const MIN_SUBROWS_TO_EXPAND = 2;

// Groups Surveyor splits together with their corresponding Adjuster split
const groupSurveyorAdjusterSplits = (data: Invoice) => {
  const { adjustersConsideration, surveyorsConsideration } = data;
  const list: SubrowDataType[] = [];
  adjustersConsideration.splits.forEach(adjusterSplit => {
    const surveyorSplit = surveyorsConsideration?.splits.find(item => item.id === adjusterSplit.surveyorSplitId);
    list.push({ adjuster: adjusterSplit, surveyor: surveyorSplit });
  });
  return list;
};

// Crunch through and return ready table rows and subrows
const composeTableRowData = (
  data: Invoice[],
  props: CSTableProps
): CollapsibleTableRowData[] => {
  const segments = data.map(row => ({
    row: tableSchemaForPrintRow(row, props),
    subrows: groupSurveyorAdjusterSplits(row).map(
      subrow => tableSchemaForPrintSubrow(subrow, row, props)
    )
  }));
  return segments;
};

/**
 * Takes table data, as well as properties needed for table cell contents, and
 * returns a an object of the different parts of the table schema (header sections,
 * table row content, etc.)
 */
export const createTableSchemaForPrint = (
  data: Invoice[],
  props: CSTableProps
): CollapsibleTableSchemaReturn => {
  const genAvgColumn = props.claimStatementSettings?.hasGeneralAverage ? 'auto' : '';
  return ({
    layout: `auto ${genAvgColumn} repeat(12, auto)`,
    subrowsHeaderText: 'Splits',
    minSubrowsToExpand: MIN_SUBROWS_TO_EXPAND,
    selectableRows: false,
    collapseButtons: tableSchemaForPrintCollapse(props),
    header: tableSchemaForPrintHeader(props),
    subheader: tableSchemaForPrintSubheader(props),
    tableRows: composeTableRowData(data, props),
  });
};
