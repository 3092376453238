import { WarningFilled } from '@instech/icons';
import { FC } from 'react';
import styled from 'styled-components';

const StyledWarningFilled = styled(WarningFilled)`
  color: ${props => props.theme.marineBlue};
  width: 80px;
  height: 64px;
  padding: 10px;
`;

interface Props {
  className?: string;
}
/**
 * Stylized large warning signed, primarily used in alert modals.
 * ViewBox is altered to fill the SVG space with the whole icon, which should be
 * reassessed in the event that the SVG is updated to change its internal spacing.
 */
export const WarningLarge: FC<Props> = ({ className }) => (
  <StyledWarningFilled viewBox="0 2 24 19" className={className} />
);
