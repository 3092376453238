import {
  ButtonGroup, SlimButton, TextArea
} from '@instech/components';
import { Close, Plus } from '@instech/icons';
import { useField } from 'formik';
import { useState } from 'react';

export const InternalNote = () => {
  const [input, , helpers] = useField('notes');
  const [showNote, setShowNote] = useState(input.value !== '');

  const handleShowNote = () => setShowNote(true);
  const handleDeleteNote = () => {
    setShowNote(false);
    helpers.setValue('');
  };

  if (!showNote) {
    return (
      <ButtonGroup>
        <SlimButton startIcon={<Plus />} variant="tertiary" onClick={handleShowNote}>ADD INTERNAL NOTE</SlimButton>
      </ButtonGroup>
    );
  }
  return (
    <>
      <TextArea name="notes" label="Internal Notes - Only visible for users with your role" placeholder="" />
      <ButtonGroup>
        <SlimButton startIcon={<Close />} variant="tertiary" onClick={handleDeleteNote}>DELETE INTERNAL NOTE</SlimButton>
      </ButtonGroup>
    </>
  );
};
