import {
  AdjustersConsiderationForm,
  AdjustersConsiderationSplitForm,
  Invoice
} from '@/types';
import { LabelValuePair } from '@instech/components';
import { FieldArray, useFormikContext } from 'formik';
import { FunctionComponent as FC, useMemo } from 'react';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { newAdjustersConsiderationItem } from '@/components/pages/invoice/core/EditInvoice/initialValues';
import { useConsiderationContext } from '../InvoiceForm/utils/ConsiderationProvider';
import { getShowApplyValuesOwner, getShowApplyValuesSurveyor } from '../utils/showApplyValues';
import { AdjustersConsiderationButtons } from './AdjustersConsiderationButtons';
import { SplitItemAdjuster } from './core/SplitItemAdjuster';
import { SplitTableAdjuster } from './core/SplitTableAdjuster';
import { SplitTableRowAdjuster } from './core/SplitTableRowAdjuster';
import { TotalLineEdit } from './core/TotalLine';

interface Props {
  invoice: Invoice;
  selectedCurrency?: string | null;
  categoryOptions: LabelValuePair[];
  repairPeriodOptions: LabelValuePair[];
  occurrenceOptions: LabelValuePair[];
  updateSplitsOrder: (splitsIdOrder: AdjustersConsiderationSplitForm[]) => void;
  showGeneralAverage: boolean;
}
export const ConsiderationFields: FC<Props> = ({
  invoice,
  selectedCurrency,
  categoryOptions,
  repairPeriodOptions,
  occurrenceOptions,
  updateSplitsOrder,
  showGeneralAverage
}) => {
  const { values, setFieldValue, setValues } = useFormikContext<AdjustersConsiderationForm>();
  const { getConsiderationData, setConsiderationData } = useConsiderationContext();
  const invoiceRecord = invoice.record;
  const useInvoiceCurrency = selectedCurrency === invoice.record.currency;
  const multipleSplits = values.splits.length > 1;
  const viewAsListOfSplits = multipleSplits || values.splits[0]?.isDeleted === true;

  const showApplyValuesOwner = getShowApplyValuesOwner(invoice.state);
  const showApplyValuesSurveyor = getShowApplyValuesSurveyor(invoice.state);
  const applyValuesDisabled = useMemo(() => {
    const currentSplits = values.splits.filter(split => !split.isDeleted);
    const allOwnersConsidered = currentSplits.every(split => (split.form.ownersWorkConsidered === true || !split.initialOwnersWork));
    const allCommonConsidered = currentSplits.every(split => (split.form.commonExpensesConsidered === true || !split.initialCommonExpenses));
    const allParticularAvgConsidered = currentSplits.every(split => (split.form.particularAvgConsidered === true || !split.initialParticularAverage));

    return (allOwnersConsidered && allCommonConsidered && allParticularAvgConsidered);
  }, [values]);

  const markValuesAsConsidered = () => {
    const newValues = {
      ...values,
      splits: values.splits.map(split => ({
        ...split,
        form: {
          ...split.form,
          ownersWorkConsidered: true,
          commonExpensesConsidered: true,
          particularAvgConsidered: true,
          generalAverageConsidered: true,
        }
      }))
    };
    void setValues(newValues);
  };

  return (
    <FieldArray
      name="splits"
      render={arrayHelpers => {
        const addNewSplit = () => {
          const prevItemIndex = values.splits.length - 1;
          const newItem = newAdjustersConsiderationItem(occurrenceOptions[0], values.splits[prevItemIndex]);
          arrayHelpers.push(newItem);
        };
        // only remove item completely if it is not connected to a surveyor split
        const removeSplit = (index: number) => {
          const split = values.splits[index];
          if (split.surveyorSplitId) {
            void setFieldValue(`splits.${index}.isDeleted`, true);
          } else {
            arrayHelpers.remove(index);
          }
          const splitsForConsideration = [...values.splits];
          splitsForConsideration.splice(index, 1);
          setConsiderationData(splitsForConsideration.filter(s => !s.isDeleted));
        };
        const restoreSplit = (index: number) => {
          const splits = [...values.splits];
          splits[index] = { ...splits[index], isDeleted: false };
          setConsiderationData(splits.filter(s => !s.isDeleted));
        };
        return (
          <>
            <SplitTableAdjuster viewAsListOfSplits={viewAsListOfSplits} showGeneralAverage={showGeneralAverage}>
              <SharedTooltip id="adjuster-cost-fields-tags-tooltip">
                {values.splits.map((split, index: number) => (
                  <SplitTableRowAdjuster
                    arrayName="splits"
                    index={index}
                    key={split.form.arrayId}
                    invoiceRecord={invoiceRecord}
                    adjusterSplit={split}
                    startCollapsed={split.form.startCollapsed || index === 0}
                    surveyorSplitId={split.surveyorSplitId}
                    useInvoiceCurrency={useInvoiceCurrency}
                    viewAsListOfSplits={viewAsListOfSplits}
                    showGeneralAverage={showGeneralAverage}>
                    <SplitItemAdjuster
                      arrayName="splits"
                      index={index}
                      invoiceRecord={invoiceRecord}
                      categoryOptions={categoryOptions}
                      repairPeriodOptions={repairPeriodOptions}
                      occurrenceOptions={occurrenceOptions}
                      onAddSplit={addNewSplit}
                      onRemoveSplit={removeSplit}
                      onRestoreSplit={restoreSplit}
                      selectedCurrency={selectedCurrency}
                      surveyorSplitId={split.surveyorSplitId}
                      viewAsListOfSplits={viewAsListOfSplits}
                      useInvoiceCurrency={useInvoiceCurrency}
                      showApplyValuesOwner={showApplyValuesOwner && !viewAsListOfSplits}
                      showApplyValuesSurveyor={showApplyValuesSurveyor && !viewAsListOfSplits}
                      onApplyValues={markValuesAsConsidered}
                      applyValuesDisabled={applyValuesDisabled}
                      showGeneralAverage={showGeneralAverage}
                    />
                  </SplitTableRowAdjuster>
                ))}
                {viewAsListOfSplits && (
                  <TotalLineEdit
                    splits={(getConsiderationData<AdjustersConsiderationSplitForm[]>() ?? [])}
                    invoice={invoiceRecord}
                    selectedCurrency={selectedCurrency || ''}
                    showGeneralAverage={showGeneralAverage}
                  />
                )}
              </SharedTooltip>
            </SplitTableAdjuster>
            <AdjustersConsiderationButtons
              onAddSplit={addNewSplit}
              viewAsListOfSplits={viewAsListOfSplits}
              multipleSplits={multipleSplits}
              showApplyValuesOwner={showApplyValuesOwner}
              showApplyValuesSurveyor={showApplyValuesSurveyor}
              onApplyValues={markValuesAsConsidered}
              applyValuesDisabled={applyValuesDisabled}
              reorderSplitsModalProps={{
                form: values,
                usingInvoiceCurrency: useInvoiceCurrency,
                exchangeRate: invoice.record.exchangeRate,
                updateSplitsOrder
              }} />
          </>
        );
      }}
    />
  );
};
