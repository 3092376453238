import { ModalType } from '@/common/modules/Modal/types';
import {
  ModalContentLarge, ModalContentSmall, useModalContext
} from '@/common/modules/Modal';
import { InfoWithLabel } from '@/common/components/Text';
import {
  SupportingDocumentForm,
  uploadSupportingDocument, useSupportingDocuments
} from '@/services/supportingDocumentsServices';
import { ClaimStatementInvoiceId } from '@/types';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { supportingDocumentFileTypes, validateFiletypeAndSize } from '@/utils/file';
import {
  ActionButtonsGroup, DocumentNameField, FormContent, PiiDescriptionField, PiiDropdown
} from '../../core/ModalFormComponents';
import { InvalidFileError } from '../../core/Modals/InvalidFileError';
import { paymentConfirmationValidationSchema } from '../paymentConfirmationValidationSchema';
import { createInitialValues } from '../utils';

const TextLabel = styled(InfoWithLabel)`
  margin-bottom: 20px;
  color: ${props => props.theme.marineBlue};
`;

interface UploadPaymentConfirmationsProps {
  paymentConfirmation: File;
  claimStatementInvoiceId: ClaimStatementInvoiceId;
}
const ModalContent = ({ paymentConfirmation, claimStatementInvoiceId }: UploadPaymentConfirmationsProps) => {
  const { close } = useModalContext();
  const initialValues = createInitialValues();
  const { refreshSupportingDocuments } = useSupportingDocuments(claimStatementInvoiceId);

  const upload = async (values: SupportingDocumentForm) => {
    await uploadSupportingDocument(claimStatementInvoiceId, paymentConfirmation, values);
    await refreshSupportingDocuments();
    close();
  };

  const validationError = validateFiletypeAndSize(paymentConfirmation, supportingDocumentFileTypes);
  if (validationError) {
    return (
      <ModalContentSmall>
        <InvalidFileError validationError={validationError} />
      </ModalContentSmall>
    );
  }
  return (
    <ModalContentLarge>
      <Formik initialValues={initialValues} validationSchema={paymentConfirmationValidationSchema} onSubmit={upload}>
        <Form>
          <FormContent>
            <TextLabel label="Filename" text={paymentConfirmation.name} />
            <DocumentNameField />
            <PiiDropdown />
            <PiiDescriptionField />
          </FormContent>
          <ActionButtonsGroup close={close} />
        </Form>
      </Formik>
    </ModalContentLarge>
  );
};

type UploadPaymentConfirmationModal = (props: UploadPaymentConfirmationsProps, onCancel?: () => void) => ModalType<UploadPaymentConfirmationsProps>;
export const uploadPaymentConfirmationModal: UploadPaymentConfirmationModal = (props, onCancel = () => null) => ({
  component: ModalContent,
  options: {
    title: 'Upload Payment Confirmation',
    size: 'content',
    padding: '12px 24px 24px 24px'
  },
  args: props,
  onCancel
});

export const defaultUploadPaymentConfirmationsProps: UploadPaymentConfirmationsProps = {
  paymentConfirmation: new File([], ''),
  claimStatementInvoiceId: { claimStatementId: '', invoiceId: '' }
};
