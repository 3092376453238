import { useCurrentRole } from '@/common/authentication/CurrentRoleContext';
import { FileDropzone } from '@/common/components/FileDropzone';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { useTypedModal } from '@/common/modules/Modal';
import { SupportingDocumentResponse } from '@/services/supportingDocumentsServices';
import { ClaimStatementInvoiceId } from '@/types';
import { ContextMenu } from '@instech/components';
import { ComponentProps, FC } from 'react';
import { FileTable, IconCell } from '@/common/components/Table';
import { acceptedTypes } from '../../core/utils';
import {
  Content, Header, Wrapper
} from '../TabPaneComponents';
import { DropzoneContainer } from '../core/Components';
import { defaultDeleteSupportingDocumentModal, deleteSupportingDocumentModal } from '../core/Modals/DeleteSupportingDocumentModal';
import { defaultEditPaymentConfirmationsProps, editPaymentConfirmationModal } from './Modals/EditPaymentConfirmationModal';
import { defaultUploadPaymentConfirmationsProps, uploadPaymentConfirmationModal } from './Modals/UploadPaymentConfirmationModal';

const paymentConfirmationTooltipId = 'payment-confirmation-tooltip' as const;

interface PaymentConfirmationPaneProps {
  compositeInvoiceId: ClaimStatementInvoiceId;
  paymentConfirmations: SupportingDocumentResponse[];
  isClaimStatementClosed: boolean;
}
export const PaymentConfirmationPane: FC<PaymentConfirmationPaneProps> = ({
  compositeInvoiceId,
  paymentConfirmations,
  isClaimStatementClosed
}) => {
  const userRole = useCurrentRole();
  const { open: openUploadModal } = useTypedModal(uploadPaymentConfirmationModal(defaultUploadPaymentConfirmationsProps));
  const { open: openEditModal } = useTypedModal(editPaymentConfirmationModal(defaultEditPaymentConfirmationsProps));
  const { open: openDeleteModal } = useTypedModal(deleteSupportingDocumentModal(defaultDeleteSupportingDocumentModal));

  const handleUpload: ComponentProps<typeof FileDropzone>['handleUpload'] = files =>
    openUploadModal({ paymentConfirmation: files[0] as File, claimStatementInvoiceId: compositeInvoiceId });

  return (
    <Wrapper>
      <Header>
        <span>
          Your invoices should be supported by a payment confirmation prior to the issuance of the final adjustment.
          This can be uploaded at any time.
        </span>
      </Header>
      <Content>
        <SharedTooltip id={paymentConfirmationTooltipId}>
          <FileTable>
            <thead>
              <tr>
                <th>File Name</th>
                <th>Document Name</th>
                <th>PII</th>
                <th aria-label="context menu" />
              </tr>
            </thead>
            <tbody>
              {(!paymentConfirmations || paymentConfirmations.length === 0) && (
                <tr>
                  <td colSpan={4}>No payment confirmations have been uploaded yet.</td>
                </tr>
              )}
              {paymentConfirmations && paymentConfirmations.length > 0 && paymentConfirmations.map(confirmation => (
                <tr key={confirmation.id}>
                  <td><a href={confirmation.fileInformation.path}>{confirmation.fileInformation.filename}</a></td>
                  <td>{confirmation.documentName}</td>
                  <td data-for={paymentConfirmationTooltipId} data-tip={confirmation.piiDescription}>
                    {confirmation.pii === 'No' ? 'No' : 'Yes'}
                  </td>
                  <IconCell>
                    <ContextMenu
                      data-testid={`context-menu-${confirmation.id}`}
                      options={[
                        {
                          label: 'Edit',
                          onClick: isClaimStatementClosed ? null : () =>
                            openEditModal({ id: { ...compositeInvoiceId, supportingDocumentId: confirmation.id }, supportingDocument: confirmation })
                        },
                        {
                          label: 'Delete',
                          onClick: userRole?.name === confirmation?.createdBy?.role && !isClaimStatementClosed ?
                            () => openDeleteModal({
                              id: {
                                ...compositeInvoiceId,
                                supportingDocumentId: confirmation.id,
                                documentName: confirmation.documentName || confirmation.fileInformation.filename
                              }
                            }) :
                            null
                        }
                      ]} />
                  </IconCell>
                </tr>
              ))}
            </tbody>
          </FileTable>
        </SharedTooltip>
        {!isClaimStatementClosed && (
          <DropzoneContainer>
            <FileDropzone
              instructions="Please do not upload a file containing personal identifiable information unless strictly necessary to support your claim."
              acceptedTypes={acceptedTypes}
              handleUpload={handleUpload}
              id="paymentConfirmation-uploader" />
          </DropzoneContainer>
        )}
      </Content>
    </Wrapper>
  );
};
