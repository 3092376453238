import {
  TableRowItem, SplitTableRow, TableRowInner
} from '@/apps/ClaimStatement/components/InvoiceSplitTable';
import {
  AdjustersConsiderationSplit, FCWC, InvoiceRecord, Occurrence
} from '@/types';
import { calculateReadonlyAdjusterSummaryValues } from '@/utils/invoice/calculateSummaries';
import { useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import styled from 'styled-components';
import { TableRowTag } from '@/apps/ClaimStatement/components/InvoiceSplitTable/SplitTableComponents';
import { useSharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { useInvoiceConsideredBySurveyor, useSurveyorSplitById } from '../../../EditInvoice/InvoiceForm/utils/SurveyorSplitsContext';

const DarkTableRowItem = styled(TableRowItem)`
  background-color: ${props => props.theme.marineBlue20};
`;

const DeletedTableRowItem = styled(TableRowItem)<{hasGeneralAverage?: boolean}>`
  grid-column-start: 1;
  grid-column-end: ${props => (props.hasGeneralAverage ? 11 : 10)};
  justify-content: center;
  color: ${props => props.theme.marineBlue80};
  background: ${props => props.theme.adjuster.deleted};
  size: 14px;
  padding-left: 15%;
`;

interface Props {
  consideration: AdjustersConsiderationSplit;
  invoiceRecord: InvoiceRecord;
  useInvoiceCurrency?: boolean;
  occurrenceList: Occurrence[];
  surveyorSplitId?: string;
  showGeneralAverage: boolean;
}
export const ReadonlySplitTableRowAdjuster: FCWC<Props> = ({
  children,
  useInvoiceCurrency,
  invoiceRecord,
  consideration,
  occurrenceList,
  surveyorSplitId,
  showGeneralAverage
}) => {
  const { tooltipId } = useSharedTooltip();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const surveyorSplit = useSurveyorSplitById(surveyorSplitId);
  const isConsidered = useInvoiceConsideredBySurveyor();
  const { exchangeRate } = invoiceRecord;

  const { occurrence, category, commonExpenses, generalAverage, particularAverage, ownersWork, splitSum, removedSplitSum, surveyorDeviation } = useMemo(
    () => calculateReadonlyAdjusterSummaryValues(consideration, surveyorSplit, isConsidered, exchangeRate, useInvoiceCurrency, occurrenceList),
    [consideration, surveyorSplit, isConsidered, exchangeRate, useInvoiceCurrency, occurrenceList]
  );

  const handleCollapse = () => setIsCollapsed(!isCollapsed);

  return (
    <>
      <SplitTableRow
        handleCollapse={handleCollapse}
        isCollapsed={isCollapsed}
        isDeleted={consideration.isDeleted}>
        {consideration.isDeleted ? (
          <>
            <DeletedTableRowItem hasGeneralAverage={showGeneralAverage}>Deleted by Adjuster</DeletedTableRowItem>
            <TableRowItem emphasize="Deleted" justify="flex-end">{removedSplitSum}</TableRowItem>
          </>
        ) : (
          <>
            <TableRowItem>{occurrence}</TableRowItem>
            <TableRowItem>{category}</TableRowItem>
            <TableRowItem justify="right">{commonExpenses}</TableRowItem>
            <TableRowTag tag={consideration.commonExpensesTag} tooltipId={tooltipId} />
            {showGeneralAverage && <TableRowItem justify="right">{generalAverage}</TableRowItem>}
            <TableRowItem justify="right">{particularAverage}</TableRowItem>
            <TableRowTag tag={consideration.particularAverageTag} tooltipId={tooltipId} />
            <TableRowItem justify="right">{ownersWork}</TableRowItem>
            <TableRowTag tag={consideration.ownersWorkConsiderationTag} tooltipId={tooltipId} />
            <DarkTableRowItem justify="right">{splitSum}</DarkTableRowItem>
            <TableRowItem justify="right">{surveyorDeviation}</TableRowItem>
          </>
        )}
      </SplitTableRow>
      <TableRowInner>
        <AnimateHeight duration={250} height={isCollapsed ? 0 : 'auto'}>
          {children}
        </AnimateHeight>
      </TableRowInner>
    </>
  );
};
