import {
  CollapsibleTableHeaders,
  CollapsibleTableRows,
  CollapsibleTableSubheaders
} from '@/common/components/Table/CollapsibleTable';
import { useExpandedTableRows } from '@/common/components/Table/utils';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { FitToContent } from '@/common/layout/FitToContent';
import { Guid, InvoiceOverviewSurveyor } from '@/types';
import { useSortedInvoiceData } from '@/utils/invoice/sortInvoiceData';
import {
  CheckboxControlled,
  ComplexTable,
  MenuOption,
  Pane,
  SortedTableHeader
} from '@instech/components';
import { FunctionComponent, useState } from 'react';

import styled from 'styled-components';
import { TableHeaderButtons, TableHeaderText } from '../core/Components';

import { getTraversalIds, useTraversalOrderContext } from '../core/TraversalContext';
import { InvoiceButtons } from './core/InvoiceButtons';
import { InvoiceSummary } from './core/InvoiceSummary';
import { tableSchemaSurveyor } from './core/tableSchemaSurveyor';
import { useUnmovedInvoices } from './core/utils/useUnmovedInvoices';
import { useStickyTopValues } from './utils';

const StyledCheckbox = styled(CheckboxControlled)`
  // The CheckboxControlled styles were loading after these styles in the feature environment for some reason.
  // This caused the width to remain 100%.
  width: auto !important;
  color: ${({ theme }) => theme.marineBlue};
  padding-top: 16px;
`;

const FlexWrapper = styled.div`
  display: flex;
`;

interface Props {
  name: string;
  title: string;
  data: InvoiceOverviewSurveyor;
  onClick: (invoiceId: string) => void;
  onSubmit?: (invoicesToTransfer: string[]) => Promise<void>;
  selectableRows?: boolean;
  contextMenuOptions?: (invoiceId: Guid) => MenuOption[];
  isClosed: boolean;
  showButtons?: boolean;
  paneMargin?: string;
}

export const InvoiceTableSurveyor: FunctionComponent<Props> = ({
  name,
  data,
  onClick,
  title,
  onSubmit,
  selectableRows = true,
  contextMenuOptions = () => [],
  isClosed,
  showButtons = true,
  paneMargin = '0px'
}) => {
  const [sortedHeader, setSortedHeader] = useState<SortedTableHeader>();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const { setCurrentTraversalOrder } = useTraversalOrderContext();
  const { toggleExpanded, expanded } = useExpandedTableRows(data.invoices.map(invoice => invoice.id));
  const [showFullComments, setShowFullComments] = useState<boolean>(false);

  const handleSortedHeader = (header: SortedTableHeader) => {
    setSortedHeader(header);
  };

  const sortedTable = useSortedInvoiceData(data, sortedHeader);

  const handleSelectAll = () => {
    if (selectedRows.length > 0) {
      setSelectedRows([]);
    } else {
      const allRowIds = data.invoices.map(invoice => invoice.id);
      setSelectedRows([...allRowIds]);
    }
  };

  const handleSelectRow = (rowId: string) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter(id => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  useUnmovedInvoices(data, selectedRows, setSelectedRows);

  const handleOnOpen = () => {
    const selectedSortedIds = sortedTable.data.filter(x => selectedRows.some(y => y === x.id)).map(x => x.id);
    setCurrentTraversalOrder(selectedSortedIds);
    onClick(selectedSortedIds[0]);
  };

  const handleOnClick = (invoiceId: string) => {
    const traversalIds = getTraversalIds(sortedTable.data, selectedRows, invoiceId);
    setCurrentTraversalOrder(traversalIds, invoiceId);
    onClick(invoiceId);
  };

  const hasInvoices = data.invoices.length > 0;
  const canSelect = selectableRows && hasInvoices;
  const showInvoiceButtons = canSelect && showButtons;

  const stickyTop = useStickyTopValues(selectableRows);

  const tableData = tableSchemaSurveyor(
    data,
    handleOnClick,
    selectableRows,
    toggleExpanded,
    expanded,
    contextMenuOptions
  );

  return (
    <FitToContent>
      <Pane title={title} color="green" padding="0px" margin={paneMargin}>
        <SharedTooltip id="invoice-table-tooltip">
          <FlexWrapper>
            <TableHeaderText>
              <InvoiceSummary data={data} showButtons={canSelect} />
            </TableHeaderText>
            <StyledCheckbox
              name={`${name}-showFullComments`}
              rightLabel="Show full comments"
              selected={showFullComments}
              onChange={() => setShowFullComments(!showFullComments)}
              noTopLabel
              noErrors />
          </FlexWrapper>
          <TableHeaderButtons stickyTop={stickyTop.buttons}>
            {showInvoiceButtons && (
              <InvoiceButtons
                selected={selectedRows.length}
                onOpen={handleOnOpen}
                onSubmit={() => onSubmit ? onSubmit(selectedRows) : null}
                isClaimStatementClosed={isClosed}
              />
            )}
          </TableHeaderButtons>
          {hasInvoices && (
            <ComplexTable layout={tableData.layout}>
              <CollapsibleTableSubheaders
                segments={tableData.subheader ?? []}
                selectableRows={selectableRows}
                stickyTop={stickyTop.subheader} />
              <CollapsibleTableHeaders
                segments={tableData.header}
                sortedHeader={sortedHeader}
                setSortedHeader={handleSortedHeader}
                allItems={data.invoices.length}
                selectedItems={selectedRows.length}
                handleSelectAll={handleSelectAll}
                stickyTop={stickyTop.header}
                selectableRows={selectableRows}
                subrowsHeaderText="Splits"
                showCheckboxBorder
                slimBorder />
              <CollapsibleTableRows
                tableRows={tableData.tableRows}
                minSubrowsToExpand={tableData.minSubrowsToExpand}
                selectedRows={selectedRows}
                onSelect={handleSelectRow}
                selectableRows={tableData.selectableRows}
                showFullComments={showFullComments}
                expandableRows
                canDrillDown />
            </ComplexTable>
          )}
        </SharedTooltip>
      </Pane>
    </FitToContent>
  );
};
