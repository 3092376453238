import { FC } from 'react';
import { formatOrEmpty, formatWithDigitsOrEmpty } from '@/utils/number';
import styled from 'styled-components';
import { SummaryDataWithTooltip } from './types';

const SummaryItemContainer = styled.div`
  box-sizing: border-box;
`;

export const SummaryItemLabel = styled.div`
  font-size: 12px;
  color: ${props => props.theme.marineBlue};
  margin-bottom: 4px;
`;

export const SummaryItemValue = styled.div`
  font-size: 20px;
  line-height: 14px;
  font-weight: bold;
  color: ${props => props.theme.marineBlue};
`;

interface Props {
  item: SummaryDataWithTooltip;
  tooltipId: string;
}
export const SummaryItem: FC<Props> = ({ item, tooltipId }) => (
  <SummaryItemContainer>
    <SummaryItemLabel>{item.title}</SummaryItemLabel>
    <SummaryItemValue
      data-for={item.showTooltip && tooltipId}
      data-tip={formatWithDigitsOrEmpty(item.value)}>
      {item.showRaw ? item.value : formatOrEmpty(item.value)}
    </SummaryItemValue>
  </SummaryItemContainer>
);
