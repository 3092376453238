/* eslint-disable no-alert */
import { FunctionComponent, useCallback } from 'react';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

interface Props {
  message: string;
  when: boolean;
}
/**
 * If provided with a message and when true, this will present a Confirm
 * alert to the user when react-router-dom's blocker triggers to stop the
 * user's navigation.
 * Confirming the modal will proceed, cancelling will stay on the page.
 * From https://github.com/formium/formik/issues/1657#issuecomment-509754221
 */
export const PromptIfDirty: FunctionComponent<Props> = ({ message, when }) => {
  useBlocker(
    useCallback(() => {
      if (!when) return false;
      return !window.confirm(message);
    }, [when, message])
  );
  return null;
};
