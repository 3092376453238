import { Header } from '@/common/modules/Header';
import { StopCircle } from '@instech/icons';
import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  & > div {
    max-width: 600px;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 35%;
  margin: 0;
  text-align: center;
`;

const Title = styled.h2`
  margin: 0px 0px 12px 0px;
  font-size: 60px;
  font-weight: 600;
  color: ${props => props.theme.marineBlue};
`;

const Text = styled.p`
  margin: 0px;
  font-size: 28px;
  font-weight: 400;
  color: ${props => props.theme.marineBlue};
`;

export const NoClaimAccess = () => (
  <Wrapper>
    <StopCircle />
    <Title>No Access</Title>
    <Text>You cannot access this claim.</Text>
  </Wrapper>
);

export const NoClaimAccessPage = () => (
  <div>
    <Header />
    <Layout>
      <NoClaimAccess />
    </Layout>
  </div>
);
