import React from 'react';
import styled from 'styled-components';

const HiddenInput = styled.input`
  display: none;
`;
const PillLabel = styled.label`
  span {
    width: 45px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.marineBlue};
    border: 1px solid ${props => props.theme.marineBlue};
    font-size: 12px;
    cursor: pointer;
  }
  input:checked + span {
    background-color: ${props => props.theme.marineBlue};
    color: #fff;
  }
`;
interface PillRadioProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label: string;
}
export const PillRadio = ({ id, ...props }: PillRadioProps) => (
  <PillLabel htmlFor={id}>
    <HiddenInput type="radio" id={id} {...props} />
    <span>{props.label}</span>
  </PillLabel>
);
