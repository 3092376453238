import React, { useEffect, useState } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Loader } from '@instech/components';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const PdfLoader = () => (
  <LoaderWrapper>
    <Loader />
  </LoaderWrapper>
);

const StyledPage = styled(Page)<{customStyle?: FlattenSimpleInterpolation}>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  & .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
    box-sizing: border-box;
    &:not(last-of-type) {
      padding-bottom: 10px;
    }
  }
  & .react-pdf__Page__canvas canvas {
    width: 100% !important;
    height: 100% !important;
  }
  ${props => props.customStyle}
`;

interface Props {
  src: string;
  pageStyle?: FlattenSimpleInterpolation
}
export const PdfViewer = ({ src, pageStyle }: Props) => {
  const [pagesArray, setPagesArray] = useState<number[]>([]); // array of [1,2,3,...]
  const handlePdfLoad = (pdf: { numPages: number }) => {
    // Creating the array of pages this way, so the page-mapping needs no index tricks
    const arrayFromPages = [...Array(pdf.numPages).keys()].map(int => int + 1);
    setPagesArray(arrayFromPages);
  };

  /*
   * We've experienced issues related to the pdfjs worker when passing the URL (src)
   * directly to the Document component. Related issue: https://github.com/wojtekmaj/react-pdf/issues/1062
   *
   * By fetching the PDF blob ourselves before passing it along to the Document component
   * the issue goes away.
   */

  const [blob, setBlob] = useState<Blob>();

  useEffect(() => {
    void (async () => {
      const response = await fetch(src);

      if (response) {
        const responseBlob = await response.blob();
        setBlob(responseBlob);
      }
    })();
  }, [src]);

  return (
    <Document
      file={blob}
      onLoadSuccess={handlePdfLoad}
      loading={<PdfLoader />}
      noData=""
    >
      {pagesArray.map(page => (
        <StyledPage
          customStyle={pageStyle}
          key={page}
          pageNumber={page}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          width={900}
          loading=""
        />
      ))}
    </Document>
  );
};
