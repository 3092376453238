import { useModalContext, useTypedModal } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import { Form, Formik } from 'formik';
import {
  ButtonGroup,
  Checkbox,
  RadioButtons,
  SlimButton
} from '@instech/components';
import { Indexed, Occurrence } from '@/types';
import styled from 'styled-components';
import { useCallback, useState } from 'react';
import { TransferAllocatedCostRequest } from '@/services/occurrenceServices';
import { RegularText } from '@/common/components/Text';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  transferTo: Yup.string().required('Please select an occurrence to transfer the costs to.'),
  moveToRemainder: Yup.boolean()
});

const FormGroup = styled.div`
  margin: 8px 0;
`;

const Label = styled.p`
  font-weight: bold;
  margin: 0 0 12px;
  color: ${({ theme }) => theme.marineBlue};
`;

const ErrorMessage = styled(RegularText)`
  color: ${({ theme }) => theme.primaryError};
`;

const initialValues = {
  transferTo: '',
  moveToRemainder: false
};
type Props = {
  deleteOccurrence: () => Promise<void>;
  transferAllocations: (request: TransferAllocatedCostRequest) => Promise<void>;
  otherOccurrences: Indexed<Occurrence>[];
}
const ModalContent = ({ transferAllocations, deleteOccurrence, otherOccurrences }: Props) => {
  const options = otherOccurrences
    .map(({ value: occurrence, index }) => ({ label: `Move the allocation to occurrence #${index+1} - ${occurrence.name}.`, id: occurrence.id }));
  const optionLabels = options.map(x => x.label);
  const [error, setError] = useState<string>();

  const { close } = useModalContext();

  const handleSubmit = useCallback(async (data: typeof initialValues) => {
    const occurrenceToReceiveCosts = options.find(x => x.label === data.transferTo)?.id;
    if (occurrenceToReceiveCosts === undefined) {
      return;
    }

    setError(undefined);
    try {
      await transferAllocations({ receivingOccurrenceId: occurrenceToReceiveCosts, deallocateCosts: data.moveToRemainder });
      await deleteOccurrence();
      close();
    } catch {
      setError('Failed to delete the occurrence. Please try again.');
    }
  }, [close, deleteOccurrence, options, transferAllocations]);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} onReset={close} validationSchema={validationSchema}>
      {({ isSubmitting }) => (
        <Form>
          <FormGroup>
            <Label>What should happen to costs allocated to the deleted occurrence?</Label>
            <RadioButtons noLabel name="transferTo" options={optionLabels} />
          </FormGroup>
          <FormGroup>
            <Label>Optional</Label>
            <Checkbox name="moveToRemainder" rightLabel="Move all claimed costs to Remainder." noTopLabel />
          </FormGroup>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <ButtonGroup alignRight>
            <SlimButton variant="secondary" type="reset" disabled={isSubmitting}>CANCEL</SlimButton>
            <SlimButton width="166px" variant="primary" type="submit" disabled={isSubmitting} loading={isSubmitting}>
              DELETE OCCURRENCE
            </SlimButton>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  );
};

const openTransferAndConfirmDeleteOccurrenceModal = (props: Props): ModalType<Props> => ({
  component: ModalContent,
  options: {
    title: 'Delete Occurrence',
    size: 'medium',
    padding: '12px 20px 20px'
  },
  args: props
});

export const useOpenTransferAndConfirmDeleteOccurrenceModal = (args: Props) =>
  useTypedModal(openTransferAndConfirmDeleteOccurrenceModal(args));
