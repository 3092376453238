import { ChevronDown } from '@instech/icons';
import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ toggled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  & svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.marineBlue};
    transform: ${({ toggled }) => toggled && 'rotate(180deg)'};
    transition: transform 300ms ease-out 0s;
  }
`;

interface Props {
  toggled: boolean;
}
export const ToggleIcon: FC<Props> = ({ toggled }) => (
  <Wrapper toggled={toggled}>
    <ChevronDown />
  </Wrapper>
);
