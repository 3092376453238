import * as Yup from 'yup';
import { UserInfo } from '@/types';

// returns true if the UserPicker array contains no valid items
// also returns true if the array is empty or undefined
function noValidUserPickerItems<T extends UserInfo>(array: T[]): boolean {
  return array.every(item => !(item && typeof item === 'object' && item.id));
}

/**
 * Validation schema for selecting multiple users in a UserPickerMulti component.
 * Is valid even if there is only one row containing just an empty string
 * (which signifies that the user has not selected any users).
 */
export const userPickerMulti = (
  message: string = 'At least one user must be selected'
) => Yup.array().min(1, message);

/**
 * Validation schema for selecting multiple users in a UserPickerMulti component.
 * Fires an error if there is not at least one item in the user list that is a valid
 * object, and not just an empty string (which signifies a row with no selection).
 */
export const userPickerMultiRequired = (message: string = 'Required') => Yup.array()
  .test('has-values', message, array => !noValidUserPickerItems(array ?? []));
