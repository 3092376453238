import { ReactElement, ReactNode } from 'react';

export interface ModalContextData {
  open: (component: any) => void;
  close: () => void;
}

// 'large' is the default size of a modal
// 'content' delegates modal size to the component rendered within
export const modalSizes = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  content: 'content'
} as const;

export type ModalSize = typeof modalSizes[keyof typeof modalSizes];

export type ModalOptions = {
  title?: ReactNode;
  size?: ModalSize;
  padding?: string;
  hideCloseButton?: boolean;
}

export interface ModalType<T = any> {
  // allowing null here permits FunctionComponent as type
  component: (props?: any) => JSX.Element | ReactElement | null,
  options: ModalOptions,
  args?: T,
  onCancel?: () => void;
}
