import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ComingSoon as RowingIcon } from '@instech/icons';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
`;

const Title = styled.h2`
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 29px;
  color: ${props => props.theme.marineBlue};
  text-align: center;
`;

const Text = styled.p`
  max-width: 340px;
  margin: 0px;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.marineBlue};
  text-align: center;
`;

interface Props {
  description: string;
}
export const ComingSoon: FunctionComponent<Props> = ({ description }) => (
  <Content>
    <RowingIcon />
    <Title>Coming soon</Title>
    <Text>{description}</Text>
  </Content>
);
