import React from 'react';
import { ModalType } from '@/common/modules/Modal/types';
import { Loader } from '@instech/components';

type Props = {
}

const ModalContent = (props: Props) => <Loader />;

export const generatingAdjustmentPdfModal = (
  props: Props
): ModalType<Props> => ({
  component: ModalContent,
  options: {
    title: 'Generating PDF',
    size: 'medium'
  },
  args: props
});
