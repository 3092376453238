import { convertCurrency } from '@/utils/invoice/convertCurrency';
import { formatNumberWithTwoDecimals, truncateDecimals } from '@/utils/number';

// If (+0/-0) normalize to 0, otherwise return real value
const sanitizeSignedZero = (deviation: number) => {
  if (deviation === 0) return 0;
  return deviation;
};

/**
 * Get deviation in currently selected currency, applying exchange rate if necessary,
 * and return set with numeric value and two-decimal string value.
 */
export const getDeviationInCurrency = (
  deviation: number,
  currency?: string | null,
  selectedCurrency?: string | null,
  exchangeRate?: number | null,
) => {
  const invoiceCurrency = currency === selectedCurrency;

  const deviationNumber = sanitizeSignedZero(
    Number(invoiceCurrency ? deviation : convertCurrency(deviation, exchangeRate))
  );

  const truncateDeviation = truncateDecimals(deviationNumber, 2);
  const deviationString = formatNumberWithTwoDecimals(truncateDeviation);

  return {
    number: deviationNumber,
    string: deviationString
  };
};
