import {
  Invoice,
  InvoiceState,
  Nullable
} from '@/types';
import { textOrDash } from '@/utils/text';
import { invoiceArrayToSingle } from '../../core/utils';

export type CHInvoiceTableDataByCategory = {
  id: string;
  state: InvoiceState;
  category: string;
  exchangeRate: Nullable<number>;
  showReviewComment?: boolean;
  totalAmount: string;
  owner: {
    claimInPolicyCurrency: string;
    ownersWorkInPolicyCurrency: string;
  };
  surveyor: {
    isConsidered: boolean;
    generalExpenses: string;
    claimConsideration: string;
    ownersWorkConsideration: string;
    adjusterToConsider: string;
  };
  adjuster: {
    commonExpenses: string;
    generalAverage: string;
    particularAverage: string;
    ownersWorkConsideration: string;
  };
  subrowCount: number;
  subrows: {
    id: string;
    invoiceNo: string;
    state: InvoiceState;
    category: string;
    exchangeRate: Nullable<number>;
    showReviewComment?: boolean;
    totalAmount: string;
    owner: {
      claimInPolicyCurrency: string;
      ownersWorkInPolicyCurrency: string;
    };
    surveyor: {
      isConsidered: boolean;
      generalExpenses: string;
      claimConsideration: string;
      ownersWorkConsideration: string;
      adjusterToConsider: string;
    };
    adjuster: {
      commonExpenses: string;
      generalAverage: string;
      particularAverage: string;
      ownersWorkConsideration: string;
    };
  }[];
};

interface InvoicesByCategory {
  [name: string]: Invoice[];
}

type ByCategoryTableData = {
  row: Invoice;
  subrows: Invoice[];
}[];

// Take an array of invoices and return an array where 'subrows' is every invoice
// that shares the same category, and 'row' is the sum of those invoices' values
const parseDataToCategoryGrouping = (data: Invoice[]): ByCategoryTableData => {
  const byCategory: InvoicesByCategory = {};
  data.forEach(invoice => {
    // Cast absence of a category to a dash '-' to have a usable key
    const category = invoice.record.category ?? '-';
    if (!byCategory[category]) byCategory[category] = [];
    byCategory[category].push(invoice);
  });

  return Object.values(byCategory).map(invoiceGroup => ({
    row: invoiceArrayToSingle(invoiceGroup),
    subrows: invoiceGroup
  }));
};

/**
 * Using an intermediary function to map Invoice to a table-structured set of data.
 * The table API prefers working with consistent, defined values, so passing over data
 * and addressing potential undefined entries resolves that.
 */
export const getTableDataByCategory = (invoices: Invoice[]): CHInvoiceTableDataByCategory[] => (
  parseDataToCategoryGrouping(invoices).map(({ row, subrows }) => ({
    id: row.id,
    state: row.state,
    category: textOrDash(row.record.category),
    exchangeRate: row.record.exchangeRate,
    totalAmount: textOrDash(row.record.totalAmount),
    owner: {
      claimInPolicyCurrency: textOrDash(row.record.claimInPolicyCurrency),
      ownersWorkInPolicyCurrency: textOrDash(row.record.ownersWorkInPolicyCurrency),
    },
    surveyor: {
      isConsidered: !!row.surveyorsConsideration?.isConsidered,
      generalExpenses: textOrDash(row.surveyorsConsideration?.generalExpenses),
      claimConsideration: textOrDash(row.surveyorsConsideration?.claimConsideration),
      ownersWorkConsideration: textOrDash(row.surveyorsConsideration?.ownersWorkConsideration),
      adjusterToConsider: textOrDash(row.surveyorsConsideration?.adjusterToConsider),
    },
    adjuster: {
      commonExpenses: textOrDash(row.adjustersConsideration?.commonExpenses),
      generalAverage: textOrDash(row.adjustersConsideration.generalAverage),
      particularAverage: textOrDash(row.adjustersConsideration?.particularAverage),
      ownersWorkConsideration: textOrDash(row.adjustersConsideration?.ownersWorkConsideration),
    },
    subrowCount: subrows.length,
    subrows: subrows.map(invoice => ({
      id: invoice.id,
      invoiceNo: `${invoice.record.order} - ${invoice.record.invoiceNumber ?? ''}`,
      state: invoice.state,
      category: textOrDash(invoice.record.category),
      exchangeRate: invoice.record.exchangeRate,
      totalAmount: textOrDash(invoice.record.totalAmount),
      owner: {
        claimInPolicyCurrency: textOrDash(invoice.record.claimInPolicyCurrency),
        ownersWorkInPolicyCurrency: textOrDash(invoice.record.ownersWorkInPolicyCurrency),
      },
      surveyor: {
        isConsidered: !!invoice.surveyorsConsideration?.isConsidered,
        generalExpenses: textOrDash(invoice.surveyorsConsideration?.generalExpenses),
        claimConsideration: textOrDash(invoice.surveyorsConsideration?.claimConsideration),
        ownersWorkConsideration: textOrDash(invoice.surveyorsConsideration?.ownersWorkConsideration),
        adjusterToConsider: textOrDash(invoice.surveyorsConsideration?.adjusterToConsider),
      },
      adjuster: {
        commonExpenses: textOrDash(invoice.adjustersConsideration?.commonExpenses),
        generalAverage: textOrDash(invoice.adjustersConsideration.generalAverage),
        particularAverage: textOrDash(invoice.adjustersConsideration?.particularAverage),
        ownersWorkConsideration: textOrDash(invoice.adjustersConsideration?.ownersWorkConsideration),
      },
    }))
  }))
);
