import { FCWC } from '@/types';
import {
  useEffect, useState, createContext, useMemo, useContext,
} from 'react';

function findIndex(invoiceIds: string[], currentInvoiceId?: string) {
  const idx = currentInvoiceId ? invoiceIds.findIndex(x => x === currentInvoiceId) : 0;
  return idx !== -1 ? idx : 0;
}

/**
 * Returns a traversal list of invoice IDs, from a selection subset or from all invoices
 * in the table, depending on whether the user clicked on an invoice in the selection.
 * Allows user to traverse only selection by clicking on a table row (instead of 'Open' button).
 */
interface TraversableData { id: string; }
export const getTraversalIds = (data: TraversableData[], selection: string[], invoiceId: string) => {
  const clickSelectedRow = selection.some(x => x === invoiceId);
  const traversalIds = (clickSelectedRow && selection)
    ? data.filter(x => selection.some(y => y === x.id)).map(x => x.id)
    : data.map(x => x.id);
  return traversalIds;
};

interface TraversalContextData {
  getInvoicesIds: () => string[],
  setCurrentTraversalOrder: (invoiceId: string[], currentInvoieId?: string) => void;
  getNextInvoiceId: () => string;
  getPreviousInvoiceId: () => string;
}
const TraversalContext = createContext<TraversalContextData | null>(null);

export const TraversalOrderProvider: FCWC = ({ children }) => {
  const [traversalOrder, setTraverseOrder] = useState<string[] | undefined>();
  const [currentPosition, setCurrentPosition] = useState<number | undefined>();

  const sessionStorageOrderKey = 'INVOICE_TRAVERSAL_ORDER';
  const sessionStoragePositionKey = 'INVOICE_TRAVERSAL_POSITION';

  useEffect(() => {
    if (traversalOrder === undefined) {
      const storageOrder = window.sessionStorage.getItem(sessionStorageOrderKey)?.split(',');
      setTraverseOrder(storageOrder ?? []);
    }
  }, [traversalOrder]);

  useEffect(() => {
    if (currentPosition === undefined) {
      const storagePosiion = window.sessionStorage.getItem(sessionStoragePositionKey);
      setCurrentPosition(storagePosiion ? Number(storagePosiion) : 0);
    }
  }, [currentPosition]);

  const getInvoicesIds = () => traversalOrder as string[];

  const setCurrentTraversalOrder = (invoiceIds: string[], currentInvoiceId?: string) => {
    const currentIndex = findIndex(invoiceIds, currentInvoiceId);

    setTraverseOrder(invoiceIds);
    window.sessionStorage.setItem(sessionStorageOrderKey, invoiceIds.toString());

    setCurrentPosition(currentIndex);
    window.sessionStorage.setItem(sessionStoragePositionKey, currentIndex.toString());
  };

  const getNextInvoiceId = () => {
    const newCurrent = currentPosition! < traversalOrder!.length - 1 ? currentPosition! + 1 : 0;

    setCurrentPosition(newCurrent);
    window.sessionStorage.setItem(sessionStoragePositionKey, newCurrent.toString());

    return traversalOrder![newCurrent];
  };

  const getPreviousInvoiceId = () => {
    const newCurrent = currentPosition === 0 ? traversalOrder!.length - 1 : currentPosition! - 1;

    setCurrentPosition(newCurrent);
    window.sessionStorage.setItem(sessionStoragePositionKey, newCurrent.toString());

    return traversalOrder![newCurrent];
  };

  const value = useMemo(() => ({
    getInvoicesIds, setCurrentTraversalOrder, getNextInvoiceId, getPreviousInvoiceId // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [currentPosition, traversalOrder]);

  return (
    <TraversalContext.Provider value={value}>
      {children}
    </TraversalContext.Provider>
  );
};

export const useTraversalOrderContext = () => useContext(TraversalContext) as TraversalContextData;
