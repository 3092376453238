import { BaseTableCell } from '@/common/components/Table/TanstackTable/TableCell';
import { CellContent } from '@/common/components/Table/TanstackTable/core/Components';
import { useRowClick } from '@/common/components/Table/TanstackTable/providers/RowClickContext';
import { getHeaderGroup, getMergedMeta } from '@/common/components/Table/TanstackTable/utils';
import { CellContext } from '@tanstack/react-table';
import { FC } from 'react';
import { ClaimStatementTableData } from '../../getClaimStatementTableData';
import { getLastUpdate } from '../utils';

interface CellProps {
  data: CellContext<ClaimStatementTableData, any>;
  tooltipId?: string;
}
/**
 * Custom cell component for displaying who the last person to edit
 * the corresponding Claim Statement is. Built only for a table where the
 * data consumed by the table is a ClaimStatement.
 */
export const TableCellLastEdited: FC<CellProps> = ({ data, tooltipId }) => {
  const { onRowClick } = useRowClick();
  const mergedMeta = getMergedMeta(data.column);
  const headerGroup = getHeaderGroup(data.column);

  const { original } = data.row;

  return (
    <BaseTableCell
      id={data.cell.id}
      headerGroup={headerGroup}
      cellVariant={mergedMeta.tableCellVariant}
      columnBorder={mergedMeta.columnBorder}
      onClick={onRowClick}>
      <CellContent align={mergedMeta?.align}>
        <span data-for={tooltipId} data-tip={original.lastEditedBy}>
          {getLastUpdate(original.claimStatement)}
        </span>
      </CellContent>
    </BaseTableCell>
  );
};
