import styled, { css } from 'styled-components';
import { EmptyTableHeader, FilterableTableHeader } from '@instech/components';

interface TableHeaderProps {
  slimBorder?: boolean;
}

/**
 * Style override for FilterableTableHeader, so that its minimum size is
 * always wide enough to fit all its content on one line.
 */
export const FilterableTableHeaderMax = styled(FilterableTableHeader) <TableHeaderProps>`
  white-space: nowrap;
  width: max-content;
  box-sizing: border-box;
  // workaround for a strange 1px offset bug
  min-width: calc(100% + 1px);
  ${props => props.slimBorder && css`
    border-bottom-width: 1px;
  `}
  &:last-of-type {
    min-width: 100%;
  }
`;

export const StyledEmptyTableHeader = styled(EmptyTableHeader) <TableHeaderProps>`
  white-space: nowrap;
  width: max-content;
  box-sizing: border-box;
  // workaround for a strange 1px offset bug
  min-width: calc(100% + 1px);
  ${props => props.slimBorder && css`
    border-bottom-width: 1px;
  `}
  &:last-of-type {
    min-width: 100%;
  }
`;
