import { SlimButton } from '@instech/components';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { SelectedInvoices } from '../../core/SelectedInvoices';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px 40px 8px;
`;

interface InvoiceButtonsProps {
  selected: number;
  onOpen: () => void;
  onSubmit: () => void;
  isClaimStatementClosed: boolean;
}
export const InvoiceButtons: FunctionComponent<InvoiceButtonsProps> = ({ selected, onOpen, onSubmit, isClaimStatementClosed }) => {
  const disabled = selected <= 0;
  return (
    <Container>
      <SelectedInvoices selected={selected} />
      <SlimButton variant="secondary" onClick={onOpen} disabled={disabled}>OPEN</SlimButton>
      <SlimButton onClick={onSubmit} disabled={disabled || isClaimStatementClosed}>SUBMIT TO CLAIMS HANDLER</SlimButton>
    </Container>
  );
};

const ButtonWithMargin = styled(SlimButton)`
  margin-bottom: 20px;
`;

interface SubmittedInvoiceButtonsProps {
  canMarkAsComplete: boolean;
  onMarkAsComplete?: () => void;
}
export const SubmittedInvoiceButtons: FunctionComponent<SubmittedInvoiceButtonsProps> = ({ canMarkAsComplete, onMarkAsComplete }) => {
  if (!onMarkAsComplete) return null;

  return (
    <Container>
      <ButtonWithMargin onClick={onMarkAsComplete} disabled={!canMarkAsComplete}>MARK CLAIM STATEMENT AS COMPLETE</ButtonWithMargin>
    </Container>
  );
};
