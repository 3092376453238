import { FCWC } from '@/types';
import {
  createContext, useContext, useMemo
} from 'react';

export type TableOptions = {
  showFullComments?: boolean;
}

const TableOptionsContext = createContext<TableOptions | null>(null);

interface Props {
  options?: TableOptions;
}
/**
 * Provides a global set of options to components within the table.
 *
 * This provider is intended for mutable setting flags that may change frequently during
 * use, such as the ability to toggle comment cells showing the full comments in them.
 *
 * Options made available via this provider should be defined in `TableOptions`, and be
 * optional (so that no options are mandatory for a table to work).
 *
 * This approach is used as an alternative to defining global Tanstack Table `meta` props,
 * because this context-based approach is easier to access in components where accessing
 * Tanstack's meta context can be difficult (ex. injected sub-components).
 */
export const TableOptionsProvider: FCWC<Props> = ({ children, options = {} }) => {
  const value = useMemo(() => (options), [options]);

  return (
    <TableOptionsContext.Provider value={value}>
      {children}
    </TableOptionsContext.Provider>
  );
};

export const useTableOptionsContext = () => useContext(TableOptionsContext) as TableOptions;

/**
 * Returns a readily memoized instance of the provided options
 */
export const useTableOptionsSetup = (options: TableOptions) => useMemo(() => ({
  ...options
}), [options]);
