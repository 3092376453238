import { motion } from 'framer-motion';
import { FCWC } from '@/types';

const collapseVariants = {
  open: {
    height: 'auto',
    overflow: 'visible',
    // wait to switch Overflow until animation has finished
    transition: { overflow: { delay: 0.25 } }
  },
  closed: {
    height: '0px',
    overflow: 'hidden'
  },
};

interface Props {
  startOpen: boolean;
  toggleState: boolean;
}
/**
 * framer-motion wrapper component to animate the showing/hiding of any
 * child components through a vertical collapse.
 *
 * @param startOpen Set if the component should initialize in open state
 * @param toggleState Collapse is open if true, closed if false
 */
export const AnimateCollapse: FCWC<Props> = ({ children, startOpen, toggleState, ...props }) => (
  <motion.div
    variants={collapseVariants}
    initial={startOpen ? 'open' : 'closed'}
    animate={toggleState ? 'open' : 'closed'}
    transition={{ ease: 'easeOut', duration: 0.25 }}
    aria-hidden={toggleState ? 'true' : 'false'}
    {...props}>
    {children}
  </motion.div>
);
