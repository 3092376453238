import { useModalContext } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import {
  CompositeSupportingDocumentId,
  SupportingDocumentForm,
  SupportingDocumentResponse,
  updateSupportingDocument,
  useSupportingDocuments
} from '@/services/supportingDocumentsServices';
import { Form, Formik } from 'formik';
import { attachmentValidationSchema } from '../attachmentValidationSchema';
import { createInitialEditAttachmentValues } from '../utils';
import {
  ActionButtonsGroup, DocumentCategoryDropdown, DocumentNameField, FormContent, PiiDescriptionField, PiiDropdown
} from '../../core/ModalFormComponents';

interface EditAttachmentsProps {
  id: CompositeSupportingDocumentId;
  supportingDocument: SupportingDocumentResponse;
}
const ModalContent = ({ id, supportingDocument }: EditAttachmentsProps) => {
  const { close } = useModalContext();
  const initialValues = createInitialEditAttachmentValues(supportingDocument);
  const { refreshSupportingDocuments } = useSupportingDocuments(id);

  const Update = async (values: SupportingDocumentForm) => {
    await updateSupportingDocument(id, values);
    await refreshSupportingDocuments();
    close();
  };

  return (
    <Formik initialValues={initialValues} validationSchema={attachmentValidationSchema} onSubmit={Update}>
      {({ values, submitForm, isSubmitting }) => (
        <Form>
          <FormContent>
            <DocumentNameField />
            <DocumentCategoryDropdown />
            <PiiDropdown />
            <PiiDescriptionField />
            <ActionButtonsGroup close={close} />
          </FormContent>
        </Form>
      )}
    </Formik>
  );
};

export const editAttachmentModal = (props: EditAttachmentsProps, onCancel: () => void = () => null): ModalType<EditAttachmentsProps> => ({
  component: ModalContent,
  options: {
    title: 'Edit Attachment',
    size: 'medium',
    padding: '12px 24px 24px'
  },
  args: props,
  onCancel
});

export const defaultEditAttachmentsProps: EditAttachmentsProps = {
  id: { claimStatementId: '', invoiceId: '', supportingDocumentId: '' },
  supportingDocument: {} as SupportingDocumentResponse
};
