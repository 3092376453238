import { InvoiceOverview, InvoiceStateMetadata } from '@/types';
import { useEffect, useState } from 'react';
import { useClaimStatement, useIsClosed } from '@/services/claimStatementServices';
import { getClaimsHandlerButtonStates } from '../utils';

/**
 * Based on Invoice data of the table and the states of the invoices that are selected,
 * return a set of flags for which of the table's buttons should be enabled or disabled.
 */
export const useClaimsHandlerDisableButtons = (
  data: InvoiceOverview,
  selectedStates: InvoiceStateMetadata[]
) => {
  const [disableReturn, setDisableReturn] = useState<boolean>(true);
  const [disableShare, setDisableShare] = useState<boolean>(true);
  const [disableShareForReview, setDisableShareForReview] = useState<boolean>(true);
  const [enableRevokeFromReview, setEnableRevokeFromReview] = useState<boolean>(false);
  const [disableFinished, setDisableFinished] = useState<boolean>(true);

  const claimStatementId = data.invoices.at(0)?.claimStatementId;
  const { data: claimStatement } = useClaimStatement(claimStatementId);
  const isClosed = useIsClosed(claimStatement);

  useEffect(() => {
    if (!isClosed) return;

    if (disableReturn) setDisableReturn(true);
    if (disableShare) setDisableShare(true);
    if (disableShareForReview) setDisableShareForReview(true);
    if (disableFinished) setDisableFinished(true);
    if (enableRevokeFromReview) setEnableRevokeFromReview(true);
  }, [disableFinished, disableReturn, disableShare, disableShareForReview, isClosed, enableRevokeFromReview]);

  useEffect(() => {
    if (!data || !data.invoices || isClosed) return;
    const currentSelectedStates = selectedStates;
    // Buttons are auto-disabled if nothing is selected
    if (currentSelectedStates.length === 0) {
      setDisableReturn(true);
      setDisableShare(true);
      setDisableShareForReview(true);
      setEnableRevokeFromReview(false);
      return;
    }

    const buttonStates = getClaimsHandlerButtonStates(currentSelectedStates);

    setDisableReturn(buttonStates.returnToUploader === 'Disabled');
    setDisableShare(buttonStates.share === 'Disabled');
    setDisableFinished(buttonStates.finished === 'Disabled');
    setDisableShareForReview(buttonStates.shareForReview === 'Disabled');
    setEnableRevokeFromReview(buttonStates.enableRevokeFromReview === 'Active');
  }, [data, isClosed, selectedStates]);

  return {
    return: disableReturn,
    share: disableShare,
    shareForReview: disableShareForReview,
    finished: disableFinished,
    revokeFromReview: enableRevokeFromReview
  };
};
