import { StyledTag } from '@/common/components/Tags';
import { LabelValuePair } from '@instech/components';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div<{ right?: boolean, disabled?: boolean }>`
  position: absolute;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.marineBlue};
  top: 0px;
  right: 0px;
  cursor: pointer;
  ${({ disabled }) => disabled && css`
    cursor: initial;
    opacity: 0.25;
  `};
`;

interface Props {
  onClick?: () => void;
  disabled?: boolean;
  selectedTag: LabelValuePair;
  tagTooltipId?: string;
}
export const TagButton: FC<Props> = ({ onClick, disabled, selectedTag, tagTooltipId }) => {
  const emptyTag = !selectedTag.value;
  const tooltipContent = emptyTag ? undefined : selectedTag.label;
  const handleClick = (!disabled && onClick) ? onClick : undefined;
  return (
    <Container right onClick={handleClick} disabled={disabled}>
      <StyledTag
        $filled={!emptyTag}
        data-for={tagTooltipId}
        data-tip={tooltipContent}
        data-testid="open-tags-dropdown-button"
      />
    </Container>
  );
};
