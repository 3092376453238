import React, { useCallback } from 'react';
import { Pane, ToggleControlled } from '@instech/components';
import styled from 'styled-components';
import { useField } from 'formik';

const Content = styled.div`
  display: flex;
  max-width: 420px;
  color: ${({ theme }) => theme.marineBlue};
  justify-content: space-between;
  align-items: center;

  & div {
    flex: 1;
    align-items: end;
  }
`;

export const ClaimStatementSettingsPanel = () => {
  const [, { value }, { setValue }] = useField('claimStatementSettings.hasGeneralAverage');

  const handleToggle = useCallback(async () => {
    await setValue(!value);
  }, [setValue, value]);

  return (
    <Pane title="Claim Settings" color="green" padding="16px" margin="20px 0px 0px">
      <Content>
        <div>General Average</div>
        <ToggleControlled
          data-testid="general-average-toggle"
          name="claimStatementSettings.hasGeneralAverage"
          checked={value}
          onChange={handleToggle}
          rightLabel={value ? 'On' : 'Off'}
        />
      </Content>
    </Pane>
  );
};
