import { Cover } from '@/apps/Adjustment/types/adjustmentCalculations';
import moment from 'moment/moment';

const getPolicyYear = (cover: Cover): number | null => {
  const periodDate = cover.periodFrom.current
    ?? cover.periodTo.current
    ?? cover.periodFrom.initial
    ?? cover.periodTo.initial;

  if (periodDate === null) {
    return null;
  }
  return moment(periodDate).year();
};

const getPolicyTitle = (cover: Cover, index: number) => {
  const titleStart = `Policy Period ${index + 1}`;
  const policyYear = getPolicyYear(cover);

  if (policyYear === null) {
    return titleStart;
  }
  return `${titleStart} - ${policyYear}`;
};

export default getPolicyTitle;
