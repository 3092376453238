import { FunctionComponent } from 'react';
import { CollapsiblePane } from '@instech/components';
import styled from 'styled-components';
import { WarningFilled } from '@instech/icons';
import { FCWC } from '@/types';
import { CollapsiblePaneProps } from './ReadonlyCollapsiblePane';
import { SharedTooltip } from '../Tooltip/SharedTooltip';

const WarningStyledCollapsiblePane = styled(CollapsiblePane)`
  // Set icon opacity to 1
  & div div {
    opacity: 1;
  }
  
  & > div:last-child {
    border-top-color: ${props => props.theme.notification.warnBox};
    background-color: ${props => props.theme.marineBlue20};
  }
`;

// Svg does not render tooltip on hover, so we need to overlay it with a div
const TooltipOverlay = styled.div`
  position: relative;
  top: 0;
  left: 16px;
  width: 16px;
  height: 16px;
  opacity: 0;
  z-index: 1;
`;

interface WarningCollapsiblePaneProps extends CollapsiblePaneProps {
  tooltip?: string;
}

const IconWithTooltip: FunctionComponent<WarningCollapsiblePaneProps> = ({ tooltip }) => (
  <SharedTooltip id="warning-pane">
    <TooltipOverlay data-for="warning-pane" data-tip={tooltip} />
    <WarningFilled />
  </SharedTooltip>
);

export const WarningCollapsiblePane: FCWC<WarningCollapsiblePaneProps> = (
  { icon, tooltip, children, ...paneProps }
) => (
  <WarningStyledCollapsiblePane icon={<IconWithTooltip tooltip={tooltip} />} {...paneProps}>
    {children}
  </WarningStyledCollapsiblePane>

);
