import { FunctionComponent as FC } from 'react';
import { SurveyorsConsiderationSplit } from '@/types';
import { DeletedBySurveyorConsideration, OutdatedConsideration }
  from '@/components/pages/invoice/core/ReadOnlyInvoice/SurveyorsConsiderationPane';
import { DarkSingleReadonlySurveyorsConsiderationPane }
  from '@/components/pages/invoice/core/ReadOnlyInvoice/SurveyorsConsiderationPane/SurveyorsConsiderationReadonly';

interface ReadonlySurveyorItemProps {
  viewAsListOfSplits?: boolean;
  isOutdated?: boolean;
  item: SurveyorsConsiderationSplit | null;
  deletedByAdjuster?: boolean;
  deletedBySurveyor?: boolean;
}

export const ReadonlySurveyorItem: FC<ReadonlySurveyorItemProps> = ({
  viewAsListOfSplits,
  isOutdated,
  item,
  deletedByAdjuster,
  deletedBySurveyor
}) => {
  if (!viewAsListOfSplits) return null;

  if (deletedBySurveyor && !deletedByAdjuster) {
    return <DeletedBySurveyorConsideration />;
  }

  // NB: Deliberate double null escape; conditionals for deletedBy* will be
  // met while item is null, so this escape needs to be after the deleted check.
  if (!item) return null;

  if (isOutdated) {
    return <OutdatedConsideration record={item} />;
  }

  return <DarkSingleReadonlySurveyorsConsiderationPane record={item} />;
};
