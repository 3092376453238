import { ModalType } from '@/common/modules/Modal/types';
import { InfoCircle } from '@instech/icons';
import styled from 'styled-components';

const Text = styled.div`
  color: ${props => props.theme.marineBlue};
`;

const TitleContent = styled.span`
  display: flex;
  align-items: flex-end;
  font-weight: normal;

  svg {
    margin-right: 12px;
    height: 20px;
    width: 20px;
  }
  g {
    stroke-width: 2px;
  }
`;

const Title = () => (
  <TitleContent>
    <InfoCircle />
    PII Description
  </TitleContent>
);

const ModalContent = () => (
  <Text>
    <p>Describe where in the document you will find the Personal Identification Information (PII).</p>
    <p>For example page number(s).</p>
  </Text>
);

export const piiDescriptionModal = (): ModalType => ({
  component: ModalContent,
  options: {
    title: <Title />,
    size: 'small',
    padding: '12px 20px 20px'
  },
});
