import { useEffect, useState } from 'react';

/**
 * Takes the #id of a DOM element (ex <div id="example" />) and
 * returns a reference to that element if it exists.
 *
 * For use with React.createPortal(children, container), where the
 * element returned from this hook is provided as the container.
 */
export const useAnchor = (id: string) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement>();

  useEffect(() => {
    const anchor = document.getElementById(id);
    if (!anchorElement && anchor) {
      setAnchorElement(anchor);
    }
  }, [id, anchorElement]);

  return anchorElement;
};
