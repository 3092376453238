import React, { useState } from 'react';
import { ButtonGroup, SlimButton } from '@instech/components';
import { WarningFilled } from '@instech/icons';
import { ModalType } from '@/common/modules/Modal/types';
import { useModalContext } from '@/common/modules/Modal';
import { ButtonGroupModal } from '@/common/components/ButtonGroup/ButtonGroupModal';
import styled from 'styled-components';
import {
  BoldText, EmphasizedText, RegularText, TextWrapper
} from './core/Components';

const WarningIcon = styled(WarningFilled)`
  margin-bottom: 12px;
  height: 52px;
  width: 55px;
`;

interface TransferSurveyorToAdjusterModalProps {
  numberOfCompleteInvoices: number;
  numberOfIncompleteInvoices: number;
  transfer: () => Promise<void>;
}

const getHeaderText = (numberOfCompleteInvoices: number, numberOfIncompleteInvoices: number) => {
  if (numberOfCompleteInvoices > 1) {
    return `${numberOfCompleteInvoices} invoices will be submitted to Claims Handler.`;
  }
  if (numberOfCompleteInvoices === 1 && numberOfIncompleteInvoices > 0) {
    return `${numberOfCompleteInvoices} invoice will be submitted to Claims Handler.`;
  }
  return 'This invoice will be submitted to Claims Handler.';
};

const getText = () => 'Submitted invoices will be moved to your \'Submitted to Claims Handler\' table. You will not be able to edit them after submitting.';

const getIncompleteText = (numberOfIncompleteInvoices: number) => {
  if (numberOfIncompleteInvoices > 1) {
    return `${numberOfIncompleteInvoices} invoices are incomplete and will not be submitted.`;
  }
  return `${numberOfIncompleteInvoices} invoice is incomplete and will not be submitted.`;
};

const getAllInvoicesIncompleteHeaderText = (numberOfIncompleteInvoices: number) => {
  if (numberOfIncompleteInvoices > 1) {
    return 'These invoices are incomplete.';
  }
  return 'This invoice is incomplete.';
};

const ModalContent = ({ numberOfCompleteInvoices, numberOfIncompleteInvoices, transfer }: TransferSurveyorToAdjusterModalProps) => {
  const { close } = useModalContext();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const handleSubmit = async () => {
    setSubmitting(true);
    await transfer();
    close();
  };

  if (numberOfIncompleteInvoices > 0 && numberOfCompleteInvoices === 0) {
    // No invoices will be submitted
    return (
      <div>
        <TextWrapper>
          <WarningIcon />
          <BoldText>{getAllInvoicesIncompleteHeaderText(numberOfIncompleteInvoices)}</BoldText>
          <RegularText>Please enter the required information before submitting.</RegularText>
        </TextWrapper>
        <ButtonGroup alignRight>
          <SlimButton padding="0 4px" onClick={close}>OK</SlimButton>
        </ButtonGroup>
      </div>
    );
  }

  return (
    <div>
      <TextWrapper>
        <BoldText>{getHeaderText(numberOfCompleteInvoices, numberOfIncompleteInvoices)}</BoldText>
        <RegularText>{getText()}</RegularText>
        {numberOfIncompleteInvoices > 0 && (
          <EmphasizedText>
            <WarningFilled />
            {getIncompleteText(numberOfIncompleteInvoices)}
          </EmphasizedText>
        )}
      </TextWrapper>
      <ButtonGroupModal alignRight>
        <SlimButton onClick={close} variant="secondary">
          CANCEL
        </SlimButton>
        <SlimButton onClick={handleSubmit} width="186px" disabled={submitting} loading={submitting}>
          SUBMIT TO CLAIMS HANDLER
        </SlimButton>
      </ButtonGroupModal>
    </div>
  );
};

export const transferSurveyorToAdjusterModal = (
  { numberOfCompleteInvoices, numberOfIncompleteInvoices, transfer }: TransferSurveyorToAdjusterModalProps
): ModalType<TransferSurveyorToAdjusterModalProps> => ({
  component: ModalContent,
  options: {
    title: 'Submit to Claims Handler',
    size: 'small',
    padding: '20px'
  },
  args: { numberOfCompleteInvoices, numberOfIncompleteInvoices, transfer }
});
