import { ClaimsHandlerInvoiceSummary, UserRoles } from '@/types';
import { roles } from '@/utils/constants';

const surveyorsAllocationFields = (surveyor: ClaimsHandlerInvoiceSummary['surveyor']) => [
  { title: 'Invoices', value: `${surveyor.invoicesNumerator}/${surveyor.invoicesDenominator}`, showTooltip: false, showRaw: true },
  { title: 'Sum', value: surveyor.sum, showTooltip: true },
  { title: 'Claim', value: surveyor.claim, showTooltip: true },
  { title: 'Owner', value: surveyor.ownersWork, showTooltip: true },
  { title: 'General Expenses', value: surveyor.generalExpenses, showTooltip: true },
  { title: 'Adjuster to Consider', value: surveyor.adjusterToConsider, showTooltip: true },
];

const ownerClaimFields = (owner: ClaimsHandlerInvoiceSummary['owner'], userRole?: UserRoles) => [
  {
    title: (userRole === roles.Owner ? 'Submitted Invoices' : 'Invoices'),
    value: `${owner.invoicesNumerator}/${owner.invoicesDenominator}`,
    showTooltip: false,
    showRaw: true
  },
  {
    title: (userRole === roles.Owner ? 'Total Amount' : 'Sum'),
    value: owner.sum,
    showTooltip: true
  },
  { title: 'Claim', value: owner.claim, showTooltip: true },
  {
    title: (userRole === roles.Owner ? 'Owner’s Work' : 'Owner'),
    value: owner.ownersWork,
    showTooltip: true
  },
  { title: 'Not Allocated', value: owner.notAllocated, showTooltip: true },
];

export const adjusterAllocationFieldsForSummary = (adjuster: ClaimsHandlerInvoiceSummary['adjuster'], showGeneralAverage: boolean = false) => [
  { title: 'Total Amount', value: adjuster.sum, showTooltip: true },
  { title: 'General Average', value: adjuster.generalAverage, showTooltip: true, hideData: !showGeneralAverage },
  { title: 'Particular Average', value: adjuster.particularAverage, showTooltip: true },
  { title: 'Remainder', value: adjuster.totalOwnersWork, showTooltip: true },
  { title: 'Common Expenses', value: adjuster.commonExpenses, showTooltip: true },
];

export const adjusterAllocationFieldsForReview = (adjuster: ClaimsHandlerInvoiceSummary['adjuster'], showGeneralAverage: boolean = false) => [
  { title: 'Total Amount', value: adjuster.sum, showTooltip: true },
  { title: 'Particular Average', value: adjuster.particularAverage, showTooltip: true },
  { title: 'Remainder', value: adjuster.totalOwnersWork, showTooltip: true },
  { title: 'Common Expenses', value: adjuster.commonExpenses, showTooltip: true },
  { title: 'General Average', value: adjuster.generalAverage, showTooltip: true, hideData: !showGeneralAverage },
];

export const summaryContent = (summary: ClaimsHandlerInvoiceSummary, userRole?: UserRoles, showGeneralAverage: boolean = false) => ({
  subTitle: undefined,
  ownersClaim: {
    title: "Owner's Claim - Submitted",
    fields: ownerClaimFields(summary.owner, userRole)
  },
  surveyorsAllocation: {
    title: "Surveyor's Allocation of Cost - Submitted",
    fields: surveyorsAllocationFields(summary.surveyor)
  },
  adjustersAllocation: {
    title: "Adjuster's Allocation of Cost - Considered",
    fields: [
      {
        title: 'Finished Invoices',
        value: `${summary.adjuster.invoicesNumerator}/${summary.adjuster.invoicesDenominator}`,
        showTooltip: false,
        showRaw: true
      },
      ...adjusterAllocationFieldsForSummary(summary.adjuster, showGeneralAverage)
    ]
  }
});

export const summaryReviewContent =
  (summary: ClaimsHandlerInvoiceSummary, userRole?: UserRoles, showGeneralAverage: boolean = false, showSubTitle: boolean = true) => ({
    subTitle: (showSubTitle ? 'Note: All figures pending final adjustment' : ''),
    ownersClaim: {
      title: "Owner's Claim",
      fields: ownerClaimFields(summary.owner, userRole)
    },
    surveyorsAllocation: {
      title: "Surveyor's Allocation of Cost",
      fields: surveyorsAllocationFields(summary.surveyor)
    },
    adjustersAllocation: {
      title: "Adjuster's Allocation of Cost",
      fields: [
        {
          title: 'Shared for Review',
          value: `${summary.adjuster.invoicesNumerator}/${summary.adjuster.invoicesDenominator}`,
          showTooltip: false,
          showRaw: true
        },
        ...adjusterAllocationFieldsForReview(summary.adjuster, showGeneralAverage)
      ]
    }
  });
