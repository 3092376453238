import { ClaimStatementInvoiceId, Guid } from '@/types';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const useRequireClaimStatementId = (fallbackUrl: string = '/'): Guid => {
  const navigate = useNavigate();
  const { claimStatementId } = useParams();

  useEffect(() => {
    if (!claimStatementId) {
      navigate(fallbackUrl);
    }
  }, [claimStatementId, fallbackUrl, navigate]);

  return claimStatementId as Guid;
};

export const useRequireClaimStatementInvoiceId = (): ClaimStatementInvoiceId => {
  const { claimStatementId, invoiceId } = useParams();
  return { claimStatementId: claimStatementId || '' as Guid, invoiceId: invoiceId || '' as Guid };
};
