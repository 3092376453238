import { arrayAddOrRemove } from '@/utils/array';
import { theme } from '@/utils/style/theme';
import { Table } from '@tanstack/react-table';
import { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { CollapsibleHeaderCell } from './CollapsibleHeaderCell';
import { StyledHeaderRow, StyledTableHeader } from './Components';
import { TableHeaderCell } from './TableHeaderCell';

const createCollapseCSS = (collapsed: string[]) => {
  let styles = '';
  collapsed.forEach(group => {
    styles += `
    & th[data-header-group="${group}"] {
      padding: 0px;
      width: 0px;
      overflow: hidden;
      & > * {
        display: none;
      }
    }
    & ~ tbody td[data-header-group="${group}"] {
      padding: 0px;
      width: 0px;
      min-width: 0px;
      overflow: hidden;
      background-color: ${theme.marineBlue20} !important;
      & > * {
        display: none;
      }
    }
    `;
  });
  return css`${styles}`;
};

const StyledCollapsibleTableHeader = styled(StyledTableHeader) <{ collapsedGroups: string[] }>`
  ${props => createCollapseCSS(props.collapsedGroups)}
`;

interface StyledCellProps {
  border?: 'left' | 'right';
}
const TableHeaderCellBorder = styled(TableHeaderCell) <StyledCellProps>`
  ${props => props.border && css`
    border-${props.border}: 1px solid ${props.theme.marineBlue};
  `};
`;

interface Props {
  table: Table<any>
}
/**
 * Renders a row of table header elements.
 *
 * If the provided Table has Headers nested under a HeaderGroup, the first-level
 * group of headers will be rendered as a row of collapsible headers with their
 * accompanying child headers on the row below.
 *
 * ### Rendering borders on column cells
 *
 * The collapsible header element will automatically render borders on its left side.
 * To also render borders on the column's header and the cells within the column,
 * set the `meta.border` property on the columns in question (likely first column
 * in each GroupColumn). Doing this manually is quicker and more efficient right now
 * than to try and automatically propagate the border setting based on grouping.
 */
export const TableHeaderCollapsible: FC<Props> = ({ table }) => {
  const [collapsedGroups, setCollapsedGroups] = useState<string[]>([]);
  const [collapsibleHeaders, tableHeaders] = table.getHeaderGroups();

  const handleCollapse = (range: string) => {
    const newRanges = arrayAddOrRemove(collapsedGroups, range);
    setCollapsedGroups([...newRanges]);
  };

  return (
    <StyledCollapsibleTableHeader collapsedGroups={collapsedGroups}>
      <StyledHeaderRow>
        {collapsibleHeaders.headers.map(header => (
          <CollapsibleHeaderCell
            key={header.id}
            header={header}
            isCollapsed={collapsedGroups.includes(header.column.id)}
            onClick={handleCollapse}
          />
        ))}
      </StyledHeaderRow>
      <StyledHeaderRow>
        {tableHeaders.headers.map(header => (
          <TableHeaderCellBorder
            key={header.id}
            border={header.column.columnDef.meta?.columnBorder}
            borderWidth="4px"
            header={header} />
        ))}
      </StyledHeaderRow>
    </StyledCollapsibleTableHeader>
  );
};
