import React from 'react';
import styled from 'styled-components';
import { ButtonGroup, SlimButton } from '@instech/components';
import { useModalContext } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import { usePromptContext } from '@/common/components/Form/PromptUnsavedChanges';
import { delay } from '@/utils/delay';

const MessageText = styled.div`
  padding: 32px 0px 100px;
  text-align: center;
  color: ${props => props.theme.marineBlue};
  & > p {
    margin: 0px;
  }
`;

const BoldText = styled.div`
  font-weight: bold;
  margin-bottom: 20px;
`;

interface CancelProps {
  cancelCallback: () => void;
}

const ModalContent = ({ cancelCallback: callback }: CancelProps) => {
  const { close } = useModalContext();
  const { skipPrompt } = usePromptContext();

  const handleCancel = async () => {
    skipPrompt();
    await delay(0); // Force delay to let prompt skip state finish
    close();
    callback();
  };

  return (
    <div>
      <MessageText>
        <BoldText>Do you want to cancel changes?</BoldText>
        <p>Your work will not be saved.</p>
      </MessageText>
      <ButtonGroup alignRight>
        <SlimButton variant="secondary" onClick={close}>CONTINUE EDITING CLAIM</SlimButton>
        <SlimButton onClick={handleCancel}>CANCEL CHANGES</SlimButton>
      </ButtonGroup>
    </div>
  );
};

export const cancelModal = ({ cancelCallback }: CancelProps): ModalType<CancelProps> => ({
  component: ModalContent,
  options: {
    title: 'Cancel',
    size: 'small'
  },
  args: {
    cancelCallback
  }
});
