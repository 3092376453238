import { FC, TableHTMLAttributes } from 'react';
import styled from 'styled-components';
import { TableOptions, TableOptionsProvider } from './providers/TableOptionsContext';

const TableScroll = styled.div`
  position: relative;
  max-height: max(70vh, 400px);
  overflow: auto;
  z-index: 0;
`;

const StyledTable = styled.table`
  height: 100%;
  width: max-content;
  min-width: 100%;
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.marineBlue};
  tbody {
    height: 100%;
  }
`;

interface Props extends TableHTMLAttributes<HTMLTableElement> {
  options?: TableOptions;
}
/**
 * Root element for Tanstack Table
 *
 * @prop options: Takes array of global option flags, that can be consumed by
 * cells within the table. See `<TableOptionsProvider />` for more information.
 */
export const TanstackTable: FC<Props> = ({ children, options, ...props }) => (
  <TableScroll>
    <TableOptionsProvider options={options}>
      <StyledTable cellSpacing={0} {...props}>
        {children}
      </StyledTable>
    </TableOptionsProvider>
  </TableScroll>
);
