import React, { FC, useState } from 'react';
import { Pane } from '@instech/components';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { dispatchAutosaveStartedEvent, dispatchAutosaveFinishedEvent } from '@/common/components/Autosave';
import GeneralAverageInput from './GeneralAverageInput';

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.primaryError};
  margin: 8px 0 0 0;
`;

type ClaimStatementSettingsForm = {
  hasGeneralAverage: boolean;
};

interface Props {
  generalAverage?: {
    inUse: boolean;
    isEnabled: boolean;
  };
  onChange: (claimStatementSettings: ClaimStatementSettingsForm) => Promise<void>;
}
const defaultGeneralAverage: Props['generalAverage'] = {
  inUse: false,
  isEnabled: false
};
export const ClaimStatementSettingsPanel: FC<Props> = ({ generalAverage = defaultGeneralAverage, onChange }: Props) => {
  const initialValues: ClaimStatementSettingsForm = { hasGeneralAverage: generalAverage.isEnabled };
  const [error, setError] = useState('');

  const handleSubmit = async (data: ClaimStatementSettingsForm) => {
    setError('');
    dispatchAutosaveStartedEvent();
    try {
      await onChange(data);
    } catch (e) {
      setError('Something went wrong when saving. Please try again later.');
      throw e;
    } finally {
      // If the error is thrown, the value will be reverted inside the General Average Input
      dispatchAutosaveFinishedEvent();
    }
  };

  return (
    <Pane title="Claim Settings" color="green" padding="16px" margin="20px 0px 0px">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <GeneralAverageInput generalAverageInUse={generalAverage.inUse} />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Form>
      </Formik>
    </Pane>
  );
};
