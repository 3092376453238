import useSWR from 'swr';

export type InvoiceRecognition = {
  claimStatementId: string;
  invoiceId: string;
  totalAmount?: {
    amount: number;
    currencyCode: string;
  };
  paymentDate?: string;
  supplier?: string;
  invoiceNumber?: string;
}

export const useInvoiceRecognition = (claimStatementId: string, invoiceId: string) => {
  const { data, error } = useSWR<InvoiceRecognition>(`claimstatements/${claimStatementId}/invoices/${invoiceId}/invoicerecognitions/values`);

  return {
    data,
    error
  };
};
