import { DefaultTheme } from 'styled-components';
import { baseTheme } from '@instech/components';

/**
 * Local application constants for Styled Components theme.
 * Add typing for properties provided here in /src/styled.d.ts
 */
export const theme: DefaultTheme = {
  ...baseTheme,
  phldrBlue1: '#1C3775', /* this is a placeholder name */
  marineBlue30: '#B2C4D4',
  marineBlue70: '#4C769B',
  lightFlatWhite: '#F0F4F7',
  lightFlatRed: '#F9C2B5',
  primaryError: '#880D18',
  table: {
    first: {
      even: '#F1F3F6',
      odd: '#FFFFFF',
      greenEven: '#CBE6DD',
      greenOdd: '#D5EEE3'
    },
    second: {
      even: '#E3E8EE',
      odd: '#F1F3F6',
      greenEven: '#C1DED7',
      greenOdd: '#CBE6DD',
    },
    third: {
      even: '#D4DDE6',
      odd: '#E3E8EE',
      greenEven: '#B7D6D1',
      greenOdd: '#C1DED7'
    },
    empty: {
      even: '#EFDCDA',
      odd: '#FDE7E2',
    }
  },
  adjuster: {
    deleted: '#E8E9EA'
  },
  boxShadow: {
    ...baseTheme.boxShadow,
    container: '0px 2px 6px 0px rgba(0, 60, 113, 0.25)',
    header: '0px 2px 12px 0px rgba(0, 60, 113, 0.35)',
  }
};
