import { InternalNotes } from '@/apps/Adjustment/components/InternalNotes';
import { useAdjustmentOverview } from '@/apps/Adjustment/services/adjustmentOverviewService';
import { UpdateAdjustmentOverviewRequest } from '@/apps/Adjustment/types/adjustmentOverview';
import { useRequireClaimStatementId } from '@/utils/useRequireClaimStatementId';
import { Loader } from '@instech/components';
import { FC } from 'react';
import styled from 'styled-components';
import { HelpTextPane } from './core/HelpTextPane';
import { AdjustersNotePane } from './core/AdjustersNotePane';

const Layout = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 32px;
  box-sizing: border-box;
`;

interface Props { }
export const AdjustersNote: FC<Props> = () => {
  const claimStatementId = useRequireClaimStatementId();
  const { data: adjustmentOverview, mutate, updateAdjustmentOverview } =
    useAdjustmentOverview(claimStatementId);

  if (!adjustmentOverview) {
    return <Loader />;
  }

  const handleSave = async (request: UpdateAdjustmentOverviewRequest) => {
    const result = await updateAdjustmentOverview(
      adjustmentOverview.id,
      request
    );
    await mutate(result.data);
  };

  const saveAdjustersNote = async (form: { internalNote: string }) => {
    const request = { adjustersNoteInternalNote: { value: form.internalNote } };
    await handleSave(request);
  };

  return (
    <Layout>
      <div>
        <AdjustersNotePane
          adjustersNote={adjustmentOverview.adjustersNote.note}
          save={handleSave}
        />
        <InternalNotes
          fieldName="internalNote"
          value={adjustmentOverview.adjustersNote.internalNote}
          onSave={saveAdjustersNote}
        />
      </div>
      <HelpTextPane />
    </Layout>
  );
};
