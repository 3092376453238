import { CellContext } from '@tanstack/react-table';
import { FC } from 'react';
import { StyledTableCheckbox } from '../core/Components';
import { BaseButtonCell } from './BaseButtonCell';

interface Props {
  data: CellContext<any, any>;
}
export const TableButtonCheckbox: FC<Props> = ({ data }) => (
  <BaseButtonCell data={data}>
    <StyledTableCheckbox
      name={`table-checkbox-${data.row.id}`}
      selected={!!data.row.getIsSelected()}
      onChange={data.row.getToggleSelectedHandler()}
      noErrors
      noTopLabel />
  </BaseButtonCell>
);
