import { convertCurrency } from '@/utils/invoice/convertCurrency';
import { HighlightedTextFieldControlled } from '@instech/components';
import { useField } from 'formik';
import React from 'react';
import { NumberInput } from '@/common/components/Form/NumberInput';

interface HighlightedNumericalFieldProps {
  name: string;
  label: string;
  initialFieldName: string;
  isConsideredFieldName: string;
  disabled?: boolean;
  convertRate?: number | null;
  emptyWithoutConvertRate?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const HighlightedNumericalField = ({
  name,
  label,
  initialFieldName,
  disabled = false,
  convertRate,
  emptyWithoutConvertRate,
  isConsideredFieldName,
  onBlur,
}: HighlightedNumericalFieldProps) => {
  const [field, { touched: fieldTouched }, helpers] = useField(name);
  const [initialField] = useField(initialFieldName);
  const [isConsideredValue, , consideredFieldHelpers] = useField<boolean>(isConsideredFieldName);

  // Extract to variable so it can be altered and converted
  let fieldVal = field.value;
  const highlight = !isConsideredValue.value && !fieldTouched && !!initialField.value;
  // applies conversion rate to displayed value when displayed disabled
  if (convertRate) {
    fieldVal = convertCurrency(fieldVal, convertRate, true);
  }

  // allows field to show empty when it cannot convert rate
  if (!convertRate && emptyWithoutConvertRate) {
    fieldVal = '';
  }

  const handleFocus = async () => {
    if (convertRate) return;
    if (!isConsideredValue.value && field.value !== '') {
      await consideredFieldHelpers.setValue(true);
    }
    if (highlight) {
      await helpers.setTouched(true);
    }
  };

  const handleChange = (value: number | undefined) => {
    if (disabled) return;
    void helpers.setValue(value);
    void consideredFieldHelpers.setValue(true);
    void helpers.setTouched(true);
  };

  return (
    <NumberInput
      name={name}
      label={label}
      $highlight={highlight}
      value={fieldVal}
      placeholder=""
      onFocus={handleFocus}
      disabled={disabled}
      onBlur={onBlur}
      customInput={HighlightedTextFieldControlled}
      thousandSeparator
      decimalScale={2}
      onValueChange={evt => handleChange(evt.floatValue)}
      setValue={helpers.setValue}
    />
  );
};
