import { useParams } from 'react-router-dom';
import { PageContent } from '@/common/layout/Page';
import React from 'react';
import styled from 'styled-components';
import { useDownloadData } from '@/services/invoice/utils/downloadHelper';
import { MediaType } from '@/services/invoice/utils/getFile';
import { LinkButton, PageLoader } from '@instech/components';
import { MagicLinkNoAccessView } from '@/components/pages/error/MagicLinkNoAccessView';

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  & > div {
    max-width: 885px;
  }
`;

const Download = styled.div`
  background-color:  ${props => props.theme.whiteBlue};
`;

const Header = styled.div`
  font-weight: 700;
  font-size: 60px;
  color: ${props => props.theme.marineBlue};
  text-align: center;
`;

const Body = styled.div`
  margin-top: 40px;
  font-weight: 400;
  font-size: 28px;
  color: ${props => props.theme.marineBlue};
  text-align: center;
  a {
    text-decoration: underline;
  }
`;

const TextButton = styled(LinkButton)`
  div {
    span {
      font-weight: 400;
      font-size: 28px;
      color: ${props => props.theme.marineBlue};
      text-decoration: underline;
    }
  }
`;

export const SharedDownloadInProgressPage = () => {
  const { accessKey } = useParams();
  const directDownloadLink = `claimstatementdownload/${accessKey}`;

  const { downloadStatus, triggerDownload } = useDownloadData(directDownloadLink, MediaType.Zip);
  if (downloadStatus == null) return <PageLoader />;
  if (downloadStatus === 'error') return <MagicLinkNoAccessView />;

  return (
    <PageContent>
      <Layout>
        <Download>
          <Header>Claim Statement</Header>
          <Body>
            <div>All files in the Claim Statement will be downloaded in one zip file.</div>
            <div>The download should start automatically.</div>
            <div>
              If it doesn&apos;t start,
              <TextButton onClick={triggerDownload}>click this link.</TextButton>
            </div>
          </Body>
        </Download>
      </Layout>
    </PageContent>
  );
};
