import { FC, Suspense } from 'react';
import {
  Navigate, Route, Routes
} from 'react-router-dom';
import { PageLoader } from '@instech/components';
import {
  AdjustersNote,
  ClaimStatementInformation,
  InsuranceConditions,
  SummaryOfFactsAndDates,
  AdjustedClaim
} from '@/apps/Adjustment/pages/AdjustmentPage/chapters';
import { chapterSidebarList } from './chapterSidebarList';

interface Props { }
/**
 * Handles routing from /adjustment/:id/ root to various chapter pages nested
 * underneath. If the user hits this router without a specific chapter to
 * navigate to, they should be redirected to the first chapter.
 */
export const ChapterRouting: FC<Props> = () => {
  const firstChapterPath = chapterSidebarList[0].segments[0].path;

  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        {firstChapterPath && (
          // redirect to first chapter if user navigates to the '/' root path
          <Route path="/" element={<Navigate to={`./${firstChapterPath}`} />} />
        )}
        <Route path="/overview/*">
          <Route path="claim-statement-information" element={<ClaimStatementInformation />} />
          <Route path="insurance-conditions" element={<InsuranceConditions />} />
          <Route path="summary-facts" element={<SummaryOfFactsAndDates />} />
          <Route path="adjusters-note" element={<AdjustersNote />} />
          <Route path="adjusted-claim" element={<AdjustedClaim />} />
          <Route path="*" element={<Navigate to="/adjustment" />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
