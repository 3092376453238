import React, { FunctionComponent } from 'react';
import { Invoice } from '@/types';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { MultipleReadonlyConsideration } from './AdjustersConsiderationReadonly';
import { SingleReadonlyAdjustersConsiderationPane } from './AdjustersConsiderationReadonly/SingleReadonlyAdjustersConsiderationPane';
import { SurveyorSplitsProvider } from '../../EditInvoice/InvoiceForm/utils/SurveyorSplitsContext';

interface Props {
  invoice: Invoice;
  redactSurveyorSplits?: boolean;
}
export const AdjustersConsiderationPane: FunctionComponent<Props> = ({ invoice, redactSurveyorSplits = false }) => {
  const { adjustersConsideration: consideration, record: invoiceRecord, surveyorsConsideration } = invoice;

  const showMultiple = consideration.splits?.length > 1;
  const surveyorSplits = redactSurveyorSplits ? [] : surveyorsConsideration?.splits;

  const tooltipId = 'readonly-adjuster-consideration-tags';

  if (showMultiple) {
    return (
      <SharedTooltip id={tooltipId}>
        <SurveyorSplitsProvider surveyorSplits={surveyorSplits}>
          <MultipleReadonlyConsideration
            record={invoiceRecord}
            splits={consideration.splits}
            redactSurveyorSplits={redactSurveyorSplits} />
        </SurveyorSplitsProvider>
      </SharedTooltip>
    );
  }

  return (
    <SharedTooltip id={tooltipId}>
      <SingleReadonlyAdjustersConsiderationPane
        record={consideration.splits[0]}
        invoiceRecord={invoice.record} />
    </SharedTooltip>
  );
};
