export type Breadcrumb = {
  label: string;
  to: string;
}

const myDashboardStep: Breadcrumb = {
  label: 'My dashboard',
  to: '/'
};

export const reusableSteps = {
  myDashboardStep
};

export const breadcrumbVesselName = (name: string, isExperimental?: boolean) => {
  const testSuffix = isExperimental ? ' - TEST' : '';
  return `${name}${testSuffix}`;
};
