import {
  useState, useEffect, MutableRefObject
} from 'react';

export const useClickOutside = (ref: MutableRefObject<HTMLElement | null>) => {
  const [isClicked, setIsClicked] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (ref?.current && !ref.current.contains(event.target as Node)) {
        setIsClicked(true);
      } else {
        setIsClicked(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
  return isClicked;
};
