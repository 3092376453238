import styled from 'styled-components';

export const HelpTextLayout = styled.div`
  max-width: 500px;
  color: ${({ theme }) => theme.marineBlue};
  line-height: 26px;
  font-size: 16px;
  font-weight: 400;
  & > p:first-child {
    margin-top: 0px;
  }
  & > p:last-child {
    margin-bottom: 0px;
  }
`;
