import { ModalContentLarge, ModalContentSmall } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import { ClaimStatementInvoiceId } from '@/types';
import { validateFiletypeAndSize, supportingDocumentFileTypes } from '@/utils/file';
import { InvalidFileError } from '../../../core/Modals/InvalidFileError';
import { AttachmentDetailsForm } from './AttachmentDetailsForm';

interface UploadAttachmentsProps {
  attachment: File;
  claimStatementInvoiceId: ClaimStatementInvoiceId;
}

const ModalContent = ({ attachment, claimStatementInvoiceId }: UploadAttachmentsProps) => {
  const validationError = validateFiletypeAndSize(attachment, supportingDocumentFileTypes);

  if (validationError) {
    return (
      <ModalContentSmall>
        <InvalidFileError validationError={validationError} />
      </ModalContentSmall>
    );
  }
  return (
    <ModalContentLarge>
      <AttachmentDetailsForm attachment={attachment} claimStatementInvoiceId={claimStatementInvoiceId} />
    </ModalContentLarge>
  );
};

export const uploadAttachmentModal = (
  props: UploadAttachmentsProps,
  onCancel: () => void = () => null
): ModalType<UploadAttachmentsProps> => ({
  component: ModalContent,
  options: {
    title: 'Upload Attachment',
    size: 'content',
    padding: '12px 24px 24px 24px'
  },
  args: props,
  onCancel
});

export const defaultUploadAttachmentsProps: UploadAttachmentsProps = {
  attachment: new File([], ''),
  claimStatementInvoiceId: { claimStatementId: '', invoiceId: '' }
};
