import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AdjustmentPage } from './pages/AdjustmentPage';

const AdjustmentRouting = () => (
  <Routes>
    <Route path="/*" element={<AdjustmentPage />} />
  </Routes>
);

interface Props {
}
/**
 * Root component for the Adjustment app. Contains elements that are
 * shared across all pages within Adjustment, and consumes the app router.
 */
export const AdjustmentApp: FC<Props> = () => (
  <AdjustmentRouting />
);
