import { UserInfoDto } from '@/types';

/**
 * Helper function to output a user's full name or an empty dash.
 * Intended for when a table cell needs to contain a user's name.
 */
export const getUserName = (participant: UserInfoDto | null | undefined) => participant ? participant.fullName : '-';

export const getInitials = (fullName: string) =>
  fullName.split('(')[0]
    .trim()
    .split(' ')
    .map(name => name[0])
    .join('.');

export const getUserInitials = (participant: UserInfoDto | null | undefined) =>
  participant ? getInitials(participant.fullName) : '-';

/**
 * Helper function to output the full names of multiple users or an empty dash.
 * Intended for when a table cell needs to contain multiple users' names.
 */
export const getInitialsMulti = (users?: UserInfoDto[]) =>
  users ? users.map(user => getInitials(user.fullName)).join(', ') : '-';
