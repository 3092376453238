import { SlimButton } from '@instech/components';
import {
  Approved, Export, Open, Return,
  Eye, EyeRemove
} from '@instech/icons';
import {
  FunctionComponent, useMemo, useState
} from 'react';
import styled from 'styled-components';

import { SelectedInvoices } from '../../core/SelectedInvoices';
import { TableHeaderButtonFlex } from '../../core/Components';

const PaddedExport = styled(Export)`
  padding: 0 4px;
`;

interface InvoiceButtonsProps {
  selected: number;
  onOpen: () => void;
  onFinished: () => void;
  onReturn: () => void;
  onShareWithSurveyor: () => void;
  onShareForReview: () => void;
  onRevokeFromReview: () => void;
  disableReturn?: boolean;
  disableShare?: boolean;
  disableFinished?: boolean;
  disableShareForReview?: boolean;
  enableRevokeFromReview?: boolean
}
/**
 * Invoice buttons for the Claims Handler invoice table
 */
export const InvoiceButtons: FunctionComponent<InvoiceButtonsProps> = ({
  selected,
  onOpen,
  onFinished,
  onReturn,
  onShareWithSurveyor,
  onShareForReview,
  onRevokeFromReview,
  disableReturn = false,
  disableShare = false,
  disableFinished = true,
  disableShareForReview = true,
  enableRevokeFromReview = false
}) => {
  const disabled = useMemo(() => selected <= 0, [selected]);
  const [finishing, setFinishing] = useState<boolean>(false);

  const handleFinishing = async () => {
    setFinishing(true);
    await onFinished();
    setFinishing(false);
  };

  return (
    <TableHeaderButtonFlex padding="0px">
      <SelectedInvoices selected={selected} />
      <SlimButton variant="secondary" onClick={onOpen} disabled={disabled} startIcon={<Open />}>OPEN</SlimButton>
      <SlimButton variant="secondary" onClick={onReturn} disabled={disabled || disableReturn} startIcon={<Return />}>RETURN TO OWNER</SlimButton>
      <SlimButton variant="secondary" onClick={onShareWithSurveyor} disabled={disabled || disableShare} startIcon={<PaddedExport />}>
        SHARE WITH SURVEYOR
      </SlimButton>
      <SlimButton
        variant="secondary"
        width="160px"
        loading={finishing}
        onClick={handleFinishing}
        disabled={disabled || disableFinished || finishing}
        startIcon={<Approved />}>
        MARK AS FINISHED
      </SlimButton>
      {!enableRevokeFromReview && (
        <SlimButton startIcon={<Eye />} onClick={onShareForReview} disabled={disabled || disableShareForReview}>
          SHARE FOR REVIEW
        </SlimButton>
      )}
      {enableRevokeFromReview && (
        <SlimButton startIcon={<EyeRemove />} onClick={onRevokeFromReview}>
          REVOKE FROM REVIEW
        </SlimButton>
      )}
    </TableHeaderButtonFlex>
  );
};
