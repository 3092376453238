import {
  CollapsibleTableCollapseButtons,
  CollapsibleTableHeaders,
  useSortedCollapsibleTableData
} from '@/common/components/Table/CollapsibleTable';
import { CollapseWrapper } from '@/common/components/Table/CollapsibleTable/CollapseColumns';
import { CollapsibleTableRows } from '@/common/components/Table/CollapsibleTable/CollapsibleTableRows';
import { GroupingRadioStickyWrapper, TableGroupingRadio } from '@/common/components/Table/TableGrouping';
import { GroupingOption, groupingOptionsList } from '@/common/components/Table/TableGrouping/types';
import { useExpandedTableRows } from '@/common/components/Table/utils';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { HiddenInPrint } from '@/common/layout/Page';
import {
  ClaimStatementSettings, Guid, InvoiceOverview
} from '@/types';
import { arrayAddOrRemove } from '@/utils/array';
import {
  ComplexTable, Pane, SortedTableHeader
} from '@instech/components';
import {
  useCallback, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router';
import { TableHeaderButtonFlex, TableHeaderButtons } from '../core/Components';
import { useTraversalOrderContext } from '../core/TraversalContext';
import { useTableSchemaByGrouping } from '../InvoiceTableClaimsHandler/utils';
import {
  AddSpaceInPrint,
  FitTableArea,
  StyledCheckbox
} from './Components';

// Calculate the stickyTop offsets of the different table elements, based on the height of the elements
const getStickyTopValues = (showCheckbox: boolean) => {
  const baseGapHeight = 50 + 80;
  const checkboxHeight = showCheckbox ? 72 : 0;
  const collapseHeight = 57;

  const buttonsStickyTop = baseGapHeight;
  const collapseStickyTop = baseGapHeight + checkboxHeight;
  const headerStickyTop = collapseStickyTop + collapseHeight;

  return {
    buttons: `${buttonsStickyTop}px`,
    collapse: `${collapseStickyTop}px`,
    header: `${headerStickyTop}px`
  };
};

interface Props {
  data: InvoiceOverview;
  title: string;
  claimStatementSettings?: ClaimStatementSettings;
  usePrintSchema?: boolean;
}
export const InvoiceTableReview = ({ data, title, claimStatementSettings, usePrintSchema }: Props) => {
  const { setCurrentTraversalOrder } = useTraversalOrderContext();
  const navigate = useNavigate();

  const [showFullComments, setShowFullComments] = useState<boolean>(false);
  const { expanded, toggleExpanded, allRowsExpanded, toggleExpandAllRows } = useExpandedTableRows(
    data.invoices.map(invoice => invoice.id)
  );

  const [selectedGrouping, setSelectedGrouping] = useState<GroupingOption>(groupingOptionsList[0]);
  const [sortedHeader, setSortedHeader] = useState<SortedTableHeader>();
  const [collapsedGroups, setCollapsedGroups] = useState<string[]>([]);

  const tooltipId = 'invoice-table-tooltip';
  const selectableRows = false;
  const showReview = false;
  const showCommentCheckbox = selectedGrouping === 'No group';

  const navigateToInvoice = useCallback((invoiceId: Guid) => navigate(`invoices/${invoiceId}`), [navigate]);

  const handleOnClick = useCallback((invoiceId: string, invoiceIdList: string[]) => {
    setCurrentTraversalOrder(invoiceIdList, invoiceId);
    navigateToInvoice(invoiceId);
  }, [navigateToInvoice, setCurrentTraversalOrder]);

  // Based on currently selected grouping option, generate and return table segments
  const tableData = useTableSchemaByGrouping(
    selectedGrouping,
    data.invoices,
    {
      selectableRows,
      showReview,
      currency: data.currency,
      claimStatementSettings,
      usePrintSchema,
      showReviewComment: true,
      onRowClick: handleOnClick,
      toggleExpanded,
      expanded
    }
  );

  // Parse the raw table data into a set that is sorted based on the user's selection
  const sortedTable = useSortedCollapsibleTableData(tableData.tableRows, sortedHeader);
  const stickyTop = useMemo(() => getStickyTopValues(showCommentCheckbox), [showCommentCheckbox]);

  const handleCollapse = (range: string) => {
    const newRanges = arrayAddOrRemove(collapsedGroups, range);
    setCollapsedGroups([...newRanges]);
  };

  const handleSetGrouping = (grouping: GroupingOption) => {
    setSelectedGrouping(grouping);
  };

  const handleSortedHeader = (header: SortedTableHeader) => {
    setSortedHeader(header);
  };

  return (
    <>
      <GroupingRadioStickyWrapper>
        <TableGroupingRadio selected={selectedGrouping} onChange={handleSetGrouping} />
      </GroupingRadioStickyWrapper>
      <FitTableArea>
        <Pane title={title} color="green" padding="0px">
          <TableHeaderButtons stickyTop={stickyTop.buttons}>
            <HiddenInPrint>
              <TableHeaderButtonFlex padding="16px 40px">
                {showCommentCheckbox && (
                  <StyledCheckbox
                    name="showFullComments"
                    rightLabel="Show full comments"
                    selected={showFullComments}
                    onChange={() => setShowFullComments(!showFullComments)}
                    noTopLabel
                    noErrors />
                )}
                <StyledCheckbox
                  name="expandAllSplits"
                  rightLabel="Expand all splits"
                  selected={allRowsExpanded}
                  onChange={toggleExpandAllRows}
                  noTopLabel
                  noErrors />
              </TableHeaderButtonFlex>
            </HiddenInPrint>
            <AddSpaceInPrint />
          </TableHeaderButtons>
          <SharedTooltip id={tooltipId}>
            <CollapseWrapper collapsedGroups={collapsedGroups}>
              <ComplexTable layout={tableData.layout}>
                <CollapsibleTableCollapseButtons
                  segments={tableData.collapseButtons}
                  collapsedGroups={collapsedGroups}
                  onClick={handleCollapse}
                  stickyTop={stickyTop.collapse}
                />
                <CollapsibleTableHeaders
                  segments={tableData.header}
                  sortedHeader={sortedHeader}
                  setSortedHeader={handleSortedHeader}
                  subrowsHeaderText={tableData.subrowsHeaderText}
                  allItems={data.invoices.length}
                  selectableRows={false}
                  stickyTop={stickyTop.header}
                />
                <CollapsibleTableRows
                  tableRows={sortedTable.data}
                  minSubrowsToExpand={tableData.minSubrowsToExpand}
                  showFullComments={showFullComments}
                  selectableRows={false}
                  expandableRows
                  canDrillDown
                />
              </ComplexTable>
            </CollapseWrapper>
          </SharedTooltip>
        </Pane>
      </FitTableArea>
    </>
  );
};
