import styled from 'styled-components';
import { CheckboxControlled } from '@instech/components';
import { FitToContent } from '@/common/layout/FitToContent';

export const FitTableArea = styled(FitToContent)`
  padding: 0px 40px 40px;
  box-sizing: border-box;
`;

export const StyledCheckbox = styled(CheckboxControlled)`
  // The CheckboxControlled styles were loading after these styles in the feature environment for some reason.
  // This caused the width to remain 100%.
  width: auto !important;
  color: ${({ theme }) => theme.marineBlue};
`;

export const AddSpaceInPrint = styled.div`
  @media print {
    height: 20px;
    width: 100%;
  }
`;
