import { DatePickerHighlighted } from '@/common/components/HighlightedFields';
import { useField } from 'formik';
import { isHighlighted } from '@/apps/Adjustment/pages/AdjustmentPage/chapters/InsuranceConditions/core/PolicyInformationPane/Inputs/utils';
import moment from 'moment';

type InsurancePeriodInputProps = {
  initialValue: string | null;
  name: string;
  label: string;
}
const InsurancePeriodInput = ({ initialValue, name, label }: InsurancePeriodInputProps) => {
  const [, read] = useField(name);

  const highlight = isHighlighted({
    current: moment(read.value).format('YYYY-MM-DD'),
    initial: moment(initialValue).format('YYYY-MM-DD')
  });

  return (
    <DatePickerHighlighted
      name={name}
      label={label}
      data-highlight={highlight}
    />
  );
};

type PeriodInputProps = {
  initialValue: string | null;
}

export const PeriodFromInput = ({ initialValue }: PeriodInputProps) => (
  <InsurancePeriodInput
    name="periodFrom"
    label="Policy Period from"
    initialValue={initialValue}
  />
);

export const PeriodToInput = ({ initialValue }: PeriodInputProps) => (
  <InsurancePeriodInput
    name="periodTo"
    label="Policy Period to"
    initialValue={initialValue}
  />
);
