import { invoiceDataValidationSchema } from '@/components/pages/invoice/core/EditInvoice/utils/validationSchema';
import { UpdateInvoiceForm } from '@/types';
import moment from 'moment';

// Keep valid positive number values, and cast any
// other values (negative, empty) to undefined
const sanitizeExchangeRate = (exchangeRate?: string | number): string | undefined => {
  const toValidNumber = exchangeRate ? Number(exchangeRate) : 0;
  if (toValidNumber <= 0) return undefined;
  return String(exchangeRate);
};

/**
 * Sanitize invoice form values that will be passed through useInvoice.updateInvoice()
 */
export const sanitizeInvoiceValues = (invoice: UpdateInvoiceForm) => {
  const castValues: UpdateInvoiceForm = invoiceDataValidationSchema.cast(invoice, { assert: false });

  const isValidPayment = moment(castValues.paymentDate, moment.ISO_8601).isValid();
  const hasClaimOrOwnersWork = !!castValues.claim || !!castValues.ownersWork;

  // Ensure exchange rate is a positive number or 'undefined'
  const exchangeRate = sanitizeExchangeRate(castValues.exchangeRate);

  // If payment date is invalid, safely null the value out
  const paymentDate = isValidPayment ? castValues.paymentDate : null;

  // If only one of claim or ownersWork is a real value, and the other is nulled,
  // cast the nulled value to a 0. Either both should be null or neither should be.
  const claim = (hasClaimOrOwnersWork && !castValues.claim) ? '0' : castValues.claim;
  const ownersWork = (hasClaimOrOwnersWork && !castValues.ownersWork) ? '0' : castValues.ownersWork;

  const sanitizedValues: UpdateInvoiceForm = {
    ...castValues,
    paymentDate,
    exchangeRate,
    claim,
    ownersWork
  };

  return sanitizedValues;
};
