import { ChevronDown } from '@instech/icons';
import { CellContext } from '@tanstack/react-table';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { BaseButtonCell } from './BaseButtonCell';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 54px; 
  height: 100%;
`;

const SpaceAbove = styled.div`
  flex-grow: 1;
  height: calc(50% - 14px);
`;

const SpaceBelow = styled.div<{ isExpanded: boolean }>`
  flex-grow: 1;
  height: calc(50% - 14px);
  background-color: ${props => props.isExpanded && props.theme.marineBlue20};
  z-index: 1;
`;

const CellChip = styled.button<{ isExpanded: boolean }>`
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  width: 54px;
  height: 28px;
  background-color: ${props => props.theme.marineBlue20};
  border: none;
  border-radius: 12px;
  padding: 0px 4px;
  cursor: pointer;
  ${props => props.isExpanded && css`
    border-radius: 12px 12px 0px 0px;    
  `}
  & svg {
    color: ${props => props.theme.marineBlue80};
    transform: ${props => props.isExpanded && 'rotate(180deg)'};
    padding-left: 1px;
  }
`;

const ChipText = styled.span`
  padding-bottom: 2px;
  color: ${props => props.theme.marineBlue};
  line-height: 14px;
  font-size: 14px;
  font-weight: 700;
`;

interface ExpandButtonProps {
  data: CellContext<any, any>;
  counter: number;
}
export const TableButtonExpand: FC<ExpandButtonProps> = ({ data, counter }) => {
  const isExpanded = data.row.getIsExpanded();
  return (
    <BaseButtonCell data={data}>
      {data.row.getCanExpand() && (
        <Wrapper>
          <SpaceAbove />
          <CellChip
            onClick={data.row.getToggleExpandedHandler()}
            isExpanded={isExpanded}
            data-testid={`expand-table-row-${data.row.id}`}>
            <ChipText>{counter}</ChipText>
            <ChevronDown width="18px" />
          </CellChip>
          <SpaceBelow isExpanded={isExpanded} />
        </Wrapper>
      )}
    </BaseButtonCell>
  );
};
