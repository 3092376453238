import { FunctionComponent as FC } from 'react';
import { tableCellVariant } from '@/common/components/Table/types';
import { collapseGroups } from '@/common/components/Table/utils';
import { TableCellMaxContent, TableCellComment } from '@/common/components/Table/core/TableCell';
import { InvoiceState, SurveyorsConsideration } from '@/types';
import { invoiceStatesFinished } from '@/utils/invoice/invoiceStates';
import { invoiceStates } from '@/utils/constants';
import { RowNavigationHandler, useRowProps } from '@/common/components/Table/core/TableRow';
import { getFieldValue, getTooltipValue } from '@/components/pages/claim/ClaimStatementPage/InvoiceTable/core/utils/getConsideredValues';
import { NotConsideredView } from './NotConsideredView';

const getSurveyorsComment = (data?: SurveyorsConsideration) => {
  if (!data?.splits || data?.splits.length === 1) {
    return data?.surveyorsComment;
  }
  const noValidComments = data?.splits.map(split => split.surveyorsComment).every(comment => !comment);
  if (noValidComments) return '-';
  return 'Multiple';
};

const consideredStates: InvoiceState[] = [
  invoiceStates.AdjustersConsideration,
  invoiceStates.ReturnToUploaderAfterConsideration,
  invoiceStates.Finished
];

interface Props {
  data?: SurveyorsConsideration;
  state: InvoiceState;
  exchangeRate?: number | null;
  showComment?: boolean;
  onClick?: () => void;
}

/**
 * Switch handler for deciding what to output to the Surveyor cell(s) on the
 * Claims Handler table.
 */
export const InvoiceSurveyorCells: FC<Props> = ({
  data,
  state,
  exchangeRate,
  showComment,
  onClick,
}) => {
  // Destructure props of possibly undefined object as destructured value or undefined.
  const { isConsidered, generalExpenses, claimConsideration, ownersWorkConsideration, adjusterToConsider } = { ...data };

  const showConsideredView = consideredStates.includes(state);
  const surveyorsComment = getSurveyorsComment(data);
  const notConsideredSpan = showComment ? '5' : '4';
  const isFinished = invoiceStatesFinished.includes(state);

  const { tooltipId, showFullComments } = useRowProps();

  // If the data record is null or not considered display default values
  if (!data || !isConsidered || !showConsideredView) {
    return <NotConsideredView state={state} span={notConsideredSpan} isFinished={isFinished} onClick={onClick} />;
  }

  return (
    <RowNavigationHandler onClick={onClick}>
      <TableCellMaxContent
        data-collapse-group={collapseGroups.surveyor}
        data-cell-variant={tableCellVariant.secondary}
        data-is-finished={isFinished}
        data-collapse-border
        right>
        <span
          data-for={tooltipId}
          data-tip={getTooltipValue(isConsidered, generalExpenses, exchangeRate)}>
          {getFieldValue(isConsidered, generalExpenses, exchangeRate)}
        </span>
      </TableCellMaxContent>
      <TableCellMaxContent
        data-collapse-group={collapseGroups.surveyor}
        data-cell-variant={tableCellVariant.secondary}
        data-is-finished={isFinished}
        right>
        <span
          data-for={tooltipId}
          data-tip={getTooltipValue(isConsidered, claimConsideration, exchangeRate)}>
          {getFieldValue(isConsidered, claimConsideration, exchangeRate)}
        </span>
      </TableCellMaxContent>
      <TableCellMaxContent
        data-collapse-group={collapseGroups.surveyor}
        data-cell-variant={tableCellVariant.secondary}
        data-is-finished={isFinished}
        right>
        <span
          data-for={tooltipId}
          data-tip={getTooltipValue(isConsidered, ownersWorkConsideration, exchangeRate)}>
          {getFieldValue(isConsidered, ownersWorkConsideration, exchangeRate)}
        </span>
      </TableCellMaxContent>
      <TableCellMaxContent
        data-collapse-group={collapseGroups.surveyor}
        data-cell-variant={tableCellVariant.secondary}
        data-is-finished={isFinished}
        right>
        <span
          data-for={tooltipId}
          data-tip={getTooltipValue(isConsidered, adjusterToConsider, exchangeRate)}>
          {getFieldValue(isConsidered, adjusterToConsider, exchangeRate)}
        </span>
      </TableCellMaxContent>
      {showComment && (
        <TableCellComment
          data-collapse-group={collapseGroups.surveyor}
          data-cell-variant={tableCellVariant.secondary}
          data-is-finished={isFinished}
          comment={surveyorsComment}
          showFullComment={showFullComments}
          tooltipId={tooltipId} />
      )}
    </RowNavigationHandler>
  );
};
