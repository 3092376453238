import useSWR from 'swr';
import { LabelValuePair } from '@instech/components';
import { arrayToLabelValuePair } from '@/utils/labelValuePair';

export const useClaimStatementLocations = (claimStatementId: string) => {
  const swrRoute = claimStatementId ? `claimstatements/${claimStatementId}/locations` : null;
  return useSWR<string[]>(swrRoute);
};

export const useClaimStatementLocationsAsOptions = (claimStatementId: string) => {
  const { data: locations, mutate } = useClaimStatementLocations(claimStatementId!);

  if (!locations) {
    return { locationOptions: locations, mutate };
  }

  const locationOptions = arrayToLabelValuePair(locations) as LabelValuePair[];
  return { locationOptions, mutate };
};
