import { useState } from 'react';

export const useInvoiceConflict = () => {
  const [conflict, setConflict] = useState(false);

  const handleInvoiceConflict = () => {
    setConflict(true);
  };

  return { conflict, handleInvoiceConflict };
};
