import { InvoiceRecord, InvoiceState } from '@/types';
import { invoiceStates } from '@/utils/constants';
import { isInvoiceFilledState, isInvoicePartialState } from '@/utils/invoice/sortInvoiceData';

const getNewStateVariants = (state: InvoiceState, record?: InvoiceRecord) => {
  const isNewPartial = state === invoiceStates.New && isInvoicePartialState(record);
  const isNewFilled = state === invoiceStates.New && isInvoiceFilledState(record);
  return [isNewPartial, isNewFilled];
};

// Cast keys of invoiceStates to literal, and include New state variants
type InvoiceStateLiteral = keyof typeof invoiceStates | 'NewPartial' | 'NewFilled';

const stateSortingKeys: { [state in InvoiceStateLiteral]: number } = {
  New: 10,
  NewPartial: 11,
  NewFilled: 12,
  ReturnToUploader: 20,
  ReturnToUploaderAfterConsideration: 21,
  ReturnToUploaderAfterSharedReview: 22,
  ReturnToUploaderAfterConsiderationNoAllocation: 23,
  Review: 30,
  SharedReview: 40,
  CostAllocation: 41,
  CostReallocation: 42,
  AdjustersConsideration: 50,
  AdjustersConsiderationNoAllocation: 51,
  Finished: 60,
  FinishedNoAllocation: 61,
  FinishedSharedReview: 62,
  Deleted: 63,
};

/**
 * Takes an InvoiceState and returns a value by which the state should be
 * considered when the state is being used as the sort order in a table.
 *
 * Record can be provided to also map variations of New state to the list,
 * such as for the Owner's invoice table.
 */
export const getStateSortingKey = (state: InvoiceState, record?: InvoiceRecord) => {
  const [isNewPartial, isNewFilled] = getNewStateVariants(state, record);

  let localState = state as InvoiceStateLiteral;
  if (isNewPartial) localState = 'NewPartial';
  if (isNewFilled) localState = 'NewFilled';

  return stateSortingKeys[localState];
};
