import useSWR from 'swr';
import { LabelValuePair } from '@instech/components';
import { arrayToLabelValuePair } from '@/utils/labelValuePair';

export const useClaimStatementSuppliers = (claimStatementId: string) => {
  const swrRoute = claimStatementId ? `claimstatements/${claimStatementId}/suppliers` : null;
  return useSWR<string[]>(swrRoute);
};

export const useClaimStatementSuppliersAsOptions = (claimStatementId: string) => {
  const { data: suppliers, mutate } = useClaimStatementSuppliers(claimStatementId!);

  if (!suppliers) {
    return { supplierOptions: suppliers, mutate };
  }

  const supplierOptions = arrayToLabelValuePair(suppliers) as LabelValuePair[];
  return { supplierOptions, mutate };
};
