import { TableCellMaxContent } from '@/common/components/Table/core/TableCell';
import { TableCellWithHover } from '@/common/components/Table/core/TableCell/TableCellWithHover';
import { TableCellHoverStyles } from '@/common/components/Table/core/TableCell/TableCellHoverStyles';
import {
  CheckboxControlled, CheckboxTableHeader, TableCellProps
} from '@instech/components';
import styled, { css } from 'styled-components';
import { FCWC } from '@/types';

interface CheckboxHeaderProps {
  slimBorder?: boolean;
}

/* CheckboxTableHeader component with an added styling to resize checkbox container. */
export const CheckboxTableHeaderStyled = styled(CheckboxTableHeader) <CheckboxHeaderProps>`
  ${props => props.slimBorder && css`
    border-bottom-width: 1px;
  `}
  & span {
    width: 16px;
    height: 16px;
  }
  & span > div {
    width: 12px;
  }
`;

/* CheckboxControlled component with an added styling to resize checkbox container. */
export const CheckboxControlledStyled = styled(CheckboxControlled)`
  & span {
    width: 16px;
    height: 16px;
  }
`;

interface TableCellEmptyProps extends TableCellProps {
  isEmpty?: boolean;
  allowEmpty?: boolean;
  maxContent?: boolean;
  onClick?: () => void;
}
/**
 * Shorthand table cell component to reduce the amount of code on a line
 * to do the data-is-empty display (while assuming that it will be === null).
 * Can conditionally return as a TableCellMaxContent if `maxContent` is true.
 */
export const TableCellEmpty: FCWC<TableCellEmptyProps> = ({
  children,
  isEmpty,
  allowEmpty = false,
  maxContent = false,
  ...props
}) => {
  if (maxContent) {
    return (
      <TableCellMaxContent data-is-empty={isEmpty} data-allow-empty={allowEmpty} {...props}>
        {children}
      </TableCellMaxContent>
    );
  }
  return (
    <TableCellWithHover data-is-empty={isEmpty} data-allow-empty={allowEmpty} {...props}>
      {children}
    </TableCellWithHover>
  );
};

export const TableHeaderText = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
`;

export const TableHeaderButtons = styled.div<{ stickyTop?: string }>`
  position: sticky;
  top: ${props => props.stickyTop};
  background: ${props => props.theme.white};
  z-index: 5;
`;

export const TableHeaderButtonFlex = styled.div<{ padding?: string }>`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: ${props => props.padding ?? '0px 40px 16px'};
`;

export const RequiredTableField = styled.span<{ isEmpty: boolean; }>`
  ${props => props.isEmpty && css`
    color: ${props.theme.primaryError};
  `}
  ${TableCellHoverStyles}
`;
