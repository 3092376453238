import React from 'react';
import { PageLoader } from '@instech/components';
import {
  Navigate, Route, Routes
} from 'react-router-dom';
import { useFeatureFlag } from '@/common/providers/featureFlagProvider';
import { useFontPreload } from '@/common/providers/fontPreloadProvider';
import { useCurrentRoleContext } from '@/common/authentication/CurrentRoleContext';
import { useAppInsights } from '@/common/hooks/useAppInsights';
import { Page } from '@/common/layout/Page';
import { NoAccessPage } from '@/components/pages/authentication/NoAccessPage';
import { ClaimsHandlerClaimStatementDashboard } from '@/components/pages/claim/ClaimDashboard/ClaimHandlerClaimDashboard';
import { ClaimsHandlerClaimStatementPage } from '@/components/pages/claim/ClaimStatementPage/ClaimsHandlerClaimStatementPage';
import { ReviewInvoicePage } from '@/components/pages/invoice/InvoicePage/ReviewInvoicePage';
import { OwnerClaimStatementPage } from '@/components/pages/claim/ClaimStatementPage/OwnerClaimStatementPage';
import { NewClaimStatementPage, NewTestClaimStatementPage } from '@/components/pages/claim/NewClaimStatementPage';
import { UploadInvoicePage } from '@/components/pages/invoice/UploadInvoicePage/UploadInvoicePage';
import { UpdatesPage } from '@/components/pages/UpdatesPage/UpdatesPage';
import { useScrollToTop } from '@/utils/useScrollToTop';
import { ClaimStatementDashboard } from '@/components/pages/claim/ClaimDashboard/ClaimStatementsDashboard';
import { BatchInvoicePage } from '@/components/pages/invoice/BatchInvoicePage/BatchInvoicePage';
import { ClaimsHandlerInvoicePage } from '@/components/pages/invoice/InvoicePage/ClaimsHandlerInvoicePage';
import { OwnerInvoicePage } from '@/components/pages/invoice/InvoicePage/OwnerInvoicePage';
import { SurveyorClaimStatementPage } from '@/components/pages/claim/ClaimStatementPage/SurveyorClaimStatementPage';
import { roles } from '@/utils/constants';
import { AdjustmentApp } from '@/apps/Adjustment/AdjustmentApp';
import { EditClaimStatementPage } from '@/components/pages/claim/EditClaimStatementPage';
import { SurveyorInvoicePage } from '../pages/invoice/InvoicePage/SurveyorInvoicePage';
import { ClaimStatementReviewPage } from '../pages/claim/ClaimStatementPage/ClaimStatementReviewPage';
import { FeedbackAndSupportPage } from '../pages/FeedbackAndSupportPage/FeedbackAndSupportPage';
import { RequireClaimAuth } from '../../common/authentication/RequireClaimAuth';

// Note: Review page will get its own component when it is no longer just a placeholder
const OwnerRoutes = () => (
  <Routes>
    <Route
      path="/"
      element={<ClaimStatementDashboard />}
    />
    <Route path="claimstatements/:claimStatementId/*" element={<RequireClaimAuth />}>
      <Route path="" element={<OwnerClaimStatementPage />} />
      <Route path="invoices/:invoiceId" element={<OwnerInvoicePage />} />
      <Route path="invoices/upload" element={<UploadInvoicePage />} />
      <Route path="review" element={<ClaimStatementReviewPage />} />
      <Route path="review/invoices/:invoiceId" element={<ReviewInvoicePage />} />
      <Route path="batch/:batchId/invoices/:invoiceBatchNumber" element={<BatchInvoicePage />} />
    </Route>
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
);

const ClaimsHandlerRoutes = () => (
  <Routes>
    <Route
      path="/"
      element={<ClaimsHandlerClaimStatementDashboard />}
    />
    <Route path="claim" element={<NewClaimStatementPage />} />
    <Route path="testClaim" element={<NewTestClaimStatementPage />} />
    <Route path="claimstatements/:claimStatementId/*" element={<RequireClaimAuth />}>
      <Route path="edit" element={<EditClaimStatementPage />} />
      <Route path="" element={<ClaimsHandlerClaimStatementPage />} />
      <Route path="review" element={<ClaimStatementReviewPage />} />
      <Route path="invoices/:invoiceId" element={<ClaimsHandlerInvoicePage />} />
      <Route path="review/invoices/:invoiceId" element={<ReviewInvoicePage />} />
    </Route>
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
);

const SurveyorRoutes = () => (
  <Routes>
    <Route
      path="/"
      element={<ClaimStatementDashboard />}
    />
    <Route path="*" element={<Navigate to="/" />} />
    <Route path="claimstatements/:claimStatementId/*" element={<RequireClaimAuth />}>
      <Route path="" element={<SurveyorClaimStatementPage />} />
      <Route path="invoices/:invoiceId" element={<SurveyorInvoicePage />} />
    </Route>

  </Routes>
);

// Set to the same as the owner routes until it is actually different.
const BrokerRoutes = OwnerRoutes;

const InnerRouting = ({ name }: { name: string | undefined; }) => {
  useAppInsights(); // Application insights page tracking
  useScrollToTop();

  return (
    <Routes>
      <Route path="/announcements" element={<UpdatesPage />} />
      <Route path="/feedback-and-support" element={<FeedbackAndSupportPage />} />
      <Route path="/claimstatements/:claimStatementId/adjustments/*" element={<AdjustmentApp />} />
      {name === roles.Owner &&
        <Route path="/*" element={<OwnerRoutes />} />}
      {name === roles.ClaimsHandler &&
        <Route path="*" element={<ClaimsHandlerRoutes />} />}
      {name === roles.Surveyor &&
        <Route path="/*" element={<SurveyorRoutes />} />}
      {name === roles.Broker &&
        <Route path="/*" element={<BrokerRoutes />} />}
    </Routes>
  );
};

export const AppRouting = () => {
  const { currentRole, rolesLoaded } = useCurrentRoleContext();
  const featureFlagsLoaded = useFeatureFlag('featureFlagsLoaded');
  const fontsLoaded = useFontPreload();
  // Show loader while pending necessary information
  if (!featureFlagsLoaded || !fontsLoaded || !rolesLoaded) {
    return <PageLoader />;
  }
  // User has no roles in the app, display no access error
  if (!currentRole) {
    return <NoAccessPage />;
  }

  return (
    <Page>
      <InnerRouting name={currentRole?.name} />
    </Page>
  );
};
