import { InfoCircle } from '@instech/icons';
import styled from 'styled-components';

export const InfoTitle = styled.h3`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.marineBlue};
  font-weight: normal;
  font-size: 18px;
  margin: 0px;
`;

export const StyledInfoCircle = styled(InfoCircle)`
  // 18px InfoCircle renders the circle itself at 16px
  width: 18px;
  height: 18px;
  margin-right: 12px;
  & g { // Have to target stroke-width directly on line
    stroke-width: 2;
  }
`;

export const InfoSegment = styled.div`
  padding: 16px 0px;
  color: ${({ theme }) => theme.marineBlue};
`;

export const InfoHeading = styled.span`
  display: block;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 16px;
`;

export const InfoText = styled.span`
  display: block;
  font-size: 14px;
  & + & {
    margin-top: 16px;
  }
`;

export const InfoList = styled.ol`
  padding-left: 16px;
  font-size: 14px;
  & li {
    margin-top: 12px;
  }
`;
