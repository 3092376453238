// eslint-disable-next-line no-undef
import { ClaimStatement } from '@/types';
import { MenuOption } from '@instech/components';

export const getNonClaimHandlerMenuOptions = (
  claimStatement: ClaimStatement,
  onOpen: (claimStatementId: string) => void,
  onDowloadAll?: (claimStatementId: string) => void,
) => {
  const options: MenuOption[] = [];
  options.push({
    label: 'Open',
    onClick: () => onOpen(claimStatement.id)
  });

  options.push({
    label: 'Download all Claim Documents',
    onClick: onDowloadAll ? () => onDowloadAll(claimStatement.id) : undefined
  });

  return options;
};

export const getClaimHandlerMenuOptions = (
  claimStatement: ClaimStatement,
  canCloseClaim: boolean,
  onOpen: (claimStatementId: string) => void,
  onEdit: (claimStatementId: string) => void,
  onDelete: (claimStatementId: string) => void,
  onReopen: (claimStatementId: string) => void,
  onClose: (claimStatementId: string) => void,
  currentUserId?: string,
) => {
  const options: MenuOption[] = [];

  const isClaimsHandlerOnClaimsStatement =
    !!claimStatement.claimsHandlers?.map(x => x.id).includes(currentUserId ?? '');

  if (!(currentUserId && !isClaimsHandlerOnClaimsStatement)) {
    options.push(
      {
        label: 'Open',
        onClick: () => onOpen(claimStatement.id)
      }
    );
  }

  options.push({
    label: 'Edit',
    onClick: (canCloseClaim && claimStatement.isClosed) ? undefined : () => onEdit(claimStatement.id)
  });

  if (canCloseClaim && isClaimsHandlerOnClaimsStatement && claimStatement.isClosed) {
    options.push({
      label: 'Reopen Claim Statement',
      onClick: () => onReopen(claimStatement.id)
    });
  }

  if (canCloseClaim && isClaimsHandlerOnClaimsStatement && !claimStatement.isClosed) {
    options.push({
      label: 'Close Claim Statement',
      onClick: () => onClose(claimStatement.id)
    });
  }

  // NB: Delete option should be fixed last on the list
  if (claimStatement.isExperimental && isClaimsHandlerOnClaimsStatement) {
    options.push({
      label: 'Delete',
      onClick: () => onDelete(claimStatement.id)
    });
  }

  return options;
};
