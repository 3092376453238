import React, { useEffect } from 'react';
import {
  useLocation, useNavigate, useSearchParams
} from 'react-router-dom';
import { FCWC } from '@/types';
import { useAccount } from './AuthenticationProvider';

export const RequireAuth: FCWC = ({ children }) => {
  const { isAuthenticated } = useAccount();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!isAuthenticated) {
      // Do not redirect if already in login flow
      if (location.pathname.startsWith('/management/')) return;
      // Login and make sure we get back to the current page
      // Use window, instead of navigate, to hae the it hit the backend
      // instead of the SPA route
      window.location.href = `/management/login?returnUrl=${location.pathname}`;
    }
  }, [isAuthenticated, location, navigate, searchParams]);

  return (children as React.ReactElement);
};
