import { useClickOutside } from '@/utils/hooks/useClickOutside';
import {
  FunctionComponent as FC, useEffect, useRef
} from 'react';
import styled from 'styled-components';
import { ButtonGroup, SlimButton } from '@instech/components';
import { ExportPdf } from '@instech/icons';
import {
  InfoHeading, InfoList, InfoSegment, InfoText, InfoTitle, StyledInfoCircle
} from './Components';

const Contents = styled.div`
  width: 300px;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 2px 6px 0px rgba(72, 135, 177, 0.2);
  overflow-y: auto; // auto-add scrollbar if the sidebar is too short
`;

interface Props {
  isVisible: boolean;
  onClose: () => void;
}
export const SidebarContents: FC<Props> = ({ isVisible, onClose }) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const isOutsideClick = useClickOutside(sidebarRef);

  useEffect(() => {
    if (isVisible && isOutsideClick) onClose();
    return () => onClose();
  });

  const handlePrint = () => window.print();

  return (
    <Contents ref={sidebarRef}>
      <InfoTitle>
        <StyledInfoCircle />
        Save as PDF
      </InfoTitle>
      <InfoSegment>
        <InfoHeading>Include or hide info in the invoices table</InfoHeading>
        <InfoText>
          Comments can be expanded with the checkbox in the top left of the invoices table.
          Splits can be expanded per invoice line.
        </InfoText>
        <InfoText>
          Panels you don&apos;t want to include in the PDF can be collapsed by clicking the &lt;
          next to the title, for example &quot;&lt; Owner&quot;.
        </InfoText>
      </InfoSegment>
      <InfoSegment>
        <InfoHeading>Set the correct print settings</InfoHeading>
        <InfoList>
          <li>Select save as PDF as destination.</li>
          <li>Set layout to Landscape.</li>
          <li>Expand &quot;More Settings&quot;</li>
          <li>Set the paper size. Typically A3.</li>
          <li>Set margins to &quot;None&quot;</li>
          <li>Set the scale to &quot;Customized&quot; and 50%.</li>
          <li>Tick the option for including Background graphics.</li>
          <li>Press Save.</li>
        </InfoList>
      </InfoSegment>
      <ButtonGroup alignRight>
        <SlimButton
          variant="secondary"
          onClick={onClose}>
          CLOSE
        </SlimButton>
        <SlimButton
          startIcon={<ExportPdf width="24px" height="24px" />}
          onClick={handlePrint}>
          NEXT
        </SlimButton>
      </ButtonGroup>
    </Contents>
  );
};
