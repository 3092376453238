import React from 'react';
import styled from 'styled-components';
import { BoatFrontSolid } from '@instech/icons';

const ImageCircle = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
  width: 110px;
  color: ${props => props.theme.marineBlue};
  background-color: ${props => props.theme.lightBlue};
  border-radius: 50%;
  margin-right: 40px;
`;

const StyledIcon = styled(BoatFrontSolid) <{ $visible?: boolean }>`
  display: ${props => props.$visible ? 'block' : 'none'};
`;

interface Props {
  visible?: boolean;
}
export const VesselIcon = ({ visible }: Props) => (
  <ImageCircle>
    <StyledIcon
      width="44px"
      height="44px"
      $visible={visible}
      data-testid="claim-details-vessel-icon"
    />
  </ImageCircle>
);
