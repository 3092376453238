import { cssVariables, useCSSVariableContext } from '@/common/providers/cssVariableProvider';
import styled from 'styled-components';
import { FCWC } from '@/types';
import { PageContent } from '../Page';

// Restyling to sticky the page content, so the contents above the table will
// clamp to screen with and scroll with the horizontal viewport scroll
const StickyContent = styled(PageContent) <{ noTopPadding?: boolean }>`
  position: sticky;
  left: 0;
  right: 0;
  padding-top: ${props => props.noTopPadding && '0px'};
  width: ${`calc(100vw - ${cssVariables.scrollbarWidth})`};
  box-sizing: border-box;
`;

interface Props {
  noTopPadding?: boolean
  className?: string;
}
export const StickyPageContent: FCWC<Props> = ({ noTopPadding, className, children }) => {
  const { setScrollbarWidth, useForceVariableRefresh } = useCSSVariableContext();
  useForceVariableRefresh(setScrollbarWidth);
  return (
    <StickyContent className={className} noTopPadding={noTopPadding}>
      {children}
    </StickyContent>
  );
};
