import { CollapsiblePane, IconButton } from '@instech/components';
import { FileDropzone } from '@/common/components/FileDropzone';
import { adjustedClaimFileTypes } from '@/utils/file/file';
import { Trash } from '@instech/icons';
import { FileTable, IconCell } from '@/common/components/Table';
import { formatDateWithTimeOrNull } from '@/utils/formatDate';
import {
  dispatchAutosaveFinishedEvent,
  dispatchAutosaveStartedEvent
} from '@/common/components/Autosave';
import styled from 'styled-components';
import usePdfOfAdjustment from './usePdfOfAdjustment';
import { UploadError } from './UploadError';

const PdfUploadContainer = styled.div`
  padding: 20px;
`;

type AdjustedClaimPdfProps = {
  claimStatementId: string;
}
const AdjustedClaimPdf = ({ claimStatementId }: AdjustedClaimPdfProps) => {
  const { uploadAdjustmentPdf, uploadErrorMessage, pdf, deletePdf } = usePdfOfAdjustment(claimStatementId);

  const handleUploadPdf = async (files: File[]) => {
    dispatchAutosaveStartedEvent();
    await uploadAdjustmentPdf(files);
    dispatchAutosaveFinishedEvent();
  };

  const handleDeletePdf = async () => {
    dispatchAutosaveStartedEvent();
    await deletePdf();
    dispatchAutosaveFinishedEvent();
  };

  return (
    <CollapsiblePane color="green" title="Upload PDF of Adjustment" padding="0px">
      {pdf && (
        <FileTable>
          <thead>
            <tr>
              <th>File Name</th>
              <th>Uploaded At</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{pdf.filename}</td>
              <td>{formatDateWithTimeOrNull(pdf.lastModifiedAt)}</td>
              <IconCell>
                <IconButton icon={<Trash />} onClick={handleDeletePdf} />
              </IconCell>
            </tr>
          </tbody>
        </FileTable>
      )}
      {!pdf && (
        <>
          {uploadErrorMessage &&<UploadError error={uploadErrorMessage} />}
          <PdfUploadContainer>
            <FileDropzone
              acceptedTypes={adjustedClaimFileTypes}
              handleUpload={handleUploadPdf}
              id="adjustment-uploader"
              title="Drop file" />
          </PdfUploadContainer>
        </>
      )}
    </CollapsiblePane>
  );
};

export default AdjustedClaimPdf;
