import { FormikWithPrompt } from '@/common/components/Form/PromptUnsavedChanges';
import { InvoiceRequestStatus } from '@/services/invoice/invoiceServices';
import {
  ClaimStatement, Invoice, UpdateInvoiceForm
} from '@/types';
import { LabelValuePair } from '@instech/components';
import { Form, useFormikContext } from 'formik';
import { FC, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useAnchor } from '@/utils/hooks/useAnchor';
import { useInvoiceRecognition } from '@/services/invoice/invoiceServices/useInvoiceRecognition';
import { AdditionalInformationPane } from '../AdditionalInformationPane/AdditionalInformationPane';
import { OwnersClaimPane } from '../OwnersClaimPane/OwnersClaimPane';
import { TotalPane } from '../TotalPane/TotalPane';
import { invoiceDataValidationSchemaOwner } from '../utils/validationSchema';
import { InvoiceActionButtonsBatchPage } from './Headers/InvoiceActionButtonsBatchPage';
import { InvoiceActionButtonsOwner } from './Headers/InvoiceActionButtonsOwner';
import { useSaveInvoice } from './utils/useSaveInvoice';
import { createInitialValuesOwner } from '../initialValues';

export interface BatchUploadData {
  invoiceBatchList: string[];
  invoiceBatchNumber: number;
  batchId: string;
}

interface InvoiceFormOwnerProps {
  invoice: Invoice;
  claimStatement: ClaimStatement;
  supplierOptions: LabelValuePair[];
  locationOptions: LabelValuePair[];
  categoryOptions: LabelValuePair[];
  updateInvoice: (updateInvoiceForm: UpdateInvoiceForm) => Promise<Invoice | InvoiceRequestStatus>;
  batchUploadData?: BatchUploadData;
}

const InvoiceInformationInnerForm: FC<InvoiceFormOwnerProps> = (
  { invoice, claimStatement, supplierOptions, categoryOptions, locationOptions, updateInvoice, batchUploadData }
) => {
  const { values, setFieldValue } = useFormikContext<UpdateInvoiceForm>();
  const { data: invoiceRecognition } = useInvoiceRecognition(claimStatement.id, invoice.id);
  const ownerActionButtonsAnchor = useAnchor('owner-action-buttons');
  const batchActionButtonsAnchor = useAnchor('batch-action-buttons');

  const saveInvoice = useSaveInvoice(updateInvoice, values);

  useEffect(() => {
    // If the user changes currency to the same currency as the claimstatement/policy set exchangeRate to 1
    const sameCurrency = claimStatement?.currency && claimStatement?.currency === values.currency?.value;
    const differentExchangeRate = values.exchangeRate !== '1';
    if (sameCurrency && differentExchangeRate) {
      void setFieldValue('exchangeRate', 1);
    }
    const differentPaidAmount = values.paidAmount !== values.totalAmount;
    if (sameCurrency && differentPaidAmount) {
      void setFieldValue('paidAmount', values.totalAmount);
    }
  }, [claimStatement?.currency, setFieldValue, values]);

  const showOwnerButtons = !batchUploadData && ownerActionButtonsAnchor;
  const showBatchButtons = batchUploadData && batchActionButtonsAnchor;

  return (
    <Form>
      {showOwnerButtons && createPortal(
        <InvoiceActionButtonsOwner saveInvoice={saveInvoice} invoice={invoice} />,
        ownerActionButtonsAnchor
      )}
      {showBatchButtons && createPortal(
        <InvoiceActionButtonsBatchPage saveInvoice={saveInvoice} invoice={invoice} {...batchUploadData} />,
        batchActionButtonsAnchor
      )}
      <OwnersClaimPane recognitionValues={invoiceRecognition} supplierOptions={supplierOptions} policyCurrency={claimStatement?.currency} />
      <AdditionalInformationPane categoryOptions={categoryOptions} locationOptions={locationOptions} />
      <TotalPane invoiceRecord={invoice.record} policyCurrency={claimStatement?.currency} />
    </Form>
  );
};

export const InvoiceFormOwner: FC<InvoiceFormOwnerProps> = (
  { invoice, claimStatement, supplierOptions, locationOptions, categoryOptions, updateInvoice, batchUploadData }
) => (
  <FormikWithPrompt
    initialValues={createInitialValuesOwner(invoice.record)}
    validationSchema={invoiceDataValidationSchemaOwner}
    onSubmit={updateInvoice}
    validateOnBlur={false}
    validateOnChange={false}
    enableReinitialize>
    <InvoiceInformationInnerForm
      invoice={invoice}
      claimStatement={claimStatement}
      supplierOptions={supplierOptions}
      categoryOptions={categoryOptions}
      locationOptions={locationOptions}
      updateInvoice={updateInvoice}
      batchUploadData={batchUploadData}
    />
  </FormikWithPrompt>
);
