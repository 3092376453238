import {
  Guid, NewClaimStatement, Occurrence
} from '@/types';
import { FieldArrayRenderProps, useFormikContext } from 'formik';
import { FC } from 'react';
import { Plus } from '@instech/icons';
import styled from 'styled-components';
import { SlimButton } from '@instech/components';
import { useTypedModal } from '@/common/modules/Modal';
import {
  createOccurrenceFromInsModal,
  createOccurrenceFromOrder
} from '@/components/pages/claim/core/ClaimStatementForm/Occurrences';
import { isConnectedToIns } from '@/services/occurrenceServices';
import { OccurrenceItem } from './OccurrenceItem';

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const OccurrenceButton = styled(SlimButton)`
  margin: 10px;
  & span {
    font-size: 14px;
  }
`;

interface FormProps {
  currency: string;
  arrayHelpers: FieldArrayRenderProps;
  isExperimentalClaimStatement: boolean;
}
export const OccurrenceInnerForm: FC<FormProps> = ({ currency, arrayHelpers, isExperimentalClaimStatement }) => {
  const { values: { occurrences } } = useFormikContext<NewClaimStatement>();

  const occurrencesConnectedToIns = occurrences.filter(isConnectedToIns);
  const isTheOnlyOccurrenceConnectedToIns = (occurrenceId: Guid) => occurrencesConnectedToIns.length === 1 && occurrencesConnectedToIns[0].id === occurrenceId;

  const updateItem = (index: number, update: Occurrence) => {
    if (occurrences) {
      const aNewOccurrence: Occurrence = {
        ...occurrences[index],
        insuranceYear: update.insuranceYear,
        claimNumber: update.claimNumber,
        interest: update.interest,
        claimDate: update.claimDate
      };

      arrayHelpers.replace(index, aNewOccurrence);
    }
  };

  const handleCreateManualOccurrence = async () => {
    if (occurrences.length > 0) {
      arrayHelpers.push(createOccurrenceFromOrder(occurrences.length + 1));
    }
  };

  const handleAddOccurrenceFromIns = (occurrence: Occurrence) => {
    arrayHelpers.push(occurrence);
  };

  const { open: openCreateOccurrenceFromIns } = useTypedModal(createOccurrenceFromInsModal({
    modalTitle: 'Add Occurrence from INS+',
    occurrenceOrderNumber: occurrences.length + 1,
    currency,
    isExperimentalClaimStatement,
    onSubmit: handleAddOccurrenceFromIns,
  }));

  const removeItem = (index: number) => arrayHelpers.remove(index);

  return (
    <>
      {occurrences.map((occurrence, index) => (
        <OccurrenceItem
          occurrence={occurrence}
          index={index}
          key={occurrence.id}
          isExperimentalClaimStatement={isExperimentalClaimStatement}
          updateOccurrence={updateItem}
          removeOccurrence={removeItem}
          disableRemove={isTheOnlyOccurrenceConnectedToIns(occurrence.id)}
          currency={currency} />
      ))}
      <ButtonGroup>
        <OccurrenceButton
          disabled={occurrences.length === 0}
          variant="secondary"
          startIcon={<Plus />}
          onClick={handleCreateManualOccurrence}>
          ADD MANUAL OCCURRENCE
        </OccurrenceButton>
        <OccurrenceButton
          disabled={occurrences.length === 0}
          variant="secondary"
          startIcon={<Plus />}
          onClick={openCreateOccurrenceFromIns}>
          ADD OCCURRENCE FROM INS+
        </OccurrenceButton>
      </ButtonGroup>
    </>
  );
};
