import { InvoiceUploadStatus, StagedInvoice } from '@/types';
import { useEffect } from 'react';

type UploadStatusOrder = { [key in InvoiceUploadStatus]: number };
const uploadStatusOrderMap: UploadStatusOrder = {
  Success: 1,
  inProgress: 2,
  Failed: 0,
  SizeTooLarge: 0,
  InvalidFileType: 0,
  Duplicate: 0,
};
const orderByUploadStatus = (a: StagedInvoice, b: StagedInvoice) => {
  if (a.order !== undefined && b.order !== undefined) {
    return a.order - b.order;
  }
  return uploadStatusOrderMap[a.uploadStatus] - uploadStatusOrderMap[b.uploadStatus];
};

const isStagedAlreadySorted = (stagedInvoices: readonly StagedInvoice[]) => {
  if (stagedInvoices.length < 2) {
    return true;
  }

  const statuses = stagedInvoices.map(invoice => invoice.uploadStatus);
  const sortedByStatus = statuses.slice(1)
    .every((status, index) => uploadStatusOrderMap[status] >= uploadStatusOrderMap[statuses[index]]);
  const orderList = stagedInvoices.map(i => i.order).filter(i => i !== undefined) as number[];
  const sortedByOrder = orderList.slice(1).every((order, index) => order >= orderList[index]);
  return sortedByStatus && sortedByOrder;
};

export const useSortedStagedInvoices = (stagedInvoices: readonly StagedInvoice[], setStagedInvoices: (invoices: StagedInvoice[]) => void) =>
  useEffect(() => {
    if (isStagedAlreadySorted(stagedInvoices)) {
      return;
    }

    const sorted = [...stagedInvoices].sort(orderByUploadStatus);
    setStagedInvoices(sorted);
  }, [stagedInvoices, setStagedInvoices]);
