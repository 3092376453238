import {
  InfoLayout, InfoWithLabelRequired, InfoWithLabel
} from '@/common/components/Text';
import { InvoiceRecord } from '@/types';
import { prependDashOrEmpty } from '@/utils/text';
import { FunctionComponent as FC } from 'react';
import { formatDateOrNull } from '@/utils/formatDate';
import { formatNumberWithTwoDecimalsOrNull, formatNumberWithTwelveDecimalsOrNull } from '../utils';
import { useExchangeRateLabel } from '../../../utils/useExchangeRateLabel';

interface Props {
  record: InvoiceRecord;
  policyCurrency: string;
}
export const ClaimInfoRead: FC<Props> = ({ record, policyCurrency }) => {
  // cast invoice currency to empty string if it is nullish, for the labelling
  const currencyOrEmpty = record.currency || '';
  const exchangeRateLabel = useExchangeRateLabel(record.currency, policyCurrency);
  return (
    <InfoLayout columnMin="160px">
      <InfoWithLabelRequired
        label="Contractor / Supplier"
        text={record.supplier}
      />
      <InfoWithLabelRequired
        label="Invoice Number"
        text={record.invoiceNumber}
      />
      <InfoWithLabelRequired
        label="Payment Date"
        text={formatDateOrNull(record.paymentDate)}
      />
      <InfoWithLabel
        label="Client Reference"
        text={record.clientReference}
      />
      <InfoWithLabelRequired
        label="Invoice Currency"
        text={currencyOrEmpty}
      />
      <InfoWithLabelRequired
        label="Invoice Total Amount"
        text={formatNumberWithTwoDecimalsOrNull(record.totalAmount)}
      />
      <InfoWithLabelRequired
        label={exchangeRateLabel}
        text={formatNumberWithTwelveDecimalsOrNull(record.exchangeRate)}
      />
      <InfoWithLabel
        label={`Invoice Total Amount ${prependDashOrEmpty(policyCurrency)}`}
        text={formatNumberWithTwoDecimalsOrNull(record.paidAmount)}
      />
      <InfoWithLabel
        label={`Claim ${prependDashOrEmpty(policyCurrency)}`}
        text={formatNumberWithTwoDecimalsOrNull(record.claimInPolicyCurrency)}
      />
      <InfoWithLabel
        label={`Owner ${prependDashOrEmpty(policyCurrency)}`}
        text={formatNumberWithTwoDecimalsOrNull(record.ownersWorkInPolicyCurrency)}
      />
    </InfoLayout>
  );
};
