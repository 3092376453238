import styled from 'styled-components';
import { TableRowItem } from '@/apps/ClaimStatement/components/InvoiceSplitTable';

const RowStyle = styled.div`
  display: contents;
  background-color: ${props => props.theme.marineBlue20};
  color: ${props => props.theme.marineBlue};
  font-weight: bold;
`;

interface TotalLineSurveyorsProps {
  claim: number | string;
  ownersWork: number | string;
  generalExpenses: number | string;
  adjustersConsideration: number | string;
  sum: number | string
}
export const TotalLineSurveyorsConsideration = (totalLine: TotalLineSurveyorsProps) => (
  <RowStyle>
    <TableRowItem emphasize="Medium">Sum</TableRowItem>
    <TableRowItem emphasize="Medium" />
    <TableRowItem justify="right" emphasize="Medium">{totalLine.claim}</TableRowItem>
    <TableRowItem emphasize="Medium" />
    <TableRowItem justify="right" emphasize="Medium">{totalLine.ownersWork}</TableRowItem>
    <TableRowItem justify="right" emphasize="Medium">{totalLine.generalExpenses}</TableRowItem>
    <TableRowItem emphasize="Medium" />
    <TableRowItem justify="right" emphasize="Medium">{totalLine.adjustersConsideration}</TableRowItem>
    <TableRowItem justify="right" emphasize="High">{totalLine.sum}</TableRowItem>
    <TableRowItem emphasize="Medium" />
  </RowStyle>
);
