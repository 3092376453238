import { CollapsiblePane, RichTextEditor } from '@instech/components';
import { Formik } from 'formik';
import { FC } from 'react';
import styled from 'styled-components';
import { UpdateAdjustmentOverviewRequest } from '@/apps/Adjustment/types/adjustmentOverview';
import { FormWithAutoSave } from '../../../core/Components';

const EditorWrapper = styled.div`
  .editor-inner {
    border: 1px solid ${({ theme }) => theme.marineBlue60};
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 5px 0px;
    min-height: 400px;
  }
`;

const initialValues = (summary: string | null | undefined) =>
  ({ summary: summary ?? '' });

export type FormValues = { summary: string };

interface Props {
  summary: string | null | undefined;
  save: (request: UpdateAdjustmentOverviewRequest) => Promise<void>;
 }
export const SummaryOfFactsAndDatesPane: FC<Props> = ({ summary, save }) => {
  const handleSubmit = async (form: { summary: string }) => {
    const request = { summary: { value: form.summary } };
    await save(request);
  };

  return (
    <CollapsiblePane color="green" title="Summary of Facts & Dates" padding="20px">
      <Formik initialValues={initialValues(summary)} onSubmit={handleSubmit}>
        <FormWithAutoSave>
          <EditorWrapper>
            <RichTextEditor name="summary" />
          </EditorWrapper>
        </FormWithAutoSave>
      </Formik>
    </CollapsiblePane>
  );
};
