import { InvoiceState } from '@/types';
import { invoiceStates } from '../constants';

export const invoiceStatesFinished: InvoiceState[] = [
  invoiceStates.Finished,
  invoiceStates.FinishedNoAllocation,
  invoiceStates.FinishedSharedReview
];

export const invoiceStatesConsidered: InvoiceState[] = [
  invoiceStates.AdjustersConsideration,
  invoiceStates.ReturnToUploaderAfterConsideration,
  invoiceStates.Finished
];
