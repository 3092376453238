import {
  formatBytes, IconButton, SimpleTable, TableCell,
  TableHeader, TableRow, TruncatingTextTableCell
} from '@instech/components';
import { Remove } from '@instech/icons';
import React from 'react';
import styled from 'styled-components';
import { theme } from '@/utils/style/theme';
import {
  Guid, InvoiceUploadStatus, StagedInvoice
} from '@/types';
import { getStatusIcon } from './StatusIcon';

const ContentArea = styled.div`
  margin-top: 40px;
  color: ${props => props.theme.marineBlue};
  background-color: ${props => props.theme.white};
  padding-bottom: 20px;
  > div {
    // hacky workaround to force white bg color on the table,
    // since it does not export className for styled components
    background-color: ${props => props.theme.white};
  }
`;

const HeaderText = styled.div`
  padding: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
`;

const StyledHeader = styled(TableHeader)`
  border-bottom: 1px solid ${props => props.theme.marineBlue};
  color: ${props => props.theme.marineBlue};
  &:nth-child(1) {
    padding-left: 0px;
    margin-left: 20px;
  }
  &:nth-child(4) {
    padding-right: 0px;
    margin-right: 20px;
  }
`;

const StyledRow = styled(TableRow) <{ color: string }>`
  color: ${props => props.color};
  > span {
    justify-content: center;
    padding-bottom: 0px;
  }
`;

const CloseButton = styled(IconButton)`
  color: ${props => props.theme.marineBlue};
  & svg {
    width: 22px;
    stroke-width: 1.5;
  }
`;

const gridLayout = `
  300px
  150px
  100px
  auto
`;

const getStatusColor = (status: InvoiceUploadStatus) => {
  switch (status) {
    case 'Duplicate': return theme.marineBlue50;
    case 'Failed': return theme.warning;
    case 'SizeTooLarge': return theme.warning;
    case 'InvalidFileType': return theme.warning;
    default: return theme.marineBlue;
  }
};

interface RowProps {
  invoice: StagedInvoice;
  onRemoveItem: (invoiceId: Guid) => void;
}
const FileRow = ({ invoice, onRemoveItem }: RowProps) => (
  <StyledRow even={false} color={getStatusColor(invoice.uploadStatus)}>
    <TruncatingTextTableCell text={invoice.name} />
    <TableCell>{formatBytes(invoice.sizeInBytes)}</TableCell>
    <TableCell>
      <CloseButton
        icon={<Remove />}
        onClick={() => onRemoveItem(invoice.id)}
      />
    </TableCell>
    <TableCell>{getStatusIcon(invoice.uploadStatus)}</TableCell>
  </StyledRow>
);

interface TableProps {
  uploadedInvoices?: StagedInvoice[];
  onRemoveItem: (invoiceId: Guid) => void;
}
export const UploadTable = ({ uploadedInvoices, onRemoveItem }: TableProps) => {
  if (!uploadedInvoices?.length) {
    // TODO: How should this component handle appearing on page?
    return null;
  }

  return (
    <ContentArea>
      <HeaderText>Uploaded files</HeaderText>
      <SimpleTable layout={gridLayout}>
        <StyledHeader>Filename</StyledHeader>
        <StyledHeader>Size</StyledHeader>
        <StyledHeader>Remove</StyledHeader>
        <StyledHeader />
        {uploadedInvoices.map((invoice, index) => (
          <FileRow
            key={invoice.id}
            invoice={invoice}
            onRemoveItem={onRemoveItem}
          />
        ))}
      </SimpleTable>
    </ContentArea>
  );
};
