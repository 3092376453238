import { UpdateInvoiceForm } from '@/types';
import { formatNumberWithTwoDecimals, truncateDecimals } from '@/utils/number';
import { multiplyWithPrecision } from '@/utils/invoice/convertCurrency';

export const useFormattedAmount = (
  { totalAmount: totalAmountRaw, exchangeRate: exchangeRateRaw, currency }: UpdateInvoiceForm,
  showInvoiceCurrency: boolean,
  policyCurrency?: string
) => {
  const totalAmount = Number(totalAmountRaw);
  const exchangeRate = Number(exchangeRateRaw);
  if (Number.isNaN(totalAmount) || Number.isNaN(exchangeRate) || !currency?.value || !policyCurrency) {
    return '';
  }

  if (showInvoiceCurrency) {
    const truncatedVal = truncateDecimals(totalAmount, 2);
    return `${formatNumberWithTwoDecimals(truncatedVal)} ${currency.value}`;
  }

  const truncatedVal = truncateDecimals(multiplyWithPrecision(totalAmount, exchangeRate), 2);
  return `${formatNumberWithTwoDecimals(truncatedVal)} ${policyCurrency}`;
};
