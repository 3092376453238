import {
  FC, useCallback, useEffect
} from 'react';
import {
  CollapsiblePane, DropdownButton, SlimButton
} from '@instech/components';
import {
  useAdjustmentPdfDownloadLink,
  useAdjustmentPdfGeneration,
  useLatestAdjustmentPdf
} from '@/services/adjustmentPdfServices';
import { useRequireClaimStatementId } from '@/utils/useRequireClaimStatementId';
import { PdfViewer } from '@/common/components/File/PdfViewer';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { Download, RefreshDotted } from '@instech/icons';
import { useTypedModal } from '@/common/modules/Modal';
import { generatingAdjustmentPdfModal } from './GeneratingAdjustmentPdfModal';

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PdfPreviewWrapper = styled.div`
  height: 100%;
  aspect-ratio: 1 / 1.4142;
  overflow-y: auto;
  margin-top: 20px;
  padding: 6px;
`;

const RegularText = styled.p`
  padding-left: 6px;
  color: ${props => props.theme.marineBlue};
  font-size: 14px;
`;

const PageStyle = css`
  margin-bottom: 20px;
  box-shadow: 0px 2px 6px rgba(0, 60, 113, 0.25);
`;

interface GeneratedAtProps {
  generatedAt: string
}
const formatGeneratedAt = (generatedAt: string) => (
  moment.parseZone(generatedAt).local().format('HH:mm DD MMM. YY')
);
const GeneratedAt: FC<GeneratedAtProps> = ({ generatedAt }) => (
  <RegularText>{`Generated ${formatGeneratedAt(generatedAt)}`}</RegularText>
);

export const PdfPreviewPane: FC = () => {
  const claimStatementId = useRequireClaimStatementId();
  const { pdf, isValidating: isLoadingPdf } = useLatestAdjustmentPdf(claimStatementId);

  const downloadLinkState = useAdjustmentPdfDownloadLink(claimStatementId, pdf?.id);
  const pdfGenerationState = useAdjustmentPdfGeneration(claimStatementId);

  const modal = useTypedModal(generatingAdjustmentPdfModal({}));

  useEffect(() => {
    if (pdfGenerationState.state === 'success') {
      modal.close();
    }
  }, [modal, pdfGenerationState]);

  const generatePdf = useCallback(() => {
    if (pdfGenerationState.state !== 'generating') {
      modal.open();
      pdfGenerationState.generate(false);
    }
  }, [modal, pdfGenerationState]);

  const downloadPdf = useCallback(() => {
    if (downloadLinkState.downloadLink) {
      window.open(downloadLinkState.downloadLink?.url, '_blank', 'noreferrer');
    }
  }, [downloadLinkState]);

  return (
    <CollapsiblePane color="green" title="Adjustment PDF Preview" padding="20px">
      {!pdf && !isLoadingPdf && (
        <Layout>
          <RegularText>It takes 5 to 60 seconds to generate the preview.</RegularText>
          <SlimButton
            variant="secondary"
            disabled={pdfGenerationState.state === 'generating'}
            onClick={generatePdf}>
            GENERATE NEW PDF
          </SlimButton>
        </Layout>
      )}
      {pdf && (
        <Layout>
          <GeneratedAt generatedAt={pdf.createdAt} />
          <DropdownButton
            variant="secondary"
            disabled={pdfGenerationState.state === 'generating'}
            icon={<RefreshDotted />}
            onClick={generatePdf}
            dropdownOptions={[
              {
                label: 'DOWNLOAD PDF',
                icon: (<Download />),
                onClick: downloadPdf
              }
            ]}
          >
            GENERATE NEW PDF
          </DropdownButton>
        </Layout>
      )}
      {downloadLinkState.downloadLink?.url && (
        <PdfPreviewWrapper>
          <PdfViewer
            pageStyle={PageStyle}
            src={downloadLinkState.downloadLink.url}
          />
        </PdfPreviewWrapper>
      )}
    </CollapsiblePane>
  );
};
