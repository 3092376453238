import { SmallLabel } from '@/common/components/Text';
import { Invoice } from '@/types';
import { FC } from 'react';
import styled from 'styled-components';

const CommentText = styled.div`
  color: ${props => props.theme.marineBlue};
  white-space: pre-line;
`;

interface Props {
  invoice: Invoice;
}
export const OwnersCommentReadonly: FC<Props> = ({ invoice }) => (
  <div data-testid="owners-review-comment-readonly">
    <SmallLabel>Owners&apos; comment on Claim Review</SmallLabel>
    <CommentText>{invoice.ownersReviewComment}</CommentText>
  </div>
);
