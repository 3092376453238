import { useModalContext } from '@/common/modules/Modal';
import { ModalType } from '@/common/modules/Modal/types';
import { FormRow } from '@/common/components/Form/FormRow/FormRow';
import { getClaimDataByCurrencyAsync } from '@/services/claimServices';
import { ClaimsData, Occurrence } from '@/types';
import {
  ButtonGroup,
  NumberFieldControlled,
  SlimButton
} from '@instech/components';
import { MoveLayerDown } from '@instech/icons';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AxiosError } from 'axios';
import { createOccurrenceFromClaimsData } from '@/components/pages/claim/core/ClaimStatementForm/Occurrences';
import { ClaimsDataDetails } from '../../ClaimDetails';
import { SearchErrorMessage, useErrorToggler } from './SearchErrorMessage';

const SearchRow = styled(FormRow)`
  max-width: 930px;
  align-items: flex-end;
`;

const Area = styled.div`
  margin-bottom: 40px;
`;

const SearchButton = styled(SlimButton)`
  height: 40px;
`;

interface SearchStateProp {
  currency: string;
  number: number | string;
  year: number | string;
}

interface Props {
  occurrenceOrderNumber: number;
  currency: string;
  isExperimentalClaimStatement: boolean;
  onSubmit: (data: Occurrence) => (Promise<void> | void);
}

const ModalContent = ({ occurrenceOrderNumber, currency, isExperimentalClaimStatement, onSubmit }: Props) => {
  const [search, setSearch] = useState<SearchStateProp>({ currency, number: '', year: '' });
  const [submitting, setSubmitting] = useState(false);
  const [claimData, setClaimData] = useState<ClaimsData>();
  const { close } = useModalContext();
  const inputErrorData = useErrorToggler();
  const warningBoxData = useErrorToggler();
  const [isSaving, setIsSaving] = useState(false);

  const handleSearch = async () => {
    if (!currency || !search.number || !search.year) return;
    setSubmitting(true);

    try {
      const data = await getClaimDataByCurrencyAsync(search.number.toString(), search.year.toString(), currency);
      setClaimData(data);
      warningBoxData.hideError();
    } catch (e) {
      const statusCode = (e as AxiosError)!.response!.status;
      const claimSearchData = { claimNumber: search.number, claimYear: search.year };
      inputErrorData.showError(statusCode, claimSearchData);
      warningBoxData.showError(statusCode, claimSearchData);
    } finally {
      setSubmitting(false);
    }
  };

  const onWarningClose = async () => {
    inputErrorData.hideError();
    warningBoxData.hideError();
  };

  useEffect(() => {
    if (search) inputErrorData.hideError();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- deliberate
  }, [inputErrorData.hideError, search]);

  const confirmUpdate = async () => {
    if (claimData === undefined) {
      return;
    }

    const occurrence = createOccurrenceFromClaimsData(claimData, occurrenceOrderNumber);
    const result = onSubmit(occurrence);
    if (result instanceof Promise) {
      setIsSaving(true);
      try {
        await result;
      } finally {
        setIsSaving(false);
      }
    }
    close();
  };

  const disableUpdate = claimData === undefined;
  return (
    <>
      <SearchRow gutter="32px" layoutLarge="1fr 1fr 156px">
        <NumberFieldControlled
          name="occurrence-search-number"
          label="Claim Number"
          placeholder="Number"
          value={search.number}
          onChange={val => setSearch(prev => ({ ...prev, number: val || '' }))}
          onBlur={() => null}
          required
          noArrows
          touched
          noErrors
          error={inputErrorData.isErrorVisible ? 'true' : undefined}
        />
        <NumberFieldControlled
          name="occurrence-search-year"
          label="Insurance Year"
          placeholder="Year"
          value={search.year}
          onChange={val => setSearch(prev => ({ ...prev, year: val || '' }))}
          onBlur={() => null}
          required
          noArrows
          touched
          noErrors
          error={inputErrorData.isErrorVisible ? 'true' : undefined}
        />
        <SearchButton loading={submitting} disabled={submitting} startIcon={<MoveLayerDown />} onClick={handleSearch}>GET CLAIM DATA</SearchButton>
      </SearchRow>
      {warningBoxData.isErrorVisible && <SearchErrorMessage onClose={onWarningClose} errorToggleData={inputErrorData} includeCurrency />}
      <Area>
        <ClaimsDataDetails
          data={claimData}
          isExperimentalClaimStatement={isExperimentalClaimStatement}
          maxColumns={5}
        />
      </Area>
      <ButtonGroup alignRight>
        <SlimButton variant="secondary" onClick={close}>CANCEL</SlimButton>
        <SlimButton width="128px" disabled={disableUpdate || isSaving} loading={isSaving} onClick={confirmUpdate}>SAVE OCCURRENCE</SlimButton>
      </ButtonGroup>
    </>
  );
};

interface ModalProps extends Props {
  modalTitle: string;
}
export const createOccurrenceFromInsModal = ({ modalTitle, ...args }: ModalProps): ModalType<Props> => ({
  component: ModalContent,
  options: {
    title: modalTitle,
    size: 'large'
  },
  args
});
