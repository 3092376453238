import {
  AdjustersConsiderationSplitForm, FCWC, SurveyorsConsiderationSplitForm
} from '@/types';
import {
  useState, createContext, useMemo, useContext,
} from 'react';

type ConsiderationSplitFormData = AdjustersConsiderationSplitForm[] | SurveyorsConsiderationSplitForm[];

interface ConsiderationContextProps {
  getConsiderationData: <T extends ConsiderationSplitFormData>() => T,
  setConsiderationData: <T extends ConsiderationSplitFormData>(adjusterConsiderationForm: T) => void;
}
const ConsiderationContext = createContext<ConsiderationContextProps | null>(null);

export const ConsiderationProvider: FCWC = ({ children }) => {
  const [consideration, setConsideration] = useState<ConsiderationSplitFormData | undefined>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getConsiderationData = <T extends ConsiderationSplitFormData>() => consideration as T;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setConsiderationData = <T extends ConsiderationSplitFormData>(considerationForm: T) => {
    setConsideration(considerationForm);
  };

  const value = useMemo(() => ({
    getConsiderationData, setConsiderationData
  }), [getConsiderationData, setConsiderationData]);

  return (
    <ConsiderationContext.Provider value={value}>
      {children}
    </ConsiderationContext.Provider>
  );
};

export const useConsiderationContext = () => useContext(ConsiderationContext) as ConsiderationContextProps;
