import {
  SupportingDocumentForm, SupportingDocumentResponse, documentCategoryValues
} from '@/services/supportingDocumentsServices';
import { piiOptions } from '@/utils/invoice/invoicePiiOptions';

export const createInitialValues = (values: Partial<SupportingDocumentForm> = {}): SupportingDocumentForm => ({
  documentName: '',
  documentCategory: { label: 'Payment Confirmation', value: documentCategoryValues.paymentConfirmation },
  pii: '',
  piiDescription: '',
  ...values
});

export const createInitialEditPaymentConfirmationValues = (values: SupportingDocumentResponse): SupportingDocumentForm => ({
  documentName: values.documentName ?? '',
  documentCategory: { label: 'Payment Confirmation', value: documentCategoryValues.paymentConfirmation },
  pii: piiOptions.find(p => p.value === values.pii) ?? '',
  piiDescription: values.piiDescription,
});
