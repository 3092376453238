import { LabelValuePair } from '@instech/components';

/**
 * Converts array of strings to an array of { label, value } pairs.
 */
export const arrayToLabelValuePair = (arr: string[]) =>
  arr.map(item => ({ label: item, value: item } as LabelValuePair));

/**
 * Converts a single string to a single { label, value } pair.
 */
export const stringToLabelValuePair = (value?: string | null): LabelValuePair | undefined =>
  value ? { label: value, value } : undefined;
