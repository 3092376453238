import React from 'react';
import styled from 'styled-components';
import { Pane } from '@instech/components';

const NoInvoiceText = styled.div`
  padding: 40px;
  color: ${props => props.theme.marineBlue60};
  font-style: italic;
`;

export const NoInvoicePane = () => (
  <Pane title="Invoices" color="green" padding="0px">
    <NoInvoiceText>No invoices have been submitted yet</NoInvoiceText>
  </Pane>
);
