import React from 'react';
import styled from 'styled-components';
import { Update as UpdatesPageWidget } from 'insify-remote-component-loader';
import { useAccount } from '@/common/authentication/AuthenticationProvider';
import { useNavigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';

const ContentArea = styled.div`
  margin: 0 auto;
`;

const Page = styled.div`
background: ${props => props.theme.whiteBlue};
min-height: 100vh;
`;

const portal = {
  name: 'claimstatement',
  displayName: 'Claim Statement'
};

export const UpdatesPage = () => {
  const account = useAccount();
  const navigate = useNavigate();
  const history = createBrowserHistory();
  history.push = (to, state) => navigate(to, state);
  history.replace = (to, state) => navigate(to, { ...state, replace: true });

  return (
    <Page>
      <ContentArea>
        <UpdatesPageWidget account={account} history={history} portal={portal} />
      </ContentArea>
    </Page>
  );
};
