import { FC } from 'react';
import styled from 'styled-components';
import { TableCell, TableCellProps } from '@instech/components';
import {
  textOrNone, ellipsisOrDash, textOrDash
} from '@/utils/text';
import { collapseGroups } from '@/common/components/Table/utils';
import { TableCellHoverStyles } from '@/common/components/Table/core/TableCell/TableCellHoverStyles';
import { TableCellMaxContent } from './TableCellMaxContent';
import { TableCellVariantType } from '../../types';

interface Props extends TableCellProps {
  comment: string | null | undefined;
  showFullComment?: boolean;
  isFinished?: boolean;
  cellVariant?: TableCellVariantType;
  collapseGroup?: collapseGroups;
  tooltipId?: string;
}

const FullCommentCell = styled(TableCell)`
  min-width: 500px;
  max-width: 900px;
  white-space: pre-wrap;
  ${TableCellHoverStyles};
`;

export const TableCellComment: FC<Props> = ({
  comment,
  showFullComment,
  tooltipId,
  ...props
}) => {
  if (showFullComment) {
    return (
      <FullCommentCell {...props}>
        <span>
          {textOrDash(comment)}
        </span>
      </FullCommentCell>
    );
  }

  return (
    <TableCellMaxContent {...props}>
      <span data-for={tooltipId} data-tip={textOrNone(comment)}>
        {ellipsisOrDash(comment)}
      </span>
    </TableCellMaxContent>
  );
};
