import { Tag } from '@instech/icons';
import styled, { css } from 'styled-components';

export const StyledTag = styled(Tag) <{ $filled?: boolean, $small?: boolean }>`
  path, circle {
    transition: fill 0.2s ease-out;
  }
  ${({ theme, $filled, $small }) => css`
    padding: ${$small ? '0px 4px 0px 0px' : '8px'};
    width: ${$small ? '12px' : '16px'};
    height: ${$small ? '12px' : '16px'};
    #inside path {
      fill: ${$filled ? theme.marineBlue : 'transparent'};
    }
    #hole circle {
      fill: ${$filled ? theme.white : theme.marineBlue};
    }
  `};
`;
