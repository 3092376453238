import {
  AdjustersConsideration,
  AdjustersConsiderationSplit,
  InvoiceRecord,
  surveyorClaimConsiderationTags,
  surveyorGeneralExpensesTags,
  SurveyorsConsiderationSplit,
  UpdateInvoiceForm,
  UpdateSurveyorsConsiderationForm
} from '@/types';
import { stringToLabelValuePair } from '@/utils/labelValuePair';
import { LabelValuePair } from '@instech/components';
import { additionalInformationInitialValues } from '../AdditionalInformationPane/AdditionalInformationPane';
import { ownersClaimInitialValues } from '../OwnersClaimPane/OwnersClaimPane';
import { newSurveyorsConsiderationItem } from '../SurveyorsConsiderationPane';
import { newSurveyorsConsiderationFormValues } from '../SurveyorsConsiderationPane/SurveyorsConsiderationPane';
import { totalInitialValues } from '../TotalPane/TotalPane';
import { initialValuesToConsiderations } from '../initialValsToConsiderations';
import {
  multipleCategoriesLabelValuePair,
  multipleRepairPeriodsLabelValuePair
} from '../utils/multipleDropdownValue';

type AnyRecord = InvoiceRecord | SurveyorsConsiderationSplit | AdjustersConsideration | AdjustersConsiderationSplit;

const dropdownOptionsClaim = (invoice: InvoiceRecord): UpdateInvoiceForm => ({
  supplier: stringToLabelValuePair(invoice.supplier),
  currency: stringToLabelValuePair(invoice.currency)
});

// some pairs have a different label than value
const piiLabelValuePair = (piiValue?: string | null) => {
  if (piiValue === 'IncludesEu') {
    return {
      value: 'IncludesEu',
      label: 'Yes - Information is concerning EU citizen(s)'
    };
  }
  return stringToLabelValuePair(piiValue);
};

const dropdownOptionsInfo = (invoice: InvoiceRecord): UpdateInvoiceForm => ({
  category: invoice.category === multipleCategoriesLabelValuePair.value ? multipleCategoriesLabelValuePair : stringToLabelValuePair(invoice.category),
  location: stringToLabelValuePair(invoice.location),
  repairPeriod: invoice.repairPeriod === multipleRepairPeriodsLabelValuePair.value ?
    multipleRepairPeriodsLabelValuePair : stringToLabelValuePair(invoice.repairPeriod),
  personallyIdentifiableInformation: piiLabelValuePair(invoice.personallyIdentifiableInformation),
  personallyIdentifiableInformationDescription: invoice.personallyIdentifiableInformationDescription ?? ''
});

const filterAwayNulls = (record?: AnyRecord): UpdateInvoiceForm => {
  if (!record) return {};
  const initializedRecord = initialValuesToConsiderations(record);
  return Object.entries(initializedRecord)
    .filter(value => value[1] !== null)
    .reduce((accumulated, current) => ({
      ...accumulated,
      [current[0]]: current[1]
    }), {});
};

export const createInitialValuesOwner = (invoice: InvoiceRecord): UpdateInvoiceForm => ({
  ...additionalInformationInitialValues,
  ...ownersClaimInitialValues,
  ...totalInitialValues,
  ...filterAwayNulls(invoice),
  ...dropdownOptionsClaim(invoice),
  ...dropdownOptionsInfo(invoice),
  totalAmount: `${invoice.totalAmount ?? ''}`,
  paidAmount: `${invoice.paidAmount ?? ''}`,
  exchangeRate: `${invoice.exchangeRate ?? ''}`,
});

const categoryValueIfOneSplit = (invoiceRecord: InvoiceRecord, splits: SurveyorsConsiderationSplit[]) => {
  const singleSplitWithSingleCategory = splits.length === 1 && (invoiceRecord.category !== multipleCategoriesLabelValuePair.value);
  if (singleSplitWithSingleCategory) {
    return invoiceRecord.category;
  }
  return undefined;
};

export const createInitialValuesSurveyor = (
  invoiceRecord: InvoiceRecord,
  occurrenceOptions: LabelValuePair[],
  surveyorSplits?: SurveyorsConsiderationSplit[],
  isConsidered?: boolean
): UpdateSurveyorsConsiderationForm => {
  const splits = surveyorSplits ?? [];
  const cat = categoryValueIfOneSplit(invoiceRecord, splits);
  return ({
    ...dropdownOptionsInfo(invoiceRecord),
    splits: splits.map(split => ({
      ...newSurveyorsConsiderationItem(occurrenceOptions[0]),
      ...filterAwayNulls(split),
      form: newSurveyorsConsiderationFormValues(isConsidered, !!split?.internalNotes, true),
      generalExpensesTag: surveyorGeneralExpensesTags.find(tag => tag.value === split?.generalExpensesTag),
      claimConsiderationTag: surveyorClaimConsiderationTags.find(tag => tag.value === split?.claimConsiderationTag),
      occurrence: occurrenceOptions.find(o => o.value === split.occurrenceId) ?? occurrenceOptions[0],
      category: stringToLabelValuePair(cat ?? (split.category === multipleCategoriesLabelValuePair.value ? undefined : split.category))
    }))
  });
};
