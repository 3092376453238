import { useModalContext } from '@/common/modules/Modal';
import {
  AdjustersConsiderationSplitForm,
  SurveyorsConsiderationSplitForm
} from '@/types';
import { DragEndEvent } from '@dnd-kit/core';
import { useState } from 'react';
import { ReorderSplitsSaveButtonState } from './ReorderSplitsModal';

type SplitFormType = SurveyorsConsiderationSplitForm | AdjustersConsiderationSplitForm;
interface OrderSplitType<T extends SplitFormType, X> {
  originalSplit: T;
  id: T['id'];
  values: X;
  isDeleted?: boolean;
}
export const useReorderSplits = <T extends SplitFormType, X>(originalSplits: OrderSplitType<T, X>[], updateSplitsOrder: (splits: T[]) => void) => {
  const [orderedSplits, setOrderedSplits] = useState(originalSplits);
  const [originalSplitOrder] = useState(originalSplits.map(split => split.originalSplit));
  const [saveStatus, setSaveStatus] = useState<ReorderSplitsSaveButtonState>('idle');

  const { close } = useModalContext();

  const handleDragEnd = (event: DragEndEvent) => {
    const newOrder = [...orderedSplits];
    const element = newOrder.splice(newOrder.findIndex(s => s.id === event.active.id), 1)[0];
    newOrder.splice(orderedSplits.findIndex(s => s.id === event.over!.id), 0, element);
    setOrderedSplits(newOrder);

    const newOrderSplits = newOrder.map(split => split.originalSplit);
    updateSplitsOrder(newOrderSplits);
  };

  const handleSave = () => {
    setSaveStatus('saving');
    close();
  };

  const handleClose = () => {
    updateSplitsOrder(originalSplitOrder);
    close();
  };

  return { saveStatus, handleDragEnd, handleSave, handleClose, orderedSplits };
};
