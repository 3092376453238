import { FC } from 'react';
import styled from 'styled-components';
import { SummaryItemSmall } from '@/common/components/Summary';
import { DetailType } from '../types';
import { VesselIcon } from './VesselIcon';

/**
 * Calculate a minmax column width, between provided minWidth and a maxWidth of
 * of 1/N (where N is container width minus space taken by grid gap). This returns
 * a grid template with minimum 1 column and maximum {maxColumns}.
 */
const getColumnWidth = (minWidth: number, columnGap: number, maxColumns: number) => {
  const totalGapWidth = (maxColumns - 1) * columnGap;
  const maxWidth = `calc((100% - ${totalGapWidth}px) / ${maxColumns})`;
  return `minmax(max(${minWidth}px, ${maxWidth}), 1fr)`;
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: 68px;
`;

const DetailsWrapper = styled.div<{ maxColumns: number }>`
  display: grid;
  flex-grow: 1;
  row-gap: 16px;
  column-gap: 24px;
  grid-template-columns: ${props => (
    `repeat(auto-fill, ${getColumnWidth(110, 24, props.maxColumns)})`
  )};
  color: ${props => props.theme.marineBlue};
  // A little extra breathing room around beyond the icon
  padding: 8px 8px 16px;
`;

interface Props {
  details: DetailType[];
  showCircle?: boolean;
  maxColumns?: number;
}
export const InformationView: FC<Props> = ({ details, showCircle, maxColumns = 4 }) => {
  const isIconVisible = !!details.length;
  return (
    <Container>
      {showCircle && <VesselIcon visible={isIconVisible} />}
      <DetailsWrapper maxColumns={maxColumns}>
        {details?.map(detail => (
          <SummaryItemSmall key={detail.id} item={detail} wrapText />
        ))}
      </DetailsWrapper>
    </Container>
  );
};
