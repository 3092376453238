import { useModalContext } from '@/common/modules/Modal';
import { InfoWithLabel } from '@/common/components/Text';
import {
  SupportingDocumentForm, uploadSupportingDocument, useSupportingDocuments
} from '@/services/supportingDocumentsServices';
import { ClaimStatementInvoiceId } from '@/types';
import { Form, Formik } from 'formik';
import { FunctionComponent as FC } from 'react';
import styled from 'styled-components';
import {
  ActionButtonsGroup, DocumentCategoryDropdown, DocumentNameField, FormContent, PiiDescriptionField, PiiDropdown
} from '../../../core/ModalFormComponents';
import { createInitialValues } from '../../utils';
import { attachmentValidationSchema } from '../../attachmentValidationSchema';

const TextLabel = styled(InfoWithLabel)`
  margin-bottom: 20px;
  color: ${props => props.theme.marineBlue};
`;

interface AttachmentFormProps {
  attachment: File;
  claimStatementInvoiceId: ClaimStatementInvoiceId;
}
export const AttachmentDetailsForm: FC<AttachmentFormProps> = ({ attachment, claimStatementInvoiceId }) => {
  const { close } = useModalContext();
  const initialValues = createInitialValues();
  const { refreshSupportingDocuments } = useSupportingDocuments(claimStatementInvoiceId);

  const upload = async (values: SupportingDocumentForm) => {
    await uploadSupportingDocument(claimStatementInvoiceId, attachment, values);
    await refreshSupportingDocuments();
    close();
  };

  return (
    <Formik initialValues={initialValues} validationSchema={attachmentValidationSchema} onSubmit={upload}>
      <Form>
        <FormContent>
          <TextLabel label="Filename" text={attachment.name} />
          <DocumentNameField />
          <DocumentCategoryDropdown />
          <PiiDropdown />
          <PiiDescriptionField />
          <ActionButtonsGroup close={close} />
        </FormContent>
      </Form>
    </Formik>
  );
};
