import { getFriendlyDate } from '@instech/components';
import { useMemo } from 'react';
import {
  AtLeastOne, ClaimStatement, ClaimsData
} from '@/types';
import {
  ClaimStatementInformation,
  ClaimStatementOccurrence,
  ClaimStatementVessel,
  DetailType
} from './types';

export type TitleAndDetails = {
  title: string;
  details: DetailType[];
}

const createFieldDetail = (title: string, value?: string | number): DetailType => ({
  id: title,
  title,
  value: value ? value.toString() : '-'
});

export const useClaimStatementInformation = (
  data: ClaimStatement | undefined
): ClaimStatementInformation | undefined => useMemo(() => {
  if (data === undefined) {
    return undefined;
  }

  // Return value expects to map occurrences, so this should just
  // do nothing if there are no occurrences to actually map
  if (!data.occurrences?.length) {
    return undefined;
  }

  return {
    vesselAndPolicy: {
      vesselName: data.vesselName,
      fleetName: data.fleetName,
      yearBuilt: data.buildYear,
      imoNumber: data.imoNumber,
      class: data.class,
      policyCurrency: data.currency
    },
    occurrences: data.occurrences.map((occurrence, index) => ({
      index,
      name: occurrence.name,
      claimNumber: occurrence.claimNumber,
      lossLocation: occurrence.location,
      insuranceYear: occurrence.insuranceYear,
      dateOfLoss: occurrence.claimDate,
      interest: occurrence.interest,
    })) as AtLeastOne<ClaimStatementOccurrence>
  };
}, [data]);

/**
 * Takes Vessel Info and Policy and returns data for display
 * inside of ClaimDetails component.
 */
export const useDetailsVesselInfo = (data?: ClaimStatementVessel): TitleAndDetails => useMemo(() => {
  if (!data) {
    return { title: 'Vessel & Policy', details: [] };
  }
  const fields: DetailType[] = [];
  fields.push(createFieldDetail('Vessel Name', data.vesselName));
  fields.push(createFieldDetail('Fleet Name', data.fleetName));
  fields.push(createFieldDetail('Year Built', data.yearBuilt));
  fields.push(createFieldDetail('IMO Number', data.imoNumber));
  fields.push(createFieldDetail('Class', data.class));
  fields.push(createFieldDetail('Policy Currency', data.policyCurrency));

  return {
    title: 'Vessel & Policy',
    details: fields
  };
}, [data]);

/**
 * Takes Occurrences and returns data array for display
 * inside of ClaimDetails component.
 */
export const useDetailsOccurrences = (data?: ClaimStatementOccurrence[]): TitleAndDetails[] => useMemo(() => {
  if (!data) return [];

  const occurrenceDetails = data.map((occurrence, index) => {
    const occurrenceWithNumber = `Occurrence #${index + 1}`;
    const fields: DetailType[] = [];
    fields.push(createFieldDetail(occurrenceWithNumber, occurrence.name));
    fields.push(createFieldDetail('Loss Location', occurrence.lossLocation));
    fields.push(createFieldDetail('Date of Loss', getFriendlyDate(occurrence.dateOfLoss)));
    fields.push(createFieldDetail('Claim Number', occurrence.claimNumber));
    fields.push(createFieldDetail('Insurance Year', occurrence.insuranceYear));
    fields.push(createFieldDetail('Interest', occurrence.interest));
    return { title: occurrenceWithNumber, details: fields };
  });

  return occurrenceDetails;
}, [data]);

/**
 * Takes Claims Data and returns data for display
 * inside of ClaimDataDetails component.
 */
export const useDetailsClaimsData = (data?: ClaimsData): TitleAndDetails => useMemo(() => {
  if (!data) {
    return { title: 'Vessel & Policy', details: [] };
  }

  const fields: DetailType[] = [];
  fields.push(createFieldDetail('Claim Number', data.claimNumber));
  fields.push(createFieldDetail('Date of Loss', getFriendlyDate(data.claimDate)));
  fields.push(createFieldDetail('IMO Number', data.imoNumber));
  fields.push(createFieldDetail('Fleet Name', data.fleetName));
  fields.push(createFieldDetail('Year Built', data.buildYear));
  fields.push(createFieldDetail('Class', data.class));
  fields.push(createFieldDetail('Policy Currency', data.currency));
  fields.push(createFieldDetail('Insurance Year', data.insuranceYear));

  return {
    title: 'Vessel & Policy',
    details: fields
  };
}, [data]);

/**
 * Variant of useDetails specifically for claim review, temporary
 * fallback to retain old behaviour on this page until new component
 * has a suitable view in print.
 */
export const useDetailsClaimReview = (data?: ClaimStatement): TitleAndDetails => useMemo(() => {
  if (!data) {
    return { title: 'Vessel & Policy', details: [] };
  }
  const fields: DetailType[] = [];
  fields.push(createFieldDetail('Claim Number', data.claimNumber));
  fields.push(createFieldDetail('Date of Loss', getFriendlyDate(data.claimDate)));
  fields.push(createFieldDetail('IMO Number', data.imoNumber));
  fields.push(createFieldDetail('Fleet Name', data.fleetName));
  fields.push(createFieldDetail('Year Built', data.buildYear));
  fields.push(createFieldDetail('Class', data.class));
  fields.push(createFieldDetail('Policy Currency', data.currency));
  fields.push(createFieldDetail('Insurance Year', data.insuranceYear));

  return {
    title: 'Vessel & Policy',
    details: fields
  };
}, [data]);
