import { useInvoice } from '@/services/invoice/invoiceServices';
import { updateInvoiceState } from '@/services/invoice/invoiceStateService';
import { Invoice } from '@/types';
import { invoiceStatesFinished } from '@/utils/invoice/invoiceStates';
import { getInvoiceStateBackFromFinished } from '@/utils/invoicesTransfer';
import { useState } from 'react';
import { useSWRConfig } from 'swr';
import { EditInvoiceButton } from './HeaderButtons';

interface EditFinishedButtonProps {
  invoice: Invoice;
}
export const EditFinishedButton = ({ invoice }: EditFinishedButtonProps) => {
  const { cache } = useSWRConfig();
  const { mutate: refreshInvoice } = useInvoice(invoice.claimStatementId, invoice.id);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleEditFinishedInvoice = async () => {
    const state = getInvoiceStateBackFromFinished(invoice.state);
    const temporaryInvoice: Invoice = {
      ...invoice,
      state
    };
    await refreshInvoice(temporaryInvoice);
  };

  const finishedOnEdit = async () => {
    setSubmitting(true);
    const destinationState = getInvoiceStateBackFromFinished(invoice.state);
    await updateInvoiceState(invoice.claimStatementId, {
      destinationState,
      invoiceIdList: [invoice.id],
      instructions: ''
    }, cache);
    setSubmitting(false);

    if (handleEditFinishedInvoice) await handleEditFinishedInvoice();
  };

  if (!invoiceStatesFinished.includes(invoice.state)) return null;

  return <EditInvoiceButton onClick={finishedOnEdit} loading={submitting} disabled={submitting} />;
};
