import { FunctionComponent as FC } from 'react';
import { TableCellMaxContent } from '@/common/components/Table/core/TableCell/TableCellMaxContent';
import { tableCellVariant } from '@/common/components/Table/types';
import { collapseGroups } from '@/common/components/Table/utils';
import { TableCellComment } from '@/common/components/Table/core/TableCell';
import { useRowProps } from '@/common/components/Table/core/TableRow';
import { SplitRowMessageWrapper, SplitRowSurveyorProps } from '../../../TableCells/Components';

export const PrintSplitSurveyorCells: FC<SplitRowSurveyorProps> = ({ split, state }) => {
  const createdByAdjuster = !split.adjuster.surveyorSplitId && !split.surveyor;
  const deletedBySurveyor = split.adjuster.surveyorSplitId && !split.surveyor;
  const atSurveyorsDesk = ['CostAllocation', 'CostReallocation'];

  const { tooltipId, showFullComments } = useRowProps();

  // Safely destructure possibly undefined object to prevent throwing errors
  const { isConsidered, ...surv } = { ...split.surveyor };

  if (createdByAdjuster) {
    return (
      <TableCellMaxContent
        data-collapse-border
        data-cell-variant={tableCellVariant.secondary}
        data-collapse-group={collapseGroups.surveyor}
        span="1">
        <SplitRowMessageWrapper>Created by Adjuster.</SplitRowMessageWrapper>
      </TableCellMaxContent>
    );
  }
  if (deletedBySurveyor && !atSurveyorsDesk.includes(state)) {
    return (
      <TableCellMaxContent
        data-collapse-border
        data-cell-variant={tableCellVariant.secondary}
        data-collapse-group={collapseGroups.surveyor}
        span="1">
        <SplitRowMessageWrapper>Deleted by Surveyor.</SplitRowMessageWrapper>
      </TableCellMaxContent>
    );
  }
  if (deletedBySurveyor && atSurveyorsDesk.includes(state)) {
    return (
      <TableCellMaxContent
        data-collapse-border
        data-cell-variant={tableCellVariant.secondary}
        data-collapse-group={collapseGroups.surveyor}
        span="1">
        <SplitRowMessageWrapper>At Surveyor&apos;s desk.</SplitRowMessageWrapper>
      </TableCellMaxContent>
    );
  }
  return (
    <TableCellComment
      data-cell-variant={tableCellVariant.secondary}
      data-collapse-group={collapseGroups.surveyor}
      data-collapse-border
      comment={surv?.surveyorsComment}
      showFullComment={showFullComments}
      tooltipId={tooltipId}
    />
  );
};
