import axios from 'axios';
import { USER_ROLE } from '@/utils/userManager';
import { isSkippedDomain } from './skipDomains';

const client = axios.create({
  baseURL: '/api/'
});

client.interceptors.request.use(
  config => {
    if (config.headers && !isSkippedDomain(config.url)) {
      const userRole = sessionStorage.getItem(USER_ROLE);
      // eslint-disable-next-line no-param-reassign
      if (userRole) config.headers['x-ics-user-role'] = userRole;
      // eslint-disable-next-line no-param-reassign
      config.headers['X-CSRF'] = '1';
    }
    return config;
  },
  error => Promise.reject(error)
);

client.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401 && !error.config.url.startsWith('access/')) {
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export const getJsonAsync = <T = any>(endpoint: string) => client.get<T>(endpoint).then(response => response.data);
export const getAsync = (endpoint: string) => client.get(endpoint).then(response => response);

export const postFormAsync = (endpoint: string, form: any) =>
  client.post(endpoint, form, { headers: { 'Content-Type': 'multipart/form-data' } });

export const postJsonAsync = <T = any>(endpoint: string) =>
  client.post<T>(endpoint)
    .then(response => response.data);

export const putAsync = <T>(endpoint: string, data?: any) => client.put<T>(endpoint, data).catch(exception => exception.response);

export const patchAsync = <T>(endpoint: string, data?: any) => client.patch<T>(endpoint, data).catch(exception => exception.response);

export const postAsync = (endpoint: string, request?: any) => client.post(endpoint, request).catch(exception => exception.response);
export const postAsyncWithExceptions = (endpoint: string, request?: any) => client.post(endpoint, request);

export const deleteAsync = (endpoint: string) => client.delete(endpoint).catch(exception => exception.response);

export default client;
