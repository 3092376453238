import { CellContext } from '@tanstack/react-table';
import { FC } from 'react';
import styled from 'styled-components';
import { TableCellAlign } from '../types';
import { getHeaderGroup, getMergedMeta } from '../utils';
import { BaseCellProps, BaseTableCell } from './BaseTableCell';
import { useRowClick } from '../providers/RowClickContext';

interface StyledCellProps {
  align?: TableCellAlign;
  underline?: boolean;
}
const CellContent = styled.div <StyledCellProps>`
  text-decoration: ${props => props.underline && 'underline'};
  text-align: ${props => props.align};
`;

interface BaseTextCellProps extends BaseCellProps {
  value: string | undefined;
  align?: TableCellAlign;
  underline?: boolean;
  onClick?: () => void;
}
export const TableCellTextBase: FC<BaseTextCellProps> = ({
  id,
  value,
  headerGroup,
  cellVariant,
  columnBorder,
  span,
  align,
  underline,
  onClick
}) => (
  <BaseTableCell
    id={id}
    headerGroup={headerGroup}
    cellVariant={cellVariant}
    columnBorder={columnBorder}
    span={span}
    onClick={onClick}>
    <CellContent align={align} underline={underline}>
      {value}
    </CellContent>
  </BaseTableCell>
);

/**
 * Simple table cell component for displaying text as-is. Use this if
 * the value is in the table data as it should be, and it just has to
 * be rendered out.
 */
interface TextCellProps {
  data: CellContext<any, any>;
  overrideValue?: string;
  underline?: boolean;
}
export const TableCellText: FC<TextCellProps> = ({
  data,
  overrideValue,
  underline
}) => {
  const { onRowClick } = useRowClick();
  const mergedMeta = getMergedMeta(data.column);
  const headerGroup = getHeaderGroup(data.column);
  const value = data.getValue();
  return (
    <TableCellTextBase
      id={data.cell.id}
      value={overrideValue ?? (value || '-')}
      headerGroup={headerGroup}
      cellVariant={mergedMeta.tableCellVariant}
      columnBorder={mergedMeta.columnBorder}
      align={mergedMeta?.align}
      underline={underline}
      onClick={onRowClick}
    />
  );
};
