import { UserInfo } from '@/types';
import { FunctionComponent as FC } from 'react';
import { CollapsedUserDetailsList } from '@/common/components/UserDetails/CollapsedUserDetailsList';
import { SelectUsersProps } from '@/components/pages/claim/core/SelectUsersModal/SelectUsersModal';
import { SelectUsersPane } from './SelectedUsersPane';

interface Props {
  title: string;
  selected?: UserInfo[],
  openModal: (context?: Partial<SelectUsersProps>) => void,
  margin?: string
}
export const SelectMultiUsersPane: FC<Props> = ({ title, selected, openModal, margin }) => {
  const isEditMode = selected && selected.length > 0;
  return (
    <SelectUsersPane title={title} openModal={openModal} margin={margin} editMode={isEditMode}>
      <CollapsedUserDetailsList users={selected} startOpen />
    </SelectUsersPane>
  );
};
