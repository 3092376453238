import { FunctionComponent as FC } from 'react';
import { AdjustersConsideration, InvoiceState } from '@/types';
import { TableCellMaxContent, TableCellComment } from '@/common/components/Table/core/TableCell';
import { collapseGroups } from '@/common/components/Table/utils';
import { invoiceStatesFinished } from '@/utils/invoice/invoiceStates';
import { RowNavigationHandler, useRowProps } from '@/common/components/Table/core/TableRow';
import { tableCellVariant } from '@/common/components/Table/types';
import { getTooltipValue, getFieldValue } from '../../../../core/utils/getConsideredValues';
import { InvoiceReviewCommentCell } from './InvoiceReviewCommentCell';

const getAdjustersComment = (data?: AdjustersConsideration | null) => {
  if (!data?.splits || data?.splits.length === 1) {
    return data?.adjustersComment;
  }
  const noValidComments = data?.splits.map(split => split.adjustersComment).every(comment => !comment);
  if (noValidComments) return '-';
  return 'Multiple';
};

interface Props {
  data?: AdjustersConsideration | null;
  state: InvoiceState;
  reviewComment?: string;
  showGeneralAverage?: boolean;
  showAdjusterComment?: boolean;
  showReviewComment?: boolean;
  showReviewDraftComment?: boolean;
  usePrintSchema?: boolean;
  exchangeRate?: number | null;
  onClick?: () => void;
}
export const InvoiceAdjusterCells: FC<Props> = ({
  data,
  state,
  reviewComment,
  showGeneralAverage,
  showAdjusterComment,
  showReviewComment,
  showReviewDraftComment,
  usePrintSchema,
  exchangeRate,
  onClick
}) => {
  // Destructure props of possibly undefined object as destructured value or undefined.
  const { commonExpenses, particularAverage, ownersWorkConsideration, generalAverage } = { ...data };
  // We want to show values even if all three values in the row aren't considered. If a particular value is not considered,
  // it will be null and not be shown anyway.
  const isConsidered = true;
  const adjustersComment = getAdjustersComment(data);
  const isFinished = invoiceStatesFinished.includes(state);

  const { tooltipId, showFullComments } = useRowProps();
  return (
    <RowNavigationHandler onClick={onClick}>
      <TableCellMaxContent
        data-cell-variant={tableCellVariant.tertiary}
        data-collapse-group={collapseGroups.adjuster}
        data-is-finished={isFinished}
        data-collapse-border
        right>
        <div data-for={tooltipId} data-tip={getTooltipValue(isConsidered, commonExpenses, exchangeRate)}>
          {getFieldValue(isConsidered, commonExpenses, exchangeRate)}
        </div>
      </TableCellMaxContent>
      {!!showGeneralAverage && (
        <TableCellMaxContent
          data-cell-variant={tableCellVariant.tertiary}
          data-collapse-group={collapseGroups.adjuster}
          data-is-finished={isFinished}
          right>
          <div data-for={tooltipId} data-tip={getTooltipValue(isConsidered, generalAverage, exchangeRate)}>
            {getFieldValue(isConsidered, generalAverage, exchangeRate)}
          </div>
        </TableCellMaxContent>
      )}
      <TableCellMaxContent
        data-cell-variant={tableCellVariant.tertiary}
        data-collapse-group={collapseGroups.adjuster}
        data-is-finished={isFinished}
        right>
        <div data-for={tooltipId} data-tip={getTooltipValue(isConsidered, particularAverage, exchangeRate)}>
          {getFieldValue(isConsidered, particularAverage, exchangeRate)}
        </div>
      </TableCellMaxContent>
      <TableCellMaxContent
        data-cell-variant={tableCellVariant.tertiary}
        data-collapse-group={collapseGroups.adjuster}
        data-is-finished={isFinished}
        right>
        <div data-for={tooltipId} data-tip={getTooltipValue(isConsidered, ownersWorkConsideration, exchangeRate)}>
          {getFieldValue(isConsidered, ownersWorkConsideration, exchangeRate)}
        </div>
      </TableCellMaxContent>
      {showAdjusterComment && (
        <TableCellComment
          data-cell-variant={tableCellVariant.tertiary}
          data-collapse-group={collapseGroups.adjuster}
          data-is-finished={isFinished}
          comment={adjustersComment}
          showFullComment={showFullComments}
          tooltipId={tooltipId} />
      )}
      {(showReviewComment && !usePrintSchema) && (
        <InvoiceReviewCommentCell
          data-cell-variant={tableCellVariant.tertiary}
          data-collapse-group={collapseGroups.adjuster}
          data-is-finished={isFinished}
          showDraftComment={showReviewDraftComment}
          showComment={!!reviewComment} />
      )}
    </RowNavigationHandler>
  );
};
