import { calculateWithExchangeRate } from '@/utils/invoice/convertCurrency';
import {
  formatWithDigitsOrEmpty, formatWithTruncateOrEmpty, truncateDecimals
} from '@/utils/number';

/**
 * Returns auto-formatted value, with exchange rate, for invoice table cells.
 */
export const getFieldValue = (isConsidered?: boolean, value?: string | number | null, multFactor?: number | null) => {
  if (!isConsidered) return null;
  if (!multFactor) return '-';
  const convertedNumber = Number(value);
  return formatWithTruncateOrEmpty(truncateDecimals(calculateWithExchangeRate(convertedNumber, multFactor) as number));
};

/**
 * Returns auto-formatted value, with exchange rate, for invoice table cell tooltips.
 */
export const getTooltipValue = (isConsidered?: boolean, value?: string | number | null, multFactor?: number | null) => {
  if (!isConsidered || !multFactor) return null;
  const convertedNumber = Number(value);
  if (Number.isNaN(convertedNumber)) return '-';

  return formatWithDigitsOrEmpty(truncateDecimals(calculateWithExchangeRate(convertedNumber, multFactor) as number, 2), true);
};
