import {
  FC, useCallback, useState
} from 'react';
import { useWindowEventListener } from '@/utils/useWindowEventListener';
import {
  AutosaveFinishedEvent,
  AutosaveStartedEvent,
  autosaveStatus,
  AutosaveStatus
} from './types';
import { SaveStateDisplay } from './SaveStateDisplay';

interface Props { }
/**
 * For forms that utilize autosaving, this element can be invoked
 * by form handlers to update the current autosave status on the page.
 */
export const SavingIndicator: FC<Props> = () => {
  const [status, setStatus] = useState<AutosaveStatus>(autosaveStatus.saved);

  const handleAutosaveStarted = useCallback((e: CustomEvent<AutosaveStartedEvent>) => {
    if (status === autosaveStatus.pending) {
      return;
    }

    setStatus(autosaveStatus.pending);
  }, [status]);

  const handleAutosaveFinished = useCallback((e: CustomEvent<AutosaveFinishedEvent>) => {
    if (status === autosaveStatus.saved) {
      return;
    }

    setStatus(autosaveStatus.saved);
  }, [status]);

  useWindowEventListener('autosaveStarted', handleAutosaveStarted);
  useWindowEventListener('autosaveFinished', handleAutosaveFinished);

  return (
    <SaveStateDisplay status={status} />
  );
};
