import React from 'react';
import styled from 'styled-components';
import { PillRadio } from './PillRadio';

const CurrencyRow = styled.div<{ marginBottom?: string }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: ${props => props.marginBottom};

  label:first-child > span {
    border-radius: 4px 0 0 4px;
    border-right: 0;
  }
  label:last-child > span{
    border-radius: 0 4px 4px 0;
  }
`;

interface CurrencySelectorProps {
  first?: string | null;
  second?: string | null;
  selected?: string | null;
  setSelected: (currency: string) => void;
  marginBottom?: string;
  id: string
}
/**
 * Renders a currency selector as a two-segment pill radio component.
 * For technical simplicity, there's a rendering oddity for how this thing behaves if the values provided
 * are mutated remotely. Easiest workaround for that so far has been to just rig it up as though the first
 * option is the default choice so that data changes will make it reset to that one.
 * Still keeping that choice since its use case is limited to invoice currency selection. If planning to
 * use it more widely, would spend some time refactoring that to make it more robust and generic.
 */
export const CurrencySelector = ({
  first,
  second,
  selected,
  setSelected,
  marginBottom = '20px',
  id
}: CurrencySelectorProps) => {
  const handleClick: React.ChangeEventHandler<HTMLInputElement> = e => setSelected(e.currentTarget.value);

  if (!first || !second || first === second) {
    return null;
  }
  return (
    <CurrencyRow marginBottom={marginBottom}>
      <PillRadio
        name={`currency-view-${id}`}
        label={first}
        value={first}
        id={`currency-options-${id}-${first}`}
        checked={selected !== second}
        onChange={handleClick}
      />
      <PillRadio
        name={`currency-view-${id}`}
        label={second}
        value={second}
        id={`currency-options-${id}-${second}`}
        checked={selected === second}
        onChange={handleClick}
      />
    </CurrencyRow>
  );
};
