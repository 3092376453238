import { useAccount } from '@/common/authentication/AuthenticationProvider';
import { FCWC } from '@/types';
import React, { createContext, useContext } from 'react';
import useSWR from 'swr';

export interface FeatureFlags {
  // this flag lives only in the frontend, see Provider below
  featureFlagsLoaded: boolean;
  claimsHandlerView: boolean;
  readOnlyInvoice: boolean;
  portalSelector: boolean;
  magicLinks: boolean;
  canCloseClaim: boolean;
  adjustmentChapters: boolean;
}

export type FeatureNames = keyof FeatureFlags;

const featureFlagRoute = '/featureFlags';

const FeatureFlagContext = createContext({} as FeatureFlags);

export const FeatureFlagProvider: FCWC = ({ children }) => {
  const { isAuthenticated } = useAccount();
  const { data } = useSWR<FeatureFlags>(isAuthenticated ? featureFlagRoute : null);
  const value = React.useMemo(() => {
    if (!data) return {} as FeatureFlags;
    // Kinda cheeky, but basically packing the actual feature flags together with a
    // dummy feature flag whose job is to tell whether the other flags are loaded in
    return { ...data, featureFlagsLoaded: true };
  }, [data]);

  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlagsContext = () => useContext<FeatureFlags>(FeatureFlagContext);

export const useFeatureFlag = (key: FeatureNames) => {
  const ctx = useFeatureFlagsContext();
  return !!ctx[key];
};
