import { ModalType } from '@/common/modules/Modal/types';
import {
  DeletedTableRowItem,
  NumberCell,
  ReorderCell,
  ReorderSplitsActionButtons,
  ReorderTableRow,
  SortingContext,
  ReorderTable,
  useReorderSplits
} from '@/apps/ClaimStatement/components/InvoiceSplitTable/ReorderSplitsModal';
import { AdjustersConsiderationForm, AdjustersConsiderationSplitForm } from '@/types';
import { calculateAdjusterSummaryValues } from '@/utils/invoice/calculateSummaries';

export interface ReorderAdjusterSplitsProps {
  form: AdjustersConsiderationForm;
  usingInvoiceCurrency: boolean;
  exchangeRate: number | undefined | null;
  updateSplitsOrder: (splitsIdOrder: AdjustersConsiderationSplitForm[]) => void;
}
const ModalContent = ({ form, usingInvoiceCurrency, exchangeRate, updateSplitsOrder }: ReorderAdjusterSplitsProps) => {
  const { handleDragEnd, orderedSplits, saveStatus, handleSave, handleClose } = useReorderSplits(form.splits.map(split => ({
    values: calculateAdjusterSummaryValues(split, undefined, exchangeRate, usingInvoiceCurrency, true, undefined),
    id: split.id,
    isDeleted: split.isDeleted,
    originalSplit: split
  })), updateSplitsOrder);

  return (
    <SortingContext onDragEnd={handleDragEnd} items={orderedSplits.map(split => split.id!)}>
      <ReorderTable>
        <thead>
          <tr>
            <th aria-label="Drag handler" />
            <th>Occurrence</th>
            <th>Category</th>
            <NumberCell>Common Expenses</NumberCell>
            <NumberCell>Particular Average</NumberCell>
            <NumberCell>Owner&apos;s</NumberCell>
            <NumberCell>Split Sum</NumberCell>
          </tr>
        </thead>
        <tbody>
          {orderedSplits.map(({ id, isDeleted, values }) => (
            <ReorderTableRow id={id!} key={id}>
              <ReorderCell />
              {isDeleted ? (
                <DeletedTableRowItem colSpan={6}>Deleted by Adjuster</DeletedTableRowItem>
              ) : (
                <>
                  <td>{values.occurrence}</td>
                  <td>{values.category}</td>
                  <NumberCell>{values.commonExpenses}</NumberCell>
                  <NumberCell>{values.particularAvg}</NumberCell>
                  <NumberCell>{values.ownersWork}</NumberCell>
                  <NumberCell dark>{values.splitSum}</NumberCell>
                </>
              )}
            </ReorderTableRow>
          ))}
        </tbody>
      </ReorderTable>
      <ReorderSplitsActionButtons onClose={handleSave} onCancel={handleClose} saveButtonState={saveStatus} />
    </SortingContext>
  );
};

export const reorderAdjusterSplitsModal = (reorderAdjusterSplitsProps: ReorderAdjusterSplitsProps): ModalType<ReorderAdjusterSplitsProps> => ({
  component: ModalContent,
  options: {
    title: 'Reorder Splits',
    size: 'large',
    padding: '20px'
  },
  args: { ...reorderAdjusterSplitsProps },
});
