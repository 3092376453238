import { useEffect } from 'react';

type EventListener = <TEventType extends keyof WindowEventMap>(type: TEventType, listener: (this: Window, ev: WindowEventMap[TEventType]) => any) => void;
export const useWindowEventListener: EventListener = (event, eventListener) => {
  useEffect(() => {
    window.addEventListener(event, eventListener);
    return () => {
      window.removeEventListener(event, eventListener);
    };
  }, [event, eventListener]);
};
