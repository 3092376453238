import { FCWC } from '@/types';
import { CellContext } from '@tanstack/react-table';
import styled, { css } from 'styled-components';

interface StyledCellProps {
  border?: 'left' | 'right';
}
export const BaseButtonCellStyle = styled.td<StyledCellProps>`
  padding: 0px 12px;
  height: 48px; // TD rendering can always grow height, so this is a min-height
  box-sizing: border-box;
  cursor: initial;
  ${props => props.border && css`
    border-${props.border}: 1px solid ${props.theme.marineBlue};
  `};
`;

interface ButtonCellProps {
  data: CellContext<any, any>;
  className?: string;
}
/**
 * Table cell with narrower paddings to make room for a button. Use this component
 * as a wrapper if you are making a table cell whose content is meant to be clicked
 * and interacted with by the user.
 */
export const BaseButtonCell: FCWC<ButtonCellProps> = ({ data, className, children }) => {
  const { meta: cellMeta } = data.column.columnDef;
  // Attach if column header has parent, otherwise React will ignore
  const headerGroup = data.column.parent?.id ?? undefined;

  return (
    <BaseButtonCellStyle
      key={data.cell.id}
      data-header-group={headerGroup}
      border={cellMeta?.columnBorder}
      className={className}>
      {children}
    </BaseButtonCellStyle>
  );
};
