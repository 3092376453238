/* eslint-disable no-shadow */
import {
  TableRow, TableRowProps, TableCell
} from '@instech/components';
import styled, { css } from 'styled-components';
import { tableCellVariant } from '../../types';

interface CustomTableRowProps extends TableRowProps {
  isActive?: boolean;
  isFavourite?: boolean;
  isFaded?: boolean;
}
/**
 * TableRow component with an added styling to red-colour child cells that have a
 * particular data-is-empty flag on them. Using this in the Invoice Table to display
 * cells in red if the data in them is missing in the row's dataset.
 */
export const TableRowWithColor = styled(TableRow) <CustomTableRowProps>`
  cursor: ${props => props.isActive ? 'pointer' : 'default'};
  color: ${props => props.theme.marineBlue} !important;
  font-weight: ${props => props.isFavourite && 'bold'};
  font-size: 14px;
  line-height: 16px;
  
  &:hover ${TableCell}:before {
    visibility: ${props => props.isActive ? 'visible' : 'hidden'};
  }

  // apply styling to child cell based on row numbering and data-attribute
  ${({ theme, even, isFaded }) => css`
    & ${TableCell} {
      background-color: ${even ? theme.table.first.even : theme.table.first.odd};

      ${isFaded && css`
        font-style: italic;
        color: ${theme.marineBlue60};
      `}

      &[data-is-finished="true"] {
        background-color: ${even ? theme.table.first.greenEven : theme.table.first.greenOdd};
      }

      &[data-cell-variant="${tableCellVariant.secondary}"] {
        background-color: ${even ? theme.table.second.even : theme.table.second.odd};
        &[data-is-finished="true"] {
          background-color: ${even ? theme.table.second.greenEven : theme.table.second.greenOdd};
        }
      }

      &[data-cell-variant="${tableCellVariant.tertiary}"] {
        background-color: ${even ? theme.table.third.even : theme.table.third.odd};
        &[data-is-finished="true"] {
          background-color: ${even ? theme.table.third.greenEven : theme.table.third.greenOdd};
        }
      }

      &[data-is-empty="true"][data-allow-empty="false"] {
        background-color: ${even ? theme.table.empty.even : theme.table.empty.odd};
      }
    }
  `} 
`;
