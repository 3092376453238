import { useCurrentRole } from '@/common/authentication/CurrentRoleContext';
import { createBrowserHistory } from 'history';
import { UserMenu, Notifications } from 'insify-remote-component-loader';
import { useAccount } from '@/common/authentication/AuthenticationProvider';
import { ComponentProps, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Override rendered height of notificaton sidebar, so it can be dynamically
// adjusted with a globally set variable that matches the height of the header
const HeightOffsetWrapper = styled.div`
  display: contents;
  & div[class*="Dropdown-Notifications"] {
    top: var(--headerHeight);
    height: calc(100% - var(--headerHeight));
  }
`;

interface UserMenuLoaderProps {
  showLinks: boolean;
}
export const UserMenuLoader = ({ showLinks }: UserMenuLoaderProps) => {
  const [notificationsIsOpen, setNotificationsIsOpen] = useState(false);
  const account = useAccount();
  const role = useCurrentRole();
  const navigate = useNavigate();

  const updatesConfig = {
    announcements: true,
    notifications: true
  };

  const links: ComponentProps<typeof UserMenu>['links'] = [
    { title: 'Notifications', onClick: () => setNotificationsIsOpen(true) },
    { title: 'Feedback & Support', href: '/feedback-and-support' },
    { title: 'Announcements', href: '/announcements' }
  ];

  const history = createBrowserHistory();
  history.push = (to, state) => navigate(to, state);
  history.replace = (to, state) => navigate(to, { ...state, replace: true });

  return (
    <>
      <UserMenu
        account={account}
        history={history}
        role={role}
        portal={{ name: 'claimstatement', displayName: 'Claim Statement' }}
        config={updatesConfig}
        links={showLinks ? links : []}
      />
      <HeightOffsetWrapper>
        <Notifications
          account={account}
          history={history}
          isOpen={notificationsIsOpen}
          setIsOpen={setNotificationsIsOpen}
          top="0px"
        />
      </HeightOffsetWrapper>
    </>
  );
};
