import {
  MouseEvent, useCallback, useState, useRef,
} from 'react';
import styled, { css } from 'styled-components';
import { Remove } from '@instech/icons';
import { IconButton } from '@instech/components';
import { FCWC } from '@/types';
import {
  ModalOptions, ModalSize, modalSizes
} from './types';

const ModalScroll = styled.div<{ zIndex?: number }>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: ${props => props.theme.zIndex.modal};
  overflow-y: scroll;
`;

const Overlay = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 40px;
  background-color: rgba(0,0,0,0.65);
  box-sizing: border-box;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  pointer-events: none;
  background: none;
  padding: 0px 16px;
`;

const LayoutArea = styled.div<{ size?: ModalSize }>`
  pointer-events: auto;
  max-width: 100%;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  display: flex;
  flex-direction: column;
  box-sizing: content-box;

  ${props => props.size === modalSizes.large && css`
    width: 1110px;
  `};

  ${props => props.size === modalSizes.medium && css`
    width: 716px;
  `};

  ${props => props.size === modalSizes.small && css`
    width: 512px;
  `};
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  padding: 12px 12px 12px 24px;
  color: ${props => props.theme.marineBlue};
  & svg {
    color: ${props => props.theme.marineBlue};
    stroke-width: 2px;
  }
`;

const ModalTitle = styled.span`
  padding-top: 11px;
  padding-bottom: 13px;
  font-weight: bold;
  font-size: 18px;
`;

const ModalBody = styled.div<{ padding?: string, size?: ModalSize }>`
  // If modalSizes.content is used, padding must come from modal content
  ${props => props.size !== modalSizes.content && css`padding: ${props.padding ?? '24px 32px'};`}
  > * ~ * { /* Impose spacing between direct descendants */
    margin-top: 32px;
  }
`;

interface ModalLayoutProps {
  options: ModalOptions;
  closeModal: () => void;
  onCancel: () => void;
}

export const ModalLayout: FCWC<ModalLayoutProps> = ({ children, options = {}, closeModal, onCancel }) => {
  const [clickOutside, setClickOutside] = useState(false);
  const overlayRef = useRef(null);

  // Initiate valid outside click if this triggered on the Overlay.
  const handleMouseDown = (evt: MouseEvent<HTMLDivElement>) =>
    setClickOutside(evt.target === evt.currentTarget);

  const handleCloseModal = useCallback(() => {
    onCancel();
    closeModal();
  }, [closeModal, onCancel]);

  // Confirm valid outside click if this triggered on the Overlay.
  const handleMouseUp = useCallback((evt: MouseEvent<HTMLDivElement>) => {
    if (clickOutside && overlayRef?.current === evt.target) {
      handleCloseModal();
    }
  }, [clickOutside, handleCloseModal]);

  return (
    <ModalScroll>
      <Overlay ref={overlayRef} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
        <Container role="dialog">
          <LayoutArea size={options.size}>
            {options.title && (
              <ModalHeader>
                <ModalTitle>{options.title}</ModalTitle>
                {!options.hideCloseButton && (
                  <IconButton
                    icon={<Remove width={24} height={24} />}
                    onClick={handleCloseModal}
                  />
                )}
              </ModalHeader>
            )}
            <ModalBody padding={options.padding} size={options.size}>
              {children}
            </ModalBody>
          </LayoutArea>
        </Container>
      </Overlay>
    </ModalScroll>
  );
};
