import {
  AdjustersConsiderationSplitForm, Nullable, UpdateAdjustersConsiderationForm
} from '@/types';
import { useEffect } from 'react';
import { deepEqual } from '@/utils/array';

const considerationShouldBeFalse = (
  isConsidered: Nullable<boolean>,
  initial: Nullable<string | number>,
  value: Nullable<string | number>
): boolean => !!isConsidered && (initial === undefined || initial === null) && value === '';

/**
 * Reset isConsidered to false for individual cost fields in a split, if the
 * field's initial value was null and current value is empty.
 */
export const getSplitWithReset = (split: AdjustersConsiderationSplitForm, showGeneralAverage?: boolean) => {
  const newSplit: AdjustersConsiderationSplitForm = { ...split, form: { ...split.form } };
  const commonExpenses = considerationShouldBeFalse(
    split.form.commonExpensesConsidered,
    split.initialCommonExpenses,
    split.commonExpenses
  );
  const particularAverage = considerationShouldBeFalse(
    split.form.particularAvgConsidered,
    split.initialParticularAverage,
    split.particularAverage
  );
  const ownersWork = considerationShouldBeFalse(
    split.form.ownersWorkConsidered,
    split.initialOwnersWork,
    split.ownersWorkConsideration
  );
  const generalAverage = considerationShouldBeFalse(
    split.form.generalAverageConsidered,
    undefined,
    split.generalAverage
  );

  if (commonExpenses) newSplit.form.commonExpensesConsidered = false;
  if (particularAverage) newSplit.form.particularAvgConsidered = false;
  if (ownersWork) newSplit.form.ownersWorkConsidered = false;
  // Check only if genAvg flag is set in claim statement, this value never has an initial
  if (showGeneralAverage && generalAverage) newSplit.form.generalAverageConsidered = false;

  return newSplit;
};

/**
 * `useResetConsiderations` sets the `split.form.isConsidered` to false for individual
 * cost fields if their initial value was null and their current value is also null.
 */
export const useResetConsiderations = (
  values: UpdateAdjustersConsiderationForm,
  setValues: (updatedSplits: UpdateAdjustersConsiderationForm) => any,
  showGeneralAverage: boolean
) => useEffect(() => {
  const splits = values.splits.map(split => getSplitWithReset(split, showGeneralAverage));
  const formsAreEqual = values.splits.every((split, index) => deepEqual(split.form, splits[index].form));

  if (!formsAreEqual) {
    setValues({ ...values, splits });
  }
}, [values, setValues, showGeneralAverage]);
