import { useInvoice } from '@/services/invoice/invoiceServices';
import { useEffect, useState } from 'react';
import { InvoiceState } from '@/types';

type Optional<T> = T | undefined;

export const useInvoiceLock = (
  editableStates: InvoiceState[],
  claimStatementId: Optional<string>,
  invoiceId: Optional<string>,
  isClaimStatementClosed: boolean
) => {
  const { lockInvoice, unlockInvoice, data: invoice } = useInvoice(claimStatementId, invoiceId);
  const [ownsLock, setOwnsLock] = useState<boolean | null>(null);
  const invoiceState = invoice?.state;

  // If the invoice id change (prev/next) then reset the lock state
  useEffect(() => {
    setOwnsLock(null);
  }, [invoiceId]);

  useEffect(() => {
    if (isClaimStatementClosed) {
      setOwnsLock(true);
      return () => {};
    }

    const lockTheInvoice = async () => {
      const invoiceLockStatus = await lockInvoice();
      if (invoiceLockStatus === 'locked') setOwnsLock(true);
      else setOwnsLock(false);
    };
    if (ownsLock === null && invoiceState) {
      if (editableStates.includes(invoiceState)) {
        void lockTheInvoice();
      } else {
        setOwnsLock(true);
      }
    }
    return () => {
      if (ownsLock && invoiceState && editableStates.includes(invoiceState)) {
        void unlockInvoice();
      }
    };
  }, [ownsLock, lockInvoice, unlockInvoice, invoiceState, editableStates, isClaimStatementClosed]);

  useEffect(() => {
    if (isClaimStatementClosed) {
      return () => {};
    }

    const eventCallback = () => {
      if (ownsLock && editableStates.includes(invoiceState!)) {
        void unlockInvoice();
      }
    };
    window.addEventListener('beforeunload', eventCallback);
    return () => window.removeEventListener('beforeunload', eventCallback);
  }, [unlockInvoice, ownsLock, invoiceState, editableStates, isClaimStatementClosed]);

  return { ownsLock, unlockInvoice };
};
