import { CollapsiblePane } from '@instech/components';
import { Formik } from 'formik';
import styled from 'styled-components';
import { useRequireClaimStatementId } from '@/utils/useRequireClaimStatementId';
import { AdjustedClaimPdf } from '@/apps/Adjustment/pages/AdjustmentPage/chapters/AdjustedClaim/AdjustedClaimPdf';
import {
  dispatchAutosaveFinishedEvent,
  dispatchAutosaveStartedEvent
} from '@/common/components/Autosave';
import { FormWithAutoSave } from '../../core/Components';
import {
  UpdateAdjustedClaimRequest,
  useAdjustedClaim
} from './utils/hooks/useAdjustedClaim';
import { adjustedClaimValidationSchema } from './validationSchema';
import {
  DueDate,
  DueToAssuredInput, TotalClaimOnPolicyInput,
  UnderwritersFeeAndExpensesInput
} from './Inputs';

const PageLayout = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 32px;
  box-sizing: border-box;
`;

const Layout = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

  @media (max-width: 1800px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const AdjustedClaimPane = styled(CollapsiblePane)`
  display: flex;
  flex-direction: column;
`;

const resetDateIfNotSet = (values: UpdateAdjustedClaimRequest, initialDueDate: string | undefined, dueDateSet: boolean) => {
  if (values.dueDate === initialDueDate && !dueDateSet) {
    return { ...values, dueDate: undefined };
  }

  return values;
};

export const AdjustedClaim = () => {
  const claimStatementId = useRequireClaimStatementId();
  const { adjustedClaimData, updateAdjustedClaim, resetDueDate } = useAdjustedClaim(claimStatementId);
  const dueDateHighlight = !adjustedClaimData.dueDateSet;

  const handleSubmit = async (values: UpdateAdjustedClaimRequest) => {
    try {
      dispatchAutosaveStartedEvent();
      const request = resetDateIfNotSet(values, adjustedClaimData.adjustedClaim.dueDate, adjustedClaimData.dueDateSet);
      await updateAdjustedClaim(request);
    } finally {
      dispatchAutosaveFinishedEvent();
    }
  };

  const handleResetDueDate = async () => {
    dispatchAutosaveStartedEvent();
    try {
      await resetDueDate();
    } finally {
      dispatchAutosaveFinishedEvent();
    }
  };

  return (
    <PageLayout>
      <div>
        <AdjustedClaimPane color="green" title="Adjusted Claim" padding="20px">
          <Formik onSubmit={handleSubmit} initialValues={adjustedClaimData.adjustedClaim} validationSchema={adjustedClaimValidationSchema} enableReinitialize>
            <FormWithAutoSave>
              <Layout>
                <DueToAssuredInput />
                <UnderwritersFeeAndExpensesInput />
                <TotalClaimOnPolicyInput />
                <DueDate dueDateHighlight={dueDateHighlight} handleClick={handleResetDueDate} />
              </Layout>
            </FormWithAutoSave>
          </Formik>
        </AdjustedClaimPane>
        <AdjustedClaimPdf claimStatementId={claimStatementId} />
      </div>
    </PageLayout>
  );
};
