import styled from 'styled-components';

interface HeaderContainerProps {
  stickyTop?: string;
  borderColor?: string;
}
/**
 * Outermost wrapper component for a TableHeader component. Should be
 * the top parent element of any component intended to be used as a
 * table column's TableHeader.
 */
export const CollapsibleHeaderContainer = styled.th<HeaderContainerProps>`
  position: relative;
  background-color: ${props => props.theme.white};
  border: 0px;
  text-align: left;
  font-weight: normal;
  font-size: 16px;
  min-width: 32px;
  height: 56px;
  &:not(:first-of-type) {
    border-left: 1px solid ${props => props.theme.marineBlue};
  }
`;
