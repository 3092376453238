import { NumberParser } from '@internationalized/number';
import { convertCurrency } from './invoice/convertCurrency';

/**
 * Assert whether provided value is a Number value. Returns true
 * if provided value can be parsed as numerical value (including strings).
 */
export const isRealNumber = (value: any) => (
  !Number.isNaN(parseFloat(value)) && !Number.isNaN(value - 0) && typeof value !== 'boolean'
);

/**
 * Clamps a number to be between provided min and max value.
 * Max value is optional (default Infinity) for when only a lower bound
 * clamp is needed.
 */
export const clampNumber = (number: number, min: number, max: number = Infinity) =>
  Math.max(min, Math.min(number, max));

export const formatNumber = (value: number) => value.toLocaleString('en');

export const formatNumberWithTwoDecimals = (value: number) => value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const formatNumberWithThreeDecimals = (value: number) => value.toLocaleString('en', { minimumFractionDigits: 3, maximumFractionDigits: 3 });

export const formatNumberWithTwelveDecimals = (value: number) => value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 12 });

/**
 * Truncates a number's decimals if it has more decimals than the given maxDecimals.
 * This is not a rounding, but instead a hard cut-off at the given decimal place
 * (ex. 34.5678 to 34.56, rather than 34.57)
 */
export const truncateDecimals = (value: number, maxDecimals: number = 0): number => {
  // If val has no decimals, can just return, no need to proceed
  if (!(Number(value) % 1)) return value;

  const [number, decimals] = value.toString().split('.');
  const trimDecimals = decimals.substring(0, maxDecimals);

  if (!trimDecimals) return Number(number);
  return Number(`${number}.${trimDecimals}`);
};

export const truncateNumberWithFormat = (value: number) => {
  const truncated = truncateDecimals(value, 0);
  return truncated.toLocaleString('en');
};
export const formatWithDigitsOrEmpty = (number?: string | number | null, showZero?: boolean, multFactor?: number | null) => {
  const convertedNumber = Number(number);

  if (showZero && number === 0) return formatNumberWithTwoDecimals(number);
  return !Number.isNaN(convertedNumber) && convertedNumber !== 0 ? formatNumberWithTwoDecimals(convertedNumber * (multFactor ?? 1)) : '';
};

export const formatWithDigitsOrDash = (number?: string | number | null, showZero?: boolean, multFactor?: number | null) => {
  if (number === undefined || number === '-') return '-';
  const convertedNumber = Number(number);
  if (showZero && number === 0) return formatNumberWithTwoDecimals(number);
  return !Number.isNaN(convertedNumber) && convertedNumber !== 0 ? formatNumberWithTwoDecimals(convertedNumber * (multFactor ?? 1)) : '';
};

export const truncateWithDigitsOrDash = (number?: string | number | null, showZero?: boolean) => {
  if (number === undefined || number === '-') return '-';
  if (showZero && number === 0) return truncateDecimals(number, 2);
  const convertedNumber = Number(number);
  return !Number.isNaN(convertedNumber) && convertedNumber !== 0 ? formatNumberWithTwoDecimals(truncateDecimals(convertedNumber, 2)) : '';
};

export const formatOrEmpty = (number?: string | number | null, showZero?: boolean, multFactor?: number | null) => {
  const convertedNumber = Number(number);
  if (showZero && number === 0) return formatNumber(number);

  const multFactorValue = multFactor ?? 1;
  return !Number.isNaN(convertedNumber) && number !== null ? truncateNumberWithFormat(convertedNumber * multFactorValue) : '-';
};

export const formatWithTruncateOrEmpty = (number?: string | number | null) => {
  // Catch obvious exceptions that should not pass
  if (number === null || number === undefined || String(number).trim() === '' || String(number).trim() === '-') {
    return '-';
  }

  // Should make valid number or NaN, which is the last catch needed
  const numberAsNum = Number(number);

  if (Number.isNaN(numberAsNum)) return '-';
  if (numberAsNum === 0) return 0;
  return truncateNumberWithFormat(numberAsNum);
};

export const sanitizeFormattedNumber = (value: string) => value.replace(/\s/g, '');

export const testValidNumberWithGivenDecimals = (value: number | string, decimals: number) => {
  /* eslint-disable-next-line */
  const pattern = new RegExp('^(-)?\\d*(\\.\\d{0,' + decimals + '})?$');
  return pattern.test(String(value));
};

export const numberOrDash = (number?: string | number | null): string | number => {
  // Catch obvious exceptions that should not pass
  if (number === null || number === undefined || String(number).trim() === '') {
    return '-';
  }

  // Should make valid number or NaN, which is the last catch needed
  const numberAsNum = Number(number);

  if (Number.isNaN(numberAsNum)) return '-';
  return numberAsNum;
};

export const getConvertedCurrencyText = (
  value: number | string | null | undefined,
  exchangeRate: number | null | undefined,
  useInvoiceCurrency: boolean
) => {
  if (useInvoiceCurrency) return formatWithDigitsOrEmpty(value, true);

  const rate = (useInvoiceCurrency && 1) || exchangeRate || null;
  const allowZero = !!Number(rate);

  if (value === 0) return formatWithDigitsOrEmpty(value, allowZero);

  const convertedValue = convertCurrency(value, rate, allowZero);
  return formatWithDigitsOrEmpty(convertedValue, true);
};

/**
 * Summarizes an array of numerical values down to a single number.
 * If all provided values are a dash (-), then it will return a dash.
 */
export const createSumOrDash = (numbers: (string | number | undefined)[]): number | string => {
  const allDashes = numbers.every(value => (value === undefined) || (value === '-'));
  if (allDashes) return '-';

  const reduction = numbers.reduce((current, last) => (Number(last) || 0) + (Number(current) || 0), 0);
  return Number(reduction);
};

export const getParsedDecimalAsStringWithGivenPrecision = (value: string, precision: number = 2, skipThousandSeparatorTest: boolean = false): string => {
  const englishNumberParser = new NumberParser('en-US', { style: 'decimal' });
  const nonEnglishNumberParser = new NumberParser('da-DK', { style: 'decimal' });

  const norwegianDecimalOnly = /^-?\d+(,\d+)?$/;
  const norwegianDotAsThousand = /^-?\d+(\.\d{3})+(,\d+)?$/;
  const norwegianSpaceAsThousand = /^-?\d+( \d{3})+(,\d+)?$/;

  const englishDecimalOnly = /^-?\d+(\.\d+)?$/;
  const englishCommaAsThousand = /^-?\d+(,\d{3})+(\.\d+)?$/;
  const englishSpaceAsThousand = /^-?\d+( \d{3})+(\.\d+)?$/;

  // system-calculated numbers use dot as decimal separator, so for small calculated numbers with 3 decimals we need to skip the thousand separator test
  if (norwegianDecimalOnly.test(value) || (!skipThousandSeparatorTest && norwegianDotAsThousand.test(value)) || norwegianSpaceAsThousand.test(value)) {
    const trimmedValue = value.replace(/[^0-9.,-]+/g, '');
    const decimalValue = nonEnglishNumberParser.parse(trimmedValue);
    return truncateDecimals(Number(decimalValue), precision).toString();
  }

  if (englishDecimalOnly.test(value) || englishCommaAsThousand.test(value) || englishSpaceAsThousand.test(value)) {
    const trimmedValue = value.replace(/[^0-9.,-]+/g, '');
    const decimalValue = englishNumberParser.parse(trimmedValue);
    return truncateDecimals(Number(decimalValue), precision).toString();
  }

  return sanitizeFormattedNumber(value);
};
