import { css } from 'styled-components';

export const HighlightEffect = css`
  background-color: ${props => props.theme.lightYellow30};
  box-shadow: ${props => `inset 6px 0 0 0 ${props.theme.lightYellow}`};

  &:disabled {
    color: ${props => props.theme.marineBlue60};
    background-color: ${props => props.theme.marineBlue10};
    box-shadow: ${props => `inset 6px 0 0 0 ${props.theme.marineBlue20}`};
  }
`;
