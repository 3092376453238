import { PageContent } from '@/common/layout/Page';
import { useClaimStatement } from '@/services/claimStatementServices';
import {
  useInvoice, useInvoiceBatch, useInvoiceLink
} from '@/services/invoice/invoiceServices';
import { PageLoader } from '@instech/components';
import { Navigate, useParams } from 'react-router-dom';
import { InvoicePageLayout } from '../InvoicePage/core/Components';
import { InvoiceDisplay } from '../core/EditInvoice/InvoiceDisplay/InvoiceDisplay';
import { useUserSessionSlide } from '../core/EditInvoice/utils/useUserSessionSlide';
import { InvoiceDetailsBatchPage } from './InvoiceDetailsBatchPage';
import { BatchInvoiceHeader } from './core/BatchInvoiceHeader';

interface BatchInvoiceInnerPageProps {
  claimStatementId: string;
  batchId: string;
  invoiceBatchNumber: number;
}
const BatchInvoiceInnerPage = ({ claimStatementId, batchId, invoiceBatchNumber }: BatchInvoiceInnerPageProps) => {
  const { data: invoiceBatchList } = useInvoiceBatch(claimStatementId, batchId);

  const invoiceBatchIndex = invoiceBatchNumber - 1;
  const invoiceId = invoiceBatchList && invoiceBatchList[invoiceBatchIndex];
  const { data: invoice } = useInvoice(claimStatementId, invoiceId);
  const { data: claimStatement } = useClaimStatement(claimStatementId);
  const { data: imageData } = useInvoiceLink(claimStatementId, invoiceId);
  useUserSessionSlide();

  if (!invoiceBatchList || !invoiceId || !invoice || !claimStatement) {
    return <PageLoader />;
  }

  if (invoiceBatchIndex > invoiceBatchList.length) {
    return <Navigate to={`/claimstatements/${claimStatementId}`} />;
  }

  // Right now, this batch page is only used for uploaded invoices
  // so this can just be hard set here at the moment.

  return (
    <div>
      <BatchInvoiceHeader
        claimStatementId={claimStatementId}
        invoiceBatchNumber={invoiceBatchNumber}
        invoiceBatchList={invoiceBatchList}
        claimStatement={claimStatement}
      />
      <PageContent>
        <InvoicePageLayout>
          <InvoiceDetailsBatchPage
            // For reinitializing Formik to avoid payment date illegal value hanging
            key={`invoice-details-page-${invoiceBatchIndex}`}
            claimStatementId={claimStatementId}
            invoiceId={invoiceId}
            batchUploadData={{ invoiceBatchList, invoiceBatchNumber, batchId }}
          />
          <InvoiceDisplay
            // Re-render component after index changes
            key={`invoice-display-page-${invoiceBatchIndex}`}
            invoice={invoice}
            ownsLock
            imageUrl={imageData?.url}
            isClaimStatementClosed={false} // This page isn't accessible when the claim statement is closed, so this will always be false
          />
        </InvoicePageLayout>
      </PageContent>
    </div>
  );
};

export const BatchInvoicePage = () => {
  const { claimStatementId, batchId, invoiceBatchNumber: invoiceBatchNumberRaw } = useParams();

  const invoiceBatchNumber = parseInt(invoiceBatchNumberRaw ?? '');
  if (!claimStatementId || !batchId || (Number.isNaN(invoiceBatchNumber) && invoiceBatchNumber <= 0)) {
    return <Navigate to="/" />;
  }

  return <BatchInvoiceInnerPage claimStatementId={claimStatementId} batchId={batchId} invoiceBatchNumber={invoiceBatchNumber} />;
};
