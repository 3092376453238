import { ExpandableTableRow, ExpandedCellButton } from '@/common/components/Table/ExpandableTableRow';
import { TableCellMaxContent } from '@/common/components/Table/core/TableCell/TableCellMaxContent';
import React, { FunctionComponent as FC } from 'react';
import {
  TableCellComment,
  TableCellDigitsTooltip, TableCellText, TableCellTextTooltip
} from '@/common/components/Table/core/TableCell';
import { arrayPartition } from '@/utils/array';
import { CollapsibleSubrow, CollapsibleSubrowSegment } from './types';
import { useSharedTooltip } from '../../Tooltip/SharedTooltip';

const createCellProps = (subrow: CollapsibleSubrow, segment: CollapsibleSubrowSegment) => {
  const mapKey = `${subrow.id}-${segment.key}`;
  const props = {
    'data-collapse-group': segment.group,
    'data-collapse-border': segment.border,
    'right': segment.align === 'right'
  };
  return { mapKey, props };
};

interface SegmentProps {
  tooltipId: string;
  subrow: CollapsibleSubrow;
  segment: CollapsibleSubrowSegment;
  showFullComments?: boolean;
}

const Segment: FC<SegmentProps> = ({ tooltipId, subrow, segment, showFullComments }) => {
  const { mapKey, props } = createCellProps(subrow, segment);
  const { value, span } = segment;
  if (segment.shouldSkip) {
    return null;
  }
  if (segment.cellType === 'gap') {
    return <TableCellMaxContent {...props} key={mapKey} span={span} />;
  }
  if (segment.cellType === 'text') {
    return <TableCellText {...props} key={mapKey} value={value} />;
  }
  if (segment.cellType === 'textTooltip') {
    return (
      <TableCellTextTooltip {...props} key={mapKey} value={value} tooltipId={tooltipId} />
    );
  }
  if (segment.cellType === 'digitsTooltip') {
    return (
      <TableCellDigitsTooltip {...props} key={mapKey} value={value} tooltipId={tooltipId} />
    );
  }
  if (segment.cellType === 'comment') {
    return (
      <TableCellComment {...props} key={mapKey} comment={value} tooltipId={tooltipId} showFullComment={showFullComments} />
    );
  }
  if (segment.cellType === 'custom') {
    return <React.Fragment key={mapKey}>{value}</React.Fragment>;
  }
  return null;
};

interface Props {
  subrow: CollapsibleSubrow;
  even: boolean;
  selectableRows?: boolean;
  showFullComments?: boolean;
}
/**
 * Displays a collapsible set of inner table rows for the CH invoices table.
 * It renders out a row for each split in the adjusterSplits, and handles whether
 * to show based on a flag set in the component that imports this one.
 */
export const CollapsibleTableSubrow: FC<Props> = ({ subrow, even, selectableRows, showFullComments }) => {
  const { tooltipId } = useSharedTooltip();
  const [beforeSubrowColumn, afterSubrowColumn] = arrayPartition(subrow.segments, elem => !!elem.beforeSubrows);
  return (
    <ExpandableTableRow even={even}>
      {beforeSubrowColumn.map(segment => (
        <Segment
          key={segment.key}
          tooltipId={tooltipId}
          subrow={subrow}
          segment={segment}
          showFullComments={showFullComments} />
      ))}
      {selectableRows && (
        <TableCellMaxContent key="selectable-row-cell-space" />
      )}
      <ExpandedCellButton />
      {afterSubrowColumn.map(segment => (
        <Segment
          key={segment.key}
          tooltipId={tooltipId}
          subrow={subrow}
          segment={segment}
          showFullComments={showFullComments} />
      ))}
    </ExpandableTableRow>
  );
};
