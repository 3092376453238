import styled from 'styled-components';
import {
  TableHeaderRow, TableSubheaderClear, TableSubheaderGap, TableSubheaderGroup
} from '@instech/components';
import { FunctionComponent as FC } from 'react';
import { CollapsibleSubheaderSegment } from './types';

// Fix the height to circumvent a collapse effect where the subheader
// groups stretch and fill up more space than they need to.
const StyledSubheaderGroup = styled(TableSubheaderGroup)`
  height: 33px;
`;

interface Props {
  segments?: CollapsibleSubheaderSegment[];
  selectableRows?: boolean;
  stickyTop: string;
}
export const CollapsibleTableSubheaders: FC<Props> = ({ segments = [], selectableRows, stickyTop }) => (
  <TableHeaderRow stickyTop={stickyTop}>
    {selectableRows && (
      <TableSubheaderGap key="table-subheader-select" />
    )}
    {segments.map(segment => (
      !segment.title ? (
        <TableSubheaderGap
          key={`table-subheader-${segment.key}`}
          data-collapse-group={segment.group}
          data-collapse-border={segment.border}
          span={segment.span} />
      ) : (
        <StyledSubheaderGroup
          key={`table-subheader-${segment.key}`}
          data-collapse-group={segment.group}
          data-collapse-border={segment.border}
          span={segment.span}>
          {segment.title}
        </StyledSubheaderGroup>
      )
    ))}
    <TableSubheaderClear />
  </TableHeaderRow>
);
