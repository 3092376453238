import { useTypedModal } from '@/common/modules/Modal';
import { AdditionalInformationForm } from '@/types';
import { hasPii, piiOptions } from '@/utils/invoice/invoicePiiOptions';
import {
  CollapsiblePane,
  Dropdown,
  DropdownCreatable, LabelValuePair, DropdownOptions, TextField
} from '@instech/components';
import { InfoCircle } from '@instech/icons';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { INVOICE_PANE_MARGIN } from '@/utils/style/styleConstants';
import { piiDescriptionModal } from './modals/PiiDescriptionModal';
import { piiModal } from './modals/PiiModal';

// TODO: Replace with a standardized component from @instech/components when we've agreed upon styling
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0 32px;
`;

export const additionalInformationInitialValues: AdditionalInformationForm = {
};

// custom visibility field to still take appropriate space when hidden
const DescriptionField = styled(TextField)<{$visible: boolean}>`
  visibility: ${({ $visible }) => $visible ? 'visible' : 'hidden'};
`;

const LabelIcon = styled(InfoCircle)`
  width: 16px;
  height: 16px;

  g {
    stroke-width: 2px;
  }
`;

interface AdditionalInformationPaneProps {
  categoryOptions: DropdownOptions;
  locationOptions: LabelValuePair[];
  repairPeriodOptions?: DropdownOptions;
  hasSplit?: boolean;
  fieldsRequired?: boolean;
  showRepairPeriod?: boolean;
}
export const AdditionalInformationPane = ({
  categoryOptions,
  locationOptions,
  repairPeriodOptions,
  hasSplit,
  fieldsRequired,
  showRepairPeriod: showRepairPerid = false
}: AdditionalInformationPaneProps) => {
  const { values } = useFormikContext<AdditionalInformationForm>();
  const showDescription = hasPii(values.personallyIdentifiableInformation?.value ?? '');

  const { open: showPiiModal } = useTypedModal(piiModal());
  const { open: showPiiDescriptionModal } = useTypedModal(piiDescriptionModal());

  return (
    <CollapsiblePane padding="20px" margin={INVOICE_PANE_MARGIN} title="Additional Information" color="green">
      <Row>
        <Dropdown
          placeholder=""
          name="category"
          label={hasSplit ? 'Category for Summary':'Category'}
          options={categoryOptions}
          clearable
          required={fieldsRequired} />
        {showRepairPerid && (
        <DropdownCreatable
          placeholder=""
          name="repairPeriod"
          label={hasSplit ? 'Repair Period for Summary':'Repair Period'}
          options={repairPeriodOptions}
          clearable />
        )}
        <DropdownCreatable
          placeholder=""
          name="location"
          label="Ship Repair Location"
          options={locationOptions}
          clearable
          required={fieldsRequired} />
      </Row>
      <Row>
        <Dropdown
          placeholder="Choose"
          name="personallyIdentifiableInformation"
          label="Does this invoice contain PII?"
          options={piiOptions}
          required={fieldsRequired}
          labelIcon={<LabelIcon onClick={showPiiModal} />}
          clearable />
        <DescriptionField
          placeholder=""
          name="personallyIdentifiableInformationDescription"
          label="PII location in document"
          maxLength={110}
          forceMaxLength
          labelIcon={<LabelIcon onClick={showPiiDescriptionModal} />}
          $visible={showDescription} />
      </Row>
    </CollapsiblePane>
  );
};
