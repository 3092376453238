import {
  Breadcrumb, breadcrumbVesselName, reusableSteps
} from '@/common/components/Breadcrumbs';

export const vesselBreadcrumbSteps = (
  claimStatementId: string,
  vesselName: string,
  isExperimental?: boolean
): Breadcrumb[] => {
  const vesselNameLabel = breadcrumbVesselName(vesselName, isExperimental);
  return ([
    reusableSteps.myDashboardStep,
    { label: vesselNameLabel, to: `/claimstatements/${claimStatementId}` },
  ]);
};

export const claimReviewBreadcrumbSteps = (
  claimStatementId: string,
  vesselName: string,
  isExperimental?: boolean
): Breadcrumb[] => {
  const vesselNameLabel = `CLAIM REVIEW - ${breadcrumbVesselName(vesselName, isExperimental)}`;
  return ([
    reusableSteps.myDashboardStep,
    { label: vesselNameLabel, to: `/claimstatements/${claimStatementId}/review` },
  ]);
};
