import { useState } from 'react';
import styled from 'styled-components';
import { AnimateCollapse } from '@/common/components/Animation';
import { FCWC } from '@/types';
import { ToggleIcon } from './ToggleIcon';

const GroupTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  font-size: 18px;
  color: ${({ theme }) => theme.marineBlue};
  background-color: ${({ theme }) => theme.white};
  padding-left: 16px;
  padding-right: 4px;
  cursor: pointer;
`;

const InnerShadow = styled.div`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    height: 8px;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 60, 113, 0.10) 0%, rgba(0, 60, 113, 0) 100%);
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 8px;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 60, 113, 0.10) 0%, rgba(0, 60, 113, 0) 100%);
  }
`;

interface Props {
  title: string;
}
export const ChapterGroup: FCWC<Props> = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => setIsOpen(prev => !prev);

  return (
    <div>
      <GroupTitle onClick={handleToggle}>
        <span>{title}</span>
        <ToggleIcon toggled={isOpen} />
      </GroupTitle>
      <AnimateCollapse toggleState={isOpen} startOpen>
        <InnerShadow>{children}</InnerShadow>
      </AnimateCollapse>
    </div>
  );
};
