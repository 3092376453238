import { InvoiceUploadStatus } from '@/types';
import {
  Approved, Load, WarningFilled
} from '@instech/icons';
import React from 'react';
import styled, { css } from 'styled-components';

const TextWrapper = styled.div<{ smallMarginBottom?: boolean }>`
  margin-bottom: 45px;
  text-align: center;
  color: ${props => props.theme.marineBlue};

  ${props => props.smallMarginBottom && css`
    margin-bottom: 17px;
  `};
`;
const RegularText = styled.div`
  margin-top: 7px;
  text-align: center;
  color: ${props => props.theme.marineBlue};
  font-size: 18px;
`;
const BoldText = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
`;

const WarningFilledStyled = styled(WarningFilled)`
  width: 121px;
  height: 66px;
`;
const ApprovedStyled = styled(Approved)`
  width: 121px;
  height: 66px;
`;
const LoadStyled = styled(Load)`
  width: 50px;
  margin-top: 50px;
  margin-bottom: 75px;
`;

interface ErrorModalContentProps {
  title: string;
  content: string;
}
const ErrorContent = ({ title, content }: ErrorModalContentProps) => (
  <TextWrapper smallMarginBottom>
    <WarningFilledStyled />
    <BoldText>
      {title}
    </BoldText>
    <RegularText>
      {content}
    </RegularText>
  </TextWrapper>
);

const SuccessContent = () => (
  <TextWrapper>
    <ApprovedStyled />
    <BoldText>
      Invoice has been replaced
    </BoldText>
  </TextWrapper>
);

const LoadContent = () => (
  <TextWrapper smallMarginBottom>
    <LoadStyled />
  </TextWrapper>
);

export const replaceStatusToContentMap: { [status in InvoiceUploadStatus | 'Uninitialized']: JSX.Element } = ({
  Success: <SuccessContent />,
  inProgress: <LoadContent />,
  Uninitialized: <LoadContent />,
  Duplicate: <ErrorContent title="Duplicate file" content="The file is a duplicate of an existing invoice and will not be uploaded" />,
  SizeTooLarge: <ErrorContent title="Upload failed" content="Exceeding 25 MB" />,
  InvalidFileType: <ErrorContent title="Upload failed" content="Unsupported file format" />,
  Failed: <ErrorContent title="Upload failed" content="Please retry" />,
});
