import { Approved } from '@instech/icons';
import { FC } from 'react';
import { Loader } from '@instech/components';
import { AutosaveStatus, autosaveStatus } from '../types';
import { Container, SaveText } from './Components';

interface Props {
  status: AutosaveStatus;
}
export const SaveStateDisplay: FC<Props> = ({ status }) => {
  if (status === autosaveStatus.pending) {
    return (
      <Container>
        <SaveText>Saving</SaveText>
        <Loader size="small" />
      </Container>
    );
  }
  return (
    <Container>
      <SaveText>Saved</SaveText>
      <Approved />
    </Container>
  );
};
