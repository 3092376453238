import {
  CollapsibleTableCollapseButtons,
  CollapsibleTableHeaders,
  CollapsibleTableRows,
  CollapsibleTableSubheaders,
  useSortedCollapsibleTableData
} from '@/common/components/Table/CollapsibleTable';
import { CollapseWrapper } from '@/common/components/Table/CollapsibleTable/CollapseColumns';
import { GroupingRadioStickyWrapper, TableGroupingRadio } from '@/common/components/Table/TableGrouping';
import { GroupingOption, groupingOptionsList } from '@/common/components/Table/TableGrouping/types';
import { useExpandedTableRows } from '@/common/components/Table/utils';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { FitToContent } from '@/common/layout/FitToContent';
import { useTypedModal } from '@/common/modules/Modal';
import { shareInvoicesForReview } from '@/services/invoice/invoiceServices';
import { updateInvoiceState } from '@/services/invoice/invoiceStateService';
import {
  ClaimStatementSettings, InvoiceOverview, InvoiceState
} from '@/types';
import { arrayAddOrRemove } from '@/utils/array';
import {
  COLLAPSED_HEADER_HEIGHT,
  PAGE_HEADING_HEIGHT,
  invoiceStates
} from '@/utils/constants';
import {
  getInvoiceStateFromCHToOwner,
  getInvoiceStateToFinished,
  transferInvoicesInGroups
} from '@/utils/invoicesTransfer';
import {
  CheckboxControlled,
  ComplexTable, Pane, SortedTableHeader
} from '@instech/components';
import {
  FunctionComponent, useCallback, useEffect, useMemo, useState
} from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useSWRConfig } from 'swr';
import { RefreshCommands } from '../../../ClaimsHandlerClaimStatementPage';
import { TableHeaderButtons } from '../../core/Components';
import { NoInvoicePane } from '../../core/NoInvoicePane';
import { useTraversalOrderContext } from '../../core/TraversalContext';
import { defaultMarkAsFinshedProps, markAsFinishedModal } from '../../modal/MarkAsFinishedModal';
import { returnClaimsHandlerToOwnerModal } from '../../modal/ReturnClaimsHandlerToOwnerModal';
import { returnSingleInvoiceClaimsHandlerToSurveyorModal } from '../../modal/ReturnSingleInvoiceClaimsHandlerToSurveyorModal';
import { transferClaimsHandlerToSurveyorModal } from '../../modal/TransferClaimsHandlerToSurveyorModal';
import { InvoiceButtons } from '../core/InvoiceButtons';
import { useClaimsHandlerDisableButtons } from '../hooks/useClaimsHandlerDisableButtons';
import { useClaimsHandlerSelectedStates } from '../hooks/useClaimsHandlerSelectedStates';
import {
  getCompleteInvoices, getDeviatingInvoices, getIncompleteInvoices, useTableSchemaByGrouping
} from '../utils';

// Calculate the stickyTop offsets of the different table elements, based on the height of the elements
const getStickyTopValues = (selectableRows: boolean) => {
  const baseGapHeight = COLLAPSED_HEADER_HEIGHT + PAGE_HEADING_HEIGHT;
  const buttonHeight = selectableRows ? 76 : 0;
  const collapseHeight = 57;
  const subheaderHeight = 33;

  const buttonsStickyTop = baseGapHeight;
  const collapseStickyTop = baseGapHeight + buttonHeight;
  const subheaderStickyTop = collapseStickyTop + collapseHeight;
  const headerStickyTop = subheaderStickyTop + subheaderHeight;

  return {
    buttons: `${buttonsStickyTop}px`,
    collapse: `${collapseStickyTop}px`,
    subheader: `${subheaderStickyTop}px`,
    header: `${headerStickyTop}px`
  };
};

const FitTableArea = styled(FitToContent)`
  padding: 0px 40px 40px;
  box-sizing: border-box;
`;

const StyledCheckbox = styled(CheckboxControlled)`
  // The CheckboxControlled styles were loading after these styles in the feature environment for some reason.
  // This caused the width to remain 100%.
  width: auto !important;
  color: ${({ theme }) => theme.marineBlue};
`;

const StyledHeaderContainer = styled(TableHeaderButtons)`
  display: flex;
  align-items: center;
`;

interface Props {
  title: string;
  data: InvoiceOverview;
  claimStatementSettings?: ClaimStatementSettings;
  onClick: (invoiceId: string) => void;
  onSubmit?: (invoicesToTransfer: string[]) => Promise<void>;
  showTable?: boolean;
  selectableRows?: boolean;
  onInvoicesUpdateSuccess: (refreshCommands?: RefreshCommands) => Promise<void>;
}
/**
 * Invoice table for the Claims Handler, separate from the same table shown
 * for the Owner.
 */
export const OldInvoiceTableClaimsHandler: FunctionComponent<Props> = ({
  title,
  data,
  claimStatementSettings,
  onClick,
  onSubmit,
  showTable = true,
  selectableRows = true,
  onInvoicesUpdateSuccess,
}) => {
  const { claimStatementId } = useParams();
  const { cache } = useSWRConfig();

  const { setCurrentTraversalOrder } = useTraversalOrderContext();

  const { toggleExpanded, expanded } = useExpandedTableRows(data.invoices.map(invoice => invoice.id));
  const [selectedGrouping, setSelectedGrouping] = useState<GroupingOption>(groupingOptionsList[0]);
  const [sortedHeader, setSortedHeader] = useState<SortedTableHeader>();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [collapsedGroups, setCollapsedGroups] = useState<string[]>([]);
  const [showFullComments, setShowFullComments] = useState<boolean>(false);
  const [showFullCommentsCheckbox, setShowFullCommentsCheckbox] = useState<boolean>(true);

  const tooltipId = 'invoice-table-tooltip';
  const showReview = true;

  // Cache a list of the states of the invoice rows that have been selected
  const selectedStates = useClaimsHandlerSelectedStates(data, selectedRows);
  // Get an overview of what state-changing buttons should be enabled/disabled
  const disabledButtons = useClaimsHandlerDisableButtons(data, selectedStates);

  const handleOnRowClick = useCallback((invoiceId: string, invoiceIdList: string[]) => {
    setCurrentTraversalOrder(invoiceIdList, invoiceId);
    onClick(invoiceId);
  }, [onClick, setCurrentTraversalOrder]);

  // Based on currently selected grouping option, generate and return table segments
  const tableData = useTableSchemaByGrouping(
    selectedGrouping,
    data.invoices,
    {
      selectableRows,
      showReview,
      currency: data.currency,
      claimStatementSettings,
      onRowClick: handleOnRowClick,
      toggleExpanded,
      expanded
    }
  );

  const stickyTop = useMemo(() => getStickyTopValues(tableData.selectableRows), [tableData.selectableRows]);
  // Parse the table data into a set that is sorted based on the user's selection
  const sortedTable = useSortedCollapsibleTableData(tableData.tableRows, sortedHeader);

  // Reset what rows are selected when the grouping changes
  useEffect(() => {
    setSelectedRows([]);
  }, [selectedGrouping]);

  const handleSetGrouping = (grouping: GroupingOption) => {
    setSelectedGrouping(grouping);
    setShowFullCommentsCheckbox(grouping === 'No group');
  };

  const handleSortedHeader = (header: SortedTableHeader) => {
    setSortedHeader(header);
  };

  // Deselect all rows if any rows are selected, or
  // select all rows if no rows are selected.
  const handleSelectAll = () => {
    if (selectedRows.length > 0) {
      setSelectedRows([]);
    } else {
      const allRowIds = data.invoices.map(invoice => invoice.id);
      setSelectedRows(allRowIds);
    }
  };

  // Toggle a row's ID into or out of the array of selected row IDs
  const handleSelectRow = (rowId: string) => {
    const rowsToSelect = selectedRows.includes(rowId)
      ? selectedRows.filter(id => id !== rowId)
      : [...selectedRows, rowId];
    setSelectedRows(rowsToSelect);
  };

  const handleCollapse = (range: string) => {
    const newRanges = arrayAddOrRemove(collapsedGroups, range);
    setCollapsedGroups(newRanges);
  };

  const handleOnOpen = () => {
    const selectedSortedIds = sortedTable.data
      .filter(({ row }) => selectedRows.some(y => y === row.id))
      .map(({ row }) => row.id);
    setCurrentTraversalOrder(selectedSortedIds);
    onClick(selectedSortedIds[0]);
  };

  const transferInvoices = async (invoicesIds: string[], destinationState: InvoiceState, instructions?: string) => {
    if (claimStatementId === undefined) {
      return 'failed';
    }

    await updateInvoiceState(claimStatementId, {
      destinationState,
      instructions,
      invoiceIdList: invoicesIds
    }, cache);

    setSelectedRows([...selectedRows.filter(x => !invoicesIds.includes(x))]);
    await onInvoicesUpdateSuccess();
    return 'success';
  };

  const transferInvoiceGroups = async (
    invoiceStateFactoryCallback: (state: InvoiceState) => InvoiceState,
    instructions?: string,
    selected: string[] = selectedRows
  ) => {
    const invoicesToReturn = data.invoices.filter(x => selected.includes(x.id));
    const result = await transferInvoicesInGroups(
      claimStatementId!,
      invoicesToReturn,
      invoiceStateFactoryCallback,
      onInvoicesUpdateSuccess,
      cache,
      instructions
    );

    if (result === 'success') {
      setSelectedRows([...selected.filter(x => !invoicesToReturn.map(y => y.id).includes(x))]);
    }

    return result;
  };

  const { open: openReturnModal } = useTypedModal(returnClaimsHandlerToOwnerModal({
    claimStatementId: claimStatementId!,
    firstInvoiceId: selectedRows.length === 1 ? selectedRows[0] : '',
    numberOfInvoices: selectedRows.length,
    onReturn: async instructions => transferInvoiceGroups(getInvoiceStateFromCHToOwner, instructions)
  }));

  const { open: openTransferModal } = useTypedModal(transferClaimsHandlerToSurveyorModal({
    claimStatementId: claimStatementId!,
    firstInvoiceId: selectedRows.length === 1 ? selectedRows[0] : '',
    invoices: selectedStates,
    transfer: async (destinationState, instructions) => transferInvoices(selectedRows, destinationState, instructions)
  }));

  const { open: openReturnToSurveyorModal } = useTypedModal(returnSingleInvoiceClaimsHandlerToSurveyorModal({
    invoices: selectedStates,
    transfer: async (destinationState, instructions) => transferInvoices(selectedRows, destinationState, instructions),
    claimStatementId: claimStatementId!
  }));

  const { open: openMarkAsFinishedModal } = useTypedModal(markAsFinishedModal(defaultMarkAsFinshedProps));

  const handleMarkAsFinished = async (invoicesToFinish: string[]) => {
    await transferInvoiceGroups(getInvoiceStateToFinished, undefined, invoicesToFinish);
  };

  const handleFinished = async () => {
    const selectedInvoices = data.invoices.filter(invoice => selectedRows.includes(invoice.id));
    const deviatingInvoices = getDeviatingInvoices(selectedInvoices);
    const incompleteInvoices = getIncompleteInvoices(selectedInvoices);
    const completeInvoices = getCompleteInvoices(selectedInvoices, deviatingInvoices, incompleteInvoices);

    // show modal if some invoices cannot be finished, otherwise just finish them
    if (completeInvoices.length !== selectedInvoices.length) {
      const invoicesToFinish = completeInvoices.map(invoice => invoice.id);
      const finishInvoices = () => handleMarkAsFinished(invoicesToFinish);
      openMarkAsFinishedModal({
        totalInvoiceCount: selectedInvoices.length,
        completeInvoiceCount: completeInvoices.length,
        deviatingInvoiceCount: deviatingInvoices.length,
        incompleteInvoiceCount: incompleteInvoices.length,
        finishInvoices
      });
      return;
    }

    await handleMarkAsFinished(selectedRows);
  };

  const handleReturn = async () => {
    openReturnModal();
  };

  const handleShareWithSurveyor = async () =>
    selectedStates[0].state === invoiceStates.AdjustersConsideration ? openReturnToSurveyorModal() : openTransferModal();

  const handleShareForReview = async () => {
    if (selectedRows.length === 0 || !claimStatementId) return;
    await shareInvoicesForReview(claimStatementId, { invoiceIdList: selectedRows, IsSharedForReview: true });
    await onInvoicesUpdateSuccess({ refreshInvoiceOverview: true, refreshSummary: false });
    setSelectedRows([]);
  };

  const handleRevokeFromReview = async () => {
    if (selectedRows.length === 0 || !claimStatementId) return;
    await shareInvoicesForReview(claimStatementId, { invoiceIdList: selectedRows, IsSharedForReview: false });
    await onInvoicesUpdateSuccess({ refreshInvoiceOverview: true, refreshSummary: false });
    setSelectedRows([]);
  };

  if (!showTable) {
    return (
      <FitTableArea>
        <NoInvoicePane />
      </FitTableArea>
    );
  }

  return (
    <>
      <GroupingRadioStickyWrapper>
        <TableGroupingRadio selected={selectedGrouping} onChange={handleSetGrouping} />
      </GroupingRadioStickyWrapper>
      <FitTableArea>
        <Pane title={title} color="green" padding="0px">
          <StyledHeaderContainer stickyTop={stickyTop.buttons}>
            {tableData.selectableRows && (
              <InvoiceButtons
                selected={selectedRows.length}
                // func calls returning null are not yet implemented
                onOpen={handleOnOpen}
                onFinished={handleFinished}
                onReturn={handleReturn}
                onShareWithSurveyor={handleShareWithSurveyor}
                onShareForReview={handleShareForReview}
                onRevokeFromReview={handleRevokeFromReview}
                disableReturn={disabledButtons.return}
                disableShare={disabledButtons.share}
                disableShareForReview={disabledButtons.shareForReview}
                disableFinished={disabledButtons.finished}
                enableRevokeFromReview={disabledButtons.revokeFromReview}
              />
            )}
            {showFullCommentsCheckbox && (
              <div>
                <StyledCheckbox
                  name="showFullComments"
                  rightLabel="Show full comments"
                  selected={showFullComments}
                  onChange={() => setShowFullComments(!showFullComments)}
                  noTopLabel
                  noErrors />
              </div>
            )}
          </StyledHeaderContainer>
          <SharedTooltip id={tooltipId}>
            <CollapseWrapper collapsedGroups={collapsedGroups}>
              <ComplexTable layout={tableData.layout}>
                <CollapsibleTableCollapseButtons
                  segments={tableData.collapseButtons}
                  stickyTop={stickyTop.collapse}
                  selectableRows={tableData.selectableRows}
                  collapsedGroups={collapsedGroups}
                  onClick={handleCollapse}
                />
                <CollapsibleTableSubheaders
                  segments={tableData.subheader}
                  selectableRows={tableData.selectableRows}
                  stickyTop={stickyTop.subheader}
                />
                <CollapsibleTableHeaders
                  segments={tableData.header}
                  sortedHeader={sortedHeader}
                  setSortedHeader={handleSortedHeader}
                  allItems={data.invoices.length}
                  selectedItems={selectedRows.length}
                  handleSelectAll={handleSelectAll}
                  selectableRows={tableData.selectableRows}
                  headerCheckboxName="invoice-table-checkbox-all"
                  subrowsHeaderText={tableData.subrowsHeaderText}
                  stickyTop={stickyTop.header}
                />
                <CollapsibleTableRows
                  tableRows={sortedTable.data}
                  minSubrowsToExpand={tableData.minSubrowsToExpand}
                  selectedRows={selectedRows}
                  onSelect={handleSelectRow}
                  selectableRows={tableData.selectableRows}
                  showFullComments={showFullComments}
                  expandableRows
                  canDrillDown
                />
              </ComplexTable>
            </CollapseWrapper>
          </SharedTooltip>
        </Pane>
      </FitTableArea>
    </>
  );
};
