/**
 * Collection file for style constants that don't make sense to define as part of the theme.
 * For example, margin and padding on <Pane /> components can be overridden as props. When there
 * are several places where panes should consistently have the same margin or padding, it may
 * make sense to define that as a constant here as a single source for the value.
 *
 * Constants should be written UPPERCASE_UNDERSCORE.
 */

/**
 * Panes on Invoice pages should have a set margin above and no
 * margin on the sides or below.
 */
export const INVOICE_PANE_MARGIN = '16px 0px 0px';
