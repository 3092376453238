import { Guid } from '@/types';
import { ContextMenu, MenuOption } from '@instech/components';
import { ActionTableCell } from './ActionTableCell';
import { TableCellVariantType } from './types';

interface ContextMenuCellProps {
  documentId: Guid;
  options: MenuOption[];
  variant?: TableCellVariantType;
}
export const ContextMenuCell = ({ documentId, options, variant }: ContextMenuCellProps) => (
  <ActionTableCell width="64px" data-cell-variant={variant}>
    {options && options.length > 0 && (
      <ContextMenu
        id={`document-${documentId}-context-menu`}
        options={options}
        data-testid={`context-menu-${documentId}`}
        closeOnScroll />
    )}
  </ActionTableCell>
);
