import { useMemo } from 'react';
import { InvoiceOverview, InvoiceStateMetadata } from '@/types';

/**
 * Memoized collection of InvoiceStateMetadata for the invoices that
 * are currently selected by the user in the Claims Handler table.
 */
export const useClaimsHandlerSelectedStates = (
  data: InvoiceOverview,
  selectedRows: string[]
) => useMemo(() => data.invoices.filter(x => selectedRows.includes(x.id)).map(x => ({
  state: x.state,
  id: x.id,
  claimStatementId: x.claimStatementId,
  adjusted: x.adjustersConsideration?.isConsidered ?? false,
  isSharedForReview: x.isSharedForReview,
} as InvoiceStateMetadata)), [data.invoices, selectedRows]);
