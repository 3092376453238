import { ModalType } from '@/common/modules/Modal/types';
import { useModalContext, useTypedModal } from '@/common/modules/Modal';
import { ButtonGroup, SlimButton } from '@instech/components';
import { BoldText, RegularText } from '@/common/components/Text';
import { WarningLarge } from '@/common/components/Icon/WarningLarge';
import { ContentHorizontalCenter } from '@/common/layout/Content';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { useState } from 'react';

const TextWrapper = styled.div`
  text-align: center;
  margin: 16px 0 32px;

  ${BoldText} {
    margin-bottom: 8px;
  }
`;

const ErrorMessage = styled(RegularText)`
  color: ${({ theme }) => theme.primaryError};
  margin-top: 16px;
`;

type Props = {
  deleteOccurrence: () => Promise<void>;
};
const ModalContent = ({ deleteOccurrence }: Props) => {
  const { close } = useModalContext();
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    setError('');
    try {
      await deleteOccurrence();
      close();
    } catch {
      setError('Failed to delete the occurrence. Please try again.');
    }
  };

  return (
    <Formik initialValues={{}} onSubmit={handleSubmit} onReset={close}>
      {({ isSubmitting }) => (
        <Form>
          <ContentHorizontalCenter>
            <WarningLarge />
            <TextWrapper>
              <BoldText>Occurrence will be deleted</BoldText>
              <RegularText>Are you sure you want to delete the occurrence?</RegularText>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </TextWrapper>
          </ContentHorizontalCenter>
          <ButtonGroup alignRight>
            <SlimButton type="reset" variant="secondary" onClick={close}>
              CANCEL
            </SlimButton>
            <SlimButton type="submit" disabled={isSubmitting} loading={isSubmitting} width="166px">
              DELETE OCCURRENCE
            </SlimButton>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  );
};

const openConfirmDeleteOccurrenceModal = (args: Props): ModalType<Props> => ({
  component: ModalContent,
  options: {
    title: 'Delete Occurrence',
    size: 'small',
    padding: '12px 20px 20px'
  },
  args,
});
export const useOpenConfirmDeleteOccurrenceModal = (args: Props) =>
  useTypedModal(openConfirmDeleteOccurrenceModal(args));
