import {
  FunctionComponent, useMemo, useState
} from 'react';
import {
  ApplicationRole, InvoiceRecord, SurveyorsConsiderationSplit
} from '@/types';
import { LightReadonlyCollapsiblePane } from '@/common/components/Pane/ReadonlyCollapsiblePane';
import { useClaimStatement } from '@/services/claimStatementServices';
import { useParams } from 'react-router-dom';
import { Loader } from '@instech/components';
import { CurrencySelector } from '@/common/components/Form/CurrencySelector/CurrencySelector';
import { useCurrentRole } from '@/common/authentication/CurrentRoleContext';
import { calculateMultipleReadonlySurveyorSummaryValues } from '@/utils/invoice/calculateSummaries';
import { INVOICE_PANE_MARGIN } from '@/utils/style/styleConstants';
import { roles } from '@/utils/constants';
import { SharedTooltip } from '@/common/components/Tooltip/SharedTooltip';
import { SplitTableSurveyor } from '../../../EditInvoice/SurveyorsConsiderationPane/core/SplitTableSurveyor';
import { ReadonlySplitTableRowSurveyor } from './ReadonlySplitTableRowSurveyor';
import { TotalLineSurveyorsConsideration } from '../../../EditInvoice/SurveyorsConsiderationPane/core/TotalLineSurveyorsConsideration';
import { ReadonlyFields } from './ReadonlyFields';
import { NoExchangeRateInfo } from '../../../EditInvoice/core/NoExchangeRateInfo';

const getShowInternalNotes = (
  role: ApplicationRole | null | undefined,
  internalNote: string | null | undefined
) => {
  if (!internalNote) return false;
  return role?.name === roles.Surveyor;
};

interface MultipleReadOnlyProps {
  record: InvoiceRecord;
  splits: SurveyorsConsiderationSplit[];
  startCollapsed?: boolean;
}
export const MultipleReadonlyConsideration: FunctionComponent<MultipleReadOnlyProps> = ({ record, splits, startCollapsed }) => {
  const { claimStatementId } = useParams();
  const { data: claimstatement } = useClaimStatement(claimStatementId);
  const role = useCurrentRole();
  const [selectedCurrency, setCurrency] = useState(record.currency);

  const useInvoiceCurrency = useMemo(() => selectedCurrency === record.currency, [selectedCurrency, record.currency]);
  const { adjustersConsiderationTotal, claimConsiderationTotal, generalExpensesTotal, ownersWorkConsiderationTotal, totalSum } = useMemo(
    () => calculateMultipleReadonlySurveyorSummaryValues(splits, record.exchangeRate, useInvoiceCurrency),
    [splits, record.exchangeRate, useInvoiceCurrency]
  );

  if (!claimstatement) return <Loader />;

  const handleCurrencyChange = (currency: string) => {
    setCurrency(currency);
  };

  const noExchangeRateVisible = (claimstatement.currency === selectedCurrency && !record.exchangeRate);

  const { occurrences } = claimstatement;
  const getOccurrenceName = (id: string | undefined) => occurrences.find(o => o.id === id)?.name;
  return (
    <LightReadonlyCollapsiblePane
      title="Surveyor's Consideration"
      margin={INVOICE_PANE_MARGIN}
      padding="16px"
      startCollapsed={startCollapsed}>
      <CurrencySelector
        first={record.currency}
        second={claimstatement.currency}
        selected={selectedCurrency}
        setSelected={handleCurrencyChange}
        marginBottom="16px"
        id="surveyor-multiple-read-only" />
      <NoExchangeRateInfo isVisible={noExchangeRateVisible} />
      <SplitTableSurveyor isMultiple>
        <SharedTooltip id="surveyor-cost-fields-tags-tooltip">
          {splits.map(split => (
            <ReadonlySplitTableRowSurveyor
              key={split.id}
              invoiceRecord={record}
              consideration={split}
              useInvoiceCurrency={useInvoiceCurrency}
              occurrenceList={occurrences}>
              <ReadonlyFields
                claimConsideration={split.claimConsideration}
                ownersWorkConsideration={split.ownersWorkConsideration}
                generalExpenses={split.generalExpenses}
                adjustersConsideration={split.adjustersConsideration}
                exchangeRate={record.exchangeRate}
                category={split.category}
                surveyorsComment={split.surveyorsComment}
                internalNotes={split.internalNotes}
                useInvoiceCurrency={useInvoiceCurrency}
                occurrence={getOccurrenceName(split.occurrenceId)}
                showInternalNotes={getShowInternalNotes(role, split.internalNotes)}
                claimConsiderationTag={split.claimConsiderationTag}
                generalExpensesTag={split.generalExpensesTag}
              />
            </ReadonlySplitTableRowSurveyor>
          ))}
          <TotalLineSurveyorsConsideration
            adjustersConsideration={adjustersConsiderationTotal}
            claim={claimConsiderationTotal}
            generalExpenses={generalExpensesTotal}
            ownersWork={ownersWorkConsiderationTotal}
            sum={totalSum}
          />
        </SharedTooltip>
      </SplitTableSurveyor>
    </LightReadonlyCollapsiblePane>
  );
};
