import { ComingSoon } from '@/apps/Adjustment/components/ComingSoon';
import { Pane } from '@instech/components';
import { Form } from 'formik';
import { FC } from 'react';
import { FCWC } from '@/types';
import { AutosaveTrigger } from '@/common/components/Autosave';

const comingSoonMessage = `
  Adjustment is currently in development, and so the
  content on this page is still a work in progress.
`;

/**
 * Renders a pane showing a centered "Coming soon" graphic
 */
export const ComingSoonElement: FC = () => (
  <Pane title="Work in Progress" color="green">
    <ComingSoon description={comingSoonMessage} />
  </Pane>
);

export const FormWithAutoSave: FCWC = ({ children }) => (
  <Form>
    <AutosaveTrigger />
    {children}
  </Form>
);
