import React from 'react';
import styled from 'styled-components';
import { Header } from '@/common/modules/Header';
import { SharedDownloadRouter } from '@/components/pages/SharedDownload/SharedDownloadRouter';

const PageWrapper = styled.div`
  background-color:  ${props => props.theme.whiteBlue};
  min-height: 100vh;
`;

const PagePadding = styled.div`
  padding-top: 100px;
`;

export const SharedDownloadPage = () => (
  <PageWrapper>
    <Header showTabs={false} showPortalSelector={false} showUserMenu={false} disableLink />
    <PagePadding>
      <SharedDownloadRouter />
    </PagePadding>
  </PageWrapper>
);
