import {
  AdjustersConsideration,
  AdjustersConsiderationSplitForm,
  UpdateAdjustersConsiderationForm,
  UpdateSurveyorsConsiderationForm
} from '@/types';

/**
 * Filter out splits from the formik backed array where the split are
 * no longer stored at the server
 * @param splitForm the split form array in formik
 * @param consideration stored splits at the backend
 */
const isPermanentlyDeleted = (splitForm: AdjustersConsiderationSplitForm, consideration: AdjustersConsideration) =>
  splitForm.isDeleted && !consideration.splits.some(({ id }) => id === splitForm.id);

const removeDeletedSplits = (values: UpdateAdjustersConsiderationForm, consideration?: AdjustersConsideration) =>
  // Remove splits that are no longer stored
  consideration ? values.splits.filter(x => !isPermanentlyDeleted(x, consideration)): values.splits;

/**
 * Util func to reset InvoiceFormAdjuster with considered helper fields
 * manually set to true. This is necessary because the form is not being
 * auto re-initialized after save in order to persist other temporary
 * form states (collapse states, etc.)
 */
export const resetAdjusterFormWithConsidered = (values: UpdateAdjustersConsiderationForm, consideration?: AdjustersConsideration) => ({
  ...values,
  splits: removeDeletedSplits(values, consideration).map(split => ({
    ...split,
    form: {
      ...split.form,
      isOutdated: false
    }
  }))
});

/**
 * Util func to reset InvoiceFormSurveyor with considered helper fields
 * manually set to true. This is necessary because the form is not being
 * auto re-initialized after save in order to persist other temporary
 * form states (collapse states, isConsidered fields, etc.)
 */
export const resetSurveyorFormWithConsidered = (values: UpdateSurveyorsConsiderationForm) => ({
  ...values,
  splits: values.splits.map(split => ({
    ...split,
    form: {
      ...split.form,
      claimConsidered: true,
      ownersWorkConsidered: true
    }
  }))
});
