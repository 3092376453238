import { NhcLogoSymbol, NhcLogoText } from '@instech/icons';
import {
  motion, MotionValue, useScroll, useTransform
} from 'framer-motion';
import { FunctionComponent as FC } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  HEADER_ANIM_KEYFRAMES,
  LOGOTEXT_ANIM_KEYFRAMES,
  LOGO_HEIGHT_VALUES,
  LOGO_TOP_VALUES,
  LOGO_WIDTH_VALUES,
  TEXT_ALPHA_VALUES
} from './animationConstants';

const logostyles = css`
  grid-area: logo;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 340px;
  box-sizing: border-box;
`;

const LogoLink = styled(Link)`
  ${logostyles}
`;

const LogoLayout = styled.div`
  ${logostyles}
`;

const LogoWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  justify-content: center;
  height: ${`${LOGO_HEIGHT_VALUES[0]}px`};
  width:  ${`${LOGO_WIDTH_VALUES[0]}px`};;
  transition: width 0.1s linear;
`;

const StyledLogo = styled(motion.div)`
  position: absolute;
  top:  ${`${LOGO_TOP_VALUES[0]}px`};;
  transition: height 0.1s linear, top 0.1s linear;
  & svg {
    height: 100%;
    width: auto;
  }
`;

const StyledLogoText = styled(motion.div)`
  position: absolute;
  width: 130px;
  padding-top: 4px;
  bottom: -8px;
  transition: opacity 0.1s linear;
  & svg {
    height: auto;
    width: 100%;
  }
`;

const ProductName = styled.span`
  padding-left: 42px;
  font-size: 20px;
  font-weight: bold;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
`;

interface ComponentProps {
  wrapperWidth: MotionValue<number>;
  iconHeight: MotionValue<number>;
  iconTop: MotionValue<number>;
  textOpacity: MotionValue<number>;
}
const LogoComponents: FC<ComponentProps> = ({ wrapperWidth, iconHeight, iconTop, textOpacity }) => (
  <>
    <LogoWrapper style={{ width: wrapperWidth }}>
      <StyledLogo style={{ height: iconHeight, top: iconTop }}>
        <NhcLogoSymbol alt="NHC logo" />
      </StyledLogo>
      <StyledLogoText style={{ opacity: textOpacity }}>
        <NhcLogoText alt="NORWEGIAN HULL CLUB" />
      </StyledLogoText>
    </LogoWrapper>
    <ProductName>Claim Statement</ProductName>
  </>
);

interface Props {
  disableLink?: boolean;
}
export const HeaderLogo: FC<Props> = ({ disableLink = false }) => {
  const { scrollY } = useScroll();

  const wrapperWidth = useTransform(scrollY, HEADER_ANIM_KEYFRAMES, LOGO_WIDTH_VALUES);
  const iconHeight = useTransform(scrollY, HEADER_ANIM_KEYFRAMES, LOGO_HEIGHT_VALUES);
  const iconTop = useTransform(scrollY, HEADER_ANIM_KEYFRAMES, LOGO_TOP_VALUES);
  const textOpacity = useTransform(scrollY, LOGOTEXT_ANIM_KEYFRAMES, TEXT_ALPHA_VALUES);

  if (disableLink) {
    return (
      <LogoLayout>
        <LogoComponents wrapperWidth={wrapperWidth} iconHeight={iconHeight} iconTop={iconTop} textOpacity={textOpacity} />
      </LogoLayout>
    );
  }

  return (
    <LogoLink to="/">
      <LogoComponents wrapperWidth={wrapperWidth} iconHeight={iconHeight} iconTop={iconTop} textOpacity={textOpacity} />
    </LogoLink>
  );
};
