import { WarningFilled } from '@instech/icons';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.marineBlue20};
  color: ${props => props.theme.warning};
  font-size: 14px;
  padding: 6px 24px 6px 16px;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const ErrorText = styled.div`
  padding-left: 8px;
`;

interface DeviationMessageProps {
  deviation: string;
  currency: string;
  isNumber: boolean;
  className?: string;
}
/**
 * Cost allocation deviation message display, with support for style override.
 * Takes a numerical value as a string, together with a currency code (ISO-4217),
 * and returns a component that shows the provided deviation value.
 * This component renders out the elements. There is a role-specific version of this
 * for each role that needs to make use of it, which handles the data calculations
 * from a provided invoice.
 */
export const Deviation = ({ deviation, currency, isNumber, className }: DeviationMessageProps) => {
  const errorMessage = isNumber
    ? 'Deviating from Total Amount'
    : 'Cannot find deviation, not a number';

  const deviationMessage = `${deviation} ${currency || ''}`;

  return (
    <Container className={className}>
      <Section>
        <WarningFilled width="17px" height="17px" />
        <ErrorText>{errorMessage}</ErrorText>
      </Section>
      <Section>
        {isNumber && deviationMessage}
      </Section>
    </Container>
  );
};
