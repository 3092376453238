import { useFeatureFlag } from '@/common/providers/featureFlagProvider';
import {
  ClaimsHandlerInvoiceSummary,
  ClaimStatement,
  CreateClaimStatementRequest,
  Guid,
  InvoiceOverview,
  InvoiceStatus,
  Participants
} from '@/types';
import useSWR, { mutate as globalMutate, SWRConfiguration } from 'swr';
import client, { postAsync, putAsync } from './client';

export const claimStatementUrl = (claimStatementId: Guid) => `/claimstatements/${claimStatementId}`;
const claimUrl = (number: number, year: number) => `/claimstatements/${number}/years/${year}`;
const ownersWorkCompleteUrl = (claimStatementId: Guid) => `/claimstatements/${claimStatementId}/setOwnersWorkComplete`;
const claimStatementsByStatusUrl = (status?: InvoiceStatus) => `/claimstatements${status ? `?status=${status}` : ''}`;

const getAllClaimStatementsUrl = '/claimstatements/all';

export const createClaimStatement = async (
  claimNumber: number,
  insuranceYear: number,
  newClaimStatement: CreateClaimStatementRequest,
  claimStatementId?: Guid
) => {
  if (claimStatementId) {
    const result = await client.put<any>(claimStatementUrl(claimStatementId), newClaimStatement);
    return result.data;
  }
  const result = await postAsync(claimUrl(claimNumber, insuranceYear), newClaimStatement);
  return result.data;
};

export const refreshClaimStatementOverview = (data: ClaimStatement[]) => globalMutate(getAllClaimStatementsUrl, data);

export const useClaimStatementOverview = (invoiceStatus?: InvoiceStatus) => {
  const { data, mutate, error } = useSWR<ClaimStatement[]>(claimStatementsByStatusUrl(invoiceStatus));

  const deleteClaimStatement = async (claimStatementId: string) => {
    const result = await client.delete(`claimstatements/${claimStatementId}`);
    if (result!.status === 200) {
      void mutate(prev => prev?.filter(i => i.id !== claimStatementId));
    }
  };

  return { data, deleteClaimStatement, error };
};

export const useClaimStatement = (claimStatementId: string | undefined, config?: SWRConfiguration) => {
  const swrRoute = claimStatementId ? `claimstatements/${claimStatementId}` : null;
  return useSWR<ClaimStatement>(swrRoute, config);
};

export const useClaimStatementInvoicesForReview = (claimStatementId: string | undefined) => {
  const swrRoute = claimStatementId ? `claimstatements/${claimStatementId}/invoices/reviews` : null;
  return useSWR<InvoiceOverview>(swrRoute);
};

export const useReviewSummary = (claimStatementId: Guid | undefined) => {
  const swrRoute = claimStatementId ? `claimstatements/${claimStatementId}/invoices/reviews/summary` : null;
  return useSWR<ClaimsHandlerInvoiceSummary>(swrRoute);
};

export const setOwnersWorkComplete = async (claimStatementId: Guid | undefined) => {
  if (!claimStatementId) return null;

  const result = await client.put(ownersWorkCompleteUrl(claimStatementId));
  return result.data;
};

export const setClaimStatementClosed = (claimStatementId: Guid, isClosed: boolean) =>
  putAsync(`${claimStatementUrl(claimStatementId)}/closed?isClosed=${isClosed}`);

export const useIsClosed = (claimStatement?: ClaimStatement) => {
  const canCloseClaim = useFeatureFlag('canCloseClaim');
  return canCloseClaim && claimStatement?.isClosed === true;
};

export const useUpdateClaimStatementSettings = (claimStatementId: Guid) =>
  (hasGeneralAverage: boolean) => putAsync(`${claimStatementUrl(claimStatementId)}/claimstatementsettings`, { hasGeneralAverage });

export const useUpdateParticipants = (claimStatementId: Guid) =>
  (async (participants: Participants) => {
    await putAsync(`${claimStatementUrl(claimStatementId)}/participants`, participants);
    await globalMutate(claimStatementUrl(claimStatementId));
  });
