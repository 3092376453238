import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import {
  Enlarge, SortDown, SortUp
} from '@instech/icons';
import { SortDirection } from '@tanstack/react-table';

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 14px;
  padding-left: 8px;
  margin-right: -2px;
  color: ${props => props.theme.marineBlue};
  opacity: 0.5;
  svg path:not(:first-child) {
    // TODO: This is a fallback. The component should not
    // require this, but does because the SVG is not currentColor
    stroke: ${props => props.theme.marineBlue};
  }
`;

const getFilterIcon = (sortDirection: SortDirection | false) => {
  switch (sortDirection) {
    case 'asc':
      return <SortDown height="14px" width="14px" />;
    case 'desc':
      return <SortUp height="14px" width="14px" />;
    default:
      return null;
  }
};

interface FilterIconprops {
  sortDirection: SortDirection | false;
}
export const FilterIcons: FunctionComponent<FilterIconprops> = ({ sortDirection }) => {
  const filterIcon = getFilterIcon(sortDirection);
  return (
    <IconWrapper>
      {sortDirection ? filterIcon : <Enlarge height="14px" width="14px" />}
    </IconWrapper>
  );
};
